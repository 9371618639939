import CryptoIcon from 'components/CurrencyIcons/CryptoIcon';
import FiatIcon from 'components/CurrencyIcons/FiatIcon';
import { SelectOption } from 'components/FormControls/SelectInput';
import { Chain, Currency, FiatCurrency } from 'generated/graphql';

export const defaultChainOfCurrency: Record<Currency, Chain> = {
  [Currency.ETH]: Chain.ETHEREUM,
  [Currency.BTC]: Chain.BITCOIN,
  [Currency.USDC]: Chain.ETHEREUM,
  [Currency.SHIB]: Chain.ETHEREUM,
  [Currency.LTC]: Chain.LITECOIN,
  [Currency.TRX]: Chain.TRON,
  [Currency.MATIC]: Chain.MATIC_POLYGON,
  [Currency.USDT]: Chain.ETHEREUM,
  [Currency.XRP]: Chain.XRP,
  [Currency.SOL]: Chain.SOLANA,
  [Currency.DOGE]: Chain.DOGE,
  [Currency.BUSD]: Chain.BINANCE_SMART_CHAIN,
  [Currency.BNB]: Chain.BINANCE_SMART_CHAIN,
};

export const currenciesOrder: Currency[] = [
  Currency.BTC,
  Currency.ETH,
  Currency.LTC,
  Currency.TRX,
  Currency.XRP,
  Currency.USDT,
  Currency.USDC,
  Currency.BUSD,
  Currency.DOGE,
  Currency.MATIC,
  Currency.SOL,
  Currency.BNB,
  Currency.SHIB,
];

export const droppedCurrencies = [Currency.BUSD];

export const selectableCurrencies = currenciesOrder.filter(
  currency => !droppedCurrencies.includes(currency),
);

export const currencyNameRecord: Record<Currency, string> = {
  [Currency.BTC]: 'Bitcoin',
  [Currency.ETH]: 'Ethereum',
  [Currency.LTC]: 'Litecoin',
  [Currency.USDT]: 'Tether',
  [Currency.USDC]: 'USD Coin',
  [Currency.TRX]: 'Tron',
  [Currency.MATIC]: 'Polygon',
  [Currency.XRP]: 'Ripple',
  [Currency.SOL]: 'Solana',
  [Currency.DOGE]: 'Dogecoin',
  [Currency.BNB]: 'Binance Coin',
  [Currency.BUSD]: 'Binance USD',
  [Currency.SHIB]: 'Shiba Inu',
};

const TX_LINK_PREFIXES_TESTNET: Record<Chain, string> = {
  [Chain.ETHEREUM]: 'https://goerli.etherscan.io/tx/{txId}',
  [Chain.BITCOIN]: 'https://www.blockchain.com/btc-testnet/tx/{txId}',
  [Chain.LITECOIN]: 'https://blockexplorer.one/litecoin/testnet/tx/{txId}',
  [Chain.TRON]: 'https://shasta.tronscan.org/#/transaction/{txId}',
  [Chain.MATIC_POLYGON]: 'https://polygonscan.com/tx/{txId}',
  [Chain.XRP]: 'https://testnet.xrpl.org/transactions/{txId}',
  [Chain.SOLANA]: 'https://explorer.solana.com/tx/{txId}?cluster=devnet',
  [Chain.DOGE]: 'https://blockexplorer.one/dogecoin/testnet/tx/{txId}',
  [Chain.BINANCE_SMART_CHAIN]: 'https://testnet.bscscan.com/tx/{txId}',
};

const TX_LINK_PREFIXES_MAINNET: Record<Chain, string> = {
  [Chain.ETHEREUM]: 'https://etherscan.io/tx/{txId}',
  [Chain.BITCOIN]: 'https://www.blockchain.com/btc/tx/{txId}',
  [Chain.LITECOIN]: 'https://blockexplorer.one/litecoin/mainnet/tx/{txId}',
  [Chain.TRON]: 'https://tronscan.org/#/transaction/{txId}',
  [Chain.MATIC_POLYGON]: 'https://polygonscan.com/tx/{txId}',
  [Chain.XRP]: 'https://xrpscan.com/tx/{txId}',
  [Chain.SOLANA]: 'https://explorer.solana.com/tx/{txId}',
  [Chain.DOGE]: 'https://blockexplorer.one/dogecoin/mainnet/tx/{txId}',
  [Chain.BINANCE_SMART_CHAIN]: 'https://bscscan.com/tx/{txId}',
};

const TX_LINK_PREFIXES =
  process.env.NEXT_PUBLIC_ENV === 'prod' ? TX_LINK_PREFIXES_MAINNET : TX_LINK_PREFIXES_TESTNET;

/**
 * Get the URL for a given transaction ID on a given chain.
 * Bitcoin  BTC   -> `blockchain.com`
 * Solana   SPL   -> `solscan.io`
 * Ethereum ERC20 -> `etherscan.io`
 *
 * Note - 2 August 2022
 * This function will need to be updated as we add more chains or currencies.
 */
export const getExternalTxLinkOfChain = (txId: string, chain: Chain) => {
  const url = TX_LINK_PREFIXES[chain];

  return url.replace('{txId}', txId);
};

function createOption(currency: Currency, name?: string): SelectOption<Currency> {
  return {
    label: name ? `${name} (${currency})` : currency,
    value: currency,
    icon: <CryptoIcon currency={currency} />,
  };
}

export const currencyOptions: SelectOption<Currency>[] = selectableCurrencies.map(currency =>
  createOption(currency as Currency, currencyNameRecord[currency]),
);

export const currencyOptionsShortenName: SelectOption<Currency>[] = selectableCurrencies.map(
  currency => createOption(currency as Currency),
);

export const fiatCurrencyOptions: SelectOption<FiatCurrency>[] = Object.keys(FiatCurrency).map(
  fiat => {
    return {
      label: fiat as FiatCurrency,
      value: fiat as FiatCurrency,
      icon: <FiatIcon currency={fiat as FiatCurrency} />,
    };
  },
);
