import { useDispatch } from 'react-redux';
import { client, clientWithoutAuth } from 'apollo/clients';
import { useGetChallengeCountLazyQuery, usePendingVipRewardsLazyQuery } from 'generated/graphql';
import {
  RewardEvent,
  TipType,
  useDepositCompletedSubscription,
  useDepositPendingSubscription,
  useRewardUpdatedSubscription,
  useTipReceivedSubscription,
  useVipRewardsUpdatedSubscription,
  useWithdrawalCompletedSubscription,
} from 'generated/subscription';
import { useTranslation } from 'next-i18next';
import { addAlert, AlertsEnum } from 'redux/slices/alertsSlice';

type Props = {
  isLoggedIn: boolean;
};

export const useGlobalSubscriptionAlerts = ({ isLoggedIn }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const messageAlert = (message: string) => {
    dispatch(
      addAlert({
        message,
        type: AlertsEnum.Success,
      }),
    );
  };

  useTipReceivedSubscription({
    skip: !isLoggedIn,
    onData({ data }) {
      const tipReceived = data?.data?.tipReceived;

      if (tipReceived) {
        const transKey =
          tipReceived.tipType === TipType.TIP_RAIN ? 'alertRainReceived' : 'alertTipReceived';

        messageAlert(
          t(transKey, {
            sender: tipReceived.senderUsername,
            amount: tipReceived.amount,
            currency: tipReceived.currency,
          }),
        );
      }
    },
  });

  useWithdrawalCompletedSubscription({
    skip: !isLoggedIn,
    onData({ data }) {
      const withdrawal = data?.data?.withdrawalCompleted;

      if (withdrawal) {
        messageAlert(
          t('alertWithdrawalCompleted', {
            amount: withdrawal.amount,
            currency: withdrawal.currency,
          }),
        );
      }
    },
  });

  useDepositPendingSubscription({
    skip: !isLoggedIn,
    onData({ data }) {
      const depositPending = data?.data?.depositPending;

      if (depositPending) {
        messageAlert(
          t('alertDepositPending', {
            amount: depositPending.amount,
            currency: depositPending.currency,
          }),
        );
      }
    },
  });

  useDepositCompletedSubscription({
    skip: !isLoggedIn,
    onData({ data }) {
      const depositCompleted = data?.data?.depositCompleted;

      if (depositCompleted) {
        messageAlert(
          t('alertDepositCompleted', {
            amount: depositCompleted.amount,
            currency: depositCompleted.currency,
          }),
        );
      }
    },
  });

  const [fetchVipRewards] = usePendingVipRewardsLazyQuery({
    variables: { skipRaceReward: true },
    fetchPolicy: 'network-only',
  });

  const [fetchChallengeCount] = useGetChallengeCountLazyQuery({
    client: isLoggedIn ? client : clientWithoutAuth,
    fetchPolicy: 'network-only',
  });

  /**
   * Force a refresh of the vip rewards when a reward is updated. This triggers the backend to update the cache
   * First user that triggers the cache on page load may not see the updated rewards as the data might have been pushed by the BE before this subscription is started
   * They will see the updated rewards on the next refresh, and others will see the updated rewards immediately as their subscriptions will refresh the query
   */
  useVipRewardsUpdatedSubscription({
    skip: !isLoggedIn,
    onData({ data }) {
      if (data.data?.vipRewardsUpdated) {
        fetchVipRewards();
      }
    },
  });

  useRewardUpdatedSubscription({
    skip: !isLoggedIn,
    async onData({ data }) {
      if (data.data?.rewardUpdated?.rewardEvent === RewardEvent.CHALLENGE_REWARD) {
        const rewards = await fetchVipRewards();
        const challengesCount = rewards.data?.challengeRewards?.length || 0;
        const lastestReward = rewards.data?.challengeRewards?.[challengesCount - 1];
        const game = lastestReward?.challenge?.game?.name || '';
        messageAlert(
          t('alertChallengeCompleted', {
            game,
          }),
        );
        fetchChallengeCount();
      }
    },
  });

  return null;
};
