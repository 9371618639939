import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gql } from '@apollo/client';
import cache from 'apollo/cache';
import dayjs from 'dayjs';
import {
  SportsMarket,
  SportsMarketStatus,
  SportsMatchPhase,
  SportsMatchStateFieldsFragmentDoc,
} from 'generated/graphql';
import {
  SportsMarketSelectionUpdatedPayload,
  useSportsFixtureUpdatedSubscription,
  useSportsMarketSelectionUpdatedSubscription,
  useSportsMarketUpdatedSubscription,
  useSportsMatchSummaryUpdatedSubscription,
} from 'generated/subscription';
import uniq from 'lodash/uniq';
import { OddsFormat } from 'redux/slices/browserPreferenceSlice';
import {
  BetSlipItem,
  setBetSlip,
  setSportsPagesParams,
  updateBetSlipOddsChangesIds,
} from 'redux/slices/sportsBetSlice';
import { calculateEstimatePayout } from 'utils/calculateSportsBet';
import { getSportBetOdds } from 'utils/getSportBetOdds';
import { isSportEventClosed } from 'utils/sportEventStatusCheck';

import { useIsSport } from './useIsSport';

export const useSportsPagesSubscriptions = () => {
  const { isSportPage, isSportEventPage } = useIsSport();
  const { sportsPages, betSlips } = useSelector((state: AppState) => state.sportsBet);
  const dispatch = useDispatch();
  const { fixtureIds } = sportsPages;

  const fixtureIdsFromSportsPages = fixtureIds.map(item => item.id);

  const fixtureIdsFromBetSlipWithStartTime = betSlips
    .filter(item => item.marketStatus !== SportsMarketStatus.RESULTED)
    .map(bet => {
      return {
        id: bet.fixtureId,
        startTime: bet.startTime,
      };
    });

  const fixtureIdsFromBetSlip = fixtureIdsFromBetSlipWithStartTime.map(item => item.id);
  const fixtureIdsForSubscriptions = uniq([...fixtureIdsFromSportsPages, ...fixtureIdsFromBetSlip]);
  const fixtureIdsForMatchStateSubscriptions = uniq(
    [...fixtureIds, ...fixtureIdsFromBetSlipWithStartTime]
      .filter(item => dayjs(item.startTime).isBefore(dayjs()))
      .map(fixture => fixture.id),
  );

  // Reset sports pages params if not in sports pages
  useEffect(() => {
    if (!isSportPage) {
      dispatch(
        setSportsPagesParams({
          categoryIds: [],
          competitionIds: [],
          fixtureIds: [],
        }),
      );
    }
  }, [dispatch, isSportPage]);

  const shouldSkipSubscription = !fixtureIdsForSubscriptions.length;
  const shouldSkipMatchStateSubscription = !fixtureIdsForMatchStateSubscriptions.length;

  function handleBetSlipUpdate(params: {
    betSlip: BetSlipItem;
    marketStatus: SportsMarketStatus;
    oddsNumerator: string;
    oddsDenominator: string;
  }) {
    const { betSlip, marketStatus, oddsNumerator, oddsDenominator } = params;
    const { outputDecimal } = getSportBetOdds({
      oddsNumerator,
      oddsDenominator,
      oddsFormat: OddsFormat.Decimal,
    });

    let estPayout = '';
    if (betSlip.estPayout) {
      estPayout = calculateEstimatePayout({
        betAmount: betSlip.betAmount,
        oddsNumerator,
        oddsDenominator,
      });
    }

    const updatedMarketStatus = marketStatus ? marketStatus : betSlip.marketStatus;
    // If market status is open but odds is 1, then market status should be suspended
    const invalidOdds =
      updatedMarketStatus === SportsMarketStatus.OPEN && outputDecimal.isEqualTo(1);

    if (betSlip.oddsNumerator !== oddsNumerator || betSlip.oddsDenominator !== oddsDenominator) {
      dispatch(updateBetSlipOddsChangesIds({ id: betSlip.id }));
      return {
        ...betSlip,
        oddsNumerator,
        oddsDenominator,
        estPayout,
        marketStatus: invalidOdds ? SportsMarketStatus.SUSPENDED : updatedMarketStatus,
      };
    }

    return {
      ...betSlip,
      marketStatus: invalidOdds ? SportsMarketStatus.SUSPENDED : updatedMarketStatus,
    };
  }

  function updateBetSlipsWithNewOdds(params: {
    marketStatus: SportsMarketStatus;
    marketSelectionUpdates: SportsMarketSelectionUpdatedPayload;
  }) {
    const { marketStatus, marketSelectionUpdates } = params;
    const { id, oddsNumerator, oddsDenominator } = marketSelectionUpdates;

    const betSlipsUpdateWithNewOdds = betSlips.map(betSlip => {
      if (betSlip.id === id) {
        return handleBetSlipUpdate({ betSlip, marketStatus, oddsNumerator, oddsDenominator });
      }
      return betSlip;
    });

    const updatedBetSlips = betSlipsUpdateWithNewOdds.filter(Boolean);
    if (updatedBetSlips.length > 0) {
      dispatch(setBetSlip({ betSlips: updatedBetSlips }));
    }
  }

  function updateSportsMarketCache(parmas: {
    marketStatus: SportsMarketStatus;
    marketSelectionUpdates: SportsMarketSelectionUpdatedPayload;
  }) {
    const { marketStatus, marketSelectionUpdates } = parmas;
    const { id, marketId, oddsNumerator, oddsDenominator } = marketSelectionUpdates;

    cache.modify({
      id: `SportsMarketSelection:${id}`,
      fields: {
        oddsNumerator() {
          return oddsNumerator;
        },
        oddsDenominator() {
          return oddsDenominator;
        },
      },
    });

    cache.modify({
      id: `SportsMarket:${marketId}`,
      fields: {
        status(cachedStatus) {
          return marketStatus ? marketStatus : cachedStatus;
        },
      },
    });
  }

  useSportsMarketSelectionUpdatedSubscription({
    skip: shouldSkipSubscription,
    variables: {
      fixtureIds: fixtureIdsForSubscriptions,
    },
    onData: ({ data: dataChanges }) => {
      const marketSelectionUpdates = dataChanges?.data?.sportsMarketSelectionUpdated;
      if (marketSelectionUpdates) {
        const sportsMarket = cache.readFragment({
          id: `SportsMarket:${marketSelectionUpdates?.marketId}`,
          fragmentName: 'SportsMarketBody',
          fragment: gql`
            fragment SportsMarketBody on SportsMarket {
              status
            }
          `,
        }) as SportsMarket;

        const marketStatus = sportsMarket?.status;
        updateBetSlipsWithNewOdds({ marketSelectionUpdates, marketStatus });
        updateSportsMarketCache({ marketSelectionUpdates, marketStatus });
      }
    },
  });

  useSportsMarketUpdatedSubscription({
    skip: shouldSkipSubscription,
    variables: {
      fixtureIds: fixtureIdsForSubscriptions,
    },
    onData: ({ data: dataChanges }) => {
      const marketUpdates = dataChanges?.data?.sportsMarketUpdated;
      if (marketUpdates) {
        const isDisabled = marketUpdates.disabled;
        let newMarketStatus = marketUpdates.marketStatus;
        if (isDisabled && newMarketStatus !== SportsMarketStatus.CLOSED) {
          newMarketStatus = SportsMarketStatus.CLOSED;
        }

        // update bet slip data
        const betSlipsUpdateWithNewMarketStatus = betSlips.map(betSlip => {
          if (betSlip.marketId === marketUpdates.id && betSlip.marketStatus !== newMarketStatus) {
            return {
              ...betSlip,
              marketStatus: newMarketStatus,
            };
          }
          return betSlip;
        });

        const updatedBetSlips = betSlipsUpdateWithNewMarketStatus.filter(Boolean);
        if (updatedBetSlips.length > 0) {
          dispatch(setBetSlip({ betSlips: updatedBetSlips }));
        }

        // update cache sports pages
        cache.modify({
          id: `SportsMarket:${marketUpdates?.id}`,
          fields: {
            status(cachedStatus) {
              if (cachedStatus !== newMarketStatus) {
                return newMarketStatus;
              }
              return cachedStatus;
            },
            inPlay() {
              return marketUpdates?.inPlay;
            },
            disabled() {
              return marketUpdates?.disabled;
            },
            cashoutStatus() {
              return marketUpdates?.cashoutStatus;
            },
            cashoutEnabled() {
              return marketUpdates?.cashoutEnabled;
            },
          },
        });
      }
    },
  });

  useSportsFixtureUpdatedSubscription({
    skip: shouldSkipSubscription,
    variables: {
      fixtureIds: fixtureIdsForSubscriptions,
    },
    onData: ({ data }) => {
      const fixtureUpdates = data?.data?.sportsFixtureUpdated;

      if (fixtureUpdates) {
        const isDisabled = fixtureUpdates.disabled;
        const newMarketStatus = isDisabled ? SportsMarketStatus.CLOSED : SportsMarketStatus.OPEN;

        // update bet slip data
        const betSlipsUpdateWithNewMarketStatus = betSlips.map(betSlip => {
          if (
            betSlip.fixtureId === fixtureUpdates?.id &&
            betSlip.marketStatus !== newMarketStatus
          ) {
            return {
              ...betSlip,
              marketStatus: newMarketStatus,
            };
          }
          return betSlip;
        });

        const updatedBetSlips = betSlipsUpdateWithNewMarketStatus.filter(Boolean);
        if (updatedBetSlips.length > 0) {
          dispatch(setBetSlip({ betSlips: updatedBetSlips }));
        }

        // update cache sports pages
        const fragment = isSportEventPage
          ? gql`
              fragment SportsFixtureMarketsGroupsBody on SportsFixture {
                markets {
                  status
                }
              }
            `
          : gql`
              fragment SportsFixtureWithDefaultMarketsBody on SportsFixture {
                markets {
                  status
                }
              }
            `;

        cache.writeFragment({
          id: `SportsFixture:${fixtureUpdates?.id}`,
          fragment,
          data: {
            markets: {
              status: newMarketStatus,
            },
          },
        });
      }
    },
  });

  useSportsMatchSummaryUpdatedSubscription({
    skip: shouldSkipMatchStateSubscription,
    variables: {
      fixtureIds: fixtureIdsForMatchStateSubscriptions,
    },
    onData: ({ data }) => {
      const matchSummaryUpdates = data?.data?.sportsMatchSummaryUpdated;
      if (matchSummaryUpdates) {
        const isEventClosed = isSportEventClosed({
          matchPhase: matchSummaryUpdates?.matchPhase as SportsMatchPhase,
        });

        // update bet slip data when has event closed should change market status to closed
        if (isEventClosed) {
          const betSlipsUpdateWithNewMarketStatus = betSlips.map(betSlip => {
            if (betSlip.fixtureId === matchSummaryUpdates?.fixtureId) {
              return {
                ...betSlip,
                marketStatus: SportsMarketStatus.CLOSED,
              };
            }
            return betSlip;
          });

          const updatedBetSlips = betSlipsUpdateWithNewMarketStatus.filter(
            updatedBetSlip => updatedBetSlip,
          );
          if (updatedBetSlips.length > 0) {
            dispatch(setBetSlip({ betSlips: updatedBetSlips }));
          }
        }

        cache.updateFragment(
          {
            id: `SportsMatchState:${matchSummaryUpdates.id}`,
            fragment: SportsMatchStateFieldsFragmentDoc,
            fragmentName: 'SportsMatchStateFields',
          },
          data => {
            return {
              ...data,
              matchSummary: {
                ...data?.matchSummary,
                awayScore: matchSummaryUpdates?.awayScore,
                homeScore: matchSummaryUpdates?.homeScore,
                matchPhase: matchSummaryUpdates?.matchPhase,
                currentPhaseName: matchSummaryUpdates?.currentPhaseName,
                phases: matchSummaryUpdates?.phases,
                server: matchSummaryUpdates.server,
                timeRemaining: matchSummaryUpdates?.timeRemaining,
                timeRemainingAt: matchSummaryUpdates?.timeRemainingAt,
              },
            };
          },
        );
      }
    },
  });
};
