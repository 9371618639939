import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import pickBy from 'lodash/pickBy';
import { useRouter } from 'next/router';
import {
  AuthModalTabType,
  AuthModalType,
  closeModal as closeModalType,
  initialProvablyFairData,
  ModalProps,
  ModalTypes,
  openEmailModal,
  openGeneralModal as openGeneralModalTrigger,
  openModal as openModalTrigger,
  openPasswordModal,
  openResultsModal as openResultsModalTrigger,
  openSportResultsModal as openSportResultsModalTrigger,
  PasswordModalProps,
  ProvablyFairProps,
  ProvablyFairTabType,
  setProvablyFairData,
  WalletModalTabType,
} from 'redux/slices/modalSlice';

import { openGlobalModal } from '../components/Modal/openGlobalModal';

import { useIsLoggedIn } from './auth/useIsLoggedIn';
import { CAMPAIGN_QUERY_KEY } from './useCampaignCode';
import useQueryParams, {
  createModalDataKey,
  MODAL_DATA_PREFIX,
  ModalQueryKey,
} from './useQueryParams';

export enum ModalUrl {
  AUTH = 'auth',
  BET = 'bet',
  SPORT_BET = 'sportBet',
  USER = 'user',
  WALLET = 'wallet',
  REDEEM = 'redeem',
  PROMO = 'c',
  CHALLENGE_PRIZE = 'challengePrize',
  VAULT = 'vault',
  SPORT_BET_CASH_OUT = 'sportBetCashOut',
  SPORT_BET_CASH_OUT_SUCCESS = 'sportBetCashOutSuccess',
  SPORT_BET_CASH_OUT_TRY_AGAIN = 'sportBetCashOutTryAgain',
  SPORT_BET_CASH_OUT_FAILED = 'sportBetCashOutFailed',
}

export const useModal = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isLoggedIn = useIsLoggedIn();
  const { addQueryParams, removeModalParams, buildModalParams } = useQueryParams();

  const openModal = useCallback(
    (modalType: ModalTypes, props?: ModalProps) => {
      dispatch(openModalTrigger({ modalType, props }));
    },
    [dispatch],
  );

  const openAuthenticationModal = useCallback(
    (authModalType: AuthModalType) =>
      openGlobalModal({
        type: 'auth',
        router,
        tab:
          authModalType === AuthModalType.LoginAndRegister ? AuthModalTabType.LOGIN : authModalType,
      }),
    [router],
  );

  const openGeneralModal = useCallback(
    (props: ModalProps) => {
      dispatch(openGeneralModalTrigger({ props }));
    },
    [dispatch],
  );

  // Resets the provably fair modal state if no param is provided
  const openProvablyFairModal = useCallback(
    (props: Partial<ProvablyFairProps> = initialProvablyFairData) => {
      dispatch(setProvablyFairData(props));
      dispatch(openModalTrigger({ modalType: ModalTypes.PROVABLY_FAIR }));
    },
    [dispatch],
  );

  const openResultsModal = useCallback(
    (betId: string) => {
      dispatch(openResultsModalTrigger({ betId }));
    },
    [dispatch],
  );

  const openSportResultsModal = useCallback(
    (betId: string) => {
      dispatch(openSportResultsModalTrigger({ betId }));
    },
    [dispatch],
  );

  const setProvablyFairModalTab = useCallback(
    (tab: ProvablyFairTabType) => {
      dispatch(setProvablyFairData({ tab }));
    },
    [dispatch],
  );

  const closeModal = useCallback(
    (removeParams = true) => {
      if (removeParams) {
        removeModalParams();
      }
      dispatch(closeModalType());
    },
    [dispatch, removeModalParams],
  );

  const openEmailVerificationModal = useCallback(() => {
    dispatch(openEmailModal());
  }, [dispatch]);

  const openPWModal = useCallback(
    (passwordModalType: PasswordModalProps) => {
      closeModal();
      setTimeout(() => {
        dispatch(openPasswordModal(passwordModalType));
      }, 100);
    },
    [dispatch, closeModal],
  );

  const { modal, ...data } = useModalData();
  const pathname = router?.pathname;
  const resetToken = router.query.token;

  const crashId = data['crash-game-id'];

  const openModalByUrl = useCallback(() => {
    if (!modal) {
      if (pathname === '/404') {
        // Dont do anything if page not found
        return null;
      }

      if (Object.keys(router.query).includes(CAMPAIGN_QUERY_KEY)) {
        // The campaign code is handled in the useCampaignCode hook and will be filled into the campaign code field of the register form
        if (!isLoggedIn) {
          router.replace(
            buildModalParams(ModalUrl.AUTH, { tab: AuthModalTabType.REGISTER }, { override: true }),
          );
        }
      }

      // Open promo redemption modal
      if (
        Object.keys(router.query).includes(ModalUrl.PROMO) &&
        router.query[ModalUrl.PROMO]?.length
      ) {
        openModal(ModalTypes.PROMO_REDEEM);
      }

      if (resetToken && router.pathname.includes('reset-password')) {
        if (isLoggedIn) {
          router.push(`/settings/security?token=${resetToken}`);
          openModal(ModalTypes.CREATE_NEW_PASSWORD);
        } else {
          openAuthenticationModal(AuthModalType.ResetPassword);
        }
      }

      return;
    }

    switch (modal) {
      case ModalUrl.AUTH:
        if (!isLoggedIn) {
          if (
            (data.tab && data.tab === AuthModalTabType.LOGIN) ||
            data.tab === AuthModalTabType.REGISTER
          ) {
            openModal(ModalTypes.AUTHENTICATION);
          }
        }
        break;
      case ModalUrl.WALLET:
        if (Object.values(WalletModalTabType).includes(data.tab as WalletModalTabType)) {
          openModal(ModalTypes.WALLET);
        }
        break;
      case ModalUrl.SPORT_BET:
        if (data.id) {
          openSportResultsModal(data.id);
        }
        break;
      case ModalUrl.BET:
        if (crashId) {
          openModal(ModalTypes.CRASH_PUBLIC_GAME_RESULT);
        } else if (data.id) {
          openResultsModal(data.id);
        }
        break;
      case ModalUrl.REDEEM:
        openModal(ModalTypes.REDEEM_CODE_LOOKUP);
        break;
      case ModalUrl.CHALLENGE_PRIZE:
      case ModalUrl.SPORT_BET_CASH_OUT:
      case ModalUrl.SPORT_BET_CASH_OUT_SUCCESS:
      case ModalUrl.SPORT_BET_CASH_OUT_TRY_AGAIN:
      case ModalUrl.SPORT_BET_CASH_OUT_FAILED:
      case ModalUrl.USER:
      case ModalUrl.VAULT:
        break;
      // Remove all the queries that are involved in modal query props
      default: {
        removeModalParams();
        break;
      }
    }
  }, [
    modal,
    pathname,
    router,
    resetToken,
    isLoggedIn,
    buildModalParams,
    openModal,
    openAuthenticationModal,
    data.tab,
    data.id,
    crashId,
    openResultsModal,
    openSportResultsModal,
    removeModalParams,
  ]);

  const openModalWithSearchParams = useCallback(
    (modal: ModalUrl, params: Record<string, string>) => {
      // We prefix each parameter with `md-` to comply with our
      // modal data query param spec. See useModalData for more.
      const prefixed: Record<ModalQueryKey, string> = {};
      Object.entries(params).forEach(([key, value]) => (prefixed[createModalDataKey(key)] = value));

      addQueryParams({
        modal,
        ...prefixed,
      });
    },
    [addQueryParams],
  );

  return {
    openModal,
    openPWModal,
    openGeneralModal,
    closeModal,
    openModalByUrl,
    openAuthenticationModal,
    openModalWithSearchParams,
    openProvablyFairModal,
    openResultsModal,
    setProvablyFairModalTab,
    openEmailVerificationModal,
  };
};

/**
 * Extract the data form the URL query corresponding to modals.
 * For example, `?modal=auth&md-bet-id=33`
 * will yield:  modal: auth, bet-id: 33
 */
export const useModalData = (): UseModalDataType => {
  const router = useRouter();
  const modal = useMemo(
    () => (router.query.modal ? String(router.query.modal) : undefined),
    [router.query],
  );

  const data = useMemo(() => {
    const entries = pickBy(router.query, (_, key) => key.startsWith(MODAL_DATA_PREFIX));

    // Remove prefixes from each entry...
    const params: Record<string, string> = {};
    Object.entries(entries).forEach(
      ([key, value]) => (params[key.replace(MODAL_DATA_PREFIX, '')] = String(value)),
    );

    return params;
  }, [router.query]);

  return { modal, ...data };
};

type UseModalDataType = { modal: string | undefined } & Record<string, string | undefined>;

export default useModal;
