import { configureStore, Middleware } from '@reduxjs/toolkit';

import {
  PERSIST_STORE_NAME,
  PERSIST_STORE_NAMES_METHODS,
  VERSION,
} from '../hooks/usePersistStoreData';

import rootReducer from './reducer';

const persistStorageMiddleware: Middleware = ({ getState }) => {
  return next => action => {
    const returnValue = next(action);

    if (action.type) {
      try {
        const shouldSaveStorage = !!PERSIST_STORE_NAMES_METHODS.find(({ name: storeName }) =>
          action.type.startsWith(storeName),
        );
        const store = getState();

        if (shouldSaveStorage) {
          const toBeSavedStoreData: Record<string, string> = {
            _persist: `{"version":${VERSION}}`,
          };

          for (const key in store) {
            if (PERSIST_STORE_NAMES_METHODS.find(({ name }) => name.includes(key))) {
              toBeSavedStoreData[key] = JSON.stringify(store[key]);
            }
          }
          window.localStorage.setItem(PERSIST_STORE_NAME, JSON.stringify(toBeSavedStoreData));
        }
      } catch (e) {
        console.error(`Failed to update storage ${(e as Error)?.message ?? ''}`);
      }
    }

    return returnValue;
  };
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['modal/openGeneralModal'],
        ignoredPaths: ['modal.modalProps'],
      },
    }).prepend(persistStorageMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
