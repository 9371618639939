import CookieBanner from './CookieBanner';
import { BannerManagerWrapper } from './styles';
import VersionBanner from './VersionBanner';

const BannerManager = () => {
  return (
    <BannerManagerWrapper>
      <CookieBanner />
      <VersionBanner />
    </BannerManagerWrapper>
  );
};

export default BannerManager;
