import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const ThreeDots = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 6.6665C2.6 6.6665 2 7.2665 2 7.99984C2 8.73317 2.6 9.33317 3.33333 9.33317C4.06667 9.33317 4.66667 8.73317 4.66667 7.99984C4.66667 7.2665 4.06667 6.6665 3.33333 6.6665Z"
        fill={color}
      />
      <path
        d="M12.6693 6.6665C11.9359 6.6665 11.3359 7.2665 11.3359 7.99984C11.3359 8.73317 11.9359 9.33317 12.6693 9.33317C13.4026 9.33317 14.0026 8.73317 14.0026 7.99984C14.0026 7.2665 13.4026 6.6665 12.6693 6.6665Z"
        fill={color}
      />
      <path
        d="M7.9974 6.6665C7.26406 6.6665 6.66406 7.2665 6.66406 7.99984C6.66406 8.73317 7.26406 9.33317 7.9974 9.33317C8.73073 9.33317 9.33073 8.73317 9.33073 7.99984C9.33073 7.2665 8.73073 6.6665 7.9974 6.6665Z"
        fill={color}
      />
    </svg>
  );
};

export default ThreeDots;
