import React from 'react';
import { SvgProps } from 'assets/svgs';
import {
  AmericanFootball,
  Baseball,
  Basketball,
  Boxing,
  Esports,
  Golf,
  IceHockey,
  MMA,
  RugbyUnion,
  Soccer,
  TableTennis,
  Tennis,
} from 'assets/svgs/sports/24px';
import { Sports } from 'generated/graphql';

const mapping = new Map<string, JSX.Element>();
mapping.set(Sports.AMERICAN_FOOTBALL, <AmericanFootball />);
mapping.set(Sports.BASKETBALL, <Basketball />);
mapping.set(Sports.ICE_HOCKEY, <IceHockey />);
mapping.set(Sports.SOCCER, <Soccer />);
mapping.set(Sports.TENNIS, <Tennis />);
mapping.set(Sports.BASEBALL, <Baseball />);
mapping.set(Sports.MMA, <MMA />);
mapping.set(Sports.BOXING, <Boxing />);
mapping.set(Sports.TABLE_TENNIS, <TableTennis />);
mapping.set(Sports.GOLF, <Golf />);
mapping.set(Sports.RUGBY_UNION, <RugbyUnion />);
mapping.set(Sports.ESPORTS, <Esports />);

const SportIcon = ({
  sport,
  ...rest
}: Partial<SvgProps> & {
  sport: string;
}) => {
  const mapped = mapping.get(sport);
  return mapped ? React.cloneElement(mapped, rest) : <Soccer {...rest} />;
};

export default React.memo(SportIcon);
