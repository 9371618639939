import colors from 'assets/styles/colors';
import UI from 'constants/ui';
import styled from 'styled-components';

const scale = 1;
const SWITCH_HEIGHT_PX = scale * 24;
const SWITCH_WIDTH_PX = scale * 42;
const KNOB_SIZE_PX = scale * 14;
const SWITCH_PAD = scale * 5;

const INNER_DISTANCE = SWITCH_WIDTH_PX - SWITCH_PAD * 2;
const TRANSLATION_DISTANCE = INNER_DISTANCE - KNOB_SIZE_PX;

export const SwitchWrapper = styled.button<{ $labelFloat?: 'left' | 'right'; $disabled: boolean }>`
  display: flex;
  background: none;
  border: none;
  align-items: center;
  gap: ${UI.spacing.sm};
  flex-direction: ${({ $labelFloat }) => ($labelFloat === 'right' ? 'row' : 'row-reverse')};
  justify-content: ${({ $labelFloat }) => ($labelFloat === 'right' ? 'flex-start' : 'flex-end')};
  color: inherit;
  opacity: ${({ $disabled }) => ($disabled ? 0.6 : 1)};
`;

interface SwitchElementProps {
  $checked: boolean;
  $dark: boolean;
  $disabled: boolean;
}

const getSwitchElementBgColor = ({ $checked, $dark }: SwitchElementProps) => {
  if ($checked) return colors.primaryViolet;
  if ($dark) return colors.gray900;
  return colors.gray800;
};

export const SwitchElement = styled.div<SwitchElementProps>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  height: ${SWITCH_HEIGHT_PX}px;
  width: ${SWITCH_WIDTH_PX}px;

  transition: background-color 200ms;
  background-color: ${getSwitchElementBgColor};
  border-radius: ${SWITCH_HEIGHT_PX}px;
`;

export const Knob = styled.div<{ $checked: boolean }>`
  position: absolute;
  left: ${SWITCH_PAD}px;

  transition: transform 200ms;
  transform: ${({ $checked }) =>
    $checked ? `translateX(${TRANSLATION_DISTANCE}px)` : 'translateX(0px)'};

  width: ${KNOB_SIZE_PX}px;
  height: ${KNOB_SIZE_PX}px;
  border-radius: ${UI.radius.full};
  background-color: ${colors.white};
`;

export const SwitchLabel = styled.p`
  font-size: ${UI.text.md};
`;
