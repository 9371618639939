import React from 'react';
import { useTranslation } from 'next-i18next';

import Tooltip from '../Tooltip';

import { Tab, TabList } from './styles';
import { TabItem, TabViewProps, TabWrapperProps } from './TabView.type';

import styles from './TabView.module.scss';

const TabView = ({
  tabs,
  activeTab,
  onClick,
  tooltipPlacement = 'bottom',
  wide = false,
  outline = false,
  className = '',
}: TabViewProps) => {
  const { t } = useTranslation();

  return (
    <TabList className={className} wide={wide} outline={outline}>
      {tabs.map(({ id, name, Icon, disabled, title }: TabItem) => {
        const active = activeTab === id;
        const icon = Icon ? React.cloneElement(Icon, { color: 'currentColor' }) : null;

        return (
          <TabWrapper title={title} key={id} tooltipPlacement={tooltipPlacement}>
            <Tab
              className={`${styles.tab} ${active ? styles.active : ''} ${
                disabled ? styles.disabled : ''
              }`}
              data-testid={id}
              key={id}
              id={id}
              wide={wide}
              outline={outline}
              hasIcon={!!Icon}
              disabled={disabled}
              isActive={active}
              onClick={e => onClick?.(e, id)}
            >
              {Icon && <span className={styles.icon}>{icon}</span>}
              <label>{t(name)}</label>
            </Tab>
          </TabWrapper>
        );
      })}
    </TabList>
  );
};

const TabWrapper = ({ children, title, tooltipPlacement }: TabWrapperProps) => {
  const { t } = useTranslation();

  return title ? (
    <Tooltip forceOnTop placement={tooltipPlacement}>
      <Tooltip.Trigger>{children}</Tooltip.Trigger>
      <Tooltip.Content>{t(title)}</Tooltip.Content>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export default TabView;
