import React from 'react';
import colors from 'assets/styles/colors';

interface TimesProps {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Times = ({ scale = 1, color = colors.white, viewBox = '0 0 12 12' }: TimesProps) => {
  const width = `${scale * 12}`;
  const height = `${scale * 12}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L11 11M11 1L1 11"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Times;
