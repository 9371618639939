import React from 'react';

import { Knob, SwitchElement, SwitchLabel, SwitchWrapper } from './styles';

export interface SwitchProps {
  /**
   * Is the switch initially checked?
   */
  value: boolean;

  /**
   * The label placed to the right of the switch.
   */
  label?: string | React.ReactNode;

  /**
   * Allow the position of the label and switch to be swapped.
   */
  labelFloat?: 'left' | 'right';

  /**
   * Callback; when the switch is toggled.
   */
  onToggle: (checked: boolean) => void;

  /**
   * When disabled, user interactivity will not register. Useful for loading states.
   */
  disabled?: boolean;

  /**
   * Use when the disabled switch needs to be darker to apppear against the background.
   */
  dark?: boolean;
  testId?: string;
}

const Switch = ({
  value,
  label,
  labelFloat = 'right',
  dark = false,
  onToggle,
  disabled = false,
  testId,
}: SwitchProps) => {
  const onClickToggle = () => {
    if (disabled) {
      return;
    }

    onToggle(!value);
  };

  return (
    <SwitchWrapper
      $labelFloat={labelFloat}
      $disabled={disabled}
      role="switch"
      type={'button'}
      aria-checked={value ? 'true' : 'false'}
      data-testid={testId}
    >
      <SwitchElement
        $checked={value}
        $dark={dark}
        $disabled={disabled}
        onClick={onClickToggle}
        aria-hidden="true"
      >
        <Knob $checked={value} />
      </SwitchElement>

      {label && <SwitchLabel onClick={onClickToggle}>{label}</SwitchLabel>}
    </SwitchWrapper>
  );
};

export default Switch;
