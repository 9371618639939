import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Coupon = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3269_121938)">
        <path
          d="M13.9231 1H2.07692C1.48246 1 1 1.41813 1 1.93333V15L3.69231 13.1333L5.84615 15L8 13.1333L10.1538 15L12.3077 13.1333L15 15V1.93333C15 1.41813 14.5175 1 13.9231 1Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.23077 5.66666H11.7692"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.23077 9.39999H11.7692"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3269_121938">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Coupon;
