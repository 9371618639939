import React from 'react';

import { SvgProps } from '..';

const Baseball = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_3036_12160)">
        <path
          d="M12.5 10.5C13.6046 10.5 14.5 9.60457 14.5 8.5C14.5 7.39543 13.6046 6.5 12.5 6.5C11.3954 6.5 10.5 7.39543 10.5 8.5C10.5 9.60457 11.3954 10.5 12.5 10.5Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.268 5.26797L14.668 4.86797C15.1278 4.42703 15.4097 3.83263 15.4602 3.19758C15.5107 2.56254 15.3263 1.93105 14.942 1.42297C14.7213 1.15113 14.446 0.928668 14.1339 0.769995C13.8218 0.611323 13.4799 0.519979 13.1302 0.501878C12.7806 0.483778 12.431 0.539325 12.1042 0.664923C11.7773 0.79052 11.4805 0.983366 11.233 1.23097L6.643 5.82097C6.22852 6.23629 5.91005 6.73737 5.71 7.28897L4.859 9.64097L1.25 13.25"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.75 14.75L6.359 11.141L8.711 10.29C8.804 10.256 8.895 10.219 8.985 10.179"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M0.5 12.5L3.5 15.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3036_12160">
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Baseball;
