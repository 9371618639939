import React from 'react';

import { SvgProps } from '..';

const Slots = ({ scale = 1, color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 17}`;
  const height = `${scale * 16}`;

  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none">
      <path
        d="M1 2.54546V5.27273C1 6.4025 2.83136 7.31818 5.09091 7.31818C7.35045 7.31818 9.18182 6.4025 9.18182 5.27273V2.54546"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 5.27274V8.00001C1 9.12978 2.83136 10.0455 5.09091 10.0455C6.13886 10.0455 7.09409 9.84773 7.81818 9.52387"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 8V10.7273C1 11.857 2.83136 12.7727 5.09091 12.7727C6.13886 12.7727 7.09477 12.5757 7.81818 12.2518"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.09091 4.59091C7.35026 4.59091 9.18182 3.67513 9.18182 2.54545C9.18182 1.41578 7.35026 0.5 5.09091 0.5C2.83156 0.5 1 1.41578 1 2.54545C1 3.67513 2.83156 4.59091 5.09091 4.59091Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.81836 8V10.7273C7.81836 11.857 9.64972 12.7727 11.9093 12.7727C14.1688 12.7727 16.0002 11.857 16.0002 10.7273V8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.81836 10.7273V13.4545C7.81836 14.5843 9.64972 15.5 11.9093 15.5C14.1688 15.5 16.0002 14.5843 16.0002 13.4545V10.7273"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9093 10.0455C14.1686 10.0455 16.0002 9.12967 16.0002 8C16.0002 6.87033 14.1686 5.95454 11.9093 5.95454C9.64992 5.95454 7.81836 6.87033 7.81836 8C7.81836 9.12967 9.64992 10.0455 11.9093 10.0455Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Slots;
