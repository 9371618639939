import { Sports } from 'generated/graphql';

export const LIMIT_POPULAR_COMPETITIONS = 1;

export const POPULAR_SPORTS_LIST = [
  Sports.SOCCER,
  Sports.BASKETBALL,
  Sports.AMERICAN_FOOTBALL,
  Sports.BASEBALL,
  Sports.TENNIS,
  Sports.ICE_HOCKEY,
  Sports.MMA,
  Sports.BOXING,
  Sports.TABLE_TENNIS,
  Sports.GOLF,
  Sports.RUGBY_UNION,
  Sports.ESPORTS,
];

export const LIMIT_COMPETITIONS = 3;
export const LIMIT_COMPETITION_FOR_TOURNAMENT = 30;
export const LIMIT_COMPETITIONS_FOR_LIVE_EVENT = 10;
export const DEFAULT_NUMBER_OF_FIXTURES = 10;

export const UPCOMING_HOURS_12 = 12;
export const UPCOMING_HOURS_24 = 24;
export const UPCOMING_HOURS_48 = 48;
export const DEFAULT_UPCOMING_HOURS = UPCOMING_HOURS_48;

export enum SPORT_PAGE_TABS {
  FEATURES = 'features',
  UPCOMING = 'upcoming',
  BET_LIVE = 'bet-live',
  FAVOURITES = 'favourites',
  ALL = 'all',
}
