import { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import AlertsManager from 'components/Alerts/AlertsManager';
import GlobalSetup from 'components/GlobalSetup/GlobalSetup';
import BannerManager from 'components/InfoBanner/BannerManager';
import ModalManager from 'components/modals/ModalManager';
import Layout from 'layouts/Layout';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { DefaultTheme, ThemeProvider } from 'styled-components';

import { client as apolloClient } from '../apollo/clients';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { GlobalModal } from '../components/Modal/GlobalModal';
import GeoRestrictionModal from '../components/modals/GeoRestriction/GeoRestrictionModal';
import { RenderClient } from '../components/RenderClient';
import { SearchStateProps } from '../components/Search/search.type';
import useGameStatisticsWidget from '../hooks/games/useGameStatisticsWidget';
import useBreakpointMagic from '../hooks/interface/useBreakpointMagic';
import { useIsSport } from '../hooks/useIsSport';
import store from '../redux/store';

import { SPLASH_ID } from './_document';

import '../styles/global.scss';
import '../components/ErrorBoundary.css';
import '../assets/fonts/fonts.css';
import '../assets/styles/captcha.css';
import '../styles/variables.css';
import '../styles/splash.css';
import '../styles/mobileSheet.css';

const LiveGameStatsWidget = dynamic(
  () => import('../views/games/components/LiveGameStats/LiveGameStatsWidget'),
  { ssr: false },
);
const Search = dynamic(() => import('../components/Search/Search').then(mod => mod.Search));

const isProduction = process.env.NODE_ENV === 'production';

function LiveGameStatus() {
  const { isWidgetOpen } = useGameStatisticsWidget();

  return <>{isWidgetOpen && <LiveGameStatsWidget />}</>;
}

function ShuffleApp({ Component, pageProps }: AppProps) {
  const [isMounted, setIsMounted] = useState(false);
  const { isSportPage } = useIsSport();
  const [globalSearch, setGlobalSearch] = useState<SearchStateProps>({
    type: isSportPage ? 'sports' : 'casino',
    isOpen: false,
  });
  const breakpoints = useBreakpointMagic(store);

  useEffect(() => {
    setIsMounted(true);

    const splash = document.getElementById(SPLASH_ID);

    if (splash) {
      splash.style.display = 'none';
    }

    if (isProduction) {
      // eslint-disable-next-line no-console
      console.log('%cSTOP', 'color:red; font-size: 50px');
      // eslint-disable-next-line no-console
      console.log(
        'This is a developer feature, please beware that any activity here may cause you to lose your account.',
      );
    }
  }, []);

  return (
    <>
      <ErrorBoundary>
        <div
          style={
            globalSearch.isOpen
              ? {
                  transition: '0.3s opacity',
                  opacity: isMounted ? 1 : 0,
                }
              : undefined
          }
        >
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
            />
          </Head>
          <ReduxProvider store={store}>
            <ThemeProvider theme={{ breakpoints } as DefaultTheme}>
              <ApolloProvider client={apolloClient}>
                <Layout
                  casinoLayout={pageProps.casinoLayout}
                  activityBoard={pageProps.activityBoard}
                  sports={pageProps.sports}
                  setGlobalSearch={setGlobalSearch}
                >
                  <Component {...pageProps} />
                </Layout>
                <GlobalSetup />
                <ModalManager />
                <AlertsManager />
                <BannerManager />
                <RenderClient>
                  <GeoRestrictionModal />
                  <GlobalModal />
                  <LiveGameStatus />
                </RenderClient>
                {globalSearch.isOpen && (
                  <Search type={globalSearch.type} setGlobalSearch={setGlobalSearch} />
                )}
              </ApolloProvider>
            </ThemeProvider>
          </ReduxProvider>
        </div>
      </ErrorBoundary>
    </>
  );
}

export default appWithTranslation(ShuffleApp);
