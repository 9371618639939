export const transformCurrencyInput = (value: string) => {
  let filteredValue = value.replace(/[^0-9.]/g, '');

  if (filteredValue.includes('.')) {
    // Remove double dots
    const output = filteredValue.split('.');
    filteredValue = output.shift() + '.' + output.join('');
  }

  return filteredValue;
};

export const transformPercentageInput = (value: string) => {
  let filteredValue = value.replace(/[^-0-9.]/g, '');

  if (filteredValue.includes('.')) {
    // Remove double dots
    const output = filteredValue.split('.');
    filteredValue = output.shift() + '.' + output.join('');
  }

  // Remove negative sign if it's not the first character
  if (filteredValue.includes('-') && filteredValue.lastIndexOf('-') !== 0) {
    filteredValue = filteredValue[0].concat(filteredValue.slice(1).replace('-', ''));
  }

  return filteredValue;
};

/**
 * Coalesce template literals values from tag functions into a single string
 */
export const coalesceTemplateLiteral = (template: TemplateStringsArray, values: unknown[]) => {
  return template
    .map?.((_, index) => template[index] + (values[index] ? values[index] : ''))
    .reduce((previous, current) => `${previous}${current}`, '');
};
