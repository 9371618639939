import React from 'react';

import colors from '../styles/colors';

import { SvgProps } from '.';

interface Props extends SvgProps {
  iconColor?: string;
}

const Shuffle = ({
  scale = 1,
  color = '#ffffff',
  viewBox = '0 0 147 24',
  iconColor = colors.primaryViolet,
}: Props) => {
  const width = `${scale * 147}`;
  const height = `${scale * 24}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10546_125386)">
        <path
          d="M15.468 0H5.01174C3.6733 0 2.41338 0.531836 1.46757 1.49269C0.521765 2.45531 0 3.73349 0 5.09322V6.65327C0 8.73098 1.28609 10.5516 3.44121 11.5249L4.30151 11.883V6.7295C4.29802 6.70291 4.29628 6.67809 4.29628 6.6515V5.09145C4.29628 4.8273 4.4289 4.65534 4.50743 4.57556C4.58595 4.49579 4.75522 4.36106 5.01349 4.36106H15.4697C15.7297 4.36106 15.899 4.49402 15.9775 4.57556C16.0561 4.65534 16.1869 4.8273 16.1869 5.08967V8.51293H20.4815V5.09145C20.4815 3.73172 19.9597 2.45177 19.0139 1.49091C18.0646 0.530064 16.8064 0 15.468 0Z"
          fill={iconColor}
        />
        <path
          d="M17.0228 12.4662L16.18 12.1152V17.3042C16.1835 17.3166 16.1835 17.3308 16.1835 17.3432V18.9032C16.1835 19.1674 16.0526 19.3411 15.9723 19.4209C15.8938 19.5007 15.7245 19.6354 15.4662 19.6354H5.01174C4.61736 19.6354 4.29628 19.3074 4.29628 18.9086V15.4853H0V18.9086C0 21.7167 2.24761 24 5.01174 24H15.468C16.8064 23.9982 18.0663 23.4682 19.0122 22.5073C19.958 21.5447 20.4797 20.2647 20.4797 18.905V17.345C20.4797 15.2478 19.1884 13.4236 17.0228 12.468V12.4662Z"
          fill={iconColor}
        />
        <path
          d="M8.39358 13.8755C9.89431 15.4001 10.2398 17.3306 10.2398 17.3306C10.2398 17.3306 10.4649 16.0879 11.3287 14.8097C11.5416 14.4942 11.7912 14.1768 12.0878 13.8755C13.5885 12.3509 15.4889 11.9981 15.4889 11.9981C15.4889 11.9981 14.0545 11.7322 12.6916 10.6614C12.4874 10.5001 12.2833 10.3228 12.0861 10.1225C10.5853 8.59787 10.2381 6.66553 10.2381 6.66553C10.2381 6.66553 10.0165 7.90825 9.15267 9.18998C8.93977 9.50553 8.68849 9.82286 8.39183 10.1242C6.88936 11.6488 4.98901 11.9998 4.98901 11.9998C4.98901 11.9998 6.42518 12.2658 7.78805 13.3365C7.99222 13.4996 8.19639 13.6769 8.39358 13.8772V13.8755Z"
          fill={iconColor}
        />
        <path
          d="M38.4728 10.7289L33.1086 10.3141C31.4962 10.1918 30.8714 9.84964 30.8714 8.50587C30.8714 7.0646 31.5206 6.62494 33.3738 6.62494H37.7277C39.2668 6.62494 39.916 7.1621 40.0608 8.58033C40.0852 8.97212 40.2772 9.19194 40.6628 9.19194H42.8284C43.2141 9.19194 43.4305 8.97212 43.406 8.55728C43.2385 5.11098 41.5301 3.3772 37.7294 3.3772H33.3284C29.1439 3.3772 27.5803 5.18544 27.5803 8.4846C27.5803 11.7838 29.0479 12.9325 32.7526 13.225L38.1168 13.6399C39.7292 13.7622 40.354 14.1292 40.354 15.4977C40.354 16.8663 39.7048 17.3787 37.876 17.3787H33.1609C31.5974 17.3787 30.9482 16.8415 30.8034 15.4233C30.779 15.0315 30.587 14.8117 30.2013 14.8117H28.0602C27.6745 14.8117 27.4582 15.0315 27.4826 15.4463C27.6501 18.8926 29.3341 20.6264 33.1592 20.6264H37.8969C42.106 20.6264 43.6695 18.8182 43.6695 15.519C43.6695 12.2199 42.1304 11.0232 38.4746 10.7289H38.4728Z"
          fill={color}
        />
        <path
          d="M58.6786 10.4594H49.5608V3.37354H46.2173V20.6245H49.5608V13.466H58.6786V20.6245H62.0447V3.37354H58.6786V10.4594Z"
          fill={color}
        />
        <path
          d="M78.6174 14.8098C78.6174 16.9105 77.8949 17.375 76.2843 17.375H70.8729C69.2605 17.375 68.5154 16.9105 68.5154 14.8098V3.37354H65.1719V14.6875C65.1719 18.3288 66.8803 20.6245 70.8729 20.6245H76.2843C80.2525 20.6245 81.9853 18.327 81.9853 14.6875V3.37354H78.6174V14.8098Z"
          fill={color}
        />
        <path
          d="M85.1123 20.6245H88.4314V13.3916H95.5022V10.3867H88.4314V6.62305H99.0865V3.37354H85.1123V20.6245Z"
          fill={color}
        />
        <path
          d="M101.083 20.6245H104.402V13.3916H111.475V10.3867H104.402V6.62305H115.059V3.37354H101.083V20.6245Z"
          fill={color}
        />
        <path d="M120.397 3.37354H117.053V20.6245H130.837V17.375H120.397V3.37354Z" fill={color} />
        <path
          d="M147 6.62305V3.37354H132.64V20.6245H147V17.375H135.959V13.3916H143.3V10.3867H135.959V6.62305H147Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_10546_125386">
          <rect width="147" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Shuffle;
