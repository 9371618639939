import { pxToRem } from 'assets/styles/convertors';

// Exported here in order to defer MobileSheet lazy import on build
export const MOBILE_SHEET_ID = 'mobile-sheet';
export const MOBILE_SIDEBAR_ID = 'mobile-sidebar';

const UI = {
  radius: {
    xs: pxToRem(4),
    sm: pxToRem(6),
    md: pxToRem(8),
    lg: pxToRem(12),
    full: '100%',
  },
  text: {
    xs: pxToRem(8),
    sm: pxToRem(10),
    md: pxToRem(12),
    lg: pxToRem(14),
    xl: pxToRem(16),
    h4: pxToRem(18),
    h3: pxToRem(22),
    h2: pxToRem(26),
    h1: pxToRem(32),
  },
  spacing: {
    ['2xs']: pxToRem(5),
    xs: pxToRem(8),
    sm: pxToRem(10),
    md: pxToRem(16),
    lg: pxToRem(24),
    xl: pxToRem(32),
    ['2xl']: pxToRem(40),
    ['3xl']: pxToRem(48),
  },
  opacity: {
    dimmed: 0.6,
    full: 1,
  },
  page: {
    padding: {
      desktop: '3rem',
      mobile: '1rem',
    },
  },
  duration: {
    quick: '100ms',
    normal: '200ms',
    slow: '350ms',
    delay: '500ms',
  },
  /** Z Index for components */
  z: {
    base: 1,
    menu: 5,
    header: 100,
    footer: 100,
    tooltip: 101,
    bottomNavigation: 201,
    modal: 300,
    mobileSheet: 300,
    alert: 400,
    loadingOverlay: 1000,
  },
  height: {
    navHeader: 76,
    mobileNav: 60,
    providerGameFooter: 60,
    input: 48,
  },
  game: {
    widthDefault: 1280,
    aspectRatio: 16 / 9,
  },
  inputHeight: 48,
  fontDefault: 'Aeonik',
};

export default UI;
