import { Sports, SportsMatchPhase, SportsMatchState } from 'generated/graphql';
import uniqBy from 'lodash/uniqBy';

import { closedMatchPhaseList, inPlayMatchPhaseList } from './sportEventStatusCheck';

export const getLiveMatchPhaseScores = (params: {
  sport: Sports;
  matchStatistics?: SportsMatchState;
}) => {
  const { sport, matchStatistics } = params;
  const matchPhase = matchStatistics?.matchSummary?.matchPhase as SportsMatchPhase;

  if (sport === Sports.TABLE_TENNIS) {
    const matchPhases = matchStatistics?.matchSummary?.phases || [];
    if (closedMatchPhaseList.includes(matchPhase)) {
      return matchPhases;
    }

    if (inPlayMatchPhaseList.includes(matchPhase)) {
      const nextPhaseScore = {
        phase: matchStatistics?.matchSummary?.phases
          ? Number(matchStatistics?.matchSummary?.phases?.length) + 1
          : 1,
        homeScore: matchStatistics?.matchSummary?.homeScore || 0,
        awayScore: matchStatistics?.matchSummary?.awayScore || 0,
        status: null,
      };
      return [...matchPhases, nextPhaseScore];
    }

    return [];
  }
  return matchStatistics?.matchSummary?.phases || [];
};

export const getLiveMatchScoreText = (params: {
  sport: Sports;
  matchStatistics?: SportsMatchState;
  isClosed: boolean;
}) => {
  const { sport, matchStatistics, isClosed } = params;

  const homeScore = matchStatistics?.matchSummary?.homeScore || 0;
  const awayScore = matchStatistics?.matchSummary?.awayScore || 0;

  let liveScoreText = '';
  let liveScoreList = [];
  const liveMatchPhaseScore = getLiveMatchPhaseScores({ sport, matchStatistics });

  const countWinPhaseForTennisAndTableTennis = () => {
    let customHomeScore = 0;
    let customAwayScore = 0;
    if ([Sports.TENNIS, Sports.TABLE_TENNIS].includes(sport)) {
      if (liveMatchPhaseScore.length > 1) {
        const liveMatchPhaseFilter = isClosed
          ? liveMatchPhaseScore
          : liveMatchPhaseScore.slice(0, -1); // not include set is currently in-progress phase

        liveMatchPhaseFilter.forEach(phaseData => {
          if (phaseData?.homeScore && phaseData?.awayScore) {
            if (phaseData.homeScore > phaseData.awayScore) {
              customHomeScore += 1;
            } else if (phaseData.homeScore < phaseData.awayScore) {
              customAwayScore += 1;
            }
          }
        });
      }
    }
    return {
      customHomeScore,
      customAwayScore,
    };
  };

  const { customHomeScore, customAwayScore } = countWinPhaseForTennisAndTableTennis();

  liveScoreText = [Sports.TENNIS, Sports.TABLE_TENNIS].includes(sport)
    ? `${customHomeScore}-${customAwayScore}`
    : `${homeScore}-${awayScore}`;

  const currentScorePhase =
    sport === Sports.TABLE_TENNIS
      ? `${homeScore}-${awayScore}`
      : `${liveMatchPhaseScore?.[liveMatchPhaseScore.length - 1]?.homeScore}-${
          liveMatchPhaseScore?.[liveMatchPhaseScore.length - 1]?.awayScore
        }`;
  liveScoreList = [Sports.TENNIS, Sports.TABLE_TENNIS].includes(sport)
    ? [currentScorePhase, `${customHomeScore}-${customAwayScore}`]
    : [`${homeScore}-${awayScore}`];

  return {
    homeScore: [Sports.TENNIS, Sports.TABLE_TENNIS].includes(sport) ? customHomeScore : homeScore,
    awayScore: [Sports.TENNIS, Sports.TABLE_TENNIS].includes(sport) ? customAwayScore : awayScore,
    liveScoreText,
    liveScoreList,
  };
};

export const getUniqFixtureIds = (payload: { fixtureId: string; startTime: string }[]) => {
  return uniqBy(payload, item => item.fixtureId);
};
