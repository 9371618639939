import React from 'react';

import { SvgProps } from '../index';

const Menu = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 17' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 15H9H17M1 1H9H17M1 8H17" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  );
};

export default Menu;
