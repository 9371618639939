import React from 'react';

import { SvgProps } from '../..';

const MMA = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g id="Property 1=mma" clipPath="url(#clip0_10555_127533)">
        <g id="Group 1000004953">
          <path
            id="Vector"
            d="M16.5 17C16.0523 17.8309 15.258 18.3849 14.3338 18.3849C13.3517 18.3849 11.6001 17.8628 11.0802 16.9504V8.73896M16.4958 7.92433V17.2762C16.4958 17.2762 16.4958 17.3251 16.4958 17.3577C16.9579 18.2864 17.8388 18.9055 18.8497 18.9055C20.3372 18.9055 21.5503 17.5369 21.5503 15.8588V5.88776C21.2904 2.62925 18.7053 2.14048 17.2178 2.14048C16.5969 2.14048 16.1492 2.22194 16.1492 2.22194C15.2394 1.30956 14.3295 1 13.5208 1C11.9178 1 10.7336 2.22194 10.7336 2.22194C9.93931 1.68429 9.24612 1.48878 8.62513 1.48878C6.71885 1.48878 5.67906 3.44388 5.67906 3.44388C5.20249 3.31354 4.76924 3.26466 4.37932 3.26466C1.5199 3.26466 1 6.29508 1 6.29508V15.0279C1 16.4942 2.7147 17.5 4 17.5C4.83761 17.5 5.55969 17.0112 5.97849 16.2618M6.0401 9.55358V16.5594C6.50222 17.4718 8.00353 18 9 18C9.83761 18 10.5597 17.5112 10.9785 16.7618"
            stroke={color}
            strokeMiterlimit="10"
          />
          <path
            id="Vector_2"
            d="M22.2418 10.1244C22.0073 9.23184 21.7452 8.41893 21.5245 7.70166V15.3207C21.5245 17.4087 20.8082 19 19.001 19C17.8973 19 17.0942 18.4723 16.501 17.5C16.001 18 15.001 18.5 14.001 18.5C13.4353 18.5 12.0252 17.8188 11.501 17.5C11.294 17.9463 11.1913 19.1302 11.0672 19.6562C10.9568 20.0706 11.0396 21.9844 11.3017 22.3032C11.5086 22.5582 11.7845 22.7017 12.0881 22.7017C12.157 22.7017 12.226 22.7017 12.3088 22.6698C12.6123 22.5901 12.971 22.5104 13.3297 22.4148C15.3577 21.9047 18.1307 21.1874 20.4898 21.5062C22.0349 21.7134 22.7109 20.773 23.0006 19.9442C23.5662 18.3343 23.3179 14.0455 22.2418 10.1085V10.1244Z"
            stroke={color}
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10555_127533">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MMA;
