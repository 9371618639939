import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import { mediaQueries } from 'assets/styles/mediaQueries';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const PageContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  flex: 1;

  ${mediaQueries(Breakpoint.MD)`
    overflow: hidden;
  `};
`;

// This ensures desktop mobile and chat are individually scrollable
export const PageContent = styled.div`
  // without this, mobile content can be scrolled horizontally
  overflow: hidden;

  ${mediaQueries(Breakpoint.MD)`
    display: flex;
    flex-direction: column;
    scrollbar-color: ${colors.primaryBackground} transparent;
    height: calc(100vh - ${pxToRem(UI.height.navHeader)});
    overflow: auto;
  `};
`;

export const MobileHeaderWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: ${UI.z.header};
`;

export const SupportButtonWrapper = styled.div`
  display: none;
  position: absolute;
  bottom: ${pxToRem(20)};
  right: ${pxToRem(20)};
  z-index: 1;

  & > button {
    height: 3rem;
    width: 3rem;
  }

  ${mediaQueries(Breakpoint.MD)`
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;
