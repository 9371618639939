import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface GamesState {
  /** Will be fiat if the user's `displayInFiat` preference is true, crypto otherwise */
  betAmount: string;
  isPlaying: boolean;
}

// Persistent storage for games
const initialState: GamesState = {
  betAmount: '0.00',
  isPlaying: false,
};

export const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    updateIsPlaying: (state: GamesState, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload;
    },
    updateBetAmount: (state: GamesState, action: PayloadAction<string>) => {
      if (isNaN(Number(action.payload))) return state;
      state.betAmount = action.payload;
    },
  },
});

export const { updateBetAmount, updateIsPlaying } = gamesSlice.actions;

export default gamesSlice.reducer;
