import { SportsMarketStatus } from 'generated/graphql';

import { BetSlipStatus } from '../../../../redux/slices/sportsBetSlice';
import { OtherStatus, SportsBetTicketStatus, SportsBetTicketStatusType } from '../BetsGroup.type';

type Props = Partial<{
  betSlipStatus: BetSlipStatus;
  status: SportsBetTicketStatusType;
  cashOutStatus?: string;
  isLive?: boolean;
}>;

export function getBetViewConfig({ betSlipStatus, status, cashOutStatus, isLive = false }: Props) {
  const isInBetInfoView = betSlipStatus === BetSlipStatus.BET_VIEW_INFO;
  const isInBetConfirmedView = betSlipStatus === BetSlipStatus.CONFIRMING_BET;
  const isInEmptyView = betSlipStatus === BetSlipStatus.EMPTY;
  const isInBetPlacingView = betSlipStatus === BetSlipStatus.BET_PLACING;
  const isInBetAddView = betSlipStatus === BetSlipStatus.ADDING_BET;
  const isInBetPlacedView = !betSlipStatus || betSlipStatus === BetSlipStatus.BET_PLACED;
  const isInBetPendingView = betSlipStatus === BetSlipStatus.PENDING_BET;
  const isInBetResultView = betSlipStatus === BetSlipStatus.RESULTED_BET;
  const isInBetPlacedOrConfirmed = isInBetConfirmedView || isInBetPlacingView || isInBetPlacedView;
  const isInBetAddOrConfirmed = isInBetAddView || isInBetConfirmedView || isInBetPlacingView;

  const isWon = status === SportsBetTicketStatus.WON;
  const isLost = status === SportsBetTicketStatus.LOST;
  const isCanceled = status === SportsBetTicketStatus.CANCELLED;
  const isDeadHeat = status === SportsBetTicketStatus.DEAD_HEAT;
  const isCashedOut = status === SportsBetTicketStatus.CASHED_OUT;
  const isPending = status === SportsBetTicketStatus.PENDING;
  const isPushed = status === SportsBetTicketStatus.PUSHED;
  const isTrading = status === SportsBetTicketStatus.TRADING;
  const isVoid = status === SportsBetTicketStatus.VOIDED;
  const isPartialPending = status === SportsBetTicketStatus.PARTIAL_PENDING;
  const isWin = status === OtherStatus.WIN;
  const isLoss = status === OtherStatus.LOSS;

  const shouldShowReceipt =
    isWon ||
    isLoss ||
    isLost ||
    isVoid ||
    isCashedOut ||
    isDeadHeat ||
    isPending ||
    isPartialPending ||
    isInBetPlacedView ||
    isPushed ||
    isInBetInfoView;

  const isResulted = isWon || isLoss || isLost || isVoid || isCashedOut || isDeadHeat;

  const shouldHideStatusBadgeAndShowOdds = !isTrading && !isPending && !isPartialPending;

  const shouldShowStats =
    isInBetConfirmedView ||
    isInBetPlacingView ||
    isInBetInfoView ||
    isInBetPlacedView ||
    isInBetPendingView ||
    isInBetResultView;

  const shouldShowEstimateAmount =
    !status || isCashedOut || isPending || isVoid || isInBetPlacedOrConfirmed || isPartialPending;

  const shouldHideDate = isCanceled || isResulted;

  const shouldShowCashOutSuspended = !isCashedOut && cashOutStatus === SportsMarketStatus.SUSPENDED;

  const shouldShowCashOutOpen = !isCashedOut && cashOutStatus === SportsMarketStatus.OPEN;

  const shouldShowCashedOut = isCashedOut;

  const shouldShowLiveEventInfo = isLive && !isInBetAddOrConfirmed;

  const shouldShowFooterViewAllBets = isInEmptyView || isInBetPendingView || isInBetResultView;
  const shouldShowFooterStakeAndPayout =
    !isInEmptyView && !isInBetPendingView && !isInBetResultView;

  return {
    isInBetPendingView,
    isInBetResultView,
    isInEmptyView,
    isInBetInfoView,
    isInBetPlacedView,
    isInBetConfirmedView,
    isInBetPlacedOrConfirmed,
    isInBetAddOrConfirmed,
    isInBetAddView,
    isInBetPlacingView,
    isPending,
    isWon,
    isLost,
    isWin,
    isLoss,
    isVoid,
    isPartialPending,
    isDeadHeat,
    isCanceled,
    isCashedOut,
    isPushed,
    isResulted,
    shouldShowReceipt,
    shouldHideDate,
    shouldHideStatusBadgeAndShowOdds,
    shouldShowEstimateAmount,
    shouldShowStats,
    shouldDisplayMarketStatus:
      betSlipStatus === BetSlipStatus.ADDING_BET || betSlipStatus === BetSlipStatus.CONFIRMING_BET,
    shouldShowCashOutSuspended,
    shouldShowCashOutOpen,
    shouldShowCashedOut,
    shouldShowLiveEventInfo,
    shouldShowFooterViewAllBets,
    shouldShowFooterStakeAndPayout,
  };
}
