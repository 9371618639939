import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Convert Epoch to date */
  EpochToDate: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** Amount for currencies */
  CryptoAmount: any;
  /** BigNumber */
  BigNumber: any;
  /** Percentage */
  GraphqlPercent: any;
  /** Bet Target Value */
  BetTarget: any;
  /** Crash Point Value */
  GraphqlCrashPoint: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** `Date` type as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type UserSession = {
  __typename?: 'UserSession';
  id: Scalars['String'];
  ip?: Maybe<Scalars['String']>;
  ipList?: Maybe<Array<Scalars['String']>>;
  ua?: Maybe<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  browser?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  isReducedRestriction: Scalars['Boolean'];
  refreshedAt?: Maybe<Scalars['EpochToDate']>;
  lastUsedAt: Scalars['EpochToDate'];
  updatedAt: Scalars['EpochToDate'];
  createdAt: Scalars['EpochToDate'];
  deletedAt: Scalars['EpochToDate'];
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['String'];
  key: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type GameProvider = {
  __typename?: 'GameProvider';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  image?: Maybe<Image>;
  gamesCount: Scalars['Int'];
  disabledFiatCurrencies: Array<FiatCurrency>;
  disabled: Scalars['Boolean'];
};

export enum FiatCurrency {
  USD = 'USD',
  EUR = 'EUR',
  MXN = 'MXN',
  BRL = 'BRL',
  JPY = 'JPY',
  IDR = 'IDR',
}

export type Kyc = {
  __typename?: 'Kyc';
  id: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  birthday: Scalars['Date'];
  country: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  postcode: Scalars['String'];
  occupation: Scalars['String'];
  canCompletePoa: Scalars['Boolean'];
  level1Status: KycLevelStatus;
  level2Status: KycLevelStatus;
  level3Status: KycLevelStatus;
  level4Status: KycLevelStatus;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum KycLevelStatus {
  INCOMPLETE = 'INCOMPLETE',
  REQUESTED = 'REQUESTED',
  IN_REVIEW = 'IN_REVIEW',
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  COMPLETED = 'COMPLETED',
}

export type CurrencyAmount = {
  __typename?: 'CurrencyAmount';
  currency: Currency;
  amount: Scalars['CryptoAmount'];
};

export enum Currency {
  BTC = 'BTC',
  ETH = 'ETH',
  LTC = 'LTC',
  USDT = 'USDT',
  USDC = 'USDC',
  TRX = 'TRX',
  MATIC = 'MATIC',
  XRP = 'XRP',
  SOL = 'SOL',
  DOGE = 'DOGE',
  BUSD = 'BUSD',
  BNB = 'BNB',
  SHIB = 'SHIB',
}

export type GameSeed = {
  __typename?: 'GameSeed';
  id: Scalars['String'];
  clientSeed?: Maybe<Scalars['String']>;
  seed?: Maybe<Scalars['String']>;
  status?: Maybe<GameSeedStatus>;
  hashedSeed: Scalars['String'];
  currentNonce: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum GameSeedStatus {
  ACTIVE = 'ACTIVE',
  NEXT = 'NEXT',
}

export type PaginatedGameSeed = {
  __typename?: 'PaginatedGameSeed';
  nodes?: Maybe<Array<GameSeed>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type TipRain = {
  __typename?: 'TipRain';
  id: Scalars['String'];
  tips: Array<Tip>;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  chatRoom: ChatRoom;
  createdAt: Scalars['DateTime'];
};

export enum ChatRoom {
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  CHINESE = 'CHINESE',
  SPANISH = 'SPANISH',
  PORTUGUESE = 'PORTUGUESE',
  KOREAN = 'KOREAN',
  JAPANESE = 'JAPANESE',
  GERMAN = 'GERMAN',
  TURKISH = 'TURKISH',
}

export type Tip = {
  __typename?: 'Tip';
  id: Scalars['String'];
  sender: User;
  receiver: User;
  vipLevel?: Maybe<VipLevel>;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  chatRoom?: Maybe<ChatRoom>;
  createdAt: Scalars['DateTime'];
};

export enum VipLevel {
  UNRANKED = 'UNRANKED',
  WOOD = 'WOOD',
  BRONZE_1 = 'BRONZE_1',
  BRONZE_2 = 'BRONZE_2',
  BRONZE_3 = 'BRONZE_3',
  BRONZE_4 = 'BRONZE_4',
  BRONZE_5 = 'BRONZE_5',
  SILVER_1 = 'SILVER_1',
  SILVER_2 = 'SILVER_2',
  SILVER_3 = 'SILVER_3',
  SILVER_4 = 'SILVER_4',
  SILVER_5 = 'SILVER_5',
  GOLD_1 = 'GOLD_1',
  GOLD_2 = 'GOLD_2',
  GOLD_3 = 'GOLD_3',
  GOLD_4 = 'GOLD_4',
  GOLD_5 = 'GOLD_5',
  PLATINUM_1 = 'PLATINUM_1',
  PLATINUM_2 = 'PLATINUM_2',
  PLATINUM_3 = 'PLATINUM_3',
  PLATINUM_4 = 'PLATINUM_4',
  PLATINUM_5 = 'PLATINUM_5',
  JADE_1 = 'JADE_1',
  JADE_2 = 'JADE_2',
  JADE_3 = 'JADE_3',
  JADE_4 = 'JADE_4',
  JADE_5 = 'JADE_5',
  SAPPHIRE_1 = 'SAPPHIRE_1',
  SAPPHIRE_2 = 'SAPPHIRE_2',
  SAPPHIRE_3 = 'SAPPHIRE_3',
  SAPPHIRE_4 = 'SAPPHIRE_4',
  SAPPHIRE_5 = 'SAPPHIRE_5',
  RUBY_1 = 'RUBY_1',
  RUBY_2 = 'RUBY_2',
  RUBY_3 = 'RUBY_3',
  RUBY_4 = 'RUBY_4',
  RUBY_5 = 'RUBY_5',
  DIAMOND_1 = 'DIAMOND_1',
  DIAMOND_2 = 'DIAMOND_2',
  DIAMOND_3 = 'DIAMOND_3',
  DIAMOND_4 = 'DIAMOND_4',
  DIAMOND_5 = 'DIAMOND_5',
  OPAL_1 = 'OPAL_1',
  OPAL_2 = 'OPAL_2',
  OPAL_3 = 'OPAL_3',
  OPAL_4 = 'OPAL_4',
  OPAL_5 = 'OPAL_5',
  DRAGON_1 = 'DRAGON_1',
  DRAGON_2 = 'DRAGON_2',
  DRAGON_3 = 'DRAGON_3',
  DRAGON_4 = 'DRAGON_4',
  DRAGON_5 = 'DRAGON_5',
  MYTHIC = 'MYTHIC',
}

export type Deposit = {
  __typename?: 'Deposit';
  id: Scalars['String'];
  userId: Scalars['String'];
  sourceAddress?: Maybe<Scalars['String']>;
  onChainTransactionId: Scalars['String'];
  chain: Chain;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  createdAt: Scalars['DateTime'];
  status: DepositStatus;
  confirmations: Scalars['Int'];
};

export enum Chain {
  BITCOIN = 'BITCOIN',
  ETHEREUM = 'ETHEREUM',
  SOLANA = 'SOLANA',
  LITECOIN = 'LITECOIN',
  TRON = 'TRON',
  MATIC_POLYGON = 'MATIC_POLYGON',
  XRP = 'XRP',
  DOGE = 'DOGE',
  BINANCE_SMART_CHAIN = 'BINANCE_SMART_CHAIN',
}

export enum DepositStatus {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
}

export type PaginatedDeposit = {
  __typename?: 'PaginatedDeposit';
  nodes?: Maybe<Array<Deposit>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type Admin = {
  __typename?: 'Admin';
  id: Scalars['String'];
  username: Scalars['String'];
  generatedPassword?: Maybe<Scalars['String']>;
  role: AdminRole;
  status: AdminStatus;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum AdminRole {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  PRODUCT_MANAGER = 'PRODUCT_MANAGER',
  SUPPORT = 'SUPPORT',
}

export enum AdminStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export type PaginatedAdmin = {
  __typename?: 'PaginatedAdmin';
  nodes?: Maybe<Array<Admin>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type AdminWithRoleMatrix = {
  __typename?: 'AdminWithRoleMatrix';
  id: Scalars['String'];
  username: Scalars['String'];
  generatedPassword?: Maybe<Scalars['String']>;
  role: AdminRole;
  status: AdminStatus;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  roleMatrix: Array<Power>;
};

export type AdminBalanceAdjustment = {
  __typename?: 'AdminBalanceAdjustment';
  id: Scalars['String'];
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  type: AdminBalanceAdjustmentType;
  vaultAdjustment: Scalars['Boolean'];
  note: Scalars['String'];
  adminId: Scalars['String'];
  admin: Admin;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum AdminBalanceAdjustmentType {
  KINGSIDE = 'KINGSIDE',
  MARKETING = 'MARKETING',
  SUPPORT = 'SUPPORT',
}

export type Vault = {
  __typename?: 'Vault';
  id: Scalars['String'];
  type: VaultType;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  adminBalanceAdjustmentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export enum VaultType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  ADMIN_ADJUSTMENT = 'ADMIN_ADJUSTMENT',
}

export type ChainCurrency = {
  __typename?: 'ChainCurrency';
  id: Scalars['String'];
  chain: Chain;
  currency: Currency;
  withdrawalFee: Scalars['CryptoAmount'];
  minimumWithdrawal: Scalars['CryptoAmount'];
  tagAllowed: Scalars['Boolean'];
  disabledAt?: Maybe<Scalars['Date']>;
};

export type Withdrawal = {
  __typename?: 'Withdrawal';
  id: Scalars['String'];
  user?: Maybe<User>;
  onChainTransactionId?: Maybe<Scalars['String']>;
  chainCurrency: ChainCurrency;
  receiverAddress: Scalars['String'];
  receiverAddressTag?: Maybe<Scalars['String']>;
  status: WithdrawalStatus;
  admin?: Maybe<Admin>;
  amount: Scalars['CryptoAmount'];
  fee: Scalars['CryptoAmount'];
  confirmations: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum WithdrawalStatus {
  ERRORED = 'ERRORED',
  CREATED = 'CREATED',
  IN_REVIEW = 'IN_REVIEW',
  CANCELLED = 'CANCELLED',
  PROCESSED = 'PROCESSED',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
}

export type PaginatedWithdrawal = {
  __typename?: 'PaginatedWithdrawal';
  nodes?: Maybe<Array<Withdrawal>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type PromotionRedemption = {
  __typename?: 'PromotionRedemption';
  id: Scalars['String'];
  user: User;
  usdRedeemValue: Scalars['CryptoAmount'];
  amount: Scalars['CryptoAmount'];
  currency: Currency;
  createdAt: Scalars['DateTime'];
  afterBalance?: Maybe<Scalars['CryptoAmount']>;
};

export type PaginatedPromotionRedemption = {
  __typename?: 'PaginatedPromotionRedemption';
  nodes?: Maybe<Array<PromotionRedemption>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type PromotionCode = {
  __typename?: 'PromotionCode';
  id: Scalars['String'];
  code: Scalars['String'];
  usdRedeemValue: Scalars['CryptoAmount'];
  maxRedeemCount?: Maybe<Scalars['Float']>;
  expiredAt: Scalars['Date'];
  status: PromotionCodeStatus;
  allowedUsernames?: Maybe<Array<Scalars['String']>>;
  redemptions?: Maybe<Array<PromotionRedemption>>;
  wageredRequirement?: Maybe<Scalars['CryptoAmount']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  redeemCount: Scalars['Int'];
};

export enum PromotionCodeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type PaginatedPromotionCode = {
  __typename?: 'PaginatedPromotionCode';
  nodes?: Maybe<Array<PromotionCode>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type SportsBetSelection = {
  __typename?: 'SportsBetSelection';
  id: Scalars['String'];
  oddsNumerator: Scalars['BigNumber'];
  oddsDenominator: Scalars['BigNumber'];
  inPlay: Scalars['Boolean'];
  status: SportsBetSelectionStatus;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  marketSelection: SportsMarketSelection;
  snapshot: SportsBetSelectionSnapshot;
};

export enum SportsBetSelectionStatus {
  PENDING = 'PENDING',
  LOST = 'LOST',
  WON = 'WON',
  PUSHED = 'PUSHED',
  PARTIAL = 'PARTIAL',
  PLACED = 'PLACED',
  CASHED_OUT = 'CASHED_OUT',
  VOIDED = 'VOIDED',
}

export type SportsBetSettlement = {
  __typename?: 'SportsBetSettlement';
  id: Scalars['String'];
  payoutOddsDecimal: Scalars['BigNumber'];
  payout?: Maybe<Scalars['CryptoAmount']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type SportsBet = {
  __typename?: 'SportsBet';
  id: Scalars['String'];
  account: Account;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  originalAmount?: Maybe<Scalars['CryptoAmount']>;
  totalOddsDecimal: Scalars['BigNumber'];
  selections: Array<SportsBetSelection>;
  settlement?: Maybe<SportsBetSettlement>;
  status: SportsBetStatus;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  actualOddsDecimal: Scalars['BigNumber'];
  cashoutAvailable: SportsBetCashoutAvailable;
  cashoutOddsDecimal?: Maybe<Scalars['BigNumber']>;
};

export enum SportsBetStatus {
  PENDING = 'PENDING',
  PARTIAL_PENDING = 'PARTIAL_PENDING',
  WON = 'WON',
  LOST = 'LOST',
  PARTIAL = 'PARTIAL',
  CANCELLED = 'CANCELLED',
  CASHED_OUT = 'CASHED_OUT',
  VOIDED = 'VOIDED',
}

export type PaginatedSportsBet = {
  __typename?: 'PaginatedSportsBet';
  nodes?: Maybe<Array<SportsBet>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type Rollover = {
  __typename?: 'Rollover';
  id: Scalars['String'];
  currency: Currency;
  wageredRequirement: Scalars['CryptoAmount'];
  amount: Scalars['CryptoAmount'];
  status: RolloverStatus;
  usdMaxBet: Scalars['Float'];
  allowRestrictedGame: Scalars['Boolean'];
  allowSportBet: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  progressAmount?: Maybe<Scalars['CryptoAmount']>;
  rewardPenalty: Scalars['GraphqlPercent'];
  maxProfit?: Maybe<Scalars['CryptoAmount']>;
  deductAmount?: Maybe<Scalars['CryptoAmount']>;
  profitAndLoss?: Maybe<Scalars['CryptoAmount']>;
  balance?: Maybe<Scalars['CryptoAmount']>;
  admin?: Maybe<Admin>;
};

export enum RolloverStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  FORFEITED = 'FORFEITED',
}

export type Vip = {
  __typename?: 'Vip';
  level: VipLevel;
  amount?: Maybe<Scalars['BigNumber']>;
};

export type VipUserUpgrade = {
  __typename?: 'VipUserUpgrade';
  id: Scalars['String'];
  vipLevel: VipLevel;
  upgradeBonusUsdAmount: Scalars['CryptoAmount'];
  createdAt: Scalars['DateTime'];
};

export type VipUpgradeBonusClaim = {
  __typename?: 'VipUpgradeBonusClaim';
  id: Scalars['String'];
  vipUserUpgradeId: Scalars['String'];
  amount: Scalars['CryptoAmount'];
  currency: Currency;
};

export type Race = {
  __typename?: 'Race';
  id: Scalars['String'];
  type: RaceType;
  name: Scalars['String'];
  currency: Currency;
  totalPrizeUsd: Scalars['BigNumber'];
  prizeDistribution: Array<Scalars['Float']>;
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
};

export enum RaceType {
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

export type ChallengeCreator = {
  __typename?: 'ChallengeCreator';
  username: Scalars['String'];
  vipLevel: VipLevel;
};

export type Challenge = {
  __typename?: 'Challenge';
  id: Scalars['String'];
  game: Game;
  usdMinBet: Scalars['CryptoAmount'];
  minMultiplier: Scalars['Float'];
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  referredOnly: Scalars['Boolean'];
  completed: Scalars['Boolean'];
  creator?: Maybe<ChallengeCreator>;
  admin: Admin;
  challengeReward?: Maybe<ChallengeReward>;
};

export type PaginatedChallenges = {
  __typename?: 'PaginatedChallenges';
  nodes?: Maybe<Array<Challenge>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type ChallengeReward = {
  __typename?: 'ChallengeReward';
  challenge: Challenge;
  user: User;
  bet: Bet;
  claimedAt?: Maybe<Scalars['DateTime']>;
  expiredAt: Scalars['DateTime'];
};

export type VipBonus = {
  __typename?: 'VipBonus';
  id: Scalars['String'];
  type: VipBonusType;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  cadence?: Maybe<Scalars['Int']>;
  occurrence: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  admin: Admin;
  status: VipBonusStatus;
  bonusClaims?: Maybe<Array<VipBonusClaim>>;
  endAt: Scalars['DateTime'];
  cancelledAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export enum VipBonusType {
  SINGLE = 'SINGLE',
  RELOADED = 'RELOADED',
}

export enum VipBonusStatus {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export type VipBonusClaim = {
  __typename?: 'VipBonusClaim';
  id: Scalars['String'];
  vipBonusId: Scalars['String'];
  vipBonus: VipBonus;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  occurrenceIndex: Scalars['Int'];
  createdAt: Scalars['DateTime'];
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['String'];
  type: TransactionType;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  depositId?: Maybe<Scalars['String']>;
  deposit?: Maybe<Deposit>;
  withdrawalId?: Maybe<Scalars['String']>;
  withdrawal?: Maybe<Withdrawal>;
  betId?: Maybe<Scalars['String']>;
  sportsBetId?: Maybe<Scalars['String']>;
  tipId?: Maybe<Scalars['String']>;
  tip?: Maybe<Tip>;
  vaultId?: Maybe<Scalars['String']>;
  vault?: Maybe<Vault>;
  usdRate: Scalars['BigNumber'];
  adminBalanceAdjustmentId?: Maybe<Scalars['String']>;
  adminBalanceAdjustment?: Maybe<AdminBalanceAdjustment>;
  createdAt: Scalars['DateTime'];
};

export enum TransactionType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
  WITHDRAWAL_CANCELLED = 'WITHDRAWAL_CANCELLED',
  TIP_SENT = 'TIP_SENT',
  TIP_RECEIVED = 'TIP_RECEIVED',
  BET_PLACED = 'BET_PLACED',
  BET_PAYOUT = 'BET_PAYOUT',
  BET_JACKPOT = 'BET_JACKPOT',
  BET_ROLLBACK = 'BET_ROLLBACK',
  SPORTS_BET_PLACED = 'SPORTS_BET_PLACED',
  SPORTS_BET_PAYOUT = 'SPORTS_BET_PAYOUT',
  SPORTS_BET_CASHED_OUT = 'SPORTS_BET_CASHED_OUT',
  SPORTS_BET_CANCELLED = 'SPORTS_BET_CANCELLED',
  SPORTS_BET_VOIDED = 'SPORTS_BET_VOIDED',
  VAULT_DEPOSIT = 'VAULT_DEPOSIT',
  VAULT_WITHDRAWAL = 'VAULT_WITHDRAWAL',
  COMMISSION_CLAIMED = 'COMMISSION_CLAIMED',
  RAKEBACK_CLAIMED = 'RAKEBACK_CLAIMED',
  DAILY_RAKEBACK_CLAIMED = 'DAILY_RAKEBACK_CLAIMED',
  WEEKLY_BONUS_CLAIMED = 'WEEKLY_BONUS_CLAIMED',
  MONTHLY_BONUS_CLAIMED = 'MONTHLY_BONUS_CLAIMED',
  VIP_UPGRADE_BONUS_CLAIM = 'VIP_UPGRADE_BONUS_CLAIM',
  PROMOTION_REDEMPTION = 'PROMOTION_REDEMPTION',
  ROLLOVER_CREDIT = 'ROLLOVER_CREDIT',
  ROLLOVER_FORFEITED = 'ROLLOVER_FORFEITED',
  ROLLOVER_PROFIT_ADJUSTMENT = 'ROLLOVER_PROFIT_ADJUSTMENT',
  RACE_REWARD = 'RACE_REWARD',
  CHALLENGE_REWARD = 'CHALLENGE_REWARD',
  ADMIN_ADJUSTMENT = 'ADMIN_ADJUSTMENT',
  VIP_SINGLE_BONUS_CLAIM = 'VIP_SINGLE_BONUS_CLAIM',
  VIP_RELOAD_BONUS_CLAIM = 'VIP_RELOAD_BONUS_CLAIM',
}

export type PaginatedTransaction = {
  __typename?: 'PaginatedTransaction';
  nodes?: Maybe<Array<Transaction>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type Balance = {
  __typename?: 'Balance';
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  usdTotalWagered: Scalars['CryptoAmount'];
  totalXp: Scalars['CryptoAmount'];
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['String'];
  user: User;
  balances: Array<Balance>;
  vaultBalances: Array<CurrencyAmount>;
  transactions: Array<Transaction>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type Campaign = {
  __typename?: 'Campaign';
  id: Scalars['String'];
  user: User;
  name: Scalars['String'];
  code: Scalars['String'];
  rate: Scalars['GraphqlPercent'];
  refereeCount: Scalars['Float'];
  refereeWagered: Array<CurrencyAmount>;
  commissionTotals: Array<CurrencyAmount>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type UserStatistic = {
  __typename?: 'UserStatistic';
  bets: Scalars['Int'];
  wagered: Scalars['CryptoAmount'];
};

export type UserFlag = {
  __typename?: 'UserFlag';
  id: Scalars['String'];
  withdrawalHeld: Scalars['Boolean'];
  withdrawalDisabled: Scalars['Boolean'];
  cancelWithdrawalDisabled: Scalars['Boolean'];
  gamePlayDisabled: Scalars['Boolean'];
  reducedRestriction: Scalars['Boolean'];
  raceIneligible: Scalars['Boolean'];
  commissionGenerationDisabled: Scalars['Boolean'];
  rewardGenerationDisabled: Scalars['Boolean'];
  recurringRewardDisabled: Scalars['Boolean'];
  rainReceivingDisabled: Scalars['Boolean'];
  tipOrRainSendingDisabled: Scalars['Boolean'];
  promotionCodeRedemptionDisabled: Scalars['Boolean'];
  commissionRedemptionDisabled: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['String']>;
  account: Account;
  userFlag?: Maybe<UserFlag>;
  username?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerificationSentAt?: Maybe<Scalars['DateTime']>;
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  campaignId?: Maybe<Scalars['String']>;
  campaign?: Maybe<Campaign>;
  otpSentAt?: Maybe<Scalars['DateTime']>;
  totpSecret: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  passwordUpdatedAt?: Maybe<Scalars['DateTime']>;
  passwordResetAt?: Maybe<Scalars['DateTime']>;
  status: UserStatus;
  favouriteGames: Array<Game>;
  kyc?: Maybe<Kyc>;
  anonymous: Scalars['Boolean'];
  statistic?: Maybe<UserStatistic>;
  avatar: Scalars['Int'];
  avatarBackground: Scalars['Int'];
  campaignLimit: Scalars['Int'];
  chatBanUntil?: Maybe<Scalars['DateTime']>;
  loginVerificationMethod: LoginVerificationMethod;
  sportsBetDisabled: Scalars['Boolean'];
  sportsMultiBetDisabled: Scalars['Boolean'];
  currentSession: UserSession;
  intercomToken: Scalars['String'];
  linkedOauthProviders: Array<OauthProvider>;
  refereeCount: Scalars['Int'];
  xp: Scalars['Int'];
  refereeWagered: Array<CurrencyAmount>;
  /** The claimable commissions of the user */
  commissionBalances: Array<CurrencyAmount>;
  /** The total commissions claimed + claimable for the user */
  commissionTotals: Array<CurrencyAmount>;
  /** Active Race Wager info */
  raceInfo?: Maybe<RaceInfo>;
  vipLevel: VipLevel;
  /** Commission generated by this user for their referrer */
  commissionGenerated: Array<CurrencyAmount>;
  /** Used by the referrer to see users total wagered */
  wagered: Array<CurrencyAmount>;
  /** Vault Id for the user */
  vaultId?: Maybe<Array<Scalars['String']>>;
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export enum LoginVerificationMethod {
  NONE = 'NONE',
  EMAIL_OTP = 'EMAIL_OTP',
  TOTP = 'TOTP',
}

export enum OauthProvider {
  GOOGLE = 'GOOGLE',
}

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  nodes?: Maybe<Array<User>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type GameTag = {
  __typename?: 'GameTag';
  name: Scalars['String'];
};

export type GameAndGameCategory = {
  __typename?: 'GameAndGameCategory';
  gameId: Scalars['String'];
  gameCategoryName: Scalars['String'];
  main: Scalars['Boolean'];
};

export type Game = {
  __typename?: 'Game';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  provider?: Maybe<GameProvider>;
  tags?: Maybe<Array<GameTag>>;
  gameAndGameCategories?: Maybe<Array<GameAndGameCategory>>;
  originalGame?: Maybe<OriginalGame>;
  isFavourite: Scalars['Boolean'];
  demoMode: Scalars['Boolean'];
  edge: Scalars['GraphqlPercent'];
  accentColor: Scalars['String'];
  status: GameStatus;
  releasedDate?: Maybe<Scalars['Date']>;
  restrictions?: Maybe<Array<Scalars['String']>>;
  hasLive: Scalars['Boolean'];
  accumulating: Scalars['Boolean'];
  multiplier?: Maybe<Scalars['Float']>;
  rolloverRestricted: Scalars['Boolean'];
  recentPlayAt?: Maybe<Scalars['DateTime']>;
};

export enum OriginalGame {
  DICE = 'DICE',
  PLINKO = 'PLINKO',
  CRASH = 'CRASH',
  MINES = 'MINES',
  LIMBO = 'LIMBO',
  KENO = 'KENO',
  HILO = 'HILO',
  BLACKJACK = 'BLACKJACK',
}

export enum GameStatus {
  ACTIVE = 'ACTIVE',
  ACTIVE_HIDDEN = 'ACTIVE_HIDDEN',
  DISABLED_MANUAL = 'DISABLED_MANUAL',
  DISABLED_RECALLED = 'DISABLED_RECALLED',
  DISABLED_UNRECALLED = 'DISABLED_UNRECALLED',
}

export type PaginatedGame = {
  __typename?: 'PaginatedGame';
  nodes?: Maybe<Array<Game>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type DiceActionModel = {
  __typename?: 'DiceActionModel';
  resultRaw: Scalars['String'];
  resultValue: Scalars['BetTarget'];
  userValue: Scalars['BetTarget'];
  userDiceDirection?: Maybe<Scalars['String']>;
};

export type MinesActionModel = {
  __typename?: 'MinesActionModel';
  minesResult?: Maybe<Array<Scalars['Float']>>;
  minesCount: Scalars['Float'];
  winMultiplier?: Maybe<Scalars['BigNumber']>;
  selected?: Maybe<Array<Scalars['Float']>>;
};

export type PlinkoActionModel = {
  __typename?: 'PlinkoActionModel';
  results: Array<Scalars['Float']>;
  risk: PlinkoRiskLevel;
  rows: Scalars['Float'];
  multiplier: Scalars['BigNumber'];
};

export enum PlinkoRiskLevel {
  LOW_RISK = 'LOW_RISK',
  MEDIUM_RISK = 'MEDIUM_RISK',
  HIGH_RISK = 'HIGH_RISK',
}

export type CrashActionModel = {
  __typename?: 'CrashActionModel';
  betAt: Scalars['BetTarget'];
  payout?: Maybe<Scalars['BigNumber']>;
  multiplier?: Maybe<Scalars['BigNumber']>;
  payoutType?: Maybe<PayoutType>;
};

export enum PayoutType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export type LimboActionModel = {
  __typename?: 'LimboActionModel';
  resultRaw: Scalars['String'];
  resultValue: Scalars['BetTarget'];
  userValue: Scalars['BetTarget'];
};

export type KenoActionModel = {
  __typename?: 'KenoActionModel';
  results: Array<Scalars['Float']>;
  risk: KenoRiskLevel;
  multiplier: Scalars['BigNumber'];
  selected: Array<Scalars['Float']>;
};

export enum KenoRiskLevel {
  CLASSIC = 'CLASSIC',
  LOW_RISK = 'LOW_RISK',
  MEDIUM_RISK = 'MEDIUM_RISK',
  HIGH_RISK = 'HIGH_RISK',
}

export type HiloActionModel = {
  __typename?: 'HiloActionModel';
  card?: Maybe<Scalars['Int']>;
  guess?: Maybe<HiloGuess>;
  winMultiplier?: Maybe<Scalars['BigNumber']>;
  actionType: HiloActionType;
};

export enum HiloGuess {
  SKIP = 'SKIP',
  SAME_OR_ABOVE = 'SAME_OR_ABOVE',
  SAME_OR_BELOW = 'SAME_OR_BELOW',
}

export enum HiloActionType {
  START = 'START',
  CORRECT_GUESS = 'CORRECT_GUESS',
  WRONG_GUESS = 'WRONG_GUESS',
  SKIP = 'SKIP',
  CASHOUT = 'CASHOUT',
  FORCE_CASHOUT = 'FORCE_CASHOUT',
}

export type BlackjackActionModel = {
  __typename?: 'BlackjackActionModel';
  mainPlayerHand: Array<Scalars['Int']>;
  mainPlayerActions: Array<BlackjackAction>;
  splitPlayerHand: Array<Scalars['Int']>;
  splitPlayerActions: Array<BlackjackAction>;
  dealerHand: Array<Scalars['Int']>;
  perfectPairWin?: Maybe<PerfectPairType>;
  twentyOnePlusThreeWin?: Maybe<TwentyOnePlusThreeType>;
  twentyOnePlusThreeAmount: Scalars['CryptoAmount'];
  perfectPairAmount: Scalars['CryptoAmount'];
  insuranceStatus: InsuranceStatus;
  originalMainBetAmount: Scalars['CryptoAmount'];
  mainHandOutcome: HandOutcome;
  splitHandOutcome: HandOutcome;
};

export enum BlackjackAction {
  HIT = 'HIT',
  STAND = 'STAND',
  DOUBLE_DOWN = 'DOUBLE_DOWN',
  SPLIT = 'SPLIT',
  BUY_INSURANCE = 'BUY_INSURANCE',
  REJECT_INSURANCE = 'REJECT_INSURANCE',
}

export enum PerfectPairType {
  PERFECT_PAIR = 'PERFECT_PAIR',
  COLORED_PAIR = 'COLORED_PAIR',
  MIXED_PAIR = 'MIXED_PAIR',
}

export enum TwentyOnePlusThreeType {
  SUITED_TRIPS = 'SUITED_TRIPS',
  STRAIGHT_FLUSH = 'STRAIGHT_FLUSH',
  STRAIGHT = 'STRAIGHT',
  THREE_OF_A_KIND = 'THREE_OF_A_KIND',
  FLUSH = 'FLUSH',
}

export enum InsuranceStatus {
  INELIGIBLE = 'INELIGIBLE',
  BOUGHT_PAYS_OUT = 'BOUGHT_PAYS_OUT',
  BOUGHT_DOES_NOT_PAY_OUT = 'BOUGHT_DOES_NOT_PAY_OUT',
  REJECTED = 'REJECTED',
  ELIGIBLE = 'ELIGIBLE',
}

export enum HandOutcome {
  WIN = 'WIN',
  LOSS = 'LOSS',
  PUSH = 'PUSH',
  BLACKJACK = 'BLACKJACK',
  PENDING = 'PENDING',
  NONE = 'NONE',
}

export type ShuffleOriginalActionModel = {
  __typename?: 'ShuffleOriginalActionModel';
  dice?: Maybe<DiceActionModel>;
  mines?: Maybe<MinesActionModel>;
  plinko?: Maybe<PlinkoActionModel>;
  crash?: Maybe<CrashActionModel>;
  limbo?: Maybe<LimboActionModel>;
  keno?: Maybe<KenoActionModel>;
  hilo?: Maybe<HiloActionModel>;
  blackjack?: Maybe<BlackjackActionModel>;
};

export type ShuffleOriginalAction = {
  __typename?: 'ShuffleOriginalAction';
  id: Scalars['String'];
  action: ShuffleOriginalActionModel;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type CrashGame = {
  __typename?: 'CrashGame';
  id: Scalars['Float'];
  seed: Scalars['String'];
  crashPoint?: Maybe<Scalars['GraphqlCrashPoint']>;
  countOfPlayer?: Maybe<Scalars['Float']>;
  countOfCashedOut?: Maybe<Scalars['Float']>;
};

export type CrashBet = {
  __typename?: 'CrashBet';
  id: Scalars['String'];
  crashGameId?: Maybe<Scalars['Float']>;
  crashGame?: Maybe<CrashGame>;
  account: Account;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type Bet = {
  __typename?: 'Bet';
  id: Scalars['String'];
  account: Account;
  game?: Maybe<Game>;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  originalAmount: Scalars['CryptoAmount'];
  payout?: Maybe<Scalars['CryptoAmount']>;
  jackpot: Scalars['CryptoAmount'];
  multiplier?: Maybe<Scalars['Float']>;
  usdRate: Scalars['BigNumber'];
  shuffleOriginalActions?: Maybe<Array<ShuffleOriginalAction>>;
  completedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  gameSeed?: Maybe<GameSeed>;
  gameSeedNonce?: Maybe<Scalars['Int']>;
  crashBet?: Maybe<CrashBet>;
  fiatCurrency?: Maybe<FiatCurrency>;
  afterBalance?: Maybe<Scalars['CryptoAmount']>;
  replayUrl?: Maybe<Scalars['String']>;
};

export type PaginatedBet = {
  __typename?: 'PaginatedBet';
  nodes?: Maybe<Array<Bet>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type AdminAuditLog = {
  __typename?: 'AdminAuditLog';
  id: Scalars['String'];
  adminId?: Maybe<Scalars['String']>;
  admin?: Maybe<Admin>;
  ip: Scalars['String'];
  action: Scalars['String'];
  requestArgs: Scalars['JSON'];
  response?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
};

export type PaginatedAdminAuditLog = {
  __typename?: 'PaginatedAdminAuditLog';
  nodes?: Maybe<Array<AdminAuditLog>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type OriginalGameSetting = {
  __typename?: 'OriginalGameSetting';
  maxPayoutUSD: Scalars['Int'];
  maxBetUSD: Scalars['Int'];
  minBetUSD?: Maybe<Scalars['Float']>;
};

export type SportsBetSetting = {
  __typename?: 'SportsBetSetting';
  minBetUSD: Scalars['Float'];
  minPartialBetRatio: Scalars['Float'];
};

export type BlackjackGameSetting = {
  __typename?: 'BlackjackGameSetting';
  maxPayoutUSD: Scalars['Int'];
  maxBetUSD: Scalars['Int'];
  minBetUSD?: Maybe<Scalars['Float']>;
  sidebetLimit?: Maybe<Scalars['Int']>;
};

export type RewardSetting = {
  __typename?: 'RewardSetting';
  rakebackBps: Scalars['GraphqlPercent'];
  wagerBonusBps: Scalars['GraphqlPercent'];
  rakebackCapBps: Scalars['GraphqlPercent'];
};

export type RewardSettingWithLossback = {
  __typename?: 'RewardSettingWithLossback';
  rakebackBps: Scalars['GraphqlPercent'];
  wagerBonusBps: Scalars['GraphqlPercent'];
  rakebackCapBps: Scalars['GraphqlPercent'];
  lossbackBps: Scalars['GraphqlPercent'];
};

export type AppSettings = {
  __typename?: 'AppSettings';
  withdrawalFreeze: Scalars['Boolean'];
  withdrawalRetentionSeconds: Scalars['Int'];
  minTipUSD: Scalars['Int'];
  maxSportsPayoutUSD: Scalars['Int'];
  dice: OriginalGameSetting;
  mines: OriginalGameSetting;
  plinko: OriginalGameSetting;
  crash: OriginalGameSetting;
  limbo: OriginalGameSetting;
  keno: OriginalGameSetting;
  hilo: OriginalGameSetting;
  blackjack: BlackjackGameSetting;
  sportsBet: SportsBetSetting;
  geoRestrictedRegions: Array<Scalars['String']>;
  maxZeroBetCount: Scalars['Int'];
  vipUpgradeRates: RewardSetting;
  instantBonusRates: RewardSetting;
  dailyBonusRates: RewardSetting;
  weeklyBonusRates: RewardSettingWithLossback;
  monthlyBonusRates: RewardSettingWithLossback;
  adminUserActivityActionsOption: Array<Scalars['String']>;
  updatedByAdminId?: Maybe<Scalars['String']>;
  updatedByAdmin?: Maybe<Admin>;
};

export type UserWhitelist = {
  __typename?: 'UserWhitelist';
  id: Scalars['String'];
  email: Scalars['String'];
};

export type AdminUserNote = {
  __typename?: 'AdminUserNote';
  id: Scalars['ID'];
  comment: Scalars['String'];
  adminId: Scalars['String'];
  admin: Admin;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  deletedAt: Scalars['DateTime'];
};

export type PaginatedAdminUserNote = {
  __typename?: 'PaginatedAdminUserNote';
  nodes?: Maybe<Array<AdminUserNote>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type CryptoPrice = {
  __typename?: 'CryptoPrice';
  name: Currency;
  price: Scalars['BigNumber'];
};

export type FiatPrice = {
  __typename?: 'FiatPrice';
  name: FiatCurrency;
  price: Scalars['BigNumber'];
};

export type PromotionCodeLookup = {
  __typename?: 'PromotionCodeLookup';
  usdRedeemValue: Scalars['CryptoAmount'];
};

export type UserActivityLog = {
  __typename?: 'UserActivityLog';
  id: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  ip: Scalars['String'];
  action: Scalars['String'];
  actionType?: Maybe<LogActionType>;
  createdAt: Scalars['EpochToDate'];
  adminName?: Maybe<Scalars['String']>;
  adminId?: Maybe<Scalars['String']>;
  requestArgs?: Maybe<Scalars['JSON']>;
};

export enum LogActionType {
  ADMIN_ACTION = 'ADMIN_ACTION',
  USER_ACTION = 'USER_ACTION',
  ADMIN_NOTE = 'ADMIN_NOTE',
}

export type PaginatedUserActivityLog = {
  __typename?: 'PaginatedUserActivityLog';
  nodes?: Maybe<Array<UserActivityLog>>;
  lastKey?: Maybe<Scalars['JSON']>;
};

export type LoginRequestPayload = {
  __typename?: 'LoginRequestPayload';
  loginToken: Scalars['String'];
  loginVerificationMethod: LoginVerificationMethod;
  otpEmail?: Maybe<Scalars['String']>;
  otpSentAt?: Maybe<Scalars['DateTime']>;
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type ResendVerificationEmailPayload = {
  __typename?: 'ResendVerificationEmailPayload';
  id: Scalars['String'];
  emailVerificationSentAt?: Maybe<Scalars['DateTime']>;
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type OauthRequestPayload = {
  __typename?: 'OauthRequestPayload';
  loginUrl: Scalars['String'];
};

export type LoginRegisterRequestWithOauthPayload = {
  __typename?: 'LoginRegisterRequestWithOauthPayload';
  login?: Maybe<LoginPayload>;
  oauthToken?: Maybe<Scalars['String']>;
};

export type VerifyEmailPayload = {
  __typename?: 'VerifyEmailPayload';
  id: Scalars['String'];
  emailVerifiedAt: Scalars['DateTime'];
};

export type RaceLeaderBoardPayload = {
  __typename?: 'RaceLeaderBoardPayload';
  race: Race;
  leaderboard: Array<RaceRank>;
};

export type RaceRank = {
  __typename?: 'RaceRank';
  userId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  raceId: Scalars['String'];
  raceName: Scalars['String'];
  currency: Currency;
  wagered: Scalars['CryptoAmount'];
  prize: Scalars['BigNumber'];
  rank: Scalars['Int'];
  vipLevel?: Maybe<VipLevel>;
};

export type RaceLeaderBoardV2Payload = {
  __typename?: 'RaceLeaderBoardV2Payload';
  race: Race;
  raceWager: Array<RaceWagerPayload>;
};

export type RaceWagerPayload = {
  __typename?: 'RaceWagerPayload';
  raceId: Scalars['String'];
  raceEntryId: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  wagered: Scalars['CryptoAmount'];
  vipLevel?: Maybe<VipLevel>;
};

export type RaceRewardPayload = {
  __typename?: 'RaceRewardPayload';
  raceId: Scalars['String'];
  currency: Currency;
  wagered: Scalars['CryptoAmount'];
  amount: Scalars['CryptoAmount'];
  rank: Scalars['Int'];
  rewardStartDate: Scalars['DateTime'];
  rewardEndDate: Scalars['DateTime'];
};

export type RaceInfo = {
  __typename?: 'RaceInfo';
  id: Scalars['String'];
  startingWager: Scalars['CryptoAmount'];
  rank?: Maybe<Scalars['Int']>;
};

export type BetActivityPayload = {
  __typename?: 'BetActivityPayload';
  id: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  vipLevel: VipLevel;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  payout: Scalars['CryptoAmount'];
  multiplier: Scalars['String'];
  gameName: Scalars['String'];
  gameCategory?: Maybe<Scalars['String']>;
  gameSlug: Scalars['String'];
  payoutUSD?: Maybe<Scalars['CryptoAmount']>;
};

export type RolloverProgress = {
  __typename?: 'RolloverProgress';
  progress: Scalars['GraphqlPercent'];
  currency: Currency;
  unfinishedBets?: Maybe<Array<Bet>>;
};

export type AdminLoginPayload = {
  __typename?: 'AdminLoginPayload';
  id: Scalars['String'];
  adminToken: Scalars['String'];
};

export type TipRequestOtpPayload = {
  __typename?: 'TipRequestOtpPayload';
  otpEmail?: Maybe<Scalars['String']>;
  otpSentAt?: Maybe<Scalars['DateTime']>;
};

export type SportsCompetitorModel = {
  __typename?: 'SportsCompetitorModel';
  geniusId: Scalars['String'];
  name: Scalars['String'];
  shortName?: Maybe<Scalars['String']>;
  isHome: Scalars['Boolean'];
};

export type SportsMarketSelection = {
  __typename?: 'SportsMarketSelection';
  id: Scalars['String'];
  market: SportsMarket;
  name: Scalars['String'];
  oddsNumerator: Scalars['BigNumber'];
  oddsDenominator: Scalars['BigNumber'];
  status: SportsMarketSelectionStatus;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  resultStatus?: Maybe<SportsMarketSelectionResultStatus>;
};

export enum SportsMarketSelectionStatus {
  UNPRICED = 'UNPRICED',
  TRADING = 'TRADING',
  SUSPENDED = 'SUSPENDED',
  RESULTED = 'RESULTED',
}

export enum SportsMarketSelectionResultStatus {
  LOSER = 'LOSER',
  WINNER = 'WINNER',
  PARTIAL = 'PARTIAL',
  PLACED = 'PLACED',
  PUSHED = 'PUSHED',
}

export type SportsMarket = {
  __typename?: 'SportsMarket';
  id: Scalars['String'];
  name: Scalars['String'];
  typeId: Scalars['String'];
  typeName: Scalars['String'];
  selections: Array<SportsMarketSelection>;
  sequence?: Maybe<Scalars['Int']>;
  isHandicap: Scalars['Boolean'];
  inPlay: Scalars['Boolean'];
  expiryTime: Scalars['DateTime'];
  cashoutStatus?: Maybe<Scalars['String']>;
  cashoutEnabled: Scalars['Boolean'];
  highAvailabilityCashoutEnabled: Scalars['Boolean'];
  status: SportsMarketStatus;
  disabled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  fixture: SportsFixture;
};

export enum SportsMarketStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  RESULTED = 'RESULTED',
  SUSPENDED = 'SUSPENDED',
}

export type PaginatedSportsMarket = {
  __typename?: 'PaginatedSportsMarket';
  nodes?: Maybe<Array<SportsMarket>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type SportsMatchState = {
  __typename?: 'SportsMatchState';
  id: Scalars['String'];
  fixtureId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  matchSummary?: Maybe<SportsMatchSummary>;
};

export type SportsFixture = {
  __typename?: 'SportsFixture';
  id: Scalars['String'];
  slug: Scalars['String'];
  competitors: Array<SportsCompetitorModel>;
  name: Scalars['String'];
  featured: Scalars['Boolean'];
  outright: Scalars['Boolean'];
  startTime: Scalars['DateTime'];
  disabled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  markets: Array<SportsMarket>;
  competition: SportsCompetition;
  matchState?: Maybe<SportsMatchState>;
};

export type SportsFixtureMarketsArgs = {
  group?: InputMaybe<SportsMarketGroup>;
};

export enum SportsMarketGroup {
  DEFAULT_MARKETS = 'DEFAULT_MARKETS',
  TOP_MARKETS = 'TOP_MARKETS',
  FIRST_HALF_MARKETS = 'FIRST_HALF_MARKETS',
  SECOND_HALF_MARKETS = 'SECOND_HALF_MARKETS',
  FIRST_QUARTER_MARKETS = 'FIRST_QUARTER_MARKETS',
  SECOND_QUARTER_MARKETS = 'SECOND_QUARTER_MARKETS',
  THIRD_QUARTER_MARKETS = 'THIRD_QUARTER_MARKETS',
  FOURTH_QUARTER_MARKETS = 'FOURTH_QUARTER_MARKETS',
  FIRST_PERIOD_MARKETS = 'FIRST_PERIOD_MARKETS',
  SECOND_PERIOD_MARKETS = 'SECOND_PERIOD_MARKETS',
  THIRD_PERIOD_MARKETS = 'THIRD_PERIOD_MARKETS',
  WIN_MARKETS = 'WIN_MARKETS',
  HANDICAP_MARKETS = 'HANDICAP_MARKETS',
  FIRST_5_INNINGS_MARKETS = 'FIRST_5_INNINGS_MARKETS',
  TOTAL_MARKETS = 'TOTAL_MARKETS',
  GOALSCORER_MARKETS = 'GOALSCORER_MARKETS',
  CARDS_MARKETS = 'CARDS_MARKETS',
  SCORE_MARKETS = 'SCORE_MARKETS',
  TEAM_TO_SCORE_MARKETS = 'TEAM_TO_SCORE_MARKETS',
  CORRECT_SCORE_MARKETS = 'CORRECT_SCORE_MARKETS',
  DOUBLE_CHANCE_MARKETS = 'DOUBLE_CHANCE_MARKETS',
  CLEAN_SHEET_MARKETS = 'CLEAN_SHEET_MARKETS',
  CORNERS_MARKETS = 'CORNERS_MARKETS',
  PENALTY_MARKETS = 'PENALTY_MARKETS',
  RACE_MARKETS = 'RACE_MARKETS',
  PLAYER_H2H_MARKETS = 'PLAYER_H2H_MARKETS',
  TIME_OF_SCORE_MARKETS = 'TIME_OF_SCORE_MARKETS',
  TOUCHDOWN_MARKETS = 'TOUCHDOWN_MARKETS',
  PLAYER_OVERALL_MARKETS = 'PLAYER_OVERALL_MARKETS',
  WINNER_MARKETS = 'WINNER_MARKETS',
  SET_BETTING_MARKETS = 'SET_BETTING_MARKETS',
  GAMES_BETTING_MARKETS = 'GAMES_BETTING_MARKETS',
  TIE_BREAK_MARKETS = 'TIE_BREAK_MARKETS',
  MISCELLANEOUS_MARKETS = 'MISCELLANEOUS_MARKETS',
  METHOD_MARKETS = 'METHOD_MARKETS',
  GAME_1_MARKETS = 'GAME_1_MARKETS',
  GAME_2_MARKETS = 'GAME_2_MARKETS',
  GAME_3_MARKETS = 'GAME_3_MARKETS',
  GAME_4_MARKETS = 'GAME_4_MARKETS',
  GAME_5_MARKETS = 'GAME_5_MARKETS',
  GAME_6_MARKETS = 'GAME_6_MARKETS',
  GAME_7_MARKETS = 'GAME_7_MARKETS',
  PLACE_MARKETS = 'PLACE_MARKETS',
  MATCHUP_MARKETS = 'MATCHUP_MARKETS',
  TRYSCORER_MARKETS = 'TRYSCORER_MARKETS',
  FIRST_TO_MARKETS = 'FIRST_TO_MARKETS',
  LAST_TO_MARKETS = 'LAST_TO_MARKETS',
  OTHER_MARKETS = 'OTHER_MARKETS',
  KILL_MARKETS = 'KILL_MARKETS',
  ROSHAN_MARKETS = 'ROSHAN_MARKETS',
  NEXT_TO_HAPPEN_MARKETS = 'NEXT_TO_HAPPEN_MARKETS',
  TOWER_MARKETS = 'TOWER_MARKETS',
  INHIBITOR_MARKETS = 'INHIBITOR_MARKETS',
  DRAGON_MARKETS = 'DRAGON_MARKETS',
  BARRACKS_MARKETS = 'BARRACKS_MARKETS',
  MAP_1_MARKETS = 'MAP_1_MARKETS',
  MAP_2_MARKETS = 'MAP_2_MARKETS',
  MAP_3_MARKETS = 'MAP_3_MARKETS',
  MAP_4_MARKETS = 'MAP_4_MARKETS',
  MAP_5_MARKETS = 'MAP_5_MARKETS',
  HALF_MARKETS = 'HALF_MARKETS',
  BTTS_MARKETS = 'BTTS_MARKETS',
  HANDICAP_AFTER_10_MINUTES_MARKETS = 'HANDICAP_AFTER_10_MINUTES_MARKETS',
  HANDICAP_AFTER_15_MINUTES_MARKETS = 'HANDICAP_AFTER_15_MINUTES_MARKETS',
  HANDICAP_AFTER_20_MINUTES_MARKETS = 'HANDICAP_AFTER_20_MINUTES_MARKETS',
  HANDICAP_AFTER_25_MINUTES_MARKETS = 'HANDICAP_AFTER_25_MINUTES_MARKETS',
  HANDICAP_AFTER_30_MINUTES_MARKETS = 'HANDICAP_AFTER_30_MINUTES_MARKETS',
  HANDICAP_AFTER_35_MINUTES_MARKETS = 'HANDICAP_AFTER_35_MINUTES_MARKETS',
  HANDICAP_AFTER_40_MINUTES_MARKETS = 'HANDICAP_AFTER_40_MINUTES_MARKETS',
  HANDICAP_AFTER_5_MINUTES_MARKETS = 'HANDICAP_AFTER_5_MINUTES_MARKETS',
  HANDICAP_AFTER_50_MINUTES_MARKETS = 'HANDICAP_AFTER_50_MINUTES_MARKETS',
  HANDICAP_AFTER_55_MINUTES_MARKETS = 'HANDICAP_AFTER_55_MINUTES_MARKETS',
  HANDICAP_AFTER_60_MINUTES_MARKETS = 'HANDICAP_AFTER_60_MINUTES_MARKETS',
  HANDICAP_AFTER_65_MINUTES_MARKETS = 'HANDICAP_AFTER_65_MINUTES_MARKETS',
  HANDICAP_AFTER_70_MINUTES_MARKETS = 'HANDICAP_AFTER_70_MINUTES_MARKETS',
  HANDICAP_AFTER_75_MINUTES_MARKETS = 'HANDICAP_AFTER_75_MINUTES_MARKETS',
  HANDICAP_AFTER_80_MINUTES_MARKETS = 'HANDICAP_AFTER_80_MINUTES_MARKETS',
  HANDICAP_AFTER_85_MINUTES_MARKETS = 'HANDICAP_AFTER_85_MINUTES_MARKETS',
  HANDICAP_AFTER_EXTRA_TIME_INCLUDING_NORMAL_TIME_GOALS_MARKETS = 'HANDICAP_AFTER_EXTRA_TIME_INCLUDING_NORMAL_TIME_GOALS_MARKETS',
  HANDICAP_AT_EXTRA_TIME_HALF_TIME_INCLUDING_NORMAL_TIME_GOALS_MARKETS = 'HANDICAP_AT_EXTRA_TIME_HALF_TIME_INCLUDING_NORMAL_TIME_GOALS_MARKETS',
  HANDICAP_HALF_TIME_MARKETS = 'HANDICAP_HALF_TIME_MARKETS',
  HANDICAP_SECOND_HALF_MARKETS = 'HANDICAP_SECOND_HALF_MARKETS',
}

export type PaginatedSportsFixture = {
  __typename?: 'PaginatedSportsFixture';
  nodes?: Maybe<Array<SportsFixture>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type SportsFixtureSearchResult = {
  __typename?: 'SportsFixtureSearchResult';
  nodes?: Maybe<Array<SportsFixture>>;
  totalCount: Scalars['Int'];
};

export type SportsCompetition = {
  __typename?: 'SportsCompetition';
  id: Scalars['String'];
  slug: Scalars['String'];
  category: SportsCategory;
  name: Scalars['String'];
  disabled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  fixtures: PaginatedSportsFixture;
};

export type SportsCompetitionFixturesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  sports?: InputMaybe<Sports>;
  categoryId?: InputMaybe<Scalars['String']>;
  competitionId?: InputMaybe<Scalars['String']>;
  upcomingHour?: InputMaybe<Scalars['Int']>;
  liveOnly?: InputMaybe<Scalars['Boolean']>;
};

export enum Sports {
  AMERICAN_FOOTBALL = 'AMERICAN_FOOTBALL',
  BASEBALL = 'BASEBALL',
  BASKETBALL = 'BASKETBALL',
  ICE_HOCKEY = 'ICE_HOCKEY',
  SOCCER = 'SOCCER',
  TENNIS = 'TENNIS',
  ESPORTS = 'ESPORTS',
  MMA = 'MMA',
  BOXING = 'BOXING',
  TABLE_TENNIS = 'TABLE_TENNIS',
  GOLF = 'GOLF',
  RUGBY_UNION = 'RUGBY_UNION',
}

export type PaginatedSportsCompetition = {
  __typename?: 'PaginatedSportsCompetition';
  nodes?: Maybe<Array<SportsCompetition>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type SportsCompetitionSearchResult = {
  __typename?: 'SportsCompetitionSearchResult';
  nodes?: Maybe<Array<SportsCompetition>>;
  totalCount: Scalars['Int'];
};

export type SportsCategory = {
  __typename?: 'SportsCategory';
  id: Scalars['String'];
  slug: Scalars['String'];
  sports: Sports;
  name: Scalars['String'];
  disabled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  competitions: PaginatedSportsCompetition;
};

export type SportsCategoryCompetitionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  sports?: InputMaybe<Sports>;
  categoryId?: InputMaybe<Scalars['String']>;
  upcomingHour?: InputMaybe<Scalars['Int']>;
  liveOnly?: InputMaybe<Scalars['Boolean']>;
};

export type PaginatedSportsCategory = {
  __typename?: 'PaginatedSportsCategory';
  nodes?: Maybe<Array<SportsCategory>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type SportsCategorySearchResult = {
  __typename?: 'SportsCategorySearchResult';
  nodes?: Maybe<Array<SportsCategory>>;
  totalCount: Scalars['Int'];
};

export type StartGamePayload = {
  __typename?: 'StartGamePayload';
  gameUrl?: Maybe<Scalars['String']>;
  strategy: Scalars['String'];
};

export type GameCountByCategory = {
  __typename?: 'GameCountByCategory';
  category: Scalars['String'];
  gamesCount: Scalars['Int'];
};

export type CrashBetPayoutPayload = {
  __typename?: 'CrashBetPayoutPayload';
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  betAt: Scalars['BetTarget'];
  payout?: Maybe<Scalars['CryptoAmount']>;
  multiplier?: Maybe<Scalars['BigNumber']>;
  payoutType?: Maybe<PayoutType>;
  user?: Maybe<User>;
  betId: Scalars['String'];
};

export type CrashGamePayload = {
  __typename?: 'CrashGamePayload';
  crashGameId: Scalars['Float'];
  crashHistories: Array<CrashGame>;
  betPayoutPayloads: Array<CrashBetPayoutPayload>;
  myBetId?: Maybe<Scalars['String']>;
};

export type CrashBetActivityPayload = {
  __typename?: 'CrashBetActivityPayload';
  id: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  vipLevel: VipLevel;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  payout: Scalars['CryptoAmount'];
  gameName: Scalars['String'];
  gameCategory?: Maybe<Scalars['String']>;
  gameSlug: Scalars['String'];
  payoutUSD?: Maybe<Scalars['CryptoAmount']>;
  multiplier?: Maybe<Scalars['String']>;
};

export type GameCountByProvider = {
  __typename?: 'GameCountByProvider';
  provider: GameProvider;
  gamesCount: Scalars['Int'];
};

export type SportsBetCashoutAvailable = {
  __typename?: 'SportsBetCashoutAvailable';
  canCashout: Scalars['Boolean'];
  reason?: Maybe<Scalars['String']>;
};

export type SportsBetActivity = {
  __typename?: 'SportsBetActivity';
  id: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  vipLevel: VipLevel;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  oddsDecimal: Scalars['BigNumber'];
  selectionNumber: Scalars['Float'];
  fixtureName: Scalars['String'];
  fixtureSlug: Scalars['String'];
  fixtureStatus: SportsFixtureStatus;
  competitionName: Scalars['String'];
  competitionSlug: Scalars['String'];
  categoryName: Scalars['String'];
  categorySlug: Scalars['String'];
  sports: Sports;
};

export enum SportsFixtureStatus {
  SCHEDULED = 'SCHEDULED',
  ENDED = 'ENDED',
  RESULTED = 'RESULTED',
  DELETED = 'DELETED',
}

export type SportsBetSelectionSnapshot = {
  __typename?: 'SportsBetSelectionSnapshot';
  marketId: Scalars['String'];
  marketName: Scalars['String'];
  marketTypeName: Scalars['String'];
  marketExpiryTime: Scalars['DateTime'];
  fixtureId: Scalars['String'];
  fixtureSlug: Scalars['String'];
  fixtureName: Scalars['String'];
  fixtureStartTime: Scalars['DateTime'];
  fixtureStatus: SportsFixtureStatus;
  competitionId: Scalars['String'];
  competitionSlug: Scalars['String'];
  competitionName: Scalars['String'];
  categoryId: Scalars['String'];
  categorySlug: Scalars['String'];
  categoryName: Scalars['String'];
  sports: Sports;
};

export type WithdrawalRequestOtpPayload = {
  __typename?: 'WithdrawalRequestOtpPayload';
  otpEmail?: Maybe<Scalars['String']>;
  otpSentAt?: Maybe<Scalars['DateTime']>;
};

export type AdminBetStatistic = {
  __typename?: 'AdminBetStatistic';
  noOfBet: Scalars['BigNumber'];
  wagered: Scalars['CryptoAmount'];
  payout: Scalars['CryptoAmount'];
  currency: Currency;
  theoreticalEdge: Scalars['CryptoAmount'];
  wageredInUsd: Scalars['CryptoAmount'];
  game?: Maybe<Game>;
};

export type AdminTransactionStatistic = {
  __typename?: 'AdminTransactionStatistic';
  noOfTransaction: Scalars['BigNumber'];
  amount: Scalars['CryptoAmount'];
  currency: Currency;
  transactionType: TransactionType;
};

export type AdminSportsBetStatistic = {
  __typename?: 'AdminSportsBetStatistic';
  noOfBet: Scalars['BigNumber'];
  noOfSettledBet: Scalars['BigNumber'];
  wagered: Scalars['CryptoAmount'];
  payout: Scalars['CryptoAmount'];
  currency: Currency;
};

export type AdminTransactionValue = {
  __typename?: 'AdminTransactionValue';
  type: TransactionType;
  amount: Scalars['CryptoAmount'];
};

export type AdminDepositStatistic = {
  __typename?: 'AdminDepositStatistic';
  noOfDeposit: Scalars['BigNumber'];
  amount: Scalars['CryptoAmount'];
  currency: Currency;
};

export type AdminWithdrawalStatistic = {
  __typename?: 'AdminWithdrawalStatistic';
  noOfWithdrawal: Scalars['BigNumber'];
  amount: Scalars['CryptoAmount'];
  currency: Currency;
};

export type AdminRoleMatrix = {
  __typename?: 'AdminRoleMatrix';
  role: AdminRole;
  powers: Array<Power>;
};

export type Power = {
  __typename?: 'Power';
  ability: AdminAction;
  permission: AdminPermission;
};

export enum AdminAction {
  MANAGE = 'MANAGE',
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum AdminPermission {
  ADMIN = 'ADMIN',
  ADMIN_ROLE_MATRIX = 'ADMIN_ROLE_MATRIX',
  ADMIN_AUDIT_LOG = 'ADMIN_AUDIT_LOG',
  USER_DETAILS = 'USER_DETAILS',
  USER_BALANCE_ADJUSTMENT = 'USER_BALANCE_ADJUSTMENT',
  ADMIN_CREDIT = 'ADMIN_CREDIT',
  USER = 'USER',
  GAME = 'GAME',
  CRASH_GAME = 'CRASH_GAME',
  GAME_PROVIDER = 'GAME_PROVIDER',
  PROMOTION = 'PROMOTION',
  CHAIN_CURRENCY = 'CHAIN_CURRENCY',
  ROLLOVER = 'ROLLOVER',
  CAMPAIGN = 'CAMPAIGN',
  CHAT = 'CHAT',
  WITHDRAWAL = 'WITHDRAWAL',
  HOT_WALLET = 'HOT_WALLET',
  APP_SETTING = 'APP_SETTING',
  APP_SETTING_WITHDRAWAL = 'APP_SETTING_WITHDRAWAL',
  SPORTS = 'SPORTS',
  VOID_SPORTS_BET = 'VOID_SPORTS_BET',
  CHALLENGE = 'CHALLENGE',
  VIP_BONUS = 'VIP_BONUS',
}

export type HotWalletBalance = {
  __typename?: 'HotWalletBalance';
  currency: Currency;
  balance: Scalars['CryptoAmount'];
  chain: Chain;
};

export type SportsMatchPhaseSummary = {
  __typename?: 'SportsMatchPhaseSummary';
  phase: Scalars['Int'];
  homeScore?: Maybe<Scalars['Int']>;
  awayScore?: Maybe<Scalars['Int']>;
  status?: Maybe<SportsMatchPhaseStatus>;
};

export enum SportsMatchPhaseStatus {
  TOP = 'Top',
  MIDDLE = 'Middle',
  BOTTOM = 'Bottom',
  FINISHED = 'Finished',
}

export type SportsMatchSummary = {
  __typename?: 'SportsMatchSummary';
  currentPhaseName?: Maybe<Scalars['String']>;
  matchPhase?: Maybe<SportsMatchPhase>;
  homeScore?: Maybe<Scalars['Int']>;
  awayScore?: Maybe<Scalars['Int']>;
  server?: Maybe<Scalars['String']>;
  phases?: Maybe<Array<SportsMatchPhaseSummary>>;
  timeRemaining?: Maybe<Scalars['Int']>;
  timeRemainingAt?: Maybe<Scalars['DateTime']>;
};

export enum SportsMatchPhase {
  PREGAME = 'PreGame',
  FIRSTQUARTER = 'FirstQuarter',
  BEFORESECONDQUARTER = 'BeforeSecondQuarter',
  SECONDQUARTER = 'SecondQuarter',
  FIRSTHALF = 'FirstHalf',
  HALFTIME = 'HalfTime',
  THIRDQUARTER = 'ThirdQuarter',
  BEFOREFOURTHQUARTER = 'BeforeFourthQuarter',
  FOURTHQUARTER = 'FourthQuarter',
  SECONDHALF = 'SecondHalf',
  BEFOREOVERTIME = 'BeforeOvertime',
  OVERTIME = 'Overtime',
  POSTGAME = 'PostGame',
  GAMEABANDONED = 'GameAbandoned',
  PREMATCH = 'PreMatch',
  FULLTIMENORMALTIME = 'FullTimeNormalTime',
  EXTRATIMEFIRSTHALF = 'ExtraTimeFirstHalf',
  EXTRATIMEHALFTIME = 'ExtraTimeHalfTime',
  EXTRATIMESECONDHALF = 'ExtraTimeSecondHalf',
  FULLTIMEEXTRATIME = 'FullTimeExtraTime',
  PENALTIES = 'Penalties',
  POSTMATCH = 'PostMatch',
  MATCHABANDONED = 'MatchAbandoned',
  INPROGRESS = 'InProgress',
  SUSPENDED = 'Suspended',
  CANCELLED = 'Cancelled',
  POSTPONED = 'Postponed',
  ABANDONED = 'Abandoned',
  FIRSTPERIOD = 'FirstPeriod',
  ENDOFFIRSTPERIOD = 'EndOfFirstPeriod',
  SECONDPERIOD = 'SecondPeriod',
  ENDOFSECONDPERIOD = 'EndOfSecondPeriod',
  THIRDPERIOD = 'ThirdPeriod',
  ENDOFTHIRDPERIOD = 'EndOfThirdPeriod',
  OVERTIMEPERIOD = 'OvertimePeriod',
  ENDOFOVERTIMEPERIOD = 'EndOfOvertimePeriod',
  PENALTYSHOOTOUT = 'PenaltyShootout',
  INPLAY = 'InPlay',
  BREAKINPLAY = 'BreakInPlay',
  COMPLETED = 'Completed',
}

export type SportsMarketDetails = {
  __typename?: 'SportsMarketDetails';
  wagered: Scalars['CryptoAmount'];
  status: SportsMarketStatus;
  selections: Array<SportsMarketSelection>;
};

export type AdminCreditPayload = {
  __typename?: 'AdminCreditPayload';
  usdAmount: Scalars['CryptoAmount'];
};

export type PaginatedVipBonus = {
  __typename?: 'PaginatedVipBonus';
  nodes?: Maybe<Array<VipBonus>>;
  totalCount: Scalars['Int'];
  nextCursor?: Maybe<Scalars['DateTime']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  address: Scalars['String'];
  legacyAddress?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  currency: Currency;
  chain: Chain;
};

export type DailyRakebackAmount = {
  __typename?: 'DailyRakebackAmount';
  currencyAmounts: Array<CurrencyAmount>;
  claimDate: Scalars['DateTime'];
};

export type MonthlyBonusClaimAmount = {
  __typename?: 'MonthlyBonusClaimAmount';
  claimedAmount: CurrencyAmount;
  claimMonth: Scalars['String'];
};

export type MonthlyBonusAmount = {
  __typename?: 'MonthlyBonusAmount';
  usdValue: Scalars['CryptoAmount'];
  claimMonth: Scalars['String'];
};

export type WeeklyBonusAmount = {
  __typename?: 'WeeklyBonusAmount';
  usdValue: Scalars['CryptoAmount'];
  claimDate: Scalars['DateTime'];
};

export type WeeklyBonusClaimAmount = {
  __typename?: 'WeeklyBonusClaimAmount';
  claimedAmount: CurrencyAmount;
  claimDate: Scalars['DateTime'];
};

export type DateType = {
  __typename?: 'DateType';
  seconds: Scalars['String'];
  nanoseconds: Scalars['String'];
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['String'];
  vipLevel: VipLevel;
  messageType: Scalars['String'];
  uid: Scalars['String'];
  username: Scalars['String'];
  tipId?: Maybe<Scalars['String']>;
  tipReceivers: Array<Scalars['String']>;
  tipRainId?: Maybe<Scalars['String']>;
  unreadUsers: Array<Scalars['String']>;
  mentioned_users: Array<Scalars['String']>;
  text: Scalars['String'];
  tipCurrency: Scalars['String'];
  timestamp: DateType;
  tipAmount: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
};

export type AdminInput = {
  id: Scalars['String'];
  username: Scalars['String'];
  generatedPassword?: InputMaybe<Scalars['String']>;
  role: AdminRole;
  status: AdminStatus;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

export type UserFlagInput = {
  id: Scalars['String'];
  withdrawalHeld: Scalars['Boolean'];
  withdrawalDisabled: Scalars['Boolean'];
  cancelWithdrawalDisabled: Scalars['Boolean'];
  gamePlayDisabled: Scalars['Boolean'];
  reducedRestriction: Scalars['Boolean'];
  raceIneligible: Scalars['Boolean'];
  commissionGenerationDisabled: Scalars['Boolean'];
  rewardGenerationDisabled: Scalars['Boolean'];
  recurringRewardDisabled: Scalars['Boolean'];
  rainReceivingDisabled: Scalars['Boolean'];
  tipOrRainSendingDisabled: Scalars['Boolean'];
  promotionCodeRedemptionDisabled: Scalars['Boolean'];
  commissionRedemptionDisabled: Scalars['Boolean'];
};

export type OriginalGameSettingInput = {
  maxPayoutUSD: Scalars['Int'];
  maxBetUSD: Scalars['Int'];
  minBetUSD?: InputMaybe<Scalars['Float']>;
};

export type SportsBetSettingInput = {
  minBetUSD: Scalars['Float'];
  minPartialBetRatio: Scalars['Float'];
};

export type BlackjackGameSettingInput = {
  maxPayoutUSD: Scalars['Int'];
  maxBetUSD: Scalars['Int'];
  minBetUSD?: InputMaybe<Scalars['Float']>;
  sidebetLimit?: InputMaybe<Scalars['Int']>;
};

export type RewardSettingInput = {
  rakebackBps: Scalars['GraphqlPercent'];
  wagerBonusBps: Scalars['GraphqlPercent'];
  rakebackCapBps: Scalars['GraphqlPercent'];
};

export type RewardSettingWithLossbackInput = {
  rakebackBps: Scalars['GraphqlPercent'];
  wagerBonusBps: Scalars['GraphqlPercent'];
  rakebackCapBps: Scalars['GraphqlPercent'];
  lossbackBps: Scalars['GraphqlPercent'];
};

export type UpdateAppSettingsInput = {
  withdrawalFreeze: Scalars['Boolean'];
  withdrawalRetentionSeconds: Scalars['Int'];
  minTipUSD: Scalars['Int'];
  maxSportsPayoutUSD: Scalars['Int'];
  dice: OriginalGameSettingInput;
  mines: OriginalGameSettingInput;
  plinko: OriginalGameSettingInput;
  crash: OriginalGameSettingInput;
  limbo: OriginalGameSettingInput;
  keno: OriginalGameSettingInput;
  hilo: OriginalGameSettingInput;
  blackjack?: BlackjackGameSettingInput;
  sportsBet: SportsBetSettingInput;
  geoRestrictedRegions: Array<Scalars['String']>;
  maxZeroBetCount: Scalars['Int'];
  vipUpgradeRates: RewardSettingInput;
  instantBonusRates: RewardSettingInput;
  dailyBonusRates: RewardSettingInput;
  weeklyBonusRates: RewardSettingWithLossbackInput;
  monthlyBonusRates: RewardSettingWithLossbackInput;
  adminUserActivityActionsOption: Array<Scalars['String']>;
  updatedByAdminId?: InputMaybe<Scalars['String']>;
  updatedByAdmin?: InputMaybe<AdminInput>;
};

export type Query = {
  __typename?: 'Query';
  me: User;
  favouriteGameIds: Array<Scalars['String']>;
  user: User;
  referees: PaginatedUser;
  sessions: Array<UserSession>;
  onlineSession: Scalars['Float'];
  cryptoPrice: CryptoPrice;
  cryptoPrices: Array<CryptoPrice>;
  fiatPrices: Array<FiatPrice>;
  gameSeeds: PaginatedGameSeed;
  currentGameSeeds: Array<GameSeed>;
  gameSeedUnhashed: GameSeed;
  bet: Bet;
  bets: PaginatedBet;
  openBets: Array<Bet>;
  latestBets: Array<BetActivityPayload>;
  highRollerBets: Array<BetActivityPayload>;
  transactions: PaginatedTransaction;
  vipLevels: Array<Vip>;
  appSettings: AppSettings;
  challenges: PaginatedChallenges;
  challengeCompletedByCurrentUser: PaginatedChallenges;
  challengeRewards: Array<ChallengeReward>;
  races: Array<Race>;
  raceLeaderBoard: RaceLeaderBoardPayload;
  raceLeaderBoardV2: RaceLeaderBoardV2Payload;
  raceUserReward: Array<RaceRewardPayload>;
  myRaceInfo: RaceInfo;
  adminGetAuditLogs: PaginatedAdminAuditLog;
  adminGetUserActivityLogs: PaginatedUserActivityLog;
  instantRakebackBonus: Array<CurrencyAmount>;
  crashGame: CrashGamePayload;
  getCrashHistories: Array<CrashGame>;
  getCrashGame: CrashGame;
  getCrashGameByBetId: CrashGame;
  getCrashGameLeaderboard: Array<CrashBetActivityPayload>;
  minesActiveBet?: Maybe<Bet>;
  hiloActiveBet?: Maybe<Bet>;
  blackjackActiveBet?: Maybe<Bet>;
  game: Game;
  games: PaginatedGame;
  gameTags: Array<GameTag>;
  getGameCountByCategory: Array<GameCountByCategory>;
  gameProviders: Array<GameProvider>;
  getGameCountByProvider: Array<GameCountByProvider>;
  sportsCategory: SportsCategory;
  sportsCategories: Array<SportsCategory>;
  sportsCompetition: SportsCompetition;
  sportsCompetitions: PaginatedSportsCompetition;
  sportsFixture: SportsFixture;
  sportsFixtures: PaginatedSportsFixture;
  sportsMarket: SportsMarket;
  sportsMarkets: Array<SportsMarket>;
  sportsMatchState: SportsMatchState;
  sportsMatchStates: Array<SportsMatchState>;
  searchSportsCategories: SportsCategorySearchResult;
  searchSportsCompetitions: SportsCompetitionSearchResult;
  searchSportsFixtures: SportsFixtureSearchResult;
  sportsBet: SportsBet;
  sportsBets: PaginatedSportsBet;
  latestSportsBets: Array<SportsBetActivity>;
  highRollerSportsBets: Array<SportsBetActivity>;
  kyc?: Maybe<Kyc>;
  tipRainInfo: TipRain;
  deposits: PaginatedDeposit;
  adminGetAdmins: PaginatedAdmin;
  adminGetAdmin: Admin;
  adminGetMe: AdminWithRoleMatrix;
  adminRoleMatrices: Array<AdminRoleMatrix>;
  adminGetUserBalanceAdjustment: AdminBalanceAdjustment;
  adminGetUserBalances: Array<CurrencyAmount>;
  adminGetUserVaultBalances: Array<CurrencyAmount>;
  adminSearchUser: User;
  adminGetUserTransactions: PaginatedTransaction;
  adminGetUserTransactionDetails: Transaction;
  adminGetRollovers: Array<Rollover>;
  adminGetPromotionCodes: PaginatedPromotionCode;
  adminGetPromotionCode: PromotionCode;
  adminGetPromotionRedemptions: PaginatedPromotionRedemption;
  lookupPromotionCode: PromotionCodeLookup;
  adminGetChainCurrencies: Array<ChainCurrency>;
  adminGetGame: Game;
  adminGetGames: PaginatedGame;
  adminGetGameProviders: Array<GameProvider>;
  adminGetBetStatistics: Array<AdminBetStatistic>;
  adminGetSportsBetStatistics: Array<AdminSportsBetStatistic>;
  adminGetTransactionStatistics: Array<AdminTransactionStatistic>;
  adminGetTransactionUsdValueByType: Array<AdminTransactionValue>;
  adminGetDepositStatistics: Array<AdminDepositStatistic>;
  adminGetWithdrawalStatistics: Array<AdminWithdrawalStatistic>;
  withdrawals: PaginatedWithdrawal;
  validateAddress: Scalars['Boolean'];
  adminGetUserWhitelist: Array<UserWhitelist>;
  adminGetCampaigns: Array<Campaign>;
  adminGetReferees: PaginatedUser;
  campaigns: Array<Campaign>;
  adminFindWithdrawals: PaginatedWithdrawal;
  adminGetHotWalletBalances: Array<HotWalletBalance>;
  adminGetSportsCategories: PaginatedSportsCategory;
  adminGetSportsCompetitions: PaginatedSportsCompetition;
  adminGetSportsFixtures: PaginatedSportsFixture;
  adminGetSportsMarkets: PaginatedSportsMarket;
  adminSportsMarketDetails: SportsMarketDetails;
  adminGetSportsBet: SportsBet;
  adminGetSportsBets: PaginatedSportsBet;
  adminGetAdminCredit: AdminCreditPayload;
  adminGetChallenges: PaginatedChallenges;
  adminGetUserOpenBets: Array<Bet>;
  adminAppSettings: AppSettings;
  adminGetVipBonus: PaginatedVipBonus;
  vipBonus: Array<VipBonus>;
  adminGetUserNotes: PaginatedAdminUserNote;
  adminGetUserSessions: Array<UserSession>;
  wallets: Array<Wallet>;
  vipDailyRakeback: DailyRakebackAmount;
  vipWeeklyBonus: WeeklyBonusAmount;
  vipMonthlyBonus: MonthlyBonusAmount;
  vipUpgradeBonuses: Array<VipUserUpgrade>;
  chainsCurrencies: Array<ChainCurrency>;
  getChatMessages: Array<Message>;
  version: Scalars['String'];
};

export type QueryUserArgs = {
  where: UserWhereInput;
};

export type QueryRefereesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  campaignId?: InputMaybe<Scalars['String']>;
};

export type QuerySessionsArgs = {
  inactive?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
};

export type QueryCryptoPriceArgs = {
  crypto: Currency;
  currency: FiatCurrency;
};

export type QueryCryptoPricesArgs = {
  currency: FiatCurrency;
};

export type QueryGameSeedsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
};

export type QueryGameSeedUnhashedArgs = {
  hashedSeed: Scalars['String'];
};

export type QueryBetArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type QueryBetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency>>;
};

export type QueryLatestBetsArgs = {
  count?: InputMaybe<Scalars['Int']>;
};

export type QueryHighRollerBetsArgs = {
  count?: InputMaybe<Scalars['Int']>;
};

export type QueryTransactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  typeIn?: InputMaybe<Array<TransactionType>>;
  typeNotIn?: InputMaybe<Array<TransactionType>>;
  currencyIn?: InputMaybe<Array<Currency>>;
};

export type QueryChallengesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  completed?: InputMaybe<Scalars['Boolean']>;
};

export type QueryChallengeCompletedByCurrentUserArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
};

export type QueryRacesArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
};

export type QueryRaceLeaderBoardArgs = {
  raceId?: InputMaybe<Scalars['String']>;
};

export type QueryAdminGetAuditLogsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  adminId?: InputMaybe<Scalars['String']>;
};

export type QueryAdminGetUserActivityLogsArgs = {
  first?: InputMaybe<Scalars['Int']>;
  excludedKey?: InputMaybe<Scalars['JSON']>;
  userId?: InputMaybe<Scalars['String']>;
  logActionTypes?: InputMaybe<Array<LogActionType>>;
  actions?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Scalars['Timestamp']>;
  to?: InputMaybe<Scalars['Timestamp']>;
};

export type QueryGetCrashGameArgs = {
  crashGameId: Scalars['Float'];
};

export type QueryGetCrashGameByBetIdArgs = {
  betId?: InputMaybe<Scalars['String']>;
};

export type QueryGetCrashGameLeaderboardArgs = {
  crashGameId: Scalars['Float'];
};

export type QueryGameArgs = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  originalGame?: InputMaybe<OriginalGame>;
};

export type QueryGamesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  providers?: InputMaybe<Array<Scalars['String']>>;
  providerSlug?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<GameCategoryType>>;
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<GameSortby>;
  isFavourite?: InputMaybe<Scalars['Boolean']>;
  sortRandomSeed?: InputMaybe<Scalars['Int']>;
};

export type QueryGetGameCountByCategoryArgs = {
  tags?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  providers?: InputMaybe<Array<Scalars['String']>>;
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  isFavourite?: InputMaybe<Scalars['Boolean']>;
  providerSlug?: InputMaybe<Scalars['String']>;
};

export type QueryGetGameCountByProviderArgs = {
  tags?: InputMaybe<Array<Scalars['String']>>;
  search?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<GameCategoryType>>;
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  isFavourite?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySportsCategoryArgs = {
  slug?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type QuerySportsCategoriesArgs = {
  sports: Sports;
  liveOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySportsCompetitionArgs = {
  slug?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type QuerySportsCompetitionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  sports?: InputMaybe<Sports>;
  categoryId?: InputMaybe<Scalars['String']>;
  upcomingHour?: InputMaybe<Scalars['Int']>;
  liveOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySportsFixtureArgs = {
  slug?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type QuerySportsFixturesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  sports?: InputMaybe<Sports>;
  categoryId?: InputMaybe<Scalars['String']>;
  competitionId?: InputMaybe<Scalars['String']>;
  upcomingHour?: InputMaybe<Scalars['Int']>;
  liveOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySportsMarketArgs = {
  id: Scalars['String'];
};

export type QuerySportsMarketsArgs = {
  fixtureId: Scalars['String'];
  group?: InputMaybe<SportsMarketGroup>;
};

export type QuerySportsMatchStateArgs = {
  fixtureId: Scalars['String'];
};

export type QuerySportsMatchStatesArgs = {
  fixtureIds: Array<Scalars['String']>;
};

export type QuerySearchSportsCategoriesArgs = {
  keyword: Scalars['String'];
  filters?: InputMaybe<SportsSearchFilterArgs>;
};

export type QuerySearchSportsCompetitionsArgs = {
  keyword: Scalars['String'];
  filters?: InputMaybe<SportsSearchFilterArgs>;
};

export type QuerySearchSportsFixturesArgs = {
  keyword: Scalars['String'];
  filters?: InputMaybe<SportsSearchFilterArgs>;
};

export type QuerySportsBetArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type QuerySportsBetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<SportsBetStatus>>;
  currencyIn?: InputMaybe<Array<Currency>>;
};

export type QueryLatestSportsBetsArgs = {
  count?: InputMaybe<Scalars['Int']>;
};

export type QueryHighRollerSportsBetsArgs = {
  count?: InputMaybe<Scalars['Int']>;
};

export type QueryTipRainInfoArgs = {
  tipRainId: Scalars['String'];
};

export type QueryDepositsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency>>;
};

export type QueryAdminGetAdminsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
};

export type QueryAdminGetAdminArgs = {
  username: Scalars['String'];
};

export type QueryAdminGetUserBalanceAdjustmentArgs = {
  adjustmentId: Scalars['String'];
};

export type QueryAdminGetUserBalancesArgs = {
  userId: Scalars['String'];
};

export type QueryAdminGetUserVaultBalancesArgs = {
  userId: Scalars['String'];
};

export type QueryAdminSearchUserArgs = {
  where: UserWhereInput;
};

export type QueryAdminGetUserTransactionsArgs = {
  typeIn?: InputMaybe<Array<TransactionType>>;
  typeNotIn?: InputMaybe<Array<TransactionType>>;
  currencyIn?: InputMaybe<Array<Currency>>;
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type QueryAdminGetUserTransactionDetailsArgs = {
  transactionId: Scalars['String'];
};

export type QueryAdminGetRolloversArgs = {
  userId: Scalars['String'];
};

export type QueryAdminGetPromotionCodesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<PromotionCodeStatus>;
};

export type QueryAdminGetPromotionCodeArgs = {
  code: Scalars['String'];
};

export type QueryAdminGetPromotionRedemptionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  promotionCodeId: Scalars['String'];
};

export type QueryLookupPromotionCodeArgs = {
  data: PromotionCodeLookupInput;
};

export type QueryAdminGetGameArgs = {
  slug: Scalars['String'];
};

export type QueryAdminGetGamesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<GameStatus>;
};

export type QueryAdminGetBetStatisticsArgs = {
  userId?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency>>;
};

export type QueryAdminGetSportsBetStatisticsArgs = {
  userId?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency>>;
};

export type QueryAdminGetTransactionStatisticsArgs = {
  userId?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency>>;
  transactionTypes?: InputMaybe<Array<TransactionType>>;
};

export type QueryAdminGetTransactionUsdValueByTypeArgs = {
  userId?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency>>;
};

export type QueryAdminGetDepositStatisticsArgs = {
  userId?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency>>;
};

export type QueryAdminGetWithdrawalStatisticsArgs = {
  userId?: InputMaybe<Scalars['String']>;
  startDateTime?: InputMaybe<Scalars['DateTime']>;
  endDateTime?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency>>;
};

export type QueryWithdrawalsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency>>;
};

export type QueryValidateAddressArgs = {
  chain: Chain;
  address: Scalars['String'];
};

export type QueryAdminGetCampaignsArgs = {
  userId: Scalars['String'];
};

export type QueryAdminGetRefereesArgs = {
  campaignId?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type QueryAdminFindWithdrawalsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  currencyIn?: InputMaybe<Array<Currency>>;
  destinationWallet?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WithdrawalStatus>;
  adminName?: InputMaybe<Scalars['String']>;
};

export type QueryAdminGetSportsCategoriesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  sports?: InputMaybe<Sports>;
};

export type QueryAdminGetSportsCompetitionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryAdminGetSportsFixturesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  competitionIds?: InputMaybe<Array<Scalars['String']>>;
  fixtureKeyword?: InputMaybe<Scalars['String']>;
};

export type QueryAdminGetSportsMarketsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  fixtureIds?: InputMaybe<Array<Scalars['String']>>;
  marketKeyword?: InputMaybe<Scalars['String']>;
};

export type QueryAdminSportsMarketDetailsArgs = {
  id: Scalars['String'];
};

export type QueryAdminGetSportsBetArgs = {
  id?: InputMaybe<Scalars['String']>;
};

export type QueryAdminGetSportsBetsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
  marketId?: InputMaybe<Scalars['String']>;
  fixtureId?: InputMaybe<Scalars['String']>;
  marketSelectionId?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<SportsBetStatus>>;
};

export type QueryAdminGetAdminCreditArgs = {
  username: Scalars['String'];
};

export type QueryAdminGetChallengesArgs = {
  completed?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
};

export type QueryAdminGetUserOpenBetsArgs = {
  userId: Scalars['String'];
};

export type QueryAdminGetVipBonusArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type QueryAdminGetUserNotesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  after?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type QueryAdminGetUserSessionsArgs = {
  userId: Scalars['ID'];
  inactive?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
};

export type QueryGetChatMessagesArgs = {
  data: GetChatInput;
};

export type UserWhereInput = {
  id?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export enum GameCategoryType {
  SLOTS = 'SLOTS',
  ORIGINALS = 'ORIGINALS',
  LIVE_CASINO = 'LIVE_CASINO',
  GAME_SHOWS = 'GAME_SHOWS',
  TABLE_GAMES = 'TABLE_GAMES',
  ROULETTE = 'ROULETTE',
  BLACKJACK = 'BLACKJACK',
  BACCARAT = 'BACCARAT',
  BONUS_BUY = 'BONUS_BUY',
  SHUFFLE_PICKS = 'SHUFFLE_PICKS',
}

export enum GameSortby {
  POPULAR = 'POPULAR',
  RECENT_PLAY = 'RECENT_PLAY',
  RECENT_ADD = 'RECENT_ADD',
  RANDOM = 'RANDOM',
  FEATURED = 'FEATURED',
  LATEST_RELEASED = 'LATEST_RELEASED',
}

export type SportsSearchFilterArgs = {
  sports?: InputMaybe<Sports>;
};

export type PromotionCodeLookupInput = {
  codeSlug: Scalars['String'];
};

export type GetChatInput = {
  chatRoom: ChatRoom;
  messageCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  gameCreateSession: StartGamePayload;
  userUpdatePreference: User;
  register: RegisterPayload;
  loginRequest: LoginRequestPayload;
  loginResendRequest: LoginRequestPayload;
  login: LoginPayload;
  refreshToken: RefreshTokenPayload;
  logout: Scalars['Boolean'];
  logoutOtherSessions: Scalars['Boolean'];
  registerWithOauth: RegisterPayload;
  loginRegisterRequestWithOauth: LoginRegisterRequestWithOauthPayload;
  linkWithOauth: User;
  unlinkWithOauth: User;
  oauthRequest: OauthRequestPayload;
  verifyEmail: VerifyEmailPayload;
  resendEmailVerification: ResendVerificationEmailPayload;
  passwordChange: User;
  passwordSendResetLink: Scalars['Boolean'];
  passwordSendResetLinkLoggedIn: Scalars['Boolean'];
  passwordReset: Scalars['Boolean'];
  tfaEnable: User;
  gameSeedChangeAndReveal: Array<GameSeed>;
  updateRolloverProgresses: Array<RolloverProgress>;
  claimChallengeReward: ChallengeReward;
  raceClaimUserReward: RaceRewardPayload;
  adminLogin: AdminLoginPayload;
  /** Claims commissions for the referer, returns the unclaimed amounts */
  claimCommissions: Array<CurrencyAmount>;
  instantRakebackClaim: Array<CurrencyAmount>;
  dicePlay: Bet;
  limboPlay: Bet;
  kenoPlay: Bet;
  plinkoPlay: Bet;
  crashPlay: Bet;
  crashCashout: Scalars['Boolean'];
  minesStart: Bet;
  minesCashout?: Maybe<Bet>;
  minesNext: Bet;
  minesAuto: Bet;
  hiloStart: Bet;
  hiloCashout?: Maybe<Bet>;
  hiloNext: Bet;
  blackjackStart: Bet;
  blackjackNext: Bet;
  addFavouriteGame: Scalars['Boolean'];
  removeFavouriteGame: Scalars['Boolean'];
  placeSportsBet: SportsBet;
  cashoutSportsBet: SportsBet;
  kycLevel1Update: Kyc;
  kycLevel2Update: Kyc;
  kycLevel3Update: Kyc;
  requestTipSendOtp: TipRequestOtpPayload;
  tipSend: Tip;
  requestTipRainSendOtp: TipRequestOtpPayload;
  tipRain: TipRain;
  vaultDeposit: Vault;
  vaultWithdraw: Vault;
  adminCreateAdmin: Admin;
  adminPasswordChange: Admin;
  adminUpdateAdmin: Admin;
  adminDeleteChat: Scalars['Boolean'];
  adminChatTimeoutUser: User;
  adminAdjustUserBalance: CurrencyAmount;
  adminAdjustUserVaultBalance: CurrencyAmount;
  adminUpdateUser: User;
  adminUpdateUserDetails: User;
  adminAdjustUserXp: User;
  adminUpdateUserFlag: UserFlag;
  adminCreateRollover: Rollover;
  adminForfeitRollover: Rollover;
  adminCreatePromotionCode: PromotionCode;
  adminUpdatePromotionCode: PromotionCode;
  redeemPromotionCode: PromotionRedemption;
  adminUpdateChainCurrency: ChainCurrency;
  adminUpdateGame: Game;
  adminUpdateGameProvider: GameProvider;
  adminStartCrashGame: Scalars['Boolean'];
  adminResetCrashSeed: Scalars['Boolean'];
  adminClearGameCaches: Array<Scalars['String']>;
  withdrawalRequestOtp: WithdrawalRequestOtpPayload;
  withdraw: Withdrawal;
  cancelWithdrawal: Withdrawal;
  adminAddToWhitelist: Array<UserWhitelist>;
  removeFromWhitelist: Array<UserWhitelist>;
  adminUpdateCampaignRate: Campaign;
  campaignCreate: Campaign;
  adminApproveWithdrawal: Withdrawal;
  adminCancelWithdrawal: Withdrawal;
  adminSetWithdrawalGlobalFreeze: AppSettings;
  adminSetWithdrawalRetention: AppSettings;
  adminToggleSportsCategories: Array<SportsCategory>;
  adminToggleSportsCompetitions: Array<SportsCompetition>;
  adminToggleSportsFixtures: Array<SportsFixture>;
  adminToggleSportsMarkets: Array<SportsMarket>;
  adminResultSportsMarketSelections: Scalars['Boolean'];
  adminVoidSportsBetSelection: Scalars['Boolean'];
  adminVoidSportsBet: Scalars['Boolean'];
  adminVoidSportsMarketSelection: Scalars['Boolean'];
  adminVoidSportsMarket: Scalars['Boolean'];
  adminVoidSportsFixture: Scalars['Boolean'];
  adminAdjustCredit: Scalars['Boolean'];
  adminCreateChallenge: Challenge;
  adminRemoveChallenge: Scalars['Boolean'];
  adminResetUserZeroBetCount: Scalars['Boolean'];
  adminUpdateAppSettings: AppSettings;
  adminCreateVipBonus: VipBonus;
  adminCancelVipBonus: VipBonus;
  vipBonusClaimBonus: VipBonusClaim;
  adminCreateUserNote: AdminUserNote;
  adminUpdateUserNote: AdminUserNote;
  adminRemoveUserNote: Scalars['Boolean'];
  adminLogoutUserSession: Scalars['Boolean'];
  adminLogoutUserAllSessions: Scalars['Boolean'];
  vipRewardsClaimDailyRakeback: DailyRakebackAmount;
  vipRewardsClaimWeeklyBonus: WeeklyBonusClaimAmount;
  vipRewardsClaimMonthlyBonus: MonthlyBonusClaimAmount;
  claimVipUpgradeBonus: VipUpgradeBonusClaim;
  chatSend: Scalars['Boolean'];
  chatRead: Scalars['Boolean'];
  chatJoin: Scalars['Boolean'];
};

export type MutationGameCreateSessionArgs = {
  gameSlug: Scalars['String'];
  locale: Scalars['String'];
  clientType: ClientType;
  cryptoCurrency: Currency;
  fiatCurrency: FiatCurrency;
  demoMode: Scalars['Boolean'];
};

export type MutationUserUpdatePreferenceArgs = {
  data: UpdateUserPreferenceInput;
};

export type MutationRegisterArgs = {
  data: RegisterInput;
};

export type MutationLoginRequestArgs = {
  identity: Scalars['String'];
  password: Scalars['String'];
  geetest?: InputMaybe<GeetestInput>;
};

export type MutationLoginResendRequestArgs = {
  loginToken: Scalars['String'];
};

export type MutationLoginArgs = {
  loginToken: Scalars['String'];
  tfaCode: Scalars['String'];
};

export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationLogoutArgs = {
  sessionId?: InputMaybe<Scalars['String']>;
};

export type MutationRegisterWithOauthArgs = {
  data: RegisterWithOauthInput;
};

export type MutationLoginRegisterRequestWithOauthArgs = {
  provider: OauthProvider;
  code: Scalars['String'];
};

export type MutationLinkWithOauthArgs = {
  provider: OauthProvider;
  code: Scalars['String'];
};

export type MutationUnlinkWithOauthArgs = {
  provider: OauthProvider;
};

export type MutationOauthRequestArgs = {
  provider: OauthProvider;
  state?: InputMaybe<Scalars['String']>;
};

export type MutationVerifyEmailArgs = {
  emailVerificationToken: Scalars['String'];
};

export type MutationPasswordChangeArgs = {
  data: ChangePasswordInput;
};

export type MutationPasswordSendResetLinkArgs = {
  email: Scalars['String'];
  geetest?: InputMaybe<GeetestInput>;
};

export type MutationPasswordResetArgs = {
  data: PasswordResetInput;
};

export type MutationTfaEnableArgs = {
  data: TfaEnableInput;
};

export type MutationGameSeedChangeAndRevealArgs = {
  newClientSeed: Scalars['String'];
};

export type MutationClaimChallengeRewardArgs = {
  challengeId: Scalars['String'];
};

export type MutationRaceClaimUserRewardArgs = {
  raceId: Scalars['String'];
};

export type MutationAdminLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
  geetest?: InputMaybe<GeetestInput>;
};

export type MutationDicePlayArgs = {
  data: DicePlayInput;
};

export type MutationLimboPlayArgs = {
  data: LimboPlayInput;
};

export type MutationKenoPlayArgs = {
  data: KenoPlayInput;
};

export type MutationPlinkoPlayArgs = {
  data: PlinkoPlayInput;
};

export type MutationCrashPlayArgs = {
  data: CrashPlayInput;
};

export type MutationCrashCashoutArgs = {
  crashGameId?: InputMaybe<Scalars['Int']>;
};

export type MutationMinesStartArgs = {
  data: MinesStartInput;
};

export type MutationMinesNextArgs = {
  data: MinesNextInput;
};

export type MutationMinesAutoArgs = {
  data: MinesBetInput;
};

export type MutationHiloStartArgs = {
  data: HiloStartInput;
};

export type MutationHiloCashoutArgs = {
  betId?: InputMaybe<Scalars['String']>;
};

export type MutationHiloNextArgs = {
  data: HiloNextInput;
};

export type MutationBlackjackStartArgs = {
  data: BlackjackStartInput;
};

export type MutationBlackjackNextArgs = {
  data: BlackjackNextInput;
};

export type MutationAddFavouriteGameArgs = {
  gameId: Scalars['String'];
};

export type MutationRemoveFavouriteGameArgs = {
  gameId: Scalars['String'];
};

export type MutationPlaceSportsBetArgs = {
  data: PlaceSportsBetInput;
};

export type MutationCashoutSportsBetArgs = {
  data: CashoutSportsBetInput;
};

export type MutationKycLevel1UpdateArgs = {
  data: KycLevel1UpdateInput;
};

export type MutationKycLevel2UpdateArgs = {
  identityFile: Scalars['Upload'];
  identitySecondaryFile: Scalars['Upload'];
};

export type MutationKycLevel3UpdateArgs = {
  documentType: KycPoaType;
  addressFile: Scalars['Upload'];
};

export type MutationRequestTipSendOtpArgs = {
  data: TipSendInput;
};

export type MutationTipSendArgs = {
  data: TipSendInput;
};

export type MutationRequestTipRainSendOtpArgs = {
  data: TipRainOtpInput;
};

export type MutationTipRainArgs = {
  data: TipRainInput;
};

export type MutationVaultDepositArgs = {
  data: VaultDepositInput;
};

export type MutationVaultWithdrawArgs = {
  data: VaultWithdrawInput;
};

export type MutationAdminCreateAdminArgs = {
  data: AdminCreateInput;
};

export type MutationAdminPasswordChangeArgs = {
  data: ChangePasswordInput;
};

export type MutationAdminUpdateAdminArgs = {
  id: Scalars['String'];
  status?: InputMaybe<AdminStatus>;
  role?: InputMaybe<AdminRole>;
};

export type MutationAdminDeleteChatArgs = {
  data: AdminChatDeleteInput;
};

export type MutationAdminChatTimeoutUserArgs = {
  data: AdminChatTimeoutUserInput;
};

export type MutationAdminAdjustUserBalanceArgs = {
  data: AdminAdjustUserBalanceInput;
};

export type MutationAdminAdjustUserVaultBalanceArgs = {
  data: AdminAdjustUserBalanceInput;
};

export type MutationAdminUpdateUserArgs = {
  id: Scalars['String'];
  referrerCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  chatBanUntil?: InputMaybe<Scalars['DateTime']>;
  campaignLimit?: InputMaybe<Scalars['BigNumber']>;
  sportsBetDisabled?: InputMaybe<Scalars['Boolean']>;
  sportsMultiBetDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type MutationAdminUpdateUserDetailsArgs = {
  id: Scalars['String'];
  referrerCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  chatBanUntil?: InputMaybe<Scalars['DateTime']>;
  campaignLimit?: InputMaybe<Scalars['BigNumber']>;
  sportsBetDisabled?: InputMaybe<Scalars['Boolean']>;
  sportsMultiBetDisabled?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export type MutationAdminAdjustUserXpArgs = {
  userId: Scalars['String'];
  xpAdjustment: Scalars['Int'];
};

export type MutationAdminUpdateUserFlagArgs = {
  data: AdminUserFlagUpdateInput;
};

export type MutationAdminCreateRolloverArgs = {
  data: RolloverCreateInput;
};

export type MutationAdminForfeitRolloverArgs = {
  data: RolloverForfeitInput;
};

export type MutationAdminCreatePromotionCodeArgs = {
  data: AdminPromotionCodeInput;
};

export type MutationAdminUpdatePromotionCodeArgs = {
  data: AdminUpdatePromotionCodeInput;
};

export type MutationRedeemPromotionCodeArgs = {
  data: PromotionCodeInput;
};

export type MutationAdminUpdateChainCurrencyArgs = {
  id: Scalars['String'];
  data: ChainCurrencyUpdateInput;
};

export type MutationAdminUpdateGameArgs = {
  imageFile?: InputMaybe<Scalars['Upload']>;
  data: GameUpdateInput;
};

export type MutationAdminUpdateGameProviderArgs = {
  imageFile?: InputMaybe<Scalars['Upload']>;
  data: GameProviderUpdateInput;
};

export type MutationAdminStartCrashGameArgs = {
  data: RestartCrashGameInput;
};

export type MutationAdminResetCrashSeedArgs = {
  data: GenerateCrashSeed;
};

export type MutationWithdrawalRequestOtpArgs = {
  data: WithdrawalInput;
};

export type MutationWithdrawArgs = {
  data: WithdrawalInput;
};

export type MutationCancelWithdrawalArgs = {
  data: CancelWithdrawalInput;
};

export type MutationAdminAddToWhitelistArgs = {
  emails: Array<Scalars['String']>;
};

export type MutationRemoveFromWhitelistArgs = {
  email: Scalars['String'];
};

export type MutationAdminUpdateCampaignRateArgs = {
  data: AdminUpdateCampaignRateInput;
};

export type MutationCampaignCreateArgs = {
  data: CampaignCreateInput;
};

export type MutationAdminApproveWithdrawalArgs = {
  data: AdminApproveWithdrawalInput;
};

export type MutationAdminCancelWithdrawalArgs = {
  data: AdminCancelWithdrawalInput;
};

export type MutationAdminSetWithdrawalGlobalFreezeArgs = {
  data: AdminSetWithdrawalGlobalFreezeInput;
};

export type MutationAdminSetWithdrawalRetentionArgs = {
  data: AdminSetWithdrawalRetentionInput;
};

export type MutationAdminToggleSportsCategoriesArgs = {
  ids: Array<Scalars['String']>;
  disable: Scalars['Boolean'];
};

export type MutationAdminToggleSportsCompetitionsArgs = {
  ids: Array<Scalars['String']>;
  disable: Scalars['Boolean'];
};

export type MutationAdminToggleSportsFixturesArgs = {
  ids: Array<Scalars['String']>;
  disable: Scalars['Boolean'];
};

export type MutationAdminToggleSportsMarketsArgs = {
  ids: Array<Scalars['String']>;
  disable: Scalars['Boolean'];
};

export type MutationAdminResultSportsMarketSelectionsArgs = {
  selectionIds: Array<Scalars['String']>;
  resultStatus: SportsMarketSelectionResultStatus;
  percentageWin?: InputMaybe<Scalars['Int']>;
  percentagePush?: InputMaybe<Scalars['Int']>;
  place?: InputMaybe<Scalars['Int']>;
  countInPlace?: InputMaybe<Scalars['Int']>;
};

export type MutationAdminVoidSportsBetSelectionArgs = {
  id: Scalars['String'];
};

export type MutationAdminVoidSportsBetArgs = {
  id: Scalars['String'];
};

export type MutationAdminVoidSportsMarketSelectionArgs = {
  id: Scalars['String'];
};

export type MutationAdminVoidSportsMarketArgs = {
  id: Scalars['String'];
};

export type MutationAdminVoidSportsFixtureArgs = {
  id: Scalars['String'];
};

export type MutationAdminAdjustCreditArgs = {
  data: AdminAdjustCreditInput;
};

export type MutationAdminCreateChallengeArgs = {
  data: AdminChallengeCreateInput;
};

export type MutationAdminRemoveChallengeArgs = {
  data: AdminChallengeRemoveInput;
};

export type MutationAdminResetUserZeroBetCountArgs = {
  data: AdminResetUserZeroBetCountInput;
};

export type MutationAdminUpdateAppSettingsArgs = {
  data: UpdateAppSettingsInput;
};

export type MutationAdminCreateVipBonusArgs = {
  data: CreateVipBonusInput;
};

export type MutationAdminCancelVipBonusArgs = {
  data: CancelVipBonusInput;
};

export type MutationVipBonusClaimBonusArgs = {
  data: ClaimVipBonusInput;
};

export type MutationAdminCreateUserNoteArgs = {
  data: AdminUserNoteCreateInput;
};

export type MutationAdminUpdateUserNoteArgs = {
  data: AdminUserNoteUpdateInput;
};

export type MutationAdminRemoveUserNoteArgs = {
  data: AdminUserNoteRemoveInput;
};

export type MutationAdminLogoutUserSessionArgs = {
  data: AdminLogoutUserSessionInput;
};

export type MutationAdminLogoutUserAllSessionsArgs = {
  data: AdminLogoutUserAllSessionsInput;
};

export type MutationVipRewardsClaimWeeklyBonusArgs = {
  currency: Currency;
};

export type MutationVipRewardsClaimMonthlyBonusArgs = {
  currency: Currency;
};

export type MutationClaimVipUpgradeBonusArgs = {
  currency: Currency;
  vipLevel: VipLevel;
};

export type MutationChatSendArgs = {
  data: ChatSendInput;
};

export type MutationChatReadArgs = {
  data: ChatReadInput;
};

export type MutationChatJoinArgs = {
  data: ChatJoinInput;
};

export enum ClientType {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export type UpdateUserPreferenceInput = {
  anonymous?: InputMaybe<Scalars['Boolean']>;
  avatar?: InputMaybe<Scalars['Int']>;
  avatarBackground?: InputMaybe<Scalars['Int']>;
};

export type RegisterInput = {
  username: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
  referrerCode?: InputMaybe<Scalars['String']>;
  geetest?: InputMaybe<GeetestInput>;
};

export type GeetestInput = {
  lot_number: Scalars['String'];
  captcha_output: Scalars['String'];
  pass_token: Scalars['String'];
  gen_time: Scalars['String'];
};

export type RegisterWithOauthInput = {
  username: Scalars['String'];
  referrerCode?: InputMaybe<Scalars['String']>;
  oauthToken: Scalars['String'];
};

export type ChangePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type PasswordResetInput = {
  resetToken: Scalars['String'];
  newPassword: Scalars['String'];
};

export type TfaEnableInput = {
  password: Scalars['String'];
  totpSecret: Scalars['String'];
  tfaCode: Scalars['String'];
};

export type DicePlayInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  autoBetInfo?: InputMaybe<AutoBetInfo>;
  direction: DiceDirection;
  bet: Scalars['BetTarget'];
};

export type AutoBetInfo = {
  sessionId: Scalars['String'];
  currentBetCount: Scalars['Int'];
  totalPnl: Scalars['String'];
  metadata: Scalars['String'];
};

export enum DiceDirection {
  ABOVE = 'ABOVE',
  BELOW = 'BELOW',
}

export type LimboPlayInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  autoBetInfo?: InputMaybe<AutoBetInfo>;
  bet: Scalars['BetTarget'];
};

export type KenoPlayInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  autoBetInfo?: InputMaybe<AutoBetInfo>;
  risk: KenoRiskLevel;
  selected: Array<Scalars['Int']>;
};

export type PlinkoPlayInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  autoBetInfo?: InputMaybe<AutoBetInfo>;
  risk: PlinkoRiskLevel;
  rows: Scalars['Float'];
};

export type CrashPlayInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  autoBetInfo?: InputMaybe<AutoBetInfo>;
  betAt: Scalars['BetTarget'];
};

export type MinesStartInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  autoBetInfo?: InputMaybe<AutoBetInfo>;
  minesCount: Scalars['Int'];
};

export type MinesNextInput = {
  selected: Array<Scalars['Int']>;
};

export type MinesBetInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  autoBetInfo?: InputMaybe<AutoBetInfo>;
  minesCount: Scalars['Int'];
  selected: Array<Scalars['Int']>;
};

export type HiloStartInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  startCard: Scalars['Int'];
};

export type HiloNextInput = {
  guess: HiloGuess;
  betId?: InputMaybe<Scalars['String']>;
  cardCount?: InputMaybe<Scalars['Int']>;
};

export type BlackjackStartInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  twentyOnePlusThreeAmount: Scalars['CryptoAmount'];
  perfectPairAmount: Scalars['CryptoAmount'];
};

export type BlackjackNextInput = {
  action: BlackjackAction;
};

export type PlaceSportsBetInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  totalOddsDecimal: Scalars['BigNumber'];
  selectionIds: Array<Scalars['String']>;
};

export type CashoutSportsBetInput = {
  sportsBetId: Scalars['String'];
  cashoutOddsDecimal: Scalars['BigNumber'];
};

export type KycLevel1UpdateInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  birthday: Scalars['Date'];
  country: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  postcode: Scalars['String'];
  occupation: Scalars['String'];
};

export enum KycPoaType {
  BANK_STATEMENT = 'BANK_STATEMENT',
  UTILITY_BILL = 'UTILITY_BILL',
  ELECTRICITY_BILL = 'ELECTRICITY_BILL',
  WATER_BILL = 'WATER_BILL',
  GAS_BILL = 'GAS_BILL',
  PHONE_BILL = 'PHONE_BILL',
  INTERNET_BILL = 'INTERNET_BILL',
}

export type TipSendInput = {
  /** username of the receiver */
  receiverUsername: Scalars['String'];
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  tfaCode?: InputMaybe<Scalars['String']>;
  chatRoom?: InputMaybe<ChatRoom>;
};

export type TipRainOtpInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  recipientCount: Scalars['Int'];
  chatRoom: ChatRoom;
};

export type TipRainInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  recipientCount: Scalars['Int'];
  chatRoom: ChatRoom;
  tfaCode: Scalars['String'];
};

export type VaultDepositInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
};

export type VaultWithdrawInput = {
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  password: Scalars['String'];
};

export type AdminCreateInput = {
  username: Scalars['String'];
  role: AdminRole;
};

export type AdminChatDeleteInput = {
  messageIds: Array<Scalars['String']>;
  chatRoom: ChatRoom;
};

export type AdminChatTimeoutUserInput = {
  userId: Scalars['String'];
  timeoutSecs?: InputMaybe<Scalars['Int']>;
};

export type AdminAdjustUserBalanceInput = {
  userId: Scalars['String'];
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  adjustmentType: AdminBalanceAdjustmentType;
  note: Scalars['String'];
};

export type AdminUserFlagUpdateInput = {
  id?: InputMaybe<Scalars['String']>;
  withdrawalHeld?: InputMaybe<Scalars['Boolean']>;
  withdrawalDisabled?: InputMaybe<Scalars['Boolean']>;
  cancelWithdrawalDisabled?: InputMaybe<Scalars['Boolean']>;
  gamePlayDisabled?: InputMaybe<Scalars['Boolean']>;
  reducedRestriction?: InputMaybe<Scalars['Boolean']>;
  raceIneligible?: InputMaybe<Scalars['Boolean']>;
  commissionGenerationDisabled?: InputMaybe<Scalars['Boolean']>;
  rewardGenerationDisabled?: InputMaybe<Scalars['Boolean']>;
  recurringRewardDisabled?: InputMaybe<Scalars['Boolean']>;
  rainReceivingDisabled?: InputMaybe<Scalars['Boolean']>;
  tipOrRainSendingDisabled?: InputMaybe<Scalars['Boolean']>;
  promotionCodeRedemptionDisabled?: InputMaybe<Scalars['Boolean']>;
  commissionRedemptionDisabled?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type RolloverCreateInput = {
  currency: Currency;
  wageredRequirement: Scalars['CryptoAmount'];
  expectedBalance: Scalars['CryptoAmount'];
  amount: Scalars['CryptoAmount'];
  maxProfit?: InputMaybe<Scalars['CryptoAmount']>;
  userId: Scalars['String'];
  usdMaxBet?: InputMaybe<Scalars['Int']>;
  allowRestrictedGame: Scalars['Boolean'];
  allowSportBet: Scalars['Boolean'];
  note: Scalars['String'];
  rewardPenalty: Scalars['GraphqlPercent'];
};

export type RolloverForfeitInput = {
  id: Scalars['String'];
  openBetIds: Array<Scalars['String']>;
};

export type AdminPromotionCodeInput = {
  code: Scalars['String'];
  usdRedeemValue: Scalars['CryptoAmount'];
  maxRedeemCount?: InputMaybe<Scalars['Float']>;
  expiredAt: Scalars['Date'];
  allowedUsernames?: InputMaybe<Array<Scalars['String']>>;
  wageredRequirement?: InputMaybe<Scalars['CryptoAmount']>;
};

export type AdminUpdatePromotionCodeInput = {
  code: Scalars['String'];
  usdRedeemValue: Scalars['CryptoAmount'];
  maxRedeemCount?: InputMaybe<Scalars['Float']>;
  expiredAt: Scalars['Date'];
  allowedUsernames?: InputMaybe<Array<Scalars['String']>>;
  wageredRequirement?: InputMaybe<Scalars['CryptoAmount']>;
  id: Scalars['String'];
  status: PromotionCodeStatus;
};

export type PromotionCodeInput = {
  codeSlug: Scalars['String'];
  currency: Currency;
};

export type ChainCurrencyUpdateInput = {
  withdrawalFee: Scalars['CryptoAmount'];
  minimumWithdrawal: Scalars['CryptoAmount'];
  disabled: Scalars['Boolean'];
};

export type GameUpdateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  status: GameStatus;
  tags?: InputMaybe<Array<Scalars['String']>>;
  providerId: Scalars['String'];
  slug: Scalars['String'];
  edge: Scalars['Int'];
  accentColor: Scalars['String'];
  categories: Array<GameCategoryType>;
  mainCategory: GameCategoryType;
  restrictions?: InputMaybe<Array<Scalars['String']>>;
  rolloverRestricted: Scalars['Boolean'];
};

export type GameProviderUpdateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  disabledFiatCurrencies: Array<FiatCurrency>;
  disabled: Scalars['Boolean'];
};

export type RestartCrashGameInput = {
  crashGameId?: InputMaybe<Scalars['Float']>;
};

export type GenerateCrashSeed = {
  noOfSeed: Scalars['Float'];
  bitcoinBlockHash?: InputMaybe<Scalars['String']>;
};

export type WithdrawalInput = {
  currency: Currency;
  chain: Chain;
  amount: Scalars['CryptoAmount'];
  receiverAddress: Scalars['String'];
  receiverAddressTag?: InputMaybe<Scalars['String']>;
  fee: Scalars['CryptoAmount'];
  tfaCode?: InputMaybe<Scalars['String']>;
};

export type CancelWithdrawalInput = {
  withdrawalId: Scalars['String'];
};

export type AdminUpdateCampaignRateInput = {
  id: Scalars['String'];
  rate: Scalars['GraphqlPercent'];
};

export type CampaignCreateInput = {
  name: Scalars['String'];
  code: Scalars['String'];
};

export type AdminApproveWithdrawalInput = {
  withdrawalId: Scalars['String'];
  withdrawNow: Scalars['Boolean'];
};

export type AdminCancelWithdrawalInput = {
  withdrawalId: Scalars['String'];
};

export type AdminSetWithdrawalGlobalFreezeInput = {
  freeze: Scalars['Boolean'];
};

export type AdminSetWithdrawalRetentionInput = {
  retention: Scalars['Int'];
};

export type AdminAdjustCreditInput = {
  adminId: Scalars['String'];
  usdAmount: Scalars['CryptoAmount'];
};

export type AdminChallengeCreateInput = {
  gameSlug: Scalars['String'];
  usdMinBet: Scalars['CryptoAmount'];
  minMultiplier: Scalars['Float'];
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  creatorUsername?: InputMaybe<Scalars['String']>;
  referredOnly: Scalars['Boolean'];
};

export type AdminChallengeRemoveInput = {
  challengeId: Scalars['String'];
};

export type AdminResetUserZeroBetCountInput = {
  userId: Scalars['ID'];
};

export type CreateVipBonusInput = {
  userId: Scalars['String'];
  type: VipBonusType;
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  cadence?: InputMaybe<Scalars['Int']>;
  occurrence?: InputMaybe<Scalars['Int']>;
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  note?: InputMaybe<Scalars['String']>;
};

export type CancelVipBonusInput = {
  bonusId: Scalars['String'];
};

export type ClaimVipBonusInput = {
  bonusId: Scalars['String'];
};

export type AdminUserNoteCreateInput = {
  userId: Scalars['ID'];
  comment: Scalars['String'];
};

export type AdminUserNoteUpdateInput = {
  userNoteId: Scalars['ID'];
  comment: Scalars['String'];
};

export type AdminUserNoteRemoveInput = {
  userNoteId: Scalars['String'];
};

export type AdminLogoutUserSessionInput = {
  userId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type AdminLogoutUserAllSessionsInput = {
  userId: Scalars['ID'];
};

export type ChatSendInput = {
  text: Scalars['String'];
  chatRoom: ChatRoom;
  mentionedUsers: Array<Scalars['String']>;
  unreadUsers: Array<Scalars['String']>;
};

export type ChatReadInput = {
  messageIds?: InputMaybe<Array<Scalars['String']>>;
  chatRoom: ChatRoom;
  readAll?: InputMaybe<Scalars['Boolean']>;
};

export type ChatJoinInput = {
  chatRoom: ChatRoom;
};

export type CrashEventPayload = {
  __typename?: 'CrashEventPayload';
  status: CrashStatus;
  elapsed?: Maybe<Scalars['Float']>;
  currentPoint?: Maybe<Scalars['GraphqlCrashPoint']>;
  crashPoint?: Maybe<Scalars['GraphqlCrashPoint']>;
  startedAt?: Maybe<Scalars['Float']>;
  nextRoundIn?: Maybe<Scalars['Float']>;
};

export enum CrashStatus {
  NOT_STARTED = 'NOT_STARTED',
  ACCEPTING_BETS = 'ACCEPTING_BETS',
  BLOCK = 'BLOCK',
  IN_PROGRESS = 'IN_PROGRESS',
  CRASHED = 'CRASHED',
  FIRST = 'FIRST',
}

export type DepositNotificationPayload = {
  __typename?: 'DepositNotificationPayload';
  depositId: Scalars['String'];
  currency: Currency;
  amount: Scalars['CryptoAmount'];
};

export type GameResultPayload = {
  __typename?: 'GameResultPayload';
  currency: Currency;
  betAmount: Scalars['CryptoAmount'];
  payout: Scalars['CryptoAmount'];
};

export type RewardPayload = {
  __typename?: 'RewardPayload';
  rewardEvent: RewardEvent;
};

export enum RewardEvent {
  CHALLENGE_REWARD = 'CHALLENGE_REWARD',
}

export type SportsCategoryUpdatedPayload = {
  __typename?: 'SportsCategoryUpdatedPayload';
  id: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type SportsCompetitionUpdatedPayload = {
  __typename?: 'SportsCompetitionUpdatedPayload';
  id: Scalars['String'];
  disabled: Scalars['Boolean'];
};

export type SportsFixtureUpdatedPayload = {
  __typename?: 'SportsFixtureUpdatedPayload';
  id: Scalars['String'];
  disabled: Scalars['Boolean'];
  status: SportsFixtureStatus;
};

export type SportsMarketUpdatedPayload = {
  __typename?: 'SportsMarketUpdatedPayload';
  id: Scalars['String'];
  fixtureId: Scalars['String'];
  inPlay: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  status: SportsMarketStatus;
  cashoutEnabled: Scalars['Boolean'];
  cashoutStatus: Scalars['String'];
};

export type SportsMarketSelectionUpdatedPayload = {
  __typename?: 'SportsMarketSelectionUpdatedPayload';
  id: Scalars['String'];
  fixtureId: Scalars['String'];
  marketId: Scalars['String'];
  oddsNumerator: Scalars['BigNumber'];
  oddsDenominator: Scalars['BigNumber'];
};

export type SportsMatchSummaryUpdatedPayload = {
  __typename?: 'SportsMatchSummaryUpdatedPayload';
  id: Scalars['String'];
  fixtureId: Scalars['String'];
  currentPhaseName?: Maybe<Scalars['String']>;
  matchPhase?: Maybe<SportsMatchPhase>;
  homeScore?: Maybe<Scalars['Int']>;
  awayScore?: Maybe<Scalars['Int']>;
  server?: Maybe<Scalars['String']>;
  phases?: Maybe<Array<SportsMatchPhaseSummary>>;
  timeRemaining?: Maybe<Scalars['Int']>;
  timeRemainingAt?: Maybe<Scalars['DateTime']>;
};

export type TipReceivedPayload = {
  __typename?: 'TipReceivedPayload';
  /** username of the sender */
  senderUsername: Scalars['String'];
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  tipType: TipType;
};

export enum TipType {
  TIP = 'TIP',
  TIP_RAIN = 'TIP_RAIN',
}

export type VipLevelUpdatedPayload = {
  __typename?: 'VipLevelUpdatedPayload';
  vipLevel?: Maybe<VipLevel>;
  xp: Scalars['Int'];
  wagered?: Maybe<Scalars['CryptoAmount']>;
};

export type WithdrawalCompletedPayload = {
  __typename?: 'WithdrawalCompletedPayload';
  withdrawalId: Scalars['String'];
  currency: Currency;
  amount: Scalars['CryptoAmount'];
  status: WithdrawalStatus;
};

export type Subscription = {
  __typename?: 'Subscription';
  emailVerified: VerifyEmailPayload;
  myBetUpdated: Bet;
  latestBetUpdated: BetActivityPayload;
  highRollerBetUpdated: BetActivityPayload;
  crashEventUpdate: CrashEventPayload;
  crashBetPayoutEvent: CrashBetPayoutPayload;
  myCrashBetPayoutEvent: CrashBetPayoutPayload;
  depositPending: DepositNotificationPayload;
  depositCompleted: DepositNotificationPayload;
  gameResult: GameResultPayload;
  raceWagerUpdated: Array<RaceWagerPayload>;
  raceLeaderboardUpdated: RaceLeaderBoardPayload;
  rewardUpdated: RewardPayload;
  mySportsBetUpdated: SportsBet;
  latestSportsBetUpdated: SportsBetActivity;
  highRollerSportsBetUpdated: SportsBetActivity;
  sportsCategoryUpdated: SportsCategoryUpdatedPayload;
  sportsCompetitionUpdated: SportsCompetitionUpdatedPayload;
  sportsFixtureUpdated: SportsFixtureUpdatedPayload;
  sportsMarketUpdated: SportsMarketUpdatedPayload;
  sportsMarketSelectionUpdated: SportsMarketSelectionUpdatedPayload;
  sportsMatchSummaryUpdated: SportsMatchSummaryUpdatedPayload;
  tipReceived: TipReceivedPayload;
  balanceUpdated: CurrencyAmount;
  vipLevelUpdated: VipLevelUpdatedPayload;
  vipRewardsUpdated: Scalars['Boolean'];
  withdrawalCompleted: WithdrawalCompletedPayload;
};

export type SubscriptionSportsCategoryUpdatedArgs = {
  categoryIds?: InputMaybe<Array<Scalars['String']>>;
};

export type SubscriptionSportsCompetitionUpdatedArgs = {
  competitionIds?: InputMaybe<Array<Scalars['String']>>;
};

export type SubscriptionSportsFixtureUpdatedArgs = {
  fixtureIds?: InputMaybe<Array<Scalars['String']>>;
};

export type SubscriptionSportsMarketUpdatedArgs = {
  fixtureIds?: InputMaybe<Array<Scalars['String']>>;
  marketIds?: InputMaybe<Array<Scalars['String']>>;
};

export type SubscriptionSportsMarketSelectionUpdatedArgs = {
  fixtureIds?: InputMaybe<Array<Scalars['String']>>;
  marketIds?: InputMaybe<Array<Scalars['String']>>;
};

export type SubscriptionSportsMatchSummaryUpdatedArgs = {
  fixtureIds: Array<Scalars['String']>;
};

export type CrashBetPayoutEventFieldsFragment = {
  __typename?: 'CrashBetPayoutPayload';
  betId: string;
  currency: Currency;
  amount: any;
  betAt: any;
  payout?: any | null;
  multiplier?: any | null;
  payoutType?: PayoutType | null;
  user?: { __typename?: 'User'; username?: string | null; vipLevel: VipLevel } | null;
};

export type BlackjackBetFieldsFragment = {
  __typename?: 'Bet';
  id: string;
  amount: any;
  originalAmount: any;
  payout?: any | null;
  multiplier?: number | null;
  currency: Currency;
  afterBalance?: any | null;
  completedAt?: any | null;
  shuffleOriginalActions?: Array<{
    __typename?: 'ShuffleOriginalAction';
    id: string;
    action: {
      __typename?: 'ShuffleOriginalActionModel';
      blackjack?: {
        __typename?: 'BlackjackActionModel';
        mainPlayerHand: Array<number>;
        mainPlayerActions: Array<BlackjackAction>;
        splitPlayerHand: Array<number>;
        splitPlayerActions: Array<BlackjackAction>;
        dealerHand: Array<number>;
        perfectPairWin?: PerfectPairType | null;
        twentyOnePlusThreeWin?: TwentyOnePlusThreeType | null;
        twentyOnePlusThreeAmount: any;
        perfectPairAmount: any;
        insuranceStatus: InsuranceStatus;
        originalMainBetAmount: any;
        mainHandOutcome: HandOutcome;
        splitHandOutcome: HandOutcome;
      } | null;
    };
  }> | null;
};

export type BetFieldsFragment = {
  __typename?: 'Bet';
  id: string;
  currency: Currency;
  amount: any;
  payout?: any | null;
  multiplier?: number | null;
  game?: {
    __typename?: 'Game';
    id: string;
    name: string;
    slug: string;
    gameAndGameCategories?: Array<{
      __typename?: 'GameAndGameCategory';
      gameCategoryName: string;
      gameId: string;
      main: boolean;
    }> | null;
  } | null;
};

export type BetActivityFieldsFragment = {
  __typename?: 'BetActivityPayload';
  id: string;
  username?: string | null;
  vipLevel: VipLevel;
  currency: Currency;
  amount: any;
  payout: any;
  multiplier: string;
  gameName: string;
  gameCategory?: string | null;
  gameSlug: string;
};

export type CrashGameFieldsFragment = {
  __typename?: 'CrashGame';
  id: number;
  seed: string;
  crashPoint?: any | null;
  countOfPlayer?: number | null;
  countOfCashedOut?: number | null;
};

export type DiceFieldsFragment = {
  __typename?: 'DiceActionModel';
  userDiceDirection?: string | null;
  userValue: any;
  resultValue: any;
  resultRaw: string;
};

export type GameSeedFieldsFragment = {
  __typename?: 'GameSeed';
  id: string;
  clientSeed?: string | null;
  seed?: string | null;
  hashedSeed: string;
  status?: GameSeedStatus | null;
  currentNonce: string;
  createdAt: any;
};

export type MinesBetFieldsFragment = {
  __typename?: 'Bet';
  id: string;
  amount: any;
  payout?: any | null;
  currency: Currency;
  multiplier?: number | null;
  gameSeedNonce?: number | null;
  afterBalance?: any | null;
  game?: { __typename?: 'Game'; id: string; name: string; slug: string; edge: any } | null;
  shuffleOriginalActions?: Array<{
    __typename?: 'ShuffleOriginalAction';
    id: string;
    updatedAt: any;
    createdAt: any;
    action: {
      __typename?: 'ShuffleOriginalActionModel';
      mines?: {
        __typename?: 'MinesActionModel';
        minesResult?: Array<number> | null;
        minesCount: number;
        winMultiplier?: any | null;
        selected?: Array<number> | null;
      } | null;
    };
  }> | null;
};

export type SportsBetActivityFieldsFragment = {
  __typename?: 'SportsBetActivity';
  id: string;
  vipLevel: VipLevel;
  username?: string | null;
  currency: Currency;
  amount: any;
  categoryName: string;
  categorySlug: string;
  competitionName: string;
  competitionSlug: string;
  selectionNumber: number;
  fixtureName: string;
  sports: Sports;
  fixtureSlug: string;
  oddsDecimal: any;
};

export type HiloBetFieldsFragment = {
  __typename?: 'Bet';
  id: string;
  amount: any;
  payout?: any | null;
  currency: Currency;
  afterBalance?: any | null;
  shuffleOriginalActions?: Array<{
    __typename?: 'ShuffleOriginalAction';
    id: string;
    action: {
      __typename?: 'ShuffleOriginalActionModel';
      hilo?: {
        __typename?: 'HiloActionModel';
        card?: number | null;
        guess?: HiloGuess | null;
        winMultiplier?: any | null;
        actionType: HiloActionType;
      } | null;
    };
  }> | null;
};

export type KycFieldsFragment = {
  __typename?: 'Kyc';
  id: string;
  firstName: string;
  lastName: string;
  country: string;
  birthday: any;
  city: string;
  address: string;
  postcode: string;
  occupation: string;
  level1Status: KycLevelStatus;
  level2Status: KycLevelStatus;
  level3Status: KycLevelStatus;
  canCompletePoa: boolean;
};

export type CampaignFieldsFragment = {
  __typename?: 'Campaign';
  id: string;
  name: string;
  code: string;
  refereeCount: number;
  rate: any;
  refereeWagered: Array<{ __typename?: 'CurrencyAmount'; amount: any; currency: Currency }>;
  commissionTotals: Array<{ __typename?: 'CurrencyAmount'; amount: any; currency: Currency }>;
};

export type LeaderboardFieldsFragment = {
  __typename?: 'RaceRank';
  userId?: string | null;
  username?: string | null;
  raceId: string;
  raceName: string;
  currency: Currency;
  wagered: any;
  rank: number;
  vipLevel?: VipLevel | null;
  prize: any;
};

export type SportsMarketSelectionFieldsFragment = {
  __typename?: 'SportsMarketSelection';
  id: string;
  status: SportsMarketSelectionStatus;
  oddsNumerator: any;
  oddsDenominator: any;
  name: string;
  market: {
    __typename?: 'SportsMarket';
    id: string;
    typeName: string;
    typeId: string;
    name: string;
    status: SportsMarketStatus;
    disabled: boolean;
    expiryTime: any;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    inPlay: boolean;
    fixture: {
      __typename?: 'SportsFixture';
      competitors: Array<{
        __typename?: 'SportsCompetitorModel';
        geniusId: string;
        name: string;
        shortName?: string | null;
        isHome: boolean;
      }>;
    };
  };
};

export type SportBetSelectionSnapshotFieldsFragment = {
  __typename?: 'SportsBetSelectionSnapshot';
  marketId: string;
  marketName: string;
  marketTypeName: string;
  marketExpiryTime: any;
  fixtureId: string;
  fixtureSlug: string;
  fixtureName: string;
  fixtureStartTime: any;
  fixtureStatus: SportsFixtureStatus;
  competitionId: string;
  competitionSlug: string;
  competitionName: string;
  categoryId: string;
  categorySlug: string;
  categoryName: string;
  sports: Sports;
};

export type SportBetFieldsFragment = {
  __typename?: 'SportsBet';
  id: string;
  currency: Currency;
  amount: any;
  totalOddsDecimal: any;
  actualOddsDecimal: any;
  status: SportsBetStatus;
  cashoutAvailable: {
    __typename?: 'SportsBetCashoutAvailable';
    reason?: string | null;
    canCashout: boolean;
  };
  selections: Array<{
    __typename?: 'SportsBetSelection';
    id: string;
    oddsNumerator: any;
    oddsDenominator: any;
    status: SportsBetSelectionStatus;
    marketSelection: {
      __typename?: 'SportsMarketSelection';
      id: string;
      status: SportsMarketSelectionStatus;
      oddsNumerator: any;
      oddsDenominator: any;
      name: string;
      market: {
        __typename?: 'SportsMarket';
        id: string;
        typeName: string;
        typeId: string;
        name: string;
        status: SportsMarketStatus;
        disabled: boolean;
        expiryTime: any;
        cashoutStatus?: string | null;
        cashoutEnabled: boolean;
        inPlay: boolean;
        fixture: {
          __typename?: 'SportsFixture';
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
        };
      };
    };
    snapshot: {
      __typename?: 'SportsBetSelectionSnapshot';
      marketId: string;
      marketName: string;
      marketTypeName: string;
      marketExpiryTime: any;
      fixtureId: string;
      fixtureSlug: string;
      fixtureName: string;
      fixtureStartTime: any;
      fixtureStatus: SportsFixtureStatus;
      competitionId: string;
      competitionSlug: string;
      competitionName: string;
      categoryId: string;
      categorySlug: string;
      categoryName: string;
      sports: Sports;
    };
  }>;
  settlement?: {
    __typename?: 'SportsBetSettlement';
    id: string;
    payout?: any | null;
    payoutOddsDecimal: any;
    createdAt: any;
  } | null;
};

export type SportsCompetitionBodyFragment = {
  __typename?: 'SportsCompetition';
  id: string;
  name: string;
  slug: string;
  category: {
    __typename?: 'SportsCategory';
    id: string;
    slug: string;
    name: string;
    sports: Sports;
  };
};

export type SportsFixtureBodyFragment = {
  __typename?: 'SportsFixture';
  id: string;
  slug: string;
  name: string;
  startTime: any;
  updatedAt: any;
  createdAt: any;
  competitors: Array<{
    __typename?: 'SportsCompetitorModel';
    geniusId: string;
    name: string;
    shortName?: string | null;
    isHome: boolean;
  }>;
  matchState?: {
    __typename?: 'SportsMatchState';
    id: string;
    fixtureId: string;
    updatedAt: any;
    createdAt: any;
    matchSummary?: {
      __typename?: 'SportsMatchSummary';
      matchPhase?: SportsMatchPhase | null;
      currentPhaseName?: string | null;
      homeScore?: number | null;
      awayScore?: number | null;
      server?: string | null;
      timeRemaining?: number | null;
      timeRemainingAt?: any | null;
      phases?: Array<{
        __typename?: 'SportsMatchPhaseSummary';
        phase: number;
        homeScore?: number | null;
        awayScore?: number | null;
        status?: SportsMatchPhaseStatus | null;
      }> | null;
    } | null;
  } | null;
};

export type SportsFixtureWithDefaultMarketsBodyFragment = {
  __typename?: 'SportsFixture';
  id: string;
  slug: string;
  name: string;
  startTime: any;
  updatedAt: any;
  createdAt: any;
  markets: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
  competitors: Array<{
    __typename?: 'SportsCompetitorModel';
    geniusId: string;
    name: string;
    shortName?: string | null;
    isHome: boolean;
  }>;
  matchState?: {
    __typename?: 'SportsMatchState';
    id: string;
    fixtureId: string;
    updatedAt: any;
    createdAt: any;
    matchSummary?: {
      __typename?: 'SportsMatchSummary';
      matchPhase?: SportsMatchPhase | null;
      currentPhaseName?: string | null;
      homeScore?: number | null;
      awayScore?: number | null;
      server?: string | null;
      timeRemaining?: number | null;
      timeRemainingAt?: any | null;
      phases?: Array<{
        __typename?: 'SportsMatchPhaseSummary';
        phase: number;
        homeScore?: number | null;
        awayScore?: number | null;
        status?: SportsMatchPhaseStatus | null;
      }> | null;
    } | null;
  } | null;
};

export type SportsFixtureMarketsGroupsBodyFragment = {
  __typename?: 'SportsFixture';
  DEFAULT_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  TOP_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  FIRST_HALF_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  SECOND_HALF_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  FIRST_QUARTER_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  SECOND_QUARTER_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  THIRD_QUARTER_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  FOURTH_QUARTER_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  FIRST_PERIOD_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  SECOND_PERIOD_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  THIRD_PERIOD_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  WIN_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  TOTAL_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  GOALSCORER_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  CARDS_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  TEAM_TO_SCORE_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  CORRECT_SCORE_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  DOUBLE_CHANCE_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  CLEAN_SHEET_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  CORNERS_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  PENALTY_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  RACE_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  PLAYER_H2H_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  TIME_OF_SCORE_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  TOUCHDOWN_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  PLAYER_OVERALL_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  WINNER_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  SET_BETTING_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  GAMES_BETTING_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  TIE_BREAK_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  MISCELLANEOUS_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  SCORE_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  FIRST_5_INNINGS_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  METHOD_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  GAME_1_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  GAME_2_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  GAME_3_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  GAME_4_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  GAME_5_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  GAME_6_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  GAME_7_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  PLACE_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  MATCHUP_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  TRYSCORER_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  FIRST_TO_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  LAST_TO_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  OTHER_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  KILL_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  ROSHAN_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  NEXT_TO_HAPPEN_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  TOWER_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  INHIBITOR_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  DRAGON_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  BARRACKS_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  MAP_1_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  MAP_2_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  MAP_3_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  MAP_4_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  MAP_5_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HALF_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  BTTS_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_10_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_15_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_20_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_25_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_30_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_35_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_40_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_5_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_50_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_55_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_60_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_65_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_70_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_75_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_80_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_85_MINUTES_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AFTER_EXTRA_TIME_INCLUDING_NORMAL_TIME_GOALS_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_AT_EXTRA_TIME_HALF_TIME_INCLUDING_NORMAL_TIME_GOALS_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_HALF_TIME_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
  HANDICAP_SECOND_HALF_MARKETS: Array<{
    __typename?: 'SportsMarket';
    id: string;
    name: string;
    typeId: string;
    typeName: string;
    status: SportsMarketStatus;
    inPlay: boolean;
    expiryTime: any;
    disabled: boolean;
    cashoutStatus?: string | null;
    cashoutEnabled: boolean;
    selections: Array<{
      __typename?: 'SportsMarketSelection';
      id: string;
      name: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsMarketSelectionStatus;
    }>;
  }>;
};

export type SportsCompetitorBodyFragment = {
  __typename?: 'SportsCompetitorModel';
  geniusId: string;
  name: string;
  shortName?: string | null;
  isHome: boolean;
};

export type SportMarketSelectionBodyFragment = {
  __typename?: 'SportsMarketSelection';
  id: string;
  name: string;
  oddsNumerator: any;
  oddsDenominator: any;
  status: SportsMarketSelectionStatus;
};

export type SportsMarketBodyFragment = {
  __typename?: 'SportsMarket';
  id: string;
  name: string;
  typeId: string;
  typeName: string;
  status: SportsMarketStatus;
  inPlay: boolean;
  expiryTime: any;
  disabled: boolean;
  cashoutStatus?: string | null;
  cashoutEnabled: boolean;
  selections: Array<{
    __typename?: 'SportsMarketSelection';
    id: string;
    name: string;
    oddsNumerator: any;
    oddsDenominator: any;
    status: SportsMarketSelectionStatus;
  }>;
};

export type SportsMatchStateFieldsFragment = {
  __typename?: 'SportsMatchState';
  id: string;
  fixtureId: string;
  updatedAt: any;
  createdAt: any;
  matchSummary?: {
    __typename?: 'SportsMatchSummary';
    matchPhase?: SportsMatchPhase | null;
    currentPhaseName?: string | null;
    homeScore?: number | null;
    awayScore?: number | null;
    server?: string | null;
    timeRemaining?: number | null;
    timeRemainingAt?: any | null;
    phases?: Array<{
      __typename?: 'SportsMatchPhaseSummary';
      phase: number;
      homeScore?: number | null;
      awayScore?: number | null;
      status?: SportsMatchPhaseStatus | null;
    }> | null;
  } | null;
};

export type GetAllSportsLeaguesQueryVariables = Exact<{
  sports: Sports;
}>;

export type GetAllSportsLeaguesQuery = {
  __typename?: 'Query';
  sportsCategories: Array<{
    __typename?: 'SportsCategory';
    id: string;
    sports: Sports;
    slug: string;
    name: string;
    competitions: {
      __typename?: 'PaginatedSportsCompetition';
      totalCount: number;
      nodes?: Array<{
        __typename?: 'SportsCompetition';
        id: string;
        slug: string;
        name: string;
      }> | null;
    };
  }>;
};

export type BlackjackActiveBetQueryVariables = Exact<{ [key: string]: never }>;

export type BlackjackActiveBetQuery = {
  __typename?: 'Query';
  blackjackActiveBet?: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    originalAmount: any;
    payout?: any | null;
    multiplier?: number | null;
    currency: Currency;
    afterBalance?: any | null;
    completedAt?: any | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        blackjack?: {
          __typename?: 'BlackjackActionModel';
          mainPlayerHand: Array<number>;
          mainPlayerActions: Array<BlackjackAction>;
          splitPlayerHand: Array<number>;
          splitPlayerActions: Array<BlackjackAction>;
          dealerHand: Array<number>;
          perfectPairWin?: PerfectPairType | null;
          twentyOnePlusThreeWin?: TwentyOnePlusThreeType | null;
          twentyOnePlusThreeAmount: any;
          perfectPairAmount: any;
          insuranceStatus: InsuranceStatus;
          originalMainBetAmount: any;
          mainHandOutcome: HandOutcome;
          splitHandOutcome: HandOutcome;
        } | null;
      };
    }> | null;
  } | null;
};

export type GetBetInfoQueryVariables = Exact<{
  betId: Scalars['String'];
}>;

export type GetBetInfoQuery = {
  __typename?: 'Query';
  bet: {
    __typename?: 'Bet';
    id: string;
    gameSeedNonce?: number | null;
    amount: any;
    originalAmount: any;
    payout?: any | null;
    currency: Currency;
    createdAt: any;
    afterBalance?: any | null;
    multiplier?: number | null;
    account: {
      __typename?: 'Account';
      id: string;
      user: { __typename?: 'User'; username?: string | null; vipLevel: VipLevel };
    };
    game?: {
      __typename?: 'Game';
      id: string;
      name: string;
      slug: string;
      edge: any;
      originalGame?: OriginalGame | null;
      gameAndGameCategories?: Array<{
        __typename?: 'GameAndGameCategory';
        gameCategoryName: string;
        gameId: string;
        main: boolean;
      }> | null;
      provider?: { __typename?: 'GameProvider'; name: string } | null;
    } | null;
    gameSeed?: {
      __typename?: 'GameSeed';
      id: string;
      clientSeed?: string | null;
      seed?: string | null;
      hashedSeed: string;
      status?: GameSeedStatus | null;
      currentNonce: string;
      createdAt: any;
    } | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      updatedAt: any;
      createdAt: any;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        dice?: {
          __typename?: 'DiceActionModel';
          userDiceDirection?: string | null;
          userValue: any;
          resultValue: any;
          resultRaw: string;
        } | null;
        plinko?: {
          __typename?: 'PlinkoActionModel';
          multiplier: any;
          results: Array<number>;
          risk: PlinkoRiskLevel;
          rows: number;
        } | null;
        mines?: {
          __typename?: 'MinesActionModel';
          minesResult?: Array<number> | null;
          minesCount: number;
          winMultiplier?: any | null;
          selected?: Array<number> | null;
        } | null;
        limbo?: {
          __typename?: 'LimboActionModel';
          resultRaw: string;
          resultValue: any;
          userValue: any;
        } | null;
        keno?: {
          __typename?: 'KenoActionModel';
          results: Array<number>;
          risk: KenoRiskLevel;
          multiplier: any;
          selected: Array<number>;
        } | null;
        hilo?: {
          __typename?: 'HiloActionModel';
          card?: number | null;
          guess?: HiloGuess | null;
          winMultiplier?: any | null;
          actionType: HiloActionType;
        } | null;
        blackjack?: {
          __typename?: 'BlackjackActionModel';
          mainPlayerHand: Array<number>;
          mainPlayerActions: Array<BlackjackAction>;
          splitPlayerHand: Array<number>;
          splitPlayerActions: Array<BlackjackAction>;
          dealerHand: Array<number>;
          perfectPairWin?: PerfectPairType | null;
          twentyOnePlusThreeWin?: TwentyOnePlusThreeType | null;
          twentyOnePlusThreeAmount: any;
          perfectPairAmount: any;
          insuranceStatus: InsuranceStatus;
          originalMainBetAmount: any;
          mainHandOutcome: HandOutcome;
          splitHandOutcome: HandOutcome;
        } | null;
      };
    }> | null;
  };
};

export type GetChainsCurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetChainsCurrenciesQuery = {
  __typename?: 'Query';
  chainsCurrencies: Array<{
    __typename?: 'ChainCurrency';
    currency: Currency;
    chain: Chain;
    withdrawalFee: any;
    minimumWithdrawal: any;
    disabledAt?: any | null;
    tagAllowed: boolean;
  }>;
};

export type GetLatestBetsQueryVariables = Exact<{
  count?: InputMaybe<Scalars['Int']>;
}>;

export type GetLatestBetsQuery = {
  __typename?: 'Query';
  latestBets: Array<{
    __typename?: 'BetActivityPayload';
    id: string;
    username?: string | null;
    vipLevel: VipLevel;
    currency: Currency;
    amount: any;
    payout: any;
    multiplier: string;
    gameName: string;
    gameCategory?: string | null;
    gameSlug: string;
  }>;
};

export type GetHighRollerBetsQueryVariables = Exact<{
  count?: InputMaybe<Scalars['Int']>;
}>;

export type GetHighRollerBetsQuery = {
  __typename?: 'Query';
  highRollerBets: Array<{
    __typename?: 'BetActivityPayload';
    id: string;
    username?: string | null;
    vipLevel: VipLevel;
    currency: Currency;
    amount: any;
    payout: any;
    multiplier: string;
    gameName: string;
    gameCategory?: string | null;
    gameSlug: string;
  }>;
};

export type GetChallengeCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetChallengeCountQuery = {
  __typename?: 'Query';
  challenges: { __typename?: 'PaginatedChallenges'; totalCount: number };
};

export type GetGameCountByCategoryQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  providers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  isFavourite?: InputMaybe<Scalars['Boolean']>;
  providerSlug?: InputMaybe<Scalars['String']>;
}>;

export type GetGameCountByCategoryQuery = {
  __typename?: 'Query';
  getGameCountByCategory: Array<{
    __typename?: 'GameCountByCategory';
    category: string;
    gamesCount: number;
  }>;
};

export type GetCampaignsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCampaignsQuery = {
  __typename?: 'Query';
  campaigns: Array<{
    __typename?: 'Campaign';
    id: string;
    name: string;
    code: string;
    refereeCount: number;
    rate: any;
    refereeWagered: Array<{ __typename?: 'CurrencyAmount'; amount: any; currency: Currency }>;
    commissionTotals: Array<{ __typename?: 'CurrencyAmount'; amount: any; currency: Currency }>;
  }>;
};

export type GetChallengeRewardsQueryVariables = Exact<{ [key: string]: never }>;

export type GetChallengeRewardsQuery = {
  __typename?: 'Query';
  challengeRewards: Array<{
    __typename?: 'ChallengeReward';
    claimedAt?: any | null;
    expiredAt: any;
    challenge: {
      __typename?: 'Challenge';
      id: string;
      usdMinBet: any;
      minMultiplier: number;
      currency: Currency;
      amount: any;
      referredOnly: boolean;
      completed: boolean;
      game: {
        __typename?: 'Game';
        id: string;
        name: string;
        externalId?: string | null;
        description?: string | null;
        originalGame?: OriginalGame | null;
        slug: string;
        releasedDate?: any | null;
        demoMode: boolean;
        edge: any;
        accentColor: string;
        gameAndGameCategories?: Array<{
          __typename?: 'GameAndGameCategory';
          gameCategoryName: string;
          gameId: string;
          main: boolean;
        }> | null;
        provider?: {
          __typename?: 'GameProvider';
          id: string;
          slug: string;
          name: string;
          disabledFiatCurrencies: Array<FiatCurrency>;
        } | null;
        image?: { __typename?: 'Image'; key: string } | null;
        tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
      };
      creator?: { __typename?: 'ChallengeCreator'; username: string; vipLevel: VipLevel } | null;
    };
  }>;
};

export type GetFavouriteGameIdsQueryVariables = Exact<{ [key: string]: never }>;

export type GetFavouriteGameIdsQuery = { __typename?: 'Query'; favouriteGameIds: Array<string> };

export type GetChallengesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  completed?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetChallengesQuery = {
  __typename?: 'Query';
  challenges: {
    __typename?: 'PaginatedChallenges';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'Challenge';
      id: string;
      usdMinBet: any;
      minMultiplier: number;
      currency: Currency;
      amount: any;
      referredOnly: boolean;
      completed: boolean;
      game: {
        __typename?: 'Game';
        id: string;
        name: string;
        externalId?: string | null;
        description?: string | null;
        originalGame?: OriginalGame | null;
        slug: string;
        releasedDate?: any | null;
        demoMode: boolean;
        edge: any;
        accentColor: string;
        gameAndGameCategories?: Array<{
          __typename?: 'GameAndGameCategory';
          gameCategoryName: string;
          gameId: string;
          main: boolean;
        }> | null;
        provider?: {
          __typename?: 'GameProvider';
          id: string;
          slug: string;
          name: string;
          disabledFiatCurrencies: Array<FiatCurrency>;
        } | null;
        image?: { __typename?: 'Image'; key: string } | null;
        tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
      };
      creator?: { __typename?: 'ChallengeCreator'; username: string; vipLevel: VipLevel } | null;
      challengeReward?: {
        __typename?: 'ChallengeReward';
        user: { __typename?: 'User'; username?: string | null; vipLevel: VipLevel };
      } | null;
    }> | null;
  };
};

export type GetGameCountByProviderQueryVariables = Exact<{
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  isFavourite?: InputMaybe<Scalars['Boolean']>;
  categories?: InputMaybe<Array<GameCategoryType> | GameCategoryType>;
  search?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetGameCountByProviderQuery = {
  __typename?: 'Query';
  getGameCountByProvider: Array<{
    __typename?: 'GameCountByProvider';
    gamesCount: number;
    provider: { __typename?: 'GameProvider'; id: string; name: string };
  }>;
};

export type GetGameTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGameTagsQuery = {
  __typename?: 'Query';
  gameTags: Array<{ __typename?: 'GameTag'; name: string }>;
};

export type GetGameSeedsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
}>;

export type GetGameSeedsQuery = {
  __typename?: 'Query';
  gameSeeds: {
    __typename?: 'PaginatedGameSeed';
    totalCount: number;
    nextCursor?: any | null;
    nodes?: Array<{
      __typename?: 'GameSeed';
      id: string;
      clientSeed?: string | null;
      seed?: string | null;
      hashedSeed: string;
      status?: GameSeedStatus | null;
      currentNonce: string;
      createdAt: any;
    }> | null;
  };
};

export type GetCurrentGameSeedsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentGameSeedsQuery = {
  __typename?: 'Query';
  currentGameSeeds: Array<{
    __typename?: 'GameSeed';
    id: string;
    clientSeed?: string | null;
    seed?: string | null;
    hashedSeed: string;
    status?: GameSeedStatus | null;
    currentNonce: string;
    createdAt: any;
  }>;
};

export type GetDepositsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency> | Currency>;
}>;

export type GetDepositsQuery = {
  __typename?: 'Query';
  deposits: {
    __typename?: 'PaginatedDeposit';
    totalCount: number;
    nextCursor?: any | null;
    nodes?: Array<{
      __typename?: 'Deposit';
      id: string;
      userId: string;
      onChainTransactionId: string;
      chain: Chain;
      currency: Currency;
      amount: any;
      createdAt: any;
      status: DepositStatus;
    }> | null;
  };
};

export type GetBetsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency> | Currency>;
}>;

export type GetBetsQuery = {
  __typename?: 'Query';
  bets: {
    __typename?: 'PaginatedBet';
    nextCursor?: any | null;
    nodes?: Array<{
      __typename?: 'Bet';
      updatedAt: any;
      createdAt: any;
      id: string;
      currency: Currency;
      amount: any;
      payout?: any | null;
      multiplier?: number | null;
      game?: {
        __typename?: 'Game';
        id: string;
        name: string;
        slug: string;
        gameAndGameCategories?: Array<{
          __typename?: 'GameAndGameCategory';
          gameCategoryName: string;
          gameId: string;
          main: boolean;
        }> | null;
      } | null;
    }> | null;
  };
};

export type GetMyBetsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetMyBetsQuery = {
  __typename?: 'Query';
  myBets: {
    __typename?: 'PaginatedBet';
    nodes?: Array<{
      __typename?: 'Bet';
      id: string;
      currency: Currency;
      amount: any;
      payout?: any | null;
      multiplier?: number | null;
      game?: {
        __typename?: 'Game';
        id: string;
        name: string;
        slug: string;
        gameAndGameCategories?: Array<{
          __typename?: 'GameAndGameCategory';
          gameCategoryName: string;
          gameId: string;
          main: boolean;
        }> | null;
      } | null;
    }> | null;
  };
};

export type GameBodyFragment = {
  __typename?: 'Game';
  id: string;
  name: string;
  externalId?: string | null;
  description?: string | null;
  originalGame?: OriginalGame | null;
  slug: string;
  releasedDate?: any | null;
  demoMode: boolean;
  edge: any;
  accentColor: string;
  gameAndGameCategories?: Array<{
    __typename?: 'GameAndGameCategory';
    gameCategoryName: string;
    gameId: string;
    main: boolean;
  }> | null;
  provider?: {
    __typename?: 'GameProvider';
    id: string;
    slug: string;
    name: string;
    disabledFiatCurrencies: Array<FiatCurrency>;
  } | null;
  image?: { __typename?: 'Image'; key: string } | null;
  tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
};

export type GetGamesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  providers?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  providerSlug?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<GameCategoryType> | GameCategoryType>;
  isOriginal?: InputMaybe<Scalars['Boolean']>;
  sortBy?: InputMaybe<GameSortby>;
  isFavourite?: InputMaybe<Scalars['Boolean']>;
  sortRandomSeed?: InputMaybe<Scalars['Int']>;
}>;

export type GetGamesQuery = {
  __typename?: 'Query';
  games: {
    __typename?: 'PaginatedGame';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'Game';
      id: string;
      name: string;
      externalId?: string | null;
      description?: string | null;
      originalGame?: OriginalGame | null;
      slug: string;
      releasedDate?: any | null;
      demoMode: boolean;
      edge: any;
      accentColor: string;
      gameAndGameCategories?: Array<{
        __typename?: 'GameAndGameCategory';
        gameCategoryName: string;
        gameId: string;
        main: boolean;
      }> | null;
      provider?: {
        __typename?: 'GameProvider';
        id: string;
        slug: string;
        name: string;
        disabledFiatCurrencies: Array<FiatCurrency>;
      } | null;
      image?: { __typename?: 'Image'; key: string } | null;
      tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
    }> | null;
  };
};

export type GetGameBySlugQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
}>;

export type GetGameBySlugQuery = {
  __typename?: 'Query';
  game: {
    __typename?: 'Game';
    id: string;
    name: string;
    externalId?: string | null;
    description?: string | null;
    originalGame?: OriginalGame | null;
    slug: string;
    releasedDate?: any | null;
    demoMode: boolean;
    edge: any;
    accentColor: string;
    gameAndGameCategories?: Array<{
      __typename?: 'GameAndGameCategory';
      gameCategoryName: string;
      gameId: string;
      main: boolean;
    }> | null;
    provider?: {
      __typename?: 'GameProvider';
      id: string;
      slug: string;
      name: string;
      disabledFiatCurrencies: Array<FiatCurrency>;
    } | null;
    image?: { __typename?: 'Image'; key: string } | null;
    tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
  };
};

export type GetMinesActiveBetQueryVariables = Exact<{ [key: string]: never }>;

export type GetMinesActiveBetQuery = {
  __typename?: 'Query';
  minesActiveBet?: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    payout?: any | null;
    currency: Currency;
    multiplier?: number | null;
    gameSeedNonce?: number | null;
    afterBalance?: any | null;
    game?: { __typename?: 'Game'; id: string; name: string; slug: string; edge: any } | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      updatedAt: any;
      createdAt: any;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        mines?: {
          __typename?: 'MinesActionModel';
          minesResult?: Array<number> | null;
          minesCount: number;
          winMultiplier?: any | null;
          selected?: Array<number> | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type CommissionTotalsQueryVariables = Exact<{ [key: string]: never }>;

export type CommissionTotalsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id?: string | null;
    commissionTotals: Array<{ __typename?: 'CurrencyAmount'; currency: Currency; amount: any }>;
    commissionBalances: Array<{ __typename?: 'CurrencyAmount'; currency: Currency; amount: any }>;
  };
};

export type GetCrashGameQueryVariables = Exact<{ [key: string]: never }>;

export type GetCrashGameQuery = {
  __typename?: 'Query';
  crashGame: {
    __typename?: 'CrashGamePayload';
    crashGameId: number;
    crashHistories: Array<{
      __typename?: 'CrashGame';
      id: number;
      seed: string;
      crashPoint?: any | null;
      countOfPlayer?: number | null;
      countOfCashedOut?: number | null;
    }>;
    betPayoutPayloads: Array<{
      __typename?: 'CrashBetPayoutPayload';
      betId: string;
      currency: Currency;
      amount: any;
      betAt: any;
      payout?: any | null;
      multiplier?: any | null;
      payoutType?: PayoutType | null;
      user?: { __typename?: 'User'; username?: string | null; vipLevel: VipLevel } | null;
    }>;
  };
};

export type GetCrashGameHistoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCrashGameHistoriesQuery = {
  __typename?: 'Query';
  getCrashHistories: Array<{
    __typename?: 'CrashGame';
    id: number;
    seed: string;
    crashPoint?: any | null;
    countOfPlayer?: number | null;
    countOfCashedOut?: number | null;
  }>;
};

export type GetCrashGameByGameIdQueryVariables = Exact<{
  id: Scalars['Float'];
}>;

export type GetCrashGameByGameIdQuery = {
  __typename?: 'Query';
  getCrashGame: {
    __typename?: 'CrashGame';
    id: number;
    seed: string;
    crashPoint?: any | null;
    countOfPlayer?: number | null;
    countOfCashedOut?: number | null;
  };
};

export type GetCrashGameByBetIdQueryVariables = Exact<{
  betId: Scalars['String'];
}>;

export type GetCrashGameByBetIdQuery = {
  __typename?: 'Query';
  getCrashGameByBetId: {
    __typename?: 'CrashGame';
    id: number;
    seed: string;
    crashPoint?: any | null;
    countOfPlayer?: number | null;
    countOfCashedOut?: number | null;
  };
};

export type GetCrashGameLeaderboardQueryVariables = Exact<{
  crashGameId: Scalars['Float'];
}>;

export type GetCrashGameLeaderboardQuery = {
  __typename?: 'Query';
  getCrashGameLeaderboard: Array<{
    __typename?: 'CrashBetActivityPayload';
    id: string;
    username?: string | null;
    vipLevel: VipLevel;
    currency: Currency;
    amount: any;
    payout: any;
    gameName: string;
    gameCategory?: string | null;
    gameSlug: string;
    payoutUSD?: any | null;
    multiplier?: string | null;
  }>;
};

export type HiloActiveBetQueryVariables = Exact<{ [key: string]: never }>;

export type HiloActiveBetQuery = {
  __typename?: 'Query';
  hiloActiveBet?: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    payout?: any | null;
    currency: Currency;
    afterBalance?: any | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        hilo?: {
          __typename?: 'HiloActionModel';
          card?: number | null;
          guess?: HiloGuess | null;
          winMultiplier?: any | null;
          actionType: HiloActionType;
        } | null;
      };
    }> | null;
  } | null;
};

export type GetGlobalDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetGlobalDataQuery = {
  __typename?: 'Query';
  appSettings: {
    __typename?: 'AppSettings';
    geoRestrictedRegions: Array<string>;
    minTipUSD: number;
    dice: {
      __typename?: 'OriginalGameSetting';
      maxPayoutUSD: number;
      maxBetUSD: number;
      minBetUSD?: number | null;
    };
    mines: {
      __typename?: 'OriginalGameSetting';
      maxPayoutUSD: number;
      maxBetUSD: number;
      minBetUSD?: number | null;
    };
    plinko: {
      __typename?: 'OriginalGameSetting';
      maxPayoutUSD: number;
      maxBetUSD: number;
      minBetUSD?: number | null;
    };
    crash: {
      __typename?: 'OriginalGameSetting';
      maxPayoutUSD: number;
      maxBetUSD: number;
      minBetUSD?: number | null;
    };
    limbo: {
      __typename?: 'OriginalGameSetting';
      maxPayoutUSD: number;
      maxBetUSD: number;
      minBetUSD?: number | null;
    };
    keno: {
      __typename?: 'OriginalGameSetting';
      maxPayoutUSD: number;
      maxBetUSD: number;
      minBetUSD?: number | null;
    };
    hilo: {
      __typename?: 'OriginalGameSetting';
      maxPayoutUSD: number;
      maxBetUSD: number;
      minBetUSD?: number | null;
    };
    blackjack: {
      __typename?: 'BlackjackGameSetting';
      maxPayoutUSD: number;
      maxBetUSD: number;
      sidebetLimit?: number | null;
      minBetUSD?: number | null;
    };
    sportsBet: { __typename?: 'SportsBetSetting'; minBetUSD: number; minPartialBetRatio: number };
  };
  vipLevels: Array<{ __typename?: 'Vip'; level: VipLevel; amount?: any | null }>;
};

export type GetMyKycQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyKycQuery = {
  __typename?: 'Query';
  kyc?: {
    __typename?: 'Kyc';
    id: string;
    firstName: string;
    lastName: string;
    country: string;
    birthday: any;
    city: string;
    address: string;
    postcode: string;
    occupation: string;
    level1Status: KycLevelStatus;
    level2Status: KycLevelStatus;
    level3Status: KycLevelStatus;
    canCompletePoa: boolean;
  } | null;
};

export type GetOnlineUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetOnlineUsersQuery = { __typename?: 'Query'; onlineSession: number };

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyProfileQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id?: string | null;
    username?: string | null;
    email: string;
    emailVerifiedAt?: any | null;
    vipLevel: VipLevel;
    otpSentAt?: any | null;
    updatedAt: any;
    createdAt: any;
    passwordUpdatedAt?: any | null;
    anonymous: boolean;
    avatar: number;
    avatarBackground: number;
    linkedOauthProviders: Array<OauthProvider>;
    chatBanUntil?: any | null;
    xp: number;
    intercomToken: string;
    currentSession: {
      __typename?: 'UserSession';
      id: string;
      ip?: string | null;
      ua?: string | null;
      os?: string | null;
      device?: string | null;
      browser?: string | null;
      country?: string | null;
      city?: string | null;
      updatedAt: any;
      createdAt: any;
      refreshedAt?: any | null;
      lastUsedAt: any;
      active: boolean;
    };
    account: {
      __typename?: 'Account';
      balances: Array<{ __typename?: 'Balance'; currency: Currency; amount: any }>;
      vaultBalances: Array<{ __typename?: 'CurrencyAmount'; currency: Currency; amount: any }>;
    };
    raceInfo?: {
      __typename?: 'RaceInfo';
      id: string;
      startingWager: any;
      rank?: number | null;
    } | null;
  };
};

export type GetMyStatisticQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyStatisticQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id?: string | null;
    statistic?: { __typename?: 'UserStatistic'; bets: number; wagered: any } | null;
  };
};

export type GetBanStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetBanStatusQuery = {
  __typename?: 'Query';
  me: { __typename?: 'User'; id?: string | null; chatBanUntil?: any | null };
};

export type PendingVipRewardsQueryVariables = Exact<{
  skipRaceReward: Scalars['Boolean'];
}>;

export type PendingVipRewardsQuery = {
  __typename?: 'Query';
  vipDailyRakeback: {
    __typename?: 'DailyRakebackAmount';
    claimDate: any;
    currencyAmounts: Array<{ __typename?: 'CurrencyAmount'; amount: any; currency: Currency }>;
  };
  vipWeeklyBonus: { __typename?: 'WeeklyBonusAmount'; usdValue: any; claimDate: any };
  vipMonthlyBonus: { __typename?: 'MonthlyBonusAmount'; usdValue: any; claimMonth: string };
  vipUpgradeBonuses: Array<{
    __typename?: 'VipUserUpgrade';
    upgradeBonusUsdAmount: any;
    vipLevel: VipLevel;
    createdAt: any;
    id: string;
  }>;
  raceUserReward?: Array<{
    __typename?: 'RaceRewardPayload';
    raceId: string;
    currency: Currency;
    wagered: any;
    amount: any;
    rank: number;
    rewardStartDate: any;
    rewardEndDate: any;
  }>;
  vipBonus: Array<{
    __typename?: 'VipBonus';
    id: string;
    type: VipBonusType;
    currency: Currency;
    amount: any;
    cadence?: number | null;
    occurrence: number;
    cancelledAt?: any | null;
    createdAt: any;
    endAt: any;
    bonusClaims?: Array<{
      __typename?: 'VipBonusClaim';
      currency: Currency;
      amount: any;
      occurrenceIndex: number;
    }> | null;
  }>;
  challengeRewards: Array<{
    __typename?: 'ChallengeReward';
    claimedAt?: any | null;
    expiredAt: any;
    challenge: {
      __typename?: 'Challenge';
      id: string;
      usdMinBet: any;
      minMultiplier: number;
      currency: Currency;
      amount: any;
      referredOnly: boolean;
      completed: boolean;
      game: {
        __typename?: 'Game';
        id: string;
        name: string;
        externalId?: string | null;
        description?: string | null;
        originalGame?: OriginalGame | null;
        slug: string;
        releasedDate?: any | null;
        demoMode: boolean;
        edge: any;
        accentColor: string;
        gameAndGameCategories?: Array<{
          __typename?: 'GameAndGameCategory';
          gameCategoryName: string;
          gameId: string;
          main: boolean;
        }> | null;
        provider?: {
          __typename?: 'GameProvider';
          id: string;
          slug: string;
          name: string;
          disabledFiatCurrencies: Array<FiatCurrency>;
        } | null;
        image?: { __typename?: 'Image'; key: string } | null;
        tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
      };
      creator?: { __typename?: 'ChallengeCreator'; username: string; vipLevel: VipLevel } | null;
    };
  }>;
};

export type VipUpgradeBonusesQueryVariables = Exact<{ [key: string]: never }>;

export type VipUpgradeBonusesQuery = {
  __typename?: 'Query';
  vipUpgradeBonuses: Array<{
    __typename?: 'VipUserUpgrade';
    id: string;
    vipLevel: VipLevel;
    upgradeBonusUsdAmount: any;
    createdAt: any;
  }>;
};

export type GetMyRaceInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyRaceInfoQuery = {
  __typename?: 'Query';
  myRaceInfo: { __typename?: 'RaceInfo'; id: string; startingWager: any; rank?: number | null };
};

export type GetGameProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type GetGameProvidersQuery = {
  __typename?: 'Query';
  gameProviders: Array<{
    __typename?: 'GameProvider';
    id: string;
    name: string;
    gamesCount: number;
    slug: string;
    image?: { __typename?: 'Image'; id: string; key: string } | null;
  }>;
};

export type GetMyReferralStatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyReferralStatsQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id?: string | null;
    refereeCount: number;
    refereeWagered: Array<{ __typename?: 'CurrencyAmount'; currency: Currency; amount: any }>;
    commissionTotals: Array<{ __typename?: 'CurrencyAmount'; currency: Currency; amount: any }>;
  };
};

export type GetLobbyGamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLobbyGamesQuery = {
  __typename?: 'Query';
  slots: {
    __typename?: 'PaginatedGame';
    nodes?: Array<{
      __typename?: 'Game';
      id: string;
      name: string;
      externalId?: string | null;
      description?: string | null;
      originalGame?: OriginalGame | null;
      slug: string;
      releasedDate?: any | null;
      demoMode: boolean;
      edge: any;
      accentColor: string;
      gameAndGameCategories?: Array<{
        __typename?: 'GameAndGameCategory';
        gameCategoryName: string;
        gameId: string;
        main: boolean;
      }> | null;
      provider?: {
        __typename?: 'GameProvider';
        id: string;
        slug: string;
        name: string;
        disabledFiatCurrencies: Array<FiatCurrency>;
      } | null;
      image?: { __typename?: 'Image'; key: string } | null;
      tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
    }> | null;
  };
  liveCasino: {
    __typename?: 'PaginatedGame';
    nodes?: Array<{
      __typename?: 'Game';
      id: string;
      name: string;
      externalId?: string | null;
      description?: string | null;
      originalGame?: OriginalGame | null;
      slug: string;
      releasedDate?: any | null;
      demoMode: boolean;
      edge: any;
      accentColor: string;
      gameAndGameCategories?: Array<{
        __typename?: 'GameAndGameCategory';
        gameCategoryName: string;
        gameId: string;
        main: boolean;
      }> | null;
      provider?: {
        __typename?: 'GameProvider';
        id: string;
        slug: string;
        name: string;
        disabledFiatCurrencies: Array<FiatCurrency>;
      } | null;
      image?: { __typename?: 'Image'; key: string } | null;
      tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
    }> | null;
  };
  gameShows: {
    __typename?: 'PaginatedGame';
    nodes?: Array<{
      __typename?: 'Game';
      id: string;
      name: string;
      externalId?: string | null;
      description?: string | null;
      originalGame?: OriginalGame | null;
      slug: string;
      releasedDate?: any | null;
      demoMode: boolean;
      edge: any;
      accentColor: string;
      gameAndGameCategories?: Array<{
        __typename?: 'GameAndGameCategory';
        gameCategoryName: string;
        gameId: string;
        main: boolean;
      }> | null;
      provider?: {
        __typename?: 'GameProvider';
        id: string;
        slug: string;
        name: string;
        disabledFiatCurrencies: Array<FiatCurrency>;
      } | null;
      image?: { __typename?: 'Image'; key: string } | null;
      tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
    }> | null;
  };
  tableGames: {
    __typename?: 'PaginatedGame';
    nodes?: Array<{
      __typename?: 'Game';
      id: string;
      name: string;
      externalId?: string | null;
      description?: string | null;
      originalGame?: OriginalGame | null;
      slug: string;
      releasedDate?: any | null;
      demoMode: boolean;
      edge: any;
      accentColor: string;
      gameAndGameCategories?: Array<{
        __typename?: 'GameAndGameCategory';
        gameCategoryName: string;
        gameId: string;
        main: boolean;
      }> | null;
      provider?: {
        __typename?: 'GameProvider';
        id: string;
        slug: string;
        name: string;
        disabledFiatCurrencies: Array<FiatCurrency>;
      } | null;
      image?: { __typename?: 'Image'; key: string } | null;
      tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
    }> | null;
  };
  originals: {
    __typename?: 'PaginatedGame';
    nodes?: Array<{
      __typename?: 'Game';
      id: string;
      name: string;
      externalId?: string | null;
      description?: string | null;
      originalGame?: OriginalGame | null;
      slug: string;
      releasedDate?: any | null;
      demoMode: boolean;
      edge: any;
      accentColor: string;
      gameAndGameCategories?: Array<{
        __typename?: 'GameAndGameCategory';
        gameCategoryName: string;
        gameId: string;
        main: boolean;
      }> | null;
      provider?: {
        __typename?: 'GameProvider';
        id: string;
        slug: string;
        name: string;
        disabledFiatCurrencies: Array<FiatCurrency>;
      } | null;
      image?: { __typename?: 'Image'; key: string } | null;
      tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
    }> | null;
  };
};

export type GetRaceLeaderBoardQueryVariables = Exact<{ [key: string]: never }>;

export type GetRaceLeaderBoardQuery = {
  __typename?: 'Query';
  raceLeaderBoard: {
    __typename?: 'RaceLeaderBoardPayload';
    race: {
      __typename?: 'Race';
      id: string;
      name: string;
      prizeDistribution: Array<number>;
      totalPrizeUsd: any;
      currency: Currency;
      endAt: any;
      type: RaceType;
    };
    leaderboard: Array<{
      __typename?: 'RaceRank';
      userId?: string | null;
      username?: string | null;
      raceId: string;
      raceName: string;
      currency: Currency;
      wagered: any;
      rank: number;
      vipLevel?: VipLevel | null;
      prize: any;
    }>;
  };
};

export type GetRaceLeaderBoardV2QueryVariables = Exact<{ [key: string]: never }>;

export type GetRaceLeaderBoardV2Query = {
  __typename?: 'Query';
  raceLeaderBoardV2: {
    __typename?: 'RaceLeaderBoardV2Payload';
    race: {
      __typename?: 'Race';
      id: string;
      type: RaceType;
      name: string;
      currency: Currency;
      totalPrizeUsd: any;
      prizeDistribution: Array<number>;
      startAt: any;
      endAt: any;
    };
    raceWager: Array<{
      __typename?: 'RaceWagerPayload';
      vipLevel?: VipLevel | null;
      username?: string | null;
      wagered: any;
      raceId: string;
      raceEntryId: string;
    }>;
  };
};

export type GetSportsBetQueryVariables = Exact<{
  id: Scalars['String'];
  withCashoutOdds: Scalars['Boolean'];
}>;

export type GetSportsBetQuery = {
  __typename?: 'Query';
  sportsBet: {
    __typename?: 'SportsBet';
    totalOddsDecimal: any;
    actualOddsDecimal: any;
    cashoutOddsDecimal?: any | null;
    status: SportsBetStatus;
    amount: any;
    currency: Currency;
    account: {
      __typename?: 'Account';
      user: { __typename?: 'User'; username?: string | null; vipLevel: VipLevel };
    };
    cashoutAvailable: {
      __typename?: 'SportsBetCashoutAvailable';
      reason?: string | null;
      canCashout: boolean;
    };
    settlement?: {
      __typename?: 'SportsBetSettlement';
      payoutOddsDecimal: any;
      payout?: any | null;
    } | null;
    selections: Array<{
      __typename?: 'SportsBetSelection';
      id: string;
      status: SportsBetSelectionStatus;
      oddsNumerator: any;
      oddsDenominator: any;
      createdAt: any;
      updatedAt: any;
      marketSelection: {
        __typename?: 'SportsMarketSelection';
        id: string;
        status: SportsMarketSelectionStatus;
        oddsNumerator: any;
        oddsDenominator: any;
        name: string;
        market: {
          __typename?: 'SportsMarket';
          id: string;
          typeName: string;
          typeId: string;
          name: string;
          status: SportsMarketStatus;
          disabled: boolean;
          expiryTime: any;
          cashoutStatus?: string | null;
          cashoutEnabled: boolean;
          inPlay: boolean;
          fixture: {
            __typename?: 'SportsFixture';
            competitors: Array<{
              __typename?: 'SportsCompetitorModel';
              geniusId: string;
              name: string;
              shortName?: string | null;
              isHome: boolean;
            }>;
          };
        };
      };
      snapshot: {
        __typename?: 'SportsBetSelectionSnapshot';
        marketId: string;
        marketName: string;
        marketTypeName: string;
        marketExpiryTime: any;
        fixtureId: string;
        fixtureSlug: string;
        fixtureName: string;
        fixtureStartTime: any;
        fixtureStatus: SportsFixtureStatus;
        competitionId: string;
        competitionSlug: string;
        competitionName: string;
        categoryId: string;
        categorySlug: string;
        categoryName: string;
        sports: Sports;
      };
    }>;
  };
};

export type GetSportsBetsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<SportsBetStatus> | SportsBetStatus>;
  currencyIn?: InputMaybe<Array<Currency> | Currency>;
  withCashoutOdds: Scalars['Boolean'];
}>;

export type GetSportsBetsQuery = {
  __typename?: 'Query';
  sportsBets: {
    __typename?: 'PaginatedSportsBet';
    totalCount: number;
    nextCursor?: any | null;
    nodes?: Array<{
      __typename?: 'SportsBet';
      cashoutOddsDecimal?: any | null;
      updatedAt: any;
      createdAt: any;
      id: string;
      currency: Currency;
      amount: any;
      totalOddsDecimal: any;
      actualOddsDecimal: any;
      status: SportsBetStatus;
      cashoutAvailable: {
        __typename?: 'SportsBetCashoutAvailable';
        reason?: string | null;
        canCashout: boolean;
      };
      selections: Array<{
        __typename?: 'SportsBetSelection';
        id: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsBetSelectionStatus;
        marketSelection: {
          __typename?: 'SportsMarketSelection';
          id: string;
          status: SportsMarketSelectionStatus;
          oddsNumerator: any;
          oddsDenominator: any;
          name: string;
          market: {
            __typename?: 'SportsMarket';
            id: string;
            typeName: string;
            typeId: string;
            name: string;
            status: SportsMarketStatus;
            disabled: boolean;
            expiryTime: any;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            inPlay: boolean;
            fixture: {
              __typename?: 'SportsFixture';
              competitors: Array<{
                __typename?: 'SportsCompetitorModel';
                geniusId: string;
                name: string;
                shortName?: string | null;
                isHome: boolean;
              }>;
            };
          };
        };
        snapshot: {
          __typename?: 'SportsBetSelectionSnapshot';
          marketId: string;
          marketName: string;
          marketTypeName: string;
          marketExpiryTime: any;
          fixtureId: string;
          fixtureSlug: string;
          fixtureName: string;
          fixtureStartTime: any;
          fixtureStatus: SportsFixtureStatus;
          competitionId: string;
          competitionSlug: string;
          competitionName: string;
          categoryId: string;
          categorySlug: string;
          categoryName: string;
          sports: Sports;
        };
      }>;
      settlement?: {
        __typename?: 'SportsBetSettlement';
        id: string;
        payout?: any | null;
        payoutOddsDecimal: any;
        createdAt: any;
      } | null;
    }> | null;
  };
};

export type GetMySportsBetsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetMySportsBetsQuery = {
  __typename?: 'Query';
  sportsBets: {
    __typename?: 'PaginatedSportsBet';
    nodes?: Array<{
      __typename?: 'SportsBet';
      id: string;
      currency: Currency;
      amount: any;
      totalOddsDecimal: any;
      actualOddsDecimal: any;
      status: SportsBetStatus;
      cashoutAvailable: {
        __typename?: 'SportsBetCashoutAvailable';
        reason?: string | null;
        canCashout: boolean;
      };
      selections: Array<{
        __typename?: 'SportsBetSelection';
        id: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsBetSelectionStatus;
        marketSelection: {
          __typename?: 'SportsMarketSelection';
          id: string;
          status: SportsMarketSelectionStatus;
          oddsNumerator: any;
          oddsDenominator: any;
          name: string;
          market: {
            __typename?: 'SportsMarket';
            id: string;
            typeName: string;
            typeId: string;
            name: string;
            status: SportsMarketStatus;
            disabled: boolean;
            expiryTime: any;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            inPlay: boolean;
            fixture: {
              __typename?: 'SportsFixture';
              competitors: Array<{
                __typename?: 'SportsCompetitorModel';
                geniusId: string;
                name: string;
                shortName?: string | null;
                isHome: boolean;
              }>;
            };
          };
        };
        snapshot: {
          __typename?: 'SportsBetSelectionSnapshot';
          marketId: string;
          marketName: string;
          marketTypeName: string;
          marketExpiryTime: any;
          fixtureId: string;
          fixtureSlug: string;
          fixtureName: string;
          fixtureStartTime: any;
          fixtureStatus: SportsFixtureStatus;
          competitionId: string;
          competitionSlug: string;
          competitionName: string;
          categoryId: string;
          categorySlug: string;
          categoryName: string;
          sports: Sports;
        };
      }>;
      settlement?: {
        __typename?: 'SportsBetSettlement';
        id: string;
        payout?: any | null;
        payoutOddsDecimal: any;
        createdAt: any;
      } | null;
    }> | null;
  };
};

export type GetMyRefereesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  campaignId?: InputMaybe<Scalars['String']>;
}>;

export type GetMyRefereesQuery = {
  __typename?: 'Query';
  referees: {
    __typename?: 'PaginatedUser';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'User';
      username?: string | null;
      createdAt: any;
      campaignId?: string | null;
      vipLevel: VipLevel;
      wagered: Array<{ __typename?: 'CurrencyAmount'; currency: Currency; amount: any }>;
      commissionGenerated: Array<{
        __typename?: 'CurrencyAmount';
        currency: Currency;
        amount: any;
      }>;
    }> | null;
  };
};

export type GetRainInfoQueryVariables = Exact<{
  tipRainId: Scalars['String'];
}>;

export type GetRainInfoQuery = {
  __typename?: 'Query';
  tipRainInfo: {
    __typename?: 'TipRain';
    id: string;
    currency: Currency;
    amount: any;
    chatRoom: ChatRoom;
    createdAt: any;
    tips: Array<{
      __typename?: 'Tip';
      id: string;
      vipLevel?: VipLevel | null;
      currency: Currency;
      amount: any;
      createdAt: any;
      receiver: { __typename?: 'User'; username?: string | null; vipLevel: VipLevel };
    }>;
  };
};

export type GetPricesQueryVariables = Exact<{
  currency: FiatCurrency;
}>;

export type GetPricesQuery = {
  __typename?: 'Query';
  cryptoPrices: Array<{ __typename?: 'CryptoPrice'; name: Currency; price: any }>;
  fiatPrices: Array<{ __typename?: 'FiatPrice'; name: FiatCurrency; price: any }>;
};

export type GetLatestSportBetsQueryVariables = Exact<{
  count?: InputMaybe<Scalars['Int']>;
}>;

export type GetLatestSportBetsQuery = {
  __typename?: 'Query';
  latestSportsBets: Array<{
    __typename?: 'SportsBetActivity';
    id: string;
    vipLevel: VipLevel;
    username?: string | null;
    currency: Currency;
    amount: any;
    categoryName: string;
    categorySlug: string;
    competitionName: string;
    competitionSlug: string;
    selectionNumber: number;
    fixtureName: string;
    sports: Sports;
    fixtureSlug: string;
    oddsDecimal: any;
  }>;
};

export type GetSportsHighRollerBetsQueryVariables = Exact<{
  count?: InputMaybe<Scalars['Int']>;
}>;

export type GetSportsHighRollerBetsQuery = {
  __typename?: 'Query';
  highRollerSportsBets: Array<{
    __typename?: 'SportsBetActivity';
    id: string;
    vipLevel: VipLevel;
    username?: string | null;
    currency: Currency;
    amount: any;
    categoryName: string;
    categorySlug: string;
    competitionName: string;
    competitionSlug: string;
    selectionNumber: number;
    fixtureName: string;
    sports: Sports;
    fixtureSlug: string;
    oddsDecimal: any;
  }>;
};

export type GetSportsMatchStatesQueryVariables = Exact<{
  fixtureIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetSportsMatchStatesQuery = {
  __typename?: 'Query';
  sportsMatchStates: Array<{
    __typename?: 'SportsMatchState';
    id: string;
    fixtureId: string;
    updatedAt: any;
    createdAt: any;
    matchSummary?: {
      __typename?: 'SportsMatchSummary';
      matchPhase?: SportsMatchPhase | null;
      currentPhaseName?: string | null;
      homeScore?: number | null;
      awayScore?: number | null;
      server?: string | null;
      timeRemaining?: number | null;
      timeRemainingAt?: any | null;
      phases?: Array<{
        __typename?: 'SportsMatchPhaseSummary';
        phase: number;
        homeScore?: number | null;
        awayScore?: number | null;
        status?: SportsMatchPhaseStatus | null;
      }> | null;
    } | null;
  }>;
};

export type GetSportsCompetitionQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  upcomingHour?: InputMaybe<Scalars['Int']>;
  liveOnly?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetSportsCompetitionQuery = {
  __typename?: 'Query';
  sportsCompetition: {
    __typename?: 'SportsCompetition';
    id: string;
    name: string;
    slug: string;
    fixtures: {
      __typename?: 'PaginatedSportsFixture';
      totalCount: number;
      nodes?: Array<{
        __typename?: 'SportsFixture';
        id: string;
        slug: string;
        name: string;
        startTime: any;
        updatedAt: any;
        createdAt: any;
        markets: Array<{
          __typename?: 'SportsMarket';
          id: string;
          name: string;
          typeId: string;
          typeName: string;
          status: SportsMarketStatus;
          inPlay: boolean;
          expiryTime: any;
          disabled: boolean;
          cashoutStatus?: string | null;
          cashoutEnabled: boolean;
          selections: Array<{
            __typename?: 'SportsMarketSelection';
            id: string;
            name: string;
            oddsNumerator: any;
            oddsDenominator: any;
            status: SportsMarketSelectionStatus;
          }>;
        }>;
        allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
        competitors: Array<{
          __typename?: 'SportsCompetitorModel';
          geniusId: string;
          name: string;
          shortName?: string | null;
          isHome: boolean;
        }>;
        matchState?: {
          __typename?: 'SportsMatchState';
          id: string;
          fixtureId: string;
          updatedAt: any;
          createdAt: any;
          matchSummary?: {
            __typename?: 'SportsMatchSummary';
            matchPhase?: SportsMatchPhase | null;
            currentPhaseName?: string | null;
            homeScore?: number | null;
            awayScore?: number | null;
            server?: string | null;
            timeRemaining?: number | null;
            timeRemainingAt?: any | null;
            phases?: Array<{
              __typename?: 'SportsMatchPhaseSummary';
              phase: number;
              homeScore?: number | null;
              awayScore?: number | null;
              status?: SportsMatchPhaseStatus | null;
            }> | null;
          } | null;
        } | null;
      }> | null;
    };
    category: {
      __typename?: 'SportsCategory';
      id: string;
      slug: string;
      name: string;
      sports: Sports;
    };
  };
};

export type GetAllSportsFixturesFromCompetitionQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
}>;

export type GetAllSportsFixturesFromCompetitionQuery = {
  __typename?: 'Query';
  sportsCompetition: {
    __typename?: 'SportsCompetition';
    fixtures: {
      __typename?: 'PaginatedSportsFixture';
      totalCount: number;
      nodes?: Array<{
        __typename?: 'SportsFixture';
        id: string;
        name: string;
        slug: string;
        startTime: any;
      }> | null;
    };
  };
};

export type GetSportsSearchResultQueryVariables = Exact<{
  searchTerm: Scalars['String'];
}>;

export type GetSportsSearchResultQuery = {
  __typename?: 'Query';
  searchSportsCategories: {
    __typename?: 'SportsCategorySearchResult';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCategory';
      id: string;
      name: string;
      slug: string;
      sports: Sports;
    }> | null;
  };
  searchSportsCompetitions: {
    __typename?: 'SportsCompetitionSearchResult';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  searchSportsFixtures: {
    __typename?: 'SportsFixtureSearchResult';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsFixture';
      id: string;
      name: string;
      startTime: any;
      slug: string;
      markets: Array<{ __typename?: 'SportsMarket'; inPlay: boolean }>;
      matchState?: {
        __typename?: 'SportsMatchState';
        id: string;
        fixtureId: string;
        updatedAt: any;
        createdAt: any;
        matchSummary?: {
          __typename?: 'SportsMatchSummary';
          matchPhase?: SportsMatchPhase | null;
          currentPhaseName?: string | null;
          homeScore?: number | null;
          awayScore?: number | null;
          server?: string | null;
          timeRemaining?: number | null;
          timeRemainingAt?: any | null;
          phases?: Array<{
            __typename?: 'SportsMatchPhaseSummary';
            phase: number;
            homeScore?: number | null;
            awayScore?: number | null;
            status?: SportsMatchPhaseStatus | null;
          }> | null;
        } | null;
      } | null;
      competition: {
        __typename?: 'SportsCompetition';
        id: string;
        name: string;
        slug: string;
        category: {
          __typename?: 'SportsCategory';
          id: string;
          name: string;
          sports: Sports;
          slug: string;
        };
      };
    }> | null;
  };
};

export type GetMyRakebackBalancesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyRakebackBalancesQuery = {
  __typename?: 'Query';
  instantRakebackBonus: Array<{ __typename?: 'CurrencyAmount'; currency: Currency; amount: any }>;
};

export type GetTransactionsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  typeIn?: InputMaybe<Array<TransactionType> | TransactionType>;
  typeNotIn?: InputMaybe<Array<TransactionType> | TransactionType>;
  currencyIn?: InputMaybe<Array<Currency> | Currency>;
}>;

export type GetTransactionsQuery = {
  __typename?: 'Query';
  transactions: {
    __typename?: 'PaginatedTransaction';
    totalCount: number;
    nextCursor?: any | null;
    nodes?: Array<{
      __typename?: 'Transaction';
      id: string;
      type: TransactionType;
      currency: Currency;
      amount: any;
      depositId?: string | null;
      withdrawalId?: string | null;
      betId?: string | null;
      vaultId?: string | null;
      createdAt: any;
    }> | null;
  };
};

export type GetUnhashedSeedQueryVariables = Exact<{
  hashedSeed: Scalars['String'];
}>;

export type GetUnhashedSeedQuery = {
  __typename?: 'Query';
  gameSeedUnhashed: {
    __typename?: 'GameSeed';
    id: string;
    clientSeed?: string | null;
    seed?: string | null;
    hashedSeed: string;
    status?: GameSeedStatus | null;
    currentNonce: string;
    createdAt: any;
  };
};

export type GetUserChallengesCompletedQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
}>;

export type GetUserChallengesCompletedQuery = {
  __typename?: 'Query';
  challengeCompletedByCurrentUser: {
    __typename?: 'PaginatedChallenges';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'Challenge';
      id: string;
      usdMinBet: any;
      minMultiplier: number;
      currency: Currency;
      amount: any;
      referredOnly: boolean;
      completed: boolean;
      game: {
        __typename?: 'Game';
        id: string;
        name: string;
        externalId?: string | null;
        description?: string | null;
        originalGame?: OriginalGame | null;
        slug: string;
        releasedDate?: any | null;
        demoMode: boolean;
        edge: any;
        accentColor: string;
        gameAndGameCategories?: Array<{
          __typename?: 'GameAndGameCategory';
          gameCategoryName: string;
          gameId: string;
          main: boolean;
        }> | null;
        provider?: {
          __typename?: 'GameProvider';
          id: string;
          slug: string;
          name: string;
          disabledFiatCurrencies: Array<FiatCurrency>;
        } | null;
        image?: { __typename?: 'Image'; key: string } | null;
        tags?: Array<{ __typename?: 'GameTag'; name: string }> | null;
      };
      creator?: { __typename?: 'ChallengeCreator'; username: string; vipLevel: VipLevel } | null;
      challengeReward?: {
        __typename?: 'ChallengeReward';
        user: { __typename?: 'User'; username?: string | null; vipLevel: VipLevel };
      } | null;
    }> | null;
  };
};

export type GetUserSessionsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  inactive?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetUserSessionsQuery = {
  __typename?: 'Query';
  sessions: Array<{
    __typename?: 'UserSession';
    id: string;
    ip?: string | null;
    ua?: string | null;
    os?: string | null;
    device?: string | null;
    browser?: string | null;
    country?: string | null;
    city?: string | null;
    updatedAt: any;
    createdAt: any;
    refreshedAt?: any | null;
    lastUsedAt: any;
    active: boolean;
  }>;
};

export type GetUserProfileQueryVariables = Exact<{
  username: Scalars['String'];
}>;

export type GetUserProfileQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id?: string | null;
    username?: string | null;
    vipLevel: VipLevel;
    createdAt: any;
    avatar: number;
    avatarBackground: number;
    statistic?: { __typename?: 'UserStatistic'; bets: number; wagered: any } | null;
  };
};

export type GetWalletsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWalletsQuery = {
  __typename?: 'Query';
  wallets: Array<{
    __typename?: 'Wallet';
    address: string;
    legacyAddress?: string | null;
    currency: Currency;
    chain: Chain;
    tag?: string | null;
  }>;
};

export type GetWithdrawalsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  currencyIn?: InputMaybe<Array<Currency> | Currency>;
}>;

export type GetWithdrawalsQuery = {
  __typename?: 'Query';
  withdrawals: {
    __typename?: 'PaginatedWithdrawal';
    totalCount: number;
    nextCursor?: any | null;
    nodes?: Array<{
      __typename?: 'Withdrawal';
      id: string;
      onChainTransactionId?: string | null;
      receiverAddress: string;
      status: WithdrawalStatus;
      amount: any;
      fee: any;
      createdAt: any;
      chainCurrency: { __typename?: 'ChainCurrency'; chain: Chain; currency: Currency };
    }> | null;
  };
};

export type GetSportsCompetitionsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['DateTime']>;
  sports?: InputMaybe<Sports>;
  categoryId?: InputMaybe<Scalars['String']>;
  upcomingHour?: InputMaybe<Scalars['Int']>;
  firstFixtures?: InputMaybe<Scalars['Int']>;
  skipFixtures?: InputMaybe<Scalars['Int']>;
  liveOnly?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetSportsCompetitionsQuery = {
  __typename?: 'Query';
  sportsCompetitions: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
};

export type GetPopularSportsCompetitionsQueryVariables = Exact<{
  skipBaseball?: InputMaybe<Scalars['Int']>;
  skipBasketball?: InputMaybe<Scalars['Int']>;
  skipSoccer?: InputMaybe<Scalars['Int']>;
  skipTennis?: InputMaybe<Scalars['Int']>;
  skipIceHockey?: InputMaybe<Scalars['Int']>;
  skipAmericanFootball?: InputMaybe<Scalars['Int']>;
  skipMMA?: InputMaybe<Scalars['Int']>;
  skipBoxing?: InputMaybe<Scalars['Int']>;
  skipTableTennis?: InputMaybe<Scalars['Int']>;
  skipGolf?: InputMaybe<Scalars['Int']>;
  skipRugbyUnion?: InputMaybe<Scalars['Int']>;
  skipEsports?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  upcomingHour?: InputMaybe<Scalars['Int']>;
  liveOnly?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetPopularSportsCompetitionsQuery = {
  __typename?: 'Query';
  BASEBALL: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  BASKETBALL: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  SOCCER: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  TENNIS: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  ICE_HOCKEY: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  AMERICAN_FOOTBALL: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  MMA: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  BOXING: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  TABLE_TENNIS: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  GOLF: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  RUGBY_UNION: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
  ESPORTS: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      fixtures: {
        __typename?: 'PaginatedSportsFixture';
        totalCount: number;
        nodes?: Array<{
          __typename?: 'SportsFixture';
          id: string;
          slug: string;
          name: string;
          startTime: any;
          updatedAt: any;
          createdAt: any;
          markets: Array<{
            __typename?: 'SportsMarket';
            id: string;
            name: string;
            typeId: string;
            typeName: string;
            status: SportsMarketStatus;
            inPlay: boolean;
            expiryTime: any;
            disabled: boolean;
            cashoutStatus?: string | null;
            cashoutEnabled: boolean;
            selections: Array<{
              __typename?: 'SportsMarketSelection';
              id: string;
              name: string;
              oddsNumerator: any;
              oddsDenominator: any;
              status: SportsMarketSelectionStatus;
            }>;
          }>;
          allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
          competitors: Array<{
            __typename?: 'SportsCompetitorModel';
            geniusId: string;
            name: string;
            shortName?: string | null;
            isHome: boolean;
          }>;
          matchState?: {
            __typename?: 'SportsMatchState';
            id: string;
            fixtureId: string;
            updatedAt: any;
            createdAt: any;
            matchSummary?: {
              __typename?: 'SportsMatchSummary';
              matchPhase?: SportsMatchPhase | null;
              currentPhaseName?: string | null;
              homeScore?: number | null;
              awayScore?: number | null;
              server?: string | null;
              timeRemaining?: number | null;
              timeRemainingAt?: any | null;
              phases?: Array<{
                __typename?: 'SportsMatchPhaseSummary';
                phase: number;
                homeScore?: number | null;
                awayScore?: number | null;
                status?: SportsMatchPhaseStatus | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      };
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    }> | null;
  };
};

export type GetSportsFixturesCounterQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  upcomingHour?: InputMaybe<Scalars['Int']>;
  liveOnly?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetSportsFixturesCounterQuery = {
  __typename?: 'Query';
  BASEBALL: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  BASKETBALL: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  SOCCER: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  TENNIS: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  ICE_HOCKEY: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  AMERICAN_FOOTBALL: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  MMA: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  BOXING: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  TABLE_TENNIS: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  GOLF: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  RUGBY_UNION: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
  ESPORTS: {
    __typename?: 'PaginatedSportsCompetition';
    totalCount: number;
    nodes?: Array<{
      __typename?: 'SportsCompetition';
      fixtures: { __typename?: 'PaginatedSportsFixture'; totalCount: number };
    }> | null;
  };
};

export type ValidateAddressQueryVariables = Exact<{
  chain: Chain;
  address: Scalars['String'];
}>;

export type ValidateAddressQuery = { __typename?: 'Query'; validateAddress: boolean };

export type LookupPromotionCodeQueryVariables = Exact<{
  data: PromotionCodeLookupInput;
}>;

export type LookupPromotionCodeQuery = {
  __typename?: 'Query';
  lookupPromotionCode: { __typename?: 'PromotionCodeLookup'; usdRedeemValue: any };
};

export type GetSportsTournamentsQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
}>;

export type GetSportsTournamentsQuery = {
  __typename?: 'Query';
  sportsCategory: {
    __typename?: 'SportsCategory';
    id: string;
    sports: Sports;
    slug: string;
    name: string;
    competitions: {
      __typename?: 'PaginatedSportsCompetition';
      totalCount: number;
      nodes?: Array<{
        __typename?: 'SportsCompetition';
        id: string;
        name: string;
        slug: string;
      }> | null;
    };
  };
};

export type GetSportsFixtureQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>;
}>;

export type GetSportsFixtureQuery = {
  __typename?: 'Query';
  sportsFixture: {
    __typename?: 'SportsFixture';
    id: string;
    slug: string;
    name: string;
    startTime: any;
    updatedAt: any;
    createdAt: any;
    competition: {
      __typename?: 'SportsCompetition';
      id: string;
      name: string;
      slug: string;
      category: {
        __typename?: 'SportsCategory';
        id: string;
        slug: string;
        name: string;
        sports: Sports;
      };
    };
    matchState?: {
      __typename?: 'SportsMatchState';
      id: string;
      fixtureId: string;
      updatedAt: any;
      createdAt: any;
      matchSummary?: {
        __typename?: 'SportsMatchSummary';
        matchPhase?: SportsMatchPhase | null;
        currentPhaseName?: string | null;
        homeScore?: number | null;
        awayScore?: number | null;
        server?: string | null;
        timeRemaining?: number | null;
        timeRemainingAt?: any | null;
        phases?: Array<{
          __typename?: 'SportsMatchPhaseSummary';
          phase: number;
          homeScore?: number | null;
          awayScore?: number | null;
          status?: SportsMatchPhaseStatus | null;
        }> | null;
      } | null;
    } | null;
    markets: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    allMarkets: Array<{ __typename?: 'SportsMarket'; id: string }>;
    competitors: Array<{
      __typename?: 'SportsCompetitorModel';
      geniusId: string;
      name: string;
      shortName?: string | null;
      isHome: boolean;
    }>;
  };
  marketsGroups: {
    __typename?: 'SportsFixture';
    DEFAULT_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    TOP_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    FIRST_HALF_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    SECOND_HALF_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    FIRST_QUARTER_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    SECOND_QUARTER_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    THIRD_QUARTER_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    FOURTH_QUARTER_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    FIRST_PERIOD_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    SECOND_PERIOD_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    THIRD_PERIOD_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    WIN_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    TOTAL_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    GOALSCORER_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    CARDS_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    TEAM_TO_SCORE_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    CORRECT_SCORE_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    DOUBLE_CHANCE_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    CLEAN_SHEET_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    CORNERS_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    PENALTY_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    RACE_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    PLAYER_H2H_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    TIME_OF_SCORE_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    TOUCHDOWN_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    PLAYER_OVERALL_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    WINNER_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    SET_BETTING_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    GAMES_BETTING_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    TIE_BREAK_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    MISCELLANEOUS_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    SCORE_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    FIRST_5_INNINGS_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    METHOD_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    GAME_1_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    GAME_2_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    GAME_3_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    GAME_4_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    GAME_5_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    GAME_6_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    GAME_7_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    PLACE_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    MATCHUP_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    TRYSCORER_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    FIRST_TO_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    LAST_TO_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    OTHER_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    KILL_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    ROSHAN_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    NEXT_TO_HAPPEN_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    TOWER_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    INHIBITOR_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    DRAGON_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    BARRACKS_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    MAP_1_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    MAP_2_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    MAP_3_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    MAP_4_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    MAP_5_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HALF_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    BTTS_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_10_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_15_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_20_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_25_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_30_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_35_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_40_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_5_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_50_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_55_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_60_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_65_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_70_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_75_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_80_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_85_MINUTES_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AFTER_EXTRA_TIME_INCLUDING_NORMAL_TIME_GOALS_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_AT_EXTRA_TIME_HALF_TIME_INCLUDING_NORMAL_TIME_GOALS_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_HALF_TIME_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
    HANDICAP_SECOND_HALF_MARKETS: Array<{
      __typename?: 'SportsMarket';
      id: string;
      name: string;
      typeId: string;
      typeName: string;
      status: SportsMarketStatus;
      inPlay: boolean;
      expiryTime: any;
      disabled: boolean;
      cashoutStatus?: string | null;
      cashoutEnabled: boolean;
      selections: Array<{
        __typename?: 'SportsMarketSelection';
        id: string;
        name: string;
        oddsNumerator: any;
        oddsDenominator: any;
        status: SportsMarketSelectionStatus;
      }>;
    }>;
  };
};

export type AddFavouriteGameMutationVariables = Exact<{
  gameId: Scalars['String'];
}>;

export type AddFavouriteGameMutation = { __typename?: 'Mutation'; addFavouriteGame: boolean };

export type CancelWithdrawalMutationVariables = Exact<{
  data: CancelWithdrawalInput;
}>;

export type CancelWithdrawalMutation = {
  __typename?: 'Mutation';
  cancelWithdrawal: { __typename?: 'Withdrawal'; id: string };
};

export type SendChatMutationVariables = Exact<{
  data: ChatSendInput;
}>;

export type SendChatMutation = { __typename?: 'Mutation'; chatSend: boolean };

export type JoinChatMutationVariables = Exact<{
  data: ChatJoinInput;
}>;

export type JoinChatMutation = { __typename?: 'Mutation'; chatJoin: boolean };

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  passwordChange: { __typename?: 'User'; id?: string | null; passwordUpdatedAt?: any | null };
};

export type ChangeGameSeedMutationVariables = Exact<{
  clientSeed: Scalars['String'];
}>;

export type ChangeGameSeedMutation = {
  __typename?: 'Mutation';
  gameSeedChangeAndReveal: Array<{
    __typename?: 'GameSeed';
    id: string;
    clientSeed?: string | null;
    seed?: string | null;
    hashedSeed: string;
    status?: GameSeedStatus | null;
    currentNonce: string;
    createdAt: any;
  }>;
};

export type BlackjackNextMutationVariables = Exact<{
  data: BlackjackNextInput;
}>;

export type BlackjackNextMutation = {
  __typename?: 'Mutation';
  blackjackNext: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    originalAmount: any;
    payout?: any | null;
    multiplier?: number | null;
    currency: Currency;
    afterBalance?: any | null;
    completedAt?: any | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        blackjack?: {
          __typename?: 'BlackjackActionModel';
          mainPlayerHand: Array<number>;
          mainPlayerActions: Array<BlackjackAction>;
          splitPlayerHand: Array<number>;
          splitPlayerActions: Array<BlackjackAction>;
          dealerHand: Array<number>;
          perfectPairWin?: PerfectPairType | null;
          twentyOnePlusThreeWin?: TwentyOnePlusThreeType | null;
          twentyOnePlusThreeAmount: any;
          perfectPairAmount: any;
          insuranceStatus: InsuranceStatus;
          originalMainBetAmount: any;
          mainHandOutcome: HandOutcome;
          splitHandOutcome: HandOutcome;
        } | null;
      };
    }> | null;
  };
};

export type BlackjackStartMutationVariables = Exact<{
  data: BlackjackStartInput;
}>;

export type BlackjackStartMutation = {
  __typename?: 'Mutation';
  blackjackStart: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    originalAmount: any;
    payout?: any | null;
    multiplier?: number | null;
    currency: Currency;
    afterBalance?: any | null;
    completedAt?: any | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        blackjack?: {
          __typename?: 'BlackjackActionModel';
          mainPlayerHand: Array<number>;
          mainPlayerActions: Array<BlackjackAction>;
          splitPlayerHand: Array<number>;
          splitPlayerActions: Array<BlackjackAction>;
          dealerHand: Array<number>;
          perfectPairWin?: PerfectPairType | null;
          twentyOnePlusThreeWin?: TwentyOnePlusThreeType | null;
          twentyOnePlusThreeAmount: any;
          perfectPairAmount: any;
          insuranceStatus: InsuranceStatus;
          originalMainBetAmount: any;
          mainHandOutcome: HandOutcome;
          splitHandOutcome: HandOutcome;
        } | null;
      };
    }> | null;
  };
};

export type ClaimChallengeRewardMutationVariables = Exact<{
  challengeId: Scalars['String'];
}>;

export type ClaimChallengeRewardMutation = {
  __typename?: 'Mutation';
  claimChallengeReward: {
    __typename?: 'ChallengeReward';
    claimedAt?: any | null;
    expiredAt: any;
    challenge: { __typename?: 'Challenge'; currency: Currency; amount: any };
  };
};

export type VipRewardsClaimMonthlyBonusMutationVariables = Exact<{
  currency: Currency;
}>;

export type VipRewardsClaimMonthlyBonusMutation = {
  __typename?: 'Mutation';
  vipRewardsClaimMonthlyBonus: {
    __typename?: 'MonthlyBonusClaimAmount';
    claimMonth: string;
    claimedAmount: { __typename?: 'CurrencyAmount'; currency: Currency; amount: any };
  };
};

export type ClaimCommissionsMutationVariables = Exact<{ [key: string]: never }>;

export type ClaimCommissionsMutation = {
  __typename?: 'Mutation';
  claimCommissions: Array<{ __typename?: 'CurrencyAmount'; amount: any; currency: Currency }>;
};

export type ClaimDailyRakebackMutationVariables = Exact<{ [key: string]: never }>;

export type ClaimDailyRakebackMutation = {
  __typename?: 'Mutation';
  vipRewardsClaimDailyRakeback: {
    __typename?: 'DailyRakebackAmount';
    claimDate: any;
    currencyAmounts: Array<{ __typename?: 'CurrencyAmount'; amount: any; currency: Currency }>;
  };
};

export type ClaimUserRewardMutationVariables = Exact<{
  raceId: Scalars['String'];
}>;

export type ClaimUserRewardMutation = {
  __typename?: 'Mutation';
  raceClaimUserReward: {
    __typename?: 'RaceRewardPayload';
    currency: Currency;
    wagered: any;
    amount: any;
    rank: number;
    rewardStartDate: any;
    rewardEndDate: any;
  };
};

export type ClaimVipUpgradeBonusMutationVariables = Exact<{
  currency: Currency;
  vipLevel: VipLevel;
}>;

export type ClaimVipUpgradeBonusMutation = {
  __typename?: 'Mutation';
  claimVipUpgradeBonus: {
    __typename?: 'VipUpgradeBonusClaim';
    vipUserUpgradeId: string;
    currency: Currency;
    amount: any;
  };
};

export type VipRewardsClaimWeeklyBonusMutationVariables = Exact<{
  currency: Currency;
}>;

export type VipRewardsClaimWeeklyBonusMutation = {
  __typename?: 'Mutation';
  vipRewardsClaimWeeklyBonus: {
    __typename?: 'WeeklyBonusClaimAmount';
    claimDate: any;
    claimedAmount: { __typename?: 'CurrencyAmount'; currency: Currency; amount: any };
  };
};

export type CampaignCreateMutationVariables = Exact<{
  data: CampaignCreateInput;
}>;

export type CampaignCreateMutation = {
  __typename?: 'Mutation';
  campaignCreate: {
    __typename?: 'Campaign';
    id: string;
    name: string;
    code: string;
    refereeCount: number;
    rate: any;
    refereeWagered: Array<{ __typename?: 'CurrencyAmount'; amount: any; currency: Currency }>;
    commissionTotals: Array<{ __typename?: 'CurrencyAmount'; amount: any; currency: Currency }>;
  };
};

export type GameCreateSessionMutationVariables = Exact<{
  clientType: ClientType;
  cryptoCurrency: Currency;
  demoMode: Scalars['Boolean'];
  fiatCurrency: FiatCurrency;
  gameSlug: Scalars['String'];
  locale: Scalars['String'];
}>;

export type GameCreateSessionMutation = {
  __typename?: 'Mutation';
  gameCreateSession: { __typename?: 'StartGamePayload'; gameUrl?: string | null; strategy: string };
};

export type HiloCashoutMutationVariables = Exact<{
  betId?: InputMaybe<Scalars['String']>;
}>;

export type HiloCashoutMutation = {
  __typename?: 'Mutation';
  hiloCashout?: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    payout?: any | null;
    currency: Currency;
    afterBalance?: any | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        hilo?: {
          __typename?: 'HiloActionModel';
          card?: number | null;
          guess?: HiloGuess | null;
          winMultiplier?: any | null;
          actionType: HiloActionType;
        } | null;
      };
    }> | null;
  } | null;
};

export type HiloStartMutationVariables = Exact<{
  data: HiloStartInput;
}>;

export type HiloStartMutation = {
  __typename?: 'Mutation';
  hiloStart: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    payout?: any | null;
    currency: Currency;
    afterBalance?: any | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        hilo?: {
          __typename?: 'HiloActionModel';
          card?: number | null;
          guess?: HiloGuess | null;
          winMultiplier?: any | null;
          actionType: HiloActionType;
        } | null;
      };
    }> | null;
  };
};

export type CrashCashoutMutationVariables = Exact<{
  crashGameId?: InputMaybe<Scalars['Int']>;
}>;

export type CrashCashoutMutation = { __typename?: 'Mutation'; crashCashout: boolean };

export type VipBonusClaimBonusMutationVariables = Exact<{
  data: ClaimVipBonusInput;
}>;

export type VipBonusClaimBonusMutation = {
  __typename?: 'Mutation';
  vipBonusClaimBonus: { __typename?: 'VipBonusClaim'; id: string };
};

export type CrashPlayMutationVariables = Exact<{
  data: CrashPlayInput;
}>;

export type CrashPlayMutation = {
  __typename?: 'Mutation';
  crashPlay: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    payout?: any | null;
    currency: Currency;
    jackpot: any;
    gameSeedNonce?: number | null;
    afterBalance?: any | null;
    game?: { __typename?: 'Game'; id: string; name: string; slug: string; edge: any } | null;
    crashBet?: { __typename?: 'CrashBet'; crashGameId?: number | null } | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      updatedAt: any;
      createdAt: any;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        crash?: {
          __typename?: 'CrashActionModel';
          betAt: any;
          payout?: any | null;
          multiplier?: any | null;
          payoutType?: PayoutType | null;
        } | null;
      };
    }> | null;
  };
};

export type DicePlayMutationVariables = Exact<{
  data: DicePlayInput;
}>;

export type DicePlayMutation = {
  __typename?: 'Mutation';
  dicePlay: {
    __typename?: 'Bet';
    id: string;
    currency: Currency;
    amount: any;
    payout?: any | null;
    afterBalance?: any | null;
    updatedAt: any;
    createdAt: any;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        dice?: {
          __typename?: 'DiceActionModel';
          userDiceDirection?: string | null;
          userValue: any;
          resultValue: any;
          resultRaw: string;
        } | null;
      };
    }> | null;
  };
};

export type KenoPlayMutationVariables = Exact<{
  data: KenoPlayInput;
}>;

export type KenoPlayMutation = {
  __typename?: 'Mutation';
  kenoPlay: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    currency: Currency;
    jackpot: any;
    payout?: any | null;
    afterBalance?: any | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        keno?: {
          __typename?: 'KenoActionModel';
          results: Array<number>;
          risk: KenoRiskLevel;
          multiplier: any;
          selected: Array<number>;
        } | null;
      };
    }> | null;
  };
};

export type LimboPlayMutationVariables = Exact<{
  data: LimboPlayInput;
}>;

export type LimboPlayMutation = {
  __typename?: 'Mutation';
  limboPlay: {
    __typename?: 'Bet';
    id: string;
    currency: Currency;
    amount: any;
    payout?: any | null;
    afterBalance?: any | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        limbo?: {
          __typename?: 'LimboActionModel';
          resultRaw: string;
          resultValue: any;
          userValue: any;
        } | null;
      };
    }> | null;
  };
};

export type LoginMutationVariables = Exact<{
  loginToken: Scalars['String'];
  tfaCode: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: { __typename?: 'LoginPayload'; accessToken: string; refreshToken: string };
};

export type HiloNextMutationVariables = Exact<{
  data: HiloNextInput;
}>;

export type HiloNextMutation = {
  __typename?: 'Mutation';
  hiloNext: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    payout?: any | null;
    currency: Currency;
    afterBalance?: any | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        hilo?: {
          __typename?: 'HiloActionModel';
          card?: number | null;
          guess?: HiloGuess | null;
          winMultiplier?: any | null;
          actionType: HiloActionType;
        } | null;
      };
    }> | null;
  };
};

export type LoginRequestMutationVariables = Exact<{
  identity: Scalars['String'];
  password: Scalars['String'];
  geetest?: InputMaybe<GeetestInput>;
}>;

export type LoginRequestMutation = {
  __typename?: 'Mutation';
  loginRequest: {
    __typename?: 'LoginRequestPayload';
    loginToken: string;
    otpSentAt?: any | null;
    otpEmail?: string | null;
  };
};

export type LoginRegisterRequestWithOauthMutationVariables = Exact<{
  provider: OauthProvider;
  code: Scalars['String'];
}>;

export type LoginRegisterRequestWithOauthMutation = {
  __typename?: 'Mutation';
  loginRegisterRequestWithOauth: {
    __typename?: 'LoginRegisterRequestWithOauthPayload';
    oauthToken?: string | null;
    login?: { __typename?: 'LoginPayload'; accessToken: string; refreshToken: string } | null;
  };
};

export type LoginResendRequestMutationVariables = Exact<{
  loginToken: Scalars['String'];
}>;

export type LoginResendRequestMutation = {
  __typename?: 'Mutation';
  loginResendRequest: {
    __typename?: 'LoginRequestPayload';
    loginToken: string;
    otpSentAt?: any | null;
    otpEmail?: string | null;
  };
};

export type LogoutOtherSessionsMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutOtherSessionsMutation = { __typename?: 'Mutation'; logoutOtherSessions: boolean };

export type MinesAutoBetMutationVariables = Exact<{
  data: MinesBetInput;
}>;

export type MinesAutoBetMutation = {
  __typename?: 'Mutation';
  minesAuto: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    payout?: any | null;
    currency: Currency;
    multiplier?: number | null;
    gameSeedNonce?: number | null;
    afterBalance?: any | null;
    game?: { __typename?: 'Game'; id: string; name: string; slug: string; edge: any } | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      updatedAt: any;
      createdAt: any;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        mines?: {
          __typename?: 'MinesActionModel';
          minesResult?: Array<number> | null;
          minesCount: number;
          winMultiplier?: any | null;
          selected?: Array<number> | null;
        } | null;
      };
    }> | null;
  };
};

export type MinesStartMutationVariables = Exact<{
  data: MinesStartInput;
}>;

export type MinesStartMutation = {
  __typename?: 'Mutation';
  minesStart: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    payout?: any | null;
    currency: Currency;
    multiplier?: number | null;
    gameSeedNonce?: number | null;
    afterBalance?: any | null;
    game?: { __typename?: 'Game'; id: string; name: string; slug: string; edge: any } | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      updatedAt: any;
      createdAt: any;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        mines?: {
          __typename?: 'MinesActionModel';
          minesResult?: Array<number> | null;
          minesCount: number;
          winMultiplier?: any | null;
          selected?: Array<number> | null;
        } | null;
      };
    }> | null;
  };
};

export type MinesNextMutationVariables = Exact<{
  data: MinesNextInput;
}>;

export type MinesNextMutation = {
  __typename?: 'Mutation';
  minesNext: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    payout?: any | null;
    currency: Currency;
    multiplier?: number | null;
    gameSeedNonce?: number | null;
    afterBalance?: any | null;
    game?: { __typename?: 'Game'; id: string; name: string; slug: string; edge: any } | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      updatedAt: any;
      createdAt: any;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        mines?: {
          __typename?: 'MinesActionModel';
          minesResult?: Array<number> | null;
          minesCount: number;
          winMultiplier?: any | null;
          selected?: Array<number> | null;
        } | null;
      };
    }> | null;
  };
};

export type MinesCashoutMutationVariables = Exact<{ [key: string]: never }>;

export type MinesCashoutMutation = {
  __typename?: 'Mutation';
  minesCashout?: {
    __typename?: 'Bet';
    id: string;
    amount: any;
    payout?: any | null;
    currency: Currency;
    multiplier?: number | null;
    gameSeedNonce?: number | null;
    afterBalance?: any | null;
    game?: { __typename?: 'Game'; id: string; name: string; slug: string; edge: any } | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      updatedAt: any;
      createdAt: any;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        mines?: {
          __typename?: 'MinesActionModel';
          minesResult?: Array<number> | null;
          minesCount: number;
          winMultiplier?: any | null;
          selected?: Array<number> | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type LinkWithOauthMutationVariables = Exact<{
  provider: OauthProvider;
  code: Scalars['String'];
}>;

export type LinkWithOauthMutation = {
  __typename?: 'Mutation';
  linkWithOauth: {
    __typename?: 'User';
    id?: string | null;
    linkedOauthProviders: Array<OauthProvider>;
  };
};

export type PasswordSendResetLinkMutationVariables = Exact<{
  email: Scalars['String'];
  geetest?: InputMaybe<GeetestInput>;
}>;

export type PasswordSendResetLinkMutation = {
  __typename?: 'Mutation';
  passwordSendResetLink: boolean;
};

export type OauthRequestMutationVariables = Exact<{
  provider: OauthProvider;
  state?: InputMaybe<Scalars['String']>;
}>;

export type OauthRequestMutation = {
  __typename?: 'Mutation';
  oauthRequest: { __typename?: 'OauthRequestPayload'; loginUrl: string };
};

export type OauthUnlinkMutationVariables = Exact<{
  provider: OauthProvider;
}>;

export type OauthUnlinkMutation = {
  __typename?: 'Mutation';
  unlinkWithOauth: {
    __typename?: 'User';
    id?: string | null;
    linkedOauthProviders: Array<OauthProvider>;
  };
};

export type LogoutMutationVariables = Exact<{
  sessionId?: InputMaybe<Scalars['String']>;
}>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type PasswordSendResetLinkLoggedInMutationVariables = Exact<{ [key: string]: never }>;

export type PasswordSendResetLinkLoggedInMutation = {
  __typename?: 'Mutation';
  passwordSendResetLinkLoggedIn: boolean;
};

export type PlaceSportsBetMutationVariables = Exact<{
  data: PlaceSportsBetInput;
}>;

export type PlaceSportsBetMutation = {
  __typename?: 'Mutation';
  placeSportsBet: {
    __typename?: 'SportsBet';
    id: string;
    currency: Currency;
    amount: any;
    totalOddsDecimal: any;
    status: SportsBetStatus;
    actualOddsDecimal: any;
    updatedAt: any;
    createdAt: any;
    selections: Array<{
      __typename?: 'SportsBetSelection';
      id: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsBetSelectionStatus;
      marketSelection: {
        __typename?: 'SportsMarketSelection';
        id: string;
        status: SportsMarketSelectionStatus;
        oddsNumerator: any;
        oddsDenominator: any;
        name: string;
        market: {
          __typename?: 'SportsMarket';
          id: string;
          typeName: string;
          typeId: string;
          name: string;
          status: SportsMarketStatus;
          disabled: boolean;
          expiryTime: any;
          cashoutStatus?: string | null;
          cashoutEnabled: boolean;
          inPlay: boolean;
          fixture: {
            __typename?: 'SportsFixture';
            competitors: Array<{
              __typename?: 'SportsCompetitorModel';
              geniusId: string;
              name: string;
              shortName?: string | null;
              isHome: boolean;
            }>;
          };
        };
      };
      snapshot: {
        __typename?: 'SportsBetSelectionSnapshot';
        marketId: string;
        marketName: string;
        marketTypeName: string;
        marketExpiryTime: any;
        fixtureId: string;
        fixtureSlug: string;
        fixtureName: string;
        fixtureStartTime: any;
        fixtureStatus: SportsFixtureStatus;
        competitionId: string;
        competitionSlug: string;
        competitionName: string;
        categoryId: string;
        categorySlug: string;
        categoryName: string;
        sports: Sports;
      };
    }>;
  };
};

export type RequestRainOtpMutationVariables = Exact<{
  data: TipRainOtpInput;
}>;

export type RequestRainOtpMutation = {
  __typename?: 'Mutation';
  requestTipRainSendOtp: { __typename?: 'TipRequestOtpPayload'; otpSentAt?: any | null };
};

export type SendRainMutationVariables = Exact<{
  data: TipRainInput;
}>;

export type SendRainMutation = {
  __typename?: 'Mutation';
  tipRain: {
    __typename?: 'TipRain';
    id: string;
    currency: Currency;
    amount: any;
    chatRoom: ChatRoom;
    createdAt: any;
  };
};

export type RedeemPromoCodeMutationVariables = Exact<{
  data: PromotionCodeInput;
}>;

export type RedeemPromoCodeMutation = {
  __typename?: 'Mutation';
  redeemPromotionCode: {
    __typename?: 'PromotionRedemption';
    id: string;
    currency: Currency;
    createdAt: any;
    afterBalance?: any | null;
    usdRedeemValue: any;
  };
};

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken: { __typename?: 'RefreshTokenPayload'; accessToken: string; refreshToken: string };
};

export type RegisterAccountMutationVariables = Exact<{
  data: RegisterInput;
}>;

export type RegisterAccountMutation = {
  __typename?: 'Mutation';
  register: {
    __typename?: 'RegisterPayload';
    accessToken: string;
    refreshToken: string;
    id: string;
    createdAt: any;
  };
};

export type RegisterWithOauthMutationVariables = Exact<{
  data: RegisterWithOauthInput;
}>;

export type RegisterWithOauthMutation = {
  __typename?: 'Mutation';
  registerWithOauth: {
    __typename?: 'RegisterPayload';
    id: string;
    accessToken: string;
    refreshToken: string;
    createdAt: any;
  };
};

export type RemoveFavouriteGameMutationVariables = Exact<{
  gameId: Scalars['String'];
}>;

export type RemoveFavouriteGameMutation = { __typename?: 'Mutation'; removeFavouriteGame: boolean };

export type ClaimRakebacksMutationVariables = Exact<{ [key: string]: never }>;

export type ClaimRakebacksMutation = {
  __typename?: 'Mutation';
  instantRakebackClaim: Array<{ __typename?: 'CurrencyAmount'; currency: Currency; amount: any }>;
};

export type RequestWithdrawTwoFaMutationVariables = Exact<{
  data: WithdrawalInput;
}>;

export type RequestWithdrawTwoFaMutation = {
  __typename?: 'Mutation';
  withdrawalRequestOtp: { __typename?: 'WithdrawalRequestOtpPayload'; otpSentAt?: any | null };
};

export type ResendVerificationMutationVariables = Exact<{ [key: string]: never }>;

export type ResendVerificationMutation = {
  __typename?: 'Mutation';
  resendEmailVerification: {
    __typename?: 'ResendVerificationEmailPayload';
    id: string;
    emailVerificationSentAt?: any | null;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  data: PasswordResetInput;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation'; passwordReset: boolean };

export type PlinkoPlayMutationVariables = Exact<{
  data: PlinkoPlayInput;
}>;

export type PlinkoPlayMutation = {
  __typename?: 'Mutation';
  plinkoPlay: {
    __typename?: 'Bet';
    id: string;
    currency: Currency;
    amount: any;
    payout?: any | null;
    afterBalance?: any | null;
    shuffleOriginalActions?: Array<{
      __typename?: 'ShuffleOriginalAction';
      id: string;
      updatedAt: any;
      createdAt: any;
      action: {
        __typename?: 'ShuffleOriginalActionModel';
        plinko?: {
          __typename?: 'PlinkoActionModel';
          risk: PlinkoRiskLevel;
          results: Array<number>;
          rows: number;
          multiplier: any;
        } | null;
      };
    }> | null;
  };
};

export type SendKycLevel1MutationVariables = Exact<{
  data: KycLevel1UpdateInput;
}>;

export type SendKycLevel1Mutation = {
  __typename?: 'Mutation';
  kycLevel1Update: {
    __typename?: 'Kyc';
    id: string;
    firstName: string;
    lastName: string;
    country: string;
    birthday: any;
    city: string;
    address: string;
    postcode: string;
    occupation: string;
    level1Status: KycLevelStatus;
    level2Status: KycLevelStatus;
    level3Status: KycLevelStatus;
    canCompletePoa: boolean;
  };
};

export type SendKycLevel2MutationVariables = Exact<{
  identityFile: Scalars['Upload'];
  identitySecondaryFile: Scalars['Upload'];
}>;

export type SendKycLevel2Mutation = {
  __typename?: 'Mutation';
  kycLevel2Update: {
    __typename?: 'Kyc';
    id: string;
    firstName: string;
    lastName: string;
    country: string;
    birthday: any;
    city: string;
    address: string;
    postcode: string;
    occupation: string;
    level1Status: KycLevelStatus;
    level2Status: KycLevelStatus;
    level3Status: KycLevelStatus;
    canCompletePoa: boolean;
  };
};

export type SendKycLevel3MutationVariables = Exact<{
  documentType: KycPoaType;
  addressFile: Scalars['Upload'];
}>;

export type SendKycLevel3Mutation = {
  __typename?: 'Mutation';
  kycLevel3Update: {
    __typename?: 'Kyc';
    id: string;
    firstName: string;
    lastName: string;
    country: string;
    birthday: any;
    city: string;
    address: string;
    postcode: string;
    occupation: string;
    level1Status: KycLevelStatus;
    level2Status: KycLevelStatus;
    level3Status: KycLevelStatus;
    canCompletePoa: boolean;
  };
};

export type RequestTipOtpMutationVariables = Exact<{
  data: TipSendInput;
}>;

export type RequestTipOtpMutation = {
  __typename?: 'Mutation';
  requestTipSendOtp: { __typename?: 'TipRequestOtpPayload'; otpSentAt?: any | null };
};

export type SendTipMutationVariables = Exact<{
  data: TipSendInput;
}>;

export type SendTipMutation = {
  __typename?: 'Mutation';
  tipSend: {
    __typename?: 'Tip';
    id: string;
    currency: Currency;
    amount: any;
    chatRoom?: ChatRoom | null;
    createdAt: any;
  };
};

export type UpdateRolloverProgressMutationVariables = Exact<{ [key: string]: never }>;

export type UpdateRolloverProgressMutation = {
  __typename?: 'Mutation';
  updateRolloverProgresses: Array<{
    __typename?: 'RolloverProgress';
    progress: any;
    currency: Currency;
    unfinishedBets?: Array<{
      __typename?: 'Bet';
      fiatCurrency?: FiatCurrency | null;
      game?: {
        __typename?: 'Game';
        name: string;
        slug: string;
        originalGame?: OriginalGame | null;
      } | null;
    }> | null;
  }>;
};

export type VaultDepositMutationVariables = Exact<{
  data: VaultDepositInput;
}>;

export type VaultDepositMutation = {
  __typename?: 'Mutation';
  vaultDeposit: {
    __typename?: 'Vault';
    id: string;
    type: VaultType;
    currency: Currency;
    amount: any;
    createdAt: any;
  };
};

export type VaultWithdrawMutationVariables = Exact<{
  data: VaultWithdrawInput;
}>;

export type VaultWithdrawMutation = {
  __typename?: 'Mutation';
  vaultWithdraw: {
    __typename?: 'Vault';
    id: string;
    type: VaultType;
    currency: Currency;
    amount: any;
    createdAt: any;
  };
};

export type VerifyEmailMutationVariables = Exact<{
  emailVerificationToken: Scalars['String'];
}>;

export type VerifyEmailMutation = {
  __typename?: 'Mutation';
  verifyEmail: { __typename?: 'VerifyEmailPayload'; id: string; emailVerifiedAt: any };
};

export type WithdrawMutationVariables = Exact<{
  data: WithdrawalInput;
}>;

export type WithdrawMutation = {
  __typename?: 'Mutation';
  withdraw: {
    __typename?: 'Withdrawal';
    amount: any;
    receiverAddress: string;
    fee: any;
    chainCurrency: { __typename?: 'ChainCurrency'; currency: Currency; chain: Chain };
  };
};

export type SportBetCashOutMutationVariables = Exact<{
  data: CashoutSportsBetInput;
}>;

export type SportBetCashOutMutation = {
  __typename?: 'Mutation';
  cashoutSportsBet: {
    __typename?: 'SportsBet';
    id: string;
    currency: Currency;
    amount: any;
    totalOddsDecimal: any;
    status: SportsBetStatus;
    createdAt: any;
    selections: Array<{
      __typename?: 'SportsBetSelection';
      id: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsBetSelectionStatus;
      snapshot: {
        __typename?: 'SportsBetSelectionSnapshot';
        marketId: string;
        marketName: string;
        marketTypeName: string;
        marketExpiryTime: any;
        fixtureId: string;
        fixtureSlug: string;
        fixtureName: string;
        fixtureStartTime: any;
        fixtureStatus: SportsFixtureStatus;
        competitionId: string;
        competitionSlug: string;
        competitionName: string;
        categoryId: string;
        categorySlug: string;
        categoryName: string;
        sports: Sports;
      };
    }>;
  };
};

export type UpdatePreferencesMutationVariables = Exact<{
  data: UpdateUserPreferenceInput;
}>;

export type UpdatePreferencesMutation = {
  __typename?: 'Mutation';
  userUpdatePreference: {
    __typename?: 'User';
    id?: string | null;
    anonymous: boolean;
    avatar: number;
    avatarBackground: number;
  };
};

export type BalanceUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type BalanceUpdatedSubscription = {
  __typename?: 'Subscription';
  balanceUpdated: { __typename?: 'CurrencyAmount'; currency: Currency; amount: any };
};

export type CrashGameBetPayoutSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CrashGameBetPayoutSubscription = {
  __typename?: 'Subscription';
  crashBetPayoutEvent: {
    __typename?: 'CrashBetPayoutPayload';
    betId: string;
    currency: Currency;
    amount: any;
    betAt: any;
    payout?: any | null;
    multiplier?: any | null;
    payoutType?: PayoutType | null;
    user?: { __typename?: 'User'; username?: string | null; vipLevel: VipLevel } | null;
  };
};

export type CrashGameMyPayoutSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CrashGameMyPayoutSubscription = {
  __typename?: 'Subscription';
  myCrashBetPayoutEvent: {
    __typename?: 'CrashBetPayoutPayload';
    betId: string;
    currency: Currency;
    amount: any;
    betAt: any;
    payout?: any | null;
    multiplier?: any | null;
    payoutType?: PayoutType | null;
    user?: { __typename?: 'User'; username?: string | null; vipLevel: VipLevel } | null;
  };
};

export type CrashGameUpdateSubscriptionVariables = Exact<{ [key: string]: never }>;

export type CrashGameUpdateSubscription = {
  __typename?: 'Subscription';
  crashEventUpdate: {
    __typename?: 'CrashEventPayload';
    status: CrashStatus;
    elapsed?: number | null;
    currentPoint?: any | null;
    crashPoint?: any | null;
    startedAt?: number | null;
    nextRoundIn?: number | null;
  };
};

export type DepositPendingSubscriptionVariables = Exact<{ [key: string]: never }>;

export type DepositPendingSubscription = {
  __typename?: 'Subscription';
  depositPending: {
    __typename?: 'DepositNotificationPayload';
    depositId: string;
    currency: Currency;
    amount: any;
  };
};

export type DepositCompletedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type DepositCompletedSubscription = {
  __typename?: 'Subscription';
  depositCompleted: {
    __typename?: 'DepositNotificationPayload';
    depositId: string;
    currency: Currency;
    amount: any;
  };
};

export type EmailVerifiedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type EmailVerifiedSubscription = {
  __typename?: 'Subscription';
  emailVerified: { __typename?: 'VerifyEmailPayload'; id: string; emailVerifiedAt: any };
};

export type GameResultSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GameResultSubscription = {
  __typename?: 'Subscription';
  gameResult: { __typename?: 'GameResultPayload'; currency: Currency; betAmount: any; payout: any };
};

export type HighRollerBetUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type HighRollerBetUpdatedSubscription = {
  __typename?: 'Subscription';
  highRollerBetUpdated: {
    __typename?: 'BetActivityPayload';
    id: string;
    username?: string | null;
    vipLevel: VipLevel;
    currency: Currency;
    amount: any;
    payout: any;
    multiplier: string;
    gameName: string;
    gameCategory?: string | null;
    gameSlug: string;
  };
};

export type HighRollerSportsBetUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type HighRollerSportsBetUpdatedSubscription = {
  __typename?: 'Subscription';
  highRollerSportsBetUpdated: {
    __typename?: 'SportsBetActivity';
    id: string;
    vipLevel: VipLevel;
    username?: string | null;
    currency: Currency;
    amount: any;
    categoryName: string;
    categorySlug: string;
    competitionName: string;
    competitionSlug: string;
    selectionNumber: number;
    fixtureName: string;
    sports: Sports;
    fixtureSlug: string;
    oddsDecimal: any;
  };
};

export type LatestSportsBetUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type LatestSportsBetUpdatedSubscription = {
  __typename?: 'Subscription';
  latestSportsBetUpdated: {
    __typename?: 'SportsBetActivity';
    id: string;
    vipLevel: VipLevel;
    username?: string | null;
    currency: Currency;
    amount: any;
    categoryName: string;
    categorySlug: string;
    competitionName: string;
    competitionSlug: string;
    selectionNumber: number;
    fixtureName: string;
    sports: Sports;
    fixtureSlug: string;
    oddsDecimal: any;
  };
};

export type LatestBetUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type LatestBetUpdatedSubscription = {
  __typename?: 'Subscription';
  latestBetUpdated: {
    __typename?: 'BetActivityPayload';
    id: string;
    username?: string | null;
    vipLevel: VipLevel;
    currency: Currency;
    amount: any;
    payout: any;
    multiplier: string;
    gameName: string;
    gameCategory?: string | null;
    gameSlug: string;
  };
};

export type RaceLeaderboardUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type RaceLeaderboardUpdatedSubscription = {
  __typename?: 'Subscription';
  raceLeaderboardUpdated: {
    __typename?: 'RaceLeaderBoardPayload';
    leaderboard: Array<{
      __typename?: 'RaceRank';
      userId?: string | null;
      username?: string | null;
      raceId: string;
      raceName: string;
      currency: Currency;
      wagered: any;
      rank: number;
      vipLevel?: VipLevel | null;
      prize: any;
    }>;
  };
};

export type RaceWagerUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type RaceWagerUpdatedSubscription = {
  __typename?: 'Subscription';
  raceWagerUpdated: Array<{
    __typename?: 'RaceWagerPayload';
    vipLevel?: VipLevel | null;
    username?: string | null;
    wagered: any;
    raceId: string;
    raceEntryId: string;
  }>;
};

export type MySportsBetUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type MySportsBetUpdatedSubscription = {
  __typename?: 'Subscription';
  mySportsBetUpdated: {
    __typename?: 'SportsBet';
    id: string;
    currency: Currency;
    amount: any;
    totalOddsDecimal: any;
    actualOddsDecimal: any;
    status: SportsBetStatus;
    cashoutAvailable: {
      __typename?: 'SportsBetCashoutAvailable';
      reason?: string | null;
      canCashout: boolean;
    };
    selections: Array<{
      __typename?: 'SportsBetSelection';
      id: string;
      oddsNumerator: any;
      oddsDenominator: any;
      status: SportsBetSelectionStatus;
      marketSelection: {
        __typename?: 'SportsMarketSelection';
        id: string;
        status: SportsMarketSelectionStatus;
        oddsNumerator: any;
        oddsDenominator: any;
        name: string;
        market: {
          __typename?: 'SportsMarket';
          id: string;
          typeName: string;
          typeId: string;
          name: string;
          status: SportsMarketStatus;
          disabled: boolean;
          expiryTime: any;
          cashoutStatus?: string | null;
          cashoutEnabled: boolean;
          inPlay: boolean;
          fixture: {
            __typename?: 'SportsFixture';
            competitors: Array<{
              __typename?: 'SportsCompetitorModel';
              geniusId: string;
              name: string;
              shortName?: string | null;
              isHome: boolean;
            }>;
          };
        };
      };
      snapshot: {
        __typename?: 'SportsBetSelectionSnapshot';
        marketId: string;
        marketName: string;
        marketTypeName: string;
        marketExpiryTime: any;
        fixtureId: string;
        fixtureSlug: string;
        fixtureName: string;
        fixtureStartTime: any;
        fixtureStatus: SportsFixtureStatus;
        competitionId: string;
        competitionSlug: string;
        competitionName: string;
        categoryId: string;
        categorySlug: string;
        categoryName: string;
        sports: Sports;
      };
    }>;
    settlement?: {
      __typename?: 'SportsBetSettlement';
      id: string;
      payout?: any | null;
      payoutOddsDecimal: any;
      createdAt: any;
    } | null;
  };
};

export type RewardUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type RewardUpdatedSubscription = {
  __typename?: 'Subscription';
  rewardUpdated: { __typename?: 'RewardPayload'; rewardEvent: RewardEvent };
};

export type MyBetUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type MyBetUpdatedSubscription = {
  __typename?: 'Subscription';
  myBetUpdated: {
    __typename?: 'Bet';
    id: string;
    currency: Currency;
    amount: any;
    payout?: any | null;
    multiplier?: number | null;
    game?: {
      __typename?: 'Game';
      id: string;
      name: string;
      slug: string;
      gameAndGameCategories?: Array<{
        __typename?: 'GameAndGameCategory';
        gameCategoryName: string;
        gameId: string;
        main: boolean;
      }> | null;
    } | null;
  };
};

export type SportsCategoryUpdatedSubscriptionVariables = Exact<{
  categoryIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type SportsCategoryUpdatedSubscription = {
  __typename?: 'Subscription';
  sportsCategoryUpdated: {
    __typename?: 'SportsCategoryUpdatedPayload';
    id: string;
    disabled: boolean;
  };
};

export type SportsFixtureUpdatedSubscriptionVariables = Exact<{
  fixtureIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type SportsFixtureUpdatedSubscription = {
  __typename?: 'Subscription';
  sportsFixtureUpdated: {
    __typename?: 'SportsFixtureUpdatedPayload';
    id: string;
    disabled: boolean;
    fixtureStatus: SportsFixtureStatus;
  };
};

export type TipReceivedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TipReceivedSubscription = {
  __typename?: 'Subscription';
  tipReceived: {
    __typename?: 'TipReceivedPayload';
    senderUsername: string;
    tipType: TipType;
    currency: Currency;
    amount: any;
  };
};

export type SportsMarketSelectionUpdatedSubscriptionVariables = Exact<{
  marketIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  fixtureIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type SportsMarketSelectionUpdatedSubscription = {
  __typename?: 'Subscription';
  sportsMarketSelectionUpdated: {
    __typename?: 'SportsMarketSelectionUpdatedPayload';
    id: string;
    fixtureId: string;
    marketId: string;
    oddsNumerator: any;
    oddsDenominator: any;
  };
};

export type SportsCompetitionUpdatedSubscriptionVariables = Exact<{
  competitionIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type SportsCompetitionUpdatedSubscription = {
  __typename?: 'Subscription';
  sportsCompetitionUpdated: {
    __typename?: 'SportsCompetitionUpdatedPayload';
    id: string;
    disabled: boolean;
  };
};

export type VipLevelSubscriptionVariables = Exact<{ [key: string]: never }>;

export type VipLevelSubscription = {
  __typename?: 'Subscription';
  vipLevelUpdated: {
    __typename?: 'VipLevelUpdatedPayload';
    vipLevel?: VipLevel | null;
    xp: number;
    wagered?: any | null;
  };
};

export type VipRewardsUpdatedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type VipRewardsUpdatedSubscription = {
  __typename?: 'Subscription';
  vipRewardsUpdated: boolean;
};

export type WithdrawalCompletedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type WithdrawalCompletedSubscription = {
  __typename?: 'Subscription';
  withdrawalCompleted: {
    __typename?: 'WithdrawalCompletedPayload';
    withdrawalId: string;
    currency: Currency;
    amount: any;
  };
};

export type SportsMatchSummaryUpdatedSubscriptionVariables = Exact<{
  fixtureIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type SportsMatchSummaryUpdatedSubscription = {
  __typename?: 'Subscription';
  sportsMatchSummaryUpdated: {
    __typename?: 'SportsMatchSummaryUpdatedPayload';
    id: string;
    fixtureId: string;
    matchPhase?: SportsMatchPhase | null;
    currentPhaseName?: string | null;
    homeScore?: number | null;
    awayScore?: number | null;
    server?: string | null;
    timeRemaining?: number | null;
    timeRemainingAt?: any | null;
    phases?: Array<{
      __typename?: 'SportsMatchPhaseSummary';
      phase: number;
      homeScore?: number | null;
      awayScore?: number | null;
      status?: SportsMatchPhaseStatus | null;
    }> | null;
  };
};

export type SportsMarketUpdatedSubscriptionVariables = Exact<{
  marketIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  fixtureIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type SportsMarketUpdatedSubscription = {
  __typename?: 'Subscription';
  sportsMarketUpdated: {
    __typename?: 'SportsMarketUpdatedPayload';
    id: string;
    fixtureId: string;
    inPlay: boolean;
    disabled: boolean;
    cashoutEnabled: boolean;
    cashoutStatus: string;
    marketStatus: SportsMarketStatus;
  };
};

export const CrashBetPayoutEventFieldsFragmentDoc = gql`
  fragment CrashBetPayoutEventFields on CrashBetPayoutPayload {
    betId
    currency
    amount
    betAt
    payout
    multiplier
    payoutType
    user {
      username
      vipLevel
    }
  }
`;
export const BlackjackBetFieldsFragmentDoc = gql`
  fragment BlackjackBetFields on Bet {
    id
    amount
    originalAmount
    payout
    multiplier
    currency
    afterBalance
    completedAt
    shuffleOriginalActions {
      id
      action {
        blackjack {
          mainPlayerHand
          mainPlayerActions
          splitPlayerHand
          splitPlayerActions
          dealerHand
          perfectPairWin
          twentyOnePlusThreeWin
          twentyOnePlusThreeAmount
          perfectPairAmount
          insuranceStatus
          originalMainBetAmount
          mainHandOutcome
          splitHandOutcome
        }
      }
    }
  }
`;
export const BetFieldsFragmentDoc = gql`
  fragment BetFields on Bet {
    id
    currency
    amount
    payout
    multiplier
    game {
      id
      name
      gameAndGameCategories {
        gameCategoryName
        gameId
        main
      }
      slug
    }
  }
`;
export const BetActivityFieldsFragmentDoc = gql`
  fragment BetActivityFields on BetActivityPayload {
    id
    username
    vipLevel
    currency
    amount
    payout
    multiplier
    gameName
    gameCategory
    gameSlug
  }
`;
export const CrashGameFieldsFragmentDoc = gql`
  fragment CrashGameFields on CrashGame {
    id
    seed
    crashPoint
    countOfPlayer
    countOfCashedOut
  }
`;
export const DiceFieldsFragmentDoc = gql`
  fragment DiceFields on DiceActionModel {
    userDiceDirection
    userValue
    resultValue
    resultRaw
  }
`;
export const GameSeedFieldsFragmentDoc = gql`
  fragment GameSeedFields on GameSeed {
    id
    clientSeed
    seed
    hashedSeed
    status
    currentNonce
    createdAt
  }
`;
export const MinesBetFieldsFragmentDoc = gql`
  fragment MinesBetFields on Bet {
    id
    amount
    payout
    currency
    multiplier
    game {
      id
      name
      slug
      edge
    }
    shuffleOriginalActions {
      id
      updatedAt
      createdAt
      action {
        mines {
          minesResult
          minesCount
          winMultiplier
          selected
        }
      }
    }
    gameSeedNonce
    afterBalance
  }
`;
export const SportsBetActivityFieldsFragmentDoc = gql`
  fragment SportsBetActivityFields on SportsBetActivity {
    id
    vipLevel
    username
    currency
    amount
    categoryName
    categorySlug
    competitionName
    competitionSlug
    selectionNumber
    fixtureName
    sports
    fixtureSlug
    oddsDecimal
  }
`;
export const HiloBetFieldsFragmentDoc = gql`
  fragment HiloBetFields on Bet {
    id
    amount
    payout
    currency
    afterBalance
    shuffleOriginalActions {
      id
      action {
        hilo {
          card
          guess
          winMultiplier
          actionType
        }
      }
    }
  }
`;
export const KycFieldsFragmentDoc = gql`
  fragment KycFields on Kyc {
    id
    firstName
    lastName
    country
    birthday
    city
    address
    postcode
    occupation
    level1Status
    level2Status
    level3Status
    canCompletePoa
  }
`;
export const CampaignFieldsFragmentDoc = gql`
  fragment CampaignFields on Campaign {
    id
    name
    code
    refereeCount
    rate
    refereeWagered {
      amount
      currency
    }
    commissionTotals {
      amount
      currency
    }
  }
`;
export const LeaderboardFieldsFragmentDoc = gql`
  fragment LeaderboardFields on RaceRank {
    userId
    username
    raceId
    raceName
    currency
    wagered
    rank
    vipLevel
    prize
  }
`;
export const SportsCompetitorBodyFragmentDoc = gql`
  fragment SportsCompetitorBody on SportsCompetitorModel {
    geniusId
    name
    shortName
    isHome
  }
`;
export const SportsMarketSelectionFieldsFragmentDoc = gql`
  fragment SportsMarketSelectionFields on SportsMarketSelection {
    id
    status
    oddsNumerator
    oddsDenominator
    name
    market {
      id
      typeName
      typeId
      name
      status
      disabled
      expiryTime
      cashoutStatus
      cashoutEnabled
      inPlay
      fixture {
        competitors {
          ...SportsCompetitorBody
        }
      }
    }
  }
  ${SportsCompetitorBodyFragmentDoc}
`;
export const SportBetSelectionSnapshotFieldsFragmentDoc = gql`
  fragment SportBetSelectionSnapshotFields on SportsBetSelectionSnapshot {
    marketId
    marketName
    marketTypeName
    marketExpiryTime
    fixtureId
    fixtureSlug
    fixtureName
    fixtureStartTime
    fixtureStatus
    competitionId
    competitionSlug
    competitionName
    categoryId
    categorySlug
    categoryName
    sports
  }
`;
export const SportBetFieldsFragmentDoc = gql`
  fragment SportBetFields on SportsBet {
    id
    currency
    amount
    totalOddsDecimal
    actualOddsDecimal
    status
    cashoutAvailable {
      reason
      canCashout
    }
    selections {
      id
      oddsNumerator
      oddsDenominator
      status
      marketSelection {
        ...SportsMarketSelectionFields
      }
      snapshot {
        ...SportBetSelectionSnapshotFields
      }
    }
    settlement {
      id
      payout
      payoutOddsDecimal
      createdAt
    }
  }
  ${SportsMarketSelectionFieldsFragmentDoc}
  ${SportBetSelectionSnapshotFieldsFragmentDoc}
`;
export const SportsCompetitionBodyFragmentDoc = gql`
  fragment SportsCompetitionBody on SportsCompetition {
    id
    name
    slug
    category {
      id
      slug
      name
      sports
    }
  }
`;
export const SportsMatchStateFieldsFragmentDoc = gql`
  fragment SportsMatchStateFields on SportsMatchState {
    id
    fixtureId
    updatedAt
    createdAt
    matchSummary {
      matchPhase
      currentPhaseName
      homeScore
      awayScore
      server
      phases {
        phase
        homeScore
        awayScore
        status
      }
      timeRemaining
      timeRemainingAt
    }
  }
`;
export const SportsFixtureBodyFragmentDoc = gql`
  fragment SportsFixtureBody on SportsFixture {
    id
    slug
    name
    startTime
    updatedAt
    createdAt
    competitors {
      ...SportsCompetitorBody
    }
    matchState {
      ...SportsMatchStateFields
    }
  }
  ${SportsCompetitorBodyFragmentDoc}
  ${SportsMatchStateFieldsFragmentDoc}
`;
export const SportMarketSelectionBodyFragmentDoc = gql`
  fragment SportMarketSelectionBody on SportsMarketSelection {
    id
    name
    oddsNumerator
    oddsDenominator
    status
  }
`;
export const SportsMarketBodyFragmentDoc = gql`
  fragment SportsMarketBody on SportsMarket {
    id
    name
    typeId
    typeName
    status
    inPlay
    expiryTime
    disabled
    status
    cashoutStatus
    cashoutEnabled
    selections {
      ...SportMarketSelectionBody
    }
  }
  ${SportMarketSelectionBodyFragmentDoc}
`;
export const SportsFixtureWithDefaultMarketsBodyFragmentDoc = gql`
  fragment SportsFixtureWithDefaultMarketsBody on SportsFixture {
    ...SportsFixtureBody
    markets(group: DEFAULT_MARKETS) {
      ...SportsMarketBody
    }
    allMarkets: markets {
      id
    }
  }
  ${SportsFixtureBodyFragmentDoc}
  ${SportsMarketBodyFragmentDoc}
`;
export const SportsFixtureMarketsGroupsBodyFragmentDoc = gql`
  fragment SportsFixtureMarketsGroupsBody on SportsFixture {
    DEFAULT_MARKETS: markets(group: DEFAULT_MARKETS) {
      ...SportsMarketBody
    }
    TOP_MARKETS: markets(group: TOP_MARKETS) {
      ...SportsMarketBody
    }
    FIRST_HALF_MARKETS: markets(group: FIRST_HALF_MARKETS) {
      ...SportsMarketBody
    }
    SECOND_HALF_MARKETS: markets(group: SECOND_HALF_MARKETS) {
      ...SportsMarketBody
    }
    FIRST_QUARTER_MARKETS: markets(group: FIRST_QUARTER_MARKETS) {
      ...SportsMarketBody
    }
    SECOND_QUARTER_MARKETS: markets(group: SECOND_QUARTER_MARKETS) {
      ...SportsMarketBody
    }
    THIRD_QUARTER_MARKETS: markets(group: THIRD_QUARTER_MARKETS) {
      ...SportsMarketBody
    }
    FOURTH_QUARTER_MARKETS: markets(group: FOURTH_QUARTER_MARKETS) {
      ...SportsMarketBody
    }
    FIRST_PERIOD_MARKETS: markets(group: FIRST_PERIOD_MARKETS) {
      ...SportsMarketBody
    }
    SECOND_PERIOD_MARKETS: markets(group: SECOND_PERIOD_MARKETS) {
      ...SportsMarketBody
    }
    THIRD_PERIOD_MARKETS: markets(group: THIRD_PERIOD_MARKETS) {
      ...SportsMarketBody
    }
    WIN_MARKETS: markets(group: WIN_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_MARKETS: markets(group: HANDICAP_MARKETS) {
      ...SportsMarketBody
    }
    TOTAL_MARKETS: markets(group: TOTAL_MARKETS) {
      ...SportsMarketBody
    }
    GOALSCORER_MARKETS: markets(group: GOALSCORER_MARKETS) {
      ...SportsMarketBody
    }
    CARDS_MARKETS: markets(group: CARDS_MARKETS) {
      ...SportsMarketBody
    }
    TEAM_TO_SCORE_MARKETS: markets(group: TEAM_TO_SCORE_MARKETS) {
      ...SportsMarketBody
    }
    CORRECT_SCORE_MARKETS: markets(group: CORRECT_SCORE_MARKETS) {
      ...SportsMarketBody
    }
    DOUBLE_CHANCE_MARKETS: markets(group: DOUBLE_CHANCE_MARKETS) {
      ...SportsMarketBody
    }
    CLEAN_SHEET_MARKETS: markets(group: CLEAN_SHEET_MARKETS) {
      ...SportsMarketBody
    }
    CORNERS_MARKETS: markets(group: CORNERS_MARKETS) {
      ...SportsMarketBody
    }
    PENALTY_MARKETS: markets(group: PENALTY_MARKETS) {
      ...SportsMarketBody
    }
    RACE_MARKETS: markets(group: RACE_MARKETS) {
      ...SportsMarketBody
    }
    PLAYER_H2H_MARKETS: markets(group: PLAYER_H2H_MARKETS) {
      ...SportsMarketBody
    }
    TIME_OF_SCORE_MARKETS: markets(group: TIME_OF_SCORE_MARKETS) {
      ...SportsMarketBody
    }
    TOUCHDOWN_MARKETS: markets(group: TOUCHDOWN_MARKETS) {
      ...SportsMarketBody
    }
    PLAYER_OVERALL_MARKETS: markets(group: PLAYER_OVERALL_MARKETS) {
      ...SportsMarketBody
    }
    WINNER_MARKETS: markets(group: WINNER_MARKETS) {
      ...SportsMarketBody
    }
    SET_BETTING_MARKETS: markets(group: SET_BETTING_MARKETS) {
      ...SportsMarketBody
    }
    GAMES_BETTING_MARKETS: markets(group: GAMES_BETTING_MARKETS) {
      ...SportsMarketBody
    }
    TIE_BREAK_MARKETS: markets(group: TIE_BREAK_MARKETS) {
      ...SportsMarketBody
    }
    MISCELLANEOUS_MARKETS: markets(group: MISCELLANEOUS_MARKETS) {
      ...SportsMarketBody
    }
    SCORE_MARKETS: markets(group: SCORE_MARKETS) {
      ...SportsMarketBody
    }
    FIRST_5_INNINGS_MARKETS: markets(group: FIRST_5_INNINGS_MARKETS) {
      ...SportsMarketBody
    }
    METHOD_MARKETS: markets(group: METHOD_MARKETS) {
      ...SportsMarketBody
    }
    GAME_1_MARKETS: markets(group: GAME_1_MARKETS) {
      ...SportsMarketBody
    }
    GAME_2_MARKETS: markets(group: GAME_2_MARKETS) {
      ...SportsMarketBody
    }
    GAME_3_MARKETS: markets(group: GAME_3_MARKETS) {
      ...SportsMarketBody
    }
    GAME_4_MARKETS: markets(group: GAME_4_MARKETS) {
      ...SportsMarketBody
    }
    GAME_5_MARKETS: markets(group: GAME_5_MARKETS) {
      ...SportsMarketBody
    }
    GAME_6_MARKETS: markets(group: GAME_6_MARKETS) {
      ...SportsMarketBody
    }
    GAME_7_MARKETS: markets(group: GAME_7_MARKETS) {
      ...SportsMarketBody
    }
    PLACE_MARKETS: markets(group: PLACE_MARKETS) {
      ...SportsMarketBody
    }
    MATCHUP_MARKETS: markets(group: MATCHUP_MARKETS) {
      ...SportsMarketBody
    }
    TRYSCORER_MARKETS: markets(group: TRYSCORER_MARKETS) {
      ...SportsMarketBody
    }
    FIRST_TO_MARKETS: markets(group: FIRST_TO_MARKETS) {
      ...SportsMarketBody
    }
    LAST_TO_MARKETS: markets(group: LAST_TO_MARKETS) {
      ...SportsMarketBody
    }
    OTHER_MARKETS: markets(group: OTHER_MARKETS) {
      ...SportsMarketBody
    }
    KILL_MARKETS: markets(group: KILL_MARKETS) {
      ...SportsMarketBody
    }
    ROSHAN_MARKETS: markets(group: ROSHAN_MARKETS) {
      ...SportsMarketBody
    }
    NEXT_TO_HAPPEN_MARKETS: markets(group: NEXT_TO_HAPPEN_MARKETS) {
      ...SportsMarketBody
    }
    TOWER_MARKETS: markets(group: TOWER_MARKETS) {
      ...SportsMarketBody
    }
    INHIBITOR_MARKETS: markets(group: INHIBITOR_MARKETS) {
      ...SportsMarketBody
    }
    DRAGON_MARKETS: markets(group: DRAGON_MARKETS) {
      ...SportsMarketBody
    }
    BARRACKS_MARKETS: markets(group: BARRACKS_MARKETS) {
      ...SportsMarketBody
    }
    MAP_1_MARKETS: markets(group: MAP_1_MARKETS) {
      ...SportsMarketBody
    }
    MAP_2_MARKETS: markets(group: MAP_2_MARKETS) {
      ...SportsMarketBody
    }
    MAP_3_MARKETS: markets(group: MAP_3_MARKETS) {
      ...SportsMarketBody
    }
    MAP_4_MARKETS: markets(group: MAP_4_MARKETS) {
      ...SportsMarketBody
    }
    MAP_5_MARKETS: markets(group: MAP_5_MARKETS) {
      ...SportsMarketBody
    }
    HALF_MARKETS: markets(group: HALF_MARKETS) {
      ...SportsMarketBody
    }
    BTTS_MARKETS: markets(group: BTTS_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_10_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_10_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_15_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_15_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_20_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_20_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_25_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_25_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_30_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_35_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_40_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_5_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_50_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_55_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_60_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_65_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_70_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_75_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_80_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_85_MINUTES_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_AFTER_EXTRA_TIME_INCLUDING_NORMAL_TIME_GOALS_MARKETS: markets(
      group: HANDICAP_AFTER_30_MINUTES_MARKETS
    ) {
      ...SportsMarketBody
    }
    HANDICAP_AT_EXTRA_TIME_HALF_TIME_INCLUDING_NORMAL_TIME_GOALS_MARKETS: markets(
      group: HANDICAP_AFTER_30_MINUTES_MARKETS
    ) {
      ...SportsMarketBody
    }
    HANDICAP_HALF_TIME_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
    HANDICAP_SECOND_HALF_MARKETS: markets(group: HANDICAP_AFTER_30_MINUTES_MARKETS) {
      ...SportsMarketBody
    }
  }
  ${SportsMarketBodyFragmentDoc}
`;
export const GameBodyFragmentDoc = gql`
  fragment GameBody on Game {
    id
    name
    externalId
    description
    gameAndGameCategories {
      gameCategoryName
      gameId
      main
    }
    provider {
      id
      slug
      name
      disabledFiatCurrencies
    }
    image {
      key
    }
    originalGame
    slug
    tags {
      name
    }
    releasedDate
    demoMode
    edge
    accentColor
  }
`;
export const GetAllSportsLeaguesDocument = gql`
  query GetAllSportsLeagues($sports: Sports!) {
    sportsCategories(sports: $sports) {
      id
      sports
      slug
      name
      competitions {
        nodes {
          id
          slug
          name
        }
        totalCount
      }
    }
  }
`;

/**
 * __useGetAllSportsLeaguesQuery__
 *
 * To run a query within a React component, call `useGetAllSportsLeaguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSportsLeaguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSportsLeaguesQuery({
 *   variables: {
 *      sports: // value for 'sports'
 *   },
 * });
 */
export function useGetAllSportsLeaguesQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllSportsLeaguesQuery, GetAllSportsLeaguesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllSportsLeaguesQuery, GetAllSportsLeaguesQueryVariables>(
    GetAllSportsLeaguesDocument,
    options,
  );
}
export function useGetAllSportsLeaguesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllSportsLeaguesQuery,
    GetAllSportsLeaguesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllSportsLeaguesQuery, GetAllSportsLeaguesQueryVariables>(
    GetAllSportsLeaguesDocument,
    options,
  );
}
export type GetAllSportsLeaguesQueryHookResult = ReturnType<typeof useGetAllSportsLeaguesQuery>;
export type GetAllSportsLeaguesLazyQueryHookResult = ReturnType<
  typeof useGetAllSportsLeaguesLazyQuery
>;
export type GetAllSportsLeaguesQueryResult = Apollo.QueryResult<
  GetAllSportsLeaguesQuery,
  GetAllSportsLeaguesQueryVariables
>;
export const BlackjackActiveBetDocument = gql`
  query BlackjackActiveBet {
    blackjackActiveBet {
      ...BlackjackBetFields
    }
  }
  ${BlackjackBetFieldsFragmentDoc}
`;

/**
 * __useBlackjackActiveBetQuery__
 *
 * To run a query within a React component, call `useBlackjackActiveBetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlackjackActiveBetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlackjackActiveBetQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlackjackActiveBetQuery(
  baseOptions?: Apollo.QueryHookOptions<BlackjackActiveBetQuery, BlackjackActiveBetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlackjackActiveBetQuery, BlackjackActiveBetQueryVariables>(
    BlackjackActiveBetDocument,
    options,
  );
}
export function useBlackjackActiveBetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlackjackActiveBetQuery,
    BlackjackActiveBetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlackjackActiveBetQuery, BlackjackActiveBetQueryVariables>(
    BlackjackActiveBetDocument,
    options,
  );
}
export type BlackjackActiveBetQueryHookResult = ReturnType<typeof useBlackjackActiveBetQuery>;
export type BlackjackActiveBetLazyQueryHookResult = ReturnType<
  typeof useBlackjackActiveBetLazyQuery
>;
export type BlackjackActiveBetQueryResult = Apollo.QueryResult<
  BlackjackActiveBetQuery,
  BlackjackActiveBetQueryVariables
>;
export const GetBetInfoDocument = gql`
  query GetBetInfo($betId: String!) {
    bet(id: $betId) {
      id
      account {
        id
        user {
          username
          vipLevel
        }
      }
      game {
        id
        name
        slug
        edge
        gameAndGameCategories {
          gameCategoryName
          gameId
          main
        }
        provider {
          name
        }
        originalGame
      }
      gameSeed {
        ...GameSeedFields
      }
      gameSeedNonce
      shuffleOriginalActions {
        id
        updatedAt
        createdAt
        action {
          dice {
            ...DiceFields
          }
          plinko {
            multiplier
            results
            risk
            rows
          }
          mines {
            minesResult
            minesCount
            winMultiplier
            selected
          }
          limbo {
            resultRaw
            resultValue
            userValue
          }
          keno {
            results
            risk
            multiplier
            selected
          }
          hilo {
            card
            guess
            winMultiplier
            actionType
          }
          blackjack {
            mainPlayerHand
            mainPlayerActions
            splitPlayerHand
            splitPlayerActions
            dealerHand
            perfectPairWin
            twentyOnePlusThreeWin
            twentyOnePlusThreeAmount
            perfectPairAmount
            insuranceStatus
            originalMainBetAmount
            mainHandOutcome
            splitHandOutcome
          }
        }
      }
      amount
      originalAmount
      payout
      currency
      createdAt
      afterBalance
      multiplier
    }
  }
  ${GameSeedFieldsFragmentDoc}
  ${DiceFieldsFragmentDoc}
`;

/**
 * __useGetBetInfoQuery__
 *
 * To run a query within a React component, call `useGetBetInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBetInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBetInfoQuery({
 *   variables: {
 *      betId: // value for 'betId'
 *   },
 * });
 */
export function useGetBetInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetBetInfoQuery, GetBetInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBetInfoQuery, GetBetInfoQueryVariables>(GetBetInfoDocument, options);
}
export function useGetBetInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBetInfoQuery, GetBetInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBetInfoQuery, GetBetInfoQueryVariables>(
    GetBetInfoDocument,
    options,
  );
}
export type GetBetInfoQueryHookResult = ReturnType<typeof useGetBetInfoQuery>;
export type GetBetInfoLazyQueryHookResult = ReturnType<typeof useGetBetInfoLazyQuery>;
export type GetBetInfoQueryResult = Apollo.QueryResult<GetBetInfoQuery, GetBetInfoQueryVariables>;
export const GetChainsCurrenciesDocument = gql`
  query getChainsCurrencies {
    chainsCurrencies {
      currency
      chain
      withdrawalFee
      minimumWithdrawal
      disabledAt
      tagAllowed
    }
  }
`;

/**
 * __useGetChainsCurrenciesQuery__
 *
 * To run a query within a React component, call `useGetChainsCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainsCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainsCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChainsCurrenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChainsCurrenciesQuery,
    GetChainsCurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChainsCurrenciesQuery, GetChainsCurrenciesQueryVariables>(
    GetChainsCurrenciesDocument,
    options,
  );
}
export function useGetChainsCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChainsCurrenciesQuery,
    GetChainsCurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChainsCurrenciesQuery, GetChainsCurrenciesQueryVariables>(
    GetChainsCurrenciesDocument,
    options,
  );
}
export type GetChainsCurrenciesQueryHookResult = ReturnType<typeof useGetChainsCurrenciesQuery>;
export type GetChainsCurrenciesLazyQueryHookResult = ReturnType<
  typeof useGetChainsCurrenciesLazyQuery
>;
export type GetChainsCurrenciesQueryResult = Apollo.QueryResult<
  GetChainsCurrenciesQuery,
  GetChainsCurrenciesQueryVariables
>;
export const GetLatestBetsDocument = gql`
  query GetLatestBets($count: Int) {
    latestBets(count: $count) {
      ...BetActivityFields
    }
  }
  ${BetActivityFieldsFragmentDoc}
`;

/**
 * __useGetLatestBetsQuery__
 *
 * To run a query within a React component, call `useGetLatestBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestBetsQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetLatestBetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLatestBetsQuery, GetLatestBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLatestBetsQuery, GetLatestBetsQueryVariables>(
    GetLatestBetsDocument,
    options,
  );
}
export function useGetLatestBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLatestBetsQuery, GetLatestBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLatestBetsQuery, GetLatestBetsQueryVariables>(
    GetLatestBetsDocument,
    options,
  );
}
export type GetLatestBetsQueryHookResult = ReturnType<typeof useGetLatestBetsQuery>;
export type GetLatestBetsLazyQueryHookResult = ReturnType<typeof useGetLatestBetsLazyQuery>;
export type GetLatestBetsQueryResult = Apollo.QueryResult<
  GetLatestBetsQuery,
  GetLatestBetsQueryVariables
>;
export const GetHighRollerBetsDocument = gql`
  query GetHighRollerBets($count: Int) {
    highRollerBets(count: $count) {
      ...BetActivityFields
    }
  }
  ${BetActivityFieldsFragmentDoc}
`;

/**
 * __useGetHighRollerBetsQuery__
 *
 * To run a query within a React component, call `useGetHighRollerBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHighRollerBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHighRollerBetsQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetHighRollerBetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetHighRollerBetsQuery, GetHighRollerBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHighRollerBetsQuery, GetHighRollerBetsQueryVariables>(
    GetHighRollerBetsDocument,
    options,
  );
}
export function useGetHighRollerBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHighRollerBetsQuery,
    GetHighRollerBetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHighRollerBetsQuery, GetHighRollerBetsQueryVariables>(
    GetHighRollerBetsDocument,
    options,
  );
}
export type GetHighRollerBetsQueryHookResult = ReturnType<typeof useGetHighRollerBetsQuery>;
export type GetHighRollerBetsLazyQueryHookResult = ReturnType<typeof useGetHighRollerBetsLazyQuery>;
export type GetHighRollerBetsQueryResult = Apollo.QueryResult<
  GetHighRollerBetsQuery,
  GetHighRollerBetsQueryVariables
>;
export const GetChallengeCountDocument = gql`
  query GetChallengeCount {
    challenges(completed: false) {
      totalCount
    }
  }
`;

/**
 * __useGetChallengeCountQuery__
 *
 * To run a query within a React component, call `useGetChallengeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChallengeCountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetChallengeCountQuery, GetChallengeCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChallengeCountQuery, GetChallengeCountQueryVariables>(
    GetChallengeCountDocument,
    options,
  );
}
export function useGetChallengeCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChallengeCountQuery,
    GetChallengeCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChallengeCountQuery, GetChallengeCountQueryVariables>(
    GetChallengeCountDocument,
    options,
  );
}
export type GetChallengeCountQueryHookResult = ReturnType<typeof useGetChallengeCountQuery>;
export type GetChallengeCountLazyQueryHookResult = ReturnType<typeof useGetChallengeCountLazyQuery>;
export type GetChallengeCountQueryResult = Apollo.QueryResult<
  GetChallengeCountQuery,
  GetChallengeCountQueryVariables
>;
export const GetGameCountByCategoryDocument = gql`
  query GetGameCountByCategory(
    $search: String
    $tags: [String!]
    $providers: [String!]
    $isOriginal: Boolean
    $isFavourite: Boolean
    $providerSlug: String
  ) {
    getGameCountByCategory(
      search: $search
      tags: $tags
      providers: $providers
      isOriginal: $isOriginal
      isFavourite: $isFavourite
      providerSlug: $providerSlug
    ) {
      category
      gamesCount
    }
  }
`;

/**
 * __useGetGameCountByCategoryQuery__
 *
 * To run a query within a React component, call `useGetGameCountByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameCountByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameCountByCategoryQuery({
 *   variables: {
 *      search: // value for 'search'
 *      tags: // value for 'tags'
 *      providers: // value for 'providers'
 *      isOriginal: // value for 'isOriginal'
 *      isFavourite: // value for 'isFavourite'
 *      providerSlug: // value for 'providerSlug'
 *   },
 * });
 */
export function useGetGameCountByCategoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGameCountByCategoryQuery,
    GetGameCountByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameCountByCategoryQuery, GetGameCountByCategoryQueryVariables>(
    GetGameCountByCategoryDocument,
    options,
  );
}
export function useGetGameCountByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameCountByCategoryQuery,
    GetGameCountByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameCountByCategoryQuery, GetGameCountByCategoryQueryVariables>(
    GetGameCountByCategoryDocument,
    options,
  );
}
export type GetGameCountByCategoryQueryHookResult = ReturnType<
  typeof useGetGameCountByCategoryQuery
>;
export type GetGameCountByCategoryLazyQueryHookResult = ReturnType<
  typeof useGetGameCountByCategoryLazyQuery
>;
export type GetGameCountByCategoryQueryResult = Apollo.QueryResult<
  GetGameCountByCategoryQuery,
  GetGameCountByCategoryQueryVariables
>;
export const GetCampaignsDocument = gql`
  query GetCampaigns {
    campaigns {
      ...CampaignFields
    }
  }
  ${CampaignFieldsFragmentDoc}
`;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(
    GetCampaignsDocument,
    options,
  );
}
export function useGetCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(
    GetCampaignsDocument,
    options,
  );
}
export type GetCampaignsQueryHookResult = ReturnType<typeof useGetCampaignsQuery>;
export type GetCampaignsLazyQueryHookResult = ReturnType<typeof useGetCampaignsLazyQuery>;
export type GetCampaignsQueryResult = Apollo.QueryResult<
  GetCampaignsQuery,
  GetCampaignsQueryVariables
>;
export const GetChallengeRewardsDocument = gql`
  query GetChallengeRewards {
    challengeRewards {
      claimedAt
      expiredAt
      challenge {
        id
        game {
          ...GameBody
        }
        usdMinBet
        minMultiplier
        currency
        amount
        referredOnly
        completed
        creator {
          username
          vipLevel
        }
      }
    }
  }
  ${GameBodyFragmentDoc}
`;

/**
 * __useGetChallengeRewardsQuery__
 *
 * To run a query within a React component, call `useGetChallengeRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengeRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengeRewardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChallengeRewardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChallengeRewardsQuery,
    GetChallengeRewardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChallengeRewardsQuery, GetChallengeRewardsQueryVariables>(
    GetChallengeRewardsDocument,
    options,
  );
}
export function useGetChallengeRewardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChallengeRewardsQuery,
    GetChallengeRewardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChallengeRewardsQuery, GetChallengeRewardsQueryVariables>(
    GetChallengeRewardsDocument,
    options,
  );
}
export type GetChallengeRewardsQueryHookResult = ReturnType<typeof useGetChallengeRewardsQuery>;
export type GetChallengeRewardsLazyQueryHookResult = ReturnType<
  typeof useGetChallengeRewardsLazyQuery
>;
export type GetChallengeRewardsQueryResult = Apollo.QueryResult<
  GetChallengeRewardsQuery,
  GetChallengeRewardsQueryVariables
>;
export const GetFavouriteGameIdsDocument = gql`
  query GetFavouriteGameIds {
    favouriteGameIds
  }
`;

/**
 * __useGetFavouriteGameIdsQuery__
 *
 * To run a query within a React component, call `useGetFavouriteGameIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavouriteGameIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavouriteGameIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFavouriteGameIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFavouriteGameIdsQuery,
    GetFavouriteGameIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFavouriteGameIdsQuery, GetFavouriteGameIdsQueryVariables>(
    GetFavouriteGameIdsDocument,
    options,
  );
}
export function useGetFavouriteGameIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFavouriteGameIdsQuery,
    GetFavouriteGameIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFavouriteGameIdsQuery, GetFavouriteGameIdsQueryVariables>(
    GetFavouriteGameIdsDocument,
    options,
  );
}
export type GetFavouriteGameIdsQueryHookResult = ReturnType<typeof useGetFavouriteGameIdsQuery>;
export type GetFavouriteGameIdsLazyQueryHookResult = ReturnType<
  typeof useGetFavouriteGameIdsLazyQuery
>;
export type GetFavouriteGameIdsQueryResult = Apollo.QueryResult<
  GetFavouriteGameIdsQuery,
  GetFavouriteGameIdsQueryVariables
>;
export const GetChallengesDocument = gql`
  query GetChallenges($skip: Int, $first: Int, $cursor: DateTime, $completed: Boolean) {
    challenges(skip: $skip, first: $first, cursor: $cursor, completed: $completed) {
      totalCount
      nodes {
        id
        game {
          ...GameBody
        }
        usdMinBet
        minMultiplier
        currency
        amount
        referredOnly
        completed
        creator {
          username
          vipLevel
        }
        challengeReward {
          user {
            username
            vipLevel
          }
        }
      }
    }
  }
  ${GameBodyFragmentDoc}
`;

/**
 * __useGetChallengesQuery__
 *
 * To run a query within a React component, call `useGetChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChallengesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useGetChallengesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetChallengesQuery, GetChallengesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChallengesQuery, GetChallengesQueryVariables>(
    GetChallengesDocument,
    options,
  );
}
export function useGetChallengesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChallengesQuery, GetChallengesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChallengesQuery, GetChallengesQueryVariables>(
    GetChallengesDocument,
    options,
  );
}
export type GetChallengesQueryHookResult = ReturnType<typeof useGetChallengesQuery>;
export type GetChallengesLazyQueryHookResult = ReturnType<typeof useGetChallengesLazyQuery>;
export type GetChallengesQueryResult = Apollo.QueryResult<
  GetChallengesQuery,
  GetChallengesQueryVariables
>;
export const GetGameCountByProviderDocument = gql`
  query GetGameCountByProvider(
    $isOriginal: Boolean
    $isFavourite: Boolean
    $categories: [GameCategoryType!]
    $search: String
    $tags: [String!]
  ) {
    getGameCountByProvider(
      isOriginal: $isOriginal
      isFavourite: $isFavourite
      categories: $categories
      search: $search
      tags: $tags
    ) {
      provider {
        id
        name
      }
      gamesCount
    }
  }
`;

/**
 * __useGetGameCountByProviderQuery__
 *
 * To run a query within a React component, call `useGetGameCountByProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameCountByProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameCountByProviderQuery({
 *   variables: {
 *      isOriginal: // value for 'isOriginal'
 *      isFavourite: // value for 'isFavourite'
 *      categories: // value for 'categories'
 *      search: // value for 'search'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGetGameCountByProviderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGameCountByProviderQuery,
    GetGameCountByProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameCountByProviderQuery, GetGameCountByProviderQueryVariables>(
    GetGameCountByProviderDocument,
    options,
  );
}
export function useGetGameCountByProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGameCountByProviderQuery,
    GetGameCountByProviderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameCountByProviderQuery, GetGameCountByProviderQueryVariables>(
    GetGameCountByProviderDocument,
    options,
  );
}
export type GetGameCountByProviderQueryHookResult = ReturnType<
  typeof useGetGameCountByProviderQuery
>;
export type GetGameCountByProviderLazyQueryHookResult = ReturnType<
  typeof useGetGameCountByProviderLazyQuery
>;
export type GetGameCountByProviderQueryResult = Apollo.QueryResult<
  GetGameCountByProviderQuery,
  GetGameCountByProviderQueryVariables
>;
export const GetGameTagsDocument = gql`
  query GetGameTags {
    gameTags {
      name
    }
  }
`;

/**
 * __useGetGameTagsQuery__
 *
 * To run a query within a React component, call `useGetGameTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGameTagsQuery, GetGameTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameTagsQuery, GetGameTagsQueryVariables>(GetGameTagsDocument, options);
}
export function useGetGameTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameTagsQuery, GetGameTagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameTagsQuery, GetGameTagsQueryVariables>(
    GetGameTagsDocument,
    options,
  );
}
export type GetGameTagsQueryHookResult = ReturnType<typeof useGetGameTagsQuery>;
export type GetGameTagsLazyQueryHookResult = ReturnType<typeof useGetGameTagsLazyQuery>;
export type GetGameTagsQueryResult = Apollo.QueryResult<
  GetGameTagsQuery,
  GetGameTagsQueryVariables
>;
export const GetGameSeedsDocument = gql`
  query GetGameSeeds($skip: Int = 0, $first: Int = 10, $cursor: DateTime) {
    gameSeeds(skip: $skip, first: $first, cursor: $cursor) {
      nodes {
        ...GameSeedFields
      }
      totalCount
      nextCursor
    }
  }
  ${GameSeedFieldsFragmentDoc}
`;

/**
 * __useGetGameSeedsQuery__
 *
 * To run a query within a React component, call `useGetGameSeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameSeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameSeedsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetGameSeedsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGameSeedsQuery, GetGameSeedsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameSeedsQuery, GetGameSeedsQueryVariables>(
    GetGameSeedsDocument,
    options,
  );
}
export function useGetGameSeedsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameSeedsQuery, GetGameSeedsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameSeedsQuery, GetGameSeedsQueryVariables>(
    GetGameSeedsDocument,
    options,
  );
}
export type GetGameSeedsQueryHookResult = ReturnType<typeof useGetGameSeedsQuery>;
export type GetGameSeedsLazyQueryHookResult = ReturnType<typeof useGetGameSeedsLazyQuery>;
export type GetGameSeedsQueryResult = Apollo.QueryResult<
  GetGameSeedsQuery,
  GetGameSeedsQueryVariables
>;
export const GetCurrentGameSeedsDocument = gql`
  query GetCurrentGameSeeds {
    currentGameSeeds {
      ...GameSeedFields
    }
  }
  ${GameSeedFieldsFragmentDoc}
`;

/**
 * __useGetCurrentGameSeedsQuery__
 *
 * To run a query within a React component, call `useGetCurrentGameSeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentGameSeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentGameSeedsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentGameSeedsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentGameSeedsQuery,
    GetCurrentGameSeedsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentGameSeedsQuery, GetCurrentGameSeedsQueryVariables>(
    GetCurrentGameSeedsDocument,
    options,
  );
}
export function useGetCurrentGameSeedsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentGameSeedsQuery,
    GetCurrentGameSeedsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentGameSeedsQuery, GetCurrentGameSeedsQueryVariables>(
    GetCurrentGameSeedsDocument,
    options,
  );
}
export type GetCurrentGameSeedsQueryHookResult = ReturnType<typeof useGetCurrentGameSeedsQuery>;
export type GetCurrentGameSeedsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentGameSeedsLazyQuery
>;
export type GetCurrentGameSeedsQueryResult = Apollo.QueryResult<
  GetCurrentGameSeedsQuery,
  GetCurrentGameSeedsQueryVariables
>;
export const GetDepositsDocument = gql`
  query GetDeposits($skip: Int, $first: Int, $cursor: DateTime, $currencyIn: [Currency!]) {
    deposits(skip: $skip, first: $first, cursor: $cursor, currencyIn: $currencyIn) {
      nodes {
        id
        userId
        onChainTransactionId
        chain
        currency
        amount
        createdAt
        status
      }
      totalCount
      nextCursor
    }
  }
`;

/**
 * __useGetDepositsQuery__
 *
 * To run a query within a React component, call `useGetDepositsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepositsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepositsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      currencyIn: // value for 'currencyIn'
 *   },
 * });
 */
export function useGetDepositsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDepositsQuery, GetDepositsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDepositsQuery, GetDepositsQueryVariables>(GetDepositsDocument, options);
}
export function useGetDepositsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDepositsQuery, GetDepositsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDepositsQuery, GetDepositsQueryVariables>(
    GetDepositsDocument,
    options,
  );
}
export type GetDepositsQueryHookResult = ReturnType<typeof useGetDepositsQuery>;
export type GetDepositsLazyQueryHookResult = ReturnType<typeof useGetDepositsLazyQuery>;
export type GetDepositsQueryResult = Apollo.QueryResult<
  GetDepositsQuery,
  GetDepositsQueryVariables
>;
export const GetBetsDocument = gql`
  query GetBets($skip: Int, $first: Int, $cursor: DateTime, $currencyIn: [Currency!]) {
    bets(skip: $skip, first: $first, cursor: $cursor, currencyIn: $currencyIn) {
      nodes {
        ...BetFields
        updatedAt
        createdAt
      }
      nextCursor
    }
  }
  ${BetFieldsFragmentDoc}
`;

/**
 * __useGetBetsQuery__
 *
 * To run a query within a React component, call `useGetBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBetsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      currencyIn: // value for 'currencyIn'
 *   },
 * });
 */
export function useGetBetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBetsQuery, GetBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBetsQuery, GetBetsQueryVariables>(GetBetsDocument, options);
}
export function useGetBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBetsQuery, GetBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBetsQuery, GetBetsQueryVariables>(GetBetsDocument, options);
}
export type GetBetsQueryHookResult = ReturnType<typeof useGetBetsQuery>;
export type GetBetsLazyQueryHookResult = ReturnType<typeof useGetBetsLazyQuery>;
export type GetBetsQueryResult = Apollo.QueryResult<GetBetsQuery, GetBetsQueryVariables>;
export const GetMyBetsDocument = gql`
  query GetMyBets($limit: Int) {
    myBets: bets(skip: 0, first: $limit) {
      nodes {
        ...BetFields
      }
    }
  }
  ${BetFieldsFragmentDoc}
`;

/**
 * __useGetMyBetsQuery__
 *
 * To run a query within a React component, call `useGetMyBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyBetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMyBetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyBetsQuery, GetMyBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyBetsQuery, GetMyBetsQueryVariables>(GetMyBetsDocument, options);
}
export function useGetMyBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyBetsQuery, GetMyBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyBetsQuery, GetMyBetsQueryVariables>(GetMyBetsDocument, options);
}
export type GetMyBetsQueryHookResult = ReturnType<typeof useGetMyBetsQuery>;
export type GetMyBetsLazyQueryHookResult = ReturnType<typeof useGetMyBetsLazyQuery>;
export type GetMyBetsQueryResult = Apollo.QueryResult<GetMyBetsQuery, GetMyBetsQueryVariables>;
export const GetGamesDocument = gql`
  query GetGames(
    $skip: Int
    $first: Int
    $cursor: DateTime
    $tags: [String!]
    $search: String
    $providers: [String!]
    $providerSlug: String
    $categories: [GameCategoryType!]
    $isOriginal: Boolean
    $sortBy: GameSortby
    $isFavourite: Boolean
    $sortRandomSeed: Int
  ) {
    games(
      skip: $skip
      first: $first
      cursor: $cursor
      tags: $tags
      search: $search
      providers: $providers
      providerSlug: $providerSlug
      categories: $categories
      isOriginal: $isOriginal
      sortBy: $sortBy
      isFavourite: $isFavourite
      sortRandomSeed: $sortRandomSeed
    ) {
      nodes {
        ...GameBody
      }
      totalCount
    }
  }
  ${GameBodyFragmentDoc}
`;

/**
 * __useGetGamesQuery__
 *
 * To run a query within a React component, call `useGetGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      tags: // value for 'tags'
 *      search: // value for 'search'
 *      providers: // value for 'providers'
 *      providerSlug: // value for 'providerSlug'
 *      categories: // value for 'categories'
 *      isOriginal: // value for 'isOriginal'
 *      sortBy: // value for 'sortBy'
 *      isFavourite: // value for 'isFavourite'
 *      sortRandomSeed: // value for 'sortRandomSeed'
 *   },
 * });
 */
export function useGetGamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGamesQuery, GetGamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGamesQuery, GetGamesQueryVariables>(GetGamesDocument, options);
}
export function useGetGamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGamesQuery, GetGamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGamesQuery, GetGamesQueryVariables>(GetGamesDocument, options);
}
export type GetGamesQueryHookResult = ReturnType<typeof useGetGamesQuery>;
export type GetGamesLazyQueryHookResult = ReturnType<typeof useGetGamesLazyQuery>;
export type GetGamesQueryResult = Apollo.QueryResult<GetGamesQuery, GetGamesQueryVariables>;
export const GetGameBySlugDocument = gql`
  query GetGameBySlug($slug: String) {
    game(slug: $slug) {
      ...GameBody
    }
  }
  ${GameBodyFragmentDoc}
`;

/**
 * __useGetGameBySlugQuery__
 *
 * To run a query within a React component, call `useGetGameBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetGameBySlugQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGameBySlugQuery, GetGameBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameBySlugQuery, GetGameBySlugQueryVariables>(
    GetGameBySlugDocument,
    options,
  );
}
export function useGetGameBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameBySlugQuery, GetGameBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameBySlugQuery, GetGameBySlugQueryVariables>(
    GetGameBySlugDocument,
    options,
  );
}
export type GetGameBySlugQueryHookResult = ReturnType<typeof useGetGameBySlugQuery>;
export type GetGameBySlugLazyQueryHookResult = ReturnType<typeof useGetGameBySlugLazyQuery>;
export type GetGameBySlugQueryResult = Apollo.QueryResult<
  GetGameBySlugQuery,
  GetGameBySlugQueryVariables
>;
export const GetMinesActiveBetDocument = gql`
  query GetMinesActiveBet {
    minesActiveBet {
      ...MinesBetFields
    }
  }
  ${MinesBetFieldsFragmentDoc}
`;

/**
 * __useGetMinesActiveBetQuery__
 *
 * To run a query within a React component, call `useGetMinesActiveBetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMinesActiveBetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMinesActiveBetQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMinesActiveBetQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMinesActiveBetQuery, GetMinesActiveBetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMinesActiveBetQuery, GetMinesActiveBetQueryVariables>(
    GetMinesActiveBetDocument,
    options,
  );
}
export function useGetMinesActiveBetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMinesActiveBetQuery,
    GetMinesActiveBetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMinesActiveBetQuery, GetMinesActiveBetQueryVariables>(
    GetMinesActiveBetDocument,
    options,
  );
}
export type GetMinesActiveBetQueryHookResult = ReturnType<typeof useGetMinesActiveBetQuery>;
export type GetMinesActiveBetLazyQueryHookResult = ReturnType<typeof useGetMinesActiveBetLazyQuery>;
export type GetMinesActiveBetQueryResult = Apollo.QueryResult<
  GetMinesActiveBetQuery,
  GetMinesActiveBetQueryVariables
>;
export const CommissionTotalsDocument = gql`
  query CommissionTotals {
    me {
      id
      commissionTotals {
        currency
        amount
      }
      commissionBalances {
        currency
        amount
      }
    }
  }
`;

/**
 * __useCommissionTotalsQuery__
 *
 * To run a query within a React component, call `useCommissionTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommissionTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommissionTotalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommissionTotalsQuery(
  baseOptions?: Apollo.QueryHookOptions<CommissionTotalsQuery, CommissionTotalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommissionTotalsQuery, CommissionTotalsQueryVariables>(
    CommissionTotalsDocument,
    options,
  );
}
export function useCommissionTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CommissionTotalsQuery, CommissionTotalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommissionTotalsQuery, CommissionTotalsQueryVariables>(
    CommissionTotalsDocument,
    options,
  );
}
export type CommissionTotalsQueryHookResult = ReturnType<typeof useCommissionTotalsQuery>;
export type CommissionTotalsLazyQueryHookResult = ReturnType<typeof useCommissionTotalsLazyQuery>;
export type CommissionTotalsQueryResult = Apollo.QueryResult<
  CommissionTotalsQuery,
  CommissionTotalsQueryVariables
>;
export const GetCrashGameDocument = gql`
  query GetCrashGame {
    crashGame {
      crashHistories {
        ...CrashGameFields
      }
      betPayoutPayloads {
        ...CrashBetPayoutEventFields
      }
      crashGameId
    }
  }
  ${CrashGameFieldsFragmentDoc}
  ${CrashBetPayoutEventFieldsFragmentDoc}
`;

/**
 * __useGetCrashGameQuery__
 *
 * To run a query within a React component, call `useGetCrashGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrashGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrashGameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCrashGameQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCrashGameQuery, GetCrashGameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCrashGameQuery, GetCrashGameQueryVariables>(
    GetCrashGameDocument,
    options,
  );
}
export function useGetCrashGameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCrashGameQuery, GetCrashGameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCrashGameQuery, GetCrashGameQueryVariables>(
    GetCrashGameDocument,
    options,
  );
}
export type GetCrashGameQueryHookResult = ReturnType<typeof useGetCrashGameQuery>;
export type GetCrashGameLazyQueryHookResult = ReturnType<typeof useGetCrashGameLazyQuery>;
export type GetCrashGameQueryResult = Apollo.QueryResult<
  GetCrashGameQuery,
  GetCrashGameQueryVariables
>;
export const GetCrashGameHistoriesDocument = gql`
  query GetCrashGameHistories {
    getCrashHistories {
      ...CrashGameFields
    }
  }
  ${CrashGameFieldsFragmentDoc}
`;

/**
 * __useGetCrashGameHistoriesQuery__
 *
 * To run a query within a React component, call `useGetCrashGameHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrashGameHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrashGameHistoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCrashGameHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCrashGameHistoriesQuery,
    GetCrashGameHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCrashGameHistoriesQuery, GetCrashGameHistoriesQueryVariables>(
    GetCrashGameHistoriesDocument,
    options,
  );
}
export function useGetCrashGameHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCrashGameHistoriesQuery,
    GetCrashGameHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCrashGameHistoriesQuery, GetCrashGameHistoriesQueryVariables>(
    GetCrashGameHistoriesDocument,
    options,
  );
}
export type GetCrashGameHistoriesQueryHookResult = ReturnType<typeof useGetCrashGameHistoriesQuery>;
export type GetCrashGameHistoriesLazyQueryHookResult = ReturnType<
  typeof useGetCrashGameHistoriesLazyQuery
>;
export type GetCrashGameHistoriesQueryResult = Apollo.QueryResult<
  GetCrashGameHistoriesQuery,
  GetCrashGameHistoriesQueryVariables
>;
export const GetCrashGameByGameIdDocument = gql`
  query GetCrashGameByGameId($id: Float!) {
    getCrashGame(crashGameId: $id) {
      ...CrashGameFields
    }
  }
  ${CrashGameFieldsFragmentDoc}
`;

/**
 * __useGetCrashGameByGameIdQuery__
 *
 * To run a query within a React component, call `useGetCrashGameByGameIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrashGameByGameIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrashGameByGameIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCrashGameByGameIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCrashGameByGameIdQuery,
    GetCrashGameByGameIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCrashGameByGameIdQuery, GetCrashGameByGameIdQueryVariables>(
    GetCrashGameByGameIdDocument,
    options,
  );
}
export function useGetCrashGameByGameIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCrashGameByGameIdQuery,
    GetCrashGameByGameIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCrashGameByGameIdQuery, GetCrashGameByGameIdQueryVariables>(
    GetCrashGameByGameIdDocument,
    options,
  );
}
export type GetCrashGameByGameIdQueryHookResult = ReturnType<typeof useGetCrashGameByGameIdQuery>;
export type GetCrashGameByGameIdLazyQueryHookResult = ReturnType<
  typeof useGetCrashGameByGameIdLazyQuery
>;
export type GetCrashGameByGameIdQueryResult = Apollo.QueryResult<
  GetCrashGameByGameIdQuery,
  GetCrashGameByGameIdQueryVariables
>;
export const GetCrashGameByBetIdDocument = gql`
  query GetCrashGameByBetId($betId: String!) {
    getCrashGameByBetId(betId: $betId) {
      ...CrashGameFields
    }
  }
  ${CrashGameFieldsFragmentDoc}
`;

/**
 * __useGetCrashGameByBetIdQuery__
 *
 * To run a query within a React component, call `useGetCrashGameByBetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrashGameByBetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrashGameByBetIdQuery({
 *   variables: {
 *      betId: // value for 'betId'
 *   },
 * });
 */
export function useGetCrashGameByBetIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetCrashGameByBetIdQuery, GetCrashGameByBetIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCrashGameByBetIdQuery, GetCrashGameByBetIdQueryVariables>(
    GetCrashGameByBetIdDocument,
    options,
  );
}
export function useGetCrashGameByBetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCrashGameByBetIdQuery,
    GetCrashGameByBetIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCrashGameByBetIdQuery, GetCrashGameByBetIdQueryVariables>(
    GetCrashGameByBetIdDocument,
    options,
  );
}
export type GetCrashGameByBetIdQueryHookResult = ReturnType<typeof useGetCrashGameByBetIdQuery>;
export type GetCrashGameByBetIdLazyQueryHookResult = ReturnType<
  typeof useGetCrashGameByBetIdLazyQuery
>;
export type GetCrashGameByBetIdQueryResult = Apollo.QueryResult<
  GetCrashGameByBetIdQuery,
  GetCrashGameByBetIdQueryVariables
>;
export const GetCrashGameLeaderboardDocument = gql`
  query GetCrashGameLeaderboard($crashGameId: Float!) {
    getCrashGameLeaderboard(crashGameId: $crashGameId) {
      id
      username
      vipLevel
      currency
      amount
      payout
      gameName
      gameCategory
      gameSlug
      payoutUSD
      multiplier
    }
  }
`;

/**
 * __useGetCrashGameLeaderboardQuery__
 *
 * To run a query within a React component, call `useGetCrashGameLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrashGameLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrashGameLeaderboardQuery({
 *   variables: {
 *      crashGameId: // value for 'crashGameId'
 *   },
 * });
 */
export function useGetCrashGameLeaderboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCrashGameLeaderboardQuery,
    GetCrashGameLeaderboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCrashGameLeaderboardQuery, GetCrashGameLeaderboardQueryVariables>(
    GetCrashGameLeaderboardDocument,
    options,
  );
}
export function useGetCrashGameLeaderboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCrashGameLeaderboardQuery,
    GetCrashGameLeaderboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCrashGameLeaderboardQuery, GetCrashGameLeaderboardQueryVariables>(
    GetCrashGameLeaderboardDocument,
    options,
  );
}
export type GetCrashGameLeaderboardQueryHookResult = ReturnType<
  typeof useGetCrashGameLeaderboardQuery
>;
export type GetCrashGameLeaderboardLazyQueryHookResult = ReturnType<
  typeof useGetCrashGameLeaderboardLazyQuery
>;
export type GetCrashGameLeaderboardQueryResult = Apollo.QueryResult<
  GetCrashGameLeaderboardQuery,
  GetCrashGameLeaderboardQueryVariables
>;
export const HiloActiveBetDocument = gql`
  query HiloActiveBet {
    hiloActiveBet {
      ...HiloBetFields
    }
  }
  ${HiloBetFieldsFragmentDoc}
`;

/**
 * __useHiloActiveBetQuery__
 *
 * To run a query within a React component, call `useHiloActiveBetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiloActiveBetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiloActiveBetQuery({
 *   variables: {
 *   },
 * });
 */
export function useHiloActiveBetQuery(
  baseOptions?: Apollo.QueryHookOptions<HiloActiveBetQuery, HiloActiveBetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HiloActiveBetQuery, HiloActiveBetQueryVariables>(
    HiloActiveBetDocument,
    options,
  );
}
export function useHiloActiveBetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HiloActiveBetQuery, HiloActiveBetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HiloActiveBetQuery, HiloActiveBetQueryVariables>(
    HiloActiveBetDocument,
    options,
  );
}
export type HiloActiveBetQueryHookResult = ReturnType<typeof useHiloActiveBetQuery>;
export type HiloActiveBetLazyQueryHookResult = ReturnType<typeof useHiloActiveBetLazyQuery>;
export type HiloActiveBetQueryResult = Apollo.QueryResult<
  HiloActiveBetQuery,
  HiloActiveBetQueryVariables
>;
export const GetGlobalDataDocument = gql`
  query GetGlobalData {
    appSettings {
      geoRestrictedRegions
      dice {
        maxPayoutUSD
        maxBetUSD
        minBetUSD
      }
      mines {
        maxPayoutUSD
        maxBetUSD
        minBetUSD
      }
      plinko {
        maxPayoutUSD
        maxBetUSD
        minBetUSD
      }
      crash {
        maxPayoutUSD
        maxBetUSD
        minBetUSD
      }
      limbo {
        maxPayoutUSD
        maxBetUSD
        minBetUSD
      }
      keno {
        maxPayoutUSD
        maxBetUSD
        minBetUSD
      }
      hilo {
        maxPayoutUSD
        maxBetUSD
        minBetUSD
      }
      blackjack {
        maxPayoutUSD
        maxBetUSD
        sidebetLimit
        minBetUSD
      }
      sportsBet {
        minBetUSD
        minPartialBetRatio
      }
      minTipUSD
    }
    vipLevels {
      level
      amount
    }
  }
`;

/**
 * __useGetGlobalDataQuery__
 *
 * To run a query within a React component, call `useGetGlobalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGlobalDataQuery, GetGlobalDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGlobalDataQuery, GetGlobalDataQueryVariables>(
    GetGlobalDataDocument,
    options,
  );
}
export function useGetGlobalDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalDataQuery, GetGlobalDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGlobalDataQuery, GetGlobalDataQueryVariables>(
    GetGlobalDataDocument,
    options,
  );
}
export type GetGlobalDataQueryHookResult = ReturnType<typeof useGetGlobalDataQuery>;
export type GetGlobalDataLazyQueryHookResult = ReturnType<typeof useGetGlobalDataLazyQuery>;
export type GetGlobalDataQueryResult = Apollo.QueryResult<
  GetGlobalDataQuery,
  GetGlobalDataQueryVariables
>;
export const GetMyKycDocument = gql`
  query GetMyKyc {
    kyc {
      ...KycFields
    }
  }
  ${KycFieldsFragmentDoc}
`;

/**
 * __useGetMyKycQuery__
 *
 * To run a query within a React component, call `useGetMyKycQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyKycQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyKycQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyKycQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyKycQuery, GetMyKycQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyKycQuery, GetMyKycQueryVariables>(GetMyKycDocument, options);
}
export function useGetMyKycLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyKycQuery, GetMyKycQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyKycQuery, GetMyKycQueryVariables>(GetMyKycDocument, options);
}
export type GetMyKycQueryHookResult = ReturnType<typeof useGetMyKycQuery>;
export type GetMyKycLazyQueryHookResult = ReturnType<typeof useGetMyKycLazyQuery>;
export type GetMyKycQueryResult = Apollo.QueryResult<GetMyKycQuery, GetMyKycQueryVariables>;
export const GetOnlineUsersDocument = gql`
  query GetOnlineUsers {
    onlineSession
  }
`;

/**
 * __useGetOnlineUsersQuery__
 *
 * To run a query within a React component, call `useGetOnlineUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnlineUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnlineUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnlineUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOnlineUsersQuery, GetOnlineUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOnlineUsersQuery, GetOnlineUsersQueryVariables>(
    GetOnlineUsersDocument,
    options,
  );
}
export function useGetOnlineUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOnlineUsersQuery, GetOnlineUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOnlineUsersQuery, GetOnlineUsersQueryVariables>(
    GetOnlineUsersDocument,
    options,
  );
}
export type GetOnlineUsersQueryHookResult = ReturnType<typeof useGetOnlineUsersQuery>;
export type GetOnlineUsersLazyQueryHookResult = ReturnType<typeof useGetOnlineUsersLazyQuery>;
export type GetOnlineUsersQueryResult = Apollo.QueryResult<
  GetOnlineUsersQuery,
  GetOnlineUsersQueryVariables
>;
export const GetMyProfileDocument = gql`
  query GetMyProfile {
    me {
      id
      username
      email
      emailVerifiedAt
      vipLevel
      otpSentAt
      updatedAt
      createdAt
      passwordUpdatedAt
      anonymous
      avatar
      avatarBackground
      linkedOauthProviders
      chatBanUntil
      xp
      intercomToken
      currentSession {
        id
        ip
        ua
        os
        device
        browser
        country
        city
        updatedAt
        createdAt
        refreshedAt
        lastUsedAt
        active
      }
      account {
        balances {
          currency
          amount
        }
        vaultBalances {
          currency
          amount
        }
      }
      raceInfo {
        id
        startingWager
        rank
      }
    }
  }
`;

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options,
  );
}
export function useGetMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(
    GetMyProfileDocument,
    options,
  );
}
export type GetMyProfileQueryHookResult = ReturnType<typeof useGetMyProfileQuery>;
export type GetMyProfileLazyQueryHookResult = ReturnType<typeof useGetMyProfileLazyQuery>;
export type GetMyProfileQueryResult = Apollo.QueryResult<
  GetMyProfileQuery,
  GetMyProfileQueryVariables
>;
export const GetMyStatisticDocument = gql`
  query GetMyStatistic {
    me {
      id
      statistic {
        bets
        wagered
      }
    }
  }
`;

/**
 * __useGetMyStatisticQuery__
 *
 * To run a query within a React component, call `useGetMyStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyStatisticQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyStatisticQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyStatisticQuery, GetMyStatisticQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyStatisticQuery, GetMyStatisticQueryVariables>(
    GetMyStatisticDocument,
    options,
  );
}
export function useGetMyStatisticLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyStatisticQuery, GetMyStatisticQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyStatisticQuery, GetMyStatisticQueryVariables>(
    GetMyStatisticDocument,
    options,
  );
}
export type GetMyStatisticQueryHookResult = ReturnType<typeof useGetMyStatisticQuery>;
export type GetMyStatisticLazyQueryHookResult = ReturnType<typeof useGetMyStatisticLazyQuery>;
export type GetMyStatisticQueryResult = Apollo.QueryResult<
  GetMyStatisticQuery,
  GetMyStatisticQueryVariables
>;
export const GetBanStatusDocument = gql`
  query GetBanStatus {
    me {
      id
      chatBanUntil
    }
  }
`;

/**
 * __useGetBanStatusQuery__
 *
 * To run a query within a React component, call `useGetBanStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBanStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBanStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBanStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBanStatusQuery, GetBanStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBanStatusQuery, GetBanStatusQueryVariables>(
    GetBanStatusDocument,
    options,
  );
}
export function useGetBanStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBanStatusQuery, GetBanStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBanStatusQuery, GetBanStatusQueryVariables>(
    GetBanStatusDocument,
    options,
  );
}
export type GetBanStatusQueryHookResult = ReturnType<typeof useGetBanStatusQuery>;
export type GetBanStatusLazyQueryHookResult = ReturnType<typeof useGetBanStatusLazyQuery>;
export type GetBanStatusQueryResult = Apollo.QueryResult<
  GetBanStatusQuery,
  GetBanStatusQueryVariables
>;
export const PendingVipRewardsDocument = gql`
  query PendingVipRewards($skipRaceReward: Boolean!) {
    vipDailyRakeback {
      claimDate
      currencyAmounts {
        amount
        currency
      }
    }
    vipWeeklyBonus {
      usdValue
      claimDate
    }
    vipMonthlyBonus {
      usdValue
      claimMonth
    }
    vipUpgradeBonuses {
      upgradeBonusUsdAmount
      vipLevel
      createdAt
      id
    }
    raceUserReward @skip(if: $skipRaceReward) {
      raceId
      currency
      wagered
      amount
      rank
      rewardStartDate
      rewardEndDate
    }
    vipBonus {
      id
      type
      currency
      amount
      cadence
      occurrence
      cancelledAt
      createdAt
      endAt
      bonusClaims {
        currency
        amount
        occurrenceIndex
      }
    }
    challengeRewards {
      claimedAt
      expiredAt
      challenge {
        id
        game {
          ...GameBody
        }
        usdMinBet
        minMultiplier
        currency
        amount
        referredOnly
        completed
        creator {
          username
          vipLevel
        }
      }
    }
  }
  ${GameBodyFragmentDoc}
`;

/**
 * __usePendingVipRewardsQuery__
 *
 * To run a query within a React component, call `usePendingVipRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingVipRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingVipRewardsQuery({
 *   variables: {
 *      skipRaceReward: // value for 'skipRaceReward'
 *   },
 * });
 */
export function usePendingVipRewardsQuery(
  baseOptions: Apollo.QueryHookOptions<PendingVipRewardsQuery, PendingVipRewardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PendingVipRewardsQuery, PendingVipRewardsQueryVariables>(
    PendingVipRewardsDocument,
    options,
  );
}
export function usePendingVipRewardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingVipRewardsQuery,
    PendingVipRewardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PendingVipRewardsQuery, PendingVipRewardsQueryVariables>(
    PendingVipRewardsDocument,
    options,
  );
}
export type PendingVipRewardsQueryHookResult = ReturnType<typeof usePendingVipRewardsQuery>;
export type PendingVipRewardsLazyQueryHookResult = ReturnType<typeof usePendingVipRewardsLazyQuery>;
export type PendingVipRewardsQueryResult = Apollo.QueryResult<
  PendingVipRewardsQuery,
  PendingVipRewardsQueryVariables
>;
export const VipUpgradeBonusesDocument = gql`
  query VipUpgradeBonuses {
    vipUpgradeBonuses {
      id
      vipLevel
      upgradeBonusUsdAmount
      createdAt
    }
  }
`;

/**
 * __useVipUpgradeBonusesQuery__
 *
 * To run a query within a React component, call `useVipUpgradeBonusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVipUpgradeBonusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVipUpgradeBonusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVipUpgradeBonusesQuery(
  baseOptions?: Apollo.QueryHookOptions<VipUpgradeBonusesQuery, VipUpgradeBonusesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VipUpgradeBonusesQuery, VipUpgradeBonusesQueryVariables>(
    VipUpgradeBonusesDocument,
    options,
  );
}
export function useVipUpgradeBonusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VipUpgradeBonusesQuery,
    VipUpgradeBonusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VipUpgradeBonusesQuery, VipUpgradeBonusesQueryVariables>(
    VipUpgradeBonusesDocument,
    options,
  );
}
export type VipUpgradeBonusesQueryHookResult = ReturnType<typeof useVipUpgradeBonusesQuery>;
export type VipUpgradeBonusesLazyQueryHookResult = ReturnType<typeof useVipUpgradeBonusesLazyQuery>;
export type VipUpgradeBonusesQueryResult = Apollo.QueryResult<
  VipUpgradeBonusesQuery,
  VipUpgradeBonusesQueryVariables
>;
export const GetMyRaceInfoDocument = gql`
  query GetMyRaceInfo {
    myRaceInfo {
      id
      startingWager
      rank
    }
  }
`;

/**
 * __useGetMyRaceInfoQuery__
 *
 * To run a query within a React component, call `useGetMyRaceInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRaceInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRaceInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyRaceInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyRaceInfoQuery, GetMyRaceInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyRaceInfoQuery, GetMyRaceInfoQueryVariables>(
    GetMyRaceInfoDocument,
    options,
  );
}
export function useGetMyRaceInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyRaceInfoQuery, GetMyRaceInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyRaceInfoQuery, GetMyRaceInfoQueryVariables>(
    GetMyRaceInfoDocument,
    options,
  );
}
export type GetMyRaceInfoQueryHookResult = ReturnType<typeof useGetMyRaceInfoQuery>;
export type GetMyRaceInfoLazyQueryHookResult = ReturnType<typeof useGetMyRaceInfoLazyQuery>;
export type GetMyRaceInfoQueryResult = Apollo.QueryResult<
  GetMyRaceInfoQuery,
  GetMyRaceInfoQueryVariables
>;
export const GetGameProvidersDocument = gql`
  query GetGameProviders {
    gameProviders {
      id
      name
      gamesCount
      slug
      image {
        id
        key
      }
    }
  }
`;

/**
 * __useGetGameProvidersQuery__
 *
 * To run a query within a React component, call `useGetGameProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGameProvidersQuery, GetGameProvidersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGameProvidersQuery, GetGameProvidersQueryVariables>(
    GetGameProvidersDocument,
    options,
  );
}
export function useGetGameProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGameProvidersQuery, GetGameProvidersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGameProvidersQuery, GetGameProvidersQueryVariables>(
    GetGameProvidersDocument,
    options,
  );
}
export type GetGameProvidersQueryHookResult = ReturnType<typeof useGetGameProvidersQuery>;
export type GetGameProvidersLazyQueryHookResult = ReturnType<typeof useGetGameProvidersLazyQuery>;
export type GetGameProvidersQueryResult = Apollo.QueryResult<
  GetGameProvidersQuery,
  GetGameProvidersQueryVariables
>;
export const GetMyReferralStatsDocument = gql`
  query GetMyReferralStats {
    me {
      id
      refereeCount
      refereeWagered {
        currency
        amount
      }
      commissionTotals {
        currency
        amount
      }
    }
  }
`;

/**
 * __useGetMyReferralStatsQuery__
 *
 * To run a query within a React component, call `useGetMyReferralStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyReferralStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyReferralStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyReferralStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyReferralStatsQuery, GetMyReferralStatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyReferralStatsQuery, GetMyReferralStatsQueryVariables>(
    GetMyReferralStatsDocument,
    options,
  );
}
export function useGetMyReferralStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyReferralStatsQuery,
    GetMyReferralStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyReferralStatsQuery, GetMyReferralStatsQueryVariables>(
    GetMyReferralStatsDocument,
    options,
  );
}
export type GetMyReferralStatsQueryHookResult = ReturnType<typeof useGetMyReferralStatsQuery>;
export type GetMyReferralStatsLazyQueryHookResult = ReturnType<
  typeof useGetMyReferralStatsLazyQuery
>;
export type GetMyReferralStatsQueryResult = Apollo.QueryResult<
  GetMyReferralStatsQuery,
  GetMyReferralStatsQueryVariables
>;
export const GetLobbyGamesDocument = gql`
  query GetLobbyGames {
    slots: games(categories: [SLOTS], first: 10) {
      nodes {
        ...GameBody
      }
    }
    liveCasino: games(categories: [LIVE_CASINO], first: 10) {
      nodes {
        ...GameBody
      }
    }
    gameShows: games(categories: [GAME_SHOWS], first: 10) {
      nodes {
        ...GameBody
      }
    }
    tableGames: games(categories: [TABLE_GAMES], first: 10) {
      nodes {
        ...GameBody
      }
    }
    originals: games(categories: [ORIGINALS], first: 10, sortBy: POPULAR) {
      nodes {
        ...GameBody
      }
    }
  }
  ${GameBodyFragmentDoc}
`;

/**
 * __useGetLobbyGamesQuery__
 *
 * To run a query within a React component, call `useGetLobbyGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLobbyGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLobbyGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLobbyGamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLobbyGamesQuery, GetLobbyGamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLobbyGamesQuery, GetLobbyGamesQueryVariables>(
    GetLobbyGamesDocument,
    options,
  );
}
export function useGetLobbyGamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLobbyGamesQuery, GetLobbyGamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLobbyGamesQuery, GetLobbyGamesQueryVariables>(
    GetLobbyGamesDocument,
    options,
  );
}
export type GetLobbyGamesQueryHookResult = ReturnType<typeof useGetLobbyGamesQuery>;
export type GetLobbyGamesLazyQueryHookResult = ReturnType<typeof useGetLobbyGamesLazyQuery>;
export type GetLobbyGamesQueryResult = Apollo.QueryResult<
  GetLobbyGamesQuery,
  GetLobbyGamesQueryVariables
>;
export const GetRaceLeaderBoardDocument = gql`
  query GetRaceLeaderBoard {
    raceLeaderBoard {
      race {
        id
        name
        prizeDistribution
        totalPrizeUsd
        currency
        endAt
        type
      }
      leaderboard {
        ...LeaderboardFields
      }
    }
  }
  ${LeaderboardFieldsFragmentDoc}
`;

/**
 * __useGetRaceLeaderBoardQuery__
 *
 * To run a query within a React component, call `useGetRaceLeaderBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRaceLeaderBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRaceLeaderBoardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRaceLeaderBoardQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRaceLeaderBoardQuery, GetRaceLeaderBoardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRaceLeaderBoardQuery, GetRaceLeaderBoardQueryVariables>(
    GetRaceLeaderBoardDocument,
    options,
  );
}
export function useGetRaceLeaderBoardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRaceLeaderBoardQuery,
    GetRaceLeaderBoardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRaceLeaderBoardQuery, GetRaceLeaderBoardQueryVariables>(
    GetRaceLeaderBoardDocument,
    options,
  );
}
export type GetRaceLeaderBoardQueryHookResult = ReturnType<typeof useGetRaceLeaderBoardQuery>;
export type GetRaceLeaderBoardLazyQueryHookResult = ReturnType<
  typeof useGetRaceLeaderBoardLazyQuery
>;
export type GetRaceLeaderBoardQueryResult = Apollo.QueryResult<
  GetRaceLeaderBoardQuery,
  GetRaceLeaderBoardQueryVariables
>;
export const GetRaceLeaderBoardV2Document = gql`
  query GetRaceLeaderBoardV2 {
    raceLeaderBoardV2 {
      race {
        id
        type
        name
        currency
        totalPrizeUsd
        prizeDistribution
        startAt
        endAt
      }
      raceWager {
        vipLevel
        username
        wagered
        raceId
        raceEntryId
      }
    }
  }
`;

/**
 * __useGetRaceLeaderBoardV2Query__
 *
 * To run a query within a React component, call `useGetRaceLeaderBoardV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetRaceLeaderBoardV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRaceLeaderBoardV2Query({
 *   variables: {
 *   },
 * });
 */
export function useGetRaceLeaderBoardV2Query(
  baseOptions?: Apollo.QueryHookOptions<
    GetRaceLeaderBoardV2Query,
    GetRaceLeaderBoardV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRaceLeaderBoardV2Query, GetRaceLeaderBoardV2QueryVariables>(
    GetRaceLeaderBoardV2Document,
    options,
  );
}
export function useGetRaceLeaderBoardV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRaceLeaderBoardV2Query,
    GetRaceLeaderBoardV2QueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRaceLeaderBoardV2Query, GetRaceLeaderBoardV2QueryVariables>(
    GetRaceLeaderBoardV2Document,
    options,
  );
}
export type GetRaceLeaderBoardV2QueryHookResult = ReturnType<typeof useGetRaceLeaderBoardV2Query>;
export type GetRaceLeaderBoardV2LazyQueryHookResult = ReturnType<
  typeof useGetRaceLeaderBoardV2LazyQuery
>;
export type GetRaceLeaderBoardV2QueryResult = Apollo.QueryResult<
  GetRaceLeaderBoardV2Query,
  GetRaceLeaderBoardV2QueryVariables
>;
export const GetSportsBetDocument = gql`
  query GetSportsBet($id: String!, $withCashoutOdds: Boolean!) {
    sportsBet(id: $id) {
      account {
        user {
          username
          vipLevel
        }
      }
      totalOddsDecimal
      actualOddsDecimal
      cashoutOddsDecimal @include(if: $withCashoutOdds)
      status
      cashoutAvailable {
        reason
        canCashout
      }
      amount
      currency
      settlement {
        payoutOddsDecimal
        payout
      }
      selections {
        id
        marketSelection {
          ...SportsMarketSelectionFields
        }
        status
        oddsNumerator
        oddsDenominator
        createdAt
        updatedAt
        snapshot {
          ...SportBetSelectionSnapshotFields
        }
      }
    }
  }
  ${SportsMarketSelectionFieldsFragmentDoc}
  ${SportBetSelectionSnapshotFieldsFragmentDoc}
`;

/**
 * __useGetSportsBetQuery__
 *
 * To run a query within a React component, call `useGetSportsBetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsBetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsBetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withCashoutOdds: // value for 'withCashoutOdds'
 *   },
 * });
 */
export function useGetSportsBetQuery(
  baseOptions: Apollo.QueryHookOptions<GetSportsBetQuery, GetSportsBetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSportsBetQuery, GetSportsBetQueryVariables>(
    GetSportsBetDocument,
    options,
  );
}
export function useGetSportsBetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSportsBetQuery, GetSportsBetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSportsBetQuery, GetSportsBetQueryVariables>(
    GetSportsBetDocument,
    options,
  );
}
export type GetSportsBetQueryHookResult = ReturnType<typeof useGetSportsBetQuery>;
export type GetSportsBetLazyQueryHookResult = ReturnType<typeof useGetSportsBetLazyQuery>;
export type GetSportsBetQueryResult = Apollo.QueryResult<
  GetSportsBetQuery,
  GetSportsBetQueryVariables
>;
export const GetSportsBetsDocument = gql`
  query GetSportsBets(
    $skip: Int
    $first: Int
    $cursor: DateTime
    $statuses: [SportsBetStatus!]
    $currencyIn: [Currency!]
    $withCashoutOdds: Boolean!
  ) {
    sportsBets(
      skip: $skip
      first: $first
      cursor: $cursor
      statuses: $statuses
      currencyIn: $currencyIn
    ) {
      nodes {
        ...SportBetFields
        cashoutOddsDecimal @include(if: $withCashoutOdds)
        updatedAt
        createdAt
      }
      totalCount
      nextCursor
    }
  }
  ${SportBetFieldsFragmentDoc}
`;

/**
 * __useGetSportsBetsQuery__
 *
 * To run a query within a React component, call `useGetSportsBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsBetsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      statuses: // value for 'statuses'
 *      currencyIn: // value for 'currencyIn'
 *      withCashoutOdds: // value for 'withCashoutOdds'
 *   },
 * });
 */
export function useGetSportsBetsQuery(
  baseOptions: Apollo.QueryHookOptions<GetSportsBetsQuery, GetSportsBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSportsBetsQuery, GetSportsBetsQueryVariables>(
    GetSportsBetsDocument,
    options,
  );
}
export function useGetSportsBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSportsBetsQuery, GetSportsBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSportsBetsQuery, GetSportsBetsQueryVariables>(
    GetSportsBetsDocument,
    options,
  );
}
export type GetSportsBetsQueryHookResult = ReturnType<typeof useGetSportsBetsQuery>;
export type GetSportsBetsLazyQueryHookResult = ReturnType<typeof useGetSportsBetsLazyQuery>;
export type GetSportsBetsQueryResult = Apollo.QueryResult<
  GetSportsBetsQuery,
  GetSportsBetsQueryVariables
>;
export const GetMySportsBetsDocument = gql`
  query GetMySportsBets($limit: Int) {
    sportsBets(skip: 0, first: $limit) {
      nodes {
        ...SportBetFields
      }
    }
  }
  ${SportBetFieldsFragmentDoc}
`;

/**
 * __useGetMySportsBetsQuery__
 *
 * To run a query within a React component, call `useGetMySportsBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySportsBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySportsBetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMySportsBetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMySportsBetsQuery, GetMySportsBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMySportsBetsQuery, GetMySportsBetsQueryVariables>(
    GetMySportsBetsDocument,
    options,
  );
}
export function useGetMySportsBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMySportsBetsQuery, GetMySportsBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMySportsBetsQuery, GetMySportsBetsQueryVariables>(
    GetMySportsBetsDocument,
    options,
  );
}
export type GetMySportsBetsQueryHookResult = ReturnType<typeof useGetMySportsBetsQuery>;
export type GetMySportsBetsLazyQueryHookResult = ReturnType<typeof useGetMySportsBetsLazyQuery>;
export type GetMySportsBetsQueryResult = Apollo.QueryResult<
  GetMySportsBetsQuery,
  GetMySportsBetsQueryVariables
>;
export const GetMyRefereesDocument = gql`
  query GetMyReferees($skip: Int = 0, $first: Int = 10, $cursor: DateTime, $campaignId: String) {
    referees(skip: $skip, first: $first, cursor: $cursor, campaignId: $campaignId) {
      nodes {
        username
        createdAt
        campaignId
        vipLevel
        wagered {
          currency
          amount
        }
        commissionGenerated {
          currency
          amount
        }
      }
      totalCount
    }
  }
`;

/**
 * __useGetMyRefereesQuery__
 *
 * To run a query within a React component, call `useGetMyRefereesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRefereesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRefereesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetMyRefereesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyRefereesQuery, GetMyRefereesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyRefereesQuery, GetMyRefereesQueryVariables>(
    GetMyRefereesDocument,
    options,
  );
}
export function useGetMyRefereesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyRefereesQuery, GetMyRefereesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyRefereesQuery, GetMyRefereesQueryVariables>(
    GetMyRefereesDocument,
    options,
  );
}
export type GetMyRefereesQueryHookResult = ReturnType<typeof useGetMyRefereesQuery>;
export type GetMyRefereesLazyQueryHookResult = ReturnType<typeof useGetMyRefereesLazyQuery>;
export type GetMyRefereesQueryResult = Apollo.QueryResult<
  GetMyRefereesQuery,
  GetMyRefereesQueryVariables
>;
export const GetRainInfoDocument = gql`
  query GetRainInfo($tipRainId: String!) {
    tipRainInfo(tipRainId: $tipRainId) {
      id
      tips {
        id
        receiver {
          username
          vipLevel
        }
        vipLevel
        currency
        amount
        createdAt
      }
      currency
      amount
      chatRoom
      createdAt
    }
  }
`;

/**
 * __useGetRainInfoQuery__
 *
 * To run a query within a React component, call `useGetRainInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRainInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRainInfoQuery({
 *   variables: {
 *      tipRainId: // value for 'tipRainId'
 *   },
 * });
 */
export function useGetRainInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetRainInfoQuery, GetRainInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRainInfoQuery, GetRainInfoQueryVariables>(GetRainInfoDocument, options);
}
export function useGetRainInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRainInfoQuery, GetRainInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRainInfoQuery, GetRainInfoQueryVariables>(
    GetRainInfoDocument,
    options,
  );
}
export type GetRainInfoQueryHookResult = ReturnType<typeof useGetRainInfoQuery>;
export type GetRainInfoLazyQueryHookResult = ReturnType<typeof useGetRainInfoLazyQuery>;
export type GetRainInfoQueryResult = Apollo.QueryResult<
  GetRainInfoQuery,
  GetRainInfoQueryVariables
>;
export const GetPricesDocument = gql`
  query GetPrices($currency: FiatCurrency!) {
    cryptoPrices(currency: $currency) {
      name
      price
    }
    fiatPrices {
      name
      price
    }
  }
`;

/**
 * __useGetPricesQuery__
 *
 * To run a query within a React component, call `useGetPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricesQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useGetPricesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPricesQuery, GetPricesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPricesQuery, GetPricesQueryVariables>(GetPricesDocument, options);
}
export function useGetPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPricesQuery, GetPricesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPricesQuery, GetPricesQueryVariables>(GetPricesDocument, options);
}
export type GetPricesQueryHookResult = ReturnType<typeof useGetPricesQuery>;
export type GetPricesLazyQueryHookResult = ReturnType<typeof useGetPricesLazyQuery>;
export type GetPricesQueryResult = Apollo.QueryResult<GetPricesQuery, GetPricesQueryVariables>;
export const GetLatestSportBetsDocument = gql`
  query GetLatestSportBets($count: Int) {
    latestSportsBets(count: $count) {
      ...SportsBetActivityFields
    }
  }
  ${SportsBetActivityFieldsFragmentDoc}
`;

/**
 * __useGetLatestSportBetsQuery__
 *
 * To run a query within a React component, call `useGetLatestSportBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestSportBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestSportBetsQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetLatestSportBetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLatestSportBetsQuery, GetLatestSportBetsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLatestSportBetsQuery, GetLatestSportBetsQueryVariables>(
    GetLatestSportBetsDocument,
    options,
  );
}
export function useGetLatestSportBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestSportBetsQuery,
    GetLatestSportBetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLatestSportBetsQuery, GetLatestSportBetsQueryVariables>(
    GetLatestSportBetsDocument,
    options,
  );
}
export type GetLatestSportBetsQueryHookResult = ReturnType<typeof useGetLatestSportBetsQuery>;
export type GetLatestSportBetsLazyQueryHookResult = ReturnType<
  typeof useGetLatestSportBetsLazyQuery
>;
export type GetLatestSportBetsQueryResult = Apollo.QueryResult<
  GetLatestSportBetsQuery,
  GetLatestSportBetsQueryVariables
>;
export const GetSportsHighRollerBetsDocument = gql`
  query GetSportsHighRollerBets($count: Int) {
    highRollerSportsBets(count: $count) {
      ...SportsBetActivityFields
    }
  }
  ${SportsBetActivityFieldsFragmentDoc}
`;

/**
 * __useGetSportsHighRollerBetsQuery__
 *
 * To run a query within a React component, call `useGetSportsHighRollerBetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsHighRollerBetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsHighRollerBetsQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useGetSportsHighRollerBetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSportsHighRollerBetsQuery,
    GetSportsHighRollerBetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSportsHighRollerBetsQuery, GetSportsHighRollerBetsQueryVariables>(
    GetSportsHighRollerBetsDocument,
    options,
  );
}
export function useGetSportsHighRollerBetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSportsHighRollerBetsQuery,
    GetSportsHighRollerBetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSportsHighRollerBetsQuery, GetSportsHighRollerBetsQueryVariables>(
    GetSportsHighRollerBetsDocument,
    options,
  );
}
export type GetSportsHighRollerBetsQueryHookResult = ReturnType<
  typeof useGetSportsHighRollerBetsQuery
>;
export type GetSportsHighRollerBetsLazyQueryHookResult = ReturnType<
  typeof useGetSportsHighRollerBetsLazyQuery
>;
export type GetSportsHighRollerBetsQueryResult = Apollo.QueryResult<
  GetSportsHighRollerBetsQuery,
  GetSportsHighRollerBetsQueryVariables
>;
export const GetSportsMatchStatesDocument = gql`
  query GetSportsMatchStates($fixtureIds: [String!]!) {
    sportsMatchStates(fixtureIds: $fixtureIds) {
      ...SportsMatchStateFields
    }
  }
  ${SportsMatchStateFieldsFragmentDoc}
`;

/**
 * __useGetSportsMatchStatesQuery__
 *
 * To run a query within a React component, call `useGetSportsMatchStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsMatchStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsMatchStatesQuery({
 *   variables: {
 *      fixtureIds: // value for 'fixtureIds'
 *   },
 * });
 */
export function useGetSportsMatchStatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSportsMatchStatesQuery,
    GetSportsMatchStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSportsMatchStatesQuery, GetSportsMatchStatesQueryVariables>(
    GetSportsMatchStatesDocument,
    options,
  );
}
export function useGetSportsMatchStatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSportsMatchStatesQuery,
    GetSportsMatchStatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSportsMatchStatesQuery, GetSportsMatchStatesQueryVariables>(
    GetSportsMatchStatesDocument,
    options,
  );
}
export type GetSportsMatchStatesQueryHookResult = ReturnType<typeof useGetSportsMatchStatesQuery>;
export type GetSportsMatchStatesLazyQueryHookResult = ReturnType<
  typeof useGetSportsMatchStatesLazyQuery
>;
export type GetSportsMatchStatesQueryResult = Apollo.QueryResult<
  GetSportsMatchStatesQuery,
  GetSportsMatchStatesQueryVariables
>;
export const GetSportsCompetitionDocument = gql`
  query GetSportsCompetition(
    $slug: String
    $first: Int
    $skip: Int
    $upcomingHour: Int
    $liveOnly: Boolean
  ) {
    sportsCompetition(slug: $slug) {
      ...SportsCompetitionBody
      fixtures(first: $first, skip: $skip, upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
        totalCount
        nodes {
          ...SportsFixtureWithDefaultMarketsBody
        }
      }
    }
  }
  ${SportsCompetitionBodyFragmentDoc}
  ${SportsFixtureWithDefaultMarketsBodyFragmentDoc}
`;

/**
 * __useGetSportsCompetitionQuery__
 *
 * To run a query within a React component, call `useGetSportsCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsCompetitionQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *      upcomingHour: // value for 'upcomingHour'
 *      liveOnly: // value for 'liveOnly'
 *   },
 * });
 */
export function useGetSportsCompetitionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSportsCompetitionQuery,
    GetSportsCompetitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSportsCompetitionQuery, GetSportsCompetitionQueryVariables>(
    GetSportsCompetitionDocument,
    options,
  );
}
export function useGetSportsCompetitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSportsCompetitionQuery,
    GetSportsCompetitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSportsCompetitionQuery, GetSportsCompetitionQueryVariables>(
    GetSportsCompetitionDocument,
    options,
  );
}
export type GetSportsCompetitionQueryHookResult = ReturnType<typeof useGetSportsCompetitionQuery>;
export type GetSportsCompetitionLazyQueryHookResult = ReturnType<
  typeof useGetSportsCompetitionLazyQuery
>;
export type GetSportsCompetitionQueryResult = Apollo.QueryResult<
  GetSportsCompetitionQuery,
  GetSportsCompetitionQueryVariables
>;
export const GetAllSportsFixturesFromCompetitionDocument = gql`
  query GetAllSportsFixturesFromCompetition($slug: String) {
    sportsCompetition(slug: $slug) {
      fixtures(first: 30) {
        totalCount
        nodes {
          id
          name
          slug
          startTime
        }
      }
    }
  }
`;

/**
 * __useGetAllSportsFixturesFromCompetitionQuery__
 *
 * To run a query within a React component, call `useGetAllSportsFixturesFromCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSportsFixturesFromCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSportsFixturesFromCompetitionQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetAllSportsFixturesFromCompetitionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllSportsFixturesFromCompetitionQuery,
    GetAllSportsFixturesFromCompetitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllSportsFixturesFromCompetitionQuery,
    GetAllSportsFixturesFromCompetitionQueryVariables
  >(GetAllSportsFixturesFromCompetitionDocument, options);
}
export function useGetAllSportsFixturesFromCompetitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllSportsFixturesFromCompetitionQuery,
    GetAllSportsFixturesFromCompetitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllSportsFixturesFromCompetitionQuery,
    GetAllSportsFixturesFromCompetitionQueryVariables
  >(GetAllSportsFixturesFromCompetitionDocument, options);
}
export type GetAllSportsFixturesFromCompetitionQueryHookResult = ReturnType<
  typeof useGetAllSportsFixturesFromCompetitionQuery
>;
export type GetAllSportsFixturesFromCompetitionLazyQueryHookResult = ReturnType<
  typeof useGetAllSportsFixturesFromCompetitionLazyQuery
>;
export type GetAllSportsFixturesFromCompetitionQueryResult = Apollo.QueryResult<
  GetAllSportsFixturesFromCompetitionQuery,
  GetAllSportsFixturesFromCompetitionQueryVariables
>;
export const GetSportsSearchResultDocument = gql`
  query GetSportsSearchResult($searchTerm: String!) {
    searchSportsCategories(keyword: $searchTerm) {
      totalCount
      nodes {
        id
        name
        slug
        sports
      }
    }
    searchSportsCompetitions(keyword: $searchTerm) {
      totalCount
      nodes {
        id
        name
        slug
        category {
          id
          slug
          name
          sports
        }
      }
    }
    searchSportsFixtures(keyword: $searchTerm) {
      totalCount
      nodes {
        id
        name
        startTime
        markets {
          inPlay
        }
        matchState {
          ...SportsMatchStateFields
        }
        slug
        competition {
          id
          name
          slug
          category {
            id
            name
            sports
            slug
          }
        }
      }
    }
  }
  ${SportsMatchStateFieldsFragmentDoc}
`;

/**
 * __useGetSportsSearchResultQuery__
 *
 * To run a query within a React component, call `useGetSportsSearchResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsSearchResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsSearchResultQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetSportsSearchResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSportsSearchResultQuery,
    GetSportsSearchResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSportsSearchResultQuery, GetSportsSearchResultQueryVariables>(
    GetSportsSearchResultDocument,
    options,
  );
}
export function useGetSportsSearchResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSportsSearchResultQuery,
    GetSportsSearchResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSportsSearchResultQuery, GetSportsSearchResultQueryVariables>(
    GetSportsSearchResultDocument,
    options,
  );
}
export type GetSportsSearchResultQueryHookResult = ReturnType<typeof useGetSportsSearchResultQuery>;
export type GetSportsSearchResultLazyQueryHookResult = ReturnType<
  typeof useGetSportsSearchResultLazyQuery
>;
export type GetSportsSearchResultQueryResult = Apollo.QueryResult<
  GetSportsSearchResultQuery,
  GetSportsSearchResultQueryVariables
>;
export const GetMyRakebackBalancesDocument = gql`
  query GetMyRakebackBalances {
    instantRakebackBonus {
      currency
      amount
    }
  }
`;

/**
 * __useGetMyRakebackBalancesQuery__
 *
 * To run a query within a React component, call `useGetMyRakebackBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRakebackBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRakebackBalancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyRakebackBalancesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyRakebackBalancesQuery,
    GetMyRakebackBalancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyRakebackBalancesQuery, GetMyRakebackBalancesQueryVariables>(
    GetMyRakebackBalancesDocument,
    options,
  );
}
export function useGetMyRakebackBalancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyRakebackBalancesQuery,
    GetMyRakebackBalancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyRakebackBalancesQuery, GetMyRakebackBalancesQueryVariables>(
    GetMyRakebackBalancesDocument,
    options,
  );
}
export type GetMyRakebackBalancesQueryHookResult = ReturnType<typeof useGetMyRakebackBalancesQuery>;
export type GetMyRakebackBalancesLazyQueryHookResult = ReturnType<
  typeof useGetMyRakebackBalancesLazyQuery
>;
export type GetMyRakebackBalancesQueryResult = Apollo.QueryResult<
  GetMyRakebackBalancesQuery,
  GetMyRakebackBalancesQueryVariables
>;
export const GetTransactionsDocument = gql`
  query GetTransactions(
    $skip: Int
    $first: Int
    $cursor: DateTime
    $typeIn: [TransactionType!]
    $typeNotIn: [TransactionType!]
    $currencyIn: [Currency!]
  ) {
    transactions(
      skip: $skip
      first: $first
      cursor: $cursor
      typeIn: $typeIn
      typeNotIn: $typeNotIn
      currencyIn: $currencyIn
    ) {
      nodes {
        id
        type
        currency
        amount
        depositId
        withdrawalId
        betId
        vaultId
        createdAt
      }
      totalCount
      nextCursor
    }
  }
`;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      typeIn: // value for 'typeIn'
 *      typeNotIn: // value for 'typeNotIn'
 *      currencyIn: // value for 'currencyIn'
 *   },
 * });
 */
export function useGetTransactionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(
    GetTransactionsDocument,
    options,
  );
}
export function useGetTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(
    GetTransactionsDocument,
    options,
  );
}
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<
  GetTransactionsQuery,
  GetTransactionsQueryVariables
>;
export const GetUnhashedSeedDocument = gql`
  query GetUnhashedSeed($hashedSeed: String!) {
    gameSeedUnhashed(hashedSeed: $hashedSeed) {
      ...GameSeedFields
    }
  }
  ${GameSeedFieldsFragmentDoc}
`;

/**
 * __useGetUnhashedSeedQuery__
 *
 * To run a query within a React component, call `useGetUnhashedSeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnhashedSeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnhashedSeedQuery({
 *   variables: {
 *      hashedSeed: // value for 'hashedSeed'
 *   },
 * });
 */
export function useGetUnhashedSeedQuery(
  baseOptions: Apollo.QueryHookOptions<GetUnhashedSeedQuery, GetUnhashedSeedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnhashedSeedQuery, GetUnhashedSeedQueryVariables>(
    GetUnhashedSeedDocument,
    options,
  );
}
export function useGetUnhashedSeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnhashedSeedQuery, GetUnhashedSeedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnhashedSeedQuery, GetUnhashedSeedQueryVariables>(
    GetUnhashedSeedDocument,
    options,
  );
}
export type GetUnhashedSeedQueryHookResult = ReturnType<typeof useGetUnhashedSeedQuery>;
export type GetUnhashedSeedLazyQueryHookResult = ReturnType<typeof useGetUnhashedSeedLazyQuery>;
export type GetUnhashedSeedQueryResult = Apollo.QueryResult<
  GetUnhashedSeedQuery,
  GetUnhashedSeedQueryVariables
>;
export const GetUserChallengesCompletedDocument = gql`
  query GetUserChallengesCompleted($skip: Int, $first: Int, $cursor: DateTime) {
    challengeCompletedByCurrentUser(skip: $skip, first: $first, cursor: $cursor) {
      totalCount
      nodes {
        id
        game {
          ...GameBody
        }
        usdMinBet
        minMultiplier
        currency
        amount
        referredOnly
        completed
        creator {
          username
          vipLevel
        }
        challengeReward {
          user {
            username
            vipLevel
          }
        }
      }
    }
  }
  ${GameBodyFragmentDoc}
`;

/**
 * __useGetUserChallengesCompletedQuery__
 *
 * To run a query within a React component, call `useGetUserChallengesCompletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserChallengesCompletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserChallengesCompletedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserChallengesCompletedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserChallengesCompletedQuery,
    GetUserChallengesCompletedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserChallengesCompletedQuery, GetUserChallengesCompletedQueryVariables>(
    GetUserChallengesCompletedDocument,
    options,
  );
}
export function useGetUserChallengesCompletedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserChallengesCompletedQuery,
    GetUserChallengesCompletedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserChallengesCompletedQuery,
    GetUserChallengesCompletedQueryVariables
  >(GetUserChallengesCompletedDocument, options);
}
export type GetUserChallengesCompletedQueryHookResult = ReturnType<
  typeof useGetUserChallengesCompletedQuery
>;
export type GetUserChallengesCompletedLazyQueryHookResult = ReturnType<
  typeof useGetUserChallengesCompletedLazyQuery
>;
export type GetUserChallengesCompletedQueryResult = Apollo.QueryResult<
  GetUserChallengesCompletedQuery,
  GetUserChallengesCompletedQueryVariables
>;
export const GetUserSessionsDocument = gql`
  query GetUserSessions($cursor: String, $first: Int = 10, $inactive: Boolean) {
    sessions(cursor: $cursor, first: $first, inactive: $inactive) {
      id
      ip
      ua
      os
      device
      browser
      country
      city
      updatedAt
      createdAt
      refreshedAt
      lastUsedAt
      active
    }
  }
`;

/**
 * __useGetUserSessionsQuery__
 *
 * To run a query within a React component, call `useGetUserSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSessionsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *      inactive: // value for 'inactive'
 *   },
 * });
 */
export function useGetUserSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserSessionsQuery, GetUserSessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserSessionsQuery, GetUserSessionsQueryVariables>(
    GetUserSessionsDocument,
    options,
  );
}
export function useGetUserSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserSessionsQuery, GetUserSessionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserSessionsQuery, GetUserSessionsQueryVariables>(
    GetUserSessionsDocument,
    options,
  );
}
export type GetUserSessionsQueryHookResult = ReturnType<typeof useGetUserSessionsQuery>;
export type GetUserSessionsLazyQueryHookResult = ReturnType<typeof useGetUserSessionsLazyQuery>;
export type GetUserSessionsQueryResult = Apollo.QueryResult<
  GetUserSessionsQuery,
  GetUserSessionsQueryVariables
>;
export const GetUserProfileDocument = gql`
  query GetUserProfile($username: String!) {
    user(where: { username: $username }) {
      id
      username
      vipLevel
      createdAt
      avatar
      avatarBackground
      statistic {
        bets
        wagered
      }
    }
  }
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  );
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options,
  );
}
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>;
export const GetWalletsDocument = gql`
  query GetWallets {
    wallets {
      address
      legacyAddress
      currency
      chain
      tag
    }
  }
`;

/**
 * __useGetWalletsQuery__
 *
 * To run a query within a React component, call `useGetWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWalletsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWalletsQuery, GetWalletsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWalletsQuery, GetWalletsQueryVariables>(GetWalletsDocument, options);
}
export function useGetWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWalletsQuery, GetWalletsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWalletsQuery, GetWalletsQueryVariables>(
    GetWalletsDocument,
    options,
  );
}
export type GetWalletsQueryHookResult = ReturnType<typeof useGetWalletsQuery>;
export type GetWalletsLazyQueryHookResult = ReturnType<typeof useGetWalletsLazyQuery>;
export type GetWalletsQueryResult = Apollo.QueryResult<GetWalletsQuery, GetWalletsQueryVariables>;
export const GetWithdrawalsDocument = gql`
  query GetWithdrawals($skip: Int, $first: Int, $cursor: DateTime, $currencyIn: [Currency!]) {
    withdrawals(skip: $skip, first: $first, cursor: $cursor, currencyIn: $currencyIn) {
      nodes {
        id
        onChainTransactionId
        chainCurrency {
          chain
          currency
        }
        receiverAddress
        status
        amount
        fee
        createdAt
      }
      totalCount
      nextCursor
    }
  }
`;

/**
 * __useGetWithdrawalsQuery__
 *
 * To run a query within a React component, call `useGetWithdrawalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWithdrawalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWithdrawalsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      currencyIn: // value for 'currencyIn'
 *   },
 * });
 */
export function useGetWithdrawalsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWithdrawalsQuery, GetWithdrawalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWithdrawalsQuery, GetWithdrawalsQueryVariables>(
    GetWithdrawalsDocument,
    options,
  );
}
export function useGetWithdrawalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWithdrawalsQuery, GetWithdrawalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWithdrawalsQuery, GetWithdrawalsQueryVariables>(
    GetWithdrawalsDocument,
    options,
  );
}
export type GetWithdrawalsQueryHookResult = ReturnType<typeof useGetWithdrawalsQuery>;
export type GetWithdrawalsLazyQueryHookResult = ReturnType<typeof useGetWithdrawalsLazyQuery>;
export type GetWithdrawalsQueryResult = Apollo.QueryResult<
  GetWithdrawalsQuery,
  GetWithdrawalsQueryVariables
>;
export const GetSportsCompetitionsDocument = gql`
  query GetSportsCompetitions(
    $skip: Int
    $first: Int
    $cursor: DateTime
    $sports: Sports
    $categoryId: String
    $upcomingHour: Int
    $firstFixtures: Int
    $skipFixtures: Int
    $liveOnly: Boolean
  ) {
    sportsCompetitions(
      skip: $skip
      first: $first
      cursor: $cursor
      sports: $sports
      categoryId: $categoryId
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(
          first: $firstFixtures
          skip: $skipFixtures
          upcomingHour: $upcomingHour
          liveOnly: $liveOnly
        ) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
  }
  ${SportsCompetitionBodyFragmentDoc}
  ${SportsFixtureWithDefaultMarketsBodyFragmentDoc}
`;

/**
 * __useGetSportsCompetitionsQuery__
 *
 * To run a query within a React component, call `useGetSportsCompetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsCompetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsCompetitionsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      sports: // value for 'sports'
 *      categoryId: // value for 'categoryId'
 *      upcomingHour: // value for 'upcomingHour'
 *      firstFixtures: // value for 'firstFixtures'
 *      skipFixtures: // value for 'skipFixtures'
 *      liveOnly: // value for 'liveOnly'
 *   },
 * });
 */
export function useGetSportsCompetitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSportsCompetitionsQuery,
    GetSportsCompetitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSportsCompetitionsQuery, GetSportsCompetitionsQueryVariables>(
    GetSportsCompetitionsDocument,
    options,
  );
}
export function useGetSportsCompetitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSportsCompetitionsQuery,
    GetSportsCompetitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSportsCompetitionsQuery, GetSportsCompetitionsQueryVariables>(
    GetSportsCompetitionsDocument,
    options,
  );
}
export type GetSportsCompetitionsQueryHookResult = ReturnType<typeof useGetSportsCompetitionsQuery>;
export type GetSportsCompetitionsLazyQueryHookResult = ReturnType<
  typeof useGetSportsCompetitionsLazyQuery
>;
export type GetSportsCompetitionsQueryResult = Apollo.QueryResult<
  GetSportsCompetitionsQuery,
  GetSportsCompetitionsQueryVariables
>;
export const GetPopularSportsCompetitionsDocument = gql`
  query GetPopularSportsCompetitions(
    $skipBaseball: Int
    $skipBasketball: Int
    $skipSoccer: Int
    $skipTennis: Int
    $skipIceHockey: Int
    $skipAmericanFootball: Int
    $skipMMA: Int
    $skipBoxing: Int
    $skipTableTennis: Int
    $skipGolf: Int
    $skipRugbyUnion: Int
    $skipEsports: Int
    $first: Int
    $upcomingHour: Int
    $liveOnly: Boolean
  ) {
    BASEBALL: sportsCompetitions(
      sports: BASEBALL
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(first: 3, skip: $skipBaseball, upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    BASKETBALL: sportsCompetitions(
      sports: BASKETBALL
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(
          first: 3
          skip: $skipBasketball
          upcomingHour: $upcomingHour
          liveOnly: $liveOnly
        ) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    SOCCER: sportsCompetitions(
      sports: SOCCER
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(first: 3, skip: $skipSoccer, upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    TENNIS: sportsCompetitions(
      sports: TENNIS
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(first: 3, skip: $skipTennis, upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    ICE_HOCKEY: sportsCompetitions(
      sports: ICE_HOCKEY
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(first: 3, skip: $skipIceHockey, upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    AMERICAN_FOOTBALL: sportsCompetitions(
      sports: AMERICAN_FOOTBALL
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(
          first: 3
          skip: $skipAmericanFootball
          upcomingHour: $upcomingHour
          liveOnly: $liveOnly
        ) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    MMA: sportsCompetitions(
      sports: MMA
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(first: 3, skip: $skipMMA, upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    BOXING: sportsCompetitions(
      sports: BOXING
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(first: 3, skip: $skipBoxing, upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    TABLE_TENNIS: sportsCompetitions(
      sports: TABLE_TENNIS
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(
          first: 3
          skip: $skipTableTennis
          upcomingHour: $upcomingHour
          liveOnly: $liveOnly
        ) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    GOLF: sportsCompetitions(
      sports: GOLF
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(first: 3, skip: $skipGolf, upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    RUGBY_UNION: sportsCompetitions(
      sports: RUGBY_UNION
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(
          first: 3
          skip: $skipRugbyUnion
          upcomingHour: $upcomingHour
          liveOnly: $liveOnly
        ) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
    ESPORTS: sportsCompetitions(
      sports: ESPORTS
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        ...SportsCompetitionBody
        fixtures(first: 3, skip: $skipEsports, upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
          nodes {
            ...SportsFixtureWithDefaultMarketsBody
          }
        }
      }
    }
  }
  ${SportsCompetitionBodyFragmentDoc}
  ${SportsFixtureWithDefaultMarketsBodyFragmentDoc}
`;

/**
 * __useGetPopularSportsCompetitionsQuery__
 *
 * To run a query within a React component, call `useGetPopularSportsCompetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopularSportsCompetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopularSportsCompetitionsQuery({
 *   variables: {
 *      skipBaseball: // value for 'skipBaseball'
 *      skipBasketball: // value for 'skipBasketball'
 *      skipSoccer: // value for 'skipSoccer'
 *      skipTennis: // value for 'skipTennis'
 *      skipIceHockey: // value for 'skipIceHockey'
 *      skipAmericanFootball: // value for 'skipAmericanFootball'
 *      skipMMA: // value for 'skipMMA'
 *      skipBoxing: // value for 'skipBoxing'
 *      skipTableTennis: // value for 'skipTableTennis'
 *      skipGolf: // value for 'skipGolf'
 *      skipRugbyUnion: // value for 'skipRugbyUnion'
 *      skipEsports: // value for 'skipEsports'
 *      first: // value for 'first'
 *      upcomingHour: // value for 'upcomingHour'
 *      liveOnly: // value for 'liveOnly'
 *   },
 * });
 */
export function useGetPopularSportsCompetitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPopularSportsCompetitionsQuery,
    GetPopularSportsCompetitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPopularSportsCompetitionsQuery,
    GetPopularSportsCompetitionsQueryVariables
  >(GetPopularSportsCompetitionsDocument, options);
}
export function useGetPopularSportsCompetitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPopularSportsCompetitionsQuery,
    GetPopularSportsCompetitionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPopularSportsCompetitionsQuery,
    GetPopularSportsCompetitionsQueryVariables
  >(GetPopularSportsCompetitionsDocument, options);
}
export type GetPopularSportsCompetitionsQueryHookResult = ReturnType<
  typeof useGetPopularSportsCompetitionsQuery
>;
export type GetPopularSportsCompetitionsLazyQueryHookResult = ReturnType<
  typeof useGetPopularSportsCompetitionsLazyQuery
>;
export type GetPopularSportsCompetitionsQueryResult = Apollo.QueryResult<
  GetPopularSportsCompetitionsQuery,
  GetPopularSportsCompetitionsQueryVariables
>;
export const GetSportsFixturesCounterDocument = gql`
  query GetSportsFixturesCounter($first: Int, $upcomingHour: Int, $liveOnly: Boolean) {
    BASEBALL: sportsCompetitions(
      sports: BASEBALL
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    BASKETBALL: sportsCompetitions(
      sports: BASKETBALL
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    SOCCER: sportsCompetitions(
      sports: SOCCER
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    TENNIS: sportsCompetitions(
      sports: TENNIS
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    ICE_HOCKEY: sportsCompetitions(
      sports: ICE_HOCKEY
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    AMERICAN_FOOTBALL: sportsCompetitions(
      sports: AMERICAN_FOOTBALL
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    MMA: sportsCompetitions(
      sports: MMA
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    BOXING: sportsCompetitions(
      sports: BOXING
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    TABLE_TENNIS: sportsCompetitions(
      sports: TABLE_TENNIS
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    GOLF: sportsCompetitions(
      sports: GOLF
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    RUGBY_UNION: sportsCompetitions(
      sports: RUGBY_UNION
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
    ESPORTS: sportsCompetitions(
      sports: ESPORTS
      first: $first
      upcomingHour: $upcomingHour
      liveOnly: $liveOnly
    ) {
      totalCount
      nodes {
        fixtures(upcomingHour: $upcomingHour, liveOnly: $liveOnly) {
          totalCount
        }
      }
    }
  }
`;

/**
 * __useGetSportsFixturesCounterQuery__
 *
 * To run a query within a React component, call `useGetSportsFixturesCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsFixturesCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsFixturesCounterQuery({
 *   variables: {
 *      first: // value for 'first'
 *      upcomingHour: // value for 'upcomingHour'
 *      liveOnly: // value for 'liveOnly'
 *   },
 * });
 */
export function useGetSportsFixturesCounterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSportsFixturesCounterQuery,
    GetSportsFixturesCounterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSportsFixturesCounterQuery, GetSportsFixturesCounterQueryVariables>(
    GetSportsFixturesCounterDocument,
    options,
  );
}
export function useGetSportsFixturesCounterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSportsFixturesCounterQuery,
    GetSportsFixturesCounterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSportsFixturesCounterQuery, GetSportsFixturesCounterQueryVariables>(
    GetSportsFixturesCounterDocument,
    options,
  );
}
export type GetSportsFixturesCounterQueryHookResult = ReturnType<
  typeof useGetSportsFixturesCounterQuery
>;
export type GetSportsFixturesCounterLazyQueryHookResult = ReturnType<
  typeof useGetSportsFixturesCounterLazyQuery
>;
export type GetSportsFixturesCounterQueryResult = Apollo.QueryResult<
  GetSportsFixturesCounterQuery,
  GetSportsFixturesCounterQueryVariables
>;
export const ValidateAddressDocument = gql`
  query validateAddress($chain: Chain!, $address: String!) {
    validateAddress(chain: $chain, address: $address)
  }
`;

/**
 * __useValidateAddressQuery__
 *
 * To run a query within a React component, call `useValidateAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateAddressQuery({
 *   variables: {
 *      chain: // value for 'chain'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useValidateAddressQuery(
  baseOptions: Apollo.QueryHookOptions<ValidateAddressQuery, ValidateAddressQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateAddressQuery, ValidateAddressQueryVariables>(
    ValidateAddressDocument,
    options,
  );
}
export function useValidateAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidateAddressQuery, ValidateAddressQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateAddressQuery, ValidateAddressQueryVariables>(
    ValidateAddressDocument,
    options,
  );
}
export type ValidateAddressQueryHookResult = ReturnType<typeof useValidateAddressQuery>;
export type ValidateAddressLazyQueryHookResult = ReturnType<typeof useValidateAddressLazyQuery>;
export type ValidateAddressQueryResult = Apollo.QueryResult<
  ValidateAddressQuery,
  ValidateAddressQueryVariables
>;
export const LookupPromotionCodeDocument = gql`
  query lookupPromotionCode($data: PromotionCodeLookupInput!) {
    lookupPromotionCode(data: $data) {
      usdRedeemValue
    }
  }
`;

/**
 * __useLookupPromotionCodeQuery__
 *
 * To run a query within a React component, call `useLookupPromotionCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupPromotionCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupPromotionCodeQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLookupPromotionCodeQuery(
  baseOptions: Apollo.QueryHookOptions<LookupPromotionCodeQuery, LookupPromotionCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LookupPromotionCodeQuery, LookupPromotionCodeQueryVariables>(
    LookupPromotionCodeDocument,
    options,
  );
}
export function useLookupPromotionCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LookupPromotionCodeQuery,
    LookupPromotionCodeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LookupPromotionCodeQuery, LookupPromotionCodeQueryVariables>(
    LookupPromotionCodeDocument,
    options,
  );
}
export type LookupPromotionCodeQueryHookResult = ReturnType<typeof useLookupPromotionCodeQuery>;
export type LookupPromotionCodeLazyQueryHookResult = ReturnType<
  typeof useLookupPromotionCodeLazyQuery
>;
export type LookupPromotionCodeQueryResult = Apollo.QueryResult<
  LookupPromotionCodeQuery,
  LookupPromotionCodeQueryVariables
>;
export const GetSportsTournamentsDocument = gql`
  query GetSportsTournaments($slug: String) {
    sportsCategory(slug: $slug) {
      id
      sports
      slug
      name
      competitions {
        totalCount
        nodes {
          id
          name
          slug
        }
      }
    }
  }
`;

/**
 * __useGetSportsTournamentsQuery__
 *
 * To run a query within a React component, call `useGetSportsTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsTournamentsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetSportsTournamentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSportsTournamentsQuery,
    GetSportsTournamentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSportsTournamentsQuery, GetSportsTournamentsQueryVariables>(
    GetSportsTournamentsDocument,
    options,
  );
}
export function useGetSportsTournamentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSportsTournamentsQuery,
    GetSportsTournamentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSportsTournamentsQuery, GetSportsTournamentsQueryVariables>(
    GetSportsTournamentsDocument,
    options,
  );
}
export type GetSportsTournamentsQueryHookResult = ReturnType<typeof useGetSportsTournamentsQuery>;
export type GetSportsTournamentsLazyQueryHookResult = ReturnType<
  typeof useGetSportsTournamentsLazyQuery
>;
export type GetSportsTournamentsQueryResult = Apollo.QueryResult<
  GetSportsTournamentsQuery,
  GetSportsTournamentsQueryVariables
>;
export const GetSportsFixtureDocument = gql`
  query GetSportsFixture($slug: String) {
    sportsFixture: sportsFixture(slug: $slug) {
      ...SportsFixtureWithDefaultMarketsBody
      competition {
        ...SportsCompetitionBody
      }
      matchState {
        ...SportsMatchStateFields
      }
    }
    marketsGroups: sportsFixture(slug: $slug) {
      ...SportsFixtureMarketsGroupsBody
    }
  }
  ${SportsFixtureWithDefaultMarketsBodyFragmentDoc}
  ${SportsCompetitionBodyFragmentDoc}
  ${SportsMatchStateFieldsFragmentDoc}
  ${SportsFixtureMarketsGroupsBodyFragmentDoc}
`;

/**
 * __useGetSportsFixtureQuery__
 *
 * To run a query within a React component, call `useGetSportsFixtureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSportsFixtureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSportsFixtureQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetSportsFixtureQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSportsFixtureQuery, GetSportsFixtureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSportsFixtureQuery, GetSportsFixtureQueryVariables>(
    GetSportsFixtureDocument,
    options,
  );
}
export function useGetSportsFixtureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSportsFixtureQuery, GetSportsFixtureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSportsFixtureQuery, GetSportsFixtureQueryVariables>(
    GetSportsFixtureDocument,
    options,
  );
}
export type GetSportsFixtureQueryHookResult = ReturnType<typeof useGetSportsFixtureQuery>;
export type GetSportsFixtureLazyQueryHookResult = ReturnType<typeof useGetSportsFixtureLazyQuery>;
export type GetSportsFixtureQueryResult = Apollo.QueryResult<
  GetSportsFixtureQuery,
  GetSportsFixtureQueryVariables
>;
export const AddFavouriteGameDocument = gql`
  mutation AddFavouriteGame($gameId: String!) {
    addFavouriteGame(gameId: $gameId)
  }
`;
export type AddFavouriteGameMutationFn = Apollo.MutationFunction<
  AddFavouriteGameMutation,
  AddFavouriteGameMutationVariables
>;

/**
 * __useAddFavouriteGameMutation__
 *
 * To run a mutation, you first call `useAddFavouriteGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavouriteGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavouriteGameMutation, { data, loading, error }] = useAddFavouriteGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useAddFavouriteGameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFavouriteGameMutation,
    AddFavouriteGameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddFavouriteGameMutation, AddFavouriteGameMutationVariables>(
    AddFavouriteGameDocument,
    options,
  );
}
export type AddFavouriteGameMutationHookResult = ReturnType<typeof useAddFavouriteGameMutation>;
export type AddFavouriteGameMutationResult = Apollo.MutationResult<AddFavouriteGameMutation>;
export type AddFavouriteGameMutationOptions = Apollo.BaseMutationOptions<
  AddFavouriteGameMutation,
  AddFavouriteGameMutationVariables
>;
export const CancelWithdrawalDocument = gql`
  mutation CancelWithdrawal($data: CancelWithdrawalInput!) {
    cancelWithdrawal(data: $data) {
      id
    }
  }
`;
export type CancelWithdrawalMutationFn = Apollo.MutationFunction<
  CancelWithdrawalMutation,
  CancelWithdrawalMutationVariables
>;

/**
 * __useCancelWithdrawalMutation__
 *
 * To run a mutation, you first call `useCancelWithdrawalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWithdrawalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWithdrawalMutation, { data, loading, error }] = useCancelWithdrawalMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCancelWithdrawalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelWithdrawalMutation,
    CancelWithdrawalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelWithdrawalMutation, CancelWithdrawalMutationVariables>(
    CancelWithdrawalDocument,
    options,
  );
}
export type CancelWithdrawalMutationHookResult = ReturnType<typeof useCancelWithdrawalMutation>;
export type CancelWithdrawalMutationResult = Apollo.MutationResult<CancelWithdrawalMutation>;
export type CancelWithdrawalMutationOptions = Apollo.BaseMutationOptions<
  CancelWithdrawalMutation,
  CancelWithdrawalMutationVariables
>;
export const SendChatDocument = gql`
  mutation SendChat($data: ChatSendInput!) {
    chatSend(data: $data)
  }
`;
export type SendChatMutationFn = Apollo.MutationFunction<
  SendChatMutation,
  SendChatMutationVariables
>;

/**
 * __useSendChatMutation__
 *
 * To run a mutation, you first call `useSendChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMutation, { data, loading, error }] = useSendChatMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendChatMutation(
  baseOptions?: Apollo.MutationHookOptions<SendChatMutation, SendChatMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendChatMutation, SendChatMutationVariables>(SendChatDocument, options);
}
export type SendChatMutationHookResult = ReturnType<typeof useSendChatMutation>;
export type SendChatMutationResult = Apollo.MutationResult<SendChatMutation>;
export type SendChatMutationOptions = Apollo.BaseMutationOptions<
  SendChatMutation,
  SendChatMutationVariables
>;
export const JoinChatDocument = gql`
  mutation JoinChat($data: ChatJoinInput!) {
    chatJoin(data: $data)
  }
`;
export type JoinChatMutationFn = Apollo.MutationFunction<
  JoinChatMutation,
  JoinChatMutationVariables
>;

/**
 * __useJoinChatMutation__
 *
 * To run a mutation, you first call `useJoinChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinChatMutation, { data, loading, error }] = useJoinChatMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useJoinChatMutation(
  baseOptions?: Apollo.MutationHookOptions<JoinChatMutation, JoinChatMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinChatMutation, JoinChatMutationVariables>(JoinChatDocument, options);
}
export type JoinChatMutationHookResult = ReturnType<typeof useJoinChatMutation>;
export type JoinChatMutationResult = Apollo.MutationResult<JoinChatMutation>;
export type JoinChatMutationOptions = Apollo.BaseMutationOptions<
  JoinChatMutation,
  JoinChatMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($data: ChangePasswordInput!) {
    passwordChange(data: $data) {
      id
      passwordUpdatedAt
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    options,
  );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ChangeGameSeedDocument = gql`
  mutation ChangeGameSeed($clientSeed: String!) {
    gameSeedChangeAndReveal(newClientSeed: $clientSeed) {
      ...GameSeedFields
    }
  }
  ${GameSeedFieldsFragmentDoc}
`;
export type ChangeGameSeedMutationFn = Apollo.MutationFunction<
  ChangeGameSeedMutation,
  ChangeGameSeedMutationVariables
>;

/**
 * __useChangeGameSeedMutation__
 *
 * To run a mutation, you first call `useChangeGameSeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeGameSeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeGameSeedMutation, { data, loading, error }] = useChangeGameSeedMutation({
 *   variables: {
 *      clientSeed: // value for 'clientSeed'
 *   },
 * });
 */
export function useChangeGameSeedMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeGameSeedMutation, ChangeGameSeedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeGameSeedMutation, ChangeGameSeedMutationVariables>(
    ChangeGameSeedDocument,
    options,
  );
}
export type ChangeGameSeedMutationHookResult = ReturnType<typeof useChangeGameSeedMutation>;
export type ChangeGameSeedMutationResult = Apollo.MutationResult<ChangeGameSeedMutation>;
export type ChangeGameSeedMutationOptions = Apollo.BaseMutationOptions<
  ChangeGameSeedMutation,
  ChangeGameSeedMutationVariables
>;
export const BlackjackNextDocument = gql`
  mutation BlackjackNext($data: BlackjackNextInput!) {
    blackjackNext(data: $data) {
      ...BlackjackBetFields
    }
  }
  ${BlackjackBetFieldsFragmentDoc}
`;
export type BlackjackNextMutationFn = Apollo.MutationFunction<
  BlackjackNextMutation,
  BlackjackNextMutationVariables
>;

/**
 * __useBlackjackNextMutation__
 *
 * To run a mutation, you first call `useBlackjackNextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlackjackNextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blackjackNextMutation, { data, loading, error }] = useBlackjackNextMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBlackjackNextMutation(
  baseOptions?: Apollo.MutationHookOptions<BlackjackNextMutation, BlackjackNextMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BlackjackNextMutation, BlackjackNextMutationVariables>(
    BlackjackNextDocument,
    options,
  );
}
export type BlackjackNextMutationHookResult = ReturnType<typeof useBlackjackNextMutation>;
export type BlackjackNextMutationResult = Apollo.MutationResult<BlackjackNextMutation>;
export type BlackjackNextMutationOptions = Apollo.BaseMutationOptions<
  BlackjackNextMutation,
  BlackjackNextMutationVariables
>;
export const BlackjackStartDocument = gql`
  mutation BlackjackStart($data: BlackjackStartInput!) {
    blackjackStart(data: $data) {
      ...BlackjackBetFields
    }
  }
  ${BlackjackBetFieldsFragmentDoc}
`;
export type BlackjackStartMutationFn = Apollo.MutationFunction<
  BlackjackStartMutation,
  BlackjackStartMutationVariables
>;

/**
 * __useBlackjackStartMutation__
 *
 * To run a mutation, you first call `useBlackjackStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlackjackStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blackjackStartMutation, { data, loading, error }] = useBlackjackStartMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBlackjackStartMutation(
  baseOptions?: Apollo.MutationHookOptions<BlackjackStartMutation, BlackjackStartMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BlackjackStartMutation, BlackjackStartMutationVariables>(
    BlackjackStartDocument,
    options,
  );
}
export type BlackjackStartMutationHookResult = ReturnType<typeof useBlackjackStartMutation>;
export type BlackjackStartMutationResult = Apollo.MutationResult<BlackjackStartMutation>;
export type BlackjackStartMutationOptions = Apollo.BaseMutationOptions<
  BlackjackStartMutation,
  BlackjackStartMutationVariables
>;
export const ClaimChallengeRewardDocument = gql`
  mutation ClaimChallengeReward($challengeId: String!) {
    claimChallengeReward(challengeId: $challengeId) {
      challenge {
        currency
        amount
      }
      claimedAt
      expiredAt
    }
  }
`;
export type ClaimChallengeRewardMutationFn = Apollo.MutationFunction<
  ClaimChallengeRewardMutation,
  ClaimChallengeRewardMutationVariables
>;

/**
 * __useClaimChallengeRewardMutation__
 *
 * To run a mutation, you first call `useClaimChallengeRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimChallengeRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimChallengeRewardMutation, { data, loading, error }] = useClaimChallengeRewardMutation({
 *   variables: {
 *      challengeId: // value for 'challengeId'
 *   },
 * });
 */
export function useClaimChallengeRewardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimChallengeRewardMutation,
    ClaimChallengeRewardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimChallengeRewardMutation, ClaimChallengeRewardMutationVariables>(
    ClaimChallengeRewardDocument,
    options,
  );
}
export type ClaimChallengeRewardMutationHookResult = ReturnType<
  typeof useClaimChallengeRewardMutation
>;
export type ClaimChallengeRewardMutationResult =
  Apollo.MutationResult<ClaimChallengeRewardMutation>;
export type ClaimChallengeRewardMutationOptions = Apollo.BaseMutationOptions<
  ClaimChallengeRewardMutation,
  ClaimChallengeRewardMutationVariables
>;
export const VipRewardsClaimMonthlyBonusDocument = gql`
  mutation VipRewardsClaimMonthlyBonus($currency: Currency!) {
    vipRewardsClaimMonthlyBonus(currency: $currency) {
      claimedAmount {
        currency
        amount
      }
      claimMonth
    }
  }
`;
export type VipRewardsClaimMonthlyBonusMutationFn = Apollo.MutationFunction<
  VipRewardsClaimMonthlyBonusMutation,
  VipRewardsClaimMonthlyBonusMutationVariables
>;

/**
 * __useVipRewardsClaimMonthlyBonusMutation__
 *
 * To run a mutation, you first call `useVipRewardsClaimMonthlyBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVipRewardsClaimMonthlyBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vipRewardsClaimMonthlyBonusMutation, { data, loading, error }] = useVipRewardsClaimMonthlyBonusMutation({
 *   variables: {
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useVipRewardsClaimMonthlyBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VipRewardsClaimMonthlyBonusMutation,
    VipRewardsClaimMonthlyBonusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VipRewardsClaimMonthlyBonusMutation,
    VipRewardsClaimMonthlyBonusMutationVariables
  >(VipRewardsClaimMonthlyBonusDocument, options);
}
export type VipRewardsClaimMonthlyBonusMutationHookResult = ReturnType<
  typeof useVipRewardsClaimMonthlyBonusMutation
>;
export type VipRewardsClaimMonthlyBonusMutationResult =
  Apollo.MutationResult<VipRewardsClaimMonthlyBonusMutation>;
export type VipRewardsClaimMonthlyBonusMutationOptions = Apollo.BaseMutationOptions<
  VipRewardsClaimMonthlyBonusMutation,
  VipRewardsClaimMonthlyBonusMutationVariables
>;
export const ClaimCommissionsDocument = gql`
  mutation ClaimCommissions {
    claimCommissions {
      amount
      currency
    }
  }
`;
export type ClaimCommissionsMutationFn = Apollo.MutationFunction<
  ClaimCommissionsMutation,
  ClaimCommissionsMutationVariables
>;

/**
 * __useClaimCommissionsMutation__
 *
 * To run a mutation, you first call `useClaimCommissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimCommissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimCommissionsMutation, { data, loading, error }] = useClaimCommissionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useClaimCommissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimCommissionsMutation,
    ClaimCommissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimCommissionsMutation, ClaimCommissionsMutationVariables>(
    ClaimCommissionsDocument,
    options,
  );
}
export type ClaimCommissionsMutationHookResult = ReturnType<typeof useClaimCommissionsMutation>;
export type ClaimCommissionsMutationResult = Apollo.MutationResult<ClaimCommissionsMutation>;
export type ClaimCommissionsMutationOptions = Apollo.BaseMutationOptions<
  ClaimCommissionsMutation,
  ClaimCommissionsMutationVariables
>;
export const ClaimDailyRakebackDocument = gql`
  mutation ClaimDailyRakeback {
    vipRewardsClaimDailyRakeback {
      claimDate
      currencyAmounts {
        amount
        currency
      }
    }
  }
`;
export type ClaimDailyRakebackMutationFn = Apollo.MutationFunction<
  ClaimDailyRakebackMutation,
  ClaimDailyRakebackMutationVariables
>;

/**
 * __useClaimDailyRakebackMutation__
 *
 * To run a mutation, you first call `useClaimDailyRakebackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimDailyRakebackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimDailyRakebackMutation, { data, loading, error }] = useClaimDailyRakebackMutation({
 *   variables: {
 *   },
 * });
 */
export function useClaimDailyRakebackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimDailyRakebackMutation,
    ClaimDailyRakebackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimDailyRakebackMutation, ClaimDailyRakebackMutationVariables>(
    ClaimDailyRakebackDocument,
    options,
  );
}
export type ClaimDailyRakebackMutationHookResult = ReturnType<typeof useClaimDailyRakebackMutation>;
export type ClaimDailyRakebackMutationResult = Apollo.MutationResult<ClaimDailyRakebackMutation>;
export type ClaimDailyRakebackMutationOptions = Apollo.BaseMutationOptions<
  ClaimDailyRakebackMutation,
  ClaimDailyRakebackMutationVariables
>;
export const ClaimUserRewardDocument = gql`
  mutation ClaimUserReward($raceId: String!) {
    raceClaimUserReward(raceId: $raceId) {
      currency
      wagered
      amount
      rank
      rewardStartDate
      rewardEndDate
    }
  }
`;
export type ClaimUserRewardMutationFn = Apollo.MutationFunction<
  ClaimUserRewardMutation,
  ClaimUserRewardMutationVariables
>;

/**
 * __useClaimUserRewardMutation__
 *
 * To run a mutation, you first call `useClaimUserRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimUserRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimUserRewardMutation, { data, loading, error }] = useClaimUserRewardMutation({
 *   variables: {
 *      raceId: // value for 'raceId'
 *   },
 * });
 */
export function useClaimUserRewardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimUserRewardMutation,
    ClaimUserRewardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimUserRewardMutation, ClaimUserRewardMutationVariables>(
    ClaimUserRewardDocument,
    options,
  );
}
export type ClaimUserRewardMutationHookResult = ReturnType<typeof useClaimUserRewardMutation>;
export type ClaimUserRewardMutationResult = Apollo.MutationResult<ClaimUserRewardMutation>;
export type ClaimUserRewardMutationOptions = Apollo.BaseMutationOptions<
  ClaimUserRewardMutation,
  ClaimUserRewardMutationVariables
>;
export const ClaimVipUpgradeBonusDocument = gql`
  mutation claimVipUpgradeBonus($currency: Currency!, $vipLevel: VipLevel!) {
    claimVipUpgradeBonus(currency: $currency, vipLevel: $vipLevel) {
      vipUserUpgradeId
      currency
      amount
    }
  }
`;
export type ClaimVipUpgradeBonusMutationFn = Apollo.MutationFunction<
  ClaimVipUpgradeBonusMutation,
  ClaimVipUpgradeBonusMutationVariables
>;

/**
 * __useClaimVipUpgradeBonusMutation__
 *
 * To run a mutation, you first call `useClaimVipUpgradeBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimVipUpgradeBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimVipUpgradeBonusMutation, { data, loading, error }] = useClaimVipUpgradeBonusMutation({
 *   variables: {
 *      currency: // value for 'currency'
 *      vipLevel: // value for 'vipLevel'
 *   },
 * });
 */
export function useClaimVipUpgradeBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimVipUpgradeBonusMutation,
    ClaimVipUpgradeBonusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimVipUpgradeBonusMutation, ClaimVipUpgradeBonusMutationVariables>(
    ClaimVipUpgradeBonusDocument,
    options,
  );
}
export type ClaimVipUpgradeBonusMutationHookResult = ReturnType<
  typeof useClaimVipUpgradeBonusMutation
>;
export type ClaimVipUpgradeBonusMutationResult =
  Apollo.MutationResult<ClaimVipUpgradeBonusMutation>;
export type ClaimVipUpgradeBonusMutationOptions = Apollo.BaseMutationOptions<
  ClaimVipUpgradeBonusMutation,
  ClaimVipUpgradeBonusMutationVariables
>;
export const VipRewardsClaimWeeklyBonusDocument = gql`
  mutation VipRewardsClaimWeeklyBonus($currency: Currency!) {
    vipRewardsClaimWeeklyBonus(currency: $currency) {
      claimedAmount {
        currency
        amount
      }
      claimDate
    }
  }
`;
export type VipRewardsClaimWeeklyBonusMutationFn = Apollo.MutationFunction<
  VipRewardsClaimWeeklyBonusMutation,
  VipRewardsClaimWeeklyBonusMutationVariables
>;

/**
 * __useVipRewardsClaimWeeklyBonusMutation__
 *
 * To run a mutation, you first call `useVipRewardsClaimWeeklyBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVipRewardsClaimWeeklyBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vipRewardsClaimWeeklyBonusMutation, { data, loading, error }] = useVipRewardsClaimWeeklyBonusMutation({
 *   variables: {
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useVipRewardsClaimWeeklyBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VipRewardsClaimWeeklyBonusMutation,
    VipRewardsClaimWeeklyBonusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VipRewardsClaimWeeklyBonusMutation,
    VipRewardsClaimWeeklyBonusMutationVariables
  >(VipRewardsClaimWeeklyBonusDocument, options);
}
export type VipRewardsClaimWeeklyBonusMutationHookResult = ReturnType<
  typeof useVipRewardsClaimWeeklyBonusMutation
>;
export type VipRewardsClaimWeeklyBonusMutationResult =
  Apollo.MutationResult<VipRewardsClaimWeeklyBonusMutation>;
export type VipRewardsClaimWeeklyBonusMutationOptions = Apollo.BaseMutationOptions<
  VipRewardsClaimWeeklyBonusMutation,
  VipRewardsClaimWeeklyBonusMutationVariables
>;
export const CampaignCreateDocument = gql`
  mutation CampaignCreate($data: CampaignCreateInput!) {
    campaignCreate(data: $data) {
      ...CampaignFields
    }
  }
  ${CampaignFieldsFragmentDoc}
`;
export type CampaignCreateMutationFn = Apollo.MutationFunction<
  CampaignCreateMutation,
  CampaignCreateMutationVariables
>;

/**
 * __useCampaignCreateMutation__
 *
 * To run a mutation, you first call `useCampaignCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignCreateMutation, { data, loading, error }] = useCampaignCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<CampaignCreateMutation, CampaignCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CampaignCreateMutation, CampaignCreateMutationVariables>(
    CampaignCreateDocument,
    options,
  );
}
export type CampaignCreateMutationHookResult = ReturnType<typeof useCampaignCreateMutation>;
export type CampaignCreateMutationResult = Apollo.MutationResult<CampaignCreateMutation>;
export type CampaignCreateMutationOptions = Apollo.BaseMutationOptions<
  CampaignCreateMutation,
  CampaignCreateMutationVariables
>;
export const GameCreateSessionDocument = gql`
  mutation GameCreateSession(
    $clientType: ClientType!
    $cryptoCurrency: Currency!
    $demoMode: Boolean!
    $fiatCurrency: FiatCurrency!
    $gameSlug: String!
    $locale: String!
  ) {
    gameCreateSession(
      clientType: $clientType
      cryptoCurrency: $cryptoCurrency
      demoMode: $demoMode
      fiatCurrency: $fiatCurrency
      gameSlug: $gameSlug
      locale: $locale
    ) {
      gameUrl
      strategy
    }
  }
`;
export type GameCreateSessionMutationFn = Apollo.MutationFunction<
  GameCreateSessionMutation,
  GameCreateSessionMutationVariables
>;

/**
 * __useGameCreateSessionMutation__
 *
 * To run a mutation, you first call `useGameCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGameCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gameCreateSessionMutation, { data, loading, error }] = useGameCreateSessionMutation({
 *   variables: {
 *      clientType: // value for 'clientType'
 *      cryptoCurrency: // value for 'cryptoCurrency'
 *      demoMode: // value for 'demoMode'
 *      fiatCurrency: // value for 'fiatCurrency'
 *      gameSlug: // value for 'gameSlug'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGameCreateSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GameCreateSessionMutation,
    GameCreateSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GameCreateSessionMutation, GameCreateSessionMutationVariables>(
    GameCreateSessionDocument,
    options,
  );
}
export type GameCreateSessionMutationHookResult = ReturnType<typeof useGameCreateSessionMutation>;
export type GameCreateSessionMutationResult = Apollo.MutationResult<GameCreateSessionMutation>;
export type GameCreateSessionMutationOptions = Apollo.BaseMutationOptions<
  GameCreateSessionMutation,
  GameCreateSessionMutationVariables
>;
export const HiloCashoutDocument = gql`
  mutation HiloCashout($betId: String) {
    hiloCashout(betId: $betId) {
      ...HiloBetFields
    }
  }
  ${HiloBetFieldsFragmentDoc}
`;
export type HiloCashoutMutationFn = Apollo.MutationFunction<
  HiloCashoutMutation,
  HiloCashoutMutationVariables
>;

/**
 * __useHiloCashoutMutation__
 *
 * To run a mutation, you first call `useHiloCashoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHiloCashoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hiloCashoutMutation, { data, loading, error }] = useHiloCashoutMutation({
 *   variables: {
 *      betId: // value for 'betId'
 *   },
 * });
 */
export function useHiloCashoutMutation(
  baseOptions?: Apollo.MutationHookOptions<HiloCashoutMutation, HiloCashoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HiloCashoutMutation, HiloCashoutMutationVariables>(
    HiloCashoutDocument,
    options,
  );
}
export type HiloCashoutMutationHookResult = ReturnType<typeof useHiloCashoutMutation>;
export type HiloCashoutMutationResult = Apollo.MutationResult<HiloCashoutMutation>;
export type HiloCashoutMutationOptions = Apollo.BaseMutationOptions<
  HiloCashoutMutation,
  HiloCashoutMutationVariables
>;
export const HiloStartDocument = gql`
  mutation HiloStart($data: HiloStartInput!) {
    hiloStart(data: $data) {
      ...HiloBetFields
    }
  }
  ${HiloBetFieldsFragmentDoc}
`;
export type HiloStartMutationFn = Apollo.MutationFunction<
  HiloStartMutation,
  HiloStartMutationVariables
>;

/**
 * __useHiloStartMutation__
 *
 * To run a mutation, you first call `useHiloStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHiloStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hiloStartMutation, { data, loading, error }] = useHiloStartMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useHiloStartMutation(
  baseOptions?: Apollo.MutationHookOptions<HiloStartMutation, HiloStartMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HiloStartMutation, HiloStartMutationVariables>(
    HiloStartDocument,
    options,
  );
}
export type HiloStartMutationHookResult = ReturnType<typeof useHiloStartMutation>;
export type HiloStartMutationResult = Apollo.MutationResult<HiloStartMutation>;
export type HiloStartMutationOptions = Apollo.BaseMutationOptions<
  HiloStartMutation,
  HiloStartMutationVariables
>;
export const CrashCashoutDocument = gql`
  mutation CrashCashout($crashGameId: Int) {
    crashCashout(crashGameId: $crashGameId)
  }
`;
export type CrashCashoutMutationFn = Apollo.MutationFunction<
  CrashCashoutMutation,
  CrashCashoutMutationVariables
>;

/**
 * __useCrashCashoutMutation__
 *
 * To run a mutation, you first call `useCrashCashoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrashCashoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crashCashoutMutation, { data, loading, error }] = useCrashCashoutMutation({
 *   variables: {
 *      crashGameId: // value for 'crashGameId'
 *   },
 * });
 */
export function useCrashCashoutMutation(
  baseOptions?: Apollo.MutationHookOptions<CrashCashoutMutation, CrashCashoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CrashCashoutMutation, CrashCashoutMutationVariables>(
    CrashCashoutDocument,
    options,
  );
}
export type CrashCashoutMutationHookResult = ReturnType<typeof useCrashCashoutMutation>;
export type CrashCashoutMutationResult = Apollo.MutationResult<CrashCashoutMutation>;
export type CrashCashoutMutationOptions = Apollo.BaseMutationOptions<
  CrashCashoutMutation,
  CrashCashoutMutationVariables
>;
export const VipBonusClaimBonusDocument = gql`
  mutation vipBonusClaimBonus($data: ClaimVipBonusInput!) {
    vipBonusClaimBonus(data: $data) {
      id
    }
  }
`;
export type VipBonusClaimBonusMutationFn = Apollo.MutationFunction<
  VipBonusClaimBonusMutation,
  VipBonusClaimBonusMutationVariables
>;

/**
 * __useVipBonusClaimBonusMutation__
 *
 * To run a mutation, you first call `useVipBonusClaimBonusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVipBonusClaimBonusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vipBonusClaimBonusMutation, { data, loading, error }] = useVipBonusClaimBonusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVipBonusClaimBonusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VipBonusClaimBonusMutation,
    VipBonusClaimBonusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VipBonusClaimBonusMutation, VipBonusClaimBonusMutationVariables>(
    VipBonusClaimBonusDocument,
    options,
  );
}
export type VipBonusClaimBonusMutationHookResult = ReturnType<typeof useVipBonusClaimBonusMutation>;
export type VipBonusClaimBonusMutationResult = Apollo.MutationResult<VipBonusClaimBonusMutation>;
export type VipBonusClaimBonusMutationOptions = Apollo.BaseMutationOptions<
  VipBonusClaimBonusMutation,
  VipBonusClaimBonusMutationVariables
>;
export const CrashPlayDocument = gql`
  mutation CrashPlay($data: CrashPlayInput!) {
    crashPlay(data: $data) {
      id
      amount
      payout
      currency
      jackpot
      game {
        id
        name
        slug
        edge
      }
      crashBet {
        crashGameId
      }
      shuffleOriginalActions {
        id
        updatedAt
        createdAt
        action {
          crash {
            betAt
            payout
            multiplier
            payoutType
          }
        }
      }
      gameSeedNonce
      afterBalance
    }
  }
`;
export type CrashPlayMutationFn = Apollo.MutationFunction<
  CrashPlayMutation,
  CrashPlayMutationVariables
>;

/**
 * __useCrashPlayMutation__
 *
 * To run a mutation, you first call `useCrashPlayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrashPlayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crashPlayMutation, { data, loading, error }] = useCrashPlayMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCrashPlayMutation(
  baseOptions?: Apollo.MutationHookOptions<CrashPlayMutation, CrashPlayMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CrashPlayMutation, CrashPlayMutationVariables>(
    CrashPlayDocument,
    options,
  );
}
export type CrashPlayMutationHookResult = ReturnType<typeof useCrashPlayMutation>;
export type CrashPlayMutationResult = Apollo.MutationResult<CrashPlayMutation>;
export type CrashPlayMutationOptions = Apollo.BaseMutationOptions<
  CrashPlayMutation,
  CrashPlayMutationVariables
>;
export const DicePlayDocument = gql`
  mutation DicePlay($data: DicePlayInput!) {
    dicePlay(data: $data) {
      id
      currency
      amount
      payout
      afterBalance
      shuffleOriginalActions {
        id
        action {
          dice {
            ...DiceFields
          }
        }
      }
      updatedAt
      createdAt
    }
  }
  ${DiceFieldsFragmentDoc}
`;
export type DicePlayMutationFn = Apollo.MutationFunction<
  DicePlayMutation,
  DicePlayMutationVariables
>;

/**
 * __useDicePlayMutation__
 *
 * To run a mutation, you first call `useDicePlayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDicePlayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dicePlayMutation, { data, loading, error }] = useDicePlayMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDicePlayMutation(
  baseOptions?: Apollo.MutationHookOptions<DicePlayMutation, DicePlayMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DicePlayMutation, DicePlayMutationVariables>(DicePlayDocument, options);
}
export type DicePlayMutationHookResult = ReturnType<typeof useDicePlayMutation>;
export type DicePlayMutationResult = Apollo.MutationResult<DicePlayMutation>;
export type DicePlayMutationOptions = Apollo.BaseMutationOptions<
  DicePlayMutation,
  DicePlayMutationVariables
>;
export const KenoPlayDocument = gql`
  mutation KenoPlay($data: KenoPlayInput!) {
    kenoPlay(data: $data) {
      id
      amount
      currency
      jackpot
      payout
      afterBalance
      shuffleOriginalActions {
        id
        action {
          keno {
            results
            risk
            multiplier
            selected
          }
        }
      }
    }
  }
`;
export type KenoPlayMutationFn = Apollo.MutationFunction<
  KenoPlayMutation,
  KenoPlayMutationVariables
>;

/**
 * __useKenoPlayMutation__
 *
 * To run a mutation, you first call `useKenoPlayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKenoPlayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [kenoPlayMutation, { data, loading, error }] = useKenoPlayMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useKenoPlayMutation(
  baseOptions?: Apollo.MutationHookOptions<KenoPlayMutation, KenoPlayMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<KenoPlayMutation, KenoPlayMutationVariables>(KenoPlayDocument, options);
}
export type KenoPlayMutationHookResult = ReturnType<typeof useKenoPlayMutation>;
export type KenoPlayMutationResult = Apollo.MutationResult<KenoPlayMutation>;
export type KenoPlayMutationOptions = Apollo.BaseMutationOptions<
  KenoPlayMutation,
  KenoPlayMutationVariables
>;
export const LimboPlayDocument = gql`
  mutation LimboPlay($data: LimboPlayInput!) {
    limboPlay(data: $data) {
      id
      currency
      amount
      payout
      afterBalance
      shuffleOriginalActions {
        id
        action {
          limbo {
            resultRaw
            resultValue
            userValue
          }
        }
      }
    }
  }
`;
export type LimboPlayMutationFn = Apollo.MutationFunction<
  LimboPlayMutation,
  LimboPlayMutationVariables
>;

/**
 * __useLimboPlayMutation__
 *
 * To run a mutation, you first call `useLimboPlayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLimboPlayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [limboPlayMutation, { data, loading, error }] = useLimboPlayMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLimboPlayMutation(
  baseOptions?: Apollo.MutationHookOptions<LimboPlayMutation, LimboPlayMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LimboPlayMutation, LimboPlayMutationVariables>(
    LimboPlayDocument,
    options,
  );
}
export type LimboPlayMutationHookResult = ReturnType<typeof useLimboPlayMutation>;
export type LimboPlayMutationResult = Apollo.MutationResult<LimboPlayMutation>;
export type LimboPlayMutationOptions = Apollo.BaseMutationOptions<
  LimboPlayMutation,
  LimboPlayMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($loginToken: String!, $tfaCode: String!) {
    login(loginToken: $loginToken, tfaCode: $tfaCode) {
      accessToken
      refreshToken
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginToken: // value for 'loginToken'
 *      tfaCode: // value for 'tfaCode'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const HiloNextDocument = gql`
  mutation HiloNext($data: HiloNextInput!) {
    hiloNext(data: $data) {
      ...HiloBetFields
    }
  }
  ${HiloBetFieldsFragmentDoc}
`;
export type HiloNextMutationFn = Apollo.MutationFunction<
  HiloNextMutation,
  HiloNextMutationVariables
>;

/**
 * __useHiloNextMutation__
 *
 * To run a mutation, you first call `useHiloNextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHiloNextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hiloNextMutation, { data, loading, error }] = useHiloNextMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useHiloNextMutation(
  baseOptions?: Apollo.MutationHookOptions<HiloNextMutation, HiloNextMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<HiloNextMutation, HiloNextMutationVariables>(HiloNextDocument, options);
}
export type HiloNextMutationHookResult = ReturnType<typeof useHiloNextMutation>;
export type HiloNextMutationResult = Apollo.MutationResult<HiloNextMutation>;
export type HiloNextMutationOptions = Apollo.BaseMutationOptions<
  HiloNextMutation,
  HiloNextMutationVariables
>;
export const LoginRequestDocument = gql`
  mutation LoginRequest($identity: String!, $password: String!, $geetest: GeetestInput) {
    loginRequest(identity: $identity, password: $password, geetest: $geetest) {
      loginToken
      otpSentAt
      otpEmail
    }
  }
`;
export type LoginRequestMutationFn = Apollo.MutationFunction<
  LoginRequestMutation,
  LoginRequestMutationVariables
>;

/**
 * __useLoginRequestMutation__
 *
 * To run a mutation, you first call `useLoginRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginRequestMutation, { data, loading, error }] = useLoginRequestMutation({
 *   variables: {
 *      identity: // value for 'identity'
 *      password: // value for 'password'
 *      geetest: // value for 'geetest'
 *   },
 * });
 */
export function useLoginRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<LoginRequestMutation, LoginRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginRequestMutation, LoginRequestMutationVariables>(
    LoginRequestDocument,
    options,
  );
}
export type LoginRequestMutationHookResult = ReturnType<typeof useLoginRequestMutation>;
export type LoginRequestMutationResult = Apollo.MutationResult<LoginRequestMutation>;
export type LoginRequestMutationOptions = Apollo.BaseMutationOptions<
  LoginRequestMutation,
  LoginRequestMutationVariables
>;
export const LoginRegisterRequestWithOauthDocument = gql`
  mutation LoginRegisterRequestWithOauth($provider: OauthProvider!, $code: String!) {
    loginRegisterRequestWithOauth(provider: $provider, code: $code) {
      login {
        accessToken
        refreshToken
      }
      oauthToken
    }
  }
`;
export type LoginRegisterRequestWithOauthMutationFn = Apollo.MutationFunction<
  LoginRegisterRequestWithOauthMutation,
  LoginRegisterRequestWithOauthMutationVariables
>;

/**
 * __useLoginRegisterRequestWithOauthMutation__
 *
 * To run a mutation, you first call `useLoginRegisterRequestWithOauthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginRegisterRequestWithOauthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginRegisterRequestWithOauthMutation, { data, loading, error }] = useLoginRegisterRequestWithOauthMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginRegisterRequestWithOauthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginRegisterRequestWithOauthMutation,
    LoginRegisterRequestWithOauthMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginRegisterRequestWithOauthMutation,
    LoginRegisterRequestWithOauthMutationVariables
  >(LoginRegisterRequestWithOauthDocument, options);
}
export type LoginRegisterRequestWithOauthMutationHookResult = ReturnType<
  typeof useLoginRegisterRequestWithOauthMutation
>;
export type LoginRegisterRequestWithOauthMutationResult =
  Apollo.MutationResult<LoginRegisterRequestWithOauthMutation>;
export type LoginRegisterRequestWithOauthMutationOptions = Apollo.BaseMutationOptions<
  LoginRegisterRequestWithOauthMutation,
  LoginRegisterRequestWithOauthMutationVariables
>;
export const LoginResendRequestDocument = gql`
  mutation LoginResendRequest($loginToken: String!) {
    loginResendRequest(loginToken: $loginToken) {
      loginToken
      otpSentAt
      otpEmail
    }
  }
`;
export type LoginResendRequestMutationFn = Apollo.MutationFunction<
  LoginResendRequestMutation,
  LoginResendRequestMutationVariables
>;

/**
 * __useLoginResendRequestMutation__
 *
 * To run a mutation, you first call `useLoginResendRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginResendRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginResendRequestMutation, { data, loading, error }] = useLoginResendRequestMutation({
 *   variables: {
 *      loginToken: // value for 'loginToken'
 *   },
 * });
 */
export function useLoginResendRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginResendRequestMutation,
    LoginResendRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginResendRequestMutation, LoginResendRequestMutationVariables>(
    LoginResendRequestDocument,
    options,
  );
}
export type LoginResendRequestMutationHookResult = ReturnType<typeof useLoginResendRequestMutation>;
export type LoginResendRequestMutationResult = Apollo.MutationResult<LoginResendRequestMutation>;
export type LoginResendRequestMutationOptions = Apollo.BaseMutationOptions<
  LoginResendRequestMutation,
  LoginResendRequestMutationVariables
>;
export const LogoutOtherSessionsDocument = gql`
  mutation LogoutOtherSessions {
    logoutOtherSessions
  }
`;
export type LogoutOtherSessionsMutationFn = Apollo.MutationFunction<
  LogoutOtherSessionsMutation,
  LogoutOtherSessionsMutationVariables
>;

/**
 * __useLogoutOtherSessionsMutation__
 *
 * To run a mutation, you first call `useLogoutOtherSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutOtherSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutOtherSessionsMutation, { data, loading, error }] = useLogoutOtherSessionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutOtherSessionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutOtherSessionsMutation,
    LogoutOtherSessionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutOtherSessionsMutation, LogoutOtherSessionsMutationVariables>(
    LogoutOtherSessionsDocument,
    options,
  );
}
export type LogoutOtherSessionsMutationHookResult = ReturnType<
  typeof useLogoutOtherSessionsMutation
>;
export type LogoutOtherSessionsMutationResult = Apollo.MutationResult<LogoutOtherSessionsMutation>;
export type LogoutOtherSessionsMutationOptions = Apollo.BaseMutationOptions<
  LogoutOtherSessionsMutation,
  LogoutOtherSessionsMutationVariables
>;
export const MinesAutoBetDocument = gql`
  mutation MinesAutoBet($data: MinesBetInput!) {
    minesAuto(data: $data) {
      ...MinesBetFields
    }
  }
  ${MinesBetFieldsFragmentDoc}
`;
export type MinesAutoBetMutationFn = Apollo.MutationFunction<
  MinesAutoBetMutation,
  MinesAutoBetMutationVariables
>;

/**
 * __useMinesAutoBetMutation__
 *
 * To run a mutation, you first call `useMinesAutoBetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMinesAutoBetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [minesAutoBetMutation, { data, loading, error }] = useMinesAutoBetMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMinesAutoBetMutation(
  baseOptions?: Apollo.MutationHookOptions<MinesAutoBetMutation, MinesAutoBetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MinesAutoBetMutation, MinesAutoBetMutationVariables>(
    MinesAutoBetDocument,
    options,
  );
}
export type MinesAutoBetMutationHookResult = ReturnType<typeof useMinesAutoBetMutation>;
export type MinesAutoBetMutationResult = Apollo.MutationResult<MinesAutoBetMutation>;
export type MinesAutoBetMutationOptions = Apollo.BaseMutationOptions<
  MinesAutoBetMutation,
  MinesAutoBetMutationVariables
>;
export const MinesStartDocument = gql`
  mutation MinesStart($data: MinesStartInput!) {
    minesStart(data: $data) {
      ...MinesBetFields
    }
  }
  ${MinesBetFieldsFragmentDoc}
`;
export type MinesStartMutationFn = Apollo.MutationFunction<
  MinesStartMutation,
  MinesStartMutationVariables
>;

/**
 * __useMinesStartMutation__
 *
 * To run a mutation, you first call `useMinesStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMinesStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [minesStartMutation, { data, loading, error }] = useMinesStartMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMinesStartMutation(
  baseOptions?: Apollo.MutationHookOptions<MinesStartMutation, MinesStartMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MinesStartMutation, MinesStartMutationVariables>(
    MinesStartDocument,
    options,
  );
}
export type MinesStartMutationHookResult = ReturnType<typeof useMinesStartMutation>;
export type MinesStartMutationResult = Apollo.MutationResult<MinesStartMutation>;
export type MinesStartMutationOptions = Apollo.BaseMutationOptions<
  MinesStartMutation,
  MinesStartMutationVariables
>;
export const MinesNextDocument = gql`
  mutation MinesNext($data: MinesNextInput!) {
    minesNext(data: $data) {
      ...MinesBetFields
    }
  }
  ${MinesBetFieldsFragmentDoc}
`;
export type MinesNextMutationFn = Apollo.MutationFunction<
  MinesNextMutation,
  MinesNextMutationVariables
>;

/**
 * __useMinesNextMutation__
 *
 * To run a mutation, you first call `useMinesNextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMinesNextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [minesNextMutation, { data, loading, error }] = useMinesNextMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMinesNextMutation(
  baseOptions?: Apollo.MutationHookOptions<MinesNextMutation, MinesNextMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MinesNextMutation, MinesNextMutationVariables>(
    MinesNextDocument,
    options,
  );
}
export type MinesNextMutationHookResult = ReturnType<typeof useMinesNextMutation>;
export type MinesNextMutationResult = Apollo.MutationResult<MinesNextMutation>;
export type MinesNextMutationOptions = Apollo.BaseMutationOptions<
  MinesNextMutation,
  MinesNextMutationVariables
>;
export const MinesCashoutDocument = gql`
  mutation MinesCashout {
    minesCashout {
      ...MinesBetFields
    }
  }
  ${MinesBetFieldsFragmentDoc}
`;
export type MinesCashoutMutationFn = Apollo.MutationFunction<
  MinesCashoutMutation,
  MinesCashoutMutationVariables
>;

/**
 * __useMinesCashoutMutation__
 *
 * To run a mutation, you first call `useMinesCashoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMinesCashoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [minesCashoutMutation, { data, loading, error }] = useMinesCashoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useMinesCashoutMutation(
  baseOptions?: Apollo.MutationHookOptions<MinesCashoutMutation, MinesCashoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MinesCashoutMutation, MinesCashoutMutationVariables>(
    MinesCashoutDocument,
    options,
  );
}
export type MinesCashoutMutationHookResult = ReturnType<typeof useMinesCashoutMutation>;
export type MinesCashoutMutationResult = Apollo.MutationResult<MinesCashoutMutation>;
export type MinesCashoutMutationOptions = Apollo.BaseMutationOptions<
  MinesCashoutMutation,
  MinesCashoutMutationVariables
>;
export const LinkWithOauthDocument = gql`
  mutation LinkWithOauth($provider: OauthProvider!, $code: String!) {
    linkWithOauth(provider: $provider, code: $code) {
      id
      linkedOauthProviders
    }
  }
`;
export type LinkWithOauthMutationFn = Apollo.MutationFunction<
  LinkWithOauthMutation,
  LinkWithOauthMutationVariables
>;

/**
 * __useLinkWithOauthMutation__
 *
 * To run a mutation, you first call `useLinkWithOauthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkWithOauthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkWithOauthMutation, { data, loading, error }] = useLinkWithOauthMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLinkWithOauthMutation(
  baseOptions?: Apollo.MutationHookOptions<LinkWithOauthMutation, LinkWithOauthMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LinkWithOauthMutation, LinkWithOauthMutationVariables>(
    LinkWithOauthDocument,
    options,
  );
}
export type LinkWithOauthMutationHookResult = ReturnType<typeof useLinkWithOauthMutation>;
export type LinkWithOauthMutationResult = Apollo.MutationResult<LinkWithOauthMutation>;
export type LinkWithOauthMutationOptions = Apollo.BaseMutationOptions<
  LinkWithOauthMutation,
  LinkWithOauthMutationVariables
>;
export const PasswordSendResetLinkDocument = gql`
  mutation PasswordSendResetLink($email: String!, $geetest: GeetestInput) {
    passwordSendResetLink(email: $email, geetest: $geetest)
  }
`;
export type PasswordSendResetLinkMutationFn = Apollo.MutationFunction<
  PasswordSendResetLinkMutation,
  PasswordSendResetLinkMutationVariables
>;

/**
 * __usePasswordSendResetLinkMutation__
 *
 * To run a mutation, you first call `usePasswordSendResetLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordSendResetLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordSendResetLinkMutation, { data, loading, error }] = usePasswordSendResetLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *      geetest: // value for 'geetest'
 *   },
 * });
 */
export function usePasswordSendResetLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordSendResetLinkMutation,
    PasswordSendResetLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PasswordSendResetLinkMutation, PasswordSendResetLinkMutationVariables>(
    PasswordSendResetLinkDocument,
    options,
  );
}
export type PasswordSendResetLinkMutationHookResult = ReturnType<
  typeof usePasswordSendResetLinkMutation
>;
export type PasswordSendResetLinkMutationResult =
  Apollo.MutationResult<PasswordSendResetLinkMutation>;
export type PasswordSendResetLinkMutationOptions = Apollo.BaseMutationOptions<
  PasswordSendResetLinkMutation,
  PasswordSendResetLinkMutationVariables
>;
export const OauthRequestDocument = gql`
  mutation OauthRequest($provider: OauthProvider!, $state: String) {
    oauthRequest(provider: $provider, state: $state) {
      loginUrl
    }
  }
`;
export type OauthRequestMutationFn = Apollo.MutationFunction<
  OauthRequestMutation,
  OauthRequestMutationVariables
>;

/**
 * __useOauthRequestMutation__
 *
 * To run a mutation, you first call `useOauthRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOauthRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oauthRequestMutation, { data, loading, error }] = useOauthRequestMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useOauthRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<OauthRequestMutation, OauthRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OauthRequestMutation, OauthRequestMutationVariables>(
    OauthRequestDocument,
    options,
  );
}
export type OauthRequestMutationHookResult = ReturnType<typeof useOauthRequestMutation>;
export type OauthRequestMutationResult = Apollo.MutationResult<OauthRequestMutation>;
export type OauthRequestMutationOptions = Apollo.BaseMutationOptions<
  OauthRequestMutation,
  OauthRequestMutationVariables
>;
export const OauthUnlinkDocument = gql`
  mutation OauthUnlink($provider: OauthProvider!) {
    unlinkWithOauth(provider: $provider) {
      id
      linkedOauthProviders
    }
  }
`;
export type OauthUnlinkMutationFn = Apollo.MutationFunction<
  OauthUnlinkMutation,
  OauthUnlinkMutationVariables
>;

/**
 * __useOauthUnlinkMutation__
 *
 * To run a mutation, you first call `useOauthUnlinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOauthUnlinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oauthUnlinkMutation, { data, loading, error }] = useOauthUnlinkMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useOauthUnlinkMutation(
  baseOptions?: Apollo.MutationHookOptions<OauthUnlinkMutation, OauthUnlinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OauthUnlinkMutation, OauthUnlinkMutationVariables>(
    OauthUnlinkDocument,
    options,
  );
}
export type OauthUnlinkMutationHookResult = ReturnType<typeof useOauthUnlinkMutation>;
export type OauthUnlinkMutationResult = Apollo.MutationResult<OauthUnlinkMutation>;
export type OauthUnlinkMutationOptions = Apollo.BaseMutationOptions<
  OauthUnlinkMutation,
  OauthUnlinkMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout($sessionId: String) {
    logout(sessionId: $sessionId)
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const PasswordSendResetLinkLoggedInDocument = gql`
  mutation PasswordSendResetLinkLoggedIn {
    passwordSendResetLinkLoggedIn
  }
`;
export type PasswordSendResetLinkLoggedInMutationFn = Apollo.MutationFunction<
  PasswordSendResetLinkLoggedInMutation,
  PasswordSendResetLinkLoggedInMutationVariables
>;

/**
 * __usePasswordSendResetLinkLoggedInMutation__
 *
 * To run a mutation, you first call `usePasswordSendResetLinkLoggedInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordSendResetLinkLoggedInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordSendResetLinkLoggedInMutation, { data, loading, error }] = usePasswordSendResetLinkLoggedInMutation({
 *   variables: {
 *   },
 * });
 */
export function usePasswordSendResetLinkLoggedInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordSendResetLinkLoggedInMutation,
    PasswordSendResetLinkLoggedInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordSendResetLinkLoggedInMutation,
    PasswordSendResetLinkLoggedInMutationVariables
  >(PasswordSendResetLinkLoggedInDocument, options);
}
export type PasswordSendResetLinkLoggedInMutationHookResult = ReturnType<
  typeof usePasswordSendResetLinkLoggedInMutation
>;
export type PasswordSendResetLinkLoggedInMutationResult =
  Apollo.MutationResult<PasswordSendResetLinkLoggedInMutation>;
export type PasswordSendResetLinkLoggedInMutationOptions = Apollo.BaseMutationOptions<
  PasswordSendResetLinkLoggedInMutation,
  PasswordSendResetLinkLoggedInMutationVariables
>;
export const PlaceSportsBetDocument = gql`
  mutation PlaceSportsBet($data: PlaceSportsBetInput!) {
    placeSportsBet(data: $data) {
      id
      currency
      amount
      totalOddsDecimal
      status
      actualOddsDecimal
      selections {
        id
        oddsNumerator
        oddsDenominator
        status
        marketSelection {
          ...SportsMarketSelectionFields
        }
        snapshot {
          ...SportBetSelectionSnapshotFields
        }
      }
      updatedAt
      createdAt
    }
  }
  ${SportsMarketSelectionFieldsFragmentDoc}
  ${SportBetSelectionSnapshotFieldsFragmentDoc}
`;
export type PlaceSportsBetMutationFn = Apollo.MutationFunction<
  PlaceSportsBetMutation,
  PlaceSportsBetMutationVariables
>;

/**
 * __usePlaceSportsBetMutation__
 *
 * To run a mutation, you first call `usePlaceSportsBetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceSportsBetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeSportsBetMutation, { data, loading, error }] = usePlaceSportsBetMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePlaceSportsBetMutation(
  baseOptions?: Apollo.MutationHookOptions<PlaceSportsBetMutation, PlaceSportsBetMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PlaceSportsBetMutation, PlaceSportsBetMutationVariables>(
    PlaceSportsBetDocument,
    options,
  );
}
export type PlaceSportsBetMutationHookResult = ReturnType<typeof usePlaceSportsBetMutation>;
export type PlaceSportsBetMutationResult = Apollo.MutationResult<PlaceSportsBetMutation>;
export type PlaceSportsBetMutationOptions = Apollo.BaseMutationOptions<
  PlaceSportsBetMutation,
  PlaceSportsBetMutationVariables
>;
export const RequestRainOtpDocument = gql`
  mutation RequestRainOtp($data: TipRainOtpInput!) {
    requestTipRainSendOtp(data: $data) {
      otpSentAt
    }
  }
`;
export type RequestRainOtpMutationFn = Apollo.MutationFunction<
  RequestRainOtpMutation,
  RequestRainOtpMutationVariables
>;

/**
 * __useRequestRainOtpMutation__
 *
 * To run a mutation, you first call `useRequestRainOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRainOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRainOtpMutation, { data, loading, error }] = useRequestRainOtpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequestRainOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestRainOtpMutation, RequestRainOtpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestRainOtpMutation, RequestRainOtpMutationVariables>(
    RequestRainOtpDocument,
    options,
  );
}
export type RequestRainOtpMutationHookResult = ReturnType<typeof useRequestRainOtpMutation>;
export type RequestRainOtpMutationResult = Apollo.MutationResult<RequestRainOtpMutation>;
export type RequestRainOtpMutationOptions = Apollo.BaseMutationOptions<
  RequestRainOtpMutation,
  RequestRainOtpMutationVariables
>;
export const SendRainDocument = gql`
  mutation SendRain($data: TipRainInput!) {
    tipRain(data: $data) {
      id
      currency
      amount
      chatRoom
      createdAt
    }
  }
`;
export type SendRainMutationFn = Apollo.MutationFunction<
  SendRainMutation,
  SendRainMutationVariables
>;

/**
 * __useSendRainMutation__
 *
 * To run a mutation, you first call `useSendRainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRainMutation, { data, loading, error }] = useSendRainMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendRainMutation(
  baseOptions?: Apollo.MutationHookOptions<SendRainMutation, SendRainMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendRainMutation, SendRainMutationVariables>(SendRainDocument, options);
}
export type SendRainMutationHookResult = ReturnType<typeof useSendRainMutation>;
export type SendRainMutationResult = Apollo.MutationResult<SendRainMutation>;
export type SendRainMutationOptions = Apollo.BaseMutationOptions<
  SendRainMutation,
  SendRainMutationVariables
>;
export const RedeemPromoCodeDocument = gql`
  mutation RedeemPromoCode($data: PromotionCodeInput!) {
    redeemPromotionCode(data: $data) {
      id
      currency
      createdAt
      afterBalance
      usdRedeemValue
    }
  }
`;
export type RedeemPromoCodeMutationFn = Apollo.MutationFunction<
  RedeemPromoCodeMutation,
  RedeemPromoCodeMutationVariables
>;

/**
 * __useRedeemPromoCodeMutation__
 *
 * To run a mutation, you first call `useRedeemPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPromoCodeMutation, { data, loading, error }] = useRedeemPromoCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRedeemPromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RedeemPromoCodeMutation,
    RedeemPromoCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RedeemPromoCodeMutation, RedeemPromoCodeMutationVariables>(
    RedeemPromoCodeDocument,
    options,
  );
}
export type RedeemPromoCodeMutationHookResult = ReturnType<typeof useRedeemPromoCodeMutation>;
export type RedeemPromoCodeMutationResult = Apollo.MutationResult<RedeemPromoCodeMutation>;
export type RedeemPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  RedeemPromoCodeMutation,
  RedeemPromoCodeMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
    options,
  );
}
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const RegisterAccountDocument = gql`
  mutation RegisterAccount($data: RegisterInput!) {
    register(data: $data) {
      accessToken
      refreshToken
      id
      createdAt
    }
  }
`;
export type RegisterAccountMutationFn = Apollo.MutationFunction<
  RegisterAccountMutation,
  RegisterAccountMutationVariables
>;

/**
 * __useRegisterAccountMutation__
 *
 * To run a mutation, you first call `useRegisterAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAccountMutation, { data, loading, error }] = useRegisterAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterAccountMutation,
    RegisterAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterAccountMutation, RegisterAccountMutationVariables>(
    RegisterAccountDocument,
    options,
  );
}
export type RegisterAccountMutationHookResult = ReturnType<typeof useRegisterAccountMutation>;
export type RegisterAccountMutationResult = Apollo.MutationResult<RegisterAccountMutation>;
export type RegisterAccountMutationOptions = Apollo.BaseMutationOptions<
  RegisterAccountMutation,
  RegisterAccountMutationVariables
>;
export const RegisterWithOauthDocument = gql`
  mutation RegisterWithOauth($data: RegisterWithOauthInput!) {
    registerWithOauth(data: $data) {
      id
      accessToken
      refreshToken
      createdAt
    }
  }
`;
export type RegisterWithOauthMutationFn = Apollo.MutationFunction<
  RegisterWithOauthMutation,
  RegisterWithOauthMutationVariables
>;

/**
 * __useRegisterWithOauthMutation__
 *
 * To run a mutation, you first call `useRegisterWithOauthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterWithOauthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerWithOauthMutation, { data, loading, error }] = useRegisterWithOauthMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterWithOauthMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterWithOauthMutation,
    RegisterWithOauthMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterWithOauthMutation, RegisterWithOauthMutationVariables>(
    RegisterWithOauthDocument,
    options,
  );
}
export type RegisterWithOauthMutationHookResult = ReturnType<typeof useRegisterWithOauthMutation>;
export type RegisterWithOauthMutationResult = Apollo.MutationResult<RegisterWithOauthMutation>;
export type RegisterWithOauthMutationOptions = Apollo.BaseMutationOptions<
  RegisterWithOauthMutation,
  RegisterWithOauthMutationVariables
>;
export const RemoveFavouriteGameDocument = gql`
  mutation RemoveFavouriteGame($gameId: String!) {
    removeFavouriteGame(gameId: $gameId)
  }
`;
export type RemoveFavouriteGameMutationFn = Apollo.MutationFunction<
  RemoveFavouriteGameMutation,
  RemoveFavouriteGameMutationVariables
>;

/**
 * __useRemoveFavouriteGameMutation__
 *
 * To run a mutation, you first call `useRemoveFavouriteGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFavouriteGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFavouriteGameMutation, { data, loading, error }] = useRemoveFavouriteGameMutation({
 *   variables: {
 *      gameId: // value for 'gameId'
 *   },
 * });
 */
export function useRemoveFavouriteGameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFavouriteGameMutation,
    RemoveFavouriteGameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFavouriteGameMutation, RemoveFavouriteGameMutationVariables>(
    RemoveFavouriteGameDocument,
    options,
  );
}
export type RemoveFavouriteGameMutationHookResult = ReturnType<
  typeof useRemoveFavouriteGameMutation
>;
export type RemoveFavouriteGameMutationResult = Apollo.MutationResult<RemoveFavouriteGameMutation>;
export type RemoveFavouriteGameMutationOptions = Apollo.BaseMutationOptions<
  RemoveFavouriteGameMutation,
  RemoveFavouriteGameMutationVariables
>;
export const ClaimRakebacksDocument = gql`
  mutation ClaimRakebacks {
    instantRakebackClaim {
      currency
      amount
    }
  }
`;
export type ClaimRakebacksMutationFn = Apollo.MutationFunction<
  ClaimRakebacksMutation,
  ClaimRakebacksMutationVariables
>;

/**
 * __useClaimRakebacksMutation__
 *
 * To run a mutation, you first call `useClaimRakebacksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimRakebacksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimRakebacksMutation, { data, loading, error }] = useClaimRakebacksMutation({
 *   variables: {
 *   },
 * });
 */
export function useClaimRakebacksMutation(
  baseOptions?: Apollo.MutationHookOptions<ClaimRakebacksMutation, ClaimRakebacksMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimRakebacksMutation, ClaimRakebacksMutationVariables>(
    ClaimRakebacksDocument,
    options,
  );
}
export type ClaimRakebacksMutationHookResult = ReturnType<typeof useClaimRakebacksMutation>;
export type ClaimRakebacksMutationResult = Apollo.MutationResult<ClaimRakebacksMutation>;
export type ClaimRakebacksMutationOptions = Apollo.BaseMutationOptions<
  ClaimRakebacksMutation,
  ClaimRakebacksMutationVariables
>;
export const RequestWithdrawTwoFaDocument = gql`
  mutation RequestWithdrawTwoFa($data: WithdrawalInput!) {
    withdrawalRequestOtp(data: $data) {
      otpSentAt
    }
  }
`;
export type RequestWithdrawTwoFaMutationFn = Apollo.MutationFunction<
  RequestWithdrawTwoFaMutation,
  RequestWithdrawTwoFaMutationVariables
>;

/**
 * __useRequestWithdrawTwoFaMutation__
 *
 * To run a mutation, you first call `useRequestWithdrawTwoFaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestWithdrawTwoFaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestWithdrawTwoFaMutation, { data, loading, error }] = useRequestWithdrawTwoFaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequestWithdrawTwoFaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestWithdrawTwoFaMutation,
    RequestWithdrawTwoFaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestWithdrawTwoFaMutation, RequestWithdrawTwoFaMutationVariables>(
    RequestWithdrawTwoFaDocument,
    options,
  );
}
export type RequestWithdrawTwoFaMutationHookResult = ReturnType<
  typeof useRequestWithdrawTwoFaMutation
>;
export type RequestWithdrawTwoFaMutationResult =
  Apollo.MutationResult<RequestWithdrawTwoFaMutation>;
export type RequestWithdrawTwoFaMutationOptions = Apollo.BaseMutationOptions<
  RequestWithdrawTwoFaMutation,
  RequestWithdrawTwoFaMutationVariables
>;
export const ResendVerificationDocument = gql`
  mutation ResendVerification {
    resendEmailVerification {
      id
      emailVerificationSentAt
    }
  }
`;
export type ResendVerificationMutationFn = Apollo.MutationFunction<
  ResendVerificationMutation,
  ResendVerificationMutationVariables
>;

/**
 * __useResendVerificationMutation__
 *
 * To run a mutation, you first call `useResendVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationMutation, { data, loading, error }] = useResendVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendVerificationMutation,
    ResendVerificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendVerificationMutation, ResendVerificationMutationVariables>(
    ResendVerificationDocument,
    options,
  );
}
export type ResendVerificationMutationHookResult = ReturnType<typeof useResendVerificationMutation>;
export type ResendVerificationMutationResult = Apollo.MutationResult<ResendVerificationMutation>;
export type ResendVerificationMutationOptions = Apollo.BaseMutationOptions<
  ResendVerificationMutation,
  ResendVerificationMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($data: PasswordResetInput!) {
    passwordReset(data: $data)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options,
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const PlinkoPlayDocument = gql`
  mutation PlinkoPlay($data: PlinkoPlayInput!) {
    plinkoPlay(data: $data) {
      id
      currency
      amount
      payout
      shuffleOriginalActions {
        id
        action {
          plinko {
            risk
            results
            rows
            multiplier
          }
        }
        updatedAt
        createdAt
      }
      afterBalance
    }
  }
`;
export type PlinkoPlayMutationFn = Apollo.MutationFunction<
  PlinkoPlayMutation,
  PlinkoPlayMutationVariables
>;

/**
 * __usePlinkoPlayMutation__
 *
 * To run a mutation, you first call `usePlinkoPlayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlinkoPlayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [plinkoPlayMutation, { data, loading, error }] = usePlinkoPlayMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePlinkoPlayMutation(
  baseOptions?: Apollo.MutationHookOptions<PlinkoPlayMutation, PlinkoPlayMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PlinkoPlayMutation, PlinkoPlayMutationVariables>(
    PlinkoPlayDocument,
    options,
  );
}
export type PlinkoPlayMutationHookResult = ReturnType<typeof usePlinkoPlayMutation>;
export type PlinkoPlayMutationResult = Apollo.MutationResult<PlinkoPlayMutation>;
export type PlinkoPlayMutationOptions = Apollo.BaseMutationOptions<
  PlinkoPlayMutation,
  PlinkoPlayMutationVariables
>;
export const SendKycLevel1Document = gql`
  mutation SendKycLevel1($data: KycLevel1UpdateInput!) {
    kycLevel1Update(data: $data) {
      ...KycFields
    }
  }
  ${KycFieldsFragmentDoc}
`;
export type SendKycLevel1MutationFn = Apollo.MutationFunction<
  SendKycLevel1Mutation,
  SendKycLevel1MutationVariables
>;

/**
 * __useSendKycLevel1Mutation__
 *
 * To run a mutation, you first call `useSendKycLevel1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendKycLevel1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendKycLevel1Mutation, { data, loading, error }] = useSendKycLevel1Mutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendKycLevel1Mutation(
  baseOptions?: Apollo.MutationHookOptions<SendKycLevel1Mutation, SendKycLevel1MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendKycLevel1Mutation, SendKycLevel1MutationVariables>(
    SendKycLevel1Document,
    options,
  );
}
export type SendKycLevel1MutationHookResult = ReturnType<typeof useSendKycLevel1Mutation>;
export type SendKycLevel1MutationResult = Apollo.MutationResult<SendKycLevel1Mutation>;
export type SendKycLevel1MutationOptions = Apollo.BaseMutationOptions<
  SendKycLevel1Mutation,
  SendKycLevel1MutationVariables
>;
export const SendKycLevel2Document = gql`
  mutation SendKycLevel2($identityFile: Upload!, $identitySecondaryFile: Upload!) {
    kycLevel2Update(identityFile: $identityFile, identitySecondaryFile: $identitySecondaryFile) {
      ...KycFields
    }
  }
  ${KycFieldsFragmentDoc}
`;
export type SendKycLevel2MutationFn = Apollo.MutationFunction<
  SendKycLevel2Mutation,
  SendKycLevel2MutationVariables
>;

/**
 * __useSendKycLevel2Mutation__
 *
 * To run a mutation, you first call `useSendKycLevel2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendKycLevel2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendKycLevel2Mutation, { data, loading, error }] = useSendKycLevel2Mutation({
 *   variables: {
 *      identityFile: // value for 'identityFile'
 *      identitySecondaryFile: // value for 'identitySecondaryFile'
 *   },
 * });
 */
export function useSendKycLevel2Mutation(
  baseOptions?: Apollo.MutationHookOptions<SendKycLevel2Mutation, SendKycLevel2MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendKycLevel2Mutation, SendKycLevel2MutationVariables>(
    SendKycLevel2Document,
    options,
  );
}
export type SendKycLevel2MutationHookResult = ReturnType<typeof useSendKycLevel2Mutation>;
export type SendKycLevel2MutationResult = Apollo.MutationResult<SendKycLevel2Mutation>;
export type SendKycLevel2MutationOptions = Apollo.BaseMutationOptions<
  SendKycLevel2Mutation,
  SendKycLevel2MutationVariables
>;
export const SendKycLevel3Document = gql`
  mutation SendKycLevel3($documentType: KycPoaType!, $addressFile: Upload!) {
    kycLevel3Update(documentType: $documentType, addressFile: $addressFile) {
      ...KycFields
    }
  }
  ${KycFieldsFragmentDoc}
`;
export type SendKycLevel3MutationFn = Apollo.MutationFunction<
  SendKycLevel3Mutation,
  SendKycLevel3MutationVariables
>;

/**
 * __useSendKycLevel3Mutation__
 *
 * To run a mutation, you first call `useSendKycLevel3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendKycLevel3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendKycLevel3Mutation, { data, loading, error }] = useSendKycLevel3Mutation({
 *   variables: {
 *      documentType: // value for 'documentType'
 *      addressFile: // value for 'addressFile'
 *   },
 * });
 */
export function useSendKycLevel3Mutation(
  baseOptions?: Apollo.MutationHookOptions<SendKycLevel3Mutation, SendKycLevel3MutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendKycLevel3Mutation, SendKycLevel3MutationVariables>(
    SendKycLevel3Document,
    options,
  );
}
export type SendKycLevel3MutationHookResult = ReturnType<typeof useSendKycLevel3Mutation>;
export type SendKycLevel3MutationResult = Apollo.MutationResult<SendKycLevel3Mutation>;
export type SendKycLevel3MutationOptions = Apollo.BaseMutationOptions<
  SendKycLevel3Mutation,
  SendKycLevel3MutationVariables
>;
export const RequestTipOtpDocument = gql`
  mutation RequestTipOtp($data: TipSendInput!) {
    requestTipSendOtp(data: $data) {
      otpSentAt
    }
  }
`;
export type RequestTipOtpMutationFn = Apollo.MutationFunction<
  RequestTipOtpMutation,
  RequestTipOtpMutationVariables
>;

/**
 * __useRequestTipOtpMutation__
 *
 * To run a mutation, you first call `useRequestTipOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTipOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTipOtpMutation, { data, loading, error }] = useRequestTipOtpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequestTipOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestTipOtpMutation, RequestTipOtpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestTipOtpMutation, RequestTipOtpMutationVariables>(
    RequestTipOtpDocument,
    options,
  );
}
export type RequestTipOtpMutationHookResult = ReturnType<typeof useRequestTipOtpMutation>;
export type RequestTipOtpMutationResult = Apollo.MutationResult<RequestTipOtpMutation>;
export type RequestTipOtpMutationOptions = Apollo.BaseMutationOptions<
  RequestTipOtpMutation,
  RequestTipOtpMutationVariables
>;
export const SendTipDocument = gql`
  mutation SendTip($data: TipSendInput!) {
    tipSend(data: $data) {
      id
      currency
      amount
      chatRoom
      createdAt
    }
  }
`;
export type SendTipMutationFn = Apollo.MutationFunction<SendTipMutation, SendTipMutationVariables>;

/**
 * __useSendTipMutation__
 *
 * To run a mutation, you first call `useSendTipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTipMutation, { data, loading, error }] = useSendTipMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendTipMutation(
  baseOptions?: Apollo.MutationHookOptions<SendTipMutation, SendTipMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendTipMutation, SendTipMutationVariables>(SendTipDocument, options);
}
export type SendTipMutationHookResult = ReturnType<typeof useSendTipMutation>;
export type SendTipMutationResult = Apollo.MutationResult<SendTipMutation>;
export type SendTipMutationOptions = Apollo.BaseMutationOptions<
  SendTipMutation,
  SendTipMutationVariables
>;
export const UpdateRolloverProgressDocument = gql`
  mutation UpdateRolloverProgress {
    updateRolloverProgresses {
      progress
      currency
      unfinishedBets {
        game {
          name
          slug
          originalGame
        }
        fiatCurrency
      }
    }
  }
`;
export type UpdateRolloverProgressMutationFn = Apollo.MutationFunction<
  UpdateRolloverProgressMutation,
  UpdateRolloverProgressMutationVariables
>;

/**
 * __useUpdateRolloverProgressMutation__
 *
 * To run a mutation, you first call `useUpdateRolloverProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolloverProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolloverProgressMutation, { data, loading, error }] = useUpdateRolloverProgressMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdateRolloverProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRolloverProgressMutation,
    UpdateRolloverProgressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRolloverProgressMutation,
    UpdateRolloverProgressMutationVariables
  >(UpdateRolloverProgressDocument, options);
}
export type UpdateRolloverProgressMutationHookResult = ReturnType<
  typeof useUpdateRolloverProgressMutation
>;
export type UpdateRolloverProgressMutationResult =
  Apollo.MutationResult<UpdateRolloverProgressMutation>;
export type UpdateRolloverProgressMutationOptions = Apollo.BaseMutationOptions<
  UpdateRolloverProgressMutation,
  UpdateRolloverProgressMutationVariables
>;
export const VaultDepositDocument = gql`
  mutation VaultDeposit($data: VaultDepositInput!) {
    vaultDeposit(data: $data) {
      id
      type
      currency
      amount
      createdAt
    }
  }
`;
export type VaultDepositMutationFn = Apollo.MutationFunction<
  VaultDepositMutation,
  VaultDepositMutationVariables
>;

/**
 * __useVaultDepositMutation__
 *
 * To run a mutation, you first call `useVaultDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVaultDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vaultDepositMutation, { data, loading, error }] = useVaultDepositMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVaultDepositMutation(
  baseOptions?: Apollo.MutationHookOptions<VaultDepositMutation, VaultDepositMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VaultDepositMutation, VaultDepositMutationVariables>(
    VaultDepositDocument,
    options,
  );
}
export type VaultDepositMutationHookResult = ReturnType<typeof useVaultDepositMutation>;
export type VaultDepositMutationResult = Apollo.MutationResult<VaultDepositMutation>;
export type VaultDepositMutationOptions = Apollo.BaseMutationOptions<
  VaultDepositMutation,
  VaultDepositMutationVariables
>;
export const VaultWithdrawDocument = gql`
  mutation VaultWithdraw($data: VaultWithdrawInput!) {
    vaultWithdraw(data: $data) {
      id
      type
      currency
      amount
      createdAt
    }
  }
`;
export type VaultWithdrawMutationFn = Apollo.MutationFunction<
  VaultWithdrawMutation,
  VaultWithdrawMutationVariables
>;

/**
 * __useVaultWithdrawMutation__
 *
 * To run a mutation, you first call `useVaultWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVaultWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vaultWithdrawMutation, { data, loading, error }] = useVaultWithdrawMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVaultWithdrawMutation(
  baseOptions?: Apollo.MutationHookOptions<VaultWithdrawMutation, VaultWithdrawMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VaultWithdrawMutation, VaultWithdrawMutationVariables>(
    VaultWithdrawDocument,
    options,
  );
}
export type VaultWithdrawMutationHookResult = ReturnType<typeof useVaultWithdrawMutation>;
export type VaultWithdrawMutationResult = Apollo.MutationResult<VaultWithdrawMutation>;
export type VaultWithdrawMutationOptions = Apollo.BaseMutationOptions<
  VaultWithdrawMutation,
  VaultWithdrawMutationVariables
>;
export const VerifyEmailDocument = gql`
  mutation VerifyEmail($emailVerificationToken: String!) {
    verifyEmail(emailVerificationToken: $emailVerificationToken) {
      id
      emailVerifiedAt
    }
  }
`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      emailVerificationToken: // value for 'emailVerificationToken'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    VerifyEmailDocument,
    options,
  );
}
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailMutation,
  VerifyEmailMutationVariables
>;
export const WithdrawDocument = gql`
  mutation Withdraw($data: WithdrawalInput!) {
    withdraw(data: $data) {
      chainCurrency {
        currency
        chain
      }
      amount
      receiverAddress
      fee
    }
  }
`;
export type WithdrawMutationFn = Apollo.MutationFunction<
  WithdrawMutation,
  WithdrawMutationVariables
>;

/**
 * __useWithdrawMutation__
 *
 * To run a mutation, you first call `useWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawMutation, { data, loading, error }] = useWithdrawMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWithdrawMutation(
  baseOptions?: Apollo.MutationHookOptions<WithdrawMutation, WithdrawMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WithdrawMutation, WithdrawMutationVariables>(WithdrawDocument, options);
}
export type WithdrawMutationHookResult = ReturnType<typeof useWithdrawMutation>;
export type WithdrawMutationResult = Apollo.MutationResult<WithdrawMutation>;
export type WithdrawMutationOptions = Apollo.BaseMutationOptions<
  WithdrawMutation,
  WithdrawMutationVariables
>;
export const SportBetCashOutDocument = gql`
  mutation SportBetCashOut($data: CashoutSportsBetInput!) {
    cashoutSportsBet(data: $data) {
      id
      currency
      amount
      totalOddsDecimal
      status
      selections {
        id
        oddsNumerator
        oddsDenominator
        status
        snapshot {
          ...SportBetSelectionSnapshotFields
        }
      }
      createdAt
    }
  }
  ${SportBetSelectionSnapshotFieldsFragmentDoc}
`;
export type SportBetCashOutMutationFn = Apollo.MutationFunction<
  SportBetCashOutMutation,
  SportBetCashOutMutationVariables
>;

/**
 * __useSportBetCashOutMutation__
 *
 * To run a mutation, you first call `useSportBetCashOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSportBetCashOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sportBetCashOutMutation, { data, loading, error }] = useSportBetCashOutMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSportBetCashOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SportBetCashOutMutation,
    SportBetCashOutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SportBetCashOutMutation, SportBetCashOutMutationVariables>(
    SportBetCashOutDocument,
    options,
  );
}
export type SportBetCashOutMutationHookResult = ReturnType<typeof useSportBetCashOutMutation>;
export type SportBetCashOutMutationResult = Apollo.MutationResult<SportBetCashOutMutation>;
export type SportBetCashOutMutationOptions = Apollo.BaseMutationOptions<
  SportBetCashOutMutation,
  SportBetCashOutMutationVariables
>;
export const UpdatePreferencesDocument = gql`
  mutation updatePreferences($data: UpdateUserPreferenceInput!) {
    userUpdatePreference(data: $data) {
      id
      anonymous
      avatar
      avatarBackground
    }
  }
`;
export type UpdatePreferencesMutationFn = Apollo.MutationFunction<
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables
>;

/**
 * __useUpdatePreferencesMutation__
 *
 * To run a mutation, you first call `useUpdatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferencesMutation, { data, loading, error }] = useUpdatePreferencesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePreferencesMutation,
    UpdatePreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>(
    UpdatePreferencesDocument,
    options,
  );
}
export type UpdatePreferencesMutationHookResult = ReturnType<typeof useUpdatePreferencesMutation>;
export type UpdatePreferencesMutationResult = Apollo.MutationResult<UpdatePreferencesMutation>;
export type UpdatePreferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables
>;
export const BalanceUpdatedDocument = gql`
  subscription BalanceUpdated {
    balanceUpdated {
      currency
      amount
    }
  }
`;

/**
 * __useBalanceUpdatedSubscription__
 *
 * To run a query within a React component, call `useBalanceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBalanceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBalanceUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    BalanceUpdatedSubscription,
    BalanceUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<BalanceUpdatedSubscription, BalanceUpdatedSubscriptionVariables>(
    BalanceUpdatedDocument,
    options,
  );
}
export type BalanceUpdatedSubscriptionHookResult = ReturnType<typeof useBalanceUpdatedSubscription>;
export type BalanceUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<BalanceUpdatedSubscription>;
export const CrashGameBetPayoutDocument = gql`
  subscription CrashGameBetPayout {
    crashBetPayoutEvent {
      ...CrashBetPayoutEventFields
    }
  }
  ${CrashBetPayoutEventFieldsFragmentDoc}
`;

/**
 * __useCrashGameBetPayoutSubscription__
 *
 * To run a query within a React component, call `useCrashGameBetPayoutSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCrashGameBetPayoutSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrashGameBetPayoutSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCrashGameBetPayoutSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CrashGameBetPayoutSubscription,
    CrashGameBetPayoutSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CrashGameBetPayoutSubscription,
    CrashGameBetPayoutSubscriptionVariables
  >(CrashGameBetPayoutDocument, options);
}
export type CrashGameBetPayoutSubscriptionHookResult = ReturnType<
  typeof useCrashGameBetPayoutSubscription
>;
export type CrashGameBetPayoutSubscriptionResult =
  Apollo.SubscriptionResult<CrashGameBetPayoutSubscription>;
export const CrashGameMyPayoutDocument = gql`
  subscription CrashGameMyPayout {
    myCrashBetPayoutEvent {
      ...CrashBetPayoutEventFields
    }
  }
  ${CrashBetPayoutEventFieldsFragmentDoc}
`;

/**
 * __useCrashGameMyPayoutSubscription__
 *
 * To run a query within a React component, call `useCrashGameMyPayoutSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCrashGameMyPayoutSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrashGameMyPayoutSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCrashGameMyPayoutSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CrashGameMyPayoutSubscription,
    CrashGameMyPayoutSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CrashGameMyPayoutSubscription,
    CrashGameMyPayoutSubscriptionVariables
  >(CrashGameMyPayoutDocument, options);
}
export type CrashGameMyPayoutSubscriptionHookResult = ReturnType<
  typeof useCrashGameMyPayoutSubscription
>;
export type CrashGameMyPayoutSubscriptionResult =
  Apollo.SubscriptionResult<CrashGameMyPayoutSubscription>;
export const CrashGameUpdateDocument = gql`
  subscription CrashGameUpdate {
    crashEventUpdate {
      status
      elapsed
      currentPoint
      crashPoint
      startedAt
      nextRoundIn
    }
  }
`;

/**
 * __useCrashGameUpdateSubscription__
 *
 * To run a query within a React component, call `useCrashGameUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCrashGameUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrashGameUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCrashGameUpdateSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CrashGameUpdateSubscription,
    CrashGameUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<CrashGameUpdateSubscription, CrashGameUpdateSubscriptionVariables>(
    CrashGameUpdateDocument,
    options,
  );
}
export type CrashGameUpdateSubscriptionHookResult = ReturnType<
  typeof useCrashGameUpdateSubscription
>;
export type CrashGameUpdateSubscriptionResult =
  Apollo.SubscriptionResult<CrashGameUpdateSubscription>;
export const DepositPendingDocument = gql`
  subscription DepositPending {
    depositPending {
      depositId
      currency
      amount
    }
  }
`;

/**
 * __useDepositPendingSubscription__
 *
 * To run a query within a React component, call `useDepositPendingSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDepositPendingSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositPendingSubscription({
 *   variables: {
 *   },
 * });
 */
export function useDepositPendingSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    DepositPendingSubscription,
    DepositPendingSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<DepositPendingSubscription, DepositPendingSubscriptionVariables>(
    DepositPendingDocument,
    options,
  );
}
export type DepositPendingSubscriptionHookResult = ReturnType<typeof useDepositPendingSubscription>;
export type DepositPendingSubscriptionResult =
  Apollo.SubscriptionResult<DepositPendingSubscription>;
export const DepositCompletedDocument = gql`
  subscription DepositCompleted {
    depositCompleted {
      depositId
      currency
      amount
    }
  }
`;

/**
 * __useDepositCompletedSubscription__
 *
 * To run a query within a React component, call `useDepositCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDepositCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositCompletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useDepositCompletedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    DepositCompletedSubscription,
    DepositCompletedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    DepositCompletedSubscription,
    DepositCompletedSubscriptionVariables
  >(DepositCompletedDocument, options);
}
export type DepositCompletedSubscriptionHookResult = ReturnType<
  typeof useDepositCompletedSubscription
>;
export type DepositCompletedSubscriptionResult =
  Apollo.SubscriptionResult<DepositCompletedSubscription>;
export const EmailVerifiedDocument = gql`
  subscription EmailVerified {
    emailVerified {
      id
      emailVerifiedAt
    }
  }
`;

/**
 * __useEmailVerifiedSubscription__
 *
 * To run a query within a React component, call `useEmailVerifiedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerifiedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerifiedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useEmailVerifiedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    EmailVerifiedSubscription,
    EmailVerifiedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<EmailVerifiedSubscription, EmailVerifiedSubscriptionVariables>(
    EmailVerifiedDocument,
    options,
  );
}
export type EmailVerifiedSubscriptionHookResult = ReturnType<typeof useEmailVerifiedSubscription>;
export type EmailVerifiedSubscriptionResult = Apollo.SubscriptionResult<EmailVerifiedSubscription>;
export const GameResultDocument = gql`
  subscription GameResult {
    gameResult {
      currency
      betAmount
      payout
    }
  }
`;

/**
 * __useGameResultSubscription__
 *
 * To run a query within a React component, call `useGameResultSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGameResultSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameResultSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGameResultSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GameResultSubscription,
    GameResultSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<GameResultSubscription, GameResultSubscriptionVariables>(
    GameResultDocument,
    options,
  );
}
export type GameResultSubscriptionHookResult = ReturnType<typeof useGameResultSubscription>;
export type GameResultSubscriptionResult = Apollo.SubscriptionResult<GameResultSubscription>;
export const HighRollerBetUpdatedDocument = gql`
  subscription HighRollerBetUpdated {
    highRollerBetUpdated {
      ...BetActivityFields
    }
  }
  ${BetActivityFieldsFragmentDoc}
`;

/**
 * __useHighRollerBetUpdatedSubscription__
 *
 * To run a query within a React component, call `useHighRollerBetUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHighRollerBetUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighRollerBetUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useHighRollerBetUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    HighRollerBetUpdatedSubscription,
    HighRollerBetUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    HighRollerBetUpdatedSubscription,
    HighRollerBetUpdatedSubscriptionVariables
  >(HighRollerBetUpdatedDocument, options);
}
export type HighRollerBetUpdatedSubscriptionHookResult = ReturnType<
  typeof useHighRollerBetUpdatedSubscription
>;
export type HighRollerBetUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<HighRollerBetUpdatedSubscription>;
export const HighRollerSportsBetUpdatedDocument = gql`
  subscription HighRollerSportsBetUpdated {
    highRollerSportsBetUpdated {
      ...SportsBetActivityFields
    }
  }
  ${SportsBetActivityFieldsFragmentDoc}
`;

/**
 * __useHighRollerSportsBetUpdatedSubscription__
 *
 * To run a query within a React component, call `useHighRollerSportsBetUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHighRollerSportsBetUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighRollerSportsBetUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useHighRollerSportsBetUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    HighRollerSportsBetUpdatedSubscription,
    HighRollerSportsBetUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    HighRollerSportsBetUpdatedSubscription,
    HighRollerSportsBetUpdatedSubscriptionVariables
  >(HighRollerSportsBetUpdatedDocument, options);
}
export type HighRollerSportsBetUpdatedSubscriptionHookResult = ReturnType<
  typeof useHighRollerSportsBetUpdatedSubscription
>;
export type HighRollerSportsBetUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<HighRollerSportsBetUpdatedSubscription>;
export const LatestSportsBetUpdatedDocument = gql`
  subscription LatestSportsBetUpdated {
    latestSportsBetUpdated {
      ...SportsBetActivityFields
    }
  }
  ${SportsBetActivityFieldsFragmentDoc}
`;

/**
 * __useLatestSportsBetUpdatedSubscription__
 *
 * To run a query within a React component, call `useLatestSportsBetUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLatestSportsBetUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestSportsBetUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLatestSportsBetUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    LatestSportsBetUpdatedSubscription,
    LatestSportsBetUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LatestSportsBetUpdatedSubscription,
    LatestSportsBetUpdatedSubscriptionVariables
  >(LatestSportsBetUpdatedDocument, options);
}
export type LatestSportsBetUpdatedSubscriptionHookResult = ReturnType<
  typeof useLatestSportsBetUpdatedSubscription
>;
export type LatestSportsBetUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<LatestSportsBetUpdatedSubscription>;
export const LatestBetUpdatedDocument = gql`
  subscription LatestBetUpdated {
    latestBetUpdated {
      ...BetActivityFields
    }
  }
  ${BetActivityFieldsFragmentDoc}
`;

/**
 * __useLatestBetUpdatedSubscription__
 *
 * To run a query within a React component, call `useLatestBetUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLatestBetUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestBetUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLatestBetUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    LatestBetUpdatedSubscription,
    LatestBetUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    LatestBetUpdatedSubscription,
    LatestBetUpdatedSubscriptionVariables
  >(LatestBetUpdatedDocument, options);
}
export type LatestBetUpdatedSubscriptionHookResult = ReturnType<
  typeof useLatestBetUpdatedSubscription
>;
export type LatestBetUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<LatestBetUpdatedSubscription>;
export const RaceLeaderboardUpdatedDocument = gql`
  subscription RaceLeaderboardUpdated {
    raceLeaderboardUpdated {
      leaderboard {
        ...LeaderboardFields
      }
    }
  }
  ${LeaderboardFieldsFragmentDoc}
`;

/**
 * __useRaceLeaderboardUpdatedSubscription__
 *
 * To run a query within a React component, call `useRaceLeaderboardUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRaceLeaderboardUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRaceLeaderboardUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRaceLeaderboardUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    RaceLeaderboardUpdatedSubscription,
    RaceLeaderboardUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RaceLeaderboardUpdatedSubscription,
    RaceLeaderboardUpdatedSubscriptionVariables
  >(RaceLeaderboardUpdatedDocument, options);
}
export type RaceLeaderboardUpdatedSubscriptionHookResult = ReturnType<
  typeof useRaceLeaderboardUpdatedSubscription
>;
export type RaceLeaderboardUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<RaceLeaderboardUpdatedSubscription>;
export const RaceWagerUpdatedDocument = gql`
  subscription RaceWagerUpdated {
    raceWagerUpdated {
      vipLevel
      username
      wagered
      raceId
      raceEntryId
    }
  }
`;

/**
 * __useRaceWagerUpdatedSubscription__
 *
 * To run a query within a React component, call `useRaceWagerUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRaceWagerUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRaceWagerUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRaceWagerUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    RaceWagerUpdatedSubscription,
    RaceWagerUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RaceWagerUpdatedSubscription,
    RaceWagerUpdatedSubscriptionVariables
  >(RaceWagerUpdatedDocument, options);
}
export type RaceWagerUpdatedSubscriptionHookResult = ReturnType<
  typeof useRaceWagerUpdatedSubscription
>;
export type RaceWagerUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<RaceWagerUpdatedSubscription>;
export const MySportsBetUpdatedDocument = gql`
  subscription MySportsBetUpdated {
    mySportsBetUpdated {
      ...SportBetFields
    }
  }
  ${SportBetFieldsFragmentDoc}
`;

/**
 * __useMySportsBetUpdatedSubscription__
 *
 * To run a query within a React component, call `useMySportsBetUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMySportsBetUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySportsBetUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMySportsBetUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MySportsBetUpdatedSubscription,
    MySportsBetUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MySportsBetUpdatedSubscription,
    MySportsBetUpdatedSubscriptionVariables
  >(MySportsBetUpdatedDocument, options);
}
export type MySportsBetUpdatedSubscriptionHookResult = ReturnType<
  typeof useMySportsBetUpdatedSubscription
>;
export type MySportsBetUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<MySportsBetUpdatedSubscription>;
export const RewardUpdatedDocument = gql`
  subscription RewardUpdated {
    rewardUpdated {
      rewardEvent
    }
  }
`;

/**
 * __useRewardUpdatedSubscription__
 *
 * To run a query within a React component, call `useRewardUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRewardUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRewardUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    RewardUpdatedSubscription,
    RewardUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<RewardUpdatedSubscription, RewardUpdatedSubscriptionVariables>(
    RewardUpdatedDocument,
    options,
  );
}
export type RewardUpdatedSubscriptionHookResult = ReturnType<typeof useRewardUpdatedSubscription>;
export type RewardUpdatedSubscriptionResult = Apollo.SubscriptionResult<RewardUpdatedSubscription>;
export const MyBetUpdatedDocument = gql`
  subscription MyBetUpdated {
    myBetUpdated {
      ...BetFields
    }
  }
  ${BetFieldsFragmentDoc}
`;

/**
 * __useMyBetUpdatedSubscription__
 *
 * To run a query within a React component, call `useMyBetUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMyBetUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBetUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMyBetUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    MyBetUpdatedSubscription,
    MyBetUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<MyBetUpdatedSubscription, MyBetUpdatedSubscriptionVariables>(
    MyBetUpdatedDocument,
    options,
  );
}
export type MyBetUpdatedSubscriptionHookResult = ReturnType<typeof useMyBetUpdatedSubscription>;
export type MyBetUpdatedSubscriptionResult = Apollo.SubscriptionResult<MyBetUpdatedSubscription>;
export const SportsCategoryUpdatedDocument = gql`
  subscription SportsCategoryUpdated($categoryIds: [String!]) {
    sportsCategoryUpdated(categoryIds: $categoryIds) {
      id
      disabled
    }
  }
`;

/**
 * __useSportsCategoryUpdatedSubscription__
 *
 * To run a query within a React component, call `useSportsCategoryUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSportsCategoryUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsCategoryUpdatedSubscription({
 *   variables: {
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useSportsCategoryUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SportsCategoryUpdatedSubscription,
    SportsCategoryUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SportsCategoryUpdatedSubscription,
    SportsCategoryUpdatedSubscriptionVariables
  >(SportsCategoryUpdatedDocument, options);
}
export type SportsCategoryUpdatedSubscriptionHookResult = ReturnType<
  typeof useSportsCategoryUpdatedSubscription
>;
export type SportsCategoryUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<SportsCategoryUpdatedSubscription>;
export const SportsFixtureUpdatedDocument = gql`
  subscription SportsFixtureUpdated($fixtureIds: [String!]) {
    sportsFixtureUpdated(fixtureIds: $fixtureIds) {
      id
      disabled
      fixtureStatus: status
    }
  }
`;

/**
 * __useSportsFixtureUpdatedSubscription__
 *
 * To run a query within a React component, call `useSportsFixtureUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSportsFixtureUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsFixtureUpdatedSubscription({
 *   variables: {
 *      fixtureIds: // value for 'fixtureIds'
 *   },
 * });
 */
export function useSportsFixtureUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SportsFixtureUpdatedSubscription,
    SportsFixtureUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SportsFixtureUpdatedSubscription,
    SportsFixtureUpdatedSubscriptionVariables
  >(SportsFixtureUpdatedDocument, options);
}
export type SportsFixtureUpdatedSubscriptionHookResult = ReturnType<
  typeof useSportsFixtureUpdatedSubscription
>;
export type SportsFixtureUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<SportsFixtureUpdatedSubscription>;
export const TipReceivedDocument = gql`
  subscription tipReceived {
    tipReceived {
      senderUsername
      tipType
      currency
      amount
    }
  }
`;

/**
 * __useTipReceivedSubscription__
 *
 * To run a query within a React component, call `useTipReceivedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTipReceivedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipReceivedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTipReceivedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TipReceivedSubscription,
    TipReceivedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TipReceivedSubscription, TipReceivedSubscriptionVariables>(
    TipReceivedDocument,
    options,
  );
}
export type TipReceivedSubscriptionHookResult = ReturnType<typeof useTipReceivedSubscription>;
export type TipReceivedSubscriptionResult = Apollo.SubscriptionResult<TipReceivedSubscription>;
export const SportsMarketSelectionUpdatedDocument = gql`
  subscription SportsMarketSelectionUpdated($marketIds: [String!], $fixtureIds: [String!]) {
    sportsMarketSelectionUpdated(marketIds: $marketIds, fixtureIds: $fixtureIds) {
      id
      fixtureId
      marketId
      oddsNumerator
      oddsDenominator
    }
  }
`;

/**
 * __useSportsMarketSelectionUpdatedSubscription__
 *
 * To run a query within a React component, call `useSportsMarketSelectionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSportsMarketSelectionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsMarketSelectionUpdatedSubscription({
 *   variables: {
 *      marketIds: // value for 'marketIds'
 *      fixtureIds: // value for 'fixtureIds'
 *   },
 * });
 */
export function useSportsMarketSelectionUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SportsMarketSelectionUpdatedSubscription,
    SportsMarketSelectionUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SportsMarketSelectionUpdatedSubscription,
    SportsMarketSelectionUpdatedSubscriptionVariables
  >(SportsMarketSelectionUpdatedDocument, options);
}
export type SportsMarketSelectionUpdatedSubscriptionHookResult = ReturnType<
  typeof useSportsMarketSelectionUpdatedSubscription
>;
export type SportsMarketSelectionUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<SportsMarketSelectionUpdatedSubscription>;
export const SportsCompetitionUpdatedDocument = gql`
  subscription SportsCompetitionUpdated($competitionIds: [String!]) {
    sportsCompetitionUpdated(competitionIds: $competitionIds) {
      id
      disabled
    }
  }
`;

/**
 * __useSportsCompetitionUpdatedSubscription__
 *
 * To run a query within a React component, call `useSportsCompetitionUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSportsCompetitionUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsCompetitionUpdatedSubscription({
 *   variables: {
 *      competitionIds: // value for 'competitionIds'
 *   },
 * });
 */
export function useSportsCompetitionUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SportsCompetitionUpdatedSubscription,
    SportsCompetitionUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SportsCompetitionUpdatedSubscription,
    SportsCompetitionUpdatedSubscriptionVariables
  >(SportsCompetitionUpdatedDocument, options);
}
export type SportsCompetitionUpdatedSubscriptionHookResult = ReturnType<
  typeof useSportsCompetitionUpdatedSubscription
>;
export type SportsCompetitionUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<SportsCompetitionUpdatedSubscription>;
export const VipLevelDocument = gql`
  subscription vipLevel {
    vipLevelUpdated {
      vipLevel
      xp
      wagered
    }
  }
`;

/**
 * __useVipLevelSubscription__
 *
 * To run a query within a React component, call `useVipLevelSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVipLevelSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVipLevelSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVipLevelSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<VipLevelSubscription, VipLevelSubscriptionVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<VipLevelSubscription, VipLevelSubscriptionVariables>(
    VipLevelDocument,
    options,
  );
}
export type VipLevelSubscriptionHookResult = ReturnType<typeof useVipLevelSubscription>;
export type VipLevelSubscriptionResult = Apollo.SubscriptionResult<VipLevelSubscription>;
export const VipRewardsUpdatedDocument = gql`
  subscription VipRewardsUpdated {
    vipRewardsUpdated
  }
`;

/**
 * __useVipRewardsUpdatedSubscription__
 *
 * To run a query within a React component, call `useVipRewardsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useVipRewardsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVipRewardsUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useVipRewardsUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    VipRewardsUpdatedSubscription,
    VipRewardsUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    VipRewardsUpdatedSubscription,
    VipRewardsUpdatedSubscriptionVariables
  >(VipRewardsUpdatedDocument, options);
}
export type VipRewardsUpdatedSubscriptionHookResult = ReturnType<
  typeof useVipRewardsUpdatedSubscription
>;
export type VipRewardsUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<VipRewardsUpdatedSubscription>;
export const WithdrawalCompletedDocument = gql`
  subscription WithdrawalCompleted {
    withdrawalCompleted {
      withdrawalId
      currency
      amount
    }
  }
`;

/**
 * __useWithdrawalCompletedSubscription__
 *
 * To run a query within a React component, call `useWithdrawalCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithdrawalCompletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWithdrawalCompletedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    WithdrawalCompletedSubscription,
    WithdrawalCompletedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WithdrawalCompletedSubscription,
    WithdrawalCompletedSubscriptionVariables
  >(WithdrawalCompletedDocument, options);
}
export type WithdrawalCompletedSubscriptionHookResult = ReturnType<
  typeof useWithdrawalCompletedSubscription
>;
export type WithdrawalCompletedSubscriptionResult =
  Apollo.SubscriptionResult<WithdrawalCompletedSubscription>;
export const SportsMatchSummaryUpdatedDocument = gql`
  subscription sportsMatchSummaryUpdated($fixtureIds: [String!]!) {
    sportsMatchSummaryUpdated(fixtureIds: $fixtureIds) {
      id
      fixtureId
      matchPhase
      currentPhaseName
      homeScore
      awayScore
      server
      phases {
        phase
        homeScore
        awayScore
        status
      }
      timeRemaining
      timeRemainingAt
    }
  }
`;

/**
 * __useSportsMatchSummaryUpdatedSubscription__
 *
 * To run a query within a React component, call `useSportsMatchSummaryUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSportsMatchSummaryUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsMatchSummaryUpdatedSubscription({
 *   variables: {
 *      fixtureIds: // value for 'fixtureIds'
 *   },
 * });
 */
export function useSportsMatchSummaryUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    SportsMatchSummaryUpdatedSubscription,
    SportsMatchSummaryUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SportsMatchSummaryUpdatedSubscription,
    SportsMatchSummaryUpdatedSubscriptionVariables
  >(SportsMatchSummaryUpdatedDocument, options);
}
export type SportsMatchSummaryUpdatedSubscriptionHookResult = ReturnType<
  typeof useSportsMatchSummaryUpdatedSubscription
>;
export type SportsMatchSummaryUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<SportsMatchSummaryUpdatedSubscription>;
export const SportsMarketUpdatedDocument = gql`
  subscription SportsMarketUpdated($marketIds: [String!], $fixtureIds: [String!]) {
    sportsMarketUpdated(marketIds: $marketIds, fixtureIds: $fixtureIds) {
      id
      fixtureId
      marketStatus: status
      inPlay
      disabled
      cashoutEnabled
      cashoutStatus
    }
  }
`;

/**
 * __useSportsMarketUpdatedSubscription__
 *
 * To run a query within a React component, call `useSportsMarketUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSportsMarketUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsMarketUpdatedSubscription({
 *   variables: {
 *      marketIds: // value for 'marketIds'
 *      fixtureIds: // value for 'fixtureIds'
 *   },
 * });
 */
export function useSportsMarketUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SportsMarketUpdatedSubscription,
    SportsMarketUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SportsMarketUpdatedSubscription,
    SportsMarketUpdatedSubscriptionVariables
  >(SportsMarketUpdatedDocument, options);
}
export type SportsMarketUpdatedSubscriptionHookResult = ReturnType<
  typeof useSportsMarketUpdatedSubscription
>;
export type SportsMarketUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<SportsMarketUpdatedSubscription>;
