import React from 'react';

import { SvgProps } from '../..';

const TableTennis = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g id="Property 1=table-tennis-02">
        <g id="Group">
          <path
            id="Vector"
            d="M6.41406 8.26196L15.0451 16.893"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M20.8494 10.4999C21.133 9.38942 21.1204 8.224 20.813 7.11989C20.5056 6.01577 19.9141 5.01155 19.0974 4.2074C16.3974 1.5074 12.0639 1.4759 8.63491 4.9049C5.83291 7.7099 5.34841 11.0249 6.66691 13.6199L1.11691 18.1649C1.01037 18.2523 0.923315 18.361 0.861338 18.4841C0.799361 18.6071 0.763838 18.7418 0.757056 18.8794C0.750274 19.0171 0.772382 19.1546 0.821959 19.2831C0.871536 19.4117 0.947482 19.5285 1.04491 19.6259L3.68791 22.2689C3.78535 22.3663 3.9021 22.4423 4.03067 22.4919C4.15923 22.5414 4.29675 22.5635 4.43437 22.5568C4.57199 22.55 4.70667 22.5145 4.82973 22.4525C4.9528 22.3905 5.06152 22.3034 5.14891 22.1969L9.69391 16.6469C10.6373 17.1154 11.6778 17.3552 12.7311 17.3468C13.7845 17.3385 14.821 17.0823 15.7569 16.5989"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M19.5 20.25C21.5711 20.25 23.25 18.5711 23.25 16.5C23.25 14.4289 21.5711 12.75 19.5 12.75C17.4289 12.75 15.75 14.4289 15.75 16.5C15.75 18.5711 17.4289 20.25 19.5 20.25Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default TableTennis;
