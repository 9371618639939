import { useMemo } from 'react';
import { captureMessage } from '@sentry/nextjs';
import { clientWithoutAuth } from 'apollo/clients';
import { useGetGlobalDataQuery, VipLevel } from 'generated/graphql';
import { useMyProfile } from 'hooks/useMyProfile';

import useVipLevel from './useVipLevel';

export type VipLevelParams = {
  icon: string;
};

/**
 * Grab VIP progress for the current user.
 * For now this doesn't concern itself with other users.
 *
 * @returns `current` - `VipLevelParams` - Current VIP level
 * @returns `next` - `VipLevelParams` - Next VIP level
 * @returns `progress` - Number between 0 and 100 - Percentage progress until `next`.
 */
const useVip = () => {
  const { myProfile, loading: profileLoading } = useMyProfile();
  const { data: globalData, loading: levelsLoading } = useGetGlobalDataQuery({
    client: clientWithoutAuth,
    fetchPolicy: 'cache-and-network',
  });

  /**
   * Get progress from current level to the next.
   * Progress is 100% when users are at the final level.
   * Also calculates the next level.
   */
  const properties = useMemo(() => {
    const _nullish = { progress: 0, next: VipLevel.BRONZE_1 };

    // After loading, we expect levelsData to always exist.
    if (profileLoading || levelsLoading || !globalData) {
      return _nullish;
    }

    const wagered = myProfile?.xp ?? 0;

    // Find current and next levels in levelsData
    const nextLevelIdx = globalData.vipLevels.findIndex(x => wagered < Number(x.amount));
    const _currentLevel = globalData.vipLevels[nextLevelIdx - 1] ?? null;
    const _nextLevel = globalData.vipLevels[nextLevelIdx] ?? null;
    let progressAsPercent = 0;

    // Unranked - next is BRONZE.
    if (!_currentLevel) {
      progressAsPercent = 100 * (wagered / Number(globalData.vipLevels[1].amount));

      return {
        progress: progressAsPercent,
        next: _nextLevel?.level,
      };
    }

    // This must be the final level; display 100%
    if (!_nextLevel) {
      return {
        progress: 100,
        next: null,
      };
    }

    const levelGap = Number(_nextLevel.amount) - Number(_currentLevel.amount);
    const amountOverLevel = wagered - Number(_currentLevel.amount);

    // Progress is progress to NEXT level
    const progressAsDecimal = amountOverLevel / levelGap;
    progressAsPercent = 100 * progressAsDecimal;

    if (isNaN(progressAsDecimal) || !isFinite(progressAsDecimal)) {
      captureMessage('Malformed Vip Progress Calculation: USE_VIP', 'error');
      return _nullish;
    }

    return {
      next: _nextLevel.level,
      progress: progressAsPercent,
    };
  }, [profileLoading, levelsLoading, globalData, myProfile?.xp]);

  const current = useVipLevel(myProfile?.vipLevel);
  const next = useVipLevel(properties.next || myProfile?.vipLevel);

  return {
    current,
    next,
    progress: properties.progress,
    levelsData: globalData,
  };
};

export default useVip;
