import { ReactNode } from 'react';
import noop from 'lodash/noop';

import { ModalWrapper } from './ModalWrapper';
import { ModalContainer, ModalOverlay } from './styles';

interface RawModalProps {
  closeHandler: () => void;
  children: ReactNode;
  allowClickOutside?: boolean;
}

const RawModal = ({ closeHandler, children, allowClickOutside = true }: RawModalProps) => {
  return (
    <ModalWrapper>
      <ModalContainer>
        <ModalOverlay onClick={allowClickOutside ? closeHandler : noop} animate={{ opacity: 1 }} />
        {children}
      </ModalContainer>
    </ModalWrapper>
  );
};

export default RawModal;
