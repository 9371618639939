import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SportsMarketStatus } from 'generated/graphql';
import { useTranslation } from 'next-i18next';
import { closeModal, openGeneralModal } from 'redux/slices/modalSlice';
import {
  BetSlipItem,
  BetSlipStatus,
  changeBetSlipDropdown,
  changeBetSlipStatus,
  MultiBet,
  resetBetSlip,
  setBetSlip,
  setBetSlipPlacedError,
  SportsBetState,
  updateMultiBet,
} from 'redux/slices/sportsBetSlice';
import { calculateEstimatePayout } from 'utils/calculateSportsBet';

import { checkMultiBet } from '../utils/checkMultiBet';
import { MAX_NUMBER_OF_BETS } from '../views/BetSlip/components/BetSlip';

export const useSportsBetValue = () => {
  return useSelector((state: AppState) => state.sportsBet, shallowEqual);
};

export const useSportsBet = () => {
  const {
    betSlips,
    betSlipStatus,
    multiBet: { selectionIds },
  } = useSelector((state: AppState) => state.sportsBet, shallowEqual);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateMultiBetAction = useCallback(
    (multiBet: Partial<MultiBet>) => dispatch(updateMultiBet(multiBet)),
    [dispatch],
  );

  const updateSelectionIds = useCallback(
    (id: string) =>
      updateMultiBetAction({
        selectionIds: [...new Set([...selectionIds, id])].slice(0, MAX_NUMBER_OF_BETS),
      }),
    [selectionIds, updateMultiBetAction],
  );

  const addSportsBet = useCallback(
    (selection: BetSlipItem) => {
      if (betSlips.length === MAX_NUMBER_OF_BETS) {
        const props = {
          header: t('txtMaxBetsReached'),
          body: t('txtMaxBetsReachedDescription', { maxBets: MAX_NUMBER_OF_BETS }),
          confirmBtnText: t('btnDone'),
          onConfirm: () => dispatch(closeModal()),
        };
        dispatch(openGeneralModal({ props }));
        return;
      }

      const placedBet = betSlipStatus === BetSlipStatus.BET_PLACED;
      const currentBetSlip = betSlips;

      if (betSlipStatus === BetSlipStatus.BET_PLACED) {
        dispatch(resetBetSlip());
        dispatch(changeBetSlipStatus({ betSlipStatus: BetSlipStatus.ADDING_BET }));
        dispatch(
          updateMultiBet({
            selectionIds: [selection.id],
          }),
        );
      } else {
        updateSelectionIds(selection.id);
      }

      if (!currentBetSlip.length || betSlipStatus === BetSlipStatus.CONFIRMING_BET) {
        dispatch(changeBetSlipStatus({ betSlipStatus: BetSlipStatus.ADDING_BET }));
        dispatch(setBetSlipPlacedError({ errors: [] }));
      }

      if (
        betSlipStatus === BetSlipStatus.RESULTED_BET ||
        betSlipStatus === BetSlipStatus.PENDING_BET
      ) {
        dispatch(
          changeBetSlipDropdown({
            betSlipOption: BetSlipStatus.ADDING_BET,
          }),
        );
      }

      const betSlipItem = {
        ...selection,
        betAmount: '',
        estPayout: '',
      };
      const newBetSlip = [...(placedBet ? [] : currentBetSlip), betSlipItem];

      if (
        !checkMultiBet({
          betSlipData: newBetSlip,
        }) &&
        newBetSlip.length > 1
      ) {
        dispatch(
          updateMultiBet({
            selectionIds: [],
            amount: '',
          }),
        );
      }
      dispatch(setBetSlip({ betSlips: newBetSlip }));
    },

    [betSlipStatus, betSlips, dispatch, t, updateSelectionIds],
  );

  const updateBetSlipMarketStatus = useCallback(
    (params: {
      categoryId?: string;
      competitionId?: string;
      fixtureId?: string;
      marketId?: string;
      marketStatus: SportsMarketStatus;
      inPlay?: boolean;
    }) => {
      const { categoryId, competitionId, fixtureId, marketId, marketStatus, inPlay } = params;
      const updateId = categoryId || competitionId || fixtureId || marketId || '';
      const shouldUpdateBetSlip = betSlips.some(
        item =>
          item.categoryId === updateId ||
          item.competitionId === updateId ||
          item.fixtureId === updateId ||
          item.marketId === updateId,
      );

      if (!shouldUpdateBetSlip) {
        return;
      }

      const newBetSlips = betSlips.map(item => {
        if (
          item.categoryId === updateId ||
          item.competitionId === updateId ||
          item.fixtureId === updateId ||
          item.marketId === updateId
        ) {
          return {
            ...item,
            inPlay: inPlay || item.inPlay,
            marketStatus,
          };
        }
        return item;
      });
      dispatch(setBetSlip({ betSlips: newBetSlips }));
    },
    [betSlips, dispatch],
  );

  const removeSportBet = useCallback(
    (selection: BetSlipItem) => {
      const newBetSlip = betSlips.filter(item => item.id !== selection.id);
      dispatch(setBetSlip({ betSlips: newBetSlip }));

      if (newBetSlip.length === 0) {
        dispatch(changeBetSlipStatus({ betSlipStatus: BetSlipStatus.EMPTY }));
      }

      if (selection.id) {
        const updateSelectIds = selectionIds.filter(id => id !== selection.id);
        updateMultiBetAction({
          selectionIds: updateSelectIds,
        });
      }

      if (
        !checkMultiBet({
          betSlipData: newBetSlip,
        })
      ) {
        dispatch(
          updateMultiBet({
            selectionIds: [],
            amount: '',
          }),
        );
      }
    },
    [betSlips, dispatch, selectionIds, updateMultiBetAction],
  );

  const clearBetSlip = useCallback(() => {
    dispatch(resetBetSlip());
  }, [dispatch]);

  const updateBetAmount = useCallback(
    (betAmount: string, selection: BetSlipItem) => {
      const newBetSlips = betSlips.map(item => {
        if (item.id === selection.id) {
          return {
            ...item,
            betAmount: betAmount,
            estPayout: calculateEstimatePayout({
              betAmount,
              oddsNumerator: item.oddsNumerator,
              oddsDenominator: item.oddsDenominator,
            }),
          };
        }
        return item;
      });

      dispatch(setBetSlip({ betSlips: newBetSlips }));
    },
    [betSlips, dispatch],
  );

  const updateBetSlipStatus = useCallback(
    (betSlipStatus: BetSlipStatus) => {
      dispatch(changeBetSlipStatus({ betSlipStatus }));
    },
    [dispatch],
  );

  const setBetSlipPlacedErrorsAction = useCallback(
    (errors: SportsBetState['betSlipPlacedErrors']) => {
      dispatch(setBetSlipPlacedError({ errors }));
      if (!!errors.length && betSlipStatus === BetSlipStatus.CONFIRMING_BET) {
        dispatch(changeBetSlipStatus({ betSlipStatus: BetSlipStatus.ADDING_BET }));
      }
    },
    [betSlipStatus, dispatch],
  );

  return {
    addSportsBet,
    removeSportBet,
    clearBetSlip,
    updateBetAmount,
    updateMultiBetAction,
    updateBetSlipStatus,
    setBetSlipPlacedErrorsAction,
    updateBetSlipMarketStatus,
  };
};
