import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Language } from 'constants/languages';
import { getCookie, setLanguagePreferenceCookie } from 'utils/appCookie';

import { updateAuthStore } from '../redux/slices/authSlice';
import { updateBrowserPreferenceStore } from '../redux/slices/browserPreferenceSlice';
import { updateCampaignStore } from '../redux/slices/campaignSlice';
import { setPersistStorePopulated } from '../redux/slices/globalStateSlice';
import { updatePlinkoStore } from '../redux/slices/plinkoSlice';

export const PERSIST_STORE_NAME = 'persist:root';
export const VERSION = 1;
export const PERSIST_STORE_NAMES_METHODS = [
  { name: 'auth', method: updateAuthStore },
  { name: 'browserPreference', method: updateBrowserPreferenceStore },
  { name: 'plinko', method: updatePlinkoStore },
  { name: 'campaign', method: updateCampaignStore },
];

export function getPersistStoreData() {
  try {
    if (typeof window === 'undefined') {
      return;
    }

    const store = window.localStorage.getItem(PERSIST_STORE_NAME);

    if (!store) {
      return;
    }

    const parsedStore = JSON.parse(store);

    if (parsedStore?._persist) {
      const persist = JSON.parse(parsedStore?._persist);
      if (VERSION > persist?.version && parseInt(persist?.version)) {
        return;
      }
    }

    PERSIST_STORE_NAMES_METHODS.forEach(({ name }) => {
      parsedStore[name] = JSON.parse(parsedStore[name]);
    });

    return parsedStore;
  } catch (e) {
    console.error(`Failed retrieve local storage data ${(e as Error)?.message ?? ''}`);
  }
}

export function usePersistStoreData() {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const parsedStore = getPersistStoreData();

      const languagePreference = parsedStore.browserPreference.languagePreference;

      if (languagePreference !== Language.EN && !getCookie('language-preference')) {
        setLanguagePreferenceCookie(languagePreference);
      }

      PERSIST_STORE_NAMES_METHODS.forEach(({ name, method }) => {
        if (parsedStore?.[name]) {
          dispatch(method(parsedStore[name]));
        }
      });
    } catch (e) {
      console.error(`Failed to update redux store ${(e as Error)?.message ?? ''}`);
    }

    dispatch(setPersistStorePopulated(true));
  }, [dispatch]);

  return;
}
