import React from 'react';
import clsx from 'clsx';

import styles from './BetTicketAmount.module.scss';

interface BetTicketAmountProps {
  children: React.ReactNode | string;
  isPayout?: boolean;
}

export function BetTicketAmount({ children, isPayout = false }: BetTicketAmountProps) {
  return (
    <span
      className={clsx(styles.amount, {
        [styles.amountPayout]: isPayout,
      })}
    >
      {children}
    </span>
  );
}
