import React from 'react';

import { SvgProps } from '..';

const ESports = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_6582_150930)">
        <path
          d="M4.2666 1H5.19994C6.74616 1 7.99994 2.72045 7.99994 4.26667V4.73334"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.09961 7.2002V9.7002"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.86719 8.4668H5.36719"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0006 9.86674V7.5334C15.0006 6.7908 14.7056 6.0786 14.1805 5.5535C13.6554 5.0284 12.9432 4.7334 12.2006 4.7334H3.80062C3.05802 4.7334 2.34582 5.0284 1.82072 5.5535C1.29562 6.0786 1.00062 6.7908 1.00062 7.5334V12.8982C0.989515 13.3527 1.12808 13.7982 1.39499 14.1661C1.66189 14.5341 2.04234 14.8042 2.47778 14.9348C2.93191 15.0544 3.41371 15.0097 3.83807 14.8085C4.26242 14.6073 4.602 14.2626 4.7968 13.8353L6.17627 11.2667H9.82499L11.1659 13.75C11.2984 14.0598 11.5044 14.3327 11.766 14.545C12.0276 14.7574 12.3369 14.9029 12.6673 14.969C12.9532 15.019 13.2467 15.006 13.527 14.9307C13.8074 14.8555 14.0679 14.7198 14.2903 14.5333C14.5127 14.3467 14.6917 14.1138 14.8146 13.8508C14.9375 13.5878 15.0014 13.3011 15.0019 13.0108L15.0006 9.86674Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11" cy="9" r="1" fill={color} />
        <circle cx="12.5498" cy="6.9502" r="0.75" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_6582_150930">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ESports;
