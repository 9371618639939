import React, {
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useIsomorphicLayoutEffect } from 'react-use';
import colors from 'assets/styles/colors';
import BigNumber from 'bignumber.js';
import { Flex } from 'components/commonStyles';
import CryptoIcon from 'components/CurrencyIcons/CryptoIcon';
import GeneralCurrencyIcon from 'components/CurrencyIcons/GeneralCurrencyIcon';
import Tooltip from 'components/Tooltip';
import {
  CRYPTO_DECIMALS,
  CRYPTO_DECIMALS_PLACEHOLDER,
  FIAT_DECIMALS,
  FIAT_DECIMALS_PLACEHOLDER,
  FIAT_SMALLEST_UNIT,
} from 'constants/decimalPlaces';
import { useFiatRate } from 'hooks/useFiatRate';
import usePreference from 'hooks/usePreference';
import { useTranslation } from 'next-i18next';
import { transformCurrencyInput } from 'utils/format';

import { Currency } from '../../generated/graphql';
import { useConversion } from '../../hooks/useConversion';

import {
  CurrencyInputElement,
  CurrencyInputIcon,
  ErrorMessage,
  FormControlWrapper,
  InputSuffix,
  InputWrapper,
  Label,
  LabelBlock,
  MaxPayoutInfo,
} from './styles';
import { InputVariant } from './TextInput';

import styles from './CurrencyInput.module.scss';

export interface CurrencyInputRawProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  currency: Currency;
  error?: string;
  rightLabel?: string;
  suffix?: ReactNode;
  profitDisplay?: boolean;
  bgColor?: string;
  testId?: string;
  /**
   * Control the dimmed state; given that we have some disabled
   * states that don'tneed to be dimmed.
   */
  dimmed: boolean;
  alwaysDisplayCryptoIcon?: boolean;

  /**
   * If it's defined and greater than 0, it represents the payout that the user can earn
   */
  maxPayoutUSD?: number;
  variant?: InputVariant;
}

// Value is same currency as input
export const CurrencyInputRaw = React.forwardRef<HTMLInputElement, CurrencyInputRawProps>(
  (
    {
      label,
      currency,
      value,
      rightLabel,
      suffix,
      error,
      onChange,
      onBlur,
      onFocus,
      testId,
      dimmed,
      profitDisplay = false,
      alwaysDisplayCryptoIcon = false,
      bgColor,
      maxPayoutUSD = 0,
      variant,
      ...rest
    },
    ref,
  ) => {
    const { displayInFiat, fiatPreference } = usePreference();
    const { t } = useTranslation();

    const innerRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);

    if (!onChange && !(rest.readOnly || rest.disabled)) {
      throw new Error('CurrencyInput expects onchat event');
    }

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      event => {
        if (onChange) {
          const transformedValue = transformCurrencyInput(event.target.value);

          // We need this check because for some reason without this condition and when type = number
          // if you type 1.5 and then backspace it does something funny
          if (event.target.value !== transformedValue) event.target.value = transformedValue;

          onChange(event);
        }
      },
      [onChange],
    );

    const preventCharacters: React.KeyboardEventHandler = useCallback(e => {
      if (['e', 'E', '+', '-'].includes(e.key)) e.preventDefault();
    }, []);

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
      e => {
        e.target.value =
          e.target.value &&
          BigNumber(e.target.value).toFixed(displayInFiat ? FIAT_DECIMALS : CRYPTO_DECIMALS);

        onChange?.(e);
        onBlur?.(e);
      },
      [onBlur, onChange, displayInFiat],
    );

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement, Element>) => {
        onFocus && onFocus(e);
        innerRef.current && innerRef.current.select();
      },
      [onFocus],
    );

    const defaultPlaceholder = displayInFiat
      ? FIAT_DECIMALS_PLACEHOLDER
      : CRYPTO_DECIMALS_PLACEHOLDER;

    const { fiatToCrypto } = useConversion(currency);

    const betLimitFiat = BigNumber(maxPayoutUSD).multipliedBy(useFiatRate());

    const maxPayoutFormatted = displayInFiat
      ? t('intlCurrencyWithOptions', {
          val: betLimitFiat.toString(),
          currency: fiatPreference,
          minimumFractionDigits: FIAT_DECIMALS,
          maximumFractionDigits: FIAT_DECIMALS,
        })
      : fiatToCrypto(betLimitFiat.toString());

    return (
      <FormControlWrapper $dimmed={dimmed}>
        {(label || rightLabel) && (
          <LabelBlock>
            {label &&
              (maxPayoutUSD > 0 ? (
                <Label>
                  <Flex align="center" gap="7px">
                    <span>{label}</span>

                    <Tooltip placement="top">
                      <Tooltip.Trigger>
                        <MaxPayoutInfo>
                          <img src="/icons/bet-limit-info.svg" alt="info" />
                        </MaxPayoutInfo>
                      </Tooltip.Trigger>
                      <Tooltip.Content>
                        <Flex align="center" gap="5px">
                          <p>{t('maxPayout')}</p>
                          <GeneralCurrencyIcon />
                          <p>{maxPayoutFormatted}</p>
                        </Flex>
                      </Tooltip.Content>
                    </Tooltip>
                  </Flex>
                </Label>
              ) : (
                <Label>{label}</Label>
              ))}
            {rightLabel && (
              <Label $color={colors.gray300} className={styles.label}>
                {rightLabel}
              </Label>
            )}
          </LabelBlock>
        )}

        <InputWrapper>
          <CurrencyInputIcon>
            {alwaysDisplayCryptoIcon ? (
              <CryptoIcon currency={currency} />
            ) : (
              <GeneralCurrencyIcon currency={currency} />
            )}
          </CurrencyInputIcon>

          <CurrencyInputElement
            ref={innerRef}
            variant={variant}
            profitDisplay={profitDisplay}
            {...rest}
            type={'number'}
            data-testid={testId}
            value={value}
            placeholder={rest.placeholder ?? defaultPlaceholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={preventCharacters}
            hasError={Boolean(error)}
            bgColor={bgColor}
            maxLength={rest.maxLength ?? 20}
          />

          {suffix && <InputSuffix>{suffix}</InputSuffix>}
        </InputWrapper>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormControlWrapper>
    );
  },
);

/**
 * Realtime input conversions between crypto and fiat.
 */
export const useCurrencyInputConversion = (cryptoCurrency: Currency) => {
  const { displayInFiat, fiatPreference } = usePreference();

  const { t } = useTranslation();
  const { cryptoToFiat, fiatToCrypto } = useConversion(cryptoCurrency);

  const inputToCrypto = useCallback(
    (value: string, maxValue?: string) => {
      if (!displayInFiat) return value;

      const cryptoValue = fiatToCrypto(value);

      if (maxValue && BigNumber(cryptoValue).gt(maxValue)) {
        // Check if max balance, cause fiat rounding can exceed the max balance
        // Minus 1 balance
        const valueMinusOneUnit = BigNumber(value).minus(FIAT_SMALLEST_UNIT).toFixed();
        if (BigNumber(fiatToCrypto(valueMinusOneUnit)).lte(maxValue)) {
          return maxValue;
        }
      }

      return cryptoValue;
    },
    [fiatToCrypto, displayInFiat],
  );

  const inputToLabelCurrency = useCallback(
    (value: string, cryptoValue: string) => {
      if (!value || value === '.') {
        value = '0';
        cryptoValue = '0';
      }

      if (displayInFiat) {
        const cryptoAmount = t('intlNumber', {
          val: cryptoValue,
          minimumFractionDigits: CRYPTO_DECIMALS,
          maximumFractionDigits: CRYPTO_DECIMALS,
        });

        return `${cryptoAmount} ${cryptoCurrency}`;
      }

      const labelValue = t('intlNumber', {
        val: cryptoToFiat(value),
        minimumFractionDigits: FIAT_DECIMALS,
        maximumFractionDigits: FIAT_DECIMALS,
      });

      return `${labelValue} ${fiatPreference}`;
    },
    [cryptoToFiat, t, fiatPreference, cryptoCurrency, displayInFiat],
  );

  return { inputToCrypto, inputToLabelCurrency };
};

export interface CurrencyInputViewProps {
  label: string;
  currency: Currency;
  value: string;
  profitDisplay?: boolean;
  dimmed?: boolean;
}

// value is always crypto currency
export const CurrencyInputView = React.forwardRef<HTMLInputElement, CurrencyInputViewProps>(
  ({ label, currency, value, dimmed = false, profitDisplay = false }, ref) => {
    const { cryptoToFiat } = useConversion(currency);
    const { displayInFiat } = usePreference();
    const { inputToLabelCurrency } = useCurrencyInputConversion(currency);

    const displayCurrencyValue = displayInFiat ? cryptoToFiat(value) : value;

    const displayValue = BigNumber(displayCurrencyValue).toFixed(
      displayInFiat ? FIAT_DECIMALS : CRYPTO_DECIMALS,
    );

    return (
      <CurrencyInputRaw
        ref={ref}
        label={label}
        profitDisplay={profitDisplay}
        rightLabel={inputToLabelCurrency(displayCurrencyValue, value)}
        value={displayValue}
        currency={currency}
        dimmed={dimmed}
        readOnly
      />
    );
  },
);

export interface CurrencyInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  currency: Currency;
  error?: string;
  /*
   * Max value represents the maximum value the user can put
   * This value is used for rounding purposes
   */
  maxValue?: string;
  value: string;
  onChange?: (value: string, entered: string) => void;
  suffix?: ReactNode;
  bgColor?: string;
  /* hide the  right label currency, default false */
  hideRightLabel?: boolean;

  /**
   * Control the dimmed state; given that we have some disabled
   * states that don'tneed to be dimmed.
   */
  dimmed?: boolean;
  variant?: InputVariant;
  alwaysDisplayCryptoIcon?: boolean;
}

// value is always crypto currency
const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      label,
      currency,
      value,
      maxValue,
      suffix,
      error,
      bgColor,
      hideRightLabel = false,
      onChange = () => {},
      onBlur,
      dimmed = false,
      alwaysDisplayCryptoIcon = false,
      variant,
      ...rest
    },
    ref,
  ) => {
    const { displayInFiat } = usePreference();
    const fiatCurrency = useSelector((state: AppState) => state.prices.pricesFiatCurrency);
    const { inputToCrypto, inputToLabelCurrency } = useCurrencyInputConversion(currency);
    const { cryptoToFiat } = useConversion(currency);

    const [enteredValue, setEnteredValue] = useState(value);
    const lastOnchangeValue = useRef(value);

    const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
      const value = e.target.value;

      const onChangeValue = inputToCrypto(e.target.value, maxValue);
      lastOnchangeValue.current = onChangeValue;
      setEnteredValue(value);

      // Send transformed value
      onChange(onChangeValue, e.target.value);
    };

    useEffect(() => {
      // When value is changed separately
      if (lastOnchangeValue.current !== value) {
        lastOnchangeValue.current = value;
        setEnteredValue(displayInFiat ? cryptoToFiat(value) : value);
      }
    }, [value, displayInFiat, cryptoToFiat]);

    // Using useLayoutEffect here to ensure that the enteredValue is updated before the input is rendered
    useIsomorphicLayoutEffect(() => {
      // When displayInFiat preference is changed
      if (value) {
        setEnteredValue(
          displayInFiat ? cryptoToFiat(value) : BigNumber(value).toFixed(CRYPTO_DECIMALS),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayInFiat, fiatCurrency]);

    useEffect(() => {
      if (value === '') {
        setEnteredValue('');
      }
    }, [value]);

    return (
      <CurrencyInputRaw
        label={label}
        error={error}
        ref={ref}
        rightLabel={!hideRightLabel ? inputToLabelCurrency(enteredValue, value) : ''}
        value={enteredValue}
        onChange={onChangeHandler}
        currency={currency}
        suffix={suffix}
        onBlur={onBlur}
        bgColor={bgColor}
        variant={variant}
        dimmed={dimmed}
        alwaysDisplayCryptoIcon={alwaysDisplayCryptoIcon}
        {...rest}
      />
    );
  },
);

export default CurrencyInput;
