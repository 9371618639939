import { Flex } from 'components/commonStyles';
import ProgressBar from 'components/ProgressBar/ProgessBar';
import useVip from 'hooks/vip/useVip';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import VipBadge from '../../components/Vip/VipBadge';

import { UserMenuVipProgress, UserMenuVipWrapper } from './styles';

/**
 * User VIP progress in UserMenu
 */
const UserMenuVipCard = () => {
  const { t } = useTranslation();
  const { current, next, progress } = useVip();

  const pcDisplay = t('intlNumberWithOptions', {
    val: progress / 100,
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Link href="/vip-program" passHref>
      <a>
        <UserMenuVipWrapper>
          <VipBadge {...current} />
          <UserMenuVipProgress>
            <ProgressBar percentage={progress} />
            <Flex justify="space-between" align="center">
              <span>{pcDisplay}</span>
              <VipBadge {...next} />
            </Flex>
          </UserMenuVipProgress>
        </UserMenuVipWrapper>
      </a>
    </Link>
  );
};

export default UserMenuVipCard;
