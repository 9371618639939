import React from 'react';
import { Fiat } from 'assets/svgs';
import { FiatCurrency } from 'generated/graphql';

/**
 * Icon for a fiat currency value.
 * NOTE. For plaintext symbol, please see `/utils/currency.ts:fiatSymbol`
 */
const FiatIcon = ({ currency }: { currency: FiatCurrency }) => {
  const Icon = Fiat[currency];

  if (!Icon) return null;

  return <Icon />;
};
export default React.memo(FiatIcon);
