import React from 'react';

import { SvgProps } from '.';

const LiveSports = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g id="Hicon / Linear / Home 2" clipPath="url(#clip0_6758_9874)">
        <g id="Group">
          <path
            id="Vector"
            d="M8.00008 15.3333C12.0502 15.3333 15.3334 12.0501 15.3334 8.00001C15.3334 3.94992 12.0502 0.666672 8.00008 0.666672C3.94999 0.666672 0.666748 3.94992 0.666748 8.00001C0.666748 12.0501 3.94999 15.3333 8.00008 15.3333Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M6.66675 10.6667V5.33334L10.6667 8.00001L6.66675 10.6667Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6758_9874">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LiveSports;
