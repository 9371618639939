import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum PlayOption {
  NONE = 'NONE',
  REAL = 'REAL',
  FUN = 'FUN',
}

export interface ProviderGamesState {
  playOption: PlayOption;
}

const initialState: ProviderGamesState = {
  playOption: PlayOption.NONE,
};

export const providerGameSlice = createSlice({
  name: 'providerGame',
  initialState,
  reducers: {
    updatePlayOption: (state: ProviderGamesState, action: PayloadAction<PlayOption>) => {
      state.playOption = action.payload;
    },
    resetProviderGame: () => {
      return initialState;
    },
  },
});

export const { updatePlayOption, resetProviderGame } = providerGameSlice.actions;

export default providerGameSlice.reducer;
