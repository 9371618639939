import { magicMediaQueries } from 'assets/styles/mediaQueries';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

export const OddsWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: flex-end;

  ${magicMediaQueries(Breakpoint.MD)`
    flex-direction: row;
  `};
`;

export const OddsUpdate = styled.span`
  display: flex;
  position: relative;
  animation: blink 1s linear infinite;

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;
