import React from 'react';

import { SvgProps } from '..';

const Golf = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_6582_150385)">
        <path
          d="M3 9.391C1.466 10.032 0.5 10.963 0.5 12C0.5 13.933 3.858 15.5 8 15.5C12.142 15.5 15.5 13.933 15.5 12C15.5 10.067 12.142 8.5 8 8.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 12V0.5L11 3L5.5 5.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6582_150385">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Golf;
