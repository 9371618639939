import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { HeadPhone } from 'assets/svgs';
import RoundButton from 'components/Buttons/RoundButton';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import LoadingContent from 'components/LoadingContent';
import DesktopNavigation from 'components/Navigation/DesktopNavigation';
import MobileNavigation from 'components/Navigation/MobileNavigation';
import { INTERCOM_WIDGET_CLASSNAME } from 'constants/intercom';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import useBreakpoint, { Breakpoint } from 'hooks/interface/useBreakpoint';
import usePreference from 'hooks/usePreference';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { RightSidebarVariant, setRightSidebar } from 'redux/slices/browserPreferenceSlice';
import RightSidebar, { fullscreenRightSidebarVariants } from 'views/RightSidebar';

import { RenderClient } from '../components/RenderClient';
import { SearchStateProps } from '../components/Search/search.type';

import { PAGE_CONTENT_ID } from './constants';
import { MainWrapper, PageContent, PageContentWrapper, SupportButtonWrapper } from './styles';

const BetSlipSideBar = dynamic(() => import('views/BetSlip/BetSlipSideBar'), {
  loading: () => <LoadingContent />,
});
const VipSidebar = dynamic(() => import('views/Vip/VipSidebar'), {
  loading: () => <LoadingContent />,
});
const ChatBox = dynamic(() => import('components/Chat/ChatBox'), {
  loading: () => <LoadingContent />,
});

interface CasinoLayoutProps {
  children: React.ReactNode;
  bodyRowGap?: string;
  bodyPadding?: string;
  activityBoard?: boolean;
  setGlobalSearch: React.Dispatch<React.SetStateAction<SearchStateProps>>;
}

export const CONTAINER_QUERY_NAME = 'magic-container';

const CasinoLayout = ({ children, setGlobalSearch }: CasinoLayoutProps) => {
  const isLoggedIn = useIsLoggedIn();
  const router = useRouter();
  const { rightSidebarVariant, isLeftSidebarExpanded } = usePreference();
  const isRightSidebarFullscreen = fullscreenRightSidebarVariants.includes(rightSidebarVariant);
  const dispatch = useDispatch();

  const { lessThanBreakpoint } = useBreakpoint();

  const isMobile = lessThanBreakpoint(Breakpoint.MD);

  const pageContentRef = useRef<HTMLDivElement>(null);

  const urlWithoutParam = router.asPath.split('?')[0];

  useEffect(() => {
    if (pageContentRef.current) {
      pageContentRef.current.scrollTo(0, 0);
    }
  }, [urlWithoutParam]);

  // If the user loads the page and is on a mobile device with a chat sidebar, we want to close it
  useMount(() => {
    if (isMobile && rightSidebarVariant === RightSidebarVariant.CHAT) {
      dispatch(setRightSidebar({ variant: RightSidebarVariant.NONE }));
    }
  });

  let containerClass = 'no-side-opened';

  if (rightSidebarVariant !== RightSidebarVariant.NONE && isLeftSidebarExpanded) {
    containerClass = 'both-side-opened';
  } else if (isLeftSidebarExpanded) {
    containerClass = 'left-side-opened-only';
  } else if (rightSidebarVariant !== RightSidebarVariant.NONE) {
    containerClass = 'right-side-opened-only';
  }

  return (
    <MainWrapper>
      {!isMobile && (
        <RenderClient>
          <DesktopNavigation />
        </RenderClient>
      )}
      <PageContentWrapper>
        <Header setGlobalSearch={setGlobalSearch} />
        <PageContent
          id={PAGE_CONTENT_ID}
          ref={pageContentRef}
          className={`${CONTAINER_QUERY_NAME} ${containerClass}`}
        >
          {children}

          <Footer />
        </PageContent>

        {isLoggedIn && (
          <SupportButtonWrapper className={INTERCOM_WIDGET_CLASSNAME}>
            <RoundButton icon={<HeadPhone />} />
          </SupportButtonWrapper>
        )}

        {isMobile && (
          <RenderClient>
            <MobileNavigation setGlobalSearch={setGlobalSearch} />
          </RenderClient>
        )}
      </PageContentWrapper>
      <RightSidebar fullscreen={isRightSidebarFullscreen}>
        {rightSidebarVariant === RightSidebarVariant.BET_SLIP && <BetSlipSideBar />}
        {rightSidebarVariant === RightSidebarVariant.CHAT && <ChatBox />}
        {rightSidebarVariant === RightSidebarVariant.VIP && <VipSidebar />}
      </RightSidebar>
    </MainWrapper>
  );
};

export default React.memo(CasinoLayout);
