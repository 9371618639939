import * as React from 'react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { SpringEvent } from 'react-spring-bottom-sheet/dist/types';
import { MobileSheet } from 'components/LazyLoader/MobileSheet';
import { motion } from 'framer-motion';

import colors from '../../assets/styles/colors';
import { Times } from '../../assets/svgs';
import useBreakpoint, { Breakpoint } from '../../hooks/interface/useBreakpoint';
import { closeModal } from '../../redux/slices/modalSlice';
import isFunction from '../../utils/isFunction';
import LoadingContent from '../LoadingContent';
import RawModal from '../modals/RawModal';

import styles from './index.module.scss';

export const MODAL_NAME = 'modal-component' as const;

type Props = {
  target?: string;
  footer?: ReactNode | string;
  isLoading?: boolean;
  onClose: (isOpen: boolean) => void;
  isOpen?: boolean;
  children?: React.ReactNode | (() => React.ReactNode);
  fullscreen?: boolean;
  classNames?: Partial<{
    modalRoot: string;
    modalContent: string;
    modalBody: string;
    modalClose: string;
  }>;
};

export function Modal(props: Props) {
  const { target = MODAL_NAME, footer, isLoading, isOpen, onClose } = props;
  const targetDom = typeof document === 'undefined' ? '' : document.querySelector('#' + target);

  const { lessThanBreakpoint } = useBreakpoint();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(!!isOpen);
  const [resetPosition, setResetPosition] = useState(Date.now());

  const isMobile = lessThanBreakpoint(Breakpoint.SM);

  const notifyModalClosed = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const toggleModalOpen = () => {
    onClose(!open);

    if (open) {
      notifyModalClosed();
    }
  };

  const onSpringEnd = (event: SpringEvent) => {
    if (event.type === 'CLOSE') {
      setResetPosition(Date.now());
    }
  };

  const toggleOpenByDerivedState = useCallback(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    const closeModal = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose(false);
        setOpen(false);
        notifyModalClosed();
      }
    };

    window.addEventListener('keyup', closeModal);

    return () => window.removeEventListener('keyup', closeModal);
  }, [notifyModalClosed, onClose, setOpen]);

  useEffect(() => toggleOpenByDerivedState(), [toggleOpenByDerivedState]);

  if (isMobile && !props.fullscreen) {
    return (
      <MobileSheet
        key={resetPosition}
        open={open}
        footer={footer}
        onDismiss={toggleModalOpen}
        isLoading={isLoading}
        onSpringEnd={onSpringEnd}
      >
        {isFunction(props.children) ? props.children() : props?.children}
      </MobileSheet>
    );
  }

  if (!open || !targetDom) {
    return null;
  }

  if (props.fullscreen) {
    return (
      <motion.div
        initial={{
          opacity: 0,
          y: 25,
        }}
        animate={{ opacity: 1, y: 0 }}
        className={styles.fullscreen}
      >
        <div className={styles.modalHeader}>
          <button
            aria-label={'Close modal'}
            className={`${styles.closeButton} ${props.classNames?.modalClose || ''}`}
            id={'close-modal'}
            onClick={toggleModalOpen}
          >
            <Times color={colors.white} />
          </button>
        </div>
        {isFunction(props.children) ? props.children() : props?.children}
      </motion.div>
    );
  }

  return ReactDOM.createPortal(
    <RawModal closeHandler={toggleModalOpen} allowClickOutside>
      <div role="dialog" aria-modal="true" className={props.classNames?.modalRoot || ''}>
        {isFunction(props.children) ? (
          props.children()
        ) : (
          <motion.div
            initial={{
              opacity: 0,
              y: 25,
            }}
            animate={{ opacity: 1, y: 0 }}
          >
            <div className={`${styles.modalBody} ${props.classNames?.modalBody || ''}`}>
              <div className={styles.modalHeader}>
                <button
                  aria-label={'Close modal'}
                  className={`${styles.closeButton} ${props.classNames?.modalClose || ''}`}
                  id={'close-modal'}
                  onClick={toggleModalOpen}
                >
                  <Times color={colors.white} />
                </button>
              </div>
              <div className={`${styles.modalContent} ${props.classNames?.modalContent || ''}`}>
                {isLoading ? <LoadingContent /> : props.children}
              </div>
              {footer && <div>{footer}</div>}
            </div>
          </motion.div>
        )}
      </div>
    </RawModal>,
    targetDom,
  );
}
