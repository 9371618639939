import groupBy from 'lodash/groupBy';

import { BetSlipItem } from '../redux/slices/sportsBetSlice';

type Props = {
  betSlipData: BetSlipItem[];
};

export function checkMultiBet({ betSlipData }: Props) {
  if (betSlipData.length < 2) {
    return false;
  }

  const groupedBets = groupBy(betSlipData, 'fixtureName');

  for (const key in groupedBets) {
    const groupedBet = groupedBets[key];

    if (groupedBet.length > 1) {
      return false;
    }
  }

  return true;
}
