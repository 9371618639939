import { SportsMarketStatus } from 'generated/graphql';
import { BetSlipErrors } from 'hooks/useSportsBetSlipCheck';
import { i18n } from 'next-i18next';
import { BetSlipStatus } from 'redux/slices/sportsBetSlice';

const t = (name: string) => i18n?.t?.(name) ?? '';

export const getErrorText = (errors: BetSlipErrors) => {
  if (errors.isInsufficientFunds) {
    return t('txtInsufficientFunds');
  }
  if (errors.isMinStakeNotMet) {
    return t('txtIncreaseYourStake');
  }
  return '';
};

export const getPlacedBetErrorText = () => {
  return t('txtBetCouldNotBePlaced');
};

export const shouldShowBetSlipError = (errors: BetSlipErrors) =>
  errors.isInsufficientFunds || errors.isMinStakeNotMet;

export const getBetSlipErrors = (
  betSlipErrors: BetSlipErrors[],
  multiBetErrors: BetSlipErrors,
  betSlipStatus: BetSlipStatus,
) => {
  const shouldShowError =
    betSlipStatus === BetSlipStatus.ADDING_BET || betSlipStatus === BetSlipStatus.CONFIRMING_BET;

  const isInsufficientFundsError =
    (betSlipErrors.some(item => item.isInsufficientFunds) || multiBetErrors.isInsufficientFunds) &&
    shouldShowError;

  const isMinStakeError =
    shouldShowError &&
    !isInsufficientFundsError &&
    (betSlipErrors.some(item => item.isMinStakeNotMet) || multiBetErrors.isMinStakeNotMet);

  const isEventClosedError = shouldShowError && betSlipErrors.some(item => item.isEventClosed);

  const isDimmed =
    shouldShowError && !isInsufficientFundsError && (isMinStakeError || isEventClosedError);

  return {
    shouldShowError,
    isInsufficientFundsError,
    isMinStakeError,
    isEventClosedError,
    isDimmed,
  };
};

export const shouldShowOddsWarningMessage = (params: {
  display: boolean;
  oddsChangesIds: string[];
  marketStatus: SportsMarketStatus;
  id: string;
}) => {
  const { display, oddsChangesIds, marketStatus, id } = params;
  return display && oddsChangesIds.includes(id) && marketStatus === SportsMarketStatus.OPEN;
};
