import { ColouredVariant } from 'components/commonStyles';
import { AlertsEnum } from 'redux/slices/alertsSlice';
import { DefaultTheme } from 'styled-components';

import colors from './colors';

const DEFAULT_CONVERSION_VALUE = 0.0625;

export const pxToRem = (pixel: number) => {
  const remSize = DEFAULT_CONVERSION_VALUE * pixel;
  // Round to decimals to avoid CSS errors from too many decimal places.
  return `${remSize.toFixed(4)}rem`;
};

const colorVariantMap: Record<ColouredVariant, string | null> = {
  info: colors.info,
  warning: colors.warning,
  success: colors.success,
  error: colors.error,
  white: colors.white,
  default: null,
};

export const colourFromVariant = (theme: DefaultTheme, variant: ColouredVariant) => {
  return colorVariantMap[variant] ?? colors.gray500;
};

export const alertColors = {
  [AlertsEnum.Success]: colors.success,
  [AlertsEnum.Error]: colors.error,
  [AlertsEnum.Warning]: colors.warning,
  [AlertsEnum.Info]: colors.info,
};
