import React from 'react';

import { ContainerWrapper } from './styles';

export const CONTAINER_CLASSNAME = 'container';

export type DevicePadding = {
  mobile?: string;
  desktop?: string;
};

export interface ContainerProps {
  children: React.ReactNode;
  padding?: DevicePadding;
  className?: string;
}

/**
 * Primary UI component for user interaction
 */
const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ padding, className, children }, ref) => {
    return (
      <ContainerWrapper
        ref={ref}
        padding={padding}
        className={`${CONTAINER_CLASSNAME} ${className}`}
      >
        {children}
      </ContainerWrapper>
    );
  },
);

export default Container;
