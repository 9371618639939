import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import omit from 'lodash/omit';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { AuthModalType } from '../../redux/slices/modalSlice';
import isString from '../../utils/isString';
import LoadingContent from '../LoadingContent';

import { ModalOnClose, ModalType, QueryTypes } from './GlobalModal.type';
import { Modal } from './index';

import styles from './GlobalModal.module.scss';

const Loader = () => <LoadingContent />;

const BetResultsModal = dynamic(() => import('../modals/BetResult/BetResultsModal'), {
  loading: Loader,
});
const SportBetResultsModal = dynamic(
  () => import('../modals/SportBetResult/SportBetResultsModal'),
  {
    loading: Loader,
  },
);
const WalletModal = dynamic(() => import('../modals/Wallet/WalletModal'), {
  loading: Loader,
});
const AuthModal = dynamic(() => import('../modals/Authentication/AuthModal'), {
  loading: Loader,
});
const UserInfoModal = dynamic(() => import('../modals/User/UserInfoModal'), {
  loading: Loader,
});
const ChallengePrizeModal = dynamic(() => import('../modals/VipRewards/ChallengePrizeModal'), {
  loading: Loader,
});
const SportBetCashOutModal = dynamic(
  () => import('../modals/SportBetCashOut/SportBetCashOutModal'),
  { loading: Loader },
);
const SportBetCashOutSuccessModal = dynamic(
  () => import('../modals/SportBetCashOut/SportBetCashOutSuccessModal'),
  { loading: Loader },
);
const SportBetCashOutTryAgainModal = dynamic(
  () => import('../modals/SportBetCashOut/SportBetCashOutTryAgainModal'),
  { loading: Loader },
);
const SportBetCashOutFailedModal = dynamic(
  () => import('../modals/SportBetCashOut/SportBetCashOutFailedModal'),
  { loading: Loader },
);
const VaultModal = dynamic(() => import('../modals/VaultModal/VaultModal'), { loading: Loader });

const DEFAULT_MODAL: ModalType = {
  type: 'bet' as const,
  id: '',
  isOpen: false,
  name: '' as const,
  currency: '' as const,
  amount: '' as const,
};

export function GlobalModal() {
  const [modal, setModal] = useState<ModalType>(DEFAULT_MODAL);
  const modalType = useSelector((state: AppState) => state.modal.authModalType);
  const { query, pathname, replace, push, reload } = useRouter();

  const tabTypeQuery = query[QueryTypes['md-tab']] as ModalType['tab'];
  const idQuery = query[QueryTypes['md-id']];
  const modalQuery = query[QueryTypes.modal] as ModalType['type'];
  const nameQuery = query[QueryTypes['md-name']];
  const currencyQuery = query[QueryTypes['md-currency']];
  const amountQuery = query[QueryTypes['md-amount']];

  const onClose: ModalOnClose = useCallback(() => {
    setModal(DEFAULT_MODAL);

    const shouldResetRoute =
      modalType === AuthModalType.SessionExpired || modalType === AuthModalType.ResetPassword;

    if (shouldResetRoute) {
      push('/');
      reload();
      return;
    }

    replace(
      {
        pathname,
        query: omit(query, Object.values(QueryTypes)),
      },
      undefined,
      { shallow: true },
    );
  }, [modalType, pathname, push, query, reload, replace]);

  useEffect(() => {
    const triggerModalOpenByQueryString = () => {
      if (!isString(modalQuery)) {
        return;
      }

      switch (modalQuery) {
        case 'wallet':
        case 'auth':
        case 'vault':
          setModal({
            ...DEFAULT_MODAL,
            type: modalQuery,
            isOpen: true,
            tab: tabTypeQuery,
            ...(isString(nameQuery) && nameQuery ? { name: nameQuery } : {}),
            ...(modalQuery === 'auth'
              ? {
                  classNames: {
                    modalContent: styles.authModalContent,
                    modalBody: styles.authModalBody,
                  },
                }
              : {
                  classNames: {
                    modalBody: styles.walletModalBody,
                  },
                }),
          });
          break;
        case 'sportBet':
        case 'bet':
          if (isString(idQuery)) {
            setModal({
              ...DEFAULT_MODAL,
              type: modalQuery,
              isOpen: true,
              id: idQuery,
              classNames: {
                modalBody: styles.betModalBody,
              },
            });
          }
          break;
        case 'user':
          setModal({
            ...DEFAULT_MODAL,
            type: modalQuery,
            isOpen: true,
            classNames: {
              modalBody: styles.userModalBody,
            },
          });
          break;
        case 'challengePrize':
          if (
            isString(idQuery) &&
            isString(nameQuery) &&
            isString(currencyQuery) &&
            isString(amountQuery)
          ) {
            setModal({
              ...DEFAULT_MODAL,
              type: modalQuery,
              isOpen: true,
              id: idQuery,
              name: nameQuery,
              currency: currencyQuery,
              amount: amountQuery,
            });
          }
          break;
        case 'sportBetCashOut':
          if (isString(idQuery)) {
            setModal({
              ...DEFAULT_MODAL,
              type: modalQuery,
              isOpen: true,
              id: idQuery,
            });
          }
          break;
        case 'sportBetCashOutSuccess':
          if (isString(amountQuery) && isString(currencyQuery)) {
            setModal({
              ...DEFAULT_MODAL,
              type: modalQuery,
              isOpen: true,
              amount: amountQuery,
              currency: currencyQuery,
            });
          }
          break;
        case 'sportBetCashOutTryAgain':
          if (isString(idQuery)) {
            setModal({
              ...DEFAULT_MODAL,
              type: modalQuery,
              isOpen: true,
              id: idQuery,
            });
          }
          break;
        case 'sportBetCashOutFailed':
          setModal({
            ...DEFAULT_MODAL,
            type: modalQuery,
            isOpen: true,
          });
          break;
        default:
      }
    };

    triggerModalOpenByQueryString();
  }, [amountQuery, currencyQuery, idQuery, modalQuery, nameQuery, tabTypeQuery]);

  useEffect(() => {
    const closeExistingModalWithQueryString = () => {
      if (modal.isOpen && !modalQuery) {
        setModal(DEFAULT_MODAL);
      }
    };

    closeExistingModalWithQueryString();
  }, [modal.isOpen, modalQuery]);

  return (
    <Modal classNames={modal.classNames} isOpen={modal.isOpen} onClose={onClose}>
      {modal.type === 'sportBet' && modal.id && <SportBetResultsModal betId={modal.id} />}
      {modal.type === 'bet' && modal.id && <BetResultsModal betId={modal.id} />}
      {modal.type === 'wallet' && <WalletModal tabType={modal.tab} username={modal.name} />}
      {modal.type === 'auth' && <AuthModal onClose={onClose} tabType={modal.tab} />}
      {modal.type === 'user' && <UserInfoModal />}
      {modal.type === 'challengePrize' && <ChallengePrizeModal onClose={onClose} />}
      {modal.type === 'sportBetCashOut' && <SportBetCashOutModal onClose={onClose} />}
      {modal.type === 'sportBetCashOutSuccess' && <SportBetCashOutSuccessModal onClose={onClose} />}
      {modal.type === 'sportBetCashOutTryAgain' && (
        <SportBetCashOutTryAgainModal onClose={onClose} />
      )}
      {modal.type === 'sportBetCashOutFailed' && <SportBetCashOutFailedModal onClose={onClose} />}
      {modal.type === 'vault' && <VaultModal />}
    </Modal>
  );
}
