import { pxToRem } from 'assets/styles/convertors';
import { mediaQueries } from 'assets/styles/mediaQueries';
import UI from 'constants/ui';
import { motion } from 'framer-motion';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

import colors from '../../assets/styles/colors';

import { ModalSize } from './Modal';

const MODAL_SIZE_REM_SM = 21;
const MODAL_SIZE_REM_MD = 27;
const MODAL_SIZE_REM_LG = 33.75;

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: ${UI.z.modal + 1};
  padding: 1.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaQueries(Breakpoint.MD)`
    padding: 1.25rem;
  `};
`;

export const ModalOverlay = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${colors.overlay};
  opacity: 0;
`;

interface ModalBodyProps {
  $size: ModalSize;
}

const modalBodyWidthMap: Record<ModalSize, string> = {
  sm: `${MODAL_SIZE_REM_SM}rem`,
  md: `${MODAL_SIZE_REM_MD}rem`,
  lg: `${MODAL_SIZE_REM_LG}rem`,
  auto: 'max-content',
};

export const ModalBody = styled.div<ModalBodyProps>`
  border-radius: ${UI.radius.md};
  background-size: cover;
  background-position: center;
  background: ${colors.gray900};
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: ${UI.z.modal};
  max-height: min(calc(100vh - 20px), 800px);
  box-shadow: 0 2px 20px 10px rgba(17, 17, 18, 0.25);

  /* Width should never exceed display port. */
  max-width: min(90vw, ${MODAL_SIZE_REM_LG}rem);
  min-width: ${({ $size }) => ($size === 'auto' ? `${MODAL_SIZE_REM_SM}rem` : 'unset')};
  width: ${({ $size }) => modalBodyWidthMap[$size]};
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 2.5rem 2.5rem 0 2.5rem;
  height: 2.5rem;
  color: ${colors.white};
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackIcon = styled.span`
  width: 2.75rem;
  height: 2.75rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  top: 0.5rem;
  left: 0.25rem;
  cursor: pointer;
  stroke: ${colors.white};
`;

export const CloseIcon = styled.button`
  width: 2.75rem;
  height: 2.75rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  top: 0.5rem;
  right: 0.25rem;
  cursor: pointer;
  stroke: ${colors.white};
  background: none;
  border: 0;
`;

export const ModalContent = styled.div`
  padding: 0 2.5rem 2.5rem 2.5rem;
  overflow: auto;
`;

export const ModalFooter = styled.div`
  padding: ${pxToRem(22)} 0 ${pxToRem(28)} 0;
  border-top: ${pxToRem(1)} solid ${colors.gray700};
`;

export const ModalBottomLink = styled.span`
  font-size: ${UI.text.lg};
  line-height: 1.313rem;
  font-weight: 500;
  color: ${colors.gray500};
  border-bottom: ${pxToRem(1)} solid ${colors.white};
  padding-bottom: 0.125rem;
  cursor: pointer;
  margin: 1rem auto auto;
  width: max-content;
  display: block;
  color: ${colors.white};
`;

export const ModalDivider = styled.div`
  background-color: ${colors.gray700};
  margin: ${UI.spacing.xl} 0;
  height: ${pxToRem(1)};
  width: 100%;
`;

interface ModalTabProps {
  $variant: 'default' | 'primary';
}

export const ModalTabs = styled.div<ModalTabProps>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 3rem;
  border-bottom: 0.0625rem solid ${colors.gray600};
  --modal-tab-colour: ${({ $variant }) =>
    $variant === 'default' ? colors.white : colors.primaryViolet};
  --modal-tab-active-weight: ${({ $variant }) => ($variant === 'default' ? 400 : 700)};
`;

interface ModalTabOptionProps {
  isActive: boolean;
}

export const ModalTabOption = styled.div<ModalTabOptionProps>`
  height: 100%;
  flex: 1;
  padding: 0 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: ${({ isActive }) => (isActive ? 'var(--modal-tab-colour)' : colors.white)};
  font-weight: ${({ isActive }) => (isActive ? 'var(--modal-tab-active-weight)' : 400)};
  white-space: nowrap;

  &::after {
    content: '';
    height: ${pxToRem(3)};
    width: 100%;
    background-color: var(--modal-tab-colour);
    position: absolute;
    bottom: ${pxToRem(-2)};
    border-radius: ${pxToRem(28)};
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  }
`;
