import React, { useMemo } from 'react';
import { ComponentSize } from 'components/commonStyles';

import { ProgessBarColor, ProgressBarContainer } from './styles';

interface ProgressBarProps {
  /** A number between 0 and 100 */
  percentage: number;
  size?: Omit<ComponentSize, 'sm'>;
}

const ProgressBar = ({ percentage, size = 'md' }: ProgressBarProps) => {
  const pcAsString = useMemo(() => {
    if (percentage < 0) return '0%';
    if (percentage > 100) return '100%';
    return `${percentage}%`;
  }, [percentage]);

  return (
    <ProgressBarContainer $size={size}>
      <ProgessBarColor $size={size} $precentage={pcAsString} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
