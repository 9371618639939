import { Coords, Placement } from '@floating-ui/react';
import colors from 'assets/styles/colors';
import UI from 'constants/ui';
import { TooltipVariant } from 'hooks/interface/useTooltip';
import styled from 'styled-components';

const TOOLTIP_ARROW_SIZE_REM = 0.75;
/**
 * The offset, given the difference in height between the original
 * square and the diamond we create through rotation of 45 degrees;
 * @example ■ -> ◆
 */
const TOOLTIP_ROTATED_OFFSET_REM = TOOLTIP_ARROW_SIZE_REM / 2;

export const TooltipElement = styled.div<{ variant: TooltipVariant }>`
  width: max-content;
  max-width: min(30rem, 90vw);
  border-radius: ${UI.radius.sm};
  color: ${colors.gray500};
  font-size: ${UI.text.md};

  border: ${({ variant }) => (variant === 'light' ? 'none' : `1px solid ${colors.gray700}`)};
  background-color: ${({ variant }) => (variant === 'light' ? 'white' : colors.black900)};
`;

export const TooltipChildren = styled.div`
  position: relative;
  padding: ${UI.spacing.sm} ${UI.spacing.md};
`;

interface TooltipArrowElementProps extends Partial<Coords> {
  placement: Placement;
  variant: TooltipVariant;
}

/**
 * Given the placement of the tooltip, decide where the arrow should be
 * translated to.
 */
const getTranslationFromPlacement = ({ placement }: TooltipArrowElementProps) => {
  if (placement.startsWith('top')) return `bottom: -${TOOLTIP_ROTATED_OFFSET_REM}rem`;
  if (placement.startsWith('bottom')) return `top: -${TOOLTIP_ROTATED_OFFSET_REM}rem`;
  if (placement.startsWith('left')) return `right: -${TOOLTIP_ROTATED_OFFSET_REM}rem`;
  if (placement.startsWith('right')) return `left: -${TOOLTIP_ROTATED_OFFSET_REM}rem`;
  return '';
};

/**
 * We rotate different angles such that the top and left borders of the arrow
 * always point towards the reference.
 */
const getRotationGivenPlacement = ({ placement }: TooltipArrowElementProps) => {
  if (placement.startsWith('top')) return 45 + 180;
  if (placement.startsWith('bottom')) return 45;
  if (placement.startsWith('left')) return 45 + 90;
  if (placement.startsWith('right')) return -45;
  return '';
};

export const TooltipArrowElement = styled.div<TooltipArrowElementProps>`
  position: absolute;
  width: ${`${TOOLTIP_ARROW_SIZE_REM}rem`};
  height: ${`${TOOLTIP_ARROW_SIZE_REM}rem`};
  transform: rotate(${getRotationGivenPlacement}deg);
  top: ${({ y }) => (y != null ? `${y.toFixed(4)}px` : '')};
  left: ${({ x }) => (x ? `${x.toFixed(4)}px` : '')};
  border-left: ${({ variant }) => (variant === 'light' ? 'none' : `1px solid ${colors.gray700}`)};
  border-top: ${({ variant }) => (variant === 'light' ? 'none' : `1px solid ${colors.gray700}`)};
  background-color: ${({ variant }) => (variant === 'light' ? 'white' : colors.black900)};

  ${getTranslationFromPlacement};
`;

export const TooltipContentLineWrap = styled.p`
  max-width: 20rem;
`;
