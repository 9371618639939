import React from 'react';

import { SvgProps } from '.';

const Home = ({ scale = 1, color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M14.7654 6.10154L8.00311 1L1.23458 6.10154C1.09465 6.23065 1.01051 6.40917 1 6.59925V14.3897C1.00049 14.5509 1.06476 14.7054 1.17878 14.8194C1.29279 14.9334 1.44729 14.9976 1.60853 14.9981H5.66667V11.7338C5.66667 11.115 5.9125 10.5216 6.35008 10.0841C6.78767 9.64655 7.38116 9.40075 8 9.40075C8.61884 9.40075 9.21233 9.64655 9.64992 10.0841C10.0875 10.5216 10.3333 11.115 10.3333 11.7338V15H14.3908C14.5521 14.9995 14.7067 14.9353 14.8208 14.8213C14.9349 14.7073 14.9993 14.5528 15 14.3915V6.59925C14.9892 6.40923 14.9051 6.23081 14.7654 6.10154Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Home;
