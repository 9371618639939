import React from 'react';

import styles from './AnimateLiveEventTime.module.css';

type Props = {
  time: string;
  text: string;
  variants?: 'grey' | 'green';
};

export function AnimateLiveEventTime({ time, text, variants }: Props) {
  return (
    <>
      <span className={`${styles.animateTime} ${variants === 'green' ? styles.green : ''}`}>
        {time}
      </span>{' '}
      {text}
    </>
  );
}
