import React from 'react';

import { BetContent } from './betContent';
import BetMessage from './BetMessage';

type Props = {
  isInBetAddView: boolean;
  content: BetContent;
  isInBetInfoView: boolean;
  isPartialPending: boolean;
  exceedMaxAllowedStakeAmount?: boolean;
  showOddsWarningMessage?: boolean;
};

export function BetTitleHeaderWarnings({
  isInBetAddView,
  exceedMaxAllowedStakeAmount,
  content,
  isInBetInfoView,
  isPartialPending,
  showOddsWarningMessage,
}: Props) {
  return (
    <>
      {showOddsWarningMessage && (
        <BetMessage msgType="warning" message={content.oddsChangesWarning()} />
      )}

      {isInBetAddView && exceedMaxAllowedStakeAmount && (
        <BetMessage msgType="warning" message={content.newStakeOfferTitle()} />
      )}

      {!isInBetInfoView && isPartialPending && (
        <BetMessage msgType="warning" message={content.stakeModified()} />
      )}
    </>
  );
}
