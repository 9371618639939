import React from 'react';
import colors from 'assets/styles/colors';

interface SpinnerProps {
  scale?: number;
  bgColor?: string;
  spinColor?: string;
  viewBox?: string;
}

const Spinner = ({
  scale = 1,
  bgColor = '#2A2E38',
  spinColor = colors.primaryViolet,
  viewBox = '0 0 17 17',
}: SpinnerProps) => {
  const width = `${scale * 17}`;
  const height = `${scale * 17}`;

  return (
    <svg
      aria-hidden={'true'}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.5" cy="8.5" r="6.75" stroke={bgColor} strokeWidth="2.5" />
      <path
        d="M8.5 1.74c0-.685.558-1.25 1.235-1.144A8 8 0 0 1 16 11.286c-.238.642-1.004.853-1.602.518-.597-.334-.795-1.09-.601-1.746a5.516 5.516 0 0 0-2.551-6.347 5.52 5.52 0 0 0-1.515-.592C9.062 2.966 8.5 2.425 8.5 1.74Z"
        fill={spinColor}
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 8.5 8.5"
          to="360 8.5 8.5"
          dur="1s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

export default Spinner;
