import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Chat, Chevron, Coupon, Crown, ThreeDots } from 'assets/svgs';
import RoundButton from 'components/Buttons/RoundButton';
import { Relative } from 'components/commonStyles';
import { isEnableSports } from 'components/Navigation/menu';
import Tooltip from 'components/Tooltip';
import { motion } from 'framer-motion';
import { useMobileNav } from 'hooks/useMobileNav';
import usePreference from 'hooks/usePreference';
import { useVipRewardQuery } from 'hooks/vip/useVipRewardQuery';
import { useTranslation } from 'next-i18next';
import {
  RightSidebarVariant,
  toggleBetSlipSidebar,
  toggleVipSidebar,
} from 'redux/slices/browserPreferenceSlice';

import { useSportsBet } from '../../../hooks/useSportsBet';
import { BetSlipStatus } from '../../../redux/slices/sportsBetSlice';
import { getBetViewConfig } from '../../../views/BetSlip/components/BetTicket/getBetViewConfig';

import {
  ArrowIcon,
  ExpandButton,
  ExpandMenuWrapper,
  IconNotification,
  Menu,
  MenuWrapper,
} from './styles';

import styles from './IconMenu.module.scss';

const IconMenu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { clearBetSlip, updateBetSlipStatus } = useSportsBet();
  const { vipNotificationsCount } = useVipRewardQuery();
  const { toggleChatBox } = useMobileNav();
  const { rightSidebarVariant } = usePreference();
  const { betSlipStatus, betSlips } = useSelector(
    (state: AppState) => state.sportsBet,
    shallowEqual,
  );
  const { isInBetPlacedView } = getBetViewConfig({
    betSlipStatus,
  });
  const shouldShowRightSidebar = rightSidebarVariant !== RightSidebarVariant.NONE;
  const shouldShowBetSlip = rightSidebarVariant === RightSidebarVariant.BET_SLIP;
  const shouldShowChat = rightSidebarVariant === RightSidebarVariant.CHAT;
  const shouldShowVip = rightSidebarVariant === RightSidebarVariant.VIP;

  const showChatBox = () => {
    toggleChatBox(!shouldShowChat);
    if (betSlipStatus === BetSlipStatus.BET_PLACED) {
      updateBetSlipStatus(BetSlipStatus.EMPTY);
      clearBetSlip();
    }
  };

  const toggleVipSidebarOpen = useCallback(() => {
    dispatch(toggleVipSidebar({ open: !shouldShowVip }));
  }, [dispatch, shouldShowVip]);

  const toggleBetSlipSidebarOpen = useCallback(() => {
    dispatch(toggleBetSlipSidebar({ open: !shouldShowBetSlip }));

    if (shouldShowBetSlip && betSlipStatus === BetSlipStatus.BET_PLACED) {
      clearBetSlip();
    }
  }, [betSlipStatus, clearBetSlip, dispatch, shouldShowBetSlip]);

  const renderMenuItem = (isMenuExpanded: boolean) => {
    return (
      <>
        {isEnableSports && (
          <Tooltip placement={isMenuExpanded ? 'right' : 'bottom'}>
            <Tooltip.Trigger>
              <div
                className={`${styles.countWrapper} ${shouldShowBetSlip ? styles.countActive : ''} `}
              >
                <RoundButton
                  onClick={toggleBetSlipSidebarOpen}
                  color="tertiary"
                  aria-label={`${shouldShowRightSidebar ? 'Close' : 'Open'} panel`}
                  icon={<Coupon />}
                />
                {!isInBetPlacedView && (
                  <motion.div
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: betSlips.length ? 1 : 0,
                    }}
                  >
                    <span aria-label={`${betSlips.length || 0} bet slips`} className={styles.count}>
                      {betSlips.length || 1}
                    </span>
                  </motion.div>
                )}
              </div>
            </Tooltip.Trigger>
            <Tooltip.Content>{t('txtBetSlip')}</Tooltip.Content>
          </Tooltip>
        )}

        <Tooltip placement={isMenuExpanded ? 'right' : 'bottom'}>
          <Tooltip.Trigger>
            <Relative>
              {vipNotificationsCount > 0 && (
                <IconNotification>{vipNotificationsCount}</IconNotification>
              )}
              <RoundButton
                onClick={toggleVipSidebarOpen}
                color={shouldShowVip ? 'primary' : 'tertiary'}
                icon={<Crown />}
              />
            </Relative>
          </Tooltip.Trigger>
          <Tooltip.Content>{t('txtVIP')}</Tooltip.Content>
        </Tooltip>

        <Tooltip placement={isMenuExpanded ? 'right' : 'bottom'}>
          <Tooltip.Trigger>
            <Relative>
              <RoundButton
                data-testid={'chat-button'}
                onClick={showChatBox}
                color={shouldShowChat ? 'primary' : 'tertiary'}
                icon={<Chat />}
              />
            </Relative>
          </Tooltip.Trigger>

          <Tooltip.Content>{t('txtChat')}</Tooltip.Content>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <MenuWrapper $show={shouldShowRightSidebar}>{renderMenuItem(false)}</MenuWrapper>
      <Menu $show={shouldShowRightSidebar}>
        <ExpandButton>
          <RoundButton color="tertiary" icon={<ThreeDots />} />
          <ArrowIcon>
            <Chevron />
          </ArrowIcon>
        </ExpandButton>
        <ExpandMenuWrapper>{renderMenuItem(true)}</ExpandMenuWrapper>
      </Menu>
    </>
  );
};

export default IconMenu;
