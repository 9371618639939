import { Breakpoint, defaultBreakpoints } from 'hooks/interface/useBreakpoint';
import { DefaultTheme } from 'styled-components';
import { coalesceTemplateLiteral } from 'utils/format';

/**
 * Media queries based upon the width of the window.
 */
export const mediaQueries = (key: Breakpoint) => {
  return <T>(style: TemplateStringsArray, ...values: T[]) => {
    const valueInjectedStyle = coalesceTemplateLiteral(style, values);
    return `@media (min-width: ${defaultBreakpoints[key]}px) { ${valueInjectedStyle} }`;
  };
};

/**
 * Use magic media queries based upon the width of internal content.
 *
 * Function-caption! But don't worry; essentially this just allows us to
 * call magicMediaQueries directly inside styled-components without having to
 * manually pass in `{ theme }`.
 *
 * @example
 * // If you're not using `theme` in your template string, do this:
 * ${magicMediaQueries(Breakpoint.MD)`
 *   justify-content: flex-start;
 * `};
 *
 * // If you need `theme` in your template string, you can do this:
 *
 * @example
 * ({ theme }) => ${magicMediaQueries(Breakpoint.MD)`
 *   background: ${theme.bg};
 * `}({ theme })
 */
export const magicMediaQueries = (key: Breakpoint) => {
  return <T>(style: TemplateStringsArray, ...values: T[]) => {
    const valueInjectedStyle = coalesceTemplateLiteral(style, values);

    return (props?: { theme?: DefaultTheme }) => {
      return `@media (min-width: ${
        (props?.theme?.breakpoints ?? defaultBreakpoints)[key]
      }px) { ${valueInjectedStyle} }`;
    };
  };
};
