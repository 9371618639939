import React from 'react';
import colors from 'assets/styles/colors';
import clsx from 'clsx';

import styles from './BetTicketText.module.scss';

interface BetTicketTextProps {
  children: React.ReactNode | string;
  truncateText?: boolean;
  color?: string;
  rightAlign?: boolean;
  bold?: boolean;
  capitalize?: boolean;
  noCapitalize?: boolean;
  textDecoration?: 'underline' | 'line-through' | 'none';
  wrap?: boolean;
  className?: string;
  dimmed?: boolean;
}

export function BetTicketText({
  children,
  truncateText = false,
  color = colors.white,
  rightAlign = false,
  bold = false,
  capitalize = false,
  noCapitalize = false,
  textDecoration = 'none',
  wrap = false,
  className,
  dimmed = false,
}: BetTicketTextProps) {
  return (
    <p
      className={clsx(className, styles.baseBetTicketText, {
        [styles.textTruncate]: truncateText,
        [styles.textAlignRight]: rightAlign,
        [styles.textBold]: bold,
        [styles.textCapitalize]: capitalize,
        [styles.textNoCapitalize]: noCapitalize,
        [styles.textWrap]: wrap,
        [styles.textLineThrough]: textDecoration === 'line-through',
        [styles.textUnderline]: textDecoration === 'underline',
        [styles.dimmed]: dimmed,
      })}
      style={{ color }}
    >
      {children}
    </p>
  );
}
