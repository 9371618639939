import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import styles from './loader.module.scss';

type Props = {
  invert?: boolean;
  className?: string;
  variant?: 'small' | 'medium';
};

export function Loader({ invert, className, variant = 'small' }: Props) {
  const { t } = useTranslation();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div
      className={`${styles.root} ${mounted ? styles.mounted : ''} ${className || ''} ${
        variant === 'medium' ? styles.medium : ''
      }`}
      aria-label={t('btnLoading')}
    >
      <div className={`${styles.container} ${invert ? styles.invert : ''}`}>
        <img
          src={'/icons/shuffle-logo-top.svg'}
          alt={'logo'}
          width={21}
          height={12}
          className={styles.top}
        />
        <img
          src={'/icons/shuffle-logo-middle.svg'}
          alt={'logo'}
          width={21}
          height={12}
          className={styles.middle}
        />
        <img
          src={'/icons/shuffle-logo-bottom.svg'}
          alt={'logo'}
          width={21}
          height={12}
          className={styles.bottom}
        />
      </div>
    </div>
  );
}
