import React from 'react';

import { SvgProps } from '.';

const Challenge = ({ scale = 1, color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 16 16">
      <g clipPath="url(#clip0_2257_3383)">
        <path
          d="M7.02424 7.712L8.81091 13.666C8.83836 13.7578 8.88916 13.8408 8.95833 13.907C9.0275 13.9733 9.11268 14.0204 9.20553 14.0439C9.29839 14.0673 9.39573 14.0663 9.48806 14.0408C9.58038 14.0154 9.66452 13.9664 9.73224 13.8987L10.6422 12.9887L12.9902 15.3367C13.0416 15.3881 13.1026 15.4288 13.1698 15.4567C13.2369 15.4845 13.3089 15.4988 13.3816 15.4988C13.4543 15.4988 13.5262 15.4845 13.5934 15.4567C13.6605 15.4288 13.7215 15.3881 13.7729 15.3367L15.3382 13.7713C15.3896 13.72 15.4304 13.6589 15.4582 13.5918C15.4861 13.5247 15.5004 13.4527 15.5004 13.38C15.5004 13.3073 15.4861 13.2354 15.4582 13.1682C15.4304 13.1011 15.3896 13.0401 15.3382 12.9887L12.9902 10.6407L13.9002 9.73334C13.968 9.66566 14.017 9.58157 14.0425 9.48928C14.0679 9.397 14.0691 9.29968 14.0457 9.20684C14.0223 9.114 13.9752 9.02881 13.9091 8.9596C13.8429 8.8904 13.7599 8.83954 13.6682 8.812L7.71358 7.02534C7.61812 6.99701 7.51679 6.99493 7.42025 7.01932C7.3237 7.04371 7.23552 7.09367 7.16498 7.16395C7.09443 7.23422 7.04413 7.32221 7.01936 7.41865C6.9946 7.5151 6.99628 7.61644 7.02424 7.712Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4822 7.00001C14.3867 5.66146 13.9083 4.3786 13.1043 3.30423C12.3002 2.22985 11.2042 1.40924 9.94694 0.940146C8.68965 0.47105 7.32402 0.37324 6.01271 0.658364C4.7014 0.943489 3.49968 1.59953 2.5507 2.54835C1.60171 3.49717 0.945453 4.69877 0.660094 6.01002C0.374736 7.32128 0.472303 8.68693 0.941174 9.94431C1.41005 11.2017 2.23046 12.2978 3.30469 13.1021C4.37893 13.9063 5.6617 14.3849 7.00023 14.4807"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.2089 6C10.9613 5.38754 10.5658 4.84594 10.0578 4.42359C9.54984 4.00124 8.94515 3.71128 8.29778 3.57961C7.65041 3.44794 6.9805 3.47867 6.34789 3.66904C5.71529 3.85941 5.13968 4.20349 4.67248 4.67057C4.20529 5.13765 3.86105 5.71316 3.67052 6.34572C3.47999 6.97828 3.4491 7.64818 3.5806 8.29559C3.7121 8.94299 4.00191 9.54775 4.42413 10.0558C4.84635 10.5639 5.38784 10.9595 6.00024 11.2073"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2257_3383">
          <rect width="16" height="16" fill="#FFFFFF" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Challenge;
