import React from 'react';
import clsx from 'clsx';
import { SportsMarketStatus } from 'generated/graphql';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import SportIcon from 'views/SportsHome/components/SportIcon';

import colors from '../../../../assets/styles/colors';
import Checkbox from '../../../../components/FormControls/Checkbox';
import { BetSlipItem, BetSlipStatus, MultiBet } from '../../../../redux/slices/sportsBetSlice';

import styles from './BetTicketLegHeader.module.scss';

interface BetTicketHeaderElementProps {
  bet: BetSlipItem;
  betSlipStatus?: BetSlipStatus;
  marketStatus?: SportsMarketStatus;
  onUpdateAmount?: (payload: Partial<MultiBet>) => void;
  selectionIds: string[];
  checked: boolean;
  onHeaderClick?: () => void;
  showLiveBadge?: boolean;
}

const CheckBoxStyle = styled.div`
  > div {
    margin-top: 0.15rem;

    > label {
      margin-left: auto;
      padding-left: 1rem;
    }
  }
`;

export const BetTicketLegHeader = ({
  bet: { fixtureName, sport, href, id },
  selectionIds,
  betSlipStatus,
  marketStatus,
  onUpdateAmount,
  checked,
  onHeaderClick,
  showLiveBadge = false,
}: BetTicketHeaderElementProps) => {
  const { t } = useTranslation();
  const onUpdateChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const updatedBetSlips = isChecked
      ? [...selectionIds, id]
      : selectionIds.filter(currentId => currentId !== id);

    if (updatedBetSlips.length <= 1) {
      onUpdateAmount?.({
        amount: '',
      });
    }

    onUpdateAmount?.({
      selectionIds: updatedBetSlips,
    });
  };

  return (
    <div className={styles.betTicketHeader}>
      <div className={styles.header}>
        {href && (
          <Link href={href} passHref>
            <a
              className={styles.betTicketGameNameWrapper}
              onClick={onHeaderClick}
              title={fixtureName}
            >
              <SportIcon scale={0.8} color={colors.primaryViolet} sport={sport} />
              <span className={styles.betTicketGameName}>{fixtureName}</span>
            </a>
          </Link>
        )}
        <div className={styles.legHeaderStatusWrapper}>
          {showLiveBadge && <span className={styles.liveBadge}>{t('txtLive')}</span>}
          {betSlipStatus === BetSlipStatus.ADDING_BET && (
            <div
              className={clsx({
                [styles.dimmed]: !!(
                  betSlipStatus === BetSlipStatus.ADDING_BET &&
                  marketStatus &&
                  marketStatus !== SportsMarketStatus.OPEN
                ),
              })}
            >
              <CheckBoxStyle>
                <Checkbox checked={checked} onChange={onUpdateChecked} aria-label={'select leg'} />
              </CheckBoxStyle>
            </div>
          )}
        </div>
      </div>

      <div className={styles.divider} />
    </div>
  );
};
