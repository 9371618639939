import { ReactNode } from 'react';
import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import UI from 'constants/ui';
import styled from 'styled-components';

import { SuffixButtonProps } from './SuffixButton';

interface ButtonProps {
  isRound?: boolean;
  hasMargin?: boolean;
  fillIcon?: boolean;
  bold?: boolean;
  fontWeight?: string;
  noDisabledStyle?: boolean;
}

export const PrimaryButton = styled.button<ButtonProps>`
  background-color: ${colors.primaryNeonPurple};
  border: none;
  color: ${colors.white};
  height: ${({ isRound }) => (isRound ? pxToRem(40) : pxToRem(UI.height.input))};
  margin-top: ${({ hasMargin }) => (hasMargin ? pxToRem(16) : '0')};
  padding: 0 ${UI.spacing.md};
  width: ${({ isRound }) => (isRound ? pxToRem(40) : '100%')};
  border-radius: ${({ isRound }) => (isRound ? UI.radius.full : UI.radius.sm)};
  position: relative;
  line-height: ${pxToRem(20)};
  font-weight: ${({ bold, fontWeight }) => (bold ? '700' : fontWeight)};
  font-size: ${UI.text.lg};
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  transition: transform 0.08s;

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.primaryViolet};
    }
  }

  &:active:enabled {
    transform: scale(0.95);
    background-color: ${colors.btnPressed};
  }

  ${({ noDisabledStyle, fillIcon }) =>
    !noDisabledStyle &&
    `
      &:disabled {
        background-color: ${colors.gray300};
        cursor: not-allowed;
        
        ${fillIcon && `svg path { fill: ${colors.gray500}; }`}
      }
  `}
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: transparent;
  border: ${pxToRem(1.5)} solid ${colors.white};
  color: ${colors.white};

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.gray700};
    }
  }

  &:active:enabled {
    background-color: ${colors.gray600};
  }

  ${({ noDisabledStyle }) =>
    !noDisabledStyle &&
    `
    &:disabled {
      background-color: transparent;
      color: ${colors.gray500};
      border-color: ${colors.gray500};
    }
  `}
`;

export const TertiaryButton = styled(PrimaryButton)`
  background-color: ${colors.gray700};
  color: ${colors.white};

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.gray600};
    }
  }

  &:active:enabled {
    background-color: ${colors.gray500};
  }
  color: inherit;
  ${({ noDisabledStyle }) =>
    !noDisabledStyle &&
    `
      &:disabled {
        background-color: ${colors.gray800};
        color: ${colors.white};
      }
  `}
`;

export const SuccessButton = styled(PrimaryButton)`
  background-color: ${colors.success};
  color: ${colors.black900};

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.success};
    }
  }

  &:active {
    background-color: ${colors.success};
  }

  &:disabled {
    background-color: ${colors.success};
    color: ${colors.black900};
  }
`;

export const Icon = styled.span<{
  $noFixedDimensions?: boolean;
  children?: ReactNode | undefined;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $noFixedDimensions }) => ($noFixedDimensions ? 'auto' : pxToRem(32))};
  height: ${({ $noFixedDimensions }) => ($noFixedDimensions ? 'auto' : pxToRem(32))};

  /* currently only used for "icons in the provider game buttons" */
  margin-right: ${({ $noFixedDimensions }) => ($noFixedDimensions ? UI.spacing.sm : '')};

  svg {
    font-size: ${UI.text.xl};
  }
`;

export const SuffixButtonElement = styled.div<SuffixButtonProps>`
  padding: ${pxToRem(7)} ${pxToRem(11)};
  outline: none;
  border: none;
  user-select: none;
  border-radius: ${UI.radius.sm};
  font-size: ${({ size }) => (size === 'sm' ? UI.text.sm : UI.text.md)};
  color: ${colors.white};
  background-color: ${({ variant }) => (variant === 'dark' ? colors.gray900 : colors.gray700)};
  font-weight: ${({ variant }) => (variant === 'dark' ? 400 : 500)};

  display: grid;
  place-items: center;
  line-height: 1.5;

  opacity: ${({ disabled }) => (disabled ? 0.9 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  @media (hover: hover) {
    &:hover {
      color: ${({ disabled }) => (disabled ? colors.gray300 : colors.gray100)};
    }
  }
`;

const ICON_BUTTON_DEFAULT_SIZE_PX = 42;

export const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${pxToRem(ICON_BUTTON_DEFAULT_SIZE_PX)};
  min-width: ${pxToRem(ICON_BUTTON_DEFAULT_SIZE_PX)};
  max-width: ${pxToRem(ICON_BUTTON_DEFAULT_SIZE_PX)};
  border-radius: ${UI.radius.sm};
  cursor: pointer;
  background-color: ${colors.gray700};

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.gray600};
    }
  }

  &:disabled {
    background-color: ${colors.gray800};
  }
`;
