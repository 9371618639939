// eslint-disable-next-line @typescript-eslint/no-var-requires
const path = require('path');

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'fr', 'zh', 'es', 'pt', 'ko', 'ja'],
  },
  localePath: path.resolve('./.locales'),
  react: { useSuspense: false },
};
