import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Logout = ({ scale = 1, color = '#ffffff', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.934 5.04c.206-2.4 1.44-3.38 4.14-3.38h.086c2.98 0 4.174 1.193 4.174 4.173v4.347c0 2.98-1.194 4.174-4.174 4.174h-.086c-2.68 0-3.914-.967-4.134-3.327M10 8H2.415M3.9 5.767 1.665 8l2.233 2.234"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Logout;
