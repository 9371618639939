import { MouseEventHandler, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Chevron } from 'assets/svgs';
import ClickOutsideWrapper from 'components/ClickOutsideWrapper';
import FormattedAmount from 'components/FormattedAmount';
import usePreference from 'hooks/usePreference';
import { useBalance } from 'hooks/wallet/useBalances';
import { useTranslation } from 'next-i18next';
import { PlayOption } from 'redux/slices/providerGameSlice';

import CryptoIcon from '../../CurrencyIcons/CryptoIcon';
import { BalanceBtn, BalanceBtnWrapper } from '../styles';

import BalancePopup from './BalancePopup';

export const BALANCE_MAX_DIGITS = 11;

const BalanceButton = () => {
  const { t } = useTranslation();
  const { cryptoPreference } = usePreference();
  const [balanceDropdownOpen, setBalanceDropdownOpen] = useState(false);
  const playOption = useSelector((state: AppState) => state.providerGame.playOption);
  const userPreferenceBalance = useBalance(cryptoPreference);
  const balanceBtnRef = useRef<HTMLDivElement>(null);

  const balanceBtnHandler: MouseEventHandler<HTMLDivElement> = e => {
    setBalanceDropdownOpen(!balanceDropdownOpen);
    e.stopPropagation();
  };

  const closeHandler = (element: HTMLElement) => {
    if (!balanceBtnRef.current?.contains(element)) {
      setBalanceDropdownOpen(false);
    }
  };

  return (
    <BalanceBtnWrapper>
      <BalanceBtn id="balance-button" ref={balanceBtnRef} onClick={balanceBtnHandler}>
        <CryptoIcon currency={cryptoPreference} />
        {playOption === PlayOption.REAL ? (
          t('nav.balance.button')
        ) : (
          <FormattedAmount
            value={userPreferenceBalance}
            currency={cryptoPreference}
            maxDigits={BALANCE_MAX_DIGITS}
            hideTooltip={balanceDropdownOpen}
          />
        )}

        <Chevron direction={balanceDropdownOpen ? 'up' : 'down'} />
      </BalanceBtn>

      {balanceDropdownOpen && (
        <ClickOutsideWrapper onClickOutside={closeHandler}>
          <BalancePopup closePopup={() => setBalanceDropdownOpen(false)} />
        </ClickOutsideWrapper>
      )}
    </BalanceBtnWrapper>
  );
};

export default BalanceButton;
