import { useCallback } from 'react';
import BigNumber from 'bignumber.js';

import {
  CRYPTO_DECIMALS,
  CRYPTO_DECIMALS_PLACEHOLDER,
  FIAT_DECIMALS,
} from '../constants/decimalPlaces';
import { Currency } from '../generated/graphql';

import { usePrice } from './usePrice';

export const useConversionBase = (rate: string) => {
  const cryptoToFiat = useCallback(
    (
      cryptoAmount: string,
      roundingMode: BigNumber.RoundingMode = BigNumber.ROUND_HALF_UP,
    ): string => {
      // using big number to make the zero check more robust
      if (!cryptoAmount || BigNumber(cryptoAmount).isZero())
        return BigNumber('0').toFixed(FIAT_DECIMALS);

      return BigNumber(cryptoAmount).multipliedBy(rate).toFixed(FIAT_DECIMALS, roundingMode);
    },
    [rate],
  );

  const fiatToCrypto = useCallback(
    (fiatAmount: string) => {
      // if rate is 0, return placeholder to prevent division by 0
      if (!fiatAmount || BigNumber(fiatAmount).isZero() || BigNumber(rate).isZero())
        return CRYPTO_DECIMALS_PLACEHOLDER;

      return BigNumber(fiatAmount).dividedBy(rate).toFixed(CRYPTO_DECIMALS);
    },
    [rate],
  );

  return {
    cryptoToFiat,
    fiatToCrypto,
  };
};

export const useConversion = (cryptoCurrency: Currency) => {
  const preferenceRate = usePrice(cryptoCurrency);
  return useConversionBase(preferenceRate);
};
