import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import { ComponentSize } from 'components/commonStyles';
import styled from 'styled-components';

const getProgressBarHeight = ({ $size }: { $size: Omit<ComponentSize, 'lg'> }) => {
  if ($size === 'sm') return pxToRem(6);
  if ($size === 'md') return pxToRem(8);
  return pxToRem(12);
};

export const ProgressBarContainer = styled.div<{
  $size: Omit<ComponentSize, 'lg'>;
}>`
  background: ${colors.gray700};
  overflow: hidden;
  width: 100%;
  border-radius: 333px;
  height: ${getProgressBarHeight};
`;

export const ProgessBarColor = styled.div<{
  $precentage: string;
  $size: Omit<ComponentSize, 'lg'>;
}>`
  height: ${getProgressBarHeight};
  width: ${({ $precentage }) => ($precentage ? $precentage : '0')};
  background: ${colors.success};
  border-radius: ${pxToRem(12)};
`;
