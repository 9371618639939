import React from 'react';

import { SvgProps } from '..';

const USD = ({ scale = 1, viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M15.9566 8.00028C15.9566 12.3953 12.3938 15.9582 7.99886 15.9582C3.60384 15.9582 0.0410156 12.3953 0.0410156 8.00028C0.0410156 3.60531 3.60384 0.0424805 7.99886 0.0424805C12.3938 0.0424805 15.9566 3.60531 15.9566 8.00028Z"
        fill="#41E733"
      />
      <path
        d="M15.9566 8.00028C15.9566 12.3953 12.3938 15.9582 7.99886 15.9582C3.60384 15.9582 0.0410156 12.3953 0.0410156 8.00028C0.0410156 3.60531 3.60384 0.0424805 7.99886 0.0424805C12.3938 0.0424805 15.9566 3.60531 15.9566 8.00028Z"
        fill="#2A9F2F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.22309 3C7.77784 3 8.33055 3 8.88531 3C8.85267 3.39066 8.818 3.78344 8.78741 4.1741C9.43394 4.22293 10.0642 4.43949 10.6311 4.76008C10.3864 5.40127 10.1396 6.04246 9.89284 6.68365C9.41559 6.26115 8.78741 6.0276 8.16127 6.03609C8.00423 6.04034 7.84107 6.07219 7.71054 6.16561C7.62488 6.22718 7.56165 6.32272 7.5433 6.42887C7.52902 6.5138 7.5331 6.60934 7.57797 6.68577C7.62896 6.7707 7.7187 6.82166 7.80843 6.8535C7.99199 6.92144 8.18983 6.92569 8.38154 6.94692C8.83228 7.00425 9.28506 7.08493 9.71132 7.25265C10.0417 7.38429 10.3578 7.58174 10.5842 7.87049C10.7984 8.13588 10.9228 8.47134 10.9697 8.81529C11.0248 9.21231 11.0085 9.6242 10.8902 10.0106C10.7984 10.3206 10.6413 10.6093 10.4374 10.8535C10.0152 11.3631 9.39519 11.6688 8.75886 11.7473C8.77925 12.1656 8.80373 12.5817 8.82616 13C8.29996 13 7.7758 13 7.25164 13C7.27204 12.586 7.29855 12.172 7.31691 11.758C6.89472 11.7431 6.47662 11.6454 6.08299 11.4904C5.7016 11.3397 5.33856 11.1401 5 10.9066C5.24882 10.2399 5.49765 9.57325 5.74851 8.90446C6.032 9.15287 6.33385 9.38429 6.66426 9.56051C7.01914 9.74947 7.41277 9.87049 7.81251 9.87473C8.00219 9.87686 8.19799 9.86624 8.37746 9.7983C8.4876 9.75584 8.59569 9.68153 8.64464 9.56476C8.69563 9.44161 8.69155 9.28662 8.61405 9.17622C8.55898 9.10191 8.48148 9.05096 8.3999 9.01486C8.28976 8.96815 8.17555 8.94268 8.0593 8.92357C7.631 8.85138 7.20065 8.79193 6.78663 8.6518C6.45419 8.53928 6.1299 8.38217 5.86476 8.14013C5.69752 7.98938 5.55883 7.80467 5.46093 7.5966C5.31409 7.28662 5.25902 6.93631 5.2631 6.59236C5.2631 6.20807 5.33856 5.81741 5.52212 5.47983C5.6812 5.17834 5.92391 4.93206 6.1972 4.7431C6.52761 4.51592 6.89676 4.36093 7.27204 4.23567C7.25572 3.82378 7.23941 3.41189 7.22309 3Z"
        fill="white"
      />
    </svg>
  );
};

export default USD;
