import { NextRouter } from 'next/router';

import { ModalType } from './GlobalModal.type';

export const openGlobalModal = ({
  router,
  tab,
  type,
  id,
  name,
  currency,
  amount,
  pathname,
  routerOptions = { shallow: true },
}: {
  router: NextRouter;
  pathname?: string;
  routerOptions?: {
    shallow?: boolean;
    locale?: string | false;
    scroll?: boolean;
    unstable_skipClientCache?: boolean;
  };
} & Partial<Omit<ModalType, 'isOpen' | 'classNames'>>) => {
  router
    .replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          ...(tab ? { 'md-tab': tab } : {}),
          ...(id ? { 'md-id': id } : {}),
          ...(type ? { modal: type } : {}),
          ...(name ? { 'md-name': name } : {}),
          ...(currency ? { 'md-currency': currency } : {}),
          ...(amount ? { 'md-amount': amount } : {}),
        },
      },
      pathname,
      routerOptions,
    )
    .catch();
};
