import { Language } from 'constants/languages';

const cookieMaxAge = 60 * 60 * 24 * 365; // 1 year

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookieValue = parts.pop();
    if (cookieValue) {
      return cookieValue.split(';').shift();
    }

    return '';
  }

  return '';
}

export function setLanguagePreferenceCookie(language: Language) {
  document.cookie = `language-preference=${language}; max-age=${cookieMaxAge}; path=/`;
}
