import React, { useMemo } from 'react';
import {
  Currency,
  SportsMarketStatus,
  SportsMatchPhase,
  SportsMatchState,
} from 'generated/graphql';
import { BetSlipErrors } from 'hooks/useSportsBetSlipCheck';
import omit from 'lodash/omit';

import { OddsFormat } from '../../../../redux/slices/browserPreferenceSlice';
import { BetSlipItem, BetSlipStatus } from '../../../../redux/slices/sportsBetSlice';
import { calculateOddsTotal } from '../../../../utils/calculateOddsTotal';
import { calculateEstimatePayout } from '../../../../utils/calculateSportsBet';
import { getSportBetOdds } from '../../../../utils/getSportBetOdds';
import { notNullable } from '../../../../utils/notNullable';
import { isSportEventClosed, isSportEventInPlay } from '../../../../utils/sportEventStatusCheck';
import {
  BetSlipWarnings,
  SportsBetTicketStatus,
  SportsBetTicketStatusType,
} from '../BetsGroup.type';

import { BetBrandFooter } from './BetBrandFooter';
import { BetContent } from './betContent';
import { BetGameEventInfo } from './BetGameEventInfo';
import { BetOddsStakeAndStatus } from './BetOddsStakeAndStatus';
import { BetTitleHeader } from './BetTitleHeader';

import styles from './SingleBetSlip.module.scss';

interface BetTicketProps {
  currency: Currency;
  status?: SportsBetTicketStatusType;
  hasMultiSelections?: boolean;
  odds?: ReturnType<typeof getSportBetOdds>;
  publicView?: boolean;
  betSlipStatus?: BetSlipStatus;
  bets: BetSlipItem[];
  betIds: string[];
  amount: string;
  bet?: BetSlipItem;
  oddsFormat: OddsFormat;
  content: BetContent;
  settlementPayout?: string;
  cashoutOddsDecimal?: string;
  shouldShowCashOut?: boolean;
  errors: BetSlipErrors;
  onChangeBetAmount?: (value: string) => void;
  onClickViewDetail?: () => void;
  onClickCashOut?: () => void;
  onClickHeader?: () => void;
  onAcceptNewOdds?: (id: string) => void;
  onAcceptMultiNewOdds?: () => void;
  onRemove?: () => void;
  warnings: BetSlipWarnings;
  matchStates?: SportsMatchState[];
  showOddsWarningMessage?: boolean;
}

export const SingleBetSlip = ({
  currency,
  onChangeBetAmount,
  hasMultiSelections,
  betSlipStatus,
  bets,
  bet,
  betIds,
  amount,
  status,
  odds,
  publicView,
  oddsFormat,
  content,
  settlementPayout,
  cashoutOddsDecimal,
  shouldShowCashOut = false,
  errors,
  onClickViewDetail,
  onClickCashOut,
  onClickHeader,
  onAcceptNewOdds,
  onAcceptMultiNewOdds,
  onRemove,
  warnings,
  matchStates,
  showOddsWarningMessage = false,
}: BetTicketProps) => {
  const { outputDecimal, outputText } = useMemo(
    () =>
      calculateOddsTotal({
        bets: betIds
          .map(id => {
            const betDetail = bets.find(bet => bet.id === id);
            return betDetail ? omit(betDetail, 'totalOddsDecimal') : undefined;
          })
          .filter(notNullable),
        oddsFormat,
      }),
    [bets, oddsFormat, betIds],
  );
  const estimatePayoutOutput = useMemo(
    () =>
      settlementPayout ||
      calculateEstimatePayout({
        betAmount: amount,
        totalOdds: odds?.outputDecimal ?? outputDecimal,
      }),
    [amount, odds?.outputDecimal, outputDecimal, settlementPayout],
  );

  const betDetail =
    bet ||
    [...bets].sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())[0];

  const matchStatistics = matchStates?.find(
    item => item?.fixtureId === betDetail?.fixtureId,
  ) as SportsMatchState;

  const isLive = isSportEventInPlay({
    startTime: betDetail?.startTime,
    matchStatistics,
  });

  const isEventClosed = isSportEventClosed({
    matchPhase: matchStatistics?.matchSummary?.matchPhase as SportsMatchPhase,
  });

  const hideLiveBadge =
    betDetail.status === SportsBetTicketStatus.CASHED_OUT ||
    betDetail.status === SportsBetTicketStatus.VOIDED;
  const shouldShowLiveBadge =
    isLive && betDetail.marketStatus !== SportsMarketStatus.RESULTED && !hideLiveBadge;

  return (
    <div className={styles.wrapper}>
      <BetTitleHeader
        betSlipStatus={betSlipStatus}
        bet={betDetail}
        content={content}
        status={status}
        hasMultiSelections={hasMultiSelections}
        errors={errors}
        onClickHeader={onClickHeader}
        showLiveBadge={shouldShowLiveBadge}
        warnings={warnings}
        onRemove={onRemove}
        showOddsWarningMessage={showOddsWarningMessage}
      />

      <BetGameEventInfo
        betIds={betIds}
        content={content}
        odds={odds}
        betSlipStatus={betSlipStatus}
        oddsOutputText={outputText}
        hasMultiSelections={hasMultiSelections}
        bet={betDetail}
        status={status}
        errors={errors}
        onChangeBetAmount={onChangeBetAmount}
        onAcceptNewOdds={onAcceptNewOdds}
        onAcceptMultiNewOdds={onAcceptMultiNewOdds}
        estimatePayoutOutput={estimatePayoutOutput}
        amount={amount}
        currency={currency}
        bets={bets}
        isLive={isLive}
        isEventClosed={isEventClosed}
        matchState={matchStatistics}
        showOddsWarningMessage={showOddsWarningMessage}
      />

      <BetOddsStakeAndStatus
        bet={{
          ...betDetail,
          status: status ?? betDetail?.status,
        }}
        status={status}
        content={content}
        errors={errors}
        odds={odds}
        publicView={publicView}
        betSlipStatus={betSlipStatus}
        currency={currency}
        amount={amount}
        estimatePayoutOutput={estimatePayoutOutput}
        oddsOutputText={outputText}
        settlementPayout={settlementPayout}
        cashoutOddsDecimal={cashoutOddsDecimal}
        shouldShowCashOut={shouldShowCashOut}
        onClickCashOut={onClickCashOut}
        hasMultiSelections={hasMultiSelections}
        isEventClosed={isEventClosed}
      />

      <BetBrandFooter
        status={status}
        errors={errors}
        betSlipStatus={betSlipStatus}
        onClickViewDetail={onClickViewDetail}
        content={content}
      />
    </div>
  );
};
