import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CHAT from 'constants/chat';
import { DocumentChangeType } from 'firebase/firestore';
import uniqBy from 'lodash/uniqBy';
import { Message } from 'types/types';

export interface ChatState {
  messages: Message[];
  isLoading: boolean;
  firstMessageFetchedTimestamp: Message['timestamp'];
  isAtBottom: boolean;
  newMessagesCount: number;
}

const initialState: ChatState = {
  messages: [],
  isLoading: false, // initial load of messages, so subscription to updates can begin after initial load
  firstMessageFetchedTimestamp: { seconds: '', nanoseconds: '' },
  isAtBottom: true,
  newMessagesCount: 0,
};

const compareTimestamps = (a: Message['timestamp'], b: Message['timestamp']) => {
  if (+a.seconds !== +b.seconds) return +a.seconds - +b.seconds;
  if (+a.nanoseconds !== +b.nanoseconds) return +a.nanoseconds - +b.nanoseconds;
  return 0;
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessages: (state: ChatState, action: PayloadAction<Message[]>) => {
      const defaultTimestamp: Message['timestamp'] = {
        seconds: Math.round(new Date().getTime() / 1000).toString(),
        nanoseconds: '0',
      };

      state.messages = action.payload;
      state.firstMessageFetchedTimestamp = action.payload[0]?.timestamp ?? defaultTimestamp;
    },
    addNewMessage: (
      state: ChatState,
      action: PayloadAction<{ message: Message; type: DocumentChangeType }>,
    ) => {
      const { message, type } = action.payload;

      let newMessages = [...state.messages];

      switch (type) {
        case 'added': {
          // sometimes there are duplicate messages, so we need to check if the message is already in the array
          if (newMessages.some(msg => msg.id === message.id)) {
            break;
          }

          state.newMessagesCount++;
          newMessages = uniqBy([...newMessages, message], msg => msg.id);
          break;
        }

        case 'modified': {
          const index = newMessages.findIndex(msg => msg.id === message.id);

          if (index) {
            newMessages[index] = message;
          } else {
            newMessages = uniqBy([...state.messages, message], msg => msg.id);
          }
          break;
        }

        case 'removed': {
          newMessages = newMessages.filter(msg => msg.id !== message.id);
          break;
        }

        default:
          break;
      }

      state.messages = newMessages.sort((a, b) => compareTimestamps(a.timestamp, b.timestamp));

      if (state.isAtBottom) {
        state.messages = state.messages.slice(-CHAT.MAX_DISPLAY_MESSAGES);
      } else {
        state.messages = state.messages.slice(-CHAT.MAX_DISPLAY_MESSAGES_WHEN_SCROLLED_UP);
      }
    },

    setLoadingMessages: (state: ChatState, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoading = action.payload.isLoading;
    },
    resetChat: () => initialState,
    setIsAtBottom: (state: ChatState, action: PayloadAction<{ isAtBottom: boolean }>) => {
      if (state.isAtBottom !== action.payload.isAtBottom) {
        state.isAtBottom = action.payload.isAtBottom;

        if (!state.isAtBottom) {
          state.newMessagesCount = 0;
        }
      }
    },
  },
});

export const { setMessages, addNewMessage, setLoadingMessages, resetChat, setIsAtBottom } =
  chatSlice.actions;

export default chatSlice.reducer;
