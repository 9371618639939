import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Sports, SportsMarketSelection, SportsMarketStatus } from 'generated/graphql';

import isString from '../../utils/isString';
import { SportsBetTicketStatusType } from '../../views/BetSlip/components/BetsGroup.type';

export interface BetSlipItem extends Omit<SportsMarketSelection, 'status'> {
  href: string;
  categoryId: string;
  competitionId: string;
  fixtureId: string;
  fixtureName: string;
  sport: Sports;
  marketId: string;
  marketName: string;
  startTime: string;
  betAmount: string;
  estPayout: string;
  typeName: string;
  status: SportsBetTicketStatusType;
  marketStatus: SportsMarketStatus;
  cashOutStatus: SportsMarketStatus | string;
  cashOutEnabled: boolean;
  totalOddsDecimal?: string;
  multiple?: boolean;
  errorMessage?: string;
  placedBetId?: string;
  marketExpiryTime: string;
  inPlay: boolean;
}

export enum BetSlipStatus {
  EMPTY = 'EMPTY',
  ADDING_BET = 'ADDING_BET',
  PENDING_BET = 'PENDING_BET',
  RESULTED_BET = 'RESULTED_BET',
  CONFIRMING_BET = 'CONFIRMING_BET',
  BET_PLACED = 'BET_PLACED',
  BET_PLACING = 'BET_PLACING',
  BET_VIEW_INFO = 'BET_VIEW_INFO',
}

export type MultiBet = {
  amount: string;
  selectionIds: string[];
};

export interface SportsPagesState {
  categoryIds: string[];
  competitionIds: string[];
  fixtureIds: {
    id: string;
    startTime: string;
  }[];
}

type PositionType = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export interface SportsBetState {
  betSlips: BetSlipItem[];
  multiBet: MultiBet;
  betSlipStatus: BetSlipStatus;
  betSlipPlacedErrors: {
    message: string;
    id: string;
    maxAllowedStake?: string;
    isMultiBet?: boolean;
  }[];
  betSlipOddChangesIds: string[];
  sportsPages: SportsPagesState;
  mobileBetAddedPosition: PositionType;
  betAddedPosition: PositionType;
  hasCashedOutNotification: {
    single: boolean;
    multi: boolean;
  };
}

const POSITION_DEFAULT_VALUE = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
};

export const initialState: SportsBetState = {
  betSlips: [],
  betSlipStatus: BetSlipStatus.EMPTY,
  multiBet: {
    amount: '',
    selectionIds: [],
  },
  betSlipPlacedErrors: [],
  betSlipOddChangesIds: [],
  sportsPages: {
    categoryIds: [],
    competitionIds: [],
    fixtureIds: [],
  },
  mobileBetAddedPosition: POSITION_DEFAULT_VALUE,
  betAddedPosition: POSITION_DEFAULT_VALUE,
  hasCashedOutNotification: {
    single: false,
    multi: false,
  },
};

export const sportsBetSlice = createSlice({
  name: 'sportsBet',
  initialState,
  reducers: {
    setBetSlip: (state: SportsBetState, action: PayloadAction<{ betSlips: BetSlipItem[] }>) => {
      state.betSlips = action.payload.betSlips;
    },
    resetBetSlip: (state: SportsBetState) => {
      state.betSlips = [];
      state.betSlipStatus = BetSlipStatus.EMPTY;
      state.multiBet = initialState.multiBet;
      state.betSlipPlacedErrors = [];
    },
    updateMultiBet: (state: SportsBetState, action: PayloadAction<Partial<MultiBet>>) => {
      if (isString(action.payload.amount)) {
        state.multiBet.amount = action.payload.amount;
      }
      if (action.payload.selectionIds) {
        state.multiBet.selectionIds = action.payload.selectionIds;
      }
    },
    updateAddBetPosition: (
      state: SportsBetState,
      action: PayloadAction<SportsBetState['betAddedPosition']>,
    ) => {
      state.betAddedPosition = {
        top: action.payload.top,
        left: action.payload.left,
        width: action.payload.width,
        height: action.payload.height,
      };
    },
    updateMobileBetPosition: (
      state: SportsBetState,
      action: PayloadAction<SportsBetState['mobileBetAddedPosition']>,
    ) => {
      state.mobileBetAddedPosition = {
        top: action.payload.top,
        left: action.payload.left,
        width: action.payload.width,
        height: action.payload.height,
      };
    },
    changeBetSlipStatus: (
      state: SportsBetState,
      action: PayloadAction<{ betSlipStatus: BetSlipStatus }>,
    ) => {
      state.betSlipStatus = action.payload.betSlipStatus;
    },
    changeBetSlipDropdown: (
      state: SportsBetState,
      action: PayloadAction<{ betSlipOption: BetSlipStatus }>,
    ) => {
      if (
        action.payload.betSlipOption === BetSlipStatus.PENDING_BET ||
        action.payload.betSlipOption === BetSlipStatus.RESULTED_BET
      ) {
        state.betSlips = initialState.betSlips;
        state.multiBet = initialState.multiBet;
      }

      state.betSlipStatus = action.payload.betSlipOption;
    },
    setBetSlipPlacedError: (
      state: SportsBetState,
      action: PayloadAction<{ errors: SportsBetState['betSlipPlacedErrors'] }>,
    ) => {
      state.betSlipPlacedErrors = action.payload.errors;
    },
    setSportsPagesParams: (
      state: SportsBetState,
      action: PayloadAction<{
        categoryIds: string[];
        competitionIds: string[];
        fixtureIds: {
          id: string;
          startTime: string;
        }[];
      }>,
    ) => {
      state.sportsPages = {
        categoryIds: action.payload.categoryIds,
        competitionIds: action.payload.competitionIds,
        fixtureIds: action.payload.fixtureIds,
      };
    },
    updateBetSlips: (
      state: SportsBetState,
      action: PayloadAction<{
        betSlips: BetSlipItem[];
      }>,
    ) => {
      state.betSlips = action.payload.betSlips;
    },
    updateBetSlipOddsChangesIds: (state: SportsBetState, action: PayloadAction<{ id: string }>) => {
      state.betSlipOddChangesIds = state.betSlipOddChangesIds.includes(action.payload.id)
        ? state.betSlipOddChangesIds
        : [...state.betSlipOddChangesIds, action.payload.id];
    },
    acceptBetSlipOddsChange: (state: SportsBetState, action: PayloadAction<{ id: string }>) => {
      state.betSlipOddChangesIds = state.betSlipOddChangesIds.filter(
        item => item !== action.payload.id,
      );
    },
    resetBetSlipOddsChangesIds: (state: SportsBetState) => {
      state.betSlipOddChangesIds = [];
    },
    updateBetSlipCashedOutNotification: (
      state: SportsBetState,
      action: PayloadAction<{ isMulti: boolean; success: boolean }>,
    ) => {
      state.hasCashedOutNotification = {
        single: !action.payload.isMulti ? action.payload.success : false,
        multi: action.payload.isMulti ? action.payload.success : false,
      };
    },
    resetBetSlipCashedOutNotification: (state: SportsBetState) => {
      state.hasCashedOutNotification = {
        single: false,
        multi: false,
      };
    },
  },
});

export const {
  setBetSlip,
  resetBetSlip,
  changeBetSlipStatus,
  changeBetSlipDropdown,
  updateMultiBet,
  setBetSlipPlacedError,
  setSportsPagesParams,
  updateAddBetPosition,
  updateMobileBetPosition,
  updateBetSlips,
  updateBetSlipOddsChangesIds,
  acceptBetSlipOddsChange,
  resetBetSlipOddsChangesIds,
  updateBetSlipCashedOutNotification,
  resetBetSlipCashedOutNotification,
} = sportsBetSlice.actions;

export default sportsBetSlice.reducer;
