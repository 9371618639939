import React from 'react';
import { Delete } from 'assets/svgs';
import clsx from 'clsx';
import { BetSlipErrors } from 'hooks/useSportsBetSlipCheck';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import colors from '../../../../assets/styles/colors';
import SportsBetSlip from '../../../../assets/svgs/sportsBetSlip.svg';
import { SportsBetSelectionStatus, SportsMarketStatus } from '../../../../generated/graphql';
import { BetSlipItem, BetSlipStatus } from '../../../../redux/slices/sportsBetSlice';
import SportIcon from '../../../SportsHome/components/SportIcon';
import {
  BetSlipWarnings,
  OtherStatus,
  SportsBetTicketStatus,
  SportsBetTicketStatusType,
} from '../BetsGroup.type';

import { BetContent } from './betContent';
import { getErrorText, shouldShowBetSlipError } from './betSlipErrors';
import { BetTitleHeaderErrors } from './BetTitleHeaderErrors';
import { BetTitleHeaderWarnings } from './BetTitleHeaderWarnings';
import { getBetViewConfig } from './getBetViewConfig';

import styles from './BetTitleHeader.module.scss';

type Props = {
  content: BetContent;
  betSlipStatus?: BetSlipStatus;
  hasMultiSelections?: boolean;
  bet: BetSlipItem;
  status?: SportsBetTicketStatusType;
  errors: BetSlipErrors;
  showOddsWarningMessage?: boolean;
  showLiveBadge?: boolean;
  warnings: BetSlipWarnings;
  onClickHeader?: () => void;
  onRemove?: () => void;
};

export function BetTitleHeader({
  betSlipStatus,
  bet,
  content,
  hasMultiSelections,
  status,
  errors,
  warnings,
  errors: { placedBetError },
  showOddsWarningMessage = false,
  showLiveBadge = false,
  onClickHeader,
  onRemove,
}: Props) {
  const { t } = useTranslation();
  let updatedStatus = hasMultiSelections ? status ?? bet?.status : bet?.status;

  if (updatedStatus === SportsBetSelectionStatus.WON) {
    updatedStatus = OtherStatus.WIN;
  }

  if (updatedStatus === SportsBetSelectionStatus.LOST) {
    updatedStatus = OtherStatus.LOSS;
  }

  const {
    shouldHideStatusBadgeAndShowOdds,
    isCashedOut,
    isPartialPending,
    isInBetInfoView,
    isInBetAddView,
    isInBetAddOrConfirmed,
  } = getBetViewConfig({
    betSlipStatus,
    status: updatedStatus,
  });

  if (!updatedStatus && isCashedOut && isInBetInfoView) {
    updatedStatus = bet.status;
  }

  const showError = shouldShowBetSlipError(errors);
  const errorText = getErrorText(errors);
  const hasHeader =
    (isInBetAddView && showOddsWarningMessage) ||
    (isInBetAddView && warnings.exceedMaxAllowedStakeAmount) ||
    (!isInBetInfoView && isPartialPending) ||
    (betSlipStatus === BetSlipStatus.BET_PLACED &&
      ((placedBetError && !warnings.exceedMaxAllowedStakeAmount) ||
        showError ||
        warnings.exceedMaxAllowedStakeAmount));

  const hasMarketNotOpenInBetAdd =
    isInBetAddOrConfirmed && bet.marketStatus !== SportsMarketStatus.OPEN;

  return (
    <>
      <BetTitleHeaderErrors
        isInBetAddView={isInBetAddView}
        placedBetError={placedBetError}
        betSlipStatus={betSlipStatus}
        exceedMaxAllowedStakeAmount={warnings.exceedMaxAllowedStakeAmount}
        errorText={errorText}
        showError={showError}
      />

      {!showError && (
        <BetTitleHeaderWarnings
          content={content}
          isInBetAddView={isInBetAddView}
          isInBetInfoView={isInBetInfoView}
          isPartialPending={isPartialPending}
          showOddsWarningMessage={showOddsWarningMessage}
          exceedMaxAllowedStakeAmount={warnings.exceedMaxAllowedStakeAmount}
        />
      )}

      <div className={styles.container}>
        <div
          className={clsx(styles.betTicketHeader, {
            [styles.betTicketHeaderNoBorderRadius]: !hasMarketNotOpenInBetAdd && hasHeader,
          })}
        >
          {hasMultiSelections ? (
            <>
              <SportsBetSlip />
              <div className={styles.betTitleSection}>{content.standardMulti()}</div>
            </>
          ) : (
            <Link href={bet?.href || ''} passHref>
              <a
                className={styles.betTicketGameNameWrapper}
                onClick={onClickHeader}
                title={bet?.fixtureName}
              >
                <SportIcon scale={0.6} color={colors.primaryViolet} sport={bet?.sport} />
                <span className={styles.betTicketGameName}>{bet?.fixtureName}</span>
              </a>
            </Link>
          )}
          {!hasMultiSelections && (
            <div className={styles.liveBadgeAndRemoveBlock}>
              {showLiveBadge && <span className={styles.liveBadge}>{t('txtLive')}</span>}
              {onRemove && isInBetAddView && (
                <span className={styles.removeButton} onClick={onRemove}>
                  <Delete color={colors.gray300} />
                </span>
              )}
            </div>
          )}
          {shouldHideStatusBadgeAndShowOdds && updatedStatus && (
            <span
              className={clsx(styles.betStatus, {
                [styles.betStatusError]:
                  updatedStatus == SportsBetTicketStatus.LOST || updatedStatus === OtherStatus.LOSS,
                [styles.betStatusWarning]: updatedStatus === SportsBetTicketStatus.DEAD_HEAT,
                [styles.betStatusCashedOut]: updatedStatus === SportsBetTicketStatus.CASHED_OUT,
                [styles.betStatusCancelled]:
                  updatedStatus === SportsBetTicketStatus.CANCELLED ||
                  updatedStatus === SportsBetTicketStatus.PUSHED ||
                  updatedStatus === SportsBetTicketStatus.VOIDED,
              })}
            >
              {content.status(updatedStatus)}
            </span>
          )}
        </div>
        <div className={styles.divider} />
      </div>
    </>
  );
}
