import React from 'react';

import { SvgProps } from './index';

export const AllSports = ({ color = '#FFFFFF' }: SvgProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.5 10.5L10.5 5.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 7.5L8.5 10.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 5.5L10.5 8.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.94385 0.944L15.0528 7.053"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.943848 8.944L7.05285 15.053"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6998 1.31C13.1738 -0.209998 7.49278 0.282002 3.89078 3.879C0.256782 7.509 -0.200218 13.151 1.31878 14.671C2.88378 16.234 8.51878 15.699 12.1238 12.102C15.6928 8.537 16.2188 2.83 14.6998 1.31Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
