import { useCallback, useEffect } from 'react';
import { captureException } from '@sentry/nextjs';
import { Language } from 'constants/languages';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useLanguagePreference } from 'hooks/useLanguagePreference';

import { CUSTOM_DATE_FORMATS, CustomDateFormats } from './customDateFormats';

const dayjsLocaleLoader: Record<Language, () => Promise<void>> = {
  [Language.EN]: async () => {},
  [Language.FR]: async () => {
    await import('dayjs/locale/fr');
  },
  [Language.ZH]: async () => {
    await import('dayjs/locale/zh');
  },
  [Language.ES]: async () => {
    await import('dayjs/locale/es');
  },
  [Language.PT]: async () => {
    await import('dayjs/locale/pt');
  },
  [Language.JA]: async () => {
    await import('dayjs/locale/ja');
  },
  [Language.KO]: async () => {
    await import('dayjs/locale/ko');
  },
};

dayjs.extend(relativeTime, {
  rounding: Math.floor,
});

dayjs.extend(localizedFormat);

type TimeInput = Date | string | number;

export const useDateFormat = () => {
  const { languagePreference } = useLanguagePreference();

  const customFormats = CUSTOM_DATE_FORMATS[languagePreference];

  useEffect(() => {
    dayjsLocaleLoader[languagePreference]()
      .then(() => {
        dayjs.locale(languagePreference);
      })
      .catch(captureException);
  }, [languagePreference]);

  const getRelativeDate = useCallback((date: TimeInput) => {
    return dayjs(date).fromNow();
  }, []);

  const getRelativeTimeRange = useCallback((startDate: TimeInput, endDate: TimeInput) => {
    return dayjs(startDate).to(endDate, true);
  }, []);

  /**
   * For formats, please refer to https://day.js.org/docs/en/display/format
   */
  const formatDate = useCallback((date: Date | string | number, format: string) => {
    return dayjs(date).format(format);
  }, []);

  const formatDateCustom = useCallback(
    (date: Date | string | number, format: keyof CustomDateFormats) => {
      const customFormat = customFormats[format];

      return dayjs(date).format(customFormat || format);
    },
    [customFormats],
  );

  return {
    getRelativeDate,
    getRelativeTimeRange,
    formatDate,
    formatDateCustom,
  };
};
