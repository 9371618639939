import React from 'react';

import { SvgProps } from '../..';

const RugbyUnion = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g id="Property 1=rugby-01" clipPath="url(#clip0_10555_127482)">
        <g id="Group">
          <path
            id="Vector"
            d="M8.25 15.75L15.75 8.25"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M8.25 11.25L12.75 15.75"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M11.25 8.25L15.75 12.75"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_4"
            d="M13.416 1.41602L22.5795 10.5795"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_5"
            d="M1.41602 13.416L10.5795 22.5795"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_6"
            d="M22.0494 1.96507C19.7604 -0.314931 11.2389 0.423069 5.83593 5.81857C0.384929 11.2636 -0.300571 19.7266 1.97793 22.0066C4.32543 24.3511 12.7779 23.5486 18.1854 18.1531C23.5389 12.8056 24.3279 4.24507 22.0494 1.96507Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10555_127482">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RugbyUnion;
