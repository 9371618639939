import React from 'react';

import { SvgProps } from '..';

const AmericanFootball = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_3036_12216)">
        <path
          d="M9.93899 0.743042C8.86837 0.459409 7.74825 0.416158 6.65893 0.616391C5.56962 0.816624 4.5381 1.25538 3.63832 1.90121C2.73855 2.54703 1.9928 3.38393 1.45454 4.3519C0.91628 5.31988 0.598846 6.39495 0.524994 7.50004H8.49999V12.988C8.49998 13.6488 8.76159 14.2828 9.22762 14.7512C9.69366 15.2197 10.3262 15.4846 10.987 15.488V15.488C11.3164 15.4898 11.6429 15.4264 11.9477 15.3015C12.2525 15.1766 12.5296 14.9927 12.7632 14.7604C12.9967 14.5281 13.182 14.2519 13.3085 13.9478C13.4349 13.6436 13.5 13.3174 13.5 12.988V12.5C13.5 9.50004 15.5 9.50004 15.5 7.50004C15.4638 5.91367 14.896 4.38539 13.8876 3.16018C12.8793 1.93497 11.4888 1.08377 9.93899 0.743042V0.743042Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.5 9.5C4.5 11.5 4.5 14.5 0.5 15.5V11.5H6.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3036_12216">
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AmericanFootball;
