import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum NavMenuType {
  CASINO = 'CASINO',
  SPORTS = 'SPORTS',
}

export enum MobileMenuName {
  MENU = 'navMenu',
  SEARCH = 'navSearch',
  CHAT = 'navChat',
  CASINO = 'navCasino',
  SPORTS = 'navSports',
  REWARDS = 'tabRewards',
  SPORTS_BET = 'txtBetSlip',
}

export interface GlobalState {
  globalSearchTxt: string;
  activeMobileNav?: MobileMenuName;
  activeNavMenuType: NavMenuType;
  userCountry: string | null;
  geoRestrictionWarningPopupCounter: number;
  isContentfulPreview: boolean;
  isTurboModeOn: boolean;
  isPersistStorePopulated: boolean;
}

const initialState: GlobalState = {
  globalSearchTxt: '',
  activeMobileNav: MobileMenuName.CASINO,
  activeNavMenuType: NavMenuType.CASINO,
  userCountry: null,
  isPersistStorePopulated: false,
  geoRestrictionWarningPopupCounter: 0,
  isContentfulPreview: false,
  isTurboModeOn: false,
};

export const globalStateSlice = createSlice({
  name: 'globalState',
  initialState,
  reducers: {
    setActiveNavMenuType: (state: GlobalState, action: PayloadAction<NavMenuType>) => {
      state.activeNavMenuType = action.payload;
    },
    setActiveMobileNav: (state: GlobalState, action: PayloadAction<MobileMenuName>) => {
      state.activeMobileNav = action.payload;
    },
    setUserCountry: (state: GlobalState, action: PayloadAction<string>) => {
      state.userCountry = action.payload;
    },
    geoRestrictionWarningPopupIncrement: (state: GlobalState) => {
      state.geoRestrictionWarningPopupCounter++;
    },
    setIsContentfulPreview: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isContentfulPreview = action.payload;
    },
    toggleTurboMode: (state: GlobalState) => {
      state.isTurboModeOn = !state.isTurboModeOn;
    },
    setPersistStorePopulated: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isPersistStorePopulated = action.payload;
    },
  },
});

export const {
  setActiveNavMenuType,
  setActiveMobileNav,
  setIsContentfulPreview,
  toggleTurboMode,
  setPersistStorePopulated,
} = globalStateSlice.actions;

export default globalStateSlice.reducer;
