import React, { ButtonHTMLAttributes, ReactNode } from 'react';

import { Icon, PrimaryButton, SecondaryButton, SuccessButton, TertiaryButton } from './styles';

export type ButtonColor = 'primary' | 'secondary' | 'tertiary' | 'success';

import { Loader } from '../loader';

import styles from './BaseButton.module.scss';

interface ButtonWrapperProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: ButtonColor;
  hasMargin?: boolean;
  fillIcon?: boolean;
  bold?: boolean;
  fontWeight?: string;
  children: React.ReactNode;
}

export const ButtonWrapper = ({
  color = 'primary',
  hasMargin = false,
  bold = false,
  fontWeight = '500',
  children,
  ...rest
}: ButtonWrapperProps) => {
  return (
    <>
      {color === 'primary' && (
        <PrimaryButton bold={bold} fontWeight={fontWeight} hasMargin={hasMargin} {...rest}>
          {children}
        </PrimaryButton>
      )}
      {color === 'secondary' && (
        <SecondaryButton bold={bold} fontWeight={fontWeight} hasMargin={hasMargin} {...rest}>
          {children}
        </SecondaryButton>
      )}
      {color === 'tertiary' && (
        <TertiaryButton bold={bold} fontWeight={fontWeight} hasMargin={hasMargin} {...rest}>
          {children}
        </TertiaryButton>
      )}
      {color === 'success' && (
        <SuccessButton bold={bold} fontWeight={fontWeight} hasMargin={hasMargin} {...rest}>
          {children}
        </SuccessButton>
      )}
    </>
  );
};

export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'value'> {
  /**
   * Button style
   */
  color?: ButtonColor;
  /**
   * Button contents
   */
  value: string | React.ReactNode;
  /**
   * Icon
   */
  icon?: Element | React.ReactNode | string;
  /**
   * Trigger submitting
   */
  /**
   * Add margin top
   */
  hasMargin?: boolean;
  /**
   * use only the dimensions of the icon instead of setting a fixed width and height
   */
  iconNoFixedDimensions?: boolean;

  /** Should icon SVG have filled property when disabled? */
  fillIcon?: boolean;

  /** Should button text be bolder? */
  bold?: boolean;

  /** Custom button text font weight */
  fontWeight?: string;
  loading?: boolean;
  loadingText?: boolean;
}

/**
 * Primary UI component for user interaction
 */
const BaseButton = ({
  color = 'primary',
  value,
  disabled,
  icon = '',
  hasMargin = false,
  fillIcon = true,
  iconNoFixedDimensions = false,
  bold = false,
  fontWeight = '500',
  loading,
  loadingText,
  ...rest
}: ButtonProps) => {
  const LoadingContent = (
    <>
      <Loader invert className={styles.loader} /> {loadingText}
    </>
  );

  const NoneLoadingContent = (
    <>
      {icon && <Icon $noFixedDimensions={iconNoFixedDimensions}>{icon as ReactNode}</Icon>}
      {value}
    </>
  );

  return (
    <ButtonWrapper
      disabled={disabled || loading}
      hasMargin={hasMargin}
      fillIcon={fillIcon}
      bold={bold}
      fontWeight={fontWeight}
      {...rest}
      color={color}
      className={`${rest.className} ${loading ? styles.loading : ''}`}
    >
      {loading ? LoadingContent : NoneLoadingContent}
    </ButtonWrapper>
  );
};

export default BaseButton;
