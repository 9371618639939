import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import BigNumber from 'bignumber.js';

export const useSportsOdds = (params: { odds: string; autoHideIndicator: boolean }) => {
  const odds = params.odds;
  const autoHideIndicator = params.autoHideIndicator;
  const [isOddsChanges, setIsOddsChanges] = useState(false);
  const [isOddsIncrease, setIsOddsIncrease] = useState(false);
  const [isOddsDecrease, setIsOddsDecrease] = useState(false);
  const prevOdds = usePrevious(odds);

  useEffect(() => {
    if (prevOdds && odds && odds !== prevOdds) {
      setIsOddsChanges(true);
      const oddsBN = BigNumber(odds);
      setIsOddsIncrease(oddsBN.isGreaterThan(prevOdds));
      setIsOddsDecrease(oddsBN.isLessThan(prevOdds));
    }
  }, [odds, prevOdds]);

  // Reset trigger odds changes after 3 seconds, to hide the arrow increase and decrease icon
  // If config enable accept odds changes is false, then don't reset the trigger until user press accept
  useEffect(() => {
    if (isOddsChanges && autoHideIndicator) {
      const timeout = setTimeout(() => {
        setIsOddsChanges(false);
        setIsOddsIncrease(false);
        setIsOddsDecrease(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [autoHideIndicator, isOddsChanges]);

  return {
    isOddsChanges,
    isOddsIncrease,
    isOddsDecrease,
    odds,
    prevOdds,
  };
};
