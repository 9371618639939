import React, { Component, ErrorInfo, ReactNode } from 'react';
import { captureMessage } from '@sentry/nextjs';

import colors from '../assets/styles/colors';
import { TelegramMonotone, Twitter } from '../assets/svgs';
import InstagramWhite from '../assets/svgs/brands/InstagramWhite';
import HeadPhone from '../assets/svgs/HeadPhone';
import { INSTAGRAM_URL, TELEGRAM_URL, TWITTER_URL } from '../constants/social';

type Props = {
  children?: ReactNode;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error) {
      try {
        captureMessage(`App crash ${error.message || ''} ${JSON.stringify(errorInfo) || ''}`, {
          level: 'error',
        });
      } catch {
        captureMessage(`App crash ${error.message || ''}`, {
          level: 'error',
        });
      }
    }
  }

  public render() {
    return this.state.hasError ? (
      <main className={'error-boundary-root'}>
        <div>
          <img src={'/icons/shuffle-logo.svg'} alt={'logo'} height={'72.65'} />
          <h1>Well, this is awkward</h1>
          <p>
            Sorry, something isn’t currently working properly with{' '}
            <a href={'https://www.shuffle.com'}>Shuffle.com</a>. Please try again shortly and{' '}
            <a href={'mailto:support@shuffle.com'}>contact us</a> if this problem persists.
          </p>

          <div className={'error-boundary-buttons'}>
            <button onClick={() => location.reload()}>Reload page</button>
            <a href={'mailto:support@shuffle.com'}>Contact us</a>
          </div>

          <div className={'error-boundary-social'}>
            {[
              {
                key: 'email',
                url: 'mailto:support@shuffle.com',
                icon: <HeadPhone />,
              },
              {
                key: 'twitter',
                url: TWITTER_URL,
                icon: <Twitter scale={1.25} color={colors.white} />,
              },
              { key: 'instagram', url: INSTAGRAM_URL, icon: <InstagramWhite /> },
              {
                key: 'telegram',
                url: TELEGRAM_URL,
                icon: <TelegramMonotone />,
              },
            ].map(({ key, url, icon }) => (
              <a key={key} href={url} target={'_blank'} rel="noreferrer">
                {icon}
              </a>
            ))}
          </div>
        </div>
      </main>
    ) : (
      this.props.children
    );
  }
}
