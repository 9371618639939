import React from 'react';

import { SvgProps } from '../';

const Original = ({ scale = 1, color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2754_92380)">
        <path
          d="M4.26672 1H5.20006C6.74628 1 8.00006 2.72045 8.00006 4.26667V4.73334"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.09998 7.19995V9.69995"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.86719 8.4668H5.36719"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0006 9.86668V7.53334C15.0006 6.79074 14.7056 6.07854 14.1805 5.55344C13.6554 5.02834 12.9432 4.73334 12.2006 4.73334H3.80062C3.05802 4.73334 2.34582 5.02834 1.82072 5.55344C1.29562 6.07854 1.00062 6.79074 1.00062 7.53334V12.8982C0.989515 13.3526 1.12808 13.7981 1.39499 14.1661C1.66189 14.5341 2.04234 14.8041 2.47778 14.9347C2.93191 15.0543 3.41371 15.0096 3.83807 14.8084C4.26242 14.6072 4.602 14.2625 4.7968 13.8352L6.17627 11.2667H9.82499L11.1659 13.75C11.2984 14.0598 11.5044 14.3326 11.766 14.545C12.0276 14.7574 12.3369 14.9029 12.6673 14.9689C12.9532 15.019 13.2467 15.0059 13.527 14.9307C13.8074 14.8554 14.0679 14.7197 14.2903 14.5332C14.5127 14.3466 14.6917 14.1137 14.8146 13.8507C14.9375 13.5877 15.0014 13.3011 15.0019 13.0108L15.0006 9.86668Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="11" cy="9" r="1" fill={color} />
        <circle cx="12.55" cy="6.94995" r="0.75" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_2754_92380">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Original;
