import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
  onClick?: () => void;
}

const Setting = ({ onClick, scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: onClick ? 'pointer' : 'inherit' }}
    >
      <g clipPath="url(#clip0_3269_121858)">
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.5 8C15.5 7.24659 14.8898 6.63636 14.1364 6.63636H13.2759C13.147 6.13727 12.9507 5.66682 12.695 5.23318L13.3032 4.625C13.8357 4.0925 13.8357 3.22932 13.3032 2.69682C12.7707 2.16432 11.9075 2.16432 11.375 2.69682L10.7668 3.305C10.3332 3.04932 9.86273 2.85295 9.36364 2.72409V1.86364C9.36364 1.11091 8.75341 0.5 8 0.5C7.24659 0.5 6.63636 1.11023 6.63636 1.86364V2.72409C6.13727 2.85295 5.66682 3.04932 5.23318 3.305L4.625 2.69682C4.0925 2.16432 3.22932 2.16432 2.69682 2.69682C2.16432 3.22932 2.16432 4.0925 2.69682 4.625L3.305 5.23318C3.04932 5.66682 2.85295 6.13727 2.72409 6.63636H1.86364C1.11091 6.63636 0.5 7.24659 0.5 8C0.5 8.75341 1.11023 9.36364 1.86364 9.36364H2.72409C2.85295 9.86273 3.04932 10.3332 3.305 10.7668L2.69682 11.375C2.16432 11.9075 2.16432 12.7707 2.69682 13.3032C3.22932 13.8357 4.0925 13.8357 4.625 13.3032L5.23318 12.695C5.66614 12.9507 6.13727 13.147 6.63636 13.2759V14.1364C6.63636 14.8891 7.24659 15.5 8 15.5C8.75341 15.5 9.36364 14.8898 9.36364 14.1364V13.2759C9.86273 13.147 10.3332 12.9507 10.7668 12.695L11.375 13.3032C11.9075 13.8357 12.7707 13.8357 13.3032 13.3032C13.8357 12.7707 13.8357 11.9075 13.3032 11.375L12.695 10.7668C12.9507 10.3339 13.147 9.86273 13.2759 9.36364H14.1364C14.8891 9.36364 15.5 8.75341 15.5 8Z"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 10.0454C9.12967 10.0454 10.0455 9.12966 10.0455 7.99998C10.0455 6.87031 9.12967 5.95453 8 5.95453C6.87033 5.95453 5.95454 6.87031 5.95454 7.99998C5.95454 9.12966 6.87033 10.0454 8 10.0454Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3269_121858">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Setting;
