import React from 'react';

import { SvgProps } from '../..';

const IceHockey = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M21.1596 15.15L13.3596 18.4935L4.9791 3.01501C4.69036 2.49904 4.21012 2.11746 3.64224 1.95279C3.07437 1.78812 2.4645 1.85361 1.94452 2.13508C1.42455 2.41655 1.03627 2.89139 0.863649 3.4569C0.691031 4.02241 0.747951 4.63314 1.0221 5.15701L11.2506 23.25H21.7506C22.1484 23.25 22.53 23.092 22.8113 22.8107C23.0926 22.5294 23.2506 22.1478 23.2506 21.75V16.5255C23.2501 16.2775 23.1881 16.0336 23.0702 15.8154C22.9523 15.5973 22.7821 15.4118 22.575 15.2755C22.3678 15.1392 22.1301 15.0564 21.8831 15.0345C21.6361 15.0126 21.3875 15.0523 21.1596 15.15Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6.75C20.8995 6.75 23.25 6.07843 23.25 5.25C23.25 4.42157 20.8995 3.75 18 3.75C15.1005 3.75 12.75 4.42157 12.75 5.25C12.75 6.07843 15.1005 6.75 18 6.75Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 5.25V8.25C12.75 9.078 15.1005 9.75 18 9.75C20.8995 9.75 23.25 9.078 23.25 8.25V5.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IceHockey;
