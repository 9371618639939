import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChatRoom, Currency, FiatCurrency } from '../../generated/graphql';
import { checkValidOddsType } from '../../utils/checkValidOddsType';

export enum OddsFormat {
  Decimal = 'decimal',
  Fractional = 'fractional',
  American = 'american',
  HongKong = 'hongkong',
  Indonesian = 'indonesian',
  Malaysian = 'malaysian',
}

/** Opening one right-sidebar closes the other. */
export enum RightSidebarVariant {
  CHAT = 'CHAT',
  VIP = 'VIP',
  BET_SLIP = 'BET_SLIP',
  NONE = 'NONE',
}

export interface BrowserPreferencesState {
  muteGameSounds: boolean;
  showGamesMaxBet: boolean;
  displayInFiat: boolean;
  displayInFiatSet: boolean;
  hideZeroBalance: boolean; // not in use for now
  cryptoPreferenceSet: boolean;
  cryptoPreference: Currency;
  fiatPreference: FiatCurrency;
  fiatPreferenceSet: boolean;
  cookiesAccepted: boolean;
  chatRoom: ChatRoom;
  chatRoomSet: boolean;
  isLeftSidebarExpanded: boolean;
  oddsFormat: OddsFormat;
  showBetSlipTooltip: boolean;
  gameHotkeysEnabled: boolean;
  /** When `NONE`, the right sidebar is closed. */
  rightSidebarVariant: RightSidebarVariant;
  activityQueryLimit: string;
  enabledAcceptOddsChanges: boolean;
  enabledQuickBet: boolean;
}

export const initialState: BrowserPreferencesState = {
  muteGameSounds: false,
  showGamesMaxBet: false,
  displayInFiat: false,
  displayInFiatSet: false,
  hideZeroBalance: false,
  cryptoPreferenceSet: false,
  cryptoPreference: Currency.ETH,
  fiatPreference: FiatCurrency.USD,
  fiatPreferenceSet: false,
  cookiesAccepted: false,
  chatRoom: ChatRoom.ENGLISH,
  chatRoomSet: false,
  isLeftSidebarExpanded: true,
  oddsFormat: OddsFormat.Decimal,
  showBetSlipTooltip: true,
  gameHotkeysEnabled: false,
  rightSidebarVariant: RightSidebarVariant.NONE,
  activityQueryLimit: '10',
  enabledAcceptOddsChanges: false,
  enabledQuickBet: true,
};

export const browserPreferenceSlice = createSlice({
  name: 'browserPreference',
  initialState,
  reducers: {
    toggleDisplayInFiat: (state: BrowserPreferencesState) => {
      state.displayInFiat = !state.displayInFiat;
      state.displayInFiatSet = true;
    },
    toggleMuteGameSounds: (state: BrowserPreferencesState) => {
      state.muteGameSounds = !state.muteGameSounds;
    },
    toggleShowGamesMaxBet: (state: BrowserPreferencesState) => {
      state.showGamesMaxBet = !state.showGamesMaxBet;
    },
    setCryptoPreference: (state: BrowserPreferencesState, action: PayloadAction<Currency>) => {
      state.cryptoPreferenceSet = true;
      state.cryptoPreference = action.payload;
    },
    setFiatPreference: (state: BrowserPreferencesState, action: PayloadAction<FiatCurrency>) => {
      state.fiatPreference = action.payload;
      state.fiatPreferenceSet = true;
    },
    setChatRoom: (state: BrowserPreferencesState, action: PayloadAction<{ room: ChatRoom }>) => {
      state.chatRoom = action.payload.room;
      state.chatRoomSet = true;
    },
    toggleLeftSidebar: (
      state: BrowserPreferencesState,
      action: PayloadAction<{ isLeftSidebarExpanded: boolean }>,
    ) => {
      state.isLeftSidebarExpanded = action.payload.isLeftSidebarExpanded;
    },
    toggleGameHotkeys: (state: BrowserPreferencesState) => {
      state.gameHotkeysEnabled = !state.gameHotkeysEnabled;
    },
    setCookiesAccepted: (state: BrowserPreferencesState, action: PayloadAction<boolean>) => {
      state.cookiesAccepted = action.payload;
    },
    setOddsFormat: (state: BrowserPreferencesState, action: PayloadAction<OddsFormat>) => {
      if (action.payload && checkValidOddsType(action.payload)) {
        state.oddsFormat = action.payload;
      }
    },
    hideBetSlipTooltip: (state: BrowserPreferencesState) => {
      state.showBetSlipTooltip = false;
    },
    toggleVipSidebar: (
      state: BrowserPreferencesState,
      action: PayloadAction<{ open: boolean }>,
    ) => {
      state.rightSidebarVariant = action.payload.open
        ? RightSidebarVariant.VIP
        : RightSidebarVariant.NONE;
    },
    toggleBetSlipSidebar: (
      state: BrowserPreferencesState,
      action: PayloadAction<{ open: boolean }>,
    ) => {
      state.rightSidebarVariant = action.payload.open
        ? RightSidebarVariant.BET_SLIP
        : RightSidebarVariant.NONE;
    },
    setRightSidebar: (
      state: BrowserPreferencesState,
      action: PayloadAction<{ variant: RightSidebarVariant }>,
    ) => {
      state.rightSidebarVariant = action.payload.variant;
    },
    updateBrowserPreference: (
      state: BrowserPreferencesState,
      action: PayloadAction<Partial<BrowserPreferencesState>>,
    ) => {
      if (action.payload.activityQueryLimit) {
        state.activityQueryLimit = action.payload.activityQueryLimit;
      }
    },
    toggleAcceptOddsChanges: (state: BrowserPreferencesState) => {
      state.enabledAcceptOddsChanges = !state.enabledAcceptOddsChanges;
    },
    toggleQuickBet: (state: BrowserPreferencesState) => {
      state.enabledQuickBet = !state.enabledQuickBet;
    },
    updateBrowserPreferenceStore: (_, action: PayloadAction<BrowserPreferencesState>) =>
      action.payload,
    setInitialDefault: (
      state: BrowserPreferencesState,
      action: PayloadAction<{
        fiatPreference?: FiatCurrency;
        room?: ChatRoom;
        displayInFiat?: boolean;
      }>,
    ) => {
      if (action.payload.fiatPreference) {
        state.fiatPreference = action.payload.fiatPreference;
      }
      if (action.payload.room) {
        state.chatRoom = action.payload.room;
      }
      if (action.payload.displayInFiat) {
        state.displayInFiat = action.payload.displayInFiat;
      }
    },
  },
});

export const {
  updateBrowserPreferenceStore,
  toggleDisplayInFiat,
  toggleShowGamesMaxBet,
  toggleMuteGameSounds,
  setCryptoPreference,
  setFiatPreference,
  toggleLeftSidebar,
  toggleGameHotkeys,
  setCookiesAccepted,
  setChatRoom,
  setOddsFormat,
  hideBetSlipTooltip,
  toggleVipSidebar,
  toggleBetSlipSidebar,
  setRightSidebar,
  updateBrowserPreference,
  toggleAcceptOddsChanges,
  toggleQuickBet,
  setInitialDefault,
} = browserPreferenceSlice.actions;

export default browserPreferenceSlice.reducer;
