import { RoundFlag } from 'assets/svgs';
import SelectInput, { SelectOption } from 'components/FormControls/SelectInput';
import { Language } from 'constants/languages';
import { useLanguagePreference } from 'hooks/useLanguagePreference';
import { useRouter } from 'next/router';
import { setLanguagePreferenceCookie } from 'utils/appCookie';

interface LanguageToggleProps {
  placement?: 'top' | 'bottom';
  enableAutoPlacement?: boolean;
}

// Flags here https://github.com/HatScripts/circle-flags
export const langOptions: SelectOption<Language>[] = [
  {
    label: 'English',
    value: Language.EN,
    icon: <RoundFlag country="UK" />,
  },
  {
    label: 'Français',
    value: Language.FR,
    icon: <RoundFlag country="FR" />,
  },
  {
    label: '中文',
    value: Language.ZH,
    icon: <RoundFlag country="CN" />,
  },
  {
    label: 'Español',
    value: Language.ES,
    icon: <RoundFlag country="ES" />,
  },
  {
    label: 'Português',
    value: Language.PT,
    icon: <RoundFlag country="BR" />,
  },
  {
    label: '한국어',
    value: Language.KO,
    icon: <RoundFlag country="KR" />,
  },
  {
    label: '日本語',
    value: Language.JA,
    icon: <RoundFlag country="JP" />,
  },
];

const LanguageToggle = ({
  placement = 'bottom',
  enableAutoPlacement = false,
}: LanguageToggleProps) => {
  const router = useRouter();

  const { languagePreference } = useLanguagePreference();

  return (
    <SelectInput
      options={langOptions}
      onChange={val => {
        setLanguagePreferenceCookie(val as Language);

        router
          .replace(router.asPath, router.asPath, {
            locale: val,
          })
          .catch();
      }}
      selected={languagePreference}
      placement={placement}
      enableAutoPlacement={enableAutoPlacement}
    />
  );
};

export default LanguageToggle;
