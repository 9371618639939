import React from 'react';

import { SvgProps } from '..';

const Roulette = ({ scale = 1, viewBox = '0 0 16 16', color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99994 12.5C10.4852 12.5 12.4999 10.4853 12.4999 8C12.4999 5.51472 10.4852 3.5 7.99994 3.5C5.51466 3.5 3.49994 5.51472 3.49994 8C3.49994 10.4853 5.51466 12.5 7.99994 12.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49988 9.5L9.49988 6.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49988 6.5L9.49988 9.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 0.5V3.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.69702 2.69699L4.81802 4.81799"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M0.5 8H3.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.69702 13.303L4.81802 11.182"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 15.5V12.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.3029 13.303L11.1819 11.182"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.5001 8H12.5001" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.3029 2.69699L11.1819 4.81799"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Roulette;
