import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Transaction = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5195 6.96635L13.9995 4.48633L11.5195 2.00635"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 4.48633H14" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.47998 9.0332L2 11.5132L4.47998 13.9932"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 11.5127H2" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Transaction;
