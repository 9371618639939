import { mediaQueries } from 'assets/styles/mediaQueries';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

export const AvatarElement = styled.div<{ $background: string; $size: number }>`
  background: ${({ $background }) => $background};
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $size }) =>
    mediaQueries(Breakpoint.MD)`
    width: ${$size}px;
    height: ${$size}px;
  `};
`;
