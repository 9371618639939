import React, { memo, useCallback } from 'react';
import { signOut } from 'apollo/utils';
import {
  Affiliate,
  Chevron,
  Crown,
  DoubleTicks,
  Logout,
  Setting,
  ShieldLock,
  Support,
  Transaction,
  Wallet,
} from 'assets/svgs';
import Avatar from 'components/Avatar';
import { openGlobalModal } from 'components/Modal/openGlobalModal';
import { INTERCOM_WIDGET_CLASSNAME } from 'constants/intercom';
import useAvatar from 'hooks/useAvatar';
import useModal, { ModalUrl } from 'hooks/useModal';
import { useMyProfile } from 'hooks/useMyProfile';
import { useSetting } from 'hooks/useSetting';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ModalTypes } from 'redux/slices/modalSlice';
import UserMenuVipCard from 'views/Vip/UserMenuVipCard';

import {
  ArrowIcon,
  ExpandMenu,
  ExpandMenuWrapper,
  MenuIcon,
  MenuItem,
  MenuList,
  Name,
  NameWrapper,
  UserMenuWrapper,
} from './styles';

interface MenuData {
  label: string;
  icon: JSX.Element;
  className: string;
  url?: string;
}

const MENU_DATA: MenuData[] = [
  {
    label: 'userMenuVIP',
    url: '/vip-program',
    icon: <Crown />,
    className: '',
  },
  {
    label: 'walletVault',
    url: '',
    icon: <ShieldLock />,
    className: '',
  },
  {
    label: 'userMenuAffiliateProgram',
    url: '/affiliate/overview',
    icon: <Affiliate />,
    className: '',
  },
  {
    label: 'userMenuTransactions',
    url: '/transactions/deposits',
    icon: <Transaction />,
    className: '',
  },
  {
    label: 'userMenuRedeemCode',
    icon: <DoubleTicks />,
    url: '',
    className: '',
  },
  {
    label: 'userMenuSettings',
    url: '/settings/account',
    icon: <Setting />,
    className: '',
  },
  {
    label: 'userMenuSupport',
    icon: <Support />,
    className: INTERCOM_WIDGET_CLASSNAME,
  },
];

export const ExpandMenuElement = ({
  show = false,
  onClick,
}: {
  show?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { openGeneralModal, closeModal, openModalWithSearchParams, openModal } = useModal();
  const { removeActiveSession } = useSetting();

  const handleLogout = useCallback(async () => {
    closeModal();

    try {
      await removeActiveSession();
    } catch (e) {
      // TODO: send error to sentry
    } finally {
      signOut();
      window.location.href = '/';
    }
  }, [closeModal, removeActiveSession]);

  const onConfirmLogout = useCallback(() => {
    openGeneralModal({
      header: t('msgConfirmLogout'),
      icon: 'logout',
      confirmBtnText: t('btnYes'),
      onConfirm: handleLogout,
      cancelBtnText: t('btnNo'),
      onCancel: closeModal,
    });
  }, [closeModal, handleLogout, openGeneralModal, t]);

  return (
    <ExpandMenuWrapper $show={show} onClick={onClick}>
      <ExpandMenu>
        <MenuList>
          <UserMenuVipCard />
          <MenuItem onClick={() => openModalWithSearchParams(ModalUrl.WALLET, { tab: 'deposit' })}>
            <MenuIcon>
              <Wallet />
            </MenuIcon>
            {t('userMenuWallet')}
          </MenuItem>
          {MENU_DATA.map((item: MenuData) =>
            item.url ? (
              <Link key={item.label} href={item.url} passHref>
                <a>
                  <MenuItem className={item.className}>
                    <MenuIcon>{item.icon}</MenuIcon>
                    {t(item.label)}
                  </MenuItem>
                </a>
              </Link>
            ) : (
              <React.Fragment key={item.label}>
                {item.label === 'userMenuRedeemCode' && (
                  <MenuItem onClick={() => openModal(ModalTypes.REDEEM_CODE_LOOKUP)}>
                    <MenuIcon>{item.icon}</MenuIcon>
                    {t(item.label)}
                  </MenuItem>
                )}
                {item.label === 'walletVault' && (
                  <MenuItem
                    onClick={() =>
                      openGlobalModal({
                        router,
                        type: 'vault',
                      })
                    }
                  >
                    <MenuIcon>{item.icon}</MenuIcon>
                    {t(item.label)}
                  </MenuItem>
                )}
                {!['userMenuRedeemCode', 'walletVault'].includes(item.label) && (
                  <MenuItem className={item.className}>
                    <MenuIcon>{item.icon}</MenuIcon>
                    {t(item.label)}
                  </MenuItem>
                )}
              </React.Fragment>
            ),
          )}
          <MenuItem onClick={onConfirmLogout}>
            <MenuIcon>
              <Logout />
            </MenuIcon>
            {t('userMenuLogout')}
          </MenuItem>
        </MenuList>
      </ExpandMenu>
    </ExpandMenuWrapper>
  );
};

const UserMenu = () => {
  const avatar = useAvatar();
  const { myProfile } = useMyProfile();

  return (
    <UserMenuWrapper>
      <Avatar face={avatar?.face} background={avatar?.background} />

      <NameWrapper>
        <Name>{myProfile?.username}</Name>
        <ArrowIcon>
          <Chevron />
        </ArrowIcon>
      </NameWrapper>
      <ExpandMenuElement />
    </UserMenuWrapper>
  );
};

export default memo(UserMenu);
