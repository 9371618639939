import React, { InputHTMLAttributes } from 'react';
import { Relative } from 'components/commonStyles';

import {
  CheckboxCheckMark,
  CheckboxContainer,
  CheckboxInput,
  ErrorMessage,
  FormControlWrapper,
} from './styles';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Input label
   */
  label?: string | JSX.Element;
  /**
   * Error message
   */
  error?: string;
}

/**
 * Primary UI component for user interaction
 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, error, ...rest }: CheckboxProps, ref) => {
    return (
      <FormControlWrapper>
        <CheckboxContainer>
          <CheckboxInput ref={ref} type="checkbox" {...rest} />
          <CheckboxCheckMark />
          <Relative>{label}</Relative>
        </CheckboxContainer>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormControlWrapper>
    );
  },
);

export default Checkbox;
