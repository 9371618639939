import React from 'react';
import { useMount } from 'react-use';

import styles from './License.module.scss';

const AntillephoneLicense = () => {
  useMount(() => {
    window.apg_135e6de2_7372_4c94_8491_cf709e7f9b92.init();
  });

  return (
    <div
      className={styles.licenseLink}
      id="apg-135e6de2-7372-4c94-8491-cf709e7f9b92"
      data-apg-seal-id="135e6de2-7372-4c94-8491-cf709e7f9b92"
      data-apg-image-size="256"
      data-apg-image-type="basic-light-large"
    />
  );
};

export default AntillephoneLicense;
