import { Currency } from '../generated/graphql';

import { useConversion } from './useConversion';
import usePreference from './usePreference';

export const useDisplayAmount = (cryptoAmount: string, currency: Currency, invert?: boolean) => {
  const { displayInFiat } = usePreference();
  const { cryptoToFiat } = useConversion(currency);
  const asFiat = invert ? !displayInFiat : displayInFiat;

  if (asFiat) return cryptoToFiat(cryptoAmount);

  return cryptoAmount;
};
