import React from 'react';

interface Props {
  scale?: number;
}

const TurkeyFlag = ({ scale = 1 }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M247.082 85.7066C229.73 36.6271 182.963 1.44875 127.952 1.4066H127.761C72.7497 1.44875 25.98 36.6271 8.6311 85.7066C3.9665 98.8939 1.40659 113.073 1.40659 127.857C1.40659 142.64 3.96931 156.822 8.6311 170.007C25.9941 219.114 72.8031 254.307 127.857 254.307C182.91 254.307 229.719 219.114 247.082 170.007C251.744 156.819 254.307 142.64 254.307 127.857C254.307 113.073 251.747 98.8939 247.082 85.7066Z"
        fill="#E30A17"
      />
      <path
        d="M119.713 176.56C92.3466 176.56 70.1617 154.754 70.1617 127.854C70.1617 100.954 92.3466 79.1481 119.713 79.1481C134.44 79.1481 147.628 85.4986 156.704 95.5247C145.748 78.3781 126.348 66.9723 104.227 66.9723C70.0184 66.9723 42.2893 94.2293 42.2893 127.857C42.2893 161.484 70.0184 188.741 104.227 188.741C126.356 188.741 145.762 177.327 156.715 160.169C147.642 170.203 134.449 176.56 119.713 176.56Z"
        fill="#F3F4F5"
      />
      <path
        d="M178.229 99.4138L177.728 121.919L156.732 127.621L177.431 134.991L176.93 155.631L190.43 139.512L210.827 146.588L199.028 129.292L213.427 112.092L191.329 118.185L178.229 99.4138Z"
        fill="#F3F4F5"
      />
    </svg>
  );
};

export default TurkeyFlag;
