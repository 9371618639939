import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Support = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.64003 12.3267V10.38C3.64003 9.73337 4.1467 9.15336 4.8667 9.15336C5.51337 9.15336 6.09337 9.66003 6.09337 10.38V12.2534C6.09337 13.5534 5.01337 14.6334 3.71337 14.6334C2.41337 14.6334 1.33336 13.5467 1.33336 12.2534V8.1467C1.26003 4.40003 4.22003 1.3667 7.9667 1.3667C11.7134 1.3667 14.6667 4.40003 14.6667 8.07337V12.18C14.6667 13.48 13.5867 14.56 12.2867 14.56C10.9867 14.56 9.9067 13.48 9.9067 12.18V10.3067C9.9067 9.66003 10.4134 9.08003 11.1334 9.08003C11.78 9.08003 12.36 9.5867 12.36 10.3067V12.3267"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Support;
