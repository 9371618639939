import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

import colors from '../../../assets/styles/colors';
import { OddsFormat, setOddsFormat } from '../../../redux/slices/browserPreferenceSlice';
import { checkValidOddsType } from '../../../utils/checkValidOddsType';
import { SelectInput, SelectOption } from '../../FormControls/SelectInput';

type Props = {
  mode?: 'normal' | 'compat';
};

export const options: SelectOption<OddsFormat>[] = [
  {
    label: 'Decimal',
    value: OddsFormat.Decimal,
  },
  {
    label: 'Fractional',
    value: OddsFormat.Fractional,
  },
  {
    label: 'American',
    value: OddsFormat.American,
  },
  {
    label: 'Indonesian',
    value: OddsFormat.Indonesian,
  },
  {
    label: 'Hong Kong',
    value: OddsFormat.HongKong,
  },
  {
    label: 'Malaysian',
    value: OddsFormat.Malaysian,
  },
];

const SelectedItem = styled.div`
  color: white;
  text-transform: capitalize;

  & > span {
    color: ${colors.primaryViolet};
    font-weight: 600;
  }
`;

export function OddsFormatPreference({ mode }: Props) {
  const selected = useSelector((state: AppState) => state.browserPreference.oddsFormat);
  const isCompat = mode === 'compat';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedLabel = options.find(option => option.value === selected)?.label || '';

  const validateInvalidFormat = useCallback(() => {
    if (selected && checkValidOddsType(selected)) {
      return;
    }

    dispatch(setOddsFormat(OddsFormat.Decimal));
  }, [dispatch, selected]);

  useEffect(() => validateInvalidFormat(), [validateInvalidFormat]);

  return (
    <SelectInput
      displayOption={
        <SelectedItem>
          {isCompat ? (
            selectedLabel
          ) : (
            <>
              {t('odds')}: <span>{selectedLabel}</span>
            </>
          )}
        </SelectedItem>
      }
      options={options}
      selected={selected}
      onChange={value => {
        dispatch(setOddsFormat(value));
      }}
    />
  );
}
