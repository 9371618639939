import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
  strokeWidth?: number;
  direction?: 'up' | 'down' | 'left' | 'right';
  onClick?: () => void;
}

const Chevron = ({
  onClick,
  scale = 1,
  color = '#FFFFFF',
  viewBox = '0 0 17 17',
  direction = 'down',
  strokeWidth = 1.2,
}: Props) => {
  const width = `${scale * 17}`;
  const height = `${scale * 17}`;

  let angle = 0;
  if (direction === 'up') angle = 180;
  else if (direction === 'left') angle = 90;
  else if (direction === 'right') angle = -90;

  return (
    <svg
      onClick={onClick}
      style={{ transform: `rotate(${angle}deg)` }}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m4.5 7.5 4 4 4-4"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Chevron;
