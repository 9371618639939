import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import { magicMediaQueries } from 'assets/styles/mediaQueries';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

export const ExpandMenuWrapper = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 1rem;
  display: none;
  z-index: ${UI.z.base};
  padding: ${pxToRem(10)};
  background-color: ${colors.gray900};
  border-radius: ${UI.radius.md};

  @media (hover: hover) {
    &:hover {
      display: flex;
    }
  }
`;

export const ArrowIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${pxToRem(8)};
  transition: 0.3s;
`;

export const Menu = styled.div<{ $show: boolean }>`
  position: relative;
  display: ${({ $show }) => ($show ? 'flex' : 'none')};

  ${magicMediaQueries(Breakpoint.MD)`
    display: none;
  `}

  @media (hover: hover) {
    &:hover > ${ExpandMenuWrapper} {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
      transition-delay: 0.5s;
    }

    &:hover ${ArrowIcon} {
      transform: rotate(180deg);
    }
  }
`;

export const ExpandButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface BadgeCounterProps {
  $isCollapsed: boolean;
  $counterLength: number;
}

const getBadgeCounterFontSize = ({ $counterLength }: BadgeCounterProps) => {
  if ($counterLength > 2) return pxToRem(5);
  if ($counterLength == 2) return pxToRem(6.4);
  return pxToRem(8);
};

export const BadgeCounter = styled.span<BadgeCounterProps>`
  width: ${pxToRem(12)};
  height: ${pxToRem(12)};
  background-color: ${colors.error};
  color: ${colors.white};
  position: absolute;
  font-weight: 500;
  font-size: ${getBadgeCounterFontSize};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: ${({ $isCollapsed }) => ($isCollapsed ? pxToRem(24) : pxToRem(8))};
  left: ${pxToRem(20)};
`;

export const DotIcon = styled.span`
  width: ${pxToRem(6)};
  height: ${pxToRem(6)};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${pxToRem(12)};
  right: ${pxToRem(12)};
`;

export const MenuWrapper = styled.div<{ $show: boolean }>`
  display: none;

  ${({ $show }) => magicMediaQueries(Breakpoint.XS)`
    display: ${$show ? 'none' : 'flex'};
    column-gap: 0.625rem;
  `};

  ${magicMediaQueries(Breakpoint.MD)`
    display: flex;
  `}
`;

export const IconNotification = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  min-width: 1rem;
  min-height: 1rem;
  border-radius: 50%;
  cursor: default;
  font-weight: 700;
  display: grid;
  place-items: center;
  pointer-events: none;
  color: black;
  background: ${colors.gold};
  font-size: ${UI.text.md};
`;
