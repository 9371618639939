import React from 'react';

import { SvgProps } from './index';

export const SportsBetSlip = ({ color = '#FFFFFF' }: SvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M11.5 5.5h-7v5h7v-5Z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M13.5 8c0-1.209.859-2.218 2-2.45V3.5a1 1 0 0 0-1-1h-13a1 1 0 0 0-1 1v2.05a2.5 2.5 0 0 1 0 4.9v2.05a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1v-2.05a2.502 2.502 0 0 1-2-2.45Z"
      />
    </svg>
  );
};
