import { BetSlipErrors } from 'hooks/useSportsBetSlipCheck';

import {
  Currency,
  SportsBetSelectionStatus,
  SportsBetStatus,
  SportsMatchState,
} from '../../../generated/graphql';
import { OddsFormat } from '../../../redux/slices/browserPreferenceSlice';
import { BetSlipItem, BetSlipStatus, MultiBet } from '../../../redux/slices/sportsBetSlice';
import { getSportBetOdds } from '../../../utils/getSportBetOdds';

import { BetContent } from './BetTicket/betContent';

export enum OtherStatus {
  TRADING = 'TRADING',
  DEAD_HEAT = 'DEAD_HEAT',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  LOSS = 'LOSS',
  WIN = 'WIN',
}

export type SportsBetTicketStatusType = SportsBetSelectionStatus | SportsBetStatus | OtherStatus;

export const SportsBetTicketStatus = {
  ...SportsBetSelectionStatus,
  ...SportsBetStatus,
  ...OtherStatus,
} as const;

export type BetSlipWarnings = Partial<{
  hasOddsChanges: boolean;
  exceedMaxAllowedStakeAmount: boolean;
}>;

export type Props = {
  currency?: Currency;
  actions?: {
    onHeaderClick?: () => void;
    onUpdateAmount?: (value: string) => void;
    onUpdateMultiBetAmount?: (payload: Partial<MultiBet>) => void;
    onClickViewDetail?: () => void;
    onClickCashOut?: () => void;
    onClickHeader?: () => void;
    onAcceptNewOdds?: (id: string) => void;
    onAcceptMultiNewOdds?: () => void;
    onRemove?: () => void;
  };
  status?: SportsBetTicketStatusType;
  betSlipStatus?: BetSlipStatus;
  odds?: ReturnType<typeof getSportBetOdds>;
  publicView?: boolean;
  bets: BetSlipItem[];
  betIds: string[];
  amount: string;
  oddsFormat: OddsFormat;
  currencyPreference: Currency;
  content: BetContent;
  settlementPayout?: string;
  cashoutOddsDecimal?: string;
  shouldShowCashOut?: boolean;
  errors?: BetSlipErrors;
  warnings?: BetSlipWarnings;
  showOddsWarningMessage?: boolean;
  matchStates?: SportsMatchState[];
};
