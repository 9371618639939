import { magicMediaQueries, mediaQueries } from 'assets/styles/mediaQueries';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

import { ContainerProps } from './Container';

type ContainerStyledProps = Omit<ContainerProps, 'className' | 'children'>;

export const MAX_CONTENT_WIDTH = '80rem';

export const ContainerWrapper = styled.section<ContainerStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  padding: ${({ padding }) => padding?.mobile ?? '3.125vw'};

  ${({ padding }) => mediaQueries(Breakpoint.SM)`
    padding: ${padding?.desktop ?? UI.page.padding.desktop};
  `}

  ${magicMediaQueries(Breakpoint.LG)`
    max-width: ${MAX_CONTENT_WIDTH};
  `}

  ${magicMediaQueries(Breakpoint.XL)`
    padding-left: 0;
    padding-right: 0;
  `}
`;
