import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';

export type PercentageInputMode = 'reset' | 'increaseBy';

export type AutoBetFormProps = {
  onWinMode: PercentageInputMode;
  onLossMode: PercentageInputMode;
  onWinPercentage: string;
  onLossPercentage: string;
  // These are the sources of truth for the auto bet PnL
  stopOnProfit: string;
  stopOnLoss: string;
};

export const defaultAutoBetFormValues: AutoBetFormProps = {
  onWinMode: 'reset',
  onLossMode: 'reset',
  onWinPercentage: '0.00',
  onLossPercentage: '0.00',
  stopOnProfit: '0.00',
  stopOnLoss: '0.00',
};

export interface AutobetState {
  betCount: string;
  totalPnL: number;
  formData: AutoBetFormProps;
  hasPendingBet: boolean;
}

const initialState: AutobetState = {
  betCount: '0',
  totalPnL: 0,
  formData: defaultAutoBetFormValues,
  hasPendingBet: false,
};

const originalGameSlice = createSlice({
  name: 'originalGame',
  initialState,
  reducers: {
    setAutoBetFormData: (state: AutobetState, action: PayloadAction<AutoBetFormProps>) => {
      state.formData = action.payload;
    },

    // runs after each run of autobet
    setAutoBetRoundResult: (state: AutobetState, action: PayloadAction<number>) => {
      if (state.betCount !== '0') {
        // if infinity, don't decrease bet count
        state.betCount = (Number(state.betCount) - 1).toString();
      }

      state.totalPnL = BigNumber(state.totalPnL).plus(action.payload).toNumber();
    },

    updateBetCount: (state: AutobetState, action: PayloadAction<string>) => {
      state.betCount = action.payload;
    },

    updatePendingBet: (state: AutobetState, action: PayloadAction<boolean>) => {
      state.hasPendingBet = action.payload;
    },

    // call it whenever the user leaves the original game page
    resetGame: () => {
      return initialState;
    },
  },
});

export const {
  setAutoBetFormData,
  setAutoBetRoundResult,
  resetGame,
  updateBetCount,
  updatePendingBet,
} = originalGameSlice.actions;

export default originalGameSlice.reducer;
