import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import Link from 'next/link';

import colors from '../../assets/styles/colors';
import { SvgProps } from '../../assets/svgs';
import { updateMobileBetPosition } from '../../redux/slices/sportsBetSlice';

import { MobileNavIcon, MobileNavItemWrapper, MobileNavText } from './styles';

import styles from './MobileNavItemComponent.module.scss';

interface MobileMenuItemProps {
  name: string;
  icon?: React.FC<SvgProps>;
  active?: boolean;
  disabled?: boolean;
  href?: string;
  onClick?: () => void;
  indicatorNumber?: number;
  isSportBetButton: boolean;
}

export const MobileNavItemComponent = ({
  name,
  icon: Icon,
  active = false,
  disabled = false,
  href,
  onClick,
  indicatorNumber,
  isSportBetButton,
}: MobileMenuItemProps) => {
  const navButtonRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSportBetButton && navButtonRef.current) {
      const rect = navButtonRef.current.getBoundingClientRect();

      dispatch(
        updateMobileBetPosition({
          width: rect.width,
          height: rect.height,
          top: rect.top,
          left: rect.left,
        }),
      );
    }
  }, [dispatch, isSportBetButton]);

  const item = (
    <MobileNavItemWrapper
      $active={active}
      $disabled={disabled}
      onClick={onClick}
      ref={navButtonRef}
    >
      <MobileNavIcon>
        {Icon && <Icon color={active ? colors.primaryViolet : colors.white} />}
      </MobileNavIcon>

      {!!indicatorNumber && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: indicatorNumber ? 1 : 0,
          }}
        >
          <span className={styles.count}>{indicatorNumber || 1}</span>
        </motion.div>
      )}
      <MobileNavText>{name}</MobileNavText>
    </MobileNavItemWrapper>
  );

  if (href) {
    return (
      <Link href={href}>
        <a>{item}</a>
      </Link>
    );
  }

  return item;
};
