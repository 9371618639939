import React, { useMemo } from 'react';
import { ArrowDecrease, ArrowIncrease } from 'assets/svgs';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { useSportsOdds } from 'hooks/useSportsOdds';

import { useFormattedOdds } from '../../hooks/useFormattedOdds';

import { OddsUpdate, OddsWrapper } from './styles';

import styles from './Odds.module.scss';

export interface OddsProps {
  oddsNumerator: string | number;
  oddsDenominator: string | number;
  showOddsChanges?: boolean;
  showColorOddsChanges?: boolean;
  autoHideIndicator?: boolean;
  totalOddsDecimal?: string;
  oddsDisplayPosition?: 'left' | 'right';
}

const Odds = ({
  oddsNumerator,
  oddsDenominator,
  showOddsChanges = false,
  oddsDisplayPosition = 'right',
  showColorOddsChanges = false,
  autoHideIndicator = true,
  totalOddsDecimal,
}: OddsProps) => {
  const { getFormattedOdds } = useFormattedOdds();

  const oddsFormatted = useMemo(() => {
    return getFormattedOdds({
      ...(totalOddsDecimal ? { oddDecimal: BigNumber(totalOddsDecimal) } : {}),
      oddsNumerator,
      oddsDenominator,
    });
  }, [getFormattedOdds, oddsDenominator, oddsNumerator, totalOddsDecimal]);

  const { isOddsChanges, isOddsIncrease, isOddsDecrease } = useSportsOdds({
    odds: oddsFormatted.outputDecimal.toString(),
    autoHideIndicator: autoHideIndicator,
  });

  const OddsChange = showOddsChanges && isOddsChanges && (
    <OddsUpdate>
      {isOddsIncrease && <ArrowIncrease />}
      {isOddsDecrease && <ArrowDecrease />}
    </OddsUpdate>
  );

  return (
    <OddsWrapper>
      {oddsDisplayPosition === 'left' && OddsChange}
      <span
        className={clsx({
          [styles.oddsIncrease]: showColorOddsChanges && isOddsIncrease,
          [styles.oddsDecrease]: showColorOddsChanges && isOddsDecrease,
        })}
      >
        {oddsFormatted.outputText}
      </span>
      {oddsDisplayPosition === 'right' && OddsChange}
    </OddsWrapper>
  );
};

export default React.memo(Odds);
