import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ArrowRight, Shuffle, ShuffleSmall } from 'assets/svgs';
import usePreference from 'hooks/usePreference';
import Link from 'next/link';

import { toggleLeftSidebar } from '../../redux/slices/browserPreferenceSlice';

import NavigationContent from './NavigationContent';
import { Logo, SidebarOverlay, SidebarSmallSizePadding } from './styles';

import styles from './DesktopNavigation.module.scss';

export const DesktopNavigation = () => {
  const dispatch = useDispatch();
  const { isLeftSidebarExpanded: isExpanded } = usePreference();

  useEffect(() => {
    if (typeof isExpanded === 'undefined') {
      dispatch(toggleLeftSidebar({ isLeftSidebarExpanded: true }));
    }
  }, [dispatch, isExpanded]);

  const toggleSidebar = useCallback(() => {
    dispatch(toggleLeftSidebar({ isLeftSidebarExpanded: !isExpanded }));
  }, [dispatch, isExpanded]);

  return (
    <>
      {isExpanded && <SidebarOverlay onClick={toggleSidebar} />}
      {isExpanded && <SidebarSmallSizePadding />}

      <nav className={`${styles.sidebar} ${isExpanded ? styles.isExpanded : ''}`}>
        <div className={styles.sidebarHeader}>
          <Link href="/" passHref title={'Shuffle'}>
            <Logo>{isExpanded ? <Shuffle /> : <ShuffleSmall />}</Logo>
          </Link>
          <button
            aria-label={'toggle site navigation'}
            className={styles.shrinkButton}
            onClick={toggleSidebar}
          >
            <ArrowRight />
          </button>
        </div>
        <NavigationContent isExpanded={isExpanded} showTooltip={!isExpanded} />
      </nav>
    </>
  );
};

export default React.memo(DesktopNavigation);
