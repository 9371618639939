import React from 'react';
import colors from 'assets/styles/colors';
import clsx from 'clsx';
import Odds from 'components/Odds';
import {
  Currency,
  SportsMarketStatus,
  SportsMatchPhase,
  SportsMatchState,
} from 'generated/graphql';
import { usePreLiveSportCountDown } from 'hooks/usePreLiveSportCountDown';
import { generateLiveMatchProgressText } from 'utils/sportsGenerator';
import { getSportsMarketSelection } from 'views/SportsHome/components/SportCardElements/sportDefaultMarketMap';

import { CrossError, TickCompleted } from '../../../../assets/svgs/16px';
import { useDateFormat } from '../../../../hooks/format/useDateFormat';
import { BetSlipErrors } from '../../../../hooks/useSportsBetSlipCheck';
import { BetSlipItem, BetSlipStatus, MultiBet } from '../../../../redux/slices/sportsBetSlice';
import {
  isSportEventClosed,
  isSportEventInPlay,
  isSportEventSuspended,
} from '../../../../utils/sportEventStatusCheck';
import { getLiveMatchScoreText } from '../../../../utils/sportsMatchStateUtils';
import { AnimateLiveEventTime } from '../../../SportsHome/components/SportCardElements/AnimateLiveEventTime';
import { SportsBetTicketStatusType } from '../BetsGroup.type';

import { BetContent } from './betContent';
import { BetTicketLegHeader } from './BetTicketLegHeader';
import { BetTicketText } from './BetTicketText';
import { getBetViewConfig } from './getBetViewConfig';

import styles from './MultiBetLeg.module.scss';

interface BetTicketProps {
  currency: Currency;
  bet: BetSlipItem & { betTicketStatus?: SportsBetTicketStatusType };
  betSlipStatus?: BetSlipStatus;
  onUpdateAmount?: (payload: Partial<MultiBet>) => void;
  onHeaderClick?: () => void;
  betIds: string[];
  content: BetContent;
  showOddsWarningMessage?: boolean;
  errors?: BetSlipErrors;
  matchStates?: SportsMatchState[];
}

export function MultiBetLeg({
  betSlipStatus,
  onUpdateAmount,
  bet,
  betIds,
  bet: {
    name,
    marketName,
    oddsNumerator,
    oddsDenominator,
    startTime,
    id,
    status,
    marketStatus,
    sport,
    fixtureId,
  },
  onHeaderClick,
  content,
  errors,
  showOddsWarningMessage = false,
  matchStates,
}: BetTicketProps) {
  const { formatDate } = useDateFormat();
  const matchStatistics = matchStates?.find(
    item => item.fixtureId === fixtureId,
  ) as SportsMatchState;

  const isLive = isSportEventInPlay({
    startTime,
    matchStatistics,
  });
  const shouldShowLiveBadge = isLive && marketStatus !== SportsMarketStatus.RESULTED;
  const liveMatchProgressText = generateLiveMatchProgressText({
    sport,
    matchStatistics,
  });
  const [time, text] = liveMatchProgressText.parts;

  const isEventSuspended = isSportEventSuspended({
    matchPhase: matchStatistics?.matchSummary?.matchPhase as SportsMatchPhase,
  });

  const isEventClosed = isSportEventClosed({
    matchPhase: matchStatistics?.matchSummary?.matchPhase as SportsMatchPhase,
  });

  const { status: marketStatusWithInPlay } = getSportsMarketSelection({
    marketStatus,
    isEventSuspended,
    isEventClosed,
  });

  const { liveScoreText, liveScoreList } = getLiveMatchScoreText({
    sport,
    matchStatistics: matchStatistics,
    isClosed: isEventClosed,
  });

  const shouldShowFinalScore = isEventClosed && matchStatistics;

  const {
    isInBetPlacedOrConfirmed,
    isInBetPendingView,
    isInBetInfoView,
    isInBetAddOrConfirmed,
    isInBetAddView,
    isCanceled,
    isWon,
    isLost,
    isVoid,
    isDeadHeat,
    shouldHideDate,
    shouldDisplayMarketStatus,
    shouldShowLiveEventInfo,
    isPending,
  } = getBetViewConfig({
    betSlipStatus,
    status,
    isLive,
  });
  const checked = betIds.some(slipId => slipId === id) || isInBetInfoView;

  const formattedStartTime = formatDate(startTime, 'lll');

  const { countdownTime, countdownStart } = usePreLiveSportCountDown({
    dateTime: startTime,
    formattedDateTime: formattedStartTime,
    shortened: true,
  });

  const startTimeForDisplay = countdownStart ? countdownTime : formattedStartTime;

  if (isInBetPlacedOrConfirmed && betSlipStatus && !checked) {
    return null;
  }

  const getColorFormattedStartTime = () => {
    if (isLive || countdownStart) return colors.success;
    if (
      betSlipStatus === BetSlipStatus.BET_PLACED ||
      isInBetPendingView ||
      (isPending && isInBetInfoView)
    )
      return colors.white;
    if (shouldHideDate) return colors.gray500;
    return colors.gray300;
  };

  const startTimeColor = getColorFormattedStartTime();
  const isSuspended =
    shouldDisplayMarketStatus && marketStatusWithInPlay === SportsMarketStatus.SUSPENDED;
  const isClosed =
    shouldDisplayMarketStatus && marketStatusWithInPlay === SportsMarketStatus.CLOSED;

  const hasMarketNotOpenInBetAdd =
    isInBetAddView && marketStatusWithInPlay !== SportsMarketStatus.OPEN;

  return (
    <div className={styles.multiLegWrapper}>
      <BetTicketLegHeader
        onUpdateAmount={onUpdateAmount}
        bet={bet}
        betSlipStatus={betSlipStatus}
        selectionIds={betIds}
        checked={checked}
        onHeaderClick={onHeaderClick}
        showLiveBadge={shouldShowLiveBadge}
      />

      <div
        className={
          isVoid || errors?.isInsufficientFunds || errors?.isMinStakeNotMet ? styles.voided : ''
        }
      >
        <div className={styles.betTicketBody}>
          <div
            className={clsx(styles.betTicketMarketBlock, {
              [styles.betTicketMarketBlockDimmed]:
                ((betSlipStatus && !checked) || isCanceled) && !isPending,
            })}
          >
            <div className={styles.legInfo}>
              <BetTicketText
                dimmed={hasMarketNotOpenInBetAdd}
                bold
                color={colors.primaryViolet}
                className={styles.legTitle}
              >
                {name}
              </BetTicketText>

              {!isSuspended && !isClosed && (
                <BetTicketText bold>
                  <Odds
                    autoHideIndicator={!showOddsWarningMessage}
                    showColorOddsChanges
                    showOddsChanges={isInBetAddView}
                    oddsNumerator={oddsNumerator}
                    oddsDenominator={oddsDenominator}
                    oddsDisplayPosition={'left'}
                  />
                </BetTicketText>
              )}
              {isSuspended && (
                <BetTicketText color={colors.warning} bold>
                  {content.suspended()}
                </BetTicketText>
              )}
              {isClosed && (
                <BetTicketText color={colors.error} bold>
                  {content.closed()}
                </BetTicketText>
              )}
            </div>

            <div
              className={clsx(styles.marketInfoWrapper, {
                [styles.dimmed]: hasMarketNotOpenInBetAdd,
              })}
            >
              <BetTicketText
                dimmed={hasMarketNotOpenInBetAdd}
                wrap
                color={colors.gray300}
                className={styles.marketName}
              >
                {marketName}
              </BetTicketText>
              {isWon && (
                <span className={clsx(styles.betLegStatus, styles.betLegStatusWon)}>
                  {content.won()} <TickCompleted />
                </span>
              )}
              {isLost && (
                <span className={clsx(styles.betLegStatus, styles.betLegStatusLost)}>
                  {content.lost()} <CrossError className={styles.loseLeg} />
                </span>
              )}
              {isVoid && (
                <span className={clsx(styles.betLegStatus, styles.betLegStatusVoidedOrCancelled)}>
                  {content.canceled()} <CrossError className={styles.voidedLeg} />
                </span>
              )}
              {isDeadHeat && (
                <span className={clsx(styles.betLegStatus, styles.betLegStatusDeadHeat)}>
                  {content.deadHeat()} <TickCompleted />
                </span>
              )}
            </div>

            {!isInBetAddOrConfirmed && (
              <>
                {shouldShowLiveEventInfo ? (
                  <div className={styles.liveEventBlock}>
                    {time && text && (
                      <BetTicketText color={startTimeColor}>
                        <AnimateLiveEventTime time={time} text={text} />
                      </BetTicketText>
                    )}
                    {liveScoreList.length > 1 ? (
                      <div className={styles.liveScoreBlock}>
                        <BetTicketText>{liveScoreList[0]}</BetTicketText>
                        <BetTicketText bold color={colors.success}>
                          {liveScoreList[1]}
                        </BetTicketText>
                      </div>
                    ) : (
                      <BetTicketText bold color={colors.success}>
                        {liveScoreText}
                      </BetTicketText>
                    )}
                  </div>
                ) : (
                  <>
                    {shouldShowFinalScore ? (
                      <div className={styles.liveEventBlock}>
                        <BetTicketText>{content.finalScore()}</BetTicketText>
                        <BetTicketText bold>{liveScoreText}</BetTicketText>
                      </div>
                    ) : (
                      <BetTicketText
                        truncateText
                        color={startTimeColor}
                        textDecoration={shouldHideDate ? 'line-through' : 'none'}
                      >
                        {startTimeForDisplay}
                      </BetTicketText>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
