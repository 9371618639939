import React, { memo, useCallback } from 'react';
import { useHoverDirty } from 'react-use';
import { client, clientWithoutAuth } from 'apollo/clients';
import { Challenge, Home, Star, SvgProps } from 'assets/svgs';
import clsx from 'clsx';
import LanguageToggle from 'components/LanguageToggle';
import { useGetChallengeCountQuery } from 'generated/graphql';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import useBreakpoint, { Breakpoint } from 'hooks/interface/useBreakpoint';
import { useMobileNav } from 'hooks/useMobileNav';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { MobileMenuName } from 'redux/slices/globalStateSlice';

import colors from '../../assets/styles/colors';
import Tooltip from '../Tooltip';

import { CASINO_CATEGORIES } from './menu';
import {
  LanguageSwitcher,
  NavDivider,
  NavItem,
  NavLink,
  NavList,
  NavSubheader,
  NavText,
} from './styles';

import styles from './CasinoTab.module.scss';

interface WrappedLinkProps {
  path: string;
  title?: string;
  icon?: React.FC<SvgProps>;
  isExpanded: boolean;
  disabled?: boolean;
  counter?: number;
}

export const WrappedLink = memo(
  ({ path, title, icon: Icon, isExpanded, disabled = false, counter = 0 }: WrappedLinkProps) => {
    const linkRef = React.useRef<HTMLLIElement>(null);
    const isHovering = useHoverDirty(linkRef);
    const router = useRouter();
    const activePage = router.asPath;
    const { setActiveMobileNav } = useMobileNav();

    const { lessThanBreakpoint } = useBreakpoint();

    const closeMobileMenu = useCallback(() => {
      if (lessThanBreakpoint(Breakpoint.MD)) {
        setActiveMobileNav(MobileMenuName.CASINO);
      }
    }, [lessThanBreakpoint, setActiveMobileNav]);

    return (
      <Tooltip placement="right" open={isExpanded ? false : undefined}>
        <Tooltip.Trigger>
          {disabled ? (
            <NavItem isActive={false} disabled>
              {Icon && <Icon color={colors.gray500} />}
              <NavText>{title}</NavText>
            </NavItem>
          ) : (
            <Link href={path} passHref>
              <NavLink>
                <NavItem isActive={path === activePage} ref={linkRef} onClick={closeMobileMenu}>
                  {Icon && (
                    <Icon
                      color={
                        isHovering || path === activePage ? colors.primaryViolet : colors.white
                      }
                    />
                  )}
                  <NavText>{title}</NavText>
                  {counter > 0 && (
                    <span
                      className={clsx(styles.counter, {
                        [styles.isHidden]: !isExpanded,
                      })}
                    >
                      {counter}
                    </span>
                  )}
                </NavItem>
              </NavLink>
            </Link>
          )}
        </Tooltip.Trigger>

        <Tooltip.Content>{title}</Tooltip.Content>
      </Tooltip>
    );
  },
);

export const CasinoTab = ({ isExpanded }: { isExpanded: boolean }) => {
  const { t } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const { data } = useGetChallengeCountQuery({
    client: isLoggedIn ? client : clientWithoutAuth,
  });
  const challengesCount = data?.challenges?.totalCount || 0;

  return (
    <>
      <NavList isExpanded={isExpanded}>
        <WrappedLink path="/" title={t('navHome')} icon={Home} isExpanded={isExpanded} />
        <WrappedLink
          path="/favourites"
          title={t('navFavourites')}
          icon={Star}
          isExpanded={isExpanded}
          disabled={!isLoggedIn}
        />
        <WrappedLink
          path="/challenges"
          title={t('navChallenges')}
          icon={Challenge}
          isExpanded={isExpanded}
          counter={challengesCount}
        />
      </NavList>
      {!isExpanded && <NavDivider />}
      {isExpanded && <NavSubheader>{t('navCategoryHeader')}</NavSubheader>}
      <NavList isExpanded={isExpanded}>
        {CASINO_CATEGORIES.map(({ icon, ...item }, i) =>
          item.isPrivate && !isLoggedIn ? null : (
            <WrappedLink
              path={item.href}
              title={t(item.name)}
              icon={icon}
              key={i}
              isExpanded={isExpanded}
            />
          ),
        )}
      </NavList>
      <LanguageSwitcher>
        <LanguageToggle placement="top" />
      </LanguageSwitcher>
    </>
  );
};

export default CasinoTab;
