import React from 'react';

import { SvgProps } from '..';

const Baccarat = ({ scale = 1, color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 14}`;
  const height = `${scale * 16}`;

  return (
    <svg width={width} height={height} viewBox="0 0 21 24" fill="none">
      <path
        d="M5.50024 13V2.5C5.50024 2.10218 5.65828 1.72064 5.93958 1.43934C6.22089 1.15804 6.60242 1 7.00024 1H13.0002C13.3981 1 13.7796 1.15804 14.0609 1.43934C14.3422 1.72064 14.5002 2.10218 14.5002 2.5V13C14.5002 13.3978 14.3422 13.7794 14.0609 14.0607C13.7796 14.342 13.3981 14.5 13.0002 14.5H9.11924"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5373 3.01001L18.6423 4.12401C18.8327 4.17526 19.011 4.26352 19.1672 4.38376C19.3234 4.50399 19.4544 4.65383 19.5527 4.82472C19.6509 4.99561 19.7146 5.18418 19.7399 5.37967C19.7652 5.57516 19.7518 5.77372 19.7003 5.96401L16.9483 16.098C16.8435 16.4815 16.5908 16.8078 16.2455 17.0051C15.9003 17.2023 15.4909 17.2545 15.1073 17.15L9.71631 15.69"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5002 23.5V20.5H2.50024V23.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50022 13L7.22522 10.126C7.37406 9.87748 7.60326 9.68721 7.87493 9.58666C8.1466 9.4861 8.44445 9.48128 8.71922 9.573C8.88351 9.62773 9.03491 9.71541 9.16415 9.83067C9.29339 9.94592 9.39775 10.0863 9.47086 10.2433C9.54397 10.4003 9.58428 10.5705 9.58933 10.7436C9.59437 10.9167 9.56405 11.089 9.50022 11.25L8.50022 13.75C9.46051 14.7104 10.0001 16.0129 10.0002 17.371V20.5H4.00022L1.38822 14.338C1.00113 13.4685 0.900277 12.4985 1.10022 11.568L2.77922 4.7C2.91262 4.07733 3.25553 3.51925 3.75072 3.11887C4.24591 2.7185 4.86342 2.50006 5.50022 2.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Baccarat;
