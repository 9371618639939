import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Wallet = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.2273 8.00001V3.54546H2.77273C2.06955 3.54546 1.5 2.97592 1.5 2.27274V13.0909C1.5 14.1454 2.35464 15 3.40909 15H14.2273V10.5455"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.6818 1.63636V1H2.77273C2.06955 1 1.5 1.56955 1.5 2.27273C1.5 2.97591 2.06955 3.54545 2.77273 3.54545"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.5 10.5455H12.3182C11.615 10.5455 11.0454 9.97591 11.0454 9.27273C11.0454 8.56955 11.615 8 12.3182 8H15.5V10.5455Z"
      />
    </svg>
  );
};

export default Wallet;
