import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHoverDirty } from 'react-use';
import { AlertsEnum, AlertType, removeAlert } from 'redux/slices/alertsSlice';

import { alertColors } from '../../assets/styles/convertors';

import styles from './Alert.module.scss';

const alertMapping = {
  [AlertsEnum.Info]: 'info',
  [AlertsEnum.Warning]: 'warning',
  [AlertsEnum.Error]: 'error',
  [AlertsEnum.Success]: 'success',
};

const TEN_SECONDS = 10_000;

export const Alert = ({
  message,
  duration,
  type,
  closeHandler,
}: AlertType & { closeHandler: () => void }) => {
  const alertRef = useRef<HTMLButtonElement>(null);
  const [timeToExpiry, setTimeToExpiry] = useState(duration);

  const alertIconName = alertMapping[type];
  const isHovering = useHoverDirty(alertRef);

  const lastViewingTime = useRef(new Date().getTime());

  useEffect(() => {
    // Interval runs every 100ms to prevent unnecessary re-renders
    const interval = setInterval(() => {
      // setInterval does not progress when the tab is not active
      // So if the user may have a bunch of alerts while their tab is not active
      // This will cause a flood of alerts when they open their tab again
      // The code below ensures any alert that has been idle for more than 10 seconds will disappear
      if (lastViewingTime.current + TEN_SECONDS < new Date().getTime()) {
        closeHandler();
      }

      if (!document.hidden) {
        lastViewingTime.current = new Date().getTime();

        if (!isHovering) setTimeToExpiry(time => time - 10);
      }
    }, 10);
    return () => clearInterval(interval);
  }, [closeHandler, isHovering]);

  // percentage of time left, e.g. if total duration is 5000ms and 1000ms have passed, percentage is 80%
  const percentageLeft = 100 - ((duration - timeToExpiry) / duration) * 100;

  useEffect(() => {
    // Component will be removed from DOM
    if (timeToExpiry <= 0) closeHandler();
  }, [timeToExpiry, closeHandler]);

  return (
    <button
      className={styles.root}
      ref={alertRef}
      onClick={closeHandler}
      aria-label={'dismiss alert'}
    >
      <div
        className={styles.leftContainer}
        style={{
          backgroundColor: alertColors[type],
        }}
        aria-label={alertIconName}
      >
        <img src={`/icons/alerts/${alertIconName}.svg`} alt={alertIconName} />
      </div>

      <div className={styles.rightContainer}>
        <div>
          <div className={styles.percentageBar} style={{ width: `${percentageLeft}%` }} />
          <p className={styles.text}>{message}</p>
        </div>

        <div className={styles.closeButton} onClick={closeHandler} aria-hidden={'true'}>
          <img src={'/icons/white-cross.svg'} alt={'close'} />
        </div>
      </div>
    </button>
  );
};

export const AlertWithAction = ({ message, duration, type, id }: AlertType) => {
  const dispatch = useDispatch();
  const closeHandler = useCallback(() => dispatch(removeAlert({ id })), [dispatch, id]);

  return (
    <div className={styles.alertRoot}>
      <Alert
        id={id}
        type={type}
        duration={duration}
        message={message}
        closeHandler={closeHandler}
      />
    </div>
  );
};

export default React.memo(AlertWithAction);
