import React from 'react';

import { SvgProps } from '../..';

const ESports = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g id="Property 1=esports" clipPath="url(#clip0_10560_125999)">
        <g id="Group">
          <path
            id="Vector"
            d="M6 0.75H7.5C9.985 0.75 12 3.515 12 6V6.75"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M6 11L6 15"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M4 13H8"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle id="Ellipse 1620" cx="17.5" cy="13.5" r="1.5" fill={color} />
          <circle id="Ellipse 1621" cx="20" cy="10" r="1" fill={color} />
          <path
            id="Vector_4"
            d="M23.249 15V11.25C23.249 10.0565 22.7749 8.91193 21.931 8.06802C21.0871 7.22411 19.9425 6.75 18.749 6.75H5.24904C4.05557 6.75 2.91097 7.22411 2.06706 8.06802C1.22315 8.91193 0.749042 10.0565 0.749042 11.25V19.872C0.731196 20.6024 0.95389 21.3184 1.38285 21.9097C1.8118 22.5011 2.42324 22.9352 3.12304 23.145C3.85289 23.3373 4.62722 23.2654 5.30921 22.9421C5.99121 22.6188 6.53696 22.0648 6.85004 21.378L9.06704 17.25H14.931L17.086 21.241C17.2991 21.7389 17.6301 22.1774 18.0505 22.5187C18.4709 22.86 18.968 23.0938 19.499 23.2C19.9586 23.2805 20.4302 23.2595 20.8808 23.1385C21.3313 23.0176 21.75 22.7995 22.1075 22.4997C22.4649 22.1999 22.7525 21.8256 22.95 21.4029C23.1476 20.9803 23.2503 20.5195 23.251 20.053L23.249 15Z"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10560_125999">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ESports;
