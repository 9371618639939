import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import colors from 'assets/styles/colors';
import { Location, Support, TelegramMonotone, Twitter } from 'assets/svgs';
import InstagramWhite from 'assets/svgs/brands/InstagramWhite';
import { Modal } from 'components/Modal';
import { INTERCOM_WIDGET_CLASSNAME } from 'constants/intercom';
import { INSTAGRAM_URL, TELEGRAM_URL, TWITTER_URL } from 'constants/social';
import useGeoRestriction from 'hooks/useGeoRestriction';
import { useTranslation } from 'next-i18next';
import { addAlert, AlertsEnum } from 'redux/slices/alertsSlice';

import styles from './GeoRestrictionModal.module.scss';

const WARNING_THROTTLE = 1000;

const GeoRestrictionModal = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const { isGeoRestricted, countryName, geoRestrictionWarningPopupCounter } = useGeoRestriction();

  const dispatch = useDispatch();

  // Open the restrictions modal if the country is restricted.
  useEffect(() => {
    if (isGeoRestricted) {
      setOpen(true);
    }
  }, [isGeoRestricted]);

  // Do not move this into useGeoRestriction hook, this is a global event
  const lastCounter = useRef<number>(geoRestrictionWarningPopupCounter);
  const useLastPopupTime = useRef<number>(0);
  useEffect(() => {
    if (lastCounter.current !== geoRestrictionWarningPopupCounter) {
      const now = Date.now();
      if (now > useLastPopupTime.current + WARNING_THROTTLE) {
        dispatch(
          addAlert({
            message: t('txtPlatformRestrictionAlert', { country: countryName }),
            type: AlertsEnum.Error,
          }),
        );
        useLastPopupTime.current = now;
      }
    }
    lastCounter.current = geoRestrictionWarningPopupCounter;
  }, [geoRestrictionWarningPopupCounter, countryName, dispatch, t]);

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)}>
      <div className={styles.modalBody}>
        <Location />

        <h3 className={styles.modalHeading}>{t('txtPlatformRestrictionModalHeading')}</h3>

        <p className={styles.modalDesc}>
          {t('txtPlatformRestrictionModalDesc', {
            country: countryName ?? t('txtFallbackYourCountry'),
          })}
        </p>

        <div className={styles.modalSocialsRow}>
          <a className={INTERCOM_WIDGET_CLASSNAME}>
            <Support scale={1.25} />
          </a>

          <a target="_blank" href={TWITTER_URL} rel="noopener noreferrer">
            <Twitter scale={1.25} color={colors.white} />
          </a>

          <a target="_blank" href={INSTAGRAM_URL} rel="noopener noreferrer">
            <InstagramWhite />
          </a>

          <a target="_blank" href={TELEGRAM_URL} rel="noopener noreferrer">
            <TelegramMonotone />
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default GeoRestrictionModal;
