import { useMemo, useState } from 'react';
import { useMount } from 'react-use';
import { RightSidebarVariant } from 'redux/slices/browserPreferenceSlice';
import { RootState } from 'redux/store';

import { Breakpoint, defaultBreakpoints } from './useBreakpoint';

export const SIDEBAR_WIDTH_EXPANDED_PX = 240;
export const SIDEBAR_WIDTH_DEFAULT_PX = 65;
export const RIGHT_SIDEBAR_WIDTH_PX = 360;

const mobileBreakpoint = defaultBreakpoints[Breakpoint.MD];
const smallSizeBreakpoint = defaultBreakpoints[Breakpoint.LG];

/**
 * Handles dynamic breakpoints given internal width of content, taking
 * sidebar open and close states into account.
 * @note To be called ONLY from `_app.tsx`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useBreakpointMagic = (store: any) => {
  const [additionalWidth, setAdditionalWidth] = useState<number>(0);

  const applyValuesFromState = (width: number) => {
    const state: RootState = store.getState();
    const leftSidebarOpen = state.browserPreference.isLeftSidebarExpanded;
    const rightSidebarOpen =
      state.browserPreference.rightSidebarVariant !== RightSidebarVariant.NONE;

    // We increase the breakpoints when sidebars are open by the pixel value
    // of their combines open widths; ensuring that the internal content's
    // breakpoints remain consistent.
    let _additionalWidth = 0;

    // Magic breakpoint only works for desktop
    if (width >= mobileBreakpoint) {
      if (rightSidebarOpen) _additionalWidth = _additionalWidth + RIGHT_SIDEBAR_WIDTH_PX;
    }

    if (width >= smallSizeBreakpoint) {
      if (leftSidebarOpen)
        _additionalWidth = _additionalWidth + SIDEBAR_WIDTH_EXPANDED_PX - SIDEBAR_WIDTH_DEFAULT_PX;
    }

    setAdditionalWidth(_additionalWidth);
  };

  // Listen for sidebar opened events from Redux
  useMount(() => {
    const applyValues = () => applyValuesFromState(window.innerWidth);

    window.addEventListener('resize', applyValues);
    const unsubscribe = store.subscribe(applyValues);

    return () => {
      unsubscribe();
      window.removeEventListener('resize', applyValues);
    };
  });

  const breakpoints: Record<Breakpoint, number> = useMemo(
    () => ({
      // Don't attribute additional width to clients who are already
      // on mobile sized devices
      [Breakpoint.XS]: defaultBreakpoints[Breakpoint.XS] + additionalWidth,
      [Breakpoint.SM]: defaultBreakpoints[Breakpoint.SM] + additionalWidth,
      [Breakpoint.MD]: defaultBreakpoints[Breakpoint.MD] + additionalWidth,
      [Breakpoint.LG]: defaultBreakpoints[Breakpoint.LG] + additionalWidth,
      [Breakpoint.XL]: defaultBreakpoints[Breakpoint.XL] + additionalWidth,
    }),
    [additionalWidth],
  );

  // Return breakpoints according to sidebar opened condition.
  return breakpoints;
};

export default useBreakpointMagic;
