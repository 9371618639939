import React from 'react';

import { SvgProps } from '.';

const Delete = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 11 11' }: SvgProps) => {
  const width = `${scale * 11}`;
  const height = `${scale * 11}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.707 0.293153C10.5194 0.105682 10.2651 0.000366211 9.99996 0.000366211C9.73479 0.000366211 9.48049 0.105682 9.29296 0.293153L5.99996 3.58615L2.70696 0.293153C2.51836 0.110995 2.26575 0.0102003 2.00356 0.0124787C1.74136 0.0147571 1.49055 0.119926 1.30514 0.305334C1.11973 0.490742 1.01456 0.741555 1.01229 1.00375C1.01001 1.26595 1.1108 1.51855 1.29296 1.70715L4.58596 5.00015L1.29296 8.29315C1.19745 8.3854 1.12127 8.49574 1.06886 8.61775C1.01645 8.73975 0.988862 8.87097 0.987709 9.00375C0.986555 9.13653 1.01186 9.26821 1.06214 9.39111C1.11242 9.514 1.18667 9.62565 1.28056 9.71955C1.37446 9.81344 1.48611 9.88769 1.60901 9.93797C1.7319 9.98825 1.86358 10.0136 1.99636 10.0124C2.12914 10.0112 2.26036 9.98366 2.38236 9.93125C2.50437 9.87885 2.61471 9.80266 2.70696 9.70715L5.99996 6.41415L9.29296 9.70715C9.48156 9.88931 9.73416 9.9901 9.99636 9.98783C10.2586 9.98555 10.5094 9.88038 10.6948 9.69497C10.8802 9.50956 10.9854 9.25875 10.9876 8.99655C10.9899 8.73436 10.8891 8.48176 10.707 8.29315L7.41396 5.00015L10.707 1.70715C10.8944 1.51963 10.9997 1.26532 10.9997 1.00015C10.9997 0.734989 10.8944 0.48068 10.707 0.293153Z"
        fill={color}
      />
    </svg>
  );
};

export default Delete;
