import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  autoPlacement,
  autoUpdate,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { Relative } from 'components/commonStyles';
import { LIVE_GAME_STATS_ID, MODAL_ID } from 'components/modals/ModalWrapper';
import UI, { MOBILE_SHEET_ID, MOBILE_SIDEBAR_ID } from 'constants/ui';
import useIsDescendant from 'hooks/interface/useIsDescendant';

import isString from '../../utils/isString';

import {
  ChevronDownIcon,
  ErrorMessage,
  FormControlWrapper,
  Icon,
  Label,
  LabelBlock,
  Menu,
  MenuItem,
  Select,
  SelectOptionIcon,
  SelectOptionLabel,
} from './styles';

export interface SelectOption<T extends string> {
  label: ReactNode;
  value: T;
  icon?: ReactNode;
}

export interface SelectInputProps<T extends string> {
  label?: string;
  rightLabel?: string;
  options: SelectOption<T>[];
  selected: T;
  onChange: (val: T) => void;
  error?: string;
  disabled?: boolean;

  /** A custom component to display in the menu control  */
  displayOption?: ReactNode;

  /** Whether to display the menu above or below the control */
  placement?: 'bottom' | 'top';

  /** Whether to automatically update the menu position when the spacing not enough to display */
  enableAutoPlacement?: boolean;
  testId?: string;
}

// Created because floating UI causes clickOutside to be triggered and closes the modal
const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

export function SelectInput<T extends string>({
  label,
  rightLabel,
  options = [],
  selected,
  onChange,
  error,
  disabled,
  displayOption,
  placement = 'bottom',
  enableAutoPlacement = true,
  testId,
}: SelectInputProps<T>) {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isPointer, setIsPointer] = useState(false);
  const listRef = React.useRef<Array<HTMLElement | null>>([]);
  /** Ensure that the menu sits on top of its rendered parent if modal, sheet or live game stats (draggable) */
  const intersectionRef = useRef(null);

  const layeredSelector = `#${MODAL_ID},#${MOBILE_SHEET_ID},#${LIVE_GAME_STATS_ID},#${MOBILE_SIDEBAR_ID}`;
  const isDescendantOfModal = useIsDescendant(layeredSelector, intersectionRef);
  const zIndex = isDescendantOfModal ? UI.z.modal + 5 : UI.z.menu;

  const { x, y, refs, reference, floating, context } = useFloating({
    open,
    placement,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      enableAutoPlacement &&
        autoPlacement({
          allowedPlacements: ['bottom', 'top'],
        }),
      size({
        apply({ rects, elements, availableHeight }) {
          const maxHeightPx = isDescendantOfModal
            ? availableHeight
            : availableHeight - UI.height.mobileNav;

          Object.assign(elements.floating.style, {
            maxHeight: `min(30rem, ${maxHeightPx}px)`,
            width: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const focus = useFocus(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    click,
    focus,
    dismiss,
    role,
    listNav,
  ]);

  useEffect(() => {
    // Don't scroll if the user is using a mouse pointer, which can also change the `activeIndex` on hover.
    if (activeIndex == null || isPointer) return;

    /**
     * Wait for the position to be ready if it's opening for the first time.
     * @ref https://floating-ui.com/docs/react-dom#effects
     */
    requestAnimationFrame(() => {
      listRef.current[activeIndex]?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    });
  }, [isPointer, activeIndex]);

  useEffect(() => {
    if (!open && isPointer) {
      setIsPointer(false);
    }
  }, [isPointer, open]);

  /** We take a numeric index for aria compatability */
  const handleSelect = (index: number) => {
    const value = options[index].value;
    setSelectedIndex(index);
    onChange(value as T);
    setOpen(false);
  };

  const selectedOption = options.find(o => o.value === selected) ?? {
    label: '',
    value: '',
    icon: null,
  };

  return (
    <>
      <FormControlWrapper tabIndex={0}>
        {(label || rightLabel) && (
          <LabelBlock>
            {label && <Label>{label}</Label>}
            {rightLabel && <Label $float="right">{rightLabel}</Label>}
          </LabelBlock>
        )}

        <Relative ref={intersectionRef}>
          <Select
            type={'button'}
            ref={reference}
            aria-autocomplete="none"
            $open={open}
            $disabled={disabled}
            {...getReferenceProps()}
            data-testid={testId}
          >
            {displayOption ? (
              <>{displayOption}</>
            ) : (
              <>
                {!!selectedOption.icon && (
                  <SelectOptionIcon>{selectedOption.icon}</SelectOptionIcon>
                )}
                <SelectOptionLabel $disabled={disabled}>{selectedOption.label}</SelectOptionLabel>
              </>
            )}

            <Icon>
              <ChevronDownIcon $pointUp={open} />
            </Icon>
          </Select>
        </Relative>

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormControlWrapper>

      <FloatingPortal>
        {open && (
          <FloatingFocusManager context={context} modal={false} initialFocus={refs.floating}>
            <Menu
              data-body-scroll-lock-ignore="true"
              ref={floating}
              $disabled={disabled}
              style={{
                top: y ?? 0,
                left: x ?? 0,
                minWidth: 'max-content',
                zIndex,
              }}
              {...getFloatingProps({
                onMouseMove() {
                  setIsPointer(true);
                },
                onKeyDown() {
                  setIsPointer(false);
                },
              })}
              onClick={stopPropagation}
            >
              {options.map((option, i) => (
                <MenuItem
                  key={option.value}
                  ref={node => {
                    listRef.current[i] = node;
                  }}
                  role="option"
                  aria-selected={i === selectedIndex && i === activeIndex}
                  $active={i === activeIndex}
                  data-testid={isString(option.label) ? option.label : ''}
                  $selected={selectedOption.value === option.value}
                  {...getItemProps({
                    // Handle pointer select.
                    onClick() {
                      handleSelect(i);
                    },
                    // Handle keyboard select.
                    onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
                      if (event.key === 'Enter') {
                        handleSelect(i);
                      }
                    },
                  })}
                >
                  {option.icon}
                  <SelectOptionLabel $selected={selectedOption.value === option.value}>
                    {option.label}
                  </SelectOptionLabel>
                </MenuItem>
              ))}
            </Menu>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}
export default SelectInput;
