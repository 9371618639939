import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Profile = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99917 1.8335C6.63604 1.8335 5.53917 2.93557 5.53917 4.2935C5.53917 5.61551 6.57188 6.68815 7.87576 6.74606C7.95483 6.74056 8.03836 6.74029 8.11717 6.74601C9.41976 6.68748 10.4531 5.61555 10.4592 4.29248C10.4586 2.93575 9.35602 1.8335 7.99917 1.8335ZM4.53917 4.2935C4.53917 2.38475 6.08229 0.833496 7.99917 0.833496C9.90864 0.833496 11.4592 2.38402 11.4592 4.2935L11.4592 4.29558C11.4514 6.16251 9.9798 7.68412 8.12263 7.74655C8.10042 7.74729 8.07819 7.74656 8.05608 7.74435C8.02393 7.74113 7.97589 7.7407 7.9311 7.74478C7.91047 7.74665 7.88974 7.74724 7.86904 7.74655C6.01223 7.68413 4.53917 6.16241 4.53917 4.2935ZM8.11417 8.29183C9.39963 8.29183 10.7105 8.61444 11.7235 9.29104C12.6341 9.89712 13.1558 10.7467 13.1558 11.661C13.1558 12.5752 12.6342 13.4263 11.724 14.0357L11.7239 14.0357C10.7077 14.7156 9.39522 15.0402 8.10917 15.0402C6.82332 15.0402 5.51103 14.7157 4.49495 14.0361C3.58427 13.43 3.0625 12.5803 3.0625 11.666C3.0625 10.7518 3.58413 9.90066 4.49436 9.29133L4.4962 9.29011C5.51515 8.6145 6.82854 8.29183 8.11417 8.29183ZM5.04973 10.1229C4.34729 10.5935 4.0625 11.1621 4.0625 11.666C4.0625 12.1698 4.34717 12.7367 5.04945 13.2039L5.05055 13.2046C5.86774 13.7514 6.97522 14.0402 8.10917 14.0402C9.24307 14.0402 10.3505 13.7514 11.1677 13.2047C11.8708 12.734 12.1558 12.1651 12.1558 11.661C12.1558 11.1572 11.8712 10.5903 11.1689 10.1231L11.1681 10.1226C10.3545 9.57922 9.2487 9.29183 8.11417 9.29183C6.98022 9.29183 5.87069 9.57895 5.04973 10.1229Z"
        fill={color}
      />
    </svg>
  );
};

export default Profile;
