import BigNumber from 'bignumber.js';
import { OddsFormat } from 'redux/slices/browserPreferenceSlice';

import { getSportBetOdds } from './getSportBetOdds';

export const calculateEstimatePayout = ({
  betAmount,
  oddsNumerator,
  oddsDenominator,
  totalOdds,
}: {
  betAmount: string | number;
  oddsNumerator?: string | number;
  oddsDenominator?: string | number;
  totalOdds?: BigNumber;
}): string => {
  const betAmountBN = BigNumber(betAmount);

  if (betAmountBN.isZero() || betAmountBN.isNaN()) {
    return '0';
  }

  let odds = BigNumber(totalOdds || 0);

  if (oddsNumerator !== undefined && oddsDenominator !== undefined) {
    odds = getSportBetOdds({
      oddsFormat: OddsFormat.Decimal,
      oddsNumerator,
      oddsDenominator,
    }).outputNumber;
  }

  return betAmountBN.multipliedBy(odds.toFixed(2)).toFixed();
};
