import React, { useCallback } from 'react';

import { MOBILE_SIDEBAR_ID } from '../../constants/ui';
import { useMobileNav } from '../../hooks/useMobileNav';
import { usePreventScrolling } from '../../hooks/usePreventScrolling';
import { MobileMenuName } from '../../redux/slices/globalStateSlice';

import NavigationContent from './NavigationContent';
import { SidebarMobile } from './styles';

export const MobileNavigationBar = ({ activeMobilePage }: { activeMobilePage: MobileMenuName }) => {
  const { setActiveMobileNav } = useMobileNav();

  const handleCloseMenu = useCallback(() => {
    setActiveMobileNav(activeMobilePage);
  }, [activeMobilePage, setActiveMobileNav]);

  usePreventScrolling();

  return (
    <SidebarMobile id={MOBILE_SIDEBAR_ID}>
      <NavigationContent isExpanded showTooltip={false} handleCloseMenu={handleCloseMenu} />
    </SidebarMobile>
  );
};
