import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Search = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0209 11.6463C12.8257 11.4511 12.5091 11.4511 12.3138 11.6463C12.1185 11.8416 12.1185 12.1582 12.3138 12.3534L13.0209 11.6463ZM14.9804 15.0201C15.1757 15.2153 15.4922 15.2153 15.6875 15.0201C15.8828 14.8248 15.8828 14.5082 15.6875 14.313L14.9804 15.0201ZM12.3138 12.3534L14.9804 15.0201L15.6875 14.313L13.0209 11.6463L12.3138 12.3534ZM7.33398 12.1665C4.29642 12.1665 1.83398 9.70407 1.83398 6.6665H0.833984C0.833984 10.2564 3.74413 13.1665 7.33398 13.1665V12.1665ZM12.834 6.6665C12.834 9.70407 10.3716 12.1665 7.33398 12.1665V13.1665C10.9238 13.1665 13.834 10.2564 13.834 6.6665H12.834ZM7.33398 1.1665C10.3716 1.1665 12.834 3.62894 12.834 6.6665H13.834C13.834 3.07665 10.9238 0.166504 7.33398 0.166504V1.1665ZM7.33398 0.166504C3.74413 0.166504 0.833984 3.07665 0.833984 6.6665H1.83398C1.83398 3.62894 4.29642 1.1665 7.33398 1.1665V0.166504Z"
        fill={color}
      />
    </svg>
  );
};

export default Search;
