import { mediaQueries } from 'assets/styles/mediaQueries';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

export type ColouredVariant = 'info' | 'warning' | 'success' | 'error' | 'white' | 'default';
export type ComponentSize = 'sm' | 'md' | 'lg';

export const Relative = styled.div`
  position: relative;
`;

export const Absolute = styled.div<{
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  inset?: string;
}>`
  position: absolute;
  top: ${({ top }) => top ?? 'initial'};
  bottom: ${({ bottom }) => bottom ?? 'initial'};
  left: ${({ left }) => left ?? 'initial'};
  right: ${({ right }) => right ?? 'initial'};
  inset: ${({ inset }) => inset ?? 'initial'};
`;

export const Flex = styled.div<{
  column?: boolean;
  wide?: boolean;
  align?: string;
  justify?: string;
  gap?: string;
  color?: string;
  wrap?: string;
  padding?: string;
  opacity?: number;
}>`
  position: relative;
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: ${({ align }) => align ?? 'unset'};
  justify-content: ${({ justify }) => justify ?? 'unset'};
  gap: ${({ gap }) => gap ?? 'unset'};
  width: ${({ wide }) => (wide ? '100%' : 'unset')};
  color: ${({ color }) => color ?? 'unset'};
  flex-wrap: ${({ wrap }) => wrap ?? 'unset'};
  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
  ${({ opacity }) => (opacity ? `opacity: ${opacity};` : '')}
`;

export const Grid = styled.div<{ columns: number; gap: string }>`
  position: relative;
  display: grid;
  gap: ${({ gap }) => gap};
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
`;

export const Padded = styled.div<{
  x?: string;
  y?: string;
}>`
  padding: ${({ x, y }) => `${y ?? 0} ${x ?? 0}`};
`;

export const IconWrapper = styled.span<{ withPointer?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ withPointer }) => (withPointer ? 'pointer' : 'default')};
`;

export const DisplayOnMobile = styled.div`
  display: block;
  ${mediaQueries(Breakpoint.MD)`
    display: none;
  `};
`;

export const DisplayOnDesktop = styled.div`
  display: none;
  ${mediaQueries(Breakpoint.MD)`
    display: block;
  `};
`;
