import React from 'react';

/**
 * Determine if the calling component is rendered inside a parent with a given selector.
 * Valid selectors follow the W3 naming convention.
 * @see {@link https://www.w3.org/TR/CSS21/syndata.html#characters}
 *
 * @example #modal-body
 * @example .container
 * @example .table tr, #footer
 */
const useIsDescendant = <T extends HTMLElement>(
  selector: string,
  reference: React.RefObject<T>,
) => {
  if (typeof window === 'undefined') {
    return false;
  }

  const parents = Array.from(document.querySelectorAll(selector));
  return parents.some(p => p.contains(reference.current));
};

export default useIsDescendant;
