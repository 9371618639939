import { pxToRem } from 'assets/styles/convertors';
import { mediaQueries } from 'assets/styles/mediaQueries';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

import colors from '../../assets/styles/colors';

const BOTTOM_OFFSET_PX = 20;
const BTN_WIDTH = pxToRem(120);

export const BannerManagerWrapper = styled.div`
  position: fixed;
  z-index: ${UI.z.alert};
  bottom: ${pxToRem(UI.height.mobileNav + BOTTOM_OFFSET_PX)};
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;
  max-width: 350px;

  // width takes up full width of small screens but only max-content on larger screens
  ${mediaQueries(Breakpoint.SM)`
    max-width: unset;
    width: max-content;
  `}

  ${mediaQueries(Breakpoint.MD)`
    bottom: ${pxToRem(BOTTOM_OFFSET_PX)};
  `};
`;

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  column-gap: 1rem;
  justify-content: space-between;
  max-width: 700px;

  background: ${colors.gray900};
  border: 1px solid ${colors.gray700};
  box-shadow: 0 ${pxToRem(2)} ${pxToRem(20)} ${pxToRem(10)} rgba(17, 17, 18, 0.25);
  border-radius: ${pxToRem(8)};

  ${mediaQueries(Breakpoint.SM)`
    flex-direction: row;
  `};
`;

export const BannerImage = styled.div`
  height: ${pxToRem(42)};
  width: ${pxToRem(28)};
  font-size: ${pxToRem(28)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BannerBody = styled.div`
  flex: 1;
  display: flex;
  font-size: ${UI.text.lg};
  line-height: 150%;
  padding: ${pxToRem(16)} 0 ${pxToRem(16)};
  column-gap: 1rem;
  align-items: center;
`;

export const BannerGroup = styled.div`
  padding-bottom: 1rem;
  width: 100%;

  ${mediaQueries(Breakpoint.SM)`
    width: min-content;
    padding: 1rem 0;

    & button {
      width: ${BTN_WIDTH};
    }
  `}
`;

export const CookieBannerGroup = styled(BannerGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
`;
