import { pxToRem } from 'assets/styles/convertors';
import { mediaQueries } from 'assets/styles/mediaQueries';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

import colors from '../../assets/styles/colors';

export const TabGroup = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  ${mediaQueries(Breakpoint.MD)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `};
`;

export const TabList = styled.div<{ wide?: boolean; outline?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray800};
  border-radius: ${({ outline }) => (outline ? '0' : '0.375rem;')};
  padding: 0.375rem;
  width: 100%;
  gap: 0.2rem;

  ${({ wide }) =>
    !wide &&
    mediaQueries(Breakpoint.MD)`
      margin-top: 0;
      width: fit-content;
      justify-content: flex-start;
  `};

  ${({ outline }) =>
    outline &&
    `
    border-bottom: ${pxToRem(1)} solid ${colors.gray500};
    background-color: transparent;
    justify-content: flex-start;
    padding: 0.375rem 0;
  `};
`;

interface TabProps {
  isActive: boolean;
  hasIcon: boolean;
  wide?: boolean;
  outline?: boolean;
}

export const Tab = styled.button<TabProps>`
  flex-direction: ${({ hasIcon }) => (hasIcon ? 'row' : 'column')};

  ${({ wide }) =>
    !wide &&
    mediaQueries(Breakpoint.MD)`
    flex: none;
  `};

  ${({ outline, isActive }) =>
    outline &&
    `
    flex: unset;
    background-color: transparent;
    color: ${isActive ? colors.primaryViolet : colors.white};
    font-weight: ${isActive ? '700' : '400'};
    font-size: ${UI.text.lg};
    padding: 0.5rem 1.5rem;

    &::after {
      content: '';
      border-bottom: ${isActive ? `${pxToRem(3)} solid ${colors.primaryViolet}` : 'none'};
      width: 100%;
      display: ${isActive ? 'block' : 'none'};
      padding: 0;
      position: absolute;
      bottom: ${pxToRem(-8)};
      border-radius:  ${pxToRem(28)};
    }
  `};

  ${({ outline }) =>
    outline &&
    mediaQueries(Breakpoint.MD)`
    font-size: ${UI.text.xl};
  `};
`;

export const TabViewModalElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 2rem;
  border-bottom: 0.0625rem solid ${colors.gray700};
`;

interface TabModalProps {
  isActive: boolean;
}

export const TabModal = styled.div<TabModalProps>`
  height: 100%;
  flex: 1;
  color: ${colors.white};
  padding: 0 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${UI.text.lg};
  position: relative;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: ${pxToRem(8)};

  &::after {
    content: '';
    height: ${pxToRem(3)};
    width: 100%;
    background-color: ${colors.white};
    position: absolute;
    bottom: -${pxToRem(7)};
    border-radius: 1.75rem;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  }
`;

export const ScrollableTabViewContainer = styled.div<{ $isInsideContainer: boolean }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100vw;

  ${({ $isInsideContainer }) => `
    margin-left: ${$isInsideContainer ? `-${UI.page.padding.mobile}` : 0};

    ${mediaQueries(Breakpoint.SM)`
      margin-left: ${$isInsideContainer ? `-${UI.page.padding.desktop}` : 0};
    `};
  `};
`;

export const ScrollableTabViewElement = styled.div`
  overflow-x: auto;
  padding-right: ${UI.page.padding.mobile};

  ${mediaQueries(Breakpoint.SM)`
    padding-right: ${UI.page.padding.desktop};
  `};

  // Hide horizontal scrollbar; we're on mobile.
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${TabList} {
    margin-left: ${UI.page.padding.mobile};
    width: max-content;

    ${mediaQueries(Breakpoint.SM)`
      margin-left: ${UI.page.padding.desktop};
    `}
  }
`;

export const HorizontalTabsWithArrowsWrapper = styled.div<{
  $isDisplayNext?: boolean;
  $isDisplayPrev?: boolean;
}>`
  display: flex;
  align-items: center;

  width: 100%;
  position: relative;

  padding-right: ${({ $isDisplayNext }) => ($isDisplayNext ? '0' : '3.125vw')};
  padding-left: ${({ $isDisplayPrev }) => ($isDisplayPrev ? '0' : '3.125vw')};

  ${TabList} {
    justify-content: space-between;
  }

  ${Tab} {
    width: 100%;
    font-size: ${UI.text.lg};
  }

  ${mediaQueries(Breakpoint.SM)`
    padding-right: 0;
    padding-left: 0;
  `}
`;
