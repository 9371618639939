import { useCallback } from 'react';

import { SportsBetSelectionStatus } from '../../../generated/graphql';

import { MultiBetLeg } from './BetTicket/MultiBetLeg';
import { SingleBetSlip } from './BetTicket/SingleBetSlip';
import { OtherStatus, Props } from './BetsGroup.type';

import styles from './BetSlip.module.scss';

export const MAX_NUMBER_OF_BETS = 20;

export function BetSlip({
  actions,
  status,
  odds,
  currency,
  bets,
  betIds,
  betSlipStatus,
  amount,
  oddsFormat,
  currencyPreference,
  content,
  settlementPayout,
  cashoutOddsDecimal,
  shouldShowCashOut = false,
  publicView,
  showOddsWarningMessage = false,
  errors = {
    isInsufficientFunds: false,
    isMinStakeNotMet: false,
    placedBetError: false,
  },
  warnings = {
    exceedMaxAllowedStakeAmount: false,
    hasOddsChanges: false,
  },
  matchStates,
}: Props) {
  const hasMultiSelections = bets.length > 1;
  const currentCurrency =
    actions?.onUpdateAmount || actions?.onUpdateMultiBetAmount
      ? currencyPreference
      : currency || currencyPreference;
  let primaryBetStatus = status;

  if (hasMultiSelections) {
    const hasLost = bets.some(bet => bet.status === SportsBetSelectionStatus.LOST);
    const allWin = bets.every(bet => bet.status === SportsBetSelectionStatus.WON);

    if (hasLost) {
      primaryBetStatus = OtherStatus.LOSS;
    }

    if (allWin) {
      primaryBetStatus = OtherStatus.WIN;
    }
  }

  const handleAcceptedMultiNewOdds = useCallback(() => {
    if (actions?.onAcceptMultiNewOdds) {
      actions?.onAcceptMultiNewOdds();
    }
  }, [actions]);

  return (
    <div className={styles.betSlipWrapper}>
      <SingleBetSlip
        content={content}
        status={primaryBetStatus}
        odds={odds}
        bets={bets}
        betIds={betIds}
        amount={amount}
        betSlipStatus={betSlipStatus}
        currency={currentCurrency}
        hasMultiSelections={hasMultiSelections}
        publicView={publicView}
        oddsFormat={oddsFormat}
        settlementPayout={settlementPayout}
        cashoutOddsDecimal={cashoutOddsDecimal}
        shouldShowCashOut={shouldShowCashOut}
        errors={errors}
        onChangeBetAmount={
          hasMultiSelections
            ? (value: string) =>
                actions?.onUpdateMultiBetAmount?.({
                  amount: value,
                })
            : actions?.onUpdateAmount
        }
        warnings={warnings}
        onClickHeader={actions?.onClickHeader}
        onClickViewDetail={actions?.onClickViewDetail}
        onClickCashOut={actions?.onClickCashOut}
        onRemove={actions?.onRemove}
        onAcceptNewOdds={actions?.onAcceptNewOdds}
        onAcceptMultiNewOdds={handleAcceptedMultiNewOdds}
        matchStates={matchStates}
        showOddsWarningMessage={showOddsWarningMessage}
      />

      {hasMultiSelections && (
        <>
          <div className={styles.connector} />

          {[...bets]
            .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
            .slice(0, MAX_NUMBER_OF_BETS)
            .map((bet, index) => (
              <MultiBetLeg
                key={bet.id + index}
                content={content}
                errors={errors}
                currency={currentCurrency}
                onUpdateAmount={actions?.onUpdateMultiBetAmount}
                onHeaderClick={actions?.onHeaderClick}
                betSlipStatus={betSlipStatus}
                betIds={betIds}
                bet={bet}
                showOddsWarningMessage={showOddsWarningMessage}
                matchStates={matchStates}
              />
            ))}
        </>
      )}
    </div>
  );
}
