import React from 'react';
import { Currency, FiatCurrency } from 'generated/graphql';
import usePreference from 'hooks/usePreference';

import CryptoIcon from './CryptoIcon';
import FiatIcon from './FiatIcon';

/**
 * Icon for a General currency value.
 * Will show crypto or fiat preference if not explicitly provided,
 */
interface GeneralCurrencyIconProps {
  currency?: Currency;
  fiat?: FiatCurrency;
}

const GeneralCurrencyIcon = ({ currency, fiat }: GeneralCurrencyIconProps) => {
  const { displayInFiat, cryptoPreference, fiatPreference } = usePreference();

  return displayInFiat ? (
    <FiatIcon currency={fiat ?? fiatPreference} />
  ) : (
    <CryptoIcon currency={currency ?? cryptoPreference} />
  );
};

export default React.memo(GeneralCurrencyIcon);
