import React from 'react';

import { SvgProps } from '..';

const TableTennis = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M4.27637 5.50781L10.0304 11.2618"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8996 7.00026C14.0886 6.25994 14.0803 5.48299 13.8753 4.74692C13.6704 4.01084 13.276 3.34136 12.7316 2.80526C10.9316 1.00526 8.04261 0.98426 5.75661 3.27026C3.88861 5.14026 3.56561 7.35026 4.44461 9.08026L0.744606 12.1103C0.673582 12.1685 0.615544 12.241 0.574225 12.323C0.532907 12.4051 0.509225 12.4949 0.504704 12.5866C0.500182 12.6784 0.514921 12.77 0.547973 12.8558C0.581024 12.9415 0.631654 13.0193 0.696606 13.0843L2.45861 14.8463C2.52356 14.9112 2.6014 14.9618 2.68711 14.9949C2.77282 15.0279 2.8645 15.0427 2.95625 15.0382C3.04799 15.0336 3.13778 15.01 3.21982 14.9686C3.30187 14.9273 3.37435 14.8693 3.43261 14.7983L6.46261 11.0983C7.09156 11.4106 7.78522 11.5704 8.48743 11.5649C9.18964 11.5593 9.88068 11.3885 10.5046 11.0663"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13.5C14.3807 13.5 15.5 12.3807 15.5 11C15.5 9.61929 14.3807 8.5 13 8.5C11.6193 8.5 10.5 9.61929 10.5 11C10.5 12.3807 11.6193 13.5 13 13.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TableTennis;
