import React from 'react';
import { SvgProps } from 'assets/svgs';

const Baseball = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_5545_11860)">
        <path
          d="M18.75 15.75C20.4069 15.75 21.75 14.4069 21.75 12.75C21.75 11.0931 20.4069 9.75 18.75 9.75C17.0931 9.75 15.75 11.0931 15.75 12.75C15.75 14.4069 17.0931 15.75 18.75 15.75Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.402 7.90202L22.002 7.30202C22.6917 6.64061 23.1145 5.749 23.1903 4.79644C23.2661 3.84387 22.9895 2.89663 22.413 2.13452C22.082 1.72675 21.6691 1.39306 21.2009 1.15505C20.7327 0.917046 20.2198 0.780029 19.6953 0.752878C19.1708 0.725728 18.6465 0.809049 18.1563 0.997445C17.666 1.18584 17.2208 1.47511 16.8495 1.84652L9.9645 8.73152C9.34278 9.3545 8.86508 10.1061 8.565 10.9335L7.2885 14.4615L1.875 19.875"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.125 22.125L9.5385 16.7115L13.0665 15.435C13.206 15.384 13.3425 15.3285 13.4775 15.2685"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 18.75L5.25 23.25"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5545_11860">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Baseball;
