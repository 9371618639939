import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Chat = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3269_121930)">
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.27273 11.6C1.18182 10.4 0.5 8.9 0.5 7.25C0.5 3.5 3.84091 0.5 8 0.5C12.1591 0.5 15.5 3.5 15.5 7.25C15.5 11 12.1591 14 8 14C7.25 14 6.56818 13.925 5.88636 13.7L1.86364 15.5L2.27273 11.6Z"
        />
        <path
          fill={color}
          d="M4.70007 6.29996C4.50229 6.29996 4.30895 6.35861 4.1445 6.46849C3.98005 6.57837 3.85188 6.73455 3.77619 6.91727C3.70051 7.1 3.6807 7.30107 3.71929 7.49505C3.75787 7.68903 3.85311 7.86721 3.99297 8.00707C4.13282 8.14692 4.311 8.24216 4.50498 8.28074C4.69896 8.31933 4.90003 8.29952 5.08276 8.22384C5.26548 8.14815 5.42166 8.01998 5.53154 7.85553C5.64142 7.69108 5.70007 7.49774 5.70007 7.29996C5.70007 7.03474 5.59472 6.78039 5.40718 6.59285C5.21964 6.40531 4.96529 6.29996 4.70007 6.29996Z"
        />
        <path
          fill={color}
          d="M8.20001 6.29996C8.00223 6.29996 7.80889 6.35861 7.64444 6.46849C7.47999 6.57837 7.35182 6.73455 7.27613 6.91727C7.20045 7.1 7.18064 7.30107 7.21923 7.49505C7.25781 7.68903 7.35305 7.86721 7.49291 8.00707C7.63276 8.14692 7.81094 8.24216 8.00492 8.28074C8.1989 8.31933 8.39997 8.29952 8.5827 8.22384C8.76542 8.14815 8.9216 8.01998 9.03148 7.85553C9.14136 7.69108 9.20001 7.49774 9.20001 7.29996C9.20001 7.03474 9.09466 6.78039 8.90712 6.59285C8.71958 6.40531 8.46523 6.29996 8.20001 6.29996Z"
        />
        <path
          fill={color}
          d="M11.7 6.29996C11.5022 6.29996 11.3089 6.35861 11.1444 6.46849C10.98 6.57837 10.8518 6.73455 10.7761 6.91727C10.7004 7.1 10.6806 7.30107 10.7192 7.49505C10.7578 7.68903 10.8531 7.86721 10.9929 8.00707C11.1328 8.14692 11.3109 8.24216 11.5049 8.28074C11.6989 8.31933 11.9 8.29952 12.0827 8.22384C12.2654 8.14815 12.4216 8.01998 12.5315 7.85553C12.6414 7.69108 12.7 7.49774 12.7 7.29996C12.7 7.03474 12.5947 6.78039 12.4071 6.59285C12.2196 6.40531 11.9652 6.29996 11.7 6.29996Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3269_121930">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Chat;
