import * as React from 'react';
import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import usePreference from 'hooks/usePreference';

export type TooltipVariant = 'light' | 'dark';

export interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  variant?: TooltipVariant;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;

  /**
   * Force the tooltip to render ontop of all subling content, ignoring z-index.
   * This is useful when the tooltip is clipped from an `overflow: hidden` boundary.
   * @note Bad for performance; only use where needed.
   * @ref {@link https://floating-ui.com/docs/misc#clipping}
   **/
  forceOnTop?: boolean;
}

export function useTooltip({
  initialOpen = false,
  placement = 'top',
  variant = 'light',
  forceOnTop = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const arrowRef = useRef(null);

  const { rightSidebarVariant } = usePreference();

  // Close the Tooltip when the layout changes
  useIsomorphicLayoutEffect(() => {
    setUncontrolledOpen(false);
  }, [rightSidebarVariant]);

  const data = useFloating({
    placement,
    open,
    strategy: forceOnTop ? 'fixed' : 'absolute',
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift({ padding: 5 }),
      arrow({
        element: arrowRef,
        padding: {
          top: 5,
          bottom: 5,
          left: 10,
          right: 10,
        },
      }),
    ],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
  });

  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });

  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const interactions = useInteractions([hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      variant,
      arrowRef,
      ...interactions,
      ...data,
    }),
    [open, setOpen, variant, arrowRef, interactions, data],
  );
}
