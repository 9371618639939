import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import { magicMediaQueries } from 'assets/styles/mediaQueries';
import { PrimaryButton, SecondaryButton } from 'components/Buttons/styles';
import { H4 } from 'components/Text/styles';
import { NameAndBadge } from 'components/User/styles';
import { VipLabel } from 'components/Vip/styles';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

export const VipCardGeneric = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${colors.gray800};
  border-radius: ${UI.radius.md};
  padding: 1rem;
  padding-bottom: ${pxToRem(20)};
`;

export const VipSidebarTabSelector = styled.div`
  width: 100%;
  padding: ${pxToRem(14)} ${UI.spacing.xl};
  border-bottom: 1px solid ${colors.gray700};
`;

export const VipSidebarTabContainer = styled.div`
  flex-grow: 1;
  overflow: overlay;
  padding: ${pxToRem(14)} ${pxToRem(16)};
`;

export const VipSidebarLearnMore = styled.div`
  display: flex;
  justify-content: center;

  a {
    color: ${colors.primaryViolet};
    text-decoration: underline;
    font-size: ${UI.text.lg};
  }
`;

export const VipSidebarRedeemCodeElement = styled.div`
  border-top: 1px solid ${colors.gray700};
`;

export const VipSidebarRedeemCodeHandle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${UI.spacing.lg};
  cursor: pointer;

  h4 {
    font-size: ${UI.text.xl};
  }
`;

export const VipSidebarCurrentLevelBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
  border-radius: ${UI.radius.md};

  h3 {
    font-size: ${UI.text.h2};
    line-height: ${UI.spacing.xl};
    margin-bottom: ${pxToRem(4)};
  }

  span {
    font-size: ${UI.text.xl};
    line-height: ${UI.spacing.lg};
  }
`;

export const VipSidebarBenefits = styled.div`
  margin-top: ${UI.spacing.lg};

  h4 {
    font-size: ${UI.text.xl};
    font-weight: 700;
    margin-bottom: ${UI.spacing.sm};
  }
`;

export const UserMenuVipWrapper = styled.div`
  background-color: ${colors.gray800};
  border-radius: ${UI.radius.sm};
  padding: ${pxToRem(16)};
  margin-bottom: ${UI.spacing.md};
  font-size: ${UI.text.md};
  line-height: 0px;
  user-select: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const UserMenuVipProgress = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${UI.spacing.xs};
`;

export const VipPageOverviewCard = styled.div`
  display: grid;
  gap: ${pxToRem(18)};
  grid-template-columns: repeat(1, minmax(0, 1fr));
  padding: ${UI.spacing.md};
  border-radius: ${UI.radius.md};
  background-color: ${colors.gray900};

  ${magicMediaQueries(Breakpoint.MD)`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: ${UI.spacing.xl};
    gap: ${pxToRem(48)};
  `};
`;

export const VipPageOverviewUser = styled.div`
  ${NameAndBadge} {
    h2 {
      font-size: ${UI.text.xl};
    }
  }

  ${VipLabel} {
    font-size: ${UI.text.lg};
  }
`;

export const VipCurrentProgressWrapper = styled.div`
  p {
    font-size: ${UI.text.lg};
    line-height: ${pxToRem(21)};
    font-weight: 400;
  }
`;

export const VipPageOverviewDescription = styled.span`
  font-weight: 400;
  font-size: ${UI.text.lg};
  margin-top: 0.5rem;
`;

export const VipPageOverviewCardItem = styled.div`
  flex: 1;
`;

export const VipOverviewImage = styled.div`
  width: 100%;
  min-height: 10rem;
  overflow: hidden;
  background-color: ${colors.gray800};
  border-radius: ${UI.radius.sm};
  position: relative;
`;

export const VipOverviewBannerIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
`;

export const VipRewardBlockElement = styled(VipCardGeneric)<{ $gridColumn: 1 | 2 }>`
  gap: ${pxToRem(13)};
  align-items: center;
  flex-grow: 1;
  font-size: ${UI.text.md};
  background-color: ${colors.gray900};
  border: 1px solid ${colors.gray700};
  position: relative;
  ${({ $gridColumn }) => $gridColumn === 2 && 'grid-column: 1 / span 2'};

  padding-top: 1rem;

  ${H4} {
    font-size: ${UI.text.lg};
  }

  ${PrimaryButton} {
    margin-top: ${UI.spacing.xs};
  }

  ${SecondaryButton} {
    margin-top: ${UI.spacing.xs};
  }
`;

export const VipContainer = styled.div`
  padding: 1rem;
`;

export const VipImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${pxToRem(141)};
  margin-bottom: ${pxToRem(8)};
`;

export const VipAvailableRewards = styled.p`
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  font-weight: 700;
  font-size: 1rem;
`;

export const VipOnlyBannerWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  z-index: 10;
`;

export const VipBackdrop = styled.div`
  background: ${colors.gray900};
  opacity: 0.6;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

export const VipOnlyCard = styled.div`
  z-index: 1;
  background: ${colors.gray900};
  border: 1px solid ${colors.gray700};
  border-radius: ${UI.radius.md};
  padding: ${UI.spacing.lg};
  margin: 0 1rem;
  text-align: center;
  min-width: ${pxToRem(300)};
`;

export const VipText = styled.p`
  font-size: ${UI.text.lg};
  line-height: 1.5;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const VipBlockText = styled.p`
  font-size: ${UI.text.lg};
  line-height: 1.5;
  text-align: center;
  margin-top: ${pxToRem(6)};
`;

export const VipRewardHeader = styled(H4)`
  color: ${colors.success};
  text-align: center;
`;

export const VipButton = styled(PrimaryButton)`
  height: ${pxToRem(40)};
`;

export const VipClaimedBtn = styled.div`
  height: ${pxToRem(40)};
  margin-top: 0.5rem;
  border: 1px solid ${colors.success};
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.success};
  border-radius: ${UI.radius.sm};
  column-gap: ${pxToRem(11)};
  font-weight: 500;
  font-size: ${pxToRem(14)};
  line-height: 1.5;
  gap: ${pxToRem(12)};
  cursor: not-allowed;
  pointer-events: none;
  width: 100%;
`;

export const VipClaimedWithNextReloadBtn = styled(SecondaryButton)`
  height: ${pxToRem(48)};
  width: 90%;
`;

export const VipGiftIcon = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const VipRewardBlockIconContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: ${pxToRem(18)};
`;

export const VipExpiryText = styled.p<{ $claimed?: boolean }>`
  color: ${({ $claimed }) => ($claimed ? colors.primaryViolet : colors.white)};
  font-size: ${UI.text.md};
  font-weight: 500;
  line-height: 1.5;
`;

export const VipTotalRewarded = styled.span`
  font-weight: 400;
  font-size: ${UI.text.lg};

  ${magicMediaQueries(Breakpoint.MD)`
    font-size: ${UI.text.h4};
  `};
`;

export const VipRewardedDescription = styled(VipTotalRewarded)`
  font-size: ${UI.text.md};
  color: ${colors.lavenderBlue};

  ${magicMediaQueries(Breakpoint.MD)`
    font-size: ${UI.text.lg};
  `};
`;

export const VipOveriewTotalRewardedWrapper = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 65%;
  height: 100%;
  padding: ${pxToRem(24)};
  z-index: 1;

  ${magicMediaQueries(Breakpoint.MD)`
     width: 49%;
  `};
`;

export const TotalRewardedAmount = styled.span`
  font-weight: 700;
  font-size: ${UI.text.h4};
  line-height: 130%;

  ${magicMediaQueries(Breakpoint.MD)`
     font-size: ${UI.text.h3};
  `};
`;
