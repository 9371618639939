import React from 'react';
import { Currency } from 'generated/graphql';

export const Crypto = {
  [Currency.BTC]: 'btc',
  [Currency.ETH]: 'eth',
  [Currency.LTC]: 'ltc',
  [Currency.MATIC]: 'matic',
  [Currency.TRX]: 'trx',
  [Currency.USDC]: 'usdc',
  [Currency.USDT]: 'usdt',
  [Currency.XRP]: 'xrp',
  [Currency.SOL]: 'sol',
  [Currency.DOGE]: 'doge',
  [Currency.BNB]: 'bnb',
  [Currency.BUSD]: 'busd',
  [Currency.SHIB]: 'shib',
};

const CryptoIcon = ({ currency }: { currency: Currency }) => {
  const iconSrc = Crypto[currency];

  if (!iconSrc) return null;

  return <img src={`/icons/crypto/${iconSrc}.svg`} alt={iconSrc} loading={'lazy'} />;
};
export default CryptoIcon;
