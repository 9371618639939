import { AuthModalTabType, AuthModalType, WalletModalTabType } from '../../redux/slices/modalSlice';

export type ModalType = {
  type:
    | 'sportBet'
    | 'bet'
    | 'wallet'
    | 'auth'
    | 'user'
    | 'vault'
    | 'challengePrize'
    | 'sportBetCashOut'
    | 'sportBetCashOutSuccess'
    | 'sportBetCashOutTryAgain'
    | 'sportBetCashOutFailed';

  isOpen: boolean;
  id?: string;
  name?: string;
  currency?: string;
  amount?: string;
  tab?: WalletModalTabType | AuthModalTabType | AuthModalType;
  classNames?: Partial<{
    modalContent: string;
    modalBody: string;
  }>;
};

export enum QueryTypes {
  'md-tab' = 'md-tab',
  'md-id' = 'md-id',
  'modal' = 'modal',
  'md-name' = 'md-name',
  'md-currency' = 'md-currency',
  'md-amount' = 'md-amount',
}

export type ModalOnClose = () => void;
