import { useCallback, useMemo } from 'react';
import { clientWithoutAuth } from 'apollo/clients';
import {
  BlackjackGameSetting,
  OriginalGame,
  OriginalGameSetting,
  useGetGlobalDataQuery,
} from 'generated/graphql';

type GameSetting = Omit<OriginalGameSetting & BlackjackGameSetting, '__typename'>;
export function useGlobalData() {
  const { data } = useGetGlobalDataQuery({
    client: clientWithoutAuth,
    fetchPolicy: 'cache-and-network',
  });

  const getGameSetting = useCallback(
    (game: OriginalGame): GameSetting => {
      const settings = data?.appSettings;

      if (!settings) return { maxBetUSD: 0, maxPayoutUSD: 0 };

      const mapping: Record<OriginalGame, GameSetting> = {
        [OriginalGame.DICE]: settings.dice,
        [OriginalGame.HILO]: settings.hilo,
        [OriginalGame.LIMBO]: settings.limbo,
        [OriginalGame.MINES]: settings.mines,
        [OriginalGame.CRASH]: settings.crash,
        [OriginalGame.KENO]: settings.keno,
        [OriginalGame.PLINKO]: settings.plinko,
        [OriginalGame.BLACKJACK]: settings.blackjack,
      };

      return mapping[game];
    },
    [data],
  );

  const getSportSetting = useCallback(() => {
    const settings = data?.appSettings;
    if (!settings) return { minBetUSD: 0, minPartialBetRatio: 0 };
    return settings.sportsBet;
  }, [data?.appSettings]);

  const minTipUsd = useMemo(() => {
    const settings = data?.appSettings;
    if (!settings) return 5;
    return settings.minTipUSD;
  }, [data?.appSettings]);

  return {
    ...data,
    getGameSetting,
    getSportSetting,
    minTipUsd,
  };
}
