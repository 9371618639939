import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';

import { useDateFormat } from './format/useDateFormat';

type Props = {
  dateTime: string;
  formattedDateTime: string;
  shortened?: boolean;
};

const SIXTY_MINUTES = 60;
const INTERVAL_DURATION_MILLISECONDS = 5000;

export function usePreLiveSportCountDown({ dateTime, formattedDateTime, shortened }: Props) {
  const [countdownTime, setCountdownTime] = useState(formattedDateTime);
  const [countdownStart, setCountdownStart] = useState(false);
  const { t } = useTranslation();
  const { getRelativeDate } = useDateFormat();

  const getDashPosition = useCallback((dateTime: string) => dateTime.indexOf('-'), []);

  useEffect(() => {
    const checkCountDownTimer = () => {
      const minutesDifference = dayjs(dateTime).diff(dayjs(), 'minute');

      if (!dateTime) {
        return;
      }

      if (minutesDifference < 0) {
        clearInterval(countdownTimer);
        setCountdownStart(false);
        return setCountdownTime(currentDatetime => {
          const dashPosition = getDashPosition(currentDatetime);
          return `${currentDatetime.substring(0, dashPosition + 1)} ${getRelativeDate(dateTime)}`;
        });
      }

      if (minutesDifference > SIXTY_MINUTES) {
        return;
      }

      setCountdownStart(true);

      setCountdownTime(dateTime => {
        const dashPosition = getDashPosition(dateTime);
        const moreThanMinute = minutesDifference > 1;

        if (minutesDifference === 0) {
          return t('aboutToStart');
        }

        if (!dashPosition) {
          return dateTime;
        }

        const prefix = shortened ? t('startsIn') : `${dateTime.substring(0, dashPosition + 1)} In`;

        return `${prefix} ${
          moreThanMinute
            ? t('minutes', { duration: minutesDifference })
            : t('minute_one', { count: minutesDifference })
        }`;
      });
    };

    const countdownTimer = setInterval(checkCountDownTimer, INTERVAL_DURATION_MILLISECONDS);

    checkCountDownTimer();

    return () => clearInterval(countdownTimer);
  }, [getDashPosition, getRelativeDate, dateTime, t, shortened]);

  return {
    countdownTime,
    countdownStart,
  };
}
