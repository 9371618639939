import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ChatRoom } from 'generated/graphql';
import {
  BrowserPreferencesState,
  RightSidebarVariant,
  setInitialDefault,
  setRightSidebar,
} from 'redux/slices/browserPreferenceSlice';

import { useLanguagePreference } from './useLanguagePreference';

export const useSyncLocalStorage = ({
  browserPreference,
}: {
  browserPreference: BrowserPreferencesState;
}) => {
  const dispatch = useDispatch();
  const { rightSidebarVariant, chatRoom, chatRoomSet } = browserPreference;
  const { defaultChatRoomLanguage } = useLanguagePreference();

  useEffect(() => {
    if (!Object.values(RightSidebarVariant).includes(rightSidebarVariant)) {
      dispatch(setRightSidebar({ variant: RightSidebarVariant.NONE }));
    }
  }, [dispatch, rightSidebarVariant]);

  useEffect(() => {
    if (!Object.values(ChatRoom).includes(chatRoom)) {
      dispatch(setInitialDefault({ room: ChatRoom.ENGLISH }));
    }

    if (!chatRoomSet) {
      dispatch(setInitialDefault({ room: defaultChatRoomLanguage }));
    }
  }, [chatRoom, chatRoomSet, dispatch, defaultChatRoomLanguage]);
};
