import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { currencyOptions } from 'constants/currency';
import { ChatRoom, Currency, TipSendInput } from 'generated/graphql';

export interface TipState {
  currency: Currency;
  amount: string | null;
  receiverUsername: string | null;
  chatRoom?: ChatRoom;
  hasOtpSent: boolean;
  hasConfirmed: boolean;
}

const initialCurrency = currencyOptions[0].value as Currency;

const initialState: TipState = {
  currency: initialCurrency,
  amount: null,
  chatRoom: undefined,
  receiverUsername: null,
  hasOtpSent: false,
  hasConfirmed: false,
};

const tipSlice = createSlice({
  name: 'tip',
  initialState,
  reducers: {
    initiateTip: (state: TipState, action: PayloadAction<TipSendInput>) => {
      state.amount = action.payload.amount;
      state.currency = action.payload.currency;
      state.chatRoom = action.payload.chatRoom || undefined;
      state.receiverUsername = action.payload.receiverUsername;
    },
    resetTip: () => ({ ...initialState }),
  },
});

export const { initiateTip, resetTip } = tipSlice.actions;

export default tipSlice.reducer;
