import { memo, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import cache from 'apollo/cache';
import { client } from 'apollo/clients';
import { Language } from 'constants/languages';
import { useGlobalCampaignCodeSetup } from 'hooks/useCampaignCode';
import useModal from 'hooks/useModal';
import { useGlobalEmailVerified, useGlobalUpdateVipLevel } from 'hooks/useMyProfile';
import { useGlobalPollPrices } from 'hooks/usePrice';
import useQueryParams from 'hooks/useQueryParams';
import { useSegment } from 'hooks/useSegment';
import { useSportsPagesSubscriptions } from 'hooks/useSportsPagesSubscriptions';
import { useSyncLocalStorage } from 'hooks/useSyncLocalStorage';
import { useUpdateDefaultFiatPreference } from 'hooks/useUpdateDefaultFiatPreference';
import { useVipRewards } from 'hooks/vip/useVipRewards';
import { useGlobalUpdateBalances } from 'hooks/wallet/useBalances';
import { useRouter } from 'next/router';
import { setIsContentfulPreview } from 'redux/slices/globalStateSlice';
import { getCookie, setLanguagePreferenceCookie } from 'utils/appCookie';

import { useIsLoggedIn } from '../../hooks/auth/useIsLoggedIn';
import { usePersistStoreData } from '../../hooks/usePersistStoreData';

import { useSetDefaultCurrency } from './useSetDefaultCurrency';
import { useSetupCorrelationId } from './useSetupCorrelationId';
import { useGlobalSubscriptionAlerts } from './useSubscriptionAlerts';
import { useUserTracking } from './useUserTracking';

const MINUTE = 60 * 1000;

// This has to be a component and not a hook
// because it is used in _app.tsx with the provider code
// if it was a hook it would not have access to some of the provider data
const GlobalSetup = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { isContentfulPreview, accessToken, browserPreference } = useSelector((app: AppState) => {
    return {
      isContentfulPreview: app.globalState.isContentfulPreview,
      accessToken: app.auth.accessToken,
      browserPreference: app.browserPreference,
    };
  }, shallowEqual);
  const { intercomUserLogin } = useSegment();
  const { openModalByUrl } = useModal();
  const { addQueryParams } = useQueryParams();
  const isLoggedIn = useIsLoggedIn();

  usePersistStoreData();

  useEffect(() => {
    // Persist `?preview=true` across all client-side navigation for Contentful previews.
    dispatch(setIsContentfulPreview(router.query.preview === 'true'));
  }, [dispatch, router]);

  useEffect(() => {
    // Append ?preview=true to routes to let the getServerSideProps that it's preview mode.
    if (isContentfulPreview && !router.query.preview) {
      addQueryParams({ preview: 'true' }, false);
    }
  }, [addQueryParams, isContentfulPreview, router.query.preview]);

  useUpdateEffect(() => {
    // reset store so that the wslink client reconnects with new auth token
    client.resetStore();
  }, [accessToken]);

  useEffect(() => {
    // Setup cache.gc to run every minute to prevent memory leakage issues
    const interval = setInterval(() => {
      cache.gc({ resetResultCache: true });
    }, MINUTE);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    openModalByUrl();
  }, [openModalByUrl]);

  useEffect(() => {
    intercomUserLogin();
  }, [intercomUserLogin]);

  useEffect(() => {
    const languagePreference = getCookie('language-preference');
    if (languagePreference) {
      setLanguagePreferenceCookie(languagePreference as Language);
    }
  }, []);

  useGlobalSubscriptionAlerts({ isLoggedIn });
  useGlobalUpdateBalances({ isLoggedIn });
  useGlobalUpdateVipLevel({ isLoggedIn });
  useGlobalEmailVerified({ isLoggedIn });
  useVipRewards({ isLoggedIn });
  useGlobalCampaignCodeSetup();
  useGlobalPollPrices({ browserPreference });
  useSetDefaultCurrency({ browserPreference });
  useSyncLocalStorage({ browserPreference });
  useSportsPagesSubscriptions();
  useSetupCorrelationId();
  useUserTracking();
  useUpdateDefaultFiatPreference({ browserPreference });

  return null;
};

export default memo(GlobalSetup);
