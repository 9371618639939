import { BetSlipItem } from '../../../../redux/slices/sportsBetSlice';

import { getBetViewConfig } from './getBetViewConfig';

export function findNextStartBet(bets: BetSlipItem[]) {
  return Array.isArray(bets)
    ? [...bets]
        .sort((a, b) => {
          return new Date(a.startTime) < new Date(b.startTime) ? -1 : 1;
        })
        .find(
          bet =>
            !getBetViewConfig({
              status: bet.status,
            }).isResulted,
        )?.startTime
    : undefined;
}
