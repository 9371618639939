import React from 'react';

import { SvgProps } from '../..';

const AmericanFootball = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_10555_127455)">
        <path
          d="M14.9081 1.11432C13.3022 0.688869 11.622 0.623993 9.98802 0.924342C8.35405 1.22469 6.80677 1.88283 5.45711 2.85157C4.10744 3.8203 2.98881 5.07565 2.18143 6.52761C1.37404 7.97957 0.897888 9.59217 0.787109 11.2498H12.7496V19.4818C12.7496 20.473 13.142 21.4239 13.8411 22.1266C14.5401 22.8293 15.4889 23.2267 16.4801 23.2318V23.2318C16.9742 23.2344 17.4639 23.1393 17.9212 22.952C18.3784 22.7647 18.7941 22.4889 19.1444 22.1404C19.4947 21.7919 19.7726 21.3776 19.9623 20.9214C20.152 20.4652 20.2496 19.9759 20.2496 19.4818V18.7498C20.2496 14.2498 23.2496 14.2498 23.2496 11.2498C23.1953 8.87025 22.3436 6.57784 20.8311 4.74002C19.3185 2.9022 17.2328 1.62542 14.9081 1.11432V1.11432Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.75 14.25C6.75 17.25 6.75 21.75 0.75 23.25V17.25H9.75"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10555_127455">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AmericanFootball;
