import React, { useMemo } from 'react';
import { pxToRem } from 'assets/styles/convertors';
import { CRYPTO_DECIMALS } from 'constants/decimalPlaces';
import { Currency } from 'generated/graphql';
import usePreference from 'hooks/usePreference';
import { useTranslation } from 'next-i18next';

import { useFormattedDisplayAmount } from '../hooks/useFormattedDisplayAmount';

import GeneralCurrencyIcon from './CurrencyIcons/GeneralCurrencyIcon';
import { Flex } from './commonStyles';
import Tooltip from './Tooltip';

import styles from './FormattedAmount.module.css';

// Handles the display in fiat with tooltip
const FiatWithTooltip = ({
  value,
  currency,
  formattedCrypto,
  dataTestId,
}: {
  value: string;
  currency: Currency;
  formattedCrypto?: string;
  dataTestId?: string;
}) => {
  const { t } = useTranslation();

  const cryptoDisplayValue = useMemo(
    () => (
      <span>
        {t('intlNumber', {
          val: Number(value) ?? 0,
          minimumFractionDigits: CRYPTO_DECIMALS,
          maximumFractionDigits: CRYPTO_DECIMALS,
        })}{' '}
        {currency}
      </span>
    ),
    [value, t, currency],
  );

  return (
    <span className={styles.evenlySpacedNumber}>
      <Tooltip>
        <Tooltip.Trigger data-testid={dataTestId}>{formattedCrypto}</Tooltip.Trigger>
        <Tooltip.Content>{cryptoDisplayValue}</Tooltip.Content>
      </Tooltip>
    </span>
  );
};

const FormattedAmount = ({
  value,
  currency,
  maxDigits = Infinity,
  hideTooltip = false,
  invert = false,
  showCryptoCurrency = false,
  showIcon = false,
  dataTestId,
}: {
  value: string;
  currency: Currency;
  maxDigits?: number;
  hideTooltip?: boolean;
  showCryptoCurrency?: boolean;
  invert?: boolean;
  showIcon?: boolean;
  dataTestId?: string;
}) => {
  const { displayInFiat } = usePreference();
  const formattedValue = useFormattedDisplayAmount(value, currency, {
    invert,
    maxDigits,
    showCryptoCurrency,
  });

  return (
    <Flex align="center" gap={pxToRem(8)}>
      {showIcon && <GeneralCurrencyIcon currency={currency} />}
      {displayInFiat && !hideTooltip ? (
        <FiatWithTooltip
          value={value}
          currency={currency}
          formattedCrypto={formattedValue}
          dataTestId={dataTestId}
        />
      ) : (
        <span className={styles.evenlySpacedNumber} data-testid={dataTestId}>
          {formattedValue}
        </span>
      )}
    </Flex>
  );
};

export default React.memo(FormattedAmount);
