import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import { USER_LOGOUT } from 'constants/general';

import alertsReducer from './slices/alertsSlice';
import authReducer from './slices/authSlice';
import autobetReducer from './slices/autobetSlice';
import balancesReducer from './slices/balancesSlice';
import browserPreferenceReducer from './slices/browserPreferenceSlice';
import campaignSlice from './slices/campaignSlice';
import chatReducer from './slices/chatSlice';
import crashReducer from './slices/crashSlice';
import gamesReducer from './slices/gamesSlice';
import gameStatisticReducer from './slices/gameStatisticSlice';
import globalStateReducer from './slices/globalStateSlice';
import loginReducer from './slices/loginSlice';
import modalReducer from './slices/modalSlice';
import plinkoReducer from './slices/plinkoSlice';
import pricesReducer from './slices/pricesSlice';
import providerGameSlice from './slices/providerGameSlice';
import sportsBetReducer from './slices/sportsBetSlice';
import tipReducer from './slices/tipSlice';
import vipSlice from './slices/vipSlice';
import withdrawReducer from './slices/withdrawSlice';

const appReducer = combineReducers({
  alerts: alertsReducer,
  campaign: campaignSlice,
  auth: authReducer,
  login: loginReducer,
  modal: modalReducer,
  browserPreference: browserPreferenceReducer,
  withdraw: withdrawReducer,
  tip: tipReducer,
  plinko: plinkoReducer,
  crash: crashReducer,
  prices: pricesReducer,
  chat: chatReducer,
  games: gamesReducer,
  balances: balancesReducer,
  autobet: autobetReducer,
  globalState: globalStateReducer,
  sportsBet: sportsBetReducer,
  gameStatistics: gameStatisticReducer,
  providerGame: providerGameSlice,
  vip: vipSlice,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === USER_LOGOUT) {
    const browserPreference = state.browserPreference;
    state = { browserPreference };
    return appReducer(state, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
