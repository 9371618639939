import React from 'react';
import { VipLevelParams } from 'hooks/vip/useVip';

import { VipBadgeElement } from './styles';
import VipIcon from './VipIcon';

interface VipBageProps extends VipLevelParams {
  label?: string;
  size?: 'sm' | 'md' | 'lg';
}

const VipBadge = ({ label, size = 'md', icon }: VipBageProps) => {
  return (
    <VipBadgeElement $size={size}>
      {icon && <VipIcon icon={icon} />}
      {label && <p>{label}</p>}
    </VipBadgeElement>
  );
};

export default React.memo(VipBadge);
