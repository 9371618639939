import { useDispatch, useSelector } from 'react-redux';
import { clientWithoutAuth } from 'apollo/clients';
import { updatePrices } from 'redux/slices/pricesSlice';

import { Currency, useGetPricesQuery } from '../generated/graphql';
import { BrowserPreferencesState } from '../redux/slices/browserPreferenceSlice';

const POLLING_MS = 30_000;

export const useGlobalPollPrices = ({
  browserPreference: { fiatPreference },
}: {
  browserPreference: BrowserPreferencesState;
}): void => {
  const dispatch = useDispatch();

  useGetPricesQuery({
    variables: {
      currency: fiatPreference,
    },
    pollInterval: POLLING_MS,
    client: clientWithoutAuth,
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const pricesObjArr = data?.cryptoPrices || [];
      const fiatPricesObjArr = data?.fiatPrices || [];

      dispatch(
        updatePrices({
          fiatCurrency: fiatPreference,
          prices: pricesObjArr,
          fiatPrices: fiatPricesObjArr,
        }),
      );
    },
  });
};

export const usePrice = (cryptoCurrency: Currency): string => {
  return useSelector((state: AppState) => state.prices.prices[cryptoCurrency]);
};
