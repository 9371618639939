import React, { useState } from 'react';
import { Information } from 'assets/svgs/16px';
import clsx from 'clsx';

import colors from '../../../../assets/styles/colors';
import { ShuffleSmall } from '../../../../assets/svgs';
import Tooltip from '../../../../components/Tooltip';
import { BetSlipErrors } from '../../../../hooks/useSportsBetSlipCheck';
import { BetSlipStatus } from '../../../../redux/slices/sportsBetSlice';
import { SportsBetTicketStatusType } from '../BetsGroup.type';

import { BetContent } from './betContent';
import { getBetViewConfig } from './getBetViewConfig';

import styles from './BetBrandFooter.module.scss';

type Props = {
  status?: SportsBetTicketStatusType;
  betSlipStatus?: BetSlipStatus;
  onClickViewDetail?: () => void;
  content: BetContent;
  errors: BetSlipErrors;
};

export function BetBrandFooter({
  betSlipStatus,
  status,
  onClickViewDetail,
  content,
  errors,
}: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const {
    shouldShowReceipt,
    isInBetAddOrConfirmed,
    isInBetInfoView,
    isInBetPendingView,
    isInBetResultView,
    isInBetPlacedView,
  } = getBetViewConfig({
    betSlipStatus,
    status,
  });

  if (!shouldShowReceipt) {
    return null;
  }

  return (
    <>
      {!isInBetAddOrConfirmed && (
        <div className={styles.betBrandFooter}>
          <div
            className={clsx(styles.brandWrapper, {
              [styles.brandWrapperCentered]: isInBetInfoView,
            })}
          >
            <div className={styles.brandBody}>
              <ShuffleSmall color={colors.white} scale={0.5} />
              <p className={styles.brandText} data-testid={'bet-slip-branding'}>
                {'Shuffle.com'}
              </p>
            </div>
            {(isInBetPendingView || isInBetResultView || isInBetPlacedView) &&
              onClickViewDetail &&
              !errors.placedBetError && (
                <Tooltip placement={'left'}>
                  <Tooltip.Trigger>
                    <button
                      className={styles.viewDetailBtn}
                      data-testid={'bet-brand-footer-view'}
                      onClick={onClickViewDetail}
                      onMouseLeave={() => {
                        if (!showTooltip) {
                          setShowTooltip(true);
                        }
                      }}
                    >
                      <Information />
                    </button>
                  </Tooltip.Trigger>
                  <Tooltip.Content>{content.viewDetails()}</Tooltip.Content>
                </Tooltip>
              )}
          </div>
        </div>
      )}
    </>
  );
}
