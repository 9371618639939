import * as React from 'react';

import { SvgProps } from './index';

export const BetSlip = ({ color = '#FFFFFF' }: SvgProps) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4231 0.5H1.57692C0.982462 0.5 0.5 0.918133 0.5 1.43333V15.5L3.19231 13.6333L5.34615 15.5L7.5 13.6333L9.65385 15.5L11.8077 13.6333L14.5 15.5V1.43333C14.5 0.918133 14.0175 0.5 13.4231 0.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.73047 5.16699H11.2689"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.73047 8.90039H11.2689"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
