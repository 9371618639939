import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Profile, Search, Shuffle, ShuffleSmall, Wallet } from 'assets/svgs';
import BaseButton from 'components/Buttons/BaseButton';
import RoundButton from 'components/Buttons/RoundButton';
import ClickOutsideWrapper from 'components/ClickOutsideWrapper';
import { IconWrapper } from 'components/commonStyles';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import { useMobileNav } from 'hooks/useMobileNav';
import { ModalUrl } from 'hooks/useModal';
import usePreference from 'hooks/usePreference';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { RightSidebarVariant } from 'redux/slices/browserPreferenceSlice';
import { MobileMenuName } from 'redux/slices/globalStateSlice';
import { AuthModalTabType, WalletModalTabType } from 'redux/slices/modalSlice';

import { useIsSport } from '../../hooks/useIsSport';
import { isMobile } from '../../utils/userAgent';
import { openGlobalModal } from '../Modal/openGlobalModal';
import { SearchStateProps } from '../Search/search.type';

import BalanceButton from './Balance/BalanceButton';
import IconMenu from './IconMenu/IconMenu';
import UserMenu, { ExpandMenuElement } from './UserMenu/UserMenu';
import {
  BtnContainer,
  ButtonTransparent,
  DisplayOnMobile,
  IOSChomeScrollHider,
  MenuDivider,
  NavContainer,
  NavIconsMenu,
  NavMenu,
  NavWrapper,
  SearchWrapper,
  WalletBtn,
  WalletBtnText,
} from './styles';

import styles from './Header.module.scss';

type Props = {
  setGlobalSearch: React.Dispatch<React.SetStateAction<SearchStateProps>>;
};

// !: If the height of the header is changed, update the headerHeight in views/games/game.tsx
export const Header = ({ setGlobalSearch }: Props) => {
  const { t } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const router = useRouter();
  const { rightSidebarVariant } = usePreference();
  const activeMobileNav = useSelector((app: AppState) => app.globalState.activeMobileNav);
  const { setActiveMobileNav } = useMobileNav();
  const { isSportPage } = useIsSport();

  const shouldShowRightSidebar = rightSidebarVariant !== RightSidebarVariant.NONE;

  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  const toggleSearch = () => {
    setGlobalSearch({
      type: isSportPage ? 'sports' : 'casino',
      isOpen: true,
    });

    if (isMobile) {
      if (activeMobileNav === MobileMenuName.SEARCH) {
        setActiveMobileNav(MobileMenuName.SPORTS);
      } else {
        setActiveMobileNav(MobileMenuName.SEARCH);
      }
    }
  };

  const onClickProfile = useCallback(() => {
    setOpenProfileMenu(!openProfileMenu);
  }, [openProfileMenu, setOpenProfileMenu]);

  const onClickOutside = useCallback(() => {
    setOpenProfileMenu(false);
  }, []);

  return (
    <NavWrapper id="nav-header">
      <IOSChomeScrollHider />
      <NavContainer>
        <SearchWrapper>
          <RoundButton color="tertiary" icon={<Search />} onClick={toggleSearch} title={'Search'} />
        </SearchWrapper>
        <DisplayOnMobile>
          <Link href="/" passHref>
            <IconWrapper>{isLoggedIn ? <ShuffleSmall /> : <Shuffle />}</IconWrapper>
          </Link>
        </DisplayOnMobile>

        <NavMenu>
          {isLoggedIn ? (
            <BtnContainer>
              <BalanceButton />

              <WalletBtn
                id="wallet-btn"
                onClick={() => {
                  openGlobalModal({
                    router,
                    tab: WalletModalTabType.DEPOSIT,
                    type: 'wallet',
                  });
                }}
              >
                <Wallet />
                <WalletBtnText>{t('wallet')}</WalletBtnText>
              </WalletBtn>
            </BtnContainer>
          ) : (
            <>
              <ButtonTransparent
                className={styles.authButton}
                color="tertiary"
                value={t('btnLogin')}
                onClick={() => {
                  openGlobalModal({
                    router,
                    tab: AuthModalTabType.LOGIN,
                    type: ModalUrl.AUTH,
                  });
                }}
              />
              <BaseButton
                className={styles.authButton}
                value={t('btnRegister')}
                onClick={() => {
                  openGlobalModal({
                    router,
                    tab: AuthModalTabType.REGISTER,
                    type: ModalUrl.AUTH,
                  });
                }}
              />
            </>
          )}
        </NavMenu>

        {isLoggedIn && (
          <>
            <NavIconsMenu>
              <IconMenu />
              <MenuDivider $collapse={shouldShowRightSidebar} />
              <UserMenu />
            </NavIconsMenu>

            <DisplayOnMobile>
              <ClickOutsideWrapper onClickOutside={onClickOutside}>
                <RoundButton color="tertiary" icon={<Profile />} onClick={onClickProfile} />
                <ExpandMenuElement show={openProfileMenu} onClick={onClickProfile} />
              </ClickOutsideWrapper>
            </DisplayOnMobile>
          </>
        )}
      </NavContainer>{' '}
    </NavWrapper>
  );
};

export default Header;
