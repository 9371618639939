import React from 'react';
import { loader } from 'components/LoadingContent';
import dynamic from 'next/dynamic';

import { RenderClient } from '../components/RenderClient';
import { SearchStateProps } from '../components/Search/search.type';

import CasinoLayout from './CasinoLayout';

const ActivityBoard = dynamic(() => import('views/ActivityBoard/ActivityBoard'), {
  loading: loader,
  ssr: false,
});

interface Props {
  children: React.ReactNode;
  casinoLayout?: boolean;
  activityBoard?: boolean;
  sports?: boolean;
  setGlobalSearch: React.Dispatch<React.SetStateAction<SearchStateProps>>;
}

const Layout = ({ children, casinoLayout, activityBoard, sports, setGlobalSearch }: Props) => {
  if (casinoLayout) {
    return (
      <CasinoLayout setGlobalSearch={setGlobalSearch}>
        {children}
        {activityBoard && (
          <RenderClient>
            <ActivityBoard type={sports ? 'sports' : 'casino'} />
          </RenderClient>
        )}
      </CasinoLayout>
    );
  }

  return <>{children}</>;
};

export default React.memo(Layout);
