import { useCallback } from 'react';
import { useLogoutMutation, useLogoutOtherSessionsMutation } from 'generated/graphql';

export const useSetting = () => {
  const [logout, { loading: isLoadingLogout, error: logoutError }] = useLogoutMutation();
  const [
    logoutOtherSessions,
    { loading: isLoadingLogoutOtherSessions, error: logoutOtherSessionsError },
  ] = useLogoutOtherSessionsMutation();

  const removeSessionById = useCallback(
    async (sessionId: string) => {
      try {
        const { data } = await logout({
          variables: {
            sessionId,
          },
        });

        if (data) {
          return true;
        }
      } catch (e) {
        throw new Error('Failed to remove session');
      }
    },
    [logout],
  );

  const removeAllSessions = useCallback(async () => {
    try {
      const { data } = await logoutOtherSessions();

      if (data) {
        return true;
      }
    } catch (e) {
      throw new Error('Failed to remove session');
    }
  }, [logoutOtherSessions]);

  return {
    removeSessionById,
    removeActiveSession: logout,
    isLoadingLogout,
    logoutError,
    removeAllSessions,
    isLoadingLogoutOtherSessions,
    logoutOtherSessionsError,
  };
};
