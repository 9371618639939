import React, { ForwardedRef, ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import colors from 'assets/styles/colors';
import { Times } from 'assets/svgs';
import UI from 'constants/ui';
import { AnimatePresence, motion } from 'framer-motion';
import useBreakpoint, { Breakpoint } from 'hooks/interface/useBreakpoint';
import { RIGHT_SIDEBAR_WIDTH_PX } from 'hooks/interface/useBreakpointMagic';
import useGlobalState from 'hooks/useGlobalState';
import { useMobileNav } from 'hooks/useMobileNav';
import usePreference from 'hooks/usePreference';
import { usePreventScrolling } from 'hooks/usePreventScrolling';
import { RightSidebarVariant, setRightSidebar } from 'redux/slices/browserPreferenceSlice';
import { MobileMenuName } from 'redux/slices/globalStateSlice';

import { useSportsBet } from '../../hooks/useSportsBet';
import { BetSlipStatus } from '../../redux/slices/sportsBetSlice';

import styles from './index.module.scss';

interface RightSidebarProps {
  children: ReactNode;
  fullscreen?: boolean;
}

export const fullscreenRightSidebarVariants: RightSidebarVariant[] = [RightSidebarVariant.BET_SLIP];

const Overlay = ({ children, fullscreen = false }: RightSidebarProps) => {
  return (
    <div
      className={styles.rightSidebarMobileOverlay}
      style={{
        top: fullscreen ? 0 : `${UI.height.navHeader}px`,
        bottom: fullscreen ? 0 : `${UI.height.mobileNav - 1}px`,
        zIndex: fullscreen ? UI.z.bottomNavigation + 1 : 1,
      }}
    >
      <div className={styles.rightSidebarWrapper}>{children}</div>
    </div>
  );
};

const RightSidebar = ({ children, fullscreen = false }: RightSidebarProps) => {
  const { rightSidebarVariant } = usePreference();
  const { lessThanBreakpoint } = useBreakpoint();

  const open = rightSidebarVariant !== RightSidebarVariant.NONE;
  const asOverlay = lessThanBreakpoint(Breakpoint.MD);

  usePreventScrolling(open);

  if (asOverlay) {
    return open ? <Overlay fullscreen={fullscreen}>{children}</Overlay> : null;
  }

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={styles.rightSidebarMotion}
          initial={{ width: 0 }}
          animate={{ width: RIGHT_SIDEBAR_WIDTH_PX }}
          exit={{ width: 0 }}
          transition={{ bounce: 0 }}
        >
          <div className={styles.rightSidebarWrapper}>{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface RightSidebarHeaderProps {
  spacing?: string;
  children: React.ReactNode;
}

const RightSidebarHeader = ({ spacing = UI.spacing.md, children }: RightSidebarHeaderProps) => {
  const dispatch = useDispatch();
  const { clearBetSlip } = useSportsBet();
  const { activeMobileNav } = useGlobalState();
  const { setActiveMobileNav } = useMobileNav();
  const betSlipStatus = useSelector((state: AppState) => state.sportsBet.betSlipStatus);

  const close = useCallback(() => {
    dispatch(setRightSidebar({ variant: RightSidebarVariant.NONE }));

    if (betSlipStatus === BetSlipStatus.BET_PLACED) {
      clearBetSlip();
    }

    if (activeMobileNav === MobileMenuName.CHAT) {
      setActiveMobileNav(MobileMenuName.CASINO);
    }
  }, [activeMobileNav, betSlipStatus, clearBetSlip, dispatch, setActiveMobileNav]);

  return (
    <div
      className={styles.rightSidebarHeaderElement}
      style={{
        padding: `0 ${spacing}`,
      }}
    >
      {children}

      <button className={styles.closeIcon} onClick={close} aria-label={'close panel'}>
        <Times color={colors.white} />
      </button>
    </div>
  );
};

RightSidebar.Header = RightSidebarHeader;
RightSidebar.Content = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => (
  <section className={styles.rightSidebarContent}>{children}</section>
);
RightSidebar.Scrollable = React.forwardRef(
  (
    {
      children,
      $disableScroll,
    }: {
      children: React.ReactNode | React.ReactNode[];
      $disableScroll?: boolean;
    },
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <div
      ref={ref}
      className={styles.rightSidebarScrollable}
      {...($disableScroll
        ? {
            style: {
              overflow: 'hidden',
            },
          }
        : {})}
    >
      {children}
    </div>
  ),
);
RightSidebar.Footer = ({
  children,
  padding,
}: {
  children: React.ReactNode | React.ReactNode[];
  padding?: string;
}) => (
  <section className={styles.rightSidebarFooter} {...(padding ? { style: { padding } } : {})}>
    {children}
  </section>
);

export default RightSidebar;
