import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export const MODAL_ID = 'modal';
export const MODAL_BODY_ID = 'modal-body';
export const LIVE_GAME_STATS_ID = 'live-game-stats';

let modalContainer: HTMLDivElement;
if (typeof window !== 'undefined') {
  const modalRootEl = document.getElementById(MODAL_ID);
  modalContainer = document.createElement('div');

  if (modalRootEl && modalContainer) {
    modalRootEl.appendChild(modalContainer);
  }
}

export const ModalWrapper = ({ children }: { children: ReactNode }) => {
  return ReactDOM.createPortal(children, modalContainer);
};
