import { useCallback } from 'react';

import { useLanguagePreference } from './useLanguagePreference';
import { useMyProfile } from './useMyProfile';

interface Traits {
  email?: string;
  signedUpAt?: string;
  userId?: string | null;
  name?: string | null;
  intercom?: {
    language_override?: string;
  };
}

interface Integrations {
  Intercom?: {
    user_hash: string;
  };
}

interface Analytics {
  identify: (a: string | Traits, b?: Traits | Integrations, c?: Integrations) => void;
}

declare global {
  interface Window {
    analytics: Analytics;
  }
}

export const useSegment = () => {
  const { myProfile } = useMyProfile();
  const { intercomLanguage } = useLanguagePreference();

  const identify = useCallback(
    (traits: Traits, integrations?: Integrations) => {
      if (window.analytics) {
        // TODO: Not important but Handle when window.analytics takes long to load
        // Should save all the data into an array and send them once analytics is ready
        // Also should log an error if this fails
        if (myProfile?.id) {
          const traitsArg = {
            email: myProfile?.email,
            createdAt: new Date(myProfile?.createdAt).toISOString(),
            name: myProfile?.username,
            ...traits,
          };

          window.analytics.identify(myProfile?.id, traitsArg, integrations);
        } else {
          // For non logged in users, segment will create an anon id internally
          window.analytics.identify(traits, integrations);
        }
      }
    },
    [myProfile],
  );

  const intercomToken = myProfile?.intercomToken;

  const intercomUserLogin = useCallback(() => {
    if (intercomToken) {
      identify(
        {
          intercom: {
            language_override: intercomLanguage,
          },
        },
        {
          Intercom: {
            user_hash: intercomToken,
          },
        },
      );
    }
  }, [intercomToken, identify, intercomLanguage]);

  return {
    intercomUserLogin,
    identify,
  };
};
