import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import colors from 'assets/styles/colors';
import { Times } from 'assets/svgs';
import { Flex } from 'components/commonStyles';
import TabView from 'components/TabView/TabView';
import useGlobalState from 'hooks/useGlobalState';
import { useRouter } from 'next/router';
import { NavMenuType, setActiveNavMenuType } from 'redux/slices/globalStateSlice';

import { Casino } from '../../assets/svgs/24px/Casino';
import { Tennis } from '../../assets/svgs/sports';

import CasinoTab from './CasinoTab';
import { isEnableSports } from './menu';
import SportsTab from './SportsTab';
import { CloseIcon, NavContent, NavTabList } from './styles';

interface NavigationContentProps {
  isExpanded: boolean;
  showTooltip?: boolean;
  handleCloseMenu?: () => void;
}

export const NavigationContent = ({
  isExpanded,
  showTooltip = true,
  handleCloseMenu,
}: NavigationContentProps) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { pathname } = router;

  const { activeNavMenuType } = useGlobalState();

  useEffect(() => {
    // Check url is sport content should be active the menu type is SPORTS
    if (isEnableSports && pathname.includes('/sports')) {
      dispatch(setActiveNavMenuType(NavMenuType.SPORTS));
    } else {
      dispatch(setActiveNavMenuType(NavMenuType.CASINO));
    }
  }, [dispatch, pathname]);

  const onSwitchTab = (e: React.TouchEvent | React.MouseEvent, id: string) => {
    dispatch(setActiveNavMenuType(id as NavMenuType));
    if (id === NavMenuType.SPORTS) {
      router.push('/sports');
    } else {
      router.push('/');
    }
  };

  let sportsTooltip = 'txtSportsComingSoon';

  if (!showTooltip) {
    sportsTooltip = '';
  } else if (isEnableSports) {
    sportsTooltip = 'tabSports';
  }

  return (
    <NavContent>
      <NavTabList isExpanded={isExpanded}>
        <Flex gap="1rem" align="center" justify="center">
          <TabView
            tooltipPlacement="right"
            tabs={[
              {
                id: NavMenuType.CASINO,
                name: isExpanded ? 'tabCasino' : '',
                Icon: <Casino />,
                title: showTooltip ? 'tabCasino' : '',
              },
              {
                id: NavMenuType.SPORTS,
                name: isExpanded ? 'tabSports' : '',
                Icon: <Tennis />,
                title: sportsTooltip,
                disabled: !isEnableSports,
              },
            ]}
            activeTab={activeNavMenuType}
            onClick={onSwitchTab}
          />
          {handleCloseMenu && (
            <CloseIcon onClick={handleCloseMenu}>
              <Times color={colors.white} />
            </CloseIcon>
          )}
        </Flex>
      </NavTabList>
      {activeNavMenuType === NavMenuType.CASINO && <CasinoTab isExpanded={isExpanded} />}
      {activeNavMenuType === NavMenuType.SPORTS && <SportsTab isExpanded={isExpanded} />}
    </NavContent>
  );
};

export default NavigationContent;
