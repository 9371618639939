import React from 'react';

import { SvgProps } from '../..';

const Golf = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g id="Property 1=golf-03" clipPath="url(#clip0_10555_127458)">
        <g id="Group">
          <path
            id="Vector"
            d="M4.5 14.0865C2.199 15.048 0.75 16.4445 0.75 18C0.75 20.8995 5.787 23.25 12 23.25C18.213 23.25 23.25 20.8995 23.25 18C23.25 15.1005 18.213 12.75 12 12.75"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M8.25 18V0.75L16.5 4.5L8.25 8.25"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10555_127458">
          <rect width="24" height="24" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Golf;
