import { ApolloLink, createHttpLink, from, fromPromise, split, toPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import { sleep } from 'utils/sleep';

import { customFetch } from './custom-fetch';
import {
  genCorrelationId,
  geoRestrictionAlert,
  getAccessToken,
  getRefreshedTokenPromise,
  setUserCountry,
} from './utils';

const accessTokenPromises: Record<
  string,
  Promise<{
    accessToken: string;
  }>
> = {};

function getCachedAccessToken(accessToken: string) {
  accessTokenPromises[accessToken] = accessTokenPromises[accessToken] ?? getRefreshedTokenPromise();
  return accessTokenPromises[accessToken];
}

export const countryLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();

    const country = context.response.headers.get('x-country');

    setUserCountry(country);

    return response;
  });
});

export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const errorCode = err.message;

      switch (errorCode) {
        case 'GEO_REGION_RESTRICTED': {
          geoRestrictionAlert();
          break;
        }
        case 'UNAUTHENTICATED': {
          const oldAccessToken: string =
            operation.getContext().headers['Authorization'].split(' ')[1] ?? 'accessToken';

          return fromPromise(
            getCachedAccessToken(oldAccessToken).then(refreshResponse => {
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  Authorization: `Bearer ${refreshResponse.accessToken}` || '',
                },
              }));
              return toPromise(forward(operation));
            }),
          );
        }
        default:
          console.error(`[GraphQL error]: Message: ${err.message}`);
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

export const authLink = setContext((_, { headers }) => {
  const accessToken = getAccessToken();
  return {
    headers: {
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      'X-Correlation-Id': genCorrelationId(),
    },
  };
});

export const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_API,
  fetch: customFetch,
});

const TEN_THOUSAND_RETRIES = 10_000; // A few days
let timedOut: NodeJS.Timeout;

export const wsLink =
  typeof window !== 'undefined'
    ? new GraphQLWsLink(
        createClient({
          url: process.env.NEXT_PUBLIC_SUBSCRIPTIONS_API?.replace(/^http/, 'ws') as string,
          connectionParams: () => {
            const accessToken = getAccessToken();

            return {
              authorization: accessToken ?? '',
            };
          },
          keepAlive: 10_000,
          connectionAckWaitTimeout: 5_000,
          retryAttempts: TEN_THOUSAND_RETRIES,
          on: {
            ping: received => {
              if (!received /* sent */) {
                timedOut = setTimeout(() => {
                  // a close event `4499: Terminated` is issued to the current WebSocket and an
                  // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
                  // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
                  //
                  // calling terminate is not considered fatal and a connection retry will occur as expected
                  //
                  // see: https://github.com/enisdenjo/graphql-ws/discussions/290
                  wsLink?.client.terminate();
                }, 5_000);
              }
            },
            pong: received => {
              if (received) {
                clearTimeout(timedOut);
              }
            },
          },
          retryWait: async (numRetry: number) => {
            // Log retries, keeps on retrying but gets slightly longer each time
            // numRetry starts at 0, so we should start at 1
            const waitTime = Math.log(numRetry + 1) * 5000;
            await sleep(waitTime);
          },
          shouldRetry: () => true,
        }),
      )
    : null;

/**
 * We split the link because NextJS SSR tries to create client server-side.
 * WebSocket is only available client-side so it's conditional on `window` existing.
 */
export const splitLink =
  typeof window !== 'undefined' && wsLink != null
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        },
        wsLink,
        from([countryLink, httpLink]),
      )
    : httpLink;

export const uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_API,
});
