import React, { memo } from 'react';

import BrazilFlag from './RoundFlags/BrazilFlag';
import ChinaFlag from './RoundFlags/ChinaFlag';
import FranceFlag from './RoundFlags/FranceFlag';
import GermanFlag from './RoundFlags/GermanFlag';
import JapanFlag from './RoundFlags/JapanFlag';
import KoreaFlag from './RoundFlags/KoreaFlag';
import SpainFlag from './RoundFlags/SpainFlag';
import TurkeyFlag from './RoundFlags/TurkeyFlag';
import UnitedKingdomFlag from './RoundFlags/UnitedKingdomFlag';

const Flags = {
  UK: UnitedKingdomFlag,
  FR: FranceFlag,
  CN: ChinaFlag,
  ES: SpainFlag,
  BR: BrazilFlag,
  KR: KoreaFlag,
  JP: JapanFlag,
  DE: GermanFlag,
  TR: TurkeyFlag,
};

interface Props {
  country: 'UK' | 'FR' | 'CN' | 'ES' | 'BR' | 'KR' | 'JP' | 'DE' | 'TR';
  scale?: number;
}

const RoundFlag = ({ country, scale }: Props) => {
  const FlagComponent = Flags[country];

  return <FlagComponent scale={scale} />;
};

export default memo(RoundFlag);
