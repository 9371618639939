import { useEffect, useState } from 'react';
import { iconFetcher } from 'utils/icon-fetcher';

// Caches image, however it doesnt work well with optimization
export const useCachedImage = (src: string) => {
  const [iconBlob, setIconBlob] = useState<string | null>(null);

  useEffect(() => {
    if (src) {
      iconFetcher(src).then(setIconBlob);
    }
  }, [src]);

  return iconBlob;
};
