import React from 'react';

import { SvgProps } from '..';

const MMA = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_6582_150926)">
        <path
          d="M11.0003 11.3337C10.7019 11.8876 10.1723 12.2569 9.55617 12.2569C8.90149 12.2569 7.73372 11.9088 7.38712 11.3006V5.8263M10.9975 5.28321V11.5178C10.9975 11.5178 10.9975 11.5504 10.9975 11.5721C11.3056 12.1912 11.8929 12.604 12.5668 12.604C13.5585 12.604 14.3672 11.6916 14.3672 10.5729V3.9255C14.1939 1.75316 12.4705 1.42731 11.4789 1.42731C11.0649 1.42731 10.7664 1.48162 10.7664 1.48162C10.1599 0.873364 9.55335 0.666992 9.0142 0.666992C7.94553 0.666992 7.15606 1.48162 7.15606 1.48162C6.62653 1.12318 6.1644 0.992843 5.75041 0.992843C4.47956 0.992843 3.78636 2.29625 3.78636 2.29625C3.46865 2.20935 3.17982 2.17677 2.91987 2.17677C1.01359 2.17677 0.666992 4.19704 0.666992 4.19704V10.0189C0.666992 10.9965 1.81013 11.667 2.66699 11.667C3.2254 11.667 3.70678 11.3411 3.98599 10.8415M4.02706 6.36938V11.0399C4.33514 11.6482 5.33602 12.0003 6.00033 12.0003C6.55873 12.0003 7.04012 11.6745 7.31932 11.1748"
          stroke={color}
          strokeMiterlimit="10"
        />
        <path
          d="M14.8272 6.74995C14.6709 6.15488 14.4961 5.61295 14.349 5.13477V10.2141C14.349 11.6061 13.8715 12.667 12.6667 12.667C11.9309 12.667 11.3955 12.3152 11 11.667C10.6667 12.0003 10 12.3337 9.33333 12.3337C8.95625 12.3337 8.01616 11.8795 7.66667 11.667C7.52871 11.9645 7.46023 12.7538 7.37745 13.1044C7.30388 13.3807 7.35906 14.6566 7.53381 14.8691C7.67177 15.0391 7.85571 15.1348 8.05805 15.1348C8.10404 15.1348 8.15003 15.1348 8.20521 15.1135C8.40755 15.0604 8.64668 15.0073 8.88581 14.9435C10.2378 14.6035 12.0865 14.1253 13.6592 14.3378C14.6893 14.4759 15.1399 13.849 15.3331 13.2964C15.7102 12.2232 15.5446 9.36401 14.8272 6.73933V6.74995Z"
          stroke={color}
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_6582_150926">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MMA;
