import React, { useRef } from 'react';

interface Props {
  scale?: number;
}

const JapanFlag = ({ scale = 1 }: Props) => {
  const id = useRef(`FlagFlag${Math.random()}`);

  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 512 512">
      <mask id={id.current}>
        <circle cx="256" cy="256" r="256" fill="#fff" />
      </mask>
      <g mask={`url(#${id.current})`}>
        <path fill="#eee" d="M0 0h512v512H0z" />
        <circle cx="256" cy="256" r="111.3" fill="#d80027" />
      </g>
    </svg>
  );
};

export default JapanFlag;
