import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

export enum Breakpoint {
  XS = 'XS',
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
  XL = 'XL',
}

export const defaultBreakpoints: Record<Breakpoint, number> = {
  [Breakpoint.XS]: 600,
  [Breakpoint.SM]: 768,
  [Breakpoint.MD]: 992,
  [Breakpoint.LG]: 1140,
  [Breakpoint.XL]: 1500,
};

/**
 * Tracks the breakpoints of the window.
 * Pass in `target = 'inner'` to get the width INSIDE the CasinoLayout.
 *
 * Use this ONLY when CSS media queries can't do the job.
 * This is necessary in some situations because the sidebars reduce the
 * width of the layout and while the screen might be large, the content
 * might be squished.
 */
const useBreakpoint = () => {
  const { width } = useWindowSize(-1);

  const [size, setSize] = useState<Breakpoint | null>(Breakpoint.LG);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set loaded status
    if (width !== -1) {
      setLoading(false);
    }

    const isXL = width > defaultBreakpoints[Breakpoint.LG];
    const isLG =
      width >= defaultBreakpoints[Breakpoint.MD] && width <= defaultBreakpoints[Breakpoint.LG];
    const isMD =
      width >= defaultBreakpoints[Breakpoint.SM] && width <= defaultBreakpoints[Breakpoint.MD];
    const isSM =
      width >= defaultBreakpoints[Breakpoint.XS] && width <= defaultBreakpoints[Breakpoint.SM];
    const isXS = width < defaultBreakpoints[Breakpoint.XS];

    if (isXL) return setSize(Breakpoint.XL);
    if (isLG) return setSize(Breakpoint.LG);
    if (isMD) return setSize(Breakpoint.MD);
    if (isSM) return setSize(Breakpoint.SM);
    if (isXS) return setSize(Breakpoint.XS);
  }, [width]);

  const lessThanBreakpoint = useCallback(
    (breakpoint: Breakpoint) => width < defaultBreakpoints[breakpoint],
    [width],
  );
  const greaterThanBreakpoint = useCallback(
    (breakpoint: Breakpoint) => width > defaultBreakpoints[breakpoint],
    [width],
  );

  return {
    loading,
    breakpoints: defaultBreakpoints,
    lessThanBreakpoint,
    greaterThanBreakpoint,
    breakpoint: size,
  };
};

export default useBreakpoint;
