/**
 * Using ISO 639-1 language codes
 * @see {@link https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes}
 */
export enum Language {
  EN = 'en',
  FR = 'fr',
  ZH = 'zh',
  ES = 'es',
  PT = 'pt',
  JA = 'ja',
  KO = 'ko',
}
