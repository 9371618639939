import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiatCurrency } from 'generated/graphql';
import { BrowserPreferencesState, setInitialDefault } from 'redux/slices/browserPreferenceSlice';
import { getCookie } from 'utils/appCookie';

export const useUpdateDefaultFiatPreference = ({
  browserPreference,
}: {
  browserPreference: BrowserPreferencesState;
}) => {
  const dispatch = useDispatch();

  const { fiatPreferenceSet, displayInFiatSet } = browserPreference;

  useEffect(() => {
    const ipCountry = getCookie('ip-country');
    if (!displayInFiatSet && !fiatPreferenceSet && ipCountry === 'JP') {
      dispatch(setInitialDefault({ fiatPreference: FiatCurrency.JPY, displayInFiat: true }));
    }
  });
};
