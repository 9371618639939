import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Twitter = ({ scale = 1, color = '#4185F0', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9258 4.98737C13.9345 5.11756 13.9345 5.24775 13.9345 5.37914C13.9345 9.38269 10.9627 14 5.52855 14V13.9976C3.92329 14 2.35137 13.5284 1 12.6393C1.23342 12.6681 1.46801 12.6825 1.70318 12.6831C3.03349 12.6843 4.32577 12.2265 5.37235 11.3836C4.10815 11.359 2.99956 10.5136 2.61228 9.2795C3.05514 9.36709 3.51144 9.34909 3.9461 9.2273C2.56782 8.94172 1.57623 7.6998 1.57623 6.25749C1.57623 6.24429 1.57623 6.23169 1.57623 6.21909C1.98691 6.45368 2.44673 6.58387 2.91707 6.59827C1.61894 5.70852 1.21879 3.93744 2.0027 2.55272C3.50267 4.4456 5.71575 5.59633 8.09148 5.71812C7.85338 4.66579 8.17864 3.56306 8.94617 2.82331C10.1361 1.67618 12.0075 1.73497 13.1261 2.9547C13.7877 2.82091 14.4219 2.57192 15.0022 2.21914C14.7816 2.9205 14.3201 3.51626 13.7035 3.89484C14.2891 3.82404 14.8612 3.66325 15.4 3.41787C15.0034 4.02743 14.5038 4.5584 13.9258 4.98737Z"
        fill={color}
      />
    </svg>
  );
};

export default Twitter;
