import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import { mediaQueries } from 'assets/styles/mediaQueries';
import { ChevronDownWhite } from 'assets/svgs';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

import { InputVariant } from './TextInput';

export const FormControlWrapper = styled.div<{ $dimmed?: boolean }>`
  /* TODO: Remove this */
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
  opacity: ${({ $dimmed }) => ($dimmed ? 0.6 : 1)};

  text-align: left;
  width: 100%;
  transition: opacity 0.2s;
`;

export const InputGroupHorizontal = styled.div<{ shouldMarginTop?: boolean; $isModal?: boolean }>`
  display: flex;
  align-items: start;
  margin-top: ${({ shouldMarginTop }) => (shouldMarginTop ? '1.5rem' : 'unset')};
  flex-wrap: wrap;
  gap: ${UI.spacing.xs};

  ${({ $isModal }) => mediaQueries(Breakpoint.XS)`
    gap: ${$isModal ? '1rem' : '1.5rem'};
  `}
`;

export const InputGrowWrapper = styled.div`
  flex-grow: 1;
`;

export const LabelBlock = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
`;

export const Label = styled.label<{
  $float?: 'left' | 'right';
  $fontWeight?: string;
  $color?: string;
}>`
  display: inline-flex;
  margin-bottom: 0.25rem;
  font-size: ${UI.text.md};
  line-height: 1.25rem;
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : '500')};
  color: ${({ $color }) => ($color ? $color : colors.white)};
  word-break: break-word;
  justify-content: ${({ $float }) => ($float === 'right' ? 'flex-end' : 'flex-start')};
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CurrencyInputIcon = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  // to align the icon with the select input icon which has 16px of left padding + 1px of border
  left: ${pxToRem(17)};
  transform: translateY(-50%);
  display: flex;
`;

interface InputProps {
  hasIcon?: boolean;
}

const getInputBorderColor = ({
  isValid,
  hasError,
  variant,
}: {
  isValid?: boolean;
  hasError?: boolean;
  variant?: InputVariant;
}) => {
  if (hasError) return colors.error;
  if (isValid) return colors.success;
  return variant === 'outline' ? colors.gray700 : 'transparent';
};

export const Input = styled.input<
  InputProps & {
    variant?: InputVariant;
    hasError?: boolean;
    hasPrefix?: boolean;
    isValid?: boolean;
    $hasRightIcon?: boolean; // icon like a copy icon to the right of the input
  }
>`
  height: ${pxToRem(UI.height.input)};
  background-color: ${({ variant }) => (variant === 'outline' ? 'transparent' : colors.gray800)};
  border-radius: ${pxToRem(6)};
  width: 100%;
  color: ${({ color }) => (color ? color : colors.white)};
  display: block;
  text-align: left;
  font-size: ${UI.text.lg};
  padding: 0.75rem 0.75rem 0.75rem ${({ hasPrefix }) => (hasPrefix ? pxToRem(36) : '1rem')};
  box-sizing: border-box;
  border: 1px solid
    ${({ isValid, hasError, variant }) =>
      getInputBorderColor({
        isValid,
        hasError,
        variant,
      })};

  &:focus {
    outline: none;
    border: 1px solid ${colors.primaryViolet};
  }

  &::placeholder {
    color: ${colors.gray400};
  }

  &:disabled {
    opacity: 1;
  }

  ${({ readOnly, variant }) =>
    readOnly &&
    variant !== 'outline' &&
    `
        border: 1px solid transparent;
        cursor: default;
    `}

  // prevent the input from being covered by the copy icon
  padding-right: ${({ $hasRightIcon }) => $hasRightIcon && '3rem'};

  /* Change style for autofill */
  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 ${pxToRem(UI.height.input)}
        ${({ variant }) => (variant === 'outline' ? 'transparent' : colors.gray800)},
      inset 0 0 0 ${pxToRem(UI.height.input)}
        ${({ variant }) => (variant === 'outline' ? 'transparent' : colors.gray800)},
      inset 0 0 0 ${pxToRem(UI.height.input)}
        ${({ variant }) => (variant === 'outline' ? 'transparent' : colors.gray800)},
      inset 0 0 0 ${pxToRem(UI.height.input)}
        ${({ variant }) => (variant === 'outline' ? 'transparent' : colors.gray800)};
    -webkit-text-fill-color: ${colors.white} !important;
    border: none;
    caret-color: ${colors.white} !important;
    background-clip: content-box !important;

    &:focus {
      outline: none;
      border: 1px solid ${colors.primaryViolet} !important;
    }
  }
`;

export const CurrencyInputElement = styled(Input)<{
  profitDisplay?: boolean;
  dimmed?: boolean;
  bgColor?: string;
}>`
  position: relative;
  padding-left: ${pxToRem(43)}; // to align with select input text

  ${({ bgColor }) => bgColor && `background: ${bgColor};`}

  ${({ profitDisplay }) =>
    profitDisplay &&
    `
    background: none;
    border: 1px solid ${colors.gray700};

    &:focus {
      border: 1px solid ${colors.gray700} !important;
    }
  `}

   ${({ dimmed }) =>
    dimmed &&
    `
    color: ${colors.gray500} !important;
    -webkit-text-fill-color: ${colors.gray500} !important;
    pointer-events: none;
  `}
`;

interface FileInputWrapperProps {
  disabled?: boolean;
  hasError?: boolean;
  hasSelection?: boolean;
}

const getFileInputWrapperBorder = ({ disabled, hasError, hasSelection }: FileInputWrapperProps) => {
  if (hasError) return colors.error;
  if (hasSelection && !disabled) return colors.primaryViolet;
  return 'transparent';
};

export const FileInputWrapper = styled.label<FileInputWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: ${pxToRem(UI.height.input)};
  background-color: ${colors.gray800};
  border: 2px solid ${colors.borderInput};
  border-radius: ${pxToRem(6)};
  width: 100%;
  text-align: left;
  font-size: ${UI.text.lg};
  border-radius: ${UI.radius.sm};
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  box-sizing: border-box;

  color: ${({ disabled }) => (disabled ? colors.gray500 : colors.white)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  border: 1px solid ${getFileInputWrapperBorder};

  span {
    height: ${UI.text.lg};
    line-height: ${UI.text.lg};
    overflow: hidden;
    white-space: nowrap;
  }

  svg {
    margin-left: ${UI.spacing.sm};
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  }

  input {
    /* Styling as done here */
    /* https://www.benmarshall.me/styling-file-inputs/ */
    display: none;
  }
`;

export const InputWithIcon = styled(Input)<{
  color?: string;
  $hasIcon?: boolean;
  $truncateText: boolean;
}>`
  border-radius: ${({ $hasIcon }) => $hasIcon && `${UI.radius.sm}`};
  color: ${({ color }) => color && color};
  ${({ $truncateText }) =>
    $truncateText &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
  `}
`;

export const Select = styled.button<{ $open: boolean; $disabled?: boolean }>`
  color: inherit;
  display: grid;
  text-align: left;
  grid-template-columns: max-content auto;
  width: 100%;
  background-color: ${colors.gray800};
  border: ${({ $open, $disabled }) =>
    $open && !$disabled ? `1px solid ${colors.primaryViolet}` : '1px solid transparent'};
  padding: 0 1.75rem 0 1rem;
  height: ${pxToRem(UI.height.input)};
  border-radius: ${UI.radius.sm};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  ${({ $disabled }) => $disabled && 'pointer-events: none;'}

  align-items: center;
  column-gap: ${UI.spacing.sm};
`;

export const SelectOptionIcon = styled.div`
  display: flex;
`;

interface SelectOptionLabelProps {
  $disabled?: boolean;
  $selected?: boolean;
}

const getSelectOptionLabelColor = ({ $disabled, $selected }: SelectOptionLabelProps) => {
  if ($disabled) return colors.gray500;
  if ($selected) return colors.primaryViolet;
  return colors.white;
};

export const SelectOptionLabel = styled.div<SelectOptionLabelProps>`
  font-size: ${UI.text.lg};
  margin: 0 ${pxToRem(24)} 0 0;
  color: ${getSelectOptionLabelColor};
`;

export const CurrencySelectOptionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CurrencySelectBalance = styled.span`
  margin-left: auto;
  font-size: ${UI.text.lg};
  color: ${colors.gray400};
`;

export const Menu = styled.div<{ $disabled?: boolean }>`
  position: absolute;
  flex-direction: column;

  background-color: ${colors.gray800};
  border-radius: ${pxToRem(6)};
  user-select: none;
  filter: drop-shadow(0 ${pxToRem(6)} ${pxToRem(6)} ${colors.gray900});
  outline: none;

  /* Allow scrolling for very long menus */
  max-height: min(calc(100vh - 200px), 500px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: ${pxToRem(6)};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray500};
    border-radius: ${pxToRem(24)};
  }
`;

export const MenuItem = styled.button<{ $active: boolean; $selected: boolean }>`
  display: grid;
  text-align: left;
  border: 0;
  grid-template-columns: max-content auto;
  padding: ${pxToRem(12)} 0 ${pxToRem(12)} ${pxToRem(16)};
  column-gap: ${UI.spacing.xs};
  width: 100%;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  border-left: 1px solid ${colors.gray600};
  border-right: 1px solid ${colors.gray600};
  font-weight: ${({ $selected }) => ($selected ? '700' : '400')};

  background: ${({ $active, $selected }) =>
    $selected || $active ? colors.gray700 : colors.gray800};

  &:first-of-type {
    border-top: 1px solid ${colors.gray600};
    border-radius: ${UI.radius.sm} ${UI.radius.sm} 0 0;
  }

  &:last-of-type {
    border-bottom: 1px solid ${colors.gray600};

    border-radius: 0 0 ${UI.radius.sm} ${UI.radius.sm};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.gray600};
  }
`;

export const ChevronDownIcon = styled(props => <ChevronDownWhite {...props} />)<{
  $pointUp: boolean;
}>`
  transform: ${({ $pointUp }) => ($pointUp ? 'rotate(180deg)' : '')};
`;

export const ErrorMessage = styled.div`
  color: ${colors.error};
  margin-top: 0.25rem;
  font-size: ${UI.text.md};
`;

export const CheckboxContainer = styled(Label)`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: ${pxToRem(24)};
  cursor: pointer;
  font-size: ${UI.text.md};
  user-select: none;
  width: fit-content;
  height: 1rem;
  margin-bottom: 0;
`;

export const CheckboxCheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: ${pxToRem(16)};
  width: ${pxToRem(16)};
  border: ${pxToRem(1)} solid ${colors.gray500};
  border-radius: ${pxToRem(4)};
  background-color: transparent;

  &::after {
    content: '';
    position: absolute;
    top: ${pxToRem(1)};
    display: none;
    width: 100%;
    height: 100%;
    background: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 3 3 3 5-5' stroke='%23fff' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")
      no-repeat center;
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${CheckboxCheckMark} {
    background-color: ${colors.lightBlue};
  }

  &:checked ~ ${CheckboxCheckMark}:hover {
    border: ${pxToRem(1)} solid ${colors.gray400};
    background-color: ${colors.lightBlue};
  }

  &:focus ~ ${CheckboxCheckMark} {
    border: ${pxToRem(1)} solid ${colors.lightBlue};
  }

  &:disabled ~ ${CheckboxCheckMark} {
    border: ${pxToRem(1)} solid ${colors.gray300};
    background-color: ${colors.gray300};
  }

  &:checked ~ ${CheckboxCheckMark}:after {
    display: block;
  }
`;

export const RadioInputContainer = styled(Label)`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: ${pxToRem(24)};
  cursor: pointer;
  font-size: ${UI.text.md};
  user-select: none;
  width: fit-content;
  height: 1rem;
  margin-bottom: 0;
`;

const RADIO_INPUT_SIZE_PX = 16;
const RADIO_DOT_SIZE_PX = (3 / 8) * RADIO_INPUT_SIZE_PX;

export const RadioInputDot = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: ${pxToRem(RADIO_INPUT_SIZE_PX)};
  width: ${pxToRem(RADIO_INPUT_SIZE_PX)};
  border: ${pxToRem(1)} solid ${colors.gray500};
  border-radius: ${UI.radius.full};
  background-color: transparent;

  &::after {
    content: '';
    display: none;
    position: absolute;
    top: ${pxToRem(RADIO_INPUT_SIZE_PX / 4 + 0.5)};
    left: ${pxToRem(RADIO_INPUT_SIZE_PX / 4 + 0.5)};
    width: ${pxToRem(RADIO_DOT_SIZE_PX)};
    height: ${pxToRem(RADIO_DOT_SIZE_PX)};
    background-color: ${colors.white};
    border-radius: ${UI.radius.full};
  }
`;

export const RadioInputElement = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${RadioInputDot} {
    background-color: ${colors.lightBlue};
  }

  &:checked ~ ${RadioInputDot}:hover {
    border: ${pxToRem(1)} solid ${colors.gray400};
    background-color: ${colors.lightBlue};
  }

  &:focus ~ ${RadioInputDot} {
    border: ${pxToRem(1)} solid ${colors.lightBlue};
    background-color: ${colors.lightBlue};
  }

  &:disabled ~ ${RadioInputDot} {
    border: ${pxToRem(1)} solid ${colors.gray300};
    background-color: ${colors.gray300};
  }

  &:checked ~ ${RadioInputDot}:after {
    display: block;
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  background: transparent;
  height: ${pxToRem(UI.height.input)};
  display: grid;
  place-items: center;
  cursor: pointer;

  svg {
    font-size: ${UI.text.xl};
  }
`;

export const LabelSearchIcon = styled.span`
  background: transparent;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const InputPrefix = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${pxToRem(12)};
`;

export const InputSuffix = styled.span<{ dimmed?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.75rem;
  min-width: 1.25rem;
  width: fit-content;

  ${({ dimmed }) =>
    dimmed &&
    `
    color: ${colors.gray500} !important;
    pointer-events: none;
  `}
`;

export const InputSuffixIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${colors.gray800};
  border-radius: ${UI.radius.sm};
`;

export const SearchClearButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray500};
  border-radius: ${UI.radius.full};
  width: ${pxToRem(14)};
  height: ${pxToRem(14)};
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    width: 100%;
    min-width: ${pxToRem(14)};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.gray800};
    }
  }
`;

export const StyledSearchInput = styled.input`
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
  height: 3rem;
  color: ${colors.white};
  margin-left: ${pxToRem(9)};
  font-size: ${UI.text.lg};

  &::placeholder {
    color: ${colors.gray400};
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  width: 100%;
  min-width: 10rem;
  height: 100%;

  margin-right: auto;
  background: ${colors.gray900};

  padding: 0 1rem;
  border-radius: ${pxToRem(6)};
  border: 1px solid transparent;

  &:focus-within {
    border: 1px solid ${colors.primaryViolet};
  }
`;

export const ForgotPasswordLink = styled.div`
  text-align: right;
  margin-top: ${UI.spacing.xs};
`;

export const ForgotPasswordText = styled.span<{ $disabled: boolean }>`
  font-weight: 700;
  font-size: ${UI.text.md};
  color: ${colors.white};
  text-decoration: underline;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

export const OptionsContainer = styled.div`
  position: absolute;
  display: flex;
  column-gap: ${UI.spacing['2xs']};
  height: 2rem;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  z-index: 1;
`;

export const Option = styled.div<{ $selected?: boolean }>`
  color: ${({ $selected }) => ($selected ? colors.black : colors.white)};
  background: ${({ $selected }) => ($selected ? colors.white : colors.gray700)};
  border-radius: ${UI.radius.sm};
  padding: 0.5rem;
  font-weight: 500;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const DateInputWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const DateInputGroup = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: ${UI.spacing.xs};

  ${mediaQueries(Breakpoint.SM)`
    gap: ${UI.spacing.lg};
  `}
`;

export const DateInputDayWrapper = styled.div`
  flex: 1;
  min-width: 10rem;
`;

export const DateInputMonthWrapper = styled.div`
  flex: 1;
  min-width: 10rem;
`;

export const DateInputYearWrapper = styled.div`
  flex: 1;
  min-width: 10rem;
`;

export const DateInputErrorWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const PercentageInputOption = styled.span`
  font-weight: 500;
  font-size: ${UI.text.md};
`;

export const MaxPayoutInfo = styled.div`
  /* Using px here as it sets the height for an svg, whose height is in px */
  max-height: 16px;
`;
