import { pxToRem } from 'assets/styles/convertors';
import { mediaQueries } from 'assets/styles/mediaQueries';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

import colors from '../../../assets/styles/colors';

export const ExpandMenuWrapper = styled.div<{ $show?: boolean }>`
  position: absolute;
  top: 3.25rem;
  right: 0.25rem;
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  z-index: ${UI.z.modal};
  padding-top: 1rem;

  ${mediaQueries(Breakpoint.MD)`
    top: 2rem;
    right: 0;
    display: none;

    @media (hover: hover) {
      &:hover {
        display: flex;
      }
    }
  `};
`;

export const UserMenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: ${UI.spacing.sm};
  cursor: pointer;

  &:hover > ${ExpandMenuWrapper} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition-delay: 0.5s;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Name = styled.span`
  font-weight: 500;
  font-size: ${UI.text.lg};
`;

export const ArrowIcon = styled.span`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.375rem;
`;

export const ExpandMenu = styled.div`
  min-width: 16.25rem;
  width: 100%;
  padding: 1rem;
  background-color: ${colors.gray900};
  border: ${pxToRem(1)} solid ${colors.gray700};
  border-radius: ${UI.radius.md};
`;

export const MenuList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const MenuItem = styled.li`
  display: flex;
  width: 100%;
  height: ${pxToRem(UI.height.input)};
  border-radius: ${UI.radius.sm};
  align-items: center;
  justify-content: flex-start;
  padding: ${pxToRem(12)};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.gray800};
    }
  }
`;

export const MenuIcon = styled.span`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.625rem;
`;
