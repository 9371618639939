import React from 'react';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import BaseButton from 'components/Buttons/BaseButton';
import Odds from 'components/Odds';
import { usePreLiveSportCountDown } from 'hooks/usePreLiveSportCountDown';
import { generateLiveMatchProgressText } from 'utils/sportsGenerator';
import { getLiveMatchScoreText } from 'utils/sportsMatchStateUtils';

import colors from '../../../../assets/styles/colors';
import CryptoIcon from '../../../../components/CurrencyIcons/CryptoIcon';
import FormattedAmount from '../../../../components/FormattedAmount';
import CurrencyInput from '../../../../components/FormControls/CurrencyInput';
import { Currency, SportsMarketStatus, SportsMatchState } from '../../../../generated/graphql';
import { useDateFormat } from '../../../../hooks/format/useDateFormat';
import { BetSlipErrors } from '../../../../hooks/useSportsBetSlipCheck';
import { BetSlipItem, BetSlipStatus } from '../../../../redux/slices/sportsBetSlice';
import { getSportBetOdds } from '../../../../utils/getSportBetOdds';
import { AnimateLiveEventTime } from '../../../SportsHome/components/SportCardElements/AnimateLiveEventTime';
import { SportsBetTicketStatusType } from '../BetsGroup.type';

import { BetContent } from './betContent';
import { BetTicketAmount } from './BetTicketAmount';
import { BetTicketText } from './BetTicketText';
import { findNextStartBet } from './findNextStartBet';
import { getBetViewConfig } from './getBetViewConfig';

import styles from './BetGameEventInfo.module.scss';

type BetGameEventInfoProps = {
  bet: BetSlipItem;
  content: BetContent;
  betIds: string[];
  betSlipStatus?: BetSlipStatus;
  hasMultiSelections?: boolean;
  odds?: ReturnType<typeof getSportBetOdds>;
  oddsOutputText: string;
  amount: string;
  currency: Currency;
  estimatePayoutOutput: string;
  bets: BetSlipItem[];
  showOddsWarningMessage?: boolean;
  onChangeBetAmount?: (value: string) => void;
  onAcceptNewOdds?: (id: string) => void;
  onAcceptMultiNewOdds?: () => void;
  status?: SportsBetTicketStatusType;
  errors: BetSlipErrors;
  isLive?: boolean;
  isEventClosed?: boolean;
  matchState?: SportsMatchState;
};

export function BetGameEventInfo({
  content,
  betIds,
  betSlipStatus,
  hasMultiSelections,
  odds,
  oddsOutputText,
  bet: { id, sport, startTime, name, marketName, oddsNumerator, oddsDenominator, totalOddsDecimal },
  bet,
  amount,
  currency,
  estimatePayoutOutput,
  showOddsWarningMessage = false,
  onChangeBetAmount,
  onAcceptNewOdds,
  onAcceptMultiNewOdds,
  status,
  bets,
  isLive = false,
  isEventClosed = false,
  errors,
  matchState,
}: BetGameEventInfoProps) {
  const { formatDate } = useDateFormat();

  const {
    shouldHideDate,
    isInBetAddOrConfirmed,
    isInBetConfirmedView,
    isInBetPlacingView,
    isInBetPendingView,
    isInBetAddView,
    isInBetInfoView,
    isInBetPlacedView,
    isVoid,
    isPushed,
    isCanceled,
    isPending,
    shouldShowReceipt,
    shouldShowStats,
    shouldShowLiveEventInfo,
  } = getBetViewConfig({
    betSlipStatus,
    status: hasMultiSelections ? status : bet.status,
    isLive,
  });

  let colorFormattedStartTime = colors.gray300;
  const disableInput = !betIds.length || (hasMultiSelections && betIds.length < 2);
  const shouldShowFinalScore = isEventClosed && matchState;
  const allBetsResulted =
    bets.length && bets.every(bet => getBetViewConfig({ status: bet.status }).isResulted);
  const formattedStartTime = formatDate(
    hasMultiSelections ? findNextStartBet(bets) ?? startTime : startTime,
    'lll',
  );

  const liveMatchProgressOutput = generateLiveMatchProgressText({
    sport,
    matchStatistics: matchState,
  });
  const [time, text] = liveMatchProgressOutput.parts;
  const { liveScoreText, liveScoreList } = getLiveMatchScoreText({
    sport,
    matchStatistics: matchState,
    isClosed: isEventClosed,
  });

  const { countdownTime, countdownStart } = usePreLiveSportCountDown({
    dateTime: startTime,
    formattedDateTime: formattedStartTime,
    shortened: true,
  });

  const startTimeForDisplay = countdownStart ? countdownTime : formattedStartTime;

  if (
    (shouldShowLiveEventInfo && !hasMultiSelections && !shouldHideDate) ||
    (countdownStart && !hasMultiSelections)
  ) {
    colorFormattedStartTime = colors.success;
  } else if (
    betSlipStatus === BetSlipStatus.BET_PLACED ||
    isInBetPendingView ||
    (isPending && isInBetInfoView)
  ) {
    colorFormattedStartTime = colors.white;
  } else if (shouldHideDate || allBetsResulted) {
    colorFormattedStartTime = colors.gray500;
  }

  const handleAcceptNewOdds = (id: string) => {
    if (onAcceptNewOdds) {
      onAcceptNewOdds(id);
    }
  };

  const handleAcceptedMultiNewOdds = () => {
    if (onAcceptMultiNewOdds) {
      onAcceptMultiNewOdds();
    }
  };

  const hasFooter = !isInBetAddOrConfirmed && (shouldShowReceipt || shouldShowStats);

  const hasMarketNotOpenInBetAdd =
    isInBetAddOrConfirmed && bet.marketStatus !== SportsMarketStatus.OPEN;

  const hasPlacedBetError = isInBetPlacedView && errors.placedBetError;

  return (
    <div
      className={clsx(styles.betTicketBody, {
        [styles.betTicketBodyNoRounded]: hasFooter,
        [styles.betTicketBodyComfirmingView]: isInBetConfirmedView || isInBetPlacingView,
        [styles.betTicketBodyPlacedView]: isInBetPlacedView,
      })}
    >
      {hasMultiSelections && (
        <div className={styles.betTicketMarketBlock}>
          <div className={styles.totalLegsField}>
            <BetTicketText dimmed={hasMarketNotOpenInBetAdd} bold color={colors.primaryViolet}>
              {betIds.length} {content.legs()}
            </BetTicketText>
            <BetTicketText bold>{odds?.outputText ?? oddsOutputText}</BetTicketText>
          </div>

          {!isInBetAddOrConfirmed && (
            <div
              className={`${isVoid ? styles.voidedTime : ''} ${
                errors.placedBetError ? styles.voided : ''
              }`}
            >
              <BetTicketText
                textDecoration={shouldHideDate ? 'line-through' : 'none'}
                color={colorFormattedStartTime}
              >
                {hasMultiSelections
                  ? `${content.nextLeg()}: ${formattedStartTime}`
                  : formattedStartTime}
              </BetTicketText>
            </div>
          )}
        </div>
      )}
      {(isInBetAddView || !hasMultiSelections) && (
        <>
          {!hasMultiSelections && (
            <div className={styles.betTicketMarketBlock}>
              <div className={styles.selectionField}>
                <BetTicketText
                  dimmed={hasMarketNotOpenInBetAdd}
                  bold
                  color={colors.primaryViolet}
                  wrap
                >
                  {name}
                </BetTicketText>

                {isInBetAddView ? (
                  <>
                    {bet.marketStatus === SportsMarketStatus.OPEN && (
                      <BetTicketText bold>
                        <Odds
                          autoHideIndicator={!showOddsWarningMessage}
                          showColorOddsChanges
                          showOddsChanges={isInBetAddView}
                          oddsNumerator={oddsNumerator}
                          oddsDenominator={oddsDenominator}
                          totalOddsDecimal={totalOddsDecimal}
                          oddsDisplayPosition={'left'}
                        />
                      </BetTicketText>
                    )}
                    {bet.marketStatus === SportsMarketStatus.SUSPENDED && (
                      <BetTicketText color={colors.warning} bold>
                        {content.suspended()}
                      </BetTicketText>
                    )}
                    {bet.marketStatus === SportsMarketStatus.CLOSED && (
                      <BetTicketText color={colors.error} bold>
                        {content.closed()}
                      </BetTicketText>
                    )}
                  </>
                ) : (
                  <>
                    {odds?.outputText && (
                      <BetTicketText bold>
                        <span className={styles.betTicketTotalOdds}>{odds.outputText}</span>
                      </BetTicketText>
                    )}
                  </>
                )}
              </div>

              <div className={isVoid ? styles.voided : ''}>
                {marketName && (
                  <BetTicketText
                    dimmed={hasMarketNotOpenInBetAdd || hasPlacedBetError}
                    wrap
                    color={isCanceled || isPushed ? colors.gray400 : colors.gray300}
                  >
                    {marketName}
                  </BetTicketText>
                )}
              </div>

              <div className={isVoid ? styles.voided : ''}>
                {shouldShowLiveEventInfo ? (
                  <div className={styles.liveEventBlock}>
                    {time && text && (
                      <BetTicketText color={colors.success}>
                        <AnimateLiveEventTime time={time} text={text} variants={'green'} />
                      </BetTicketText>
                    )}
                    {liveScoreList.length > 1 ? (
                      <div className={styles.liveScoreBlock}>
                        <BetTicketText>{liveScoreList[0]}</BetTicketText>
                        <BetTicketText bold color={colors.success}>
                          {liveScoreList[1]}
                        </BetTicketText>
                      </div>
                    ) : (
                      <BetTicketText bold color={colors.success}>
                        {liveScoreText}
                      </BetTicketText>
                    )}
                  </div>
                ) : (
                  <>
                    {shouldShowFinalScore ? (
                      <div className={styles.liveEventBlock}>
                        <BetTicketText>{content.finalScore()}</BetTicketText>
                        <BetTicketText bold>{liveScoreText}</BetTicketText>
                      </div>
                    ) : (
                      <>
                        {!isInBetAddOrConfirmed && (
                          <BetTicketText
                            dimmed={hasMarketNotOpenInBetAdd || hasPlacedBetError}
                            color={colorFormattedStartTime}
                            textDecoration={shouldHideDate ? 'line-through' : 'none'}
                          >
                            {startTimeForDisplay}
                          </BetTicketText>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          {isInBetAddView && (
            <div className={isVoid ? styles.voided : ''}>
              <>
                {showOddsWarningMessage ? (
                  <div className={styles.acceptOddsButton}>
                    <BaseButton
                      aria-label={'accept odds change'}
                      onClick={
                        hasMultiSelections
                          ? handleAcceptedMultiNewOdds
                          : () => handleAcceptNewOdds(id)
                      }
                      value={content.acceptNewOddsButton()}
                    />
                  </div>
                ) : (
                  <div
                    className={clsx(styles.selectionField, {
                      [styles.dimmed]: hasMarketNotOpenInBetAdd,
                    })}
                  >
                    <div className={styles.betTicketAmountInput}>
                      <CurrencyInput
                        bgColor={colors.black}
                        onChange={onChangeBetAmount}
                        value={amount}
                        currency={currency}
                        placeholder={content.phEnterStake()}
                        hideRightLabel
                        disabled={disableInput}
                      />
                    </div>

                    <div className={styles.betTicketPayout}>
                      <BetTicketText rightAlign color={colors.white}>
                        {content.lblEstPayout()}
                      </BetTicketText>

                      <BetTicketAmount isPayout={BigNumber(estimatePayoutOutput).isGreaterThan(0)}>
                        <CryptoIcon currency={currency} />
                        <FormattedAmount value={estimatePayoutOutput} currency={currency} />
                      </BetTicketAmount>
                    </div>
                  </div>
                )}
              </>
            </div>
          )}
        </>
      )}
    </div>
  );
}
