import React from 'react';

import { SvgProps } from '.';

interface Props extends SvgProps {
  onClick?: () => void;
}

const DoubleTicks = ({ scale = 1, onClick, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: onClick ? 'pointer' : 'inherit' }}
      onClick={onClick}
    >
      <path
        d="M1.33594 7.99984L4.83594 11.3332L6.58594 9.33317M5.33594 7.99984L8.83594 11.3332L14.6693 4.6665M10.6693 4.6665L8.33594 7.33317"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleTicks;
