import React from 'react';
import { Baccarat, Blackjack, GameShow, LiveCasino, Roulette, Slots } from 'assets/svgs/casino';
import { SPORT_PAGE_TABS } from 'constants/sports';
import { MobileMenuName } from 'redux/slices/globalStateSlice';

import {
  CalendarDays,
  Chat,
  Home,
  LiveSports,
  Original,
  Search,
  SvgProps,
} from '../../assets/svgs';
import { Menu, Sport } from '../../assets/svgs/24px';
import { Casino } from '../../assets/svgs/24px/Casino';
import { AllSports } from '../../assets/svgs/AllSports';
import { BetSlip } from '../../assets/svgs/BetSlip';
import { Rewards } from '../../assets/svgs/casino/Rewards';
import {
  AmericanFootball,
  Baseball,
  Basketball,
  Boxing,
  Esports,
  Golf,
  MMA,
  RugbyUnion,
  Soccer,
  TableTennis,
  Tennis,
} from '../../assets/svgs/sports';
import { IceHockey } from '../../assets/svgs/sports/24px';
import { SportsBetSlip } from '../../assets/svgs/SportsBetSlip';

const params = new URLSearchParams(typeof window === 'undefined' ? '' : window?.location?.search);

export const isEnableSports =
  process.env.NEXT_PUBLIC_ENABLE_SPORTS === 'true' || params.get('soccer') === 'football';

export interface NavItem {
  href: string;
  name: string;
  icon?: React.FC<SvgProps>;
  isPrivate: boolean; // for authenticated pages
  items?: NavItem[];
  hasCounter?: boolean;
}

export interface MobileNavItem {
  href?: string;
  name: MobileMenuName;
  icon?: React.FC<SvgProps>;
  active: boolean;
}

export const CASINO_CATEGORIES: NavItem[] = [
  {
    href: '/casino/categories/slots',
    name: 'gameSlots',
    icon: Slots,
    isPrivate: false,
  },
  {
    href: '/casino/categories/originals',
    name: 'gameOriginals',
    icon: Original,
    isPrivate: false,
  },
  {
    href: '/casino/categories/live-casino',
    name: 'gameLiveCasino',
    icon: LiveCasino,
    isPrivate: false,
  },
  {
    href: '/casino/categories/blackjack',
    name: 'gameBlackjack',
    icon: Blackjack,
    isPrivate: false,
  },
  {
    href: '/casino/categories/roulette',
    name: 'gameRoulette',
    icon: Roulette,
    isPrivate: false,
  },
  {
    href: '/casino/categories/baccarat',
    name: 'gameBaccarat',
    icon: Baccarat,
    isPrivate: false,
  },
  {
    href: '/casino/categories/game-shows',
    name: 'gameGameShows',
    icon: GameShow,
    isPrivate: false,
  },
];

export const SPORTS_NAV: NavItem[] = [
  {
    href: '/sports',
    name: 'navHome',
    icon: Home,
    isPrivate: false,
  },
  // {
  //   href: '/sports/favourites',
  //   name: 'navFavourites',
  //   icon: Star,
  //   isPrivate: false,
  // },
  {
    href: '/sports?section=' + SPORT_PAGE_TABS.ALL + '&tab=3',
    name: 'navAllSports',
    icon: AllSports,
    isPrivate: false,
    items: [
      {
        href: '/sports/american-football',
        name: 'American Football',
        icon: AmericanFootball,
        isPrivate: false,
      },
      {
        href: '/sports/basketball',
        name: 'Basketball',
        icon: Basketball,
        isPrivate: false,
      },
      {
        href: '/sports/baseball',
        name: 'Baseball',
        icon: Baseball,
        isPrivate: false,
      },
      {
        href: '/sports/ice-hockey',
        name: 'Ice Hockey',
        icon: IceHockey,
        isPrivate: false,
      },
      {
        href: '/sports/soccer',
        name: 'Soccer',
        icon: Soccer,
        isPrivate: false,
      },
      {
        href: '/sports/tennis',
        name: 'Tennis',
        icon: Tennis,
        isPrivate: false,
      },
      {
        href: '/sports/mma',
        name: 'MMA',
        icon: MMA,
        isPrivate: false,
      },
      {
        href: '/sports/boxing',
        name: 'Boxing',
        icon: Boxing,
        isPrivate: false,
      },
      {
        href: '/sports/table-tennis',
        name: 'Table Tennis',
        icon: TableTennis,
        isPrivate: false,
      },
      {
        href: '/sports/golf',
        name: 'Golf',
        icon: Golf,
        isPrivate: false,
      },
      {
        href: '/sports/rugby-union',
        name: 'Rugby Union',
        icon: RugbyUnion,
        isPrivate: false,
      },
      {
        href: '/sports/esports',
        name: 'Esports',
        icon: Esports,
        isPrivate: false,
      },
      // Todo: can be enabled once those sports are ready
      // {
      //   href: '/sports/all',
      //   name: 'Aussie Rules',
      //   icon: Football,
      //   isPrivate: false,
      // },
      // {
      //   href: '/sports/all',
      //   name: 'Boxing',
      //   icon: Boxing,
      //   isPrivate: false,
      // },
      // {
      //   href: '/sports/all',
      //   name: 'Chess',
      //   icon: Chess,
      //   isPrivate: false,
      // },
      // {
      //   href: '/sports/all',
      //   name: 'Cricket',
      //   icon: Cricket,
      //   isPrivate: false,
      // },
      // {
      //   href: '/sports/ice-hockey',
      //   name: 'Cycling',
      //   icon: Cycling,
      //   isPrivate: false,
      // },
    ],
  },
  {
    href: '/sports?section=' + SPORT_PAGE_TABS.UPCOMING + '&tab=1',
    name: 'navUpcoming',
    icon: CalendarDays,
    isPrivate: false,
  },
  {
    href: '/sports?section=' + SPORT_PAGE_TABS.BET_LIVE + '&tab=2',
    name: 'navBetLive',
    icon: LiveSports,
    isPrivate: false,
    hasCounter: true,
  },
  {
    href: '/sports/transactions/sports-bets',
    name: 'My Bets',
    icon: SportsBetSlip,
    isPrivate: false,
  },
  // {
  //   href: '/sports/bet-live',
  //   name: 'navBetLive',
  //   icon: CirclePlay,
  //   isPrivate: false,
  // },
  // {
  //   href: '/sports/promotions',
  //   name: 'navPromotions',
  //   icon: Promotion,
  //   isPrivate: false,
  // },
];

export const POPULAR_SPORTS: NavItem[] = [
  {
    href: '/sports/baseball/baseball-united-states-of-america/597-major-league-baseball?tab=0',
    name: 'MLB',
    icon: Baseball,
    isPrivate: false,
  },
  {
    href: '/sports/soccer/soccer-england/36-england-premier-league?tab=0',
    name: 'EPL',
    icon: Soccer,
    isPrivate: false,
  },
  {
    href: '/sports/soccer/soccer-brazil/183-brazil-serie-a?tab=0',
    name: 'Serie A',
    icon: Soccer,
    isPrivate: false,
  },
  {
    href: '/sports/baseball/baseball-japan/1042-japan-professional-baseball?tab=0',
    name: 'NPB',
    icon: Baseball,
    isPrivate: false,
  },
  {
    href: '/sports/american-football/americanfootball-united-states-of-america/296-nfl?tab=0',
    name: 'NFL',
    icon: AmericanFootball,
    isPrivate: false,
  },
];

export const TOP_SPORTS: NavItem[] = [
  {
    href: '/sports/soccer',
    name: 'navSoccer',
    icon: Soccer,
    isPrivate: false,
  },
  {
    href: '/sports/basketball',
    name: 'navBasketball',
    icon: Basketball,
    isPrivate: false,
  },
  {
    href: '/sports/tennis',
    name: 'navTennis',
    icon: Tennis,
    isPrivate: false,
  },
  {
    href: '/sports/baseball',
    name: 'navBaseball',
    icon: Baseball,
    isPrivate: false,
  },
  {
    href: '/sports/esports',
    name: 'Esports',
    icon: Esports,
    isPrivate: false,
  },
];

export const CASINO_MOBILE_NAV: MobileNavItem[] = [
  {
    name: MobileMenuName.MENU,
    icon: Menu,
    active: true,
  },
  {
    name: MobileMenuName.SEARCH,
    icon: Search,
    active: true,
  },
  {
    name: MobileMenuName.CHAT,
    icon: Chat,
    active: true,
  },
  {
    href: '/vip-program',
    name: MobileMenuName.REWARDS,
    icon: Rewards,
    active: true,
  },
  {
    href: '/sports',
    name: MobileMenuName.SPORTS,
    icon: Sport,
    active: isEnableSports,
  },
];

export const SPORT_MOBILE_NAV: MobileNavItem[] = [
  {
    name: MobileMenuName.MENU,
    icon: Menu,
    active: true,
  },
  {
    name: MobileMenuName.SEARCH,
    icon: Search,
    active: true,
  },
  {
    name: MobileMenuName.CHAT,
    icon: Chat,
    active: true,
  },
  {
    name: MobileMenuName.SPORTS_BET,
    icon: BetSlip,
    active: true,
  },
  {
    href: '/',
    name: MobileMenuName.CASINO,
    icon: Casino,
    active: isEnableSports,
  },
];
