import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import useGlobalState from 'hooks/useGlobalState';
import { useMobileNav } from 'hooks/useMobileNav';
import useModal from 'hooks/useModal';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { MobileMenuName } from 'redux/slices/globalStateSlice';
import { AuthModalType } from 'redux/slices/modalSlice';

import { useIsSport } from '../../hooks/useIsSport';
import usePreference from '../../hooks/usePreference';
import {
  RightSidebarVariant,
  toggleBetSlipSidebar,
} from '../../redux/slices/browserPreferenceSlice';
import { SearchStateProps } from '../Search/search.type';

import { CASINO_MOBILE_NAV, MobileNavItem, SPORT_MOBILE_NAV } from './menu';
import { MobileNavigationBar } from './MobileNavigationBar';
import { MobileNavItemComponent } from './MobileNavItemComponent';
import { MobileNavWrapper } from './styles';

type Props = {
  manualFocusInput?: () => void;
  setGlobalSearch: React.Dispatch<React.SetStateAction<SearchStateProps>>;
};

const MobileNavigation = ({ manualFocusInput, setGlobalSearch }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const dispatch = useDispatch();
  const { rightSidebarVariant } = usePreference();
  const isLoggedIn = useIsLoggedIn();
  const { activeMobileNav } = useGlobalState();
  const { openAuthenticationModal } = useModal();
  const { setActiveMobileNav } = useMobileNav();
  const { isSportPage } = useIsSport();

  const { betSlips } = useSelector((state: AppState) => state.sportsBet, shallowEqual);

  // Determine current mobile page using pathname
  const activeMobilePage: MobileMenuName = useMemo(() => {
    if (pathname.startsWith('/sports')) {
      return MobileMenuName.SPORTS;
    }

    // Default to casino
    return MobileMenuName.CASINO;
  }, [pathname]);

  const handleChangeActiveMenu = useCallback(
    (item: MobileNavItem) => {
      if (item.name === MobileMenuName.SEARCH) {
        setGlobalSearch({
          type: isSportPage ? 'sports' : 'casino',
          isOpen: true,
        });

        manualFocusInput && manualFocusInput();
      }

      if (item.name === MobileMenuName.SPORTS_BET) {
        const shouldShowBetSlip = rightSidebarVariant === RightSidebarVariant.BET_SLIP;

        dispatch(toggleBetSlipSidebar({ open: !shouldShowBetSlip }));
      }

      if (item.name !== activeMobileNav) {
        if (item.name === MobileMenuName.CHAT && !isLoggedIn) {
          return openAuthenticationModal(AuthModalType.LoginAndRegister);
        }
        setActiveMobileNav(item.name);
      } else if (!item.href) {
        setActiveMobileNav(activeMobilePage);
      }
    },
    [
      manualFocusInput,
      isSportPage,
      activeMobileNav,
      setGlobalSearch,
      rightSidebarVariant,
      dispatch,
      isLoggedIn,
      setActiveMobileNav,
      openAuthenticationModal,
      activeMobilePage,
    ],
  );

  return (
    <>
      {activeMobileNav === MobileMenuName.MENU && (
        <MobileNavigationBar activeMobilePage={activeMobilePage} />
      )}

      <MobileNavWrapper>
        {(isSportPage ? SPORT_MOBILE_NAV : CASINO_MOBILE_NAV).map(item => {
          let indicatorNumber;

          const isSportBetButton = item.name === MobileMenuName.SPORTS_BET;
          const isBetSlipsOpen = rightSidebarVariant === RightSidebarVariant.BET_SLIP;

          if (isSportBetButton) {
            indicatorNumber = betSlips.length;
          }

          return (
            <div key={item.name}>
              {item.active ? (
                <MobileNavItemComponent
                  onClick={() => handleChangeActiveMenu(item)}
                  href={item.href}
                  isSportBetButton={isSportBetButton}
                  active={isSportBetButton ? isBetSlipsOpen : item.name === activeMobileNav}
                  name={t(item.name)}
                  icon={item.icon}
                  indicatorNumber={indicatorNumber}
                />
              ) : (
                <MobileNavItemComponent
                  name={t(item.name)}
                  isSportBetButton={isSportBetButton}
                  icon={item.icon}
                  disabled
                  indicatorNumber={indicatorNumber}
                />
              )}
            </div>
          );
        })}
      </MobileNavWrapper>
    </>
  );
};

export default MobileNavigation;
