import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Affiliate = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.78065 9.3335H2.66732C1.93398 9.3335 1.33398 9.9335 1.33398 10.6668V14.6668H5.78065V9.3335Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.88677 6.6665H7.10677C6.37344 6.6665 5.77344 7.2665 5.77344 7.99984V14.6665H10.2201V7.99984C10.2201 7.2665 9.62677 6.6665 8.88677 6.6665Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.334 11.3335H10.2207V14.6668H14.6674V12.6668C14.6674 11.9335 14.0674 11.3335 13.334 11.3335Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.34682 1.37988L8.70015 2.08655C8.74682 2.18655 8.87349 2.27988 8.98015 2.29321L9.62015 2.39988C10.0268 2.46655 10.1268 2.76653 9.83349 3.0532L9.33349 3.5532C9.24682 3.63987 9.20015 3.79987 9.22682 3.91321L9.36682 4.52655C9.48015 5.01322 9.22015 5.19989 8.79349 4.94655L8.19349 4.59322C8.08682 4.52655 7.90682 4.52655 7.80015 4.59322L7.20015 4.94655C6.77349 5.19989 6.51349 5.01322 6.62682 4.52655L6.76682 3.91321C6.79349 3.79987 6.74682 3.6332 6.66015 3.5532L6.16682 3.05987C5.87349 2.76654 5.96682 2.4732 6.38015 2.40653L7.02015 2.29989C7.12682 2.27989 7.25349 2.18655 7.30015 2.09322L7.65349 1.38653C7.84682 0.999868 8.15349 0.999882 8.34682 1.37988Z"
        stroke={color}
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Affiliate;
