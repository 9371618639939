import { useDispatch } from 'react-redux';
import Switch from 'components/Switch';
import { selectableCurrencies } from 'constants/currency';
import useModal from 'hooks/useModal';
import usePreference from 'hooks/usePreference';
import { useBalances } from 'hooks/wallet/useBalances';
import { useTranslation } from 'next-i18next';
import { toggleDisplayInFiat } from 'redux/slices/browserPreferenceSlice';
import { ModalTypes } from 'redux/slices/modalSlice';

import BalanceItem from './BalanceItem';
import { BalanceCard, Options } from './styles';

interface Props {
  closePopup: () => void;
}

const BalancePopup = ({ closePopup }: Props) => {
  const { displayInFiat, fiatPreferenceSet } = usePreference();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { openModal } = useModal();
  const currencyMapping = useBalances();

  const onToggle = (enabled: boolean) => {
    if (enabled && !fiatPreferenceSet) {
      openModal(ModalTypes.CURRENCY);
    }

    dispatch(toggleDisplayInFiat());
  };

  return (
    <BalanceCard>
      <div>
        {selectableCurrencies.map(currency => (
          <BalanceItem
            closePopup={closePopup}
            key={currency}
            currency={currency}
            value={currencyMapping[currency]}
          />
        ))}
      </div>

      <Options>
        <Switch
          dark
          label={t('lblDisplayInFiat')}
          value={displayInFiat}
          onToggle={onToggle}
          testId="toggle-fiat"
        />
      </Options>
    </BalanceCard>
  );
};

export default BalancePopup;
