import React, { useCallback, useEffect, useState } from 'react';
import BaseButton from 'components/Buttons/BaseButton';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import versionFile from '../../../public/version.json';

import { BannerBody, BannerGroup, BannerImage, BannerWrapper } from './styles';

const [currentMajor, currentMinor, currentPatch] = versionFile.version.split('.');
const POLLING_INTERVAL = 15000;

const VersionBanner = () => {
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(false);
  const router = useRouter();

  const handleRefresh = useCallback(() => {
    // refresh page
    router.reload();
  }, [router]);

  const pollAPI = useCallback(async () => {
    const response = await fetch('/version.json');
    const { version: latestVersion } = await response.json();
    const [latestMajor, latestMinor, latestPatch] = latestVersion.split('.');

    // force a refresh if majoris different
    const shouldRefresh = currentMajor !== latestMajor;
    // show banner if major version is the same but patch or minor versions are different
    const shouldShowBanner =
      !shouldRefresh && (currentPatch !== latestPatch || currentMinor !== latestMinor);

    if (shouldRefresh) {
      router.reload();
    }

    if (shouldShowBanner) {
      setShowBanner(true);
    }
  }, [router]);

  useEffect(() => {
    pollAPI();
    const interval = setInterval(pollAPI, POLLING_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [pollAPI]);

  if (!showBanner) return null;

  return (
    <BannerWrapper>
      <BannerBody>
        <BannerImage>⚙️</BannerImage>
        <p>{t('txtRefreshUpdate')}</p>
      </BannerBody>

      <BannerGroup>
        <BaseButton color="primary" onClick={handleRefresh} value={t('btnRefresh')} />
      </BannerGroup>
    </BannerWrapper>
  );
};

export default VersionBanner;
