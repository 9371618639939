import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { CRYPTO_DECIMALS } from 'constants/decimalPlaces';
import { Currency } from 'generated/graphql';

export type CurrencyMapping = Record<Currency, string>;

export interface BalancesState {
  balances: CurrencyMapping;
  balanceOverrides: Partial<CurrencyMapping>;
  pauseBalanceUpdates: boolean;
}

export const DEFAULT_BALANCES: CurrencyMapping = {
  [Currency.BTC]: '0',
  [Currency.ETH]: '0',
  [Currency.USDT]: '0',
  [Currency.USDC]: '0',
  [Currency.TRX]: '0',
  [Currency.MATIC]: '0',
  [Currency.LTC]: '0',
  [Currency.XRP]: '0',
  [Currency.SOL]: '0',
  [Currency.DOGE]: '0',
  [Currency.BUSD]: '0',
  [Currency.BNB]: '0',
  [Currency.SHIB]: '0',
};

const initialState: BalancesState = {
  balances: DEFAULT_BALANCES,
  // will always be crypto values, not fiat
  balanceOverrides: {},
  pauseBalanceUpdates: false,
};

export const balancesSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    clearOverrideBalance: (state: BalancesState) => {
      state.balanceOverrides = {};
    },

    // used to change the balance when the user bets and when the bet result is known in frontend
    // for e.g. when the ball reaches the end of plinko board
    changeOverrideBalance: (
      state: BalancesState,
      action: PayloadAction<{ currency: Currency; deltaAmount: string }>,
    ) => {
      const { currency, deltaAmount } = action.payload;

      const newAmount = BigNumber(state.balanceOverrides[currency] || '0')
        .plus(deltaAmount)
        .toFixed(CRYPTO_DECIMALS);

      state.balanceOverrides[currency] = newAmount;
    },

    updateBalance(
      state: BalancesState,
      action: PayloadAction<{
        currency: Currency;
        amount: string;
        overrideDelta?: string;
        setPauseBalanceUpdates?: boolean;
      }>,
    ) {
      const { currency, amount, overrideDelta, setPauseBalanceUpdates } = action.payload;
      if (setPauseBalanceUpdates === true) {
        state.pauseBalanceUpdates = true;
      } else if (setPauseBalanceUpdates === false) {
        state.pauseBalanceUpdates = false;
      }

      if (!state.pauseBalanceUpdates) {
        state.balances[currency] = amount;

        // not using if (overrideDelta) as overrideDelta can be 0
        if (overrideDelta !== undefined) {
          const newAmount = BigNumber(state.balanceOverrides[currency] || '0')
            .plus(overrideDelta)
            .toFixed(CRYPTO_DECIMALS);

          state.balanceOverrides[currency] = newAmount;
        }
      }
    },

    pauseBalanceUpdates(state: BalancesState, action: PayloadAction<boolean>) {
      state.pauseBalanceUpdates = action.payload;
    },

    updateBalances(
      state: BalancesState,
      action: PayloadAction<{ currency: Currency; amount: string }[]>,
    ) {
      const balancesObj: Partial<CurrencyMapping> = action.payload.reduce((prev, balance) => {
        return {
          ...prev,
          [balance.currency]: balance.amount,
        };
      }, {});

      state.balances = {
        ...state.balances,
        ...balancesObj,
      };
    },
  },
});

export const {
  clearOverrideBalance,
  changeOverrideBalance,
  updateBalance,
  updateBalances,
  pauseBalanceUpdates,
} = balancesSlice.actions;

export default balancesSlice.reducer;
