import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const HeadPhone = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 26' }: Props) => {
  const width = `${scale * 24}`;
  const height = `${scale * 26}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2816_96607)">
        <path
          d="M4.5 18H3.75C2.95435 18 2.19129 17.6839 1.62868 17.1213C1.06607 16.5587 0.75 15.7956 0.75 15V12C0.75 11.2044 1.06607 10.4413 1.62868 9.87868C2.19129 9.31607 2.95435 9 3.75 9H4.5C4.69891 9 4.88968 9.07902 5.03033 9.21967C5.17098 9.36032 5.25 9.55109 5.25 9.75V17.25C5.25 17.4489 5.17098 17.6397 5.03033 17.7803C4.88968 17.921 4.69891 18 4.5 18Z"
          fill={color}
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.25 18H19.5C19.3011 18 19.1103 17.921 18.9697 17.7803C18.829 17.6397 18.75 17.4489 18.75 17.25V9.75C18.75 9.55109 18.829 9.36032 18.9697 9.21967C19.1103 9.07902 19.3011 9 19.5 9H20.25C21.0456 9 21.8087 9.31607 22.3713 9.87868C22.9339 10.4413 23.25 11.2044 23.25 12V15C23.25 15.7956 22.9339 16.5587 22.3713 17.1213C21.8087 17.6839 21.0456 18 20.25 18Z"
          fill={color}
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.75 9C3.75 6.81196 4.61919 4.71354 6.16637 3.16637C7.71354 1.61919 9.81196 0.75 12 0.75C14.188 0.75 16.2865 1.61919 17.8336 3.16637C19.3808 4.71354 20.25 6.81196 20.25 9"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 23H17.1429C17.9006 23 18.6273 22.5786 19.1632 21.8284C19.699 21.0783 20 20.0609 20 19V18"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 25H12C11.6022 25 11.2206 24.842 10.9393 24.5607C10.658 24.2794 10.5 23.8978 10.5 23.5C10.5 23.1022 10.658 22.7206 10.9393 22.4393C11.2206 22.158 11.6022 22 12 22H13.5C13.8978 22 14.2794 22.158 14.5607 22.4393C14.842 22.7206 15 23.1022 15 23.5C15 23.8978 14.842 24.2794 14.5607 24.5607C14.2794 24.842 13.8978 25 13.5 25Z"
          fill={color}
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2816_96607">
          <rect width="24" height="26" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HeadPhone;
