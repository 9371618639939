import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency, OriginalGame, PlinkoRiskLevel } from 'generated/graphql';

export enum ModalTypes {
  NONE,

  // Auth
  EMAIL,
  AUTHENTICATION,
  CREATE_NEW_PASSWORD,
  CHANGE_PASSWORD,
  PASSWORD,

  // Wallet
  WALLET,
  CURRENCY,
  WITHDRAWAL_SUCCESS,
  WITHDRAWAL_OTP,
  TIP_SUCCESS,
  TIP_INFO,
  TIP_OTP,

  // Other
  GENERAL,
  CREATE_CAMPAIGN,
  VAULT,

  // Users
  USER,
  USER_EDIT,
  USER_LOOKUP,

  // Bets
  BET,
  RESULTS,
  BET_LOOKUP,
  SPORT_RESULTS,
  PROVABLY_FAIR,
  SPORT_CASH_OUT,
  SPORT_CASH_OUT_SUCCESS,

  // Chat
  CHAT_RULES,
  CHAT_RAIN,
  CHAT_RAIN_INFO,

  // Promotions
  VIP_UPGRADE_BONUS_MODAL,
  DAILY_RAKEBACK_MODAL,
  MONTHLY_BONUS_MODAL,
  REDEEM_CODE_LOOKUP,
  WEEKLY_BONUS_MODAL,
  WEEKLY_RACE_CLAIM,
  WEEKLY_RACE_INFO,
  RAKEBACK_MODAL,
  PROMO_REDEEM,
  VIP_BONUS_MODAL,

  // Games
  CRASH_PUBLIC_GAME_RESULT,
  CRASH_GAME_TRENDS_MODAL,
  GAME_RESTRICTION_MODAL,
  GAME_HOTKEYS,
}

export enum AuthModalType {
  LoginAndRegister = 'LOGIN_AND_REGISTER',
  ForgotPassword = 'FORGOT_PASSWORD',
  ResetPassword = 'RESET_PASSWORD',
  OTPVerification = 'OTP_VERIFICATION',
  SessionExpired = 'SESSION_EXPIRED',
  RegisterMoreDetails = 'REGISTER_MORE_DETAILS',
}

export type GeneralModalIcon = 'success' | 'verification' | 'logout' | 'mail' | 'new';

export enum WalletModalTabType {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  TIP = 'tip',
  BUY_CRYPTTO = 'buy-cryptto',
}

export enum AuthModalTabType {
  LOGIN = 'login',
  REGISTER = 'register',
}

export enum ProvablyFairTabType {
  SEEDS = 'seeds',
  VERIFY = 'verify',
}

export interface ModalProps {
  header?: string;
  body?: string;
  icon?: GeneralModalIcon;
  link?: string;
  linkText?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

// currently only for withdrawing from vault
export interface PasswordModalProps {
  currency: Currency;
  amount: string;
}

export interface ProvablyFairProps {
  serverSeed: string;
  hashedServerSeed: string;
  nonce: string;
  clientSeed: string;
  tab: ProvablyFairTabType;
  gameSlug: string; // Currently only dice
  verifyGameType: OriginalGame; // Allows the modal to know which game to verify
  plinkoRowCount?: string; // Plinko only
  plinkoRiskLevel?: PlinkoRiskLevel; // Plinko only
  numberOfMines?: number; // Mines only
}

export interface ModalState {
  modalType: ModalTypes;
  modalProps: ModalProps;
  authModalType: AuthModalType;
  loginTokenEmail: string;
  passwordModalProps: PasswordModalProps;
  provablyFairData: ProvablyFairProps;
  resultsProps: {
    betId: string;
  };
}

export const initialProvablyFairData: ProvablyFairProps = {
  tab: ProvablyFairTabType.SEEDS,
  serverSeed: '',
  hashedServerSeed: '',
  clientSeed: '',
  gameSlug: '',
  nonce: '',
  verifyGameType: OriginalGame.DICE,
  numberOfMines: 3, // Default values
  plinkoRowCount: '8',
  plinkoRiskLevel: PlinkoRiskLevel.MEDIUM_RISK,
};

const initialState: ModalState = {
  modalType: ModalTypes.NONE,
  passwordModalProps: {
    currency: Currency.BTC,
    amount: '',
  },
  modalProps: {
    header: '',
  },
  // only used for authentication modal
  authModalType: AuthModalType.LoginAndRegister,
  loginTokenEmail: '',
  // 2FA via Email is automatically enabled
  // when both authentication methods are enabled, 2FA via Mobile app is always in priority and appears first
  provablyFairData: initialProvablyFairData,
  resultsProps: {
    betId: '',
  },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (
      state: ModalState,
      action: PayloadAction<{ modalType: ModalTypes; props?: ModalProps }>,
    ) => {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.props || initialState.modalProps;
    },
    closeModal: () => {
      return initialState;
    },
    openGeneralModal: (state: ModalState, action: PayloadAction<{ props: ModalProps }>) => {
      state.modalType = ModalTypes.GENERAL;
      state.modalProps = action.payload.props || initialState.modalProps;
    },
    openPasswordModal: (state: ModalState, action: PayloadAction<PasswordModalProps>) => {
      state.modalType = ModalTypes.PASSWORD;
      state.passwordModalProps = action.payload;
    },
    setLoginTokenEmail: (state: ModalState, action: PayloadAction<string>) => {
      state.loginTokenEmail = action.payload;
    },
    setProvablyFairData: (state: ModalState, action: PayloadAction<Partial<ProvablyFairProps>>) => {
      const currentState = state.provablyFairData;

      state.provablyFairData = {
        serverSeed: action.payload.serverSeed ?? currentState.serverSeed,
        hashedServerSeed: action.payload.hashedServerSeed ?? currentState.hashedServerSeed,
        clientSeed: action.payload.clientSeed ?? currentState.clientSeed,
        tab: action.payload.tab ?? currentState.tab,
        gameSlug: action.payload.gameSlug ?? currentState.gameSlug,
        nonce: action.payload.nonce ?? currentState.nonce,
        verifyGameType: action.payload.verifyGameType ?? currentState.verifyGameType,
        numberOfMines: action.payload.numberOfMines ?? currentState.numberOfMines,
        plinkoRiskLevel: action.payload.plinkoRiskLevel ?? currentState.plinkoRiskLevel,
        plinkoRowCount: action.payload.plinkoRowCount ?? currentState.plinkoRowCount,
      };
    },
    openResultsModal: (state: ModalState, action: PayloadAction<{ betId: string }>) => {
      state.modalType = ModalTypes.RESULTS;
      state.resultsProps = action.payload;
    },
    openSportResultsModal: (state: ModalState, action: PayloadAction<{ betId: string }>) => {
      state.modalType = ModalTypes.SPORT_RESULTS;
      state.resultsProps = action.payload;
    },
    openEmailModal: (state: ModalState) => {
      state.modalType = ModalTypes.EMAIL;
    },
  },
});

export const {
  openModal,
  closeModal,
  openGeneralModal,
  openPasswordModal,
  setLoginTokenEmail,
  setProvablyFairData,
  openResultsModal,
  openSportResultsModal,
  openEmailModal,
} = modalSlice.actions;

export default modalSlice.reducer;
