import { pxToRem } from 'assets/styles/convertors';
import { mediaQueries } from 'assets/styles/mediaQueries';
import { ComponentSize } from 'components/commonStyles';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

export const UserLevel = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${pxToRem(4)};
`;

export const Level = styled.div`
  font-weight: 500;
  font-size: ${UI.text.lg};
  line-height: 150%;
  margin-left: 0.625rem;
  text-transform: capitalize;
`;

export const NameAndBadge = styled.div<{ $isStacked: boolean; $size: Omit<ComponentSize, 'sm'> }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${pxToRem(8)};
  text-align: ${({ $isStacked }) => ($isStacked ? 'center' : 'left')};

  h2 {
    font-weight: 700;
    font-size: ${({ $size }) => ($size === 'lg' ? UI.text.lg : UI.text.xl)};
    line-height: ${({ $size }) => ($size === 'lg' ? UI.text.h3 : UI.text.h4)};

    ${({ $size }) =>
      mediaQueries(Breakpoint.MD)`
      font-size: ${$size === 'lg' ? UI.text.h3 : UI.text.h4};
    `};
  }
`;
