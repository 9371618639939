import React, { useState } from 'react';
import { clientWithoutAuth } from 'apollo/clients';
import LanguageToggle from 'components/LanguageToggle';
import { Sports, useGetSportsFixturesCounterQuery } from 'generated/graphql';
import { useIsLoggedIn } from 'hooks/auth/useIsLoggedIn';
import { useTranslation } from 'next-i18next';

import { WrappedLink } from './CasinoTab';
import { POPULAR_SPORTS, SPORTS_NAV, TOP_SPORTS } from './menu';
import { LanguageSwitcher, NavDivider, NavList, NavSubheader } from './styles';

import styles from './SportsTab.module.scss';

export const SportsTab = ({ isExpanded }: { isExpanded: boolean }) => {
  const { t } = useTranslation();
  const isLoggedIn = useIsLoggedIn();
  const [menuState, setMenuState] = useState({
    sportsExpand: false,
  });

  const { data: liveSportsFixturesCounter } = useGetSportsFixturesCounterQuery({
    client: clientWithoutAuth,
    fetchPolicy: 'cache-and-network',
    variables: {
      liveOnly: true,
    },
  });

  const liveSportsCounter = Object.values(Sports).reduce((result: number, item: Sports) => {
    const sportCounter =
      liveSportsFixturesCounter?.[item]?.nodes?.reduce((sumCount, competition) => {
        const fixturesCount = competition?.fixtures?.totalCount || 0;
        return sumCount + fixturesCount;
      }, 0) || 0;
    return result + sportCounter;
  }, 0);

  return (
    <>
      <NavList isExpanded={isExpanded}>
        {SPORTS_NAV.map(({ icon, ...item }, i) => {
          if (item.items) {
            return (
              <div key={i}>
                <div className={styles.nav}>
                  {item.isPrivate && !isLoggedIn ? null : (
                    <WrappedLink
                      path={item.href}
                      title={t(item.name)}
                      icon={icon}
                      key={item.name}
                      isExpanded={isExpanded}
                    />
                  )}
                  {isExpanded && (
                    <button
                      className={menuState.sportsExpand ? styles.expandArrow : ''}
                      onClick={() =>
                        setMenuState(prevState => ({
                          ...prevState,
                          sportsExpand: !prevState.sportsExpand,
                        }))
                      }
                    >
                      <img src="/icons/chevron.svg" alt="arrow" />
                    </button>
                  )}
                </div>

                <div
                  className={`${styles.nestedNav} ${menuState.sportsExpand ? styles.expand : ''}`}
                >
                  {item.items.map(item => {
                    return (
                      <div key={item.name}>
                        <WrappedLink
                          path={menuState.sportsExpand ? item.href : '#'}
                          title={t(item.name)}
                          icon={item.icon}
                          key={item.name}
                          isExpanded={isExpanded}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }

          return item.isPrivate && !isLoggedIn ? null : (
            <React.Fragment key={i}>
              {item.hasCounter ? (
                <WrappedLink
                  path={item.href}
                  title={t(item.name)}
                  icon={icon}
                  isExpanded={isExpanded}
                  counter={item.name === 'navBetLive' ? liveSportsCounter : 0}
                />
              ) : (
                <WrappedLink
                  path={item.href}
                  title={t(item.name)}
                  icon={icon}
                  isExpanded={isExpanded}
                />
              )}
            </React.Fragment>
          );
        })}
      </NavList>

      {isExpanded ? <NavSubheader>{t('navPopularHeader')}</NavSubheader> : <NavDivider />}

      <NavList isExpanded={isExpanded}>
        {POPULAR_SPORTS.map(({ icon, ...item }, i) =>
          item.isPrivate && !isLoggedIn ? null : (
            <WrappedLink
              path={item.href}
              title={t(item.name)}
              icon={icon}
              key={i}
              isExpanded={isExpanded}
            />
          ),
        )}
      </NavList>

      {isExpanded ? <NavSubheader>{t('navTopSportsHeader')}</NavSubheader> : <NavDivider />}

      <NavList isExpanded={isExpanded}>
        {TOP_SPORTS.map(({ icon, ...item }, i) =>
          item.isPrivate && !isLoggedIn ? null : (
            <WrappedLink
              path={item.href}
              title={t(item.name)}
              icon={icon}
              key={i}
              isExpanded={isExpanded}
            />
          ),
        )}
      </NavList>

      <LanguageSwitcher>
        <LanguageToggle placement="top" />
      </LanguageSwitcher>
    </>
  );
};

export default SportsTab;
