import colors from 'assets/styles/colors';
import { pxToRem } from 'assets/styles/convertors';
import { mediaQueries } from 'assets/styles/mediaQueries';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import {
  SIDEBAR_WIDTH_DEFAULT_PX,
  SIDEBAR_WIDTH_EXPANDED_PX,
} from 'hooks/interface/useBreakpointMagic';
import styled from 'styled-components';

import { Tab, TabList } from '../TabView/styles';

const getWidth = (isExpanded: boolean) => {
  return isExpanded ? SIDEBAR_WIDTH_EXPANDED_PX : SIDEBAR_WIDTH_DEFAULT_PX;
};

export const Logo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SidebarSmallSizePadding = styled.div`
  height: 100%;
  width: ${pxToRem(getWidth(false))};
  display: none;
  background-color: transparent;

  ${mediaQueries(Breakpoint.MD)`
    display: flex;
    flex-shrink: 0;
  `};

  ${mediaQueries(Breakpoint.LG)`
    display: none;
  `};
`;

export const SidebarOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${colors.overlay};
  display: none;
  z-index: unset;

  ${mediaQueries(Breakpoint.MD)`
    display: block;
    z-index: ${UI.z.modal};
  `};

  ${mediaQueries(Breakpoint.LG)`
    display: none;
  `};
`;

export const SidebarMobile = styled.div`
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: ${UI.z.mobileSheet};
  inset: ${UI.height.navHeader}px 0 ${UI.height.mobileNav}px;
  height: auto;
  background-color: ${colors.gray900};
  flex-direction: column;
  flex-shrink: 0;
  border-right: ${pxToRem(1)} solid ${colors.gray700};
  z-index: ${UI.z.modal + 1};

  ${mediaQueries(Breakpoint.MD)`
    display: none;
  `};
`;

export const NavSubheader = styled.p`
  font-size: ${UI.text.md};
  color: ${colors.gray400};
  margin: ${pxToRem(30)} ${pxToRem(30)} ${pxToRem(10)};
  line-height: 1.5;
`;

export const NavDivider = styled.div`
  background: ${colors.gray700};
  width: ${pxToRem(36)};
  height: ${pxToRem(1)};
  margin: ${pxToRem(5)} auto;
`;

export const NavContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

interface NavItemProps {
  isActive: boolean;
  disabled?: boolean;
}

export const NavItem = styled.li<NavItemProps>`
  padding: 0 20px 0 30px;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  color: ${({ isActive }) => (isActive ? colors.primaryViolet : colors.white)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  @media (hover: hover) {
    &:hover {
      color: ${colors.primaryViolet};
    }
  }

  &::after {
    content: '';
    height: ${pxToRem(UI.height.input)};
    width: ${pxToRem(4)};
    background-color: ${colors.primaryViolet};
    position: absolute;
    border-radius: ${pxToRem(24)};
    display: none;
    ${({ isActive }) => mediaQueries(Breakpoint.MD)`
      display: ${isActive ? 'block' : 'none'};
    `};
    transition: 0.2s all ease-in-out;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    color: ${colors.gray500};

    @media (hover: hover) {
      &:hover {
        color: ${colors.gray500};
      }
    }
  `}
`;

export const NavText = styled.span`
  width: 100%;
  margin-left: 0.625rem;
  font-size: ${UI.text.lg};
  transition: 350ms;
  line-height: unset;
`;

interface NavListProps {
  isExpanded: boolean;
}

export const NavList = styled.ul<NavListProps>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: left;

  & ${NavItem} {
    justify-content: center;
    padding: ${props => (props.isExpanded ? '' : '18px 0px')};

    svg {
      width: ${props => (props.isExpanded ? 1.2 : 1)}rem;
      height: ${props => (props.isExpanded ? 1.2 : 1)}rem;

      & path {
        stroke-width: 1px;
      }
    }

    &:after {
      left: ${({ isExpanded }) => pxToRem(getWidth(isExpanded) - 5)};
    }

    ${NavText} {
      display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
    }
  }
`;

export const NavTabList = styled.div<NavListProps>`
  padding: 0 ${pxToRem(30)};

  ${mediaQueries(Breakpoint.MD)`
    padding: 0;
  `};

  ${TabList} {
    flex-direction: ${({ isExpanded }) => (isExpanded ? 'row' : 'column')};
    margin: ${({ isExpanded }) => (isExpanded ? pxToRem(26) : pxToRem(8))} auto;
    justify-content: space-around;

    ${mediaQueries(Breakpoint.MD)`
      justify-content: center;
    `};
  }

  ${Tab} {
    ${props =>
      !props.isExpanded &&
      `
        width: ${pxToRem(28)};
        height: ${pxToRem(28)};
      `};

    span {
      margin-right: ${props => (props.isExpanded ? '0.375rem' : '0')};
    }
  }
`;

export const MobileNavWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.gray900};
  border-top: ${pxToRem(1)} solid ${colors.gray700};
  color: ${colors.white};
  height: ${UI.height.mobileNav}px;
  position: sticky;
  width: 100%;
  bottom: 0;
  z-index: ${UI.z.bottomNavigation};

  > div {
    border-right: ${pxToRem(1)} solid ${colors.gray700};
    flex: 1;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;

    & > a {
      margin: auto 0;
    }
  }

  > div:last-child {
    border-right: 0;
  }

  ${mediaQueries(Breakpoint.MD)`
    display: none
  `};
`;

export const MobileNavIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.15rem;
  height: 1.15rem;
  position: relative;

  svg {
    width: 1.15rem;
    height: 1.15rem;
  }
`;

export const MobileNavText = styled.span`
  font-weight: 700;
  font-size: ${UI.text.sm};
  margin-top: 0.2rem;
`;

export const MobileNavItemWrapper = styled.div<{ $active: boolean; $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  position: relative;

  ${MobileNavText} {
    color: ${({ $active }) => ($active ? colors.primaryViolet : colors.white)};
  }
`;

export const CloseIcon = styled.span`
  width: 2.75rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  top: 1rem;
  right: 0.25rem;
  cursor: pointer;
  stroke: ${colors.white};
`;

export const LanguageSwitcher = styled.div`
  padding: ${pxToRem(24)} ${pxToRem(20)};
  display: flex;

  ${mediaQueries(Breakpoint.MD)`
    display: none;
  `};
`;

export const NavLink = styled.a`
  width: 100%;
`;
