import * as React from 'react';

import { SvgProps } from '../index';

export const Rewards = ({ color = '#FFFFFF' }: SvgProps) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.49977 13.4697H14.5002C14.7123 13.4699 14.9218 13.3804 15.1149 13.5L15.995 5.30332C16.0079 5.18009 15.9958 5.0544 15.9601 4.93984C15.9244 4.82529 15.8664 4.72626 15.7924 4.65347C15.7183 4.58069 15.6311 4.53693 15.5401 4.52694C15.4491 4.51695 15.3579 4.54111 15.2763 4.59681L12.4222 7.56106C12.3685 7.62089 12.3064 7.66488 12.2396 7.69023C12.1729 7.71558 12.103 7.72174 12.0345 7.70832C11.9659 7.6949 11.9001 7.66219 11.8413 7.61226C11.7825 7.56234 11.7319 7.49629 11.6928 7.4183L8.87601 0.733382C8.8292 0.660097 8.77142 0.601347 8.70656 0.561089C8.6417 0.520831 8.57126 0.5 8.5 0.5C8.42874 0.5 8.3583 0.520831 8.29344 0.561089C8.22858 0.601347 8.1708 0.660097 8.12399 0.733382L5.30721 7.4183C5.2681 7.49629 5.21753 7.56234 5.15871 7.61226C5.09989 7.66219 5.03411 7.6949 4.96554 7.70832C4.89696 7.72174 4.82709 7.71558 4.76035 7.69023C4.69361 7.66488 4.63146 7.62089 4.57785 7.56106L1.72374 4.59772C1.64211 4.54202 1.55087 4.51786 1.4599 4.52785C1.36892 4.53784 1.28169 4.58159 1.20765 4.65438C1.13361 4.72716 1.07559 4.8262 1.03987 4.94075C1.00416 5.0553 0.992113 5.181 1.00504 5.30423L1.88508 13.5C2.0781 13.3801 2.28767 13.4702 2.49977 13.4697Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 13.5475H2.5C2.10218 13.5475 1.72064 13.703 1.43934 13.9799C1.15804 14.2567 1 14.6322 1 15.0238C1 15.4153 1.15804 15.7908 1.43934 16.0676C1.72064 16.3445 2.10218 16.5 2.5 16.5H14.5C14.8978 16.5 15.2794 16.3445 15.5607 16.0676C15.842 15.7908 16 15.4153 16 15.0238C16 14.6322 15.842 14.2567 15.5607 13.9799C15.2794 13.703 14.8978 13.5475 14.5 13.5475Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
