import React from 'react';

import { SvgProps } from '..';

const Information = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_4101_8092)">
        <path
          d="M7.99935 15.3334C12.0494 15.3334 15.3327 12.0502 15.3327 8.00008C15.3327 3.94999 12.0494 0.666748 7.99935 0.666748C3.94926 0.666748 0.666016 3.94999 0.666016 8.00008C0.666016 12.0502 3.94926 15.3334 7.99935 15.3334Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99927 7.3334V11.3334"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.1661 4.66676C8.1661 4.75881 8.09148 4.83342 7.99943 4.83342C7.90738 4.83342 7.83276 4.75881 7.83276 4.66676C7.83276 4.57471 7.90738 4.50009 7.99943 4.50009C8.09148 4.50009 8.1661 4.57471 8.1661 4.66676Z"
          fill={color}
          stroke={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4101_8092">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Information;
