import React from 'react';
import { Spinner } from 'assets/svgs';

import styles from './LoadingContent.module.css';

interface Props {
  minHeight?: string;
  overlay?: boolean;
}

const LoadingContent = ({ minHeight = '10rem', overlay = false }: Props) => {
  if (overlay)
    return (
      <div className={styles.loadingWrapperOverlay}>
        <Spinner scale={1.5} />
      </div>
    );

  return (
    <div
      className={styles.loaderWrapper}
      style={{
        minHeight,
      }}
    >
      <Spinner scale={1.5} />
    </div>
  );
};

const MemoizedLoadingContent = React.memo(LoadingContent);

export const loader = () => <MemoizedLoadingContent />;

export default MemoizedLoadingContent;
