import React from 'react';
import colors from 'assets/styles/colors';
import clsx from 'clsx';

import CryptoIcon from '../../../../components/CurrencyIcons/CryptoIcon';
import FormattedAmount from '../../../../components/FormattedAmount';
import { Currency } from '../../../../generated/graphql';
import { BetSlipStatus } from '../../../../redux/slices/sportsBetSlice';
import { OtherStatus, SportsBetTicketStatusType } from '../BetsGroup.type';

import { BetContent } from './betContent';
import { BetTicketAmount } from './BetTicketAmount';
import { BetTicketText } from './BetTicketText';
import { getBetViewConfig } from './getBetViewConfig';

import styles from './BetStakeAndPayout.module.scss';

type Props = {
  status?: SportsBetTicketStatusType;
  betSlipStatus?: BetSlipStatus;
  currency: Currency;
  content: BetContent;
  estimatePayoutOutput: string;
  publicView?: boolean;
};

export function BetStakeAndPayout({
  status,
  betSlipStatus,
  currency,
  content,
  estimatePayoutOutput,
  publicView = false,
}: Props) {
  const { isWon, isLost, isDeadHeat, isCanceled, isWin, isLoss } = getBetViewConfig({
    betSlipStatus,
    status,
  });

  return (
    <>
      {(isWin || isLoss) && (
        <div
          className={clsx(styles.field, {
            [styles.win]: isWin,
            [styles.loss]: isLoss,
          })}
        >
          <BetTicketText capitalize color={colors.gray300}>
            {isWin && content.yoWon()}
            {isLoss && content.noReturn()}
          </BetTicketText>
          <BetTicketAmount>
            <CryptoIcon currency={currency} />
            <FormattedAmount value={estimatePayoutOutput} currency={currency} />
          </BetTicketAmount>
        </div>
      )}

      {isWon && (
        <span className={styles.betStatus}>
          <div className={styles.field}>
            <BetTicketText capitalize color={colors.gray300}>
              {publicView ? content.payout() : content.yoWon()}
            </BetTicketText>
            <BetTicketAmount>
              <CryptoIcon currency={currency} />
              <FormattedAmount value={estimatePayoutOutput} currency={currency} />
            </BetTicketAmount>
          </div>
        </span>
      )}

      {isLost && (
        <span
          className={clsx(styles.betStatus, {
            [styles.betStatusLoss]: status === OtherStatus.LOSS,
            [styles.betStatusDeadheat]: status === OtherStatus.DEAD_HEAT,
          })}
        >
          <div className={styles.field}>
            <BetTicketText capitalize color={colors.gray300}>
              {content.noReturn()}
            </BetTicketText>
            <BetTicketAmount>
              <CryptoIcon currency={currency} />
              <FormattedAmount value={estimatePayoutOutput} currency={currency} />
            </BetTicketAmount>
          </div>
        </span>
      )}

      {isCanceled && (
        <span className={styles.betStatus}>
          <div className={styles.field}>
            <BetTicketText capitalize color={colors.gray300}>
              {publicView ? content.payout() : content.yoWon()}
            </BetTicketText>
            <BetTicketAmount>
              <CryptoIcon currency={currency} />
              <FormattedAmount value={estimatePayoutOutput} currency={currency} />
            </BetTicketAmount>
          </div>
        </span>
      )}

      {isDeadHeat && (
        <span
          className={clsx(styles.betStatus, {
            [styles.betStatusLoss]: status === OtherStatus.LOSS,
            [styles.betStatusDeadheat]: status === OtherStatus.DEAD_HEAT,
          })}
        >
          <div className={styles.field}>
            <BetTicketText noCapitalize color={colors.gray300}>
              {content.partialReturn()}
            </BetTicketText>
            <BetTicketAmount>
              <CryptoIcon currency={currency} />
              <FormattedAmount value={estimatePayoutOutput} currency={currency} />
            </BetTicketAmount>
          </div>
        </span>
      )}
    </>
  );
}
