import BigNumber from 'bignumber.js';

type Props = {
  outputNumber: BigNumber;
  shouldAppendPlusSymbol: boolean;
  fixedDecimalPlace?: number;
};

export function formatOddsNumber({
  outputNumber,
  shouldAppendPlusSymbol,
  fixedDecimalPlace = 0,
}: Props) {
  return outputNumber.isPositive() && shouldAppendPlusSymbol
    ? '+' + String(outputNumber.toFixed(fixedDecimalPlace))
    : String(outputNumber.toFixed(fixedDecimalPlace));
}
