import { pxToRem } from 'assets/styles/convertors';
import { ComponentSize } from 'components/commonStyles';
import UI from 'constants/ui';
import styled from 'styled-components';

export const VipBadgeElement = styled.div<{ $size?: string }>`
  display: flex;
  align-items: center;
  gap: ${pxToRem(6)};

  p {
    font-weight: 500;
    font-size: ${({ $size }) => ($size ? UI.text.md : UI.text[($size ?? 'md') as ComponentSize])};
  }
`;

export const VipCurrentProgressLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: ${pxToRem(24)};
  font-size: ${UI.text.lg};
  font-weight: 700;
`;

export const VipIconContainer = styled.span<{ $size: number }>`
  position: relative;
  display: flex;
  width: ${({ $size }) => pxToRem($size)};
  height: ${({ $size }) => pxToRem($size)};
`;

export const VipLabel = styled.span<{ $size?: string }>`
  font-weight: 500;
  font-size: ${({ $size }) => ($size ? UI.text.md : UI.text[($size ?? 'md') as ComponentSize])};
`;

export const Img = styled.img<{ $size: number }>`
  width: ${({ $size }) => pxToRem($size)};
  height: ${({ $size }) => pxToRem($size)};
`;
