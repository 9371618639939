import React from 'react';

import colors from '../styles/colors';

import { SvgProps } from '.';

const ShuffleSmall = ({
  scale = 1,
  color = colors.primaryViolet,
  viewBox = '0 0 25 28',
}: SvgProps) => {
  const width = `${scale * 25}`;
  const height = `${scale * 28}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      style={{ cursor: 'pointer' }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9636 0.0983887H6.70218C5.13267 0.0983887 3.65523 0.714499 2.54614 1.8276C1.43704 2.94276 0.825195 4.42348 0.825195 5.99867V7.80593C0.825195 10.2129 2.33332 12.322 4.86051 13.4495L5.86934 13.8643V7.89424C5.86524 7.86343 5.8632 7.83468 5.8632 7.80387V5.99662C5.8632 5.69062 6.01872 5.49141 6.1108 5.39899C6.20288 5.30657 6.40137 5.15049 6.70423 5.15049H18.9657C19.2706 5.15049 19.4691 5.30452 19.5612 5.39899C19.6532 5.49141 19.8067 5.69062 19.8067 5.99456V9.96026H24.8427V5.99662C24.8427 4.42143 24.2308 2.93866 23.1217 1.82555C22.0085 0.712445 20.5332 0.0983887 18.9636 0.0983887Z"
        fill={color}
      />
      <path
        d="M20.7869 14.5402L19.7985 14.1335V20.1447C19.8026 20.1591 19.8026 20.1755 19.8026 20.1899V21.9972C19.8026 22.3032 19.6491 22.5044 19.555 22.5968C19.4629 22.6893 19.2644 22.8453 18.9616 22.8453H6.70218C6.23972 22.8453 5.8632 22.4654 5.8632 22.0033V18.0376H0.825195V22.0033C0.825195 25.2564 3.46083 27.9016 6.70218 27.9016H18.9636C20.5332 27.8995 22.0106 27.2854 23.1197 26.1723C24.2288 25.0572 24.8406 23.5744 24.8406 21.9992V20.192C24.8406 17.7624 23.3264 15.6492 20.7869 14.5422V14.5402Z"
        fill={color}
      />
      <path
        d="M10.6679 16.1725C12.4277 17.9387 12.8329 20.1751 12.8329 20.1751C12.8329 20.1751 13.0968 18.7355 14.1098 17.2548C14.3594 16.8892 14.652 16.5216 14.9999 16.1725C16.7597 14.4063 18.9881 13.9976 18.9881 13.9976C18.9881 13.9976 17.3061 13.6895 15.7079 12.4491C15.4685 12.2622 15.2291 12.0569 14.9979 11.8248C13.238 10.0586 12.8308 7.82007 12.8308 7.82007C12.8308 7.82007 12.5709 9.25971 11.558 10.7445C11.3084 11.1101 11.0137 11.4777 10.6658 11.8268C8.90396 13.593 6.67554 13.9997 6.67554 13.9997C6.67554 13.9997 8.35965 14.3077 9.95781 15.5481C10.1972 15.7371 10.4366 15.9425 10.6679 16.1745V16.1725Z"
        fill={color}
      />
    </svg>
  );
};

export default ShuffleSmall;
