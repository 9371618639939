import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { i18n } from 'next-i18next';

export enum AlertsEnum {
  Info,
  Success,
  Warning,
  Error,
}

export interface AlertType {
  id: string;
  message: string;
  duration: number; // default to 5s but can be customised
  type: AlertsEnum;
}

export interface AlertsState {
  alerts: AlertType[];
}

const initialState: AlertsState = {
  alerts: [],
};

export interface AddAlertType {
  // without id as that's added automatically
  message: string;
  duration?: number;
  type: AlertsEnum;
}

export interface RemoveAlertType {
  id: string;
}

const t = (name: string) => i18n?.t?.(name) ?? '';

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    // adds alerts to the end of the alerts array so new alerts appear below older alerts
    // when the timeout expires, the alert is removed from the front of the array
    addAlert: (state: AlertsState, action: PayloadAction<AddAlertType>) => {
      const AutoBetEndMessage = t('autoBetEnded');
      const AutoBetStartMessage = t('autoBetStarted');
      const hasIncludeAutoBetMessage = [AutoBetEndMessage, AutoBetStartMessage].includes(
        action.payload.message,
      );

      if (action.payload.message) {
        state.alerts = [
          ...state.alerts.filter(alert => {
            if (hasIncludeAutoBetMessage) {
              return ![AutoBetEndMessage, AutoBetStartMessage].includes(alert.message);
            }

            return alert.message !== action.payload.message;
          }),
          {
            ...action.payload,
            duration: action.payload.duration || 5000,
            id: Math.random().toString(),
          },
        ];
      }
    },
    // allow the user to remove an alert by clicking close
    removeAlert: (state: AlertsState, action: PayloadAction<RemoveAlertType>) => {
      state.alerts = state.alerts.filter(alert => alert.id !== action.payload.id);
    },
  },
});

export const { addAlert, removeAlert } = alertsSlice.actions;

export default alertsSlice.reducer;
