import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VipLevel } from 'generated/graphql';

export enum VipRewardType {
  DAILY_RAKEBACK = 'DAILY_RAKEBACK',
  WEEKLY_BONUS = 'WEEKLY_BONUS',
  MONTHLY_BONUS = 'MONTHLY_BONUS',
  CHALLENGE_PRIZE = 'CHALLENGE_PRIZE',
}

export enum RewardStatus {
  UNAVAILABLE,
  NONE,
  CLAIMED,
  EXPIRED,
}

export interface VipState {
  rewardStatus: Record<VipRewardType, RewardStatus>;
  vipUpgradeRewardStatus: Partial<Record<VipLevel, RewardStatus>>;
  raceRewardStatus: Record<string, RewardStatus>;
  vipBonusStatus: Record<string, RewardStatus>;
  vipBonusIndex: Record<string, number>;
  challengeRewardStatus: Record<string, RewardStatus>;
}

const initialState: VipState = {
  rewardStatus: {
    DAILY_RAKEBACK: RewardStatus.NONE,
    WEEKLY_BONUS: RewardStatus.NONE,
    MONTHLY_BONUS: RewardStatus.NONE,
    CHALLENGE_PRIZE: RewardStatus.NONE,
  },
  vipUpgradeRewardStatus: {},
  raceRewardStatus: {},
  vipBonusStatus: {},
  vipBonusIndex: {},
  challengeRewardStatus: {},
};

const vipSlice = createSlice({
  name: 'vip',
  initialState,
  reducers: {
    setVipRewardStatus: (
      state: VipState,
      action: PayloadAction<{ type: VipRewardType; status: RewardStatus }>,
    ) => {
      state.rewardStatus[action.payload.type] = action.payload.status;
    },

    setVipUpgradeRewardStatus: (
      state: VipState,
      action: PayloadAction<{ type: VipLevel; status: RewardStatus }>,
    ) => {
      state.vipUpgradeRewardStatus[action.payload.type] = action.payload.status;
    },

    setRaceRewardStatus: (
      state: VipState,
      action: PayloadAction<{ raceId: string; status: RewardStatus }>,
    ) => {
      state.raceRewardStatus[action.payload.raceId] = action.payload.status;
    },

    setVipBonusIssuanceStatus: (
      state: VipState,
      action: PayloadAction<{ issuanceId: string; status: RewardStatus }>,
    ) => {
      state.vipBonusStatus[action.payload.issuanceId] = action.payload.status;
    },

    setVipBonusIndex: (
      state: VipState,
      action: PayloadAction<{ bonusId: string; index: number }>,
    ) => {
      state.vipBonusIndex[action.payload.bonusId] = action.payload.index;
    },

    setChallengeRewardStatus: (
      state: VipState,
      action: PayloadAction<{ challengeId: string; status: RewardStatus }>,
    ) => {
      state.challengeRewardStatus[action.payload.challengeId] = action.payload.status;
    },
  },
});

export const {
  setVipRewardStatus,
  setVipUpgradeRewardStatus,
  setRaceRewardStatus,
  setVipBonusIssuanceStatus,
  setVipBonusIndex,
  setChallengeRewardStatus,
} = vipSlice.actions;

export default vipSlice.reducer;
