import React, { memo } from 'react';
import { useCachedImage } from 'hooks/useCachedImage';

import { Img, VipIconContainer } from './styles';

interface VipIconProps {
  icon: string;
  size?: number;
}

/**
 * Raw VipIcon, without any labels or further bells and whistles.
 *
 * Intrinsic size should be 2x the rendered size to ensure maximal quality, given
 * browser's sub-pixel FFT interpolation.
 */
const VipIcon = ({ icon, size = 16 }: VipIconProps) => {
  const iconBlob = useCachedImage(icon);

  return (
    <VipIconContainer $size={size}>
      {iconBlob && <Img $size={size} src={iconBlob} alt="vip icon" />}
    </VipIconContainer>
  );
};

export default memo(VipIcon);
