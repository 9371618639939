import React from 'react';

import { SvgProps } from '..';

const LiveCasino = ({ scale = 1, color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 17}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50008 15.3333C12.5502 15.3333 15.8334 12.0501 15.8334 8.00001C15.8334 3.94992 12.5502 0.666672 8.50008 0.666672C4.44999 0.666672 1.16675 3.94992 1.16675 8.00001C1.16675 12.0501 4.44999 15.3333 8.50008 15.3333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16675 10.6667V5.33334L11.1667 8.00001L7.16675 10.6667Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LiveCasino;
