import * as React from 'react';
import { FloatingPortal, Strategy, useMergeRefs } from '@floating-ui/react';
import { MODAL_ID } from 'components/modals/ModalWrapper';
import useIsDescendant from 'hooks/interface/useIsDescendant';
import { TooltipOptions, useTooltip } from 'hooks/interface/useTooltip';

import UI, { MOBILE_SHEET_ID } from '../../constants/ui';

import {
  TooltipArrowElement,
  TooltipChildren,
  TooltipContentLineWrap,
  TooltipElement,
} from './styles';

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

interface TooltipProps extends TooltipOptions {
  children: React.ReactNode;
}

const Tooltip = ({ children, ...options }: TooltipProps) => {
  const tooltip = useTooltip(options);

  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
};

const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(({ children, asChild = false, ...props }, propRef) => {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref; // eslint-disable-line @typescript-eslint/no-explicit-any
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  /**
   * @note - Setting `asChild` allows us to pass any element as the anchor
   */
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      }),
    );
  }

  return (
    <div
      ref={ref}
      /** Optionally style the trigger based on the state */
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </div>
  );
});

interface TooltipContentProps extends React.HTMLProps<HTMLDivElement> {
  /** Wrap long text into a manageable length */
  wrapText?: boolean;
}

const TooltipContent = React.forwardRef<HTMLDivElement, TooltipContentProps>((props, propRef) => {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  // Are we inside a modal or another floating element?
  const isInsideFloating = useIsDescendant(
    `#${MODAL_ID}, #${MOBILE_SHEET_ID}, .react-draggable`,
    context.refs.reference as React.RefObject<HTMLDivElement>,
  );

  const commonStyles: React.CSSProperties = {
    visibility: context.x === null ? 'hidden' : 'visible',
  };

  const floatingProps = context.getFloatingProps(props);
  const children = React.Children.toArray(floatingProps.children as React.ReactNode[]);

  const zIndexMap: Record<Strategy, number> = {
    absolute: isInsideFloating ? UI.z.modal + 1 : UI.z.tooltip,
    fixed: UI.z.modal + 1,
  };

  const style = {
    position: context.strategy,
    zIndex: zIndexMap[context.strategy],
    left: context.x ?? 0,
    top: context.y ?? 0,
    ...commonStyles,
    ...props.style,
  };

  return (
    <FloatingPortal>
      {context.open && (
        <TooltipElement ref={ref} variant={context.variant} style={style} {...floatingProps}>
          <TooltipChildren>
            {props.wrapText ? (
              <TooltipContentLineWrap>{children}</TooltipContentLineWrap>
            ) : (
              children
            )}

            <TooltipArrowElement
              ref={context.arrowRef}
              style={commonStyles}
              variant={context.variant}
              placement={context.placement}
              {...context.middlewareData.arrow}
            />
          </TooltipChildren>
        </TooltipElement>
      )}
    </FloatingPortal>
  );
});

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;

export default Tooltip;
