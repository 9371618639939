import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs, { extend } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import { usePendingVipRewardsQuery } from 'generated/graphql';
import range from 'lodash/range';
import {
  RewardStatus,
  setChallengeRewardStatus,
  setRaceRewardStatus,
  setVipBonusIndex,
  setVipBonusIssuanceStatus,
  setVipRewardStatus,
  setVipUpgradeRewardStatus,
  VipRewardType,
} from 'redux/slices/vipSlice';
import { DatesCalculator } from 'shufflecom-calculations/lib/utils/dates-calculator';
import { deriveBonusIssuances } from 'shufflecom-calculations/lib/utils/derive-vip-bonus';
import {
  VipBonusIssuanceStatus,
  VipBonusPartial,
} from 'shufflecom-calculations/lib/utils/vip-bonus.type';

extend(utc);
extend(isSameOrAfter);

const FETCH_INTERVAL = 1000;

export const getCurrentIssuanceIndex = (vipBonus: VipBonusPartial) => {
  const now = dayjs();
  if (now.isSame(vipBonus.endAt) || now.isAfter(vipBonus.endAt)) return null;

  const diff = now.diff(vipBonus.createdAt, 'second');
  return vipBonus.cadence ? Math.floor(diff / vipBonus.cadence) + 1 : 1;
};

export const useVipRewards = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { data } = usePendingVipRewardsQuery({
    skip: !isLoggedIn,
    variables: { skipRaceReward: false },
  });
  const dispatch = useDispatch();
  const {
    rewardStatus,
    vipUpgradeRewardStatus,
    raceRewardStatus,
    vipBonusStatus,
    challengeRewardStatus,
  } = useSelector((state: AppState) => ({
    rewardStatus: state.vip.rewardStatus,
    vipUpgradeRewardStatus: state.vip.vipUpgradeRewardStatus,
    raceRewardStatus: state.vip.raceRewardStatus,
    vipBonusStatus: state.vip.vipBonusStatus,
    challengeRewardStatus: state.vip.challengeRewardStatus,
  }));

  const processVipReward = useCallback(() => {
    const currentDate = dayjs.utc();
    const vipUpgradeBonuses = data?.vipUpgradeBonuses ?? [];
    const weeklyRaceBonuses = data?.raceUserReward ?? [];
    const vipBonuses = data?.vipBonus ?? [];
    const challengeRewards =
      data?.challengeRewards
        ?.filter(item => !item.claimedAt)
        .map(item => {
          return { ...item, ...item?.challenge };
        }) ?? [];
    const startOfMonth = DatesCalculator.startOfMonthlyBonus(dayjs.utc(currentDate));
    const monthlyBonusExpiryDate = dayjs.utc(startOfMonth).add(28, 'days').subtract(1, 'minutes');

    const isDailyExpired = currentDate.hour() === 23 && currentDate.minute() === 59;
    const isWeeklyExpired =
      currentDate.day() === 4 && currentDate.hour() === 10 && currentDate.minute() === 59;
    const isMonthlyExpired = currentDate.isAfter(monthlyBonusExpiryDate);
    if (isDailyExpired && rewardStatus[VipRewardType.DAILY_RAKEBACK] !== RewardStatus.EXPIRED) {
      dispatch(
        setVipRewardStatus({ type: VipRewardType.DAILY_RAKEBACK, status: RewardStatus.EXPIRED }),
      );
    }

    if (isWeeklyExpired && rewardStatus[VipRewardType.WEEKLY_BONUS] !== RewardStatus.EXPIRED) {
      dispatch(
        setVipRewardStatus({ type: VipRewardType.WEEKLY_BONUS, status: RewardStatus.EXPIRED }),
      );
    }

    if (isMonthlyExpired && rewardStatus[VipRewardType.MONTHLY_BONUS] !== RewardStatus.EXPIRED) {
      dispatch(
        setVipRewardStatus({ type: VipRewardType.MONTHLY_BONUS, status: RewardStatus.EXPIRED }),
      );
    }

    vipUpgradeBonuses.forEach(bonus => {
      const bonusExpiryDate = dayjs.utc(bonus.createdAt).add(7, 'days').subtract(1, 'minute');

      if (
        bonusExpiryDate.isBefore(currentDate) &&
        vipUpgradeRewardStatus[bonus.vipLevel] !== RewardStatus.EXPIRED
      ) {
        dispatch(setVipUpgradeRewardStatus({ type: bonus.vipLevel, status: RewardStatus.EXPIRED }));
      }
    });

    weeklyRaceBonuses.forEach(bonus => {
      if (
        dayjs.utc(bonus.rewardEndDate).isBefore(currentDate) &&
        raceRewardStatus[bonus.raceId] !== RewardStatus.EXPIRED
      ) {
        dispatch(setRaceRewardStatus({ raceId: bonus.raceId, status: RewardStatus.EXPIRED }));
      }
    });

    challengeRewards.forEach(bonus => {
      if (
        dayjs.utc(bonus.expiredAt).isBefore(currentDate) &&
        challengeRewardStatus[bonus.id] !== RewardStatus.EXPIRED
      ) {
        dispatch(setChallengeRewardStatus({ challengeId: bonus.id, status: RewardStatus.EXPIRED }));
      }
    });

    vipBonuses?.forEach(vipBonus => {
      const derivedBonusIssuances = deriveBonusIssuances(vipBonus);
      const maxIndex = derivedBonusIssuances.length + 1;
      const currentIndex = getCurrentIssuanceIndex(vipBonus);

      // means the whole reload is expired
      if (currentIndex === null) {
        dispatch(
          setVipBonusIssuanceStatus({
            issuanceId: `${vipBonus.id}-${maxIndex}`,
            status: RewardStatus.EXPIRED,
          }),
        );
        return;
      } else {
        dispatch(
          setVipBonusIndex({
            bonusId: vipBonus.id,
            index: currentIndex,
          }),
        );
      }

      range(1, maxIndex).forEach(index => {
        const bonusIssuanceId = `${vipBonus.id}-${index}`;
        let bonusIssuanceStatus;
        const issuance = derivedBonusIssuances[index - 1];

        if (
          issuance.status === VipBonusIssuanceStatus.CLAIMED ||
          vipBonusStatus[bonusIssuanceId] === RewardStatus.CLAIMED
        ) {
          bonusIssuanceStatus = RewardStatus.CLAIMED;
        } else if (
          issuance.status === VipBonusIssuanceStatus.EXPIRED ||
          vipBonusStatus[bonusIssuanceId] === RewardStatus.EXPIRED ||
          currentDate.isSameOrAfter(issuance.expiryDate)
        ) {
          bonusIssuanceStatus = RewardStatus.EXPIRED;
        } else if (
          (issuance.status === VipBonusIssuanceStatus.PENDING_CLAIM ||
            issuance.status === VipBonusIssuanceStatus.SCHEDULED) &&
          currentDate.isSameOrAfter(issuance.availableDate) &&
          currentDate.isBefore(issuance.expiryDate)
        ) {
          bonusIssuanceStatus = RewardStatus.NONE;
        } else {
          bonusIssuanceStatus = RewardStatus.UNAVAILABLE;
        }
        dispatch(
          setVipBonusIssuanceStatus({
            issuanceId: bonusIssuanceId,
            status: bonusIssuanceStatus,
          }),
        );
      });
    });
  }, [
    data?.vipUpgradeBonuses,
    data?.raceUserReward,
    data?.vipBonus,
    data?.challengeRewards,
    rewardStatus,
    dispatch,
    vipUpgradeRewardStatus,
    raceRewardStatus,
    challengeRewardStatus,
    vipBonusStatus,
  ]);

  // TODO: Refactor this
  useEffect(() => {
    processVipReward();
    const interval = setInterval(() => {
      processVipReward();
    }, FETCH_INTERVAL);

    return () => clearInterval(interval);
  }, [processVipReward]);
};
