import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomCompareEffect } from 'react-use';
import BigNumber from 'bignumber.js';
import { CRYPTO_DECIMALS } from 'constants/decimalPlaces';
import { Currency, useBalanceUpdatedSubscription } from 'generated/subscription';
import { useMyProfile } from 'hooks/useMyProfile';
import isEqual from 'lodash/isEqual';
import {
  changeOverrideBalance,
  clearOverrideBalance,
  CurrencyMapping,
  DEFAULT_BALANCES,
  updateBalance,
  updateBalances,
} from 'redux/slices/balancesSlice';

export type BalanceOrigin = 'vault' | 'wallet';

export const useVaultBalances = () => {
  const { myProfile } = useMyProfile();

  const _balances = { ...DEFAULT_BALANCES };

  myProfile?.account.vaultBalances.forEach(
    balance => (_balances[balance.currency] = balance.amount),
  );

  return _balances;
};

// Always returns in Crypto form
export const useBalances = (): CurrencyMapping => {
  const { balanceOverrides, balances } = useSelector((state: AppState) => state.balances);

  const balancesShown: CurrencyMapping = { ...balances };
  const overridenCurrencies = Object.keys(balanceOverrides) as Currency[];

  for (const currency of overridenCurrencies) {
    balancesShown[currency] = BigNumber(balancesShown[currency])
      .plus(balanceOverrides[currency] ?? 0)
      .toFixed(CRYPTO_DECIMALS);
  }

  return balancesShown;
};

export const useBalance = (currency: Currency, origin: BalanceOrigin = 'wallet'): string => {
  const vaultBalance = useVaultBalances()[currency];
  const walletBalance = useBalances()[currency];
  if (origin === 'vault') {
    return vaultBalance;
  }
  return walletBalance;
};

// Updates the user's balance whenever it changes by using data published by the server
export const useGlobalUpdateBalances = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const dispatch = useDispatch();

  const { myProfile } = useMyProfile();

  const balances = myProfile?.account?.balances;

  useCustomCompareEffect(
    () => {
      if (balances) {
        dispatch(updateBalances(balances));
      }
    },
    [balances, dispatch],
    (prev, next) => isEqual(prev[0], next[0]),
  );

  useBalanceUpdatedSubscription({
    skip: !isLoggedIn,
    onData: ({ data }) => {
      const newBalance = data.data?.balanceUpdated;

      if (newBalance) {
        dispatch(updateBalance(newBalance));
      }
    },
  });
};

export const useOverrideBalances = () => {
  const dispatch = useDispatch();

  const stopOverrideBalances = useCallback(() => {
    dispatch(clearOverrideBalance());
  }, [dispatch]);

  const updateOverrideBalance = useCallback(
    (deltaAmount: BigNumber, currency: Currency) => {
      dispatch(
        changeOverrideBalance({ deltaAmount: deltaAmount.toFixed(CRYPTO_DECIMALS), currency }),
      );
    },
    [dispatch],
  );

  return { stopOverrideBalances, updateOverrideBalance };
};
