import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlinkoRiskLevel } from 'generated/graphql';

export interface PlinkoState {
  riskLevel: PlinkoRiskLevel;
  numberOfRows: number;
}

// Separate to PlinkoPlay because
// Persistent storage for games
const initialState: PlinkoState = {
  numberOfRows: 8,
  riskLevel: PlinkoRiskLevel.MEDIUM_RISK,
};

export const plinkoSlice = createSlice({
  name: 'plinko',
  initialState,
  reducers: {
    setPlinkoRiskLevel: (state, action: PayloadAction<PlinkoRiskLevel>) => {
      state.riskLevel = action.payload;
    },
    setPlinkoNumberOfRows: (state, action: PayloadAction<number>) => {
      state.numberOfRows = action.payload;
    },
    updatePlinkoStore: (_, action: PayloadAction<PlinkoState>) => action.payload,
  },
});

export const { setPlinkoNumberOfRows, setPlinkoRiskLevel, updatePlinkoStore } = plinkoSlice.actions;

export default plinkoSlice.reducer;
