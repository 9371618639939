import React from 'react';

import { SvgProps } from '../..';

const Tennis = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_2887_78096)">
        <path
          d="M3.75 19.6305C5.67886 17.5578 6.7508 14.8313 6.75 12C6.7508 9.16867 5.67886 6.44218 3.75 4.36951"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.25 19.6305C18.3217 17.5575 17.2498 14.8312 17.2498 12C17.2498 9.16878 18.3217 6.44255 20.25 4.36951"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2887_78096">
          <rect width="24px" height="24px" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Tennis;
