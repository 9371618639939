import dayjs from 'dayjs';

import {
  SportsFixture,
  SportsFixtureWithDefaultMarketsBodyFragment,
  SportsMatchPhase,
  SportsMatchState,
} from '../generated/graphql';

export const inPlayMatchPhaseList = [
  SportsMatchPhase.FIRSTQUARTER,
  SportsMatchPhase.SECONDQUARTER,
  SportsMatchPhase.THIRDQUARTER,
  SportsMatchPhase.FOURTHQUARTER,
  SportsMatchPhase.FIRSTHALF,
  SportsMatchPhase.SECONDHALF,
  SportsMatchPhase.HALFTIME,
  SportsMatchPhase.OVERTIME,
  SportsMatchPhase.BEFOREOVERTIME,
  SportsMatchPhase.BEFORESECONDQUARTER,
  SportsMatchPhase.BEFOREFOURTHQUARTER,
  SportsMatchPhase.FULLTIMENORMALTIME,
  SportsMatchPhase.FULLTIMEEXTRATIME,
  SportsMatchPhase.EXTRATIMEFIRSTHALF,
  SportsMatchPhase.EXTRATIMEHALFTIME,
  SportsMatchPhase.EXTRATIMESECONDHALF,
  SportsMatchPhase.PENALTIES,
  SportsMatchPhase.INPROGRESS,
  SportsMatchPhase.INPLAY,
  SportsMatchPhase.FIRSTPERIOD,
  SportsMatchPhase.ENDOFFIRSTPERIOD,
  SportsMatchPhase.SECONDPERIOD,
  SportsMatchPhase.ENDOFSECONDPERIOD,
  SportsMatchPhase.THIRDPERIOD,
  SportsMatchPhase.ENDOFTHIRDPERIOD,
  SportsMatchPhase.OVERTIMEPERIOD,
  SportsMatchPhase.ENDOFOVERTIMEPERIOD,
  SportsMatchPhase.PENALTYSHOOTOUT,
];

export const closedMatchPhaseList = [
  SportsMatchPhase.POSTGAME,
  SportsMatchPhase.POSTMATCH,
  SportsMatchPhase.GAMEABANDONED,
  SportsMatchPhase.MATCHABANDONED,
  SportsMatchPhase.CANCELLED,
  SportsMatchPhase.POSTPONED,
  SportsMatchPhase.ABANDONED,
  SportsMatchPhase.BREAKINPLAY,
  SportsMatchPhase.COMPLETED,
];

const preMatchMatchPhaseList = [SportsMatchPhase.PREGAME, SportsMatchPhase.PREMATCH];

export const checkEventStateBeforeLive = (startTime: string) => {
  const minutesDifference = dayjs(startTime).diff(dayjs(), 'minute');
  const TEN_MINUTES = 10;

  const shouldShowDelayedStart = minutesDifference < 0 && Math.abs(minutesDifference) > TEN_MINUTES;
  const shouldShowAboutToStart =
    minutesDifference < 0 && Math.abs(minutesDifference) <= TEN_MINUTES;
  return {
    shouldShowDelayedStart,
    shouldShowAboutToStart,
  };
};

export const isSportEventSuspended = (params: { matchPhase: SportsMatchPhase }) => {
  const { matchPhase } = params;
  if (matchPhase) {
    return matchPhase === SportsMatchPhase.SUSPENDED;
  }
  return false;
};

export const isSportEventClosed = (parmas: { matchPhase: SportsMatchPhase }) => {
  const { matchPhase } = parmas;
  if (matchPhase) {
    return closedMatchPhaseList.includes(matchPhase);
  }
  return false;
};

export const isSportEventPreMatch = (params: {
  startTime: string;
  matchStatistics?: SportsMatchState;
}): boolean => {
  const { startTime, matchStatistics } = params;
  const matchPhase = matchStatistics?.matchSummary?.matchPhase || null;
  return matchPhase
    ? preMatchMatchPhaseList.includes(matchPhase)
    : dayjs(startTime).isAfter(dayjs());
};

export const isSportEventInPlay = (params: {
  startTime: string;
  fixture?: SportsFixtureWithDefaultMarketsBodyFragment | SportsFixture;
  matchStatistics?: SportsMatchState;
}) => {
  const { startTime, matchStatistics, fixture } = params;

  if (!matchStatistics) {
    return false;
  }

  const matchPhase = matchStatistics?.matchSummary?.matchPhase || null;
  const currentPhaseName = matchStatistics?.matchSummary?.currentPhaseName || null;
  const hasInPlayMarket = fixture && fixture?.markets?.some(market => market?.inPlay);

  if (matchPhase) {
    return inPlayMatchPhaseList.includes(matchPhase);
  }

  if (!matchPhase && !currentPhaseName && fixture && hasInPlayMarket) {
    return dayjs(startTime).isBefore(dayjs()) && hasInPlayMarket;
  }

  if (fixture && !hasInPlayMarket) {
    return false;
  }

  return false;
};
