import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RightSidebarVariant, setRightSidebar } from 'redux/slices/browserPreferenceSlice';
import { MobileMenuName, setActiveMobileNav } from 'redux/slices/globalStateSlice';

export const useMobileNav = () => {
  const dispatch = useDispatch();
  const rightSidebarVariant = useSelector(
    (state: AppState) => state.browserPreference.rightSidebarVariant,
  );

  const toggleChatBoxHandler = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        dispatch(setActiveMobileNav(MobileMenuName.CHAT));
        dispatch(setRightSidebar({ variant: RightSidebarVariant.CHAT }));
      } else {
        dispatch(setRightSidebar({ variant: RightSidebarVariant.NONE }));
      }
    },
    [dispatch],
  );

  const setActiveMobileNavHandler = useCallback(
    (newMenu: MobileMenuName) => {
      // Toggle chat
      if (newMenu === MobileMenuName.CHAT) {
        dispatch(setRightSidebar({ variant: RightSidebarVariant.CHAT }));
      } else if (rightSidebarVariant === RightSidebarVariant.CHAT) {
        dispatch(setRightSidebar({ variant: RightSidebarVariant.NONE }));
      }

      dispatch(setActiveMobileNav(newMenu));
    },
    [dispatch, rightSidebarVariant],
  );

  return { toggleChatBox: toggleChatBoxHandler, setActiveMobileNav: setActiveMobileNavHandler };
};
