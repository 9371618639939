import React from 'react';
import { isEnableSports } from 'components/Navigation/menu';

import LanguageToggle from '../LanguageToggle';

import { OddsFormatPreference } from './OddsFormatPreference';

import styles from './LanguageAndOddSelectors.module.scss';

export const LanguageAndOddSelectors = ({
  display,
}: {
  display: 'mobileOnly' | 'mobileAboveOnly';
}) =>
  display === 'mobileOnly' ? (
    <div className={styles.visibleMobileOnly}>
      <LanguageToggle enableAutoPlacement />
      {isEnableSports && <OddsFormatPreference />}
    </div>
  ) : (
    <div className={styles.visibleOnlyMobileAbove}>
      <LanguageToggle enableAutoPlacement />
      {isEnableSports && <OddsFormatPreference />}
    </div>
  );
