export const iconFetcher = (() => {
  const cachedData: Record<string, Promise<string>> = {};

  const fetchIcon = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  };

  return async (url: string): Promise<string> => {
    if (!cachedData[url]) {
      cachedData[url] = fetchIcon(url);
    }

    return cachedData[url];
  };
})();
