import { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import {
  ALL_GAMES_OPTION_VALUE,
  resetGameStatistics,
  setSelectedGame,
} from 'redux/slices/gameStatisticSlice';

const useGameStatisticsWidget = () => {
  const { t } = useTranslation();
  const { isWidgetOpen, widgetPosition, selectedGame, gameStatisticsMap } = useSelector(
    (state: AppState) => state.gameStatistics,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const defaultOption = useMemo(() => ({ label: t('navAll'), value: ALL_GAMES_OPTION_VALUE }), [t]);

  const selectOptions = useMemo(() => {
    const namedOptions = Object.entries(gameStatisticsMap)
      .map(([slug, item]) => ({
        label: item.name,
        value: slug,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return [defaultOption, ...namedOptions];
  }, [defaultOption, gameStatisticsMap]);

  const setSelectedOption = useCallback(
    (option: string) => {
      dispatch(setSelectedGame(option));
    },
    [dispatch],
  );

  const resetStats = useCallback(() => {
    dispatch(resetGameStatistics());
    setSelectedOption(ALL_GAMES_OPTION_VALUE);
  }, [dispatch, setSelectedOption]);

  return {
    resetStats,
    isWidgetOpen,
    widgetPosition,
    selectedGame,
    selectOptions,
    defaultOption,
    setSelectedOption,
  };
};

export default useGameStatisticsWidget;
