import { MouseEvent } from 'react';

import { SvgProps } from '.';

interface StarProps extends SvgProps {
  scale?: number;
  filled?: boolean;
  onClick?: (e: MouseEvent) => void;
  viewBox?: string;
}

const Star = ({
  scale = 1,
  color = '#FFFFFF',
  filled = false,
  onClick,
  viewBox = '0 0 20 20',
}: StarProps) => {
  const width = `${scale * 20}`;
  const height = `${scale * 20}`;

  return (
    <svg
      width={width}
      height={height}
      style={{ cursor: onClick ? 'pointer' : 'inherit' }}
      viewBox={viewBox}
      fill={filled ? 'white' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5166 2.31698L12.8958 7.03106L17.475 7.48476C17.584 7.49382 17.6881 7.53393 17.7751 7.60035C17.862 7.66676 17.9281 7.75669 17.9655 7.8595C18.0029 7.9623 18.0101 8.07367 17.9861 8.18043C17.9622 8.28718 17.9082 8.38485 17.8305 8.46186L14.0619 12.1974L15.4592 17.2735C15.4877 17.3811 15.4846 17.4948 15.4502 17.6007C15.4157 17.7066 15.3514 17.8004 15.2649 17.8706C15.1785 17.9408 15.0736 17.9846 14.9628 17.9966C14.8521 18.0086 14.7402 17.9884 14.6407 17.9384L9.99822 15.6393L5.36212 17.9355C5.26261 17.9856 5.15076 18.0058 5.04002 17.9938C4.92929 17.9818 4.82438 17.938 4.73793 17.8678C4.65147 17.7975 4.58714 17.7038 4.55269 17.5979C4.51823 17.4919 4.51511 17.3783 4.54369 17.2706L5.94092 12.1946L2.16947 8.45901C2.09177 8.382 2.03777 8.28434 2.01385 8.17758C1.98993 8.07083 1.9971 7.95946 2.03451 7.85665C2.07192 7.75385 2.13799 7.66391 2.22492 7.5975C2.31185 7.53109 2.41599 7.49098 2.525 7.48192L7.10421 7.02822L9.47986 2.31698C9.52872 2.22155 9.60297 2.14145 9.69443 2.08552C9.78589 2.0296 9.89102 2 9.99822 2C10.1054 2 10.2106 2.0296 10.302 2.08552C10.3935 2.14145 10.4677 2.22155 10.5166 2.31698V2.31698Z"
      />
    </svg>
  );
};

export default Star;
