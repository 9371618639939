import React, { memo, useState } from 'react';
import { useMount } from 'react-use';
import { EighteenPlus, Shuffle } from 'assets/svgs';
import clsx from 'clsx';
import Container from 'components/Container/Container';
import { BE_GAMBLE_AWARE_URL } from 'constants/social';
import useModal from 'hooks/useModal';
import usePreference from 'hooks/usePreference';
import { usePrice } from 'hooks/usePrice';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { ModalTypes } from 'redux/slices/modalSlice';

import AntillephoneLicense from './License/AntillephoneLicense';
import XCMLicense from './License/XCMLicense';
import { LanguageAndOddSelectors } from './LanguageAndOddSelectors';
import { COMMUNITY, MenuItemType, PLATFORM, POLICIES, SUPPORT } from './menu';

import styles from './Footer.module.scss';

const MenuItemLink = ({ external, url, label, className = '' }: MenuItemType) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  if (!url) {
    return label === 'footer.redeemCode.text' ? (
      <li className={styles.menuItem} onClick={() => openModal(ModalTypes.REDEEM_CODE_LOOKUP)}>
        {t(label)}
      </li>
    ) : (
      <li className={clsx(className, styles.menuItem)}>{t(label)}</li>
    );
  }

  return (
    <li className={clsx(className, styles.menuItem)}>
      {external ? (
        <a target="_blank" href={url} rel="noopener noreferrer">
          {t(label)}
        </a>
      ) : (
        <Link href={url} passHref>
          <a>{t(label)}</a>
        </Link>
      )}
    </li>
  );
};

const Column = memo(
  ({ children, className }: { children: React.ReactNode; className?: string }) => {
    return <div className={clsx(styles.column, className)}>{children}</div>;
  },
);

const Footer = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { cryptoPreference, fiatPreference } = usePreference();
  const priceOfCryptoPreference = usePrice(cryptoPreference);
  const [xcmScriptLoaded, setXcmScriptLoaded] = useState(false);
  const [antillephoneScriptLoaded, setAntillephoneScriptLoaded] = useState(false);

  useMount(() => {
    const intervalApg = setInterval(() => {
      if (window.apg_135e6de2_7372_4c94_8491_cf709e7f9b92) {
        setAntillephoneScriptLoaded(true);
        clearTimeout(intervalApg);
      }
    }, 50);

    const intervalXcm = setInterval(() => {
      if (window.xcm_69fb7e94_6ecd_4b46_83a2_24bac03464d0) {
        setXcmScriptLoaded(true);
        clearTimeout(intervalXcm);
      }
    }, 50);
  });

  return (
    <footer className={`${styles.footerWrapper} ${className}`}>
      <Container padding={{ desktop: '3rem 3rem 2rem 3rem' }}>
        <div className={`${styles.row}`}>
          <Column className={clsx('mobile-top', styles.columnOrder0)}>
            <div className={styles.brandingWrapper}>
              <Link href="/">
                <a>
                  <div className={styles.icon}>
                    <Shuffle />
                  </div>
                </a>
              </Link>
            </div>
          </Column>
          <Column className={styles.columnOrder2}>
            <div className={styles.menuHeading}>{t('footer.header.support')}</div>
            <ul className={styles.menuList}>
              {SUPPORT.map((item: MenuItemType) => (
                <MenuItemLink key={item.label} {...item} />
              ))}
            </ul>
          </Column>
          <Column className={styles.columnOrder3}>
            <div className={styles.menuHeading}>{t('footer.header.platform')}</div>
            <ul className={styles.menuList}>
              {PLATFORM.map(
                (item: MenuItemType) => item.active && <MenuItemLink key={item.label} {...item} />,
              )}
            </ul>
          </Column>
          <Column className={styles.columnOrder4}>
            <div className={styles.menuHeading}>{t('footer.header.policy')}</div>
            <ul className={styles.menuList}>
              {POLICIES.map((item: MenuItemType) => (
                <MenuItemLink key={item.label} {...item} />
              ))}
            </ul>
          </Column>
          <Column className={styles.columnOrder5}>
            <div className={styles.menuHeading}>{t('footer.header.community')}</div>
            <ul className={styles.menuList}>
              {COMMUNITY.map((item: MenuItemType) => (
                <MenuItemLink key={item.label} {...item} />
              ))}
            </ul>
          </Column>
          <Column className={styles.columnOrder1}>
            <LanguageAndOddSelectors display={'mobileAboveOnly'} />
          </Column>
        </div>

        <LanguageAndOddSelectors display={'mobileOnly'} />

        <div className={styles.description}>{t('footerDescription')}</div>

        <div className={styles.footerBottom}>
          <span>
            {`1 ${cryptoPreference} = `}
            {t('intlCurrencyWithOptions', {
              val: priceOfCryptoPreference,
              currency: fiatPreference,
            })}
          </span>

          <div className={styles.bottomRight}>
            <span className={styles.copyrightText}>
              {t('footerCopyright', { year: new Date().getFullYear() })}
            </span>
            <div className={styles.bottomRightIcons}>
              {antillephoneScriptLoaded && <AntillephoneLicense />}
              {xcmScriptLoaded && <XCMLicense />}
              <a
                className={styles.iconLink}
                target="_blank"
                href={BE_GAMBLE_AWARE_URL}
                rel="noopener noreferrer"
              >
                <EighteenPlus />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default React.memo(Footer);
