import React from 'react';

import { SvgProps } from '..';

const Blackjack = ({ scale = 1, color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 17}`;
  const height = `${scale * 16}`;

  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none">
      <path
        d="M8.83966 1.30632C8.81482 1.1779 8.76491 1.05563 8.69279 0.946511C8.62066 0.837389 8.52773 0.743553 8.41932 0.670369C8.31091 0.597185 8.18913 0.546087 8.06096 0.519999C7.93278 0.49391 7.80072 0.493341 7.67232 0.518324L1.80566 1.65699C1.54676 1.70829 1.31863 1.85985 1.17102 2.07865C1.02341 2.29744 0.968284 2.56572 1.01766 2.82499L2.72632 11.625C2.77665 11.8843 2.9279 12.1129 3.14681 12.2607C3.36572 12.4085 3.63437 12.4633 3.89366 12.413L4.44366 12.3063"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.07037 4.55699L4.98303 6.79766L5.48503 7.25099"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.82361 2.98099C2.85597 2.97473 2.88926 2.97494 2.92154 2.98162C2.95382 2.98831 2.98446 3.00132 3.01167 3.01992C3.03889 3.03852 3.06215 3.06234 3.0801 3.08998C3.09806 3.11763 3.11035 3.14856 3.11628 3.18099"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.62564 3.27365C2.61937 3.24129 2.61959 3.208 2.62627 3.17572C2.63295 3.14344 2.64597 3.11281 2.66457 3.08559C2.68317 3.05837 2.70698 3.03511 2.73463 3.01716C2.76228 2.9992 2.79321 2.98691 2.82564 2.98099"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.91828 3.47166C2.88592 3.47783 2.85266 3.47755 2.82041 3.47083C2.78817 3.46411 2.75757 3.45108 2.73037 3.4325C2.70317 3.41391 2.67992 3.39014 2.66194 3.36253C2.64396 3.33493 2.63161 3.30405 2.62561 3.27166"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.11638 3.17832C3.12265 3.21073 3.12245 3.24405 3.11577 3.27638C3.1091 3.3087 3.09609 3.33938 3.07749 3.36665C3.0589 3.39393 3.03509 3.41724 3.00744 3.43527C2.97979 3.45329 2.94884 3.46566 2.91638 3.47166"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4667 4.74872L8.96412 3.42812C8.6033 3.35484 8.25139 3.58794 8.17811 3.94876L6.26282 13.3796C6.18954 13.7404 6.42264 14.0923 6.78346 14.1656L13.286 15.4862C13.6469 15.5595 13.9988 15.3264 14.0721 14.9655L15.9874 5.53473C16.0606 5.17391 15.8275 4.822 15.4667 4.74872Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7323 11.4117L10.9296 10.4357"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6203 7.01833C11.6203 7.01833 9.95827 7.69899 9.76093 8.67499C9.72206 8.86734 9.72146 9.06545 9.75915 9.25803C9.79684 9.45061 9.8721 9.63387 9.98062 9.79736C10.1998 10.1276 10.5411 10.3572 10.9296 10.4357C11.3181 10.5142 11.7218 10.4351 12.052 10.216C12.3822 9.9968 12.6118 9.65545 12.6903 9.26699C12.8876 8.29033 11.6203 7.01833 11.6203 7.01833Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.7196 5.10899C9.78458 5.12217 9.84166 5.16062 9.87829 5.21588C9.91492 5.27114 9.92811 5.33868 9.91494 5.40366"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.42505 5.30433C9.43823 5.23935 9.47667 5.18227 9.53193 5.14564C9.58719 5.10901 9.65474 5.09583 9.71972 5.10899"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.62036 5.59966C9.55538 5.58648 9.4983 5.54804 9.46167 5.49278C9.42504 5.43752 9.41186 5.36997 9.42503 5.30499"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91503 5.40366C9.90185 5.46863 9.86341 5.52571 9.80815 5.56234C9.75289 5.59898 9.68534 5.61216 9.62036 5.59899"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6323 13.319C12.6973 13.3322 12.7544 13.3706 12.791 13.4259C12.8276 13.4811 12.8408 13.5487 12.8277 13.6137"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.339 13.5143C12.3522 13.4493 12.3906 13.3923 12.4459 13.3556C12.5011 13.319 12.5687 13.3058 12.6337 13.319"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5343 13.809C12.4694 13.7957 12.4124 13.7572 12.3758 13.702C12.3392 13.6467 12.326 13.5793 12.339 13.5143"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8323 13.6137C12.8191 13.6786 12.7806 13.7357 12.7254 13.7723C12.6701 13.809 12.6026 13.8222 12.5376 13.809"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Blackjack;
