import { FC, lazy, LazyExoticComponent, memo, Suspense } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ModalTypes } from '../../redux/slices/modalSlice';

const ModalLoader = (Component: LazyExoticComponent<FC>) => () => {
  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  );
};

const CrashGamePublicResultModal = ModalLoader(lazy(() => import('./CrashGamePublicResultModal')));
const GameHotkeysModal = ModalLoader(lazy(() => import('./GameHotkeysModal')));
const ProvablyFairModal = ModalLoader(lazy(() => import('./ProvablyFair/ProvablyFairModal')));
const WithdrawalSuccessModal = ModalLoader(lazy(() => import('./Wallet/WithdrawalSuccessModal')));
const WithdrawalTwoFaModal = ModalLoader(lazy(() => import('./Wallet/WithdrawalTwoFaModal')));
const BetLookupModal = ModalLoader(lazy(() => import('./Chat/BetLookupModal')));
const ChatRulesModal = ModalLoader(lazy(() => import('./Chat/ChatRulesModal')));
const RainInfoModal = ModalLoader(lazy(() => import('./Chat/RainInfoModal')));
const RainModal = ModalLoader(lazy(() => import('./Chat/RainModal')));
const TipInfoModal = ModalLoader(lazy(() => import('./Chat/TipInfoModal')));
const CrashGameTrendsModal = ModalLoader(lazy(() => import('./CrashGameTrendsModal')));
const UserLookupModal = ModalLoader(lazy(() => import('./Chat/UserLookupModal')));
const CreateCampaignModal = ModalLoader(lazy(() => import('./CreateCampaign')));
const CurrencyModal = ModalLoader(lazy(() => import('./Currency/CurrencyModal')));
const GeneralModal = ModalLoader(lazy(() => import('./GeneralModal/GeneralModal')));
const ChangePasswordModal = ModalLoader(lazy(() => import('./PasswordModal/ChangePasswordModal')));
const CreateNewPassword = ModalLoader(lazy(() => import('./PasswordModal/CreateNewPassword')));
const PasswordModal = ModalLoader(lazy(() => import('./PasswordModal/PasswordModal')));
const PromoRedeemModal = ModalLoader(lazy(() => import('./PromoRedeemModal')));
const UserEditInfoModal = ModalLoader(lazy(() => import('./User/UserEditInfoModal')));
const EmailVerification = ModalLoader(lazy(() => import('./Verification/EmailVerification')));
const TipSuccessModal = ModalLoader(lazy(() => import('./Wallet/TipSuccessModal')));
const TipTwoFaModal = ModalLoader(lazy(() => import('./Wallet/TipTwoFaModal')));
const RakebackModal = ModalLoader(lazy(() => import('./VipRewards/InstantRakebackModal')));
const DailyRakebackModal = ModalLoader(lazy(() => import('./VipRewards/DailyRakebackModal')));
const WeeklyBonusModal = ModalLoader(lazy(() => import('./VipRewards/WeeklyBonusModal')));
const MonthlyBonusModal = ModalLoader(lazy(() => import('./VipRewards/MonthlyBonusModal')));
const VipUpgradeBonusModal = ModalLoader(lazy(() => import('./VipRewards/VipUpgradeBonusModal')));
const WeeklyRaceClaimModal = ModalLoader(lazy(() => import('./VipRewards/WeeklyRaceClaimModal')));
const WeeklyRaceInfoModal = ModalLoader(lazy(() => import('./VipRewards/WeeklyRaceInfoModal')));
const VipBonusModal = ModalLoader(lazy(() => import('./VipRewards/VipBonusModal')));

const RedeemCodeLookupModal = ModalLoader(
  lazy(() => import('./PromoRedeemModal/RedeemCodeLookupModal')),
);

const GeoGameRestrictionModal = ModalLoader(
  lazy(() => import('./GeoRestriction/GeoGameRestrictionModal')),
);

const ModalManager = () => {
  const modalStore = useSelector((state: AppState) => state.modal, shallowEqual);

  return (
    <>
      {modalStore.modalType === ModalTypes.EMAIL && <EmailVerification />}
      {modalStore.modalType === ModalTypes.CURRENCY && <CurrencyModal />}
      {modalStore.modalType === ModalTypes.WITHDRAWAL_OTP && <WithdrawalTwoFaModal />}
      {modalStore.modalType === ModalTypes.WITHDRAWAL_SUCCESS && <WithdrawalSuccessModal />}

      {modalStore.modalType === ModalTypes.GENERAL && <GeneralModal />}
      {modalStore.modalType === ModalTypes.PASSWORD && <PasswordModal />}
      {modalStore.modalType === ModalTypes.CHANGE_PASSWORD && <ChangePasswordModal />}
      {modalStore.modalType === ModalTypes.CREATE_NEW_PASSWORD && <CreateNewPassword />}

      {modalStore.modalType === ModalTypes.USER_EDIT && <UserEditInfoModal />}
      {modalStore.modalType === ModalTypes.USER_LOOKUP && <UserLookupModal />}

      {/* Games Modals */}
      {modalStore.modalType === ModalTypes.BET_LOOKUP && <BetLookupModal />}
      {modalStore.modalType === ModalTypes.PROVABLY_FAIR && <ProvablyFairModal />}
      {modalStore.modalType === ModalTypes.GAME_HOTKEYS && <GameHotkeysModal />}
      {modalStore.modalType === ModalTypes.CRASH_GAME_TRENDS_MODAL && <CrashGameTrendsModal />}
      {modalStore.modalType === ModalTypes.CRASH_PUBLIC_GAME_RESULT && (
        <CrashGamePublicResultModal />
      )}

      {modalStore.modalType === ModalTypes.TIP_OTP && <TipTwoFaModal />}
      {modalStore.modalType === ModalTypes.TIP_SUCCESS && <TipSuccessModal />}
      {modalStore.modalType === ModalTypes.TIP_INFO && <TipInfoModal />}

      {modalStore.modalType === ModalTypes.CHAT_RAIN && <RainModal />}
      {modalStore.modalType === ModalTypes.CHAT_RAIN_INFO && <RainInfoModal />}

      {modalStore.modalType === ModalTypes.CHAT_RULES && <ChatRulesModal />}

      {modalStore.modalType === ModalTypes.PROMO_REDEEM && <PromoRedeemModal />}
      {modalStore.modalType === ModalTypes.REDEEM_CODE_LOOKUP && <RedeemCodeLookupModal />}
      {modalStore.modalType === ModalTypes.CREATE_CAMPAIGN && <CreateCampaignModal />}

      {modalStore.modalType === ModalTypes.GAME_RESTRICTION_MODAL && <GeoGameRestrictionModal />}

      {modalStore.modalType === ModalTypes.RAKEBACK_MODAL && <RakebackModal />}
      {modalStore.modalType === ModalTypes.DAILY_RAKEBACK_MODAL && <DailyRakebackModal />}
      {modalStore.modalType === ModalTypes.WEEKLY_BONUS_MODAL && <WeeklyBonusModal />}
      {modalStore.modalType === ModalTypes.MONTHLY_BONUS_MODAL && <MonthlyBonusModal />}
      {modalStore.modalType === ModalTypes.VIP_UPGRADE_BONUS_MODAL && <VipUpgradeBonusModal />}
      {modalStore.modalType === ModalTypes.WEEKLY_RACE_CLAIM && <WeeklyRaceClaimModal />}
      {modalStore.modalType === ModalTypes.WEEKLY_RACE_INFO && <WeeklyRaceInfoModal />}
      {modalStore.modalType === ModalTypes.VIP_BONUS_MODAL && <VipBonusModal />}
    </>
  );
};

export default memo(ModalManager);
