import {
  Sports,
  SportsFixture,
  SportsFixtureWithDefaultMarketsBodyFragment,
  SportsMarket,
  SportsMarketSelection,
  SportsMarketSelectionStatus,
  SportsMarketStatus,
} from 'generated/graphql';
import { i18n } from 'next-i18next';

import { SportsCategoryCarouselItem } from '../SportsCategoryCarousel/SportsCategoryCarousel';

const t = (name: string) => i18n?.t?.(name) ?? '';

export const sportDefaultMarketsMap: Record<Sports, string[]> = {
  [Sports.SOCCER]: ['2', '122'],
  [Sports.AMERICAN_FOOTBALL]: ['103', '106'],
  [Sports.BASKETBALL]: ['115', '147'],
  [Sports.ICE_HOCKEY]: ['183', '141'],
  [Sports.TENNIS]: ['322', '311'],
  [Sports.BASEBALL]: ['117', '97'],
  [Sports.MMA]: ['9471', '10533'],
  [Sports.BOXING]: ['201', '39'],
  [Sports.TABLE_TENNIS]: ['6750', '12796'],
  [Sports.GOLF]: ['35', '355'],
  [Sports.RUGBY_UNION]: ['15', '89'],
  [Sports.ESPORTS]: ['13124', '13031'],
};

export const getPrimaryMarketName = (sports: Sports) => {
  const primaryMarketName = {
    [Sports.SOCCER]: 'Match Result',
    [Sports.AMERICAN_FOOTBALL]: 'Money Line (Including Overtime)',
    [Sports.BASKETBALL]: 'Money Line',
    [Sports.ICE_HOCKEY]: 'Money Line',
    [Sports.TENNIS]: 'Match Result',
    [Sports.BASEBALL]: 'Money Line',
    [Sports.MMA]: 'Fight Outright',
    [Sports.BOXING]: 'Fight Outright',
    [Sports.TABLE_TENNIS]: 'Match Winner',
    [Sports.GOLF]: 'Winner',
    [Sports.RUGBY_UNION]: 'Match Winner',
    [Sports.ESPORTS]: 'Match Up Winner',
  };
  return primaryMarketName[sports] ?? '';
};

export const generateTemplateDefaultMarket = (params: {
  sport: Sports;
  fixture?: SportsFixtureWithDefaultMarketsBodyFragment | SportsFixture;
  homeTeam?: string;
  awayTeam?: string;
}) => {
  const { sport, fixture, homeTeam, awayTeam } = params;
  const primaryMarketTypeId = sportDefaultMarketsMap[sport]?.[0];

  const homeTeamName = fixture?.competitors?.[0]?.name || homeTeam;
  const awayTeamName = fixture?.competitors?.[1]?.name || awayTeam;

  let selections = [] as SportsMarketSelection[];
  if ([Sports.SOCCER, Sports.RUGBY_UNION, Sports.MMA, Sports.BOXING].includes(sport)) {
    selections = [
      {
        id: 'sel1',
        name: homeTeamName,
        oddsNumerator: '0',
        oddsDenominator: '1',
        status: SportsMarketSelectionStatus.SUSPENDED,
      },
      {
        id: 'sel2',
        name: 'Draw',
        oddsNumerator: '0',
        oddsDenominator: '1',
        status: SportsMarketSelectionStatus.SUSPENDED,
      },
      {
        id: 'sel3',
        name: awayTeamName,
        oddsNumerator: '0',
        oddsDenominator: '1',
        status: SportsMarketSelectionStatus.SUSPENDED,
      },
    ] as SportsMarketSelection[];
  } else {
    selections = [
      {
        id: 'sel1',
        name: homeTeamName,
        oddsNumerator: '0',
        oddsDenominator: '1',
        status: SportsMarketSelectionStatus.SUSPENDED,
      },
      {
        id: 'sel2',
        name: awayTeamName,
        oddsNumerator: '0',
        oddsDenominator: '1',
        status: SportsMarketSelectionStatus.SUSPENDED,
      },
    ] as SportsMarketSelection[];
  }

  return {
    id: 'marketTemplate',
    name: getPrimaryMarketName(sport),
    typeId: primaryMarketTypeId,
    typeName: getPrimaryMarketName(sport),
    status: SportsMarketStatus.CLOSED,
    inPlay: false,
    expiryTime: '',
    disabled: false,
    cashoutStatus: 'CLOSED',
    cashoutEnabled: false,
    selections,
  } as SportsMarket;
};

export const sortMarkets = (
  sport: Sports,
  markets: SportsMarket[] | SportsFixtureWithDefaultMarketsBodyFragment['markets'],
  defaultMarket?: SportsMarket,
) => {
  const defaultMarketTypeIds = sportDefaultMarketsMap[sport];
  return markets.sort((a, b) => {
    // Get the index of the type name of each market in the priority array
    const [aIndex, bIndex] = [a, b].map(m =>
      defaultMarketTypeIds.findIndex(
        typeId =>
          m.typeId === typeId &&
          (m.status === SportsMarketStatus.OPEN || m.status === SportsMarketStatus.SUSPENDED) &&
          m.id === defaultMarket?.id,
      ),
    );

    // If both types are in the priority array, sort by their index
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }

    // If only one type is in the priority array, sort that type first
    if (aIndex !== -1) {
      return -1;
    }

    if (bIndex !== -1) {
      return 1;
    }

    // If neither type is in the priority array, sort by name
    return a.name.localeCompare(b.name);
  });
};

// Return default market for display, if not found primary market, return backup market
export const getDefaultMarketForDisplay = (
  sport: Sports,
  fixture: SportsFixtureWithDefaultMarketsBodyFragment | SportsFixture,
  isLive: boolean,
  isClose: boolean,
) => {
  const defaultMarketTypeIds = sportDefaultMarketsMap[sport];
  const defaultMarketList =
    fixture?.markets?.filter(
      item => item?.typeId === defaultMarketTypeIds?.[0] && item?.selections?.length > 0,
    ) || [];

  const defaultMarket = sortMarkets(sport, defaultMarketList).find(item =>
    isLive
      ? item.inPlay &&
        [SportsMarketStatus.OPEN, SportsMarketStatus.SUSPENDED].includes(item?.status)
      : [SportsMarketStatus.OPEN, SportsMarketStatus.SUSPENDED].includes(item?.status),
  ) as SportsMarket;

  const backupMarketList =
    fixture?.markets?.filter(
      item => item?.typeId === defaultMarketTypeIds?.[1] && item?.selections?.length > 0,
    ) || [];

  const backupMarket = sortMarkets(sport, backupMarketList).find(item =>
    isLive
      ? item.inPlay &&
        [SportsMarketStatus.OPEN, SportsMarketStatus.SUSPENDED].includes(item?.status)
      : [SportsMarketStatus.OPEN, SportsMarketStatus.SUSPENDED].includes(item?.status),
  ) as SportsMarket;

  const isMarketOpen = (market: SportsMarket) => market?.status === SportsMarketStatus.OPEN;
  const isMarketSuspended = (market: SportsMarket) =>
    market?.status === SportsMarketStatus.SUSPENDED;

  if (isClose) {
    return generateTemplateDefaultMarket({ sport, fixture });
  }

  if (isMarketOpen(defaultMarket)) {
    return defaultMarket;
  }

  if (isMarketOpen(backupMarket)) {
    return backupMarket;
  }

  if (
    (isMarketSuspended(defaultMarket) && isMarketSuspended(backupMarket)) ||
    isMarketSuspended(defaultMarket)
  ) {
    return defaultMarket;
  }

  return generateTemplateDefaultMarket({ sport, fixture });
};

export const getMarketStatusText = (marketStatus: SportsMarketStatus) => {
  const statusText = {
    [SportsMarketStatus.OPEN]: '',
    [SportsMarketStatus.SUSPENDED]: t('txtSuspended'),
    [SportsMarketStatus.CLOSED]: t('txtClosed'),
    [SportsMarketStatus.RESULTED]: t('txtResulted'),
  }[marketStatus];
  return statusText || '';
};

export const getSportsMarketSelection = (params: {
  marketStatus: SportsMarketStatus;
  isEventSuspended: boolean;
  isEventClosed: boolean;
}) => {
  const { marketStatus, isEventSuspended, isEventClosed } = params;

  const status = isEventSuspended || isEventClosed ? SportsMarketStatus.CLOSED : marketStatus;
  const shouldDisableAllSelections =
    isEventSuspended || isEventClosed || shouldDisableOutcome(status);
  const marketStatusText = getMarketStatusText(status);

  return {
    status,
    shouldDisableAllSelections,
    marketStatusText,
  };
};

export const getMarketSelectionStatusText = (
  marketSelectionStatus: SportsMarketSelectionStatus,
) => {
  if (marketSelectionStatus === SportsMarketSelectionStatus.TRADING) {
    return '';
  }

  const statusText = {
    [SportsMarketSelectionStatus.SUSPENDED]: t('txtSuspended'),
    [SportsMarketSelectionStatus.UNPRICED]: t('txtClosed'),
    [SportsMarketSelectionStatus.RESULTED]: t('txtClosed'),
  }[marketSelectionStatus];
  return statusText || '';
};

export const shouldDisableOutcome = (
  marketStatus: SportsMarketStatus | SportsMarketSelectionStatus,
) => {
  switch (marketStatus) {
    case SportsMarketStatus.SUSPENDED:
    case SportsMarketStatus.CLOSED:
    case SportsMarketSelectionStatus.UNPRICED:
    case SportsMarketSelectionStatus.SUSPENDED:
    case SportsMarketSelectionStatus.RESULTED:
      return true;
    default:
      return false;
  }
};

export const sortSportCategoryIcons = (categories: SportsCategoryCarouselItem[]) => {
  return categories.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
