import React from 'react';
import clsx from 'clsx';

import styles from './BetMessage.module.scss';

export type MessageType = 'success' | 'error' | 'warning' | 'info';

interface BetMessageProps {
  message: string;
  msgType: MessageType;
  errorMsg?: string | React.ReactNode;
  successMsg?: string | React.ReactNode;
}

export const BetMessageIcon = ({ msgType }: { msgType: MessageType }) => {
  return (
    <>
      {msgType === 'error' && <img alt={'error'} src={'/icons/times-circle.svg'} />}
      {msgType === 'warning' && <img alt={'warning'} src={'/icons/warning.svg'} />}
      {msgType === 'info' && <img alt={'info'} src={'/icons/tick-circle.svg'} />}
      {msgType === 'success' && <img alt={'success'} src={'/icons/tick-circle.svg'} />}
    </>
  );
};

const BetMessage = ({ message, msgType, errorMsg, successMsg }: BetMessageProps) => {
  return (
    <>
      <div
        className={clsx(styles.betMessageBase, {
          [styles.betMessageSuccess]: msgType === 'success',
          [styles.betMessageError]: msgType === 'error',
          [styles.betMessageWarning]: msgType === 'warning',
          [styles.betMessageInfo]: msgType === 'info',
          [styles.betMessageRoundedTop]:
            msgType === 'error' || msgType === 'warning' || !!successMsg,
          [styles.betMessageWhite]: msgType === 'error' || msgType === 'warning',
        })}
      >
        <BetMessageIcon msgType={msgType} />
        <span>{message}</span>
      </div>
      {errorMsg && (msgType === 'error' || msgType === 'warning') && (
        <div
          className={clsx(styles.betMessageSubTitle, {
            [styles.betMessageSubTitleError]: msgType === 'error',
            [styles.betMessageSubTitleWarning]: msgType === 'warning',
          })}
        >
          {errorMsg}
        </div>
      )}
      {msgType === 'success' && successMsg && (
        <div className={clsx(styles.betMessageSubTitle, styles.betMessageSubTitleSuccess)}>
          {successMsg}
        </div>
      )}
    </>
  );
};

export default BetMessage;
