import { pxToRem } from 'assets/styles/convertors';
import { magicMediaQueries, mediaQueries } from 'assets/styles/mediaQueries';
import { ChevronDownWhite } from 'assets/svgs';
import BaseButton from 'components/Buttons/BaseButton';
import { MAX_CONTENT_WIDTH } from 'components/Container/styles';
import UI from 'constants/ui';
import { Breakpoint } from 'hooks/interface/useBreakpoint';
import styled from 'styled-components';

import colors from '../../assets/styles/colors';

export const NavWrapper = styled.nav`
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: ${colors.gray900};
  border-bottom: ${pxToRem(1)} solid ${colors.gray700};
  color: ${colors.white};
  height: ${UI.height.navHeader}px;
  z-index: ${UI.z.header};
`;

// On ios chrome when you scroll down and the page expands, then you scroll up sometimes you can see the top edges
export const IOSChomeScrollHider = styled.div`
  background-color: ${colors.gray900};
  height: 50px;
  width: 100%;
  position: fixed;
  top: -50px;
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  gap: 1rem;

  ${magicMediaQueries(Breakpoint.SM)`
    padding: ${UI.page.padding.desktop};
  `}

  ${magicMediaQueries(Breakpoint.LG)`
    max-width: ${MAX_CONTENT_WIDTH};
  `}

  ${magicMediaQueries(Breakpoint.XL)`
    padding-left: 0;
    padding-right: 0;
  `}
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const NavIconsMenu = styled.div`
  display: none;

  ${mediaQueries(Breakpoint.MD)`
    display: flex;
    column-gap: 0.625rem;
    justify-content: center;
    align-items: center;
  `};
`;

export const SearchWrapper = styled.div`
  display: none;
  ${mediaQueries(Breakpoint.MD)`
    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const DisplayOnMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQueries(Breakpoint.MD)`
    display: none;
  `};
`;

export const IconContainer = styled.div`
  border-radius: ${UI.radius.full};
  width: 2.188rem;
  height: 2.188rem;
  border: 0.0625rem solid white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonTransparent = styled(BaseButton)`
  background-color: transparent;
  margin-right: 0.5rem;
`;

export const Btn = styled.div`
  cursor: pointer;
  transition: 0.1s background-color;
  display: flex;
  align-items: center;
  column-gap: 0.625rem;
  border-radius: ${UI.radius.sm};
  padding: 0.6rem 1rem;
  height: 3rem;
`;

export const BtnContainer = styled.div`
  display: flex;
  column-gap: 0.625rem;
`;

export const BalanceBtn = styled(Btn)`
  background-color: ${colors.gray800};
  font-size: ${UI.text.lg};
  font-variant-numeric: tabular-nums;

  p {
    margin-right: 1rem;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.gray600};
    }
  }
`;

export const WalletBtn = styled(Btn)`
  background-color: ${colors.primaryNeonPurple};
  border: none;

  @media (hover: hover) {
    &:hover {
      background-color: ${colors.primaryViolet};
    }
  }
`;

export const WalletBtnText = styled.span`
  display: none;
  ${mediaQueries(Breakpoint.SM)`
    display: block;
  `};
`;

export const ChevronDownIcon = styled(ChevronDownWhite)<{ $pointUp: boolean }>`
  transform: ${({ $pointUp }) => ($pointUp ? 'rotate(180deg)' : '')};
`;

export const BalanceBtnWrapper = styled.div`
  position: relative;
`;

export const MenuDivider = styled.div<{ $collapse: boolean }>`
  border: ${pxToRem(1)} solid ${colors.gray700};
  width: ${pxToRem(1)};
  height: ${pxToRem(40)};
  margin: 0;

  ${({ $collapse }) => magicMediaQueries(Breakpoint.XS)`
    margin: ${$collapse ? '0' : `0 ${pxToRem(24)}`};
  `};

  ${magicMediaQueries(Breakpoint.MD)`
    margin: 0 ${pxToRem(24)};
  `}
`;

export const SearchContainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: ${UI.z.menu};
  background-color: ${colors.gray900};
  top: 0;

  ${mediaQueries(Breakpoint.MD)`
    height: 100%;
  `};
`;

export const Visible = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;
