import NextImage, { ImageProps as NextImageProps } from 'next/image';

import isString from '../../utils/isString';

type WrappedImageProps = Omit<NextImageProps, 'unoptimized'>;

const EXTERNAL_URL_REGEX = /^http(s)?:/;

// Whitelist external assets; eg Contentful images
const OPTIMIZED_EXTERNAL_URLS: string[] = [String(process.env.NEXT_PUBLIC_CONTENTFUL_ASSET_URL)];

const WrappedImage = ({ src, ...props }: WrappedImageProps) => {
  const isSrcString = isString(src);

  const unoptimized =
    !props.loader &&
    isSrcString &&
    !OPTIMIZED_EXTERNAL_URLS.some(url => src.startsWith(url)) &&
    EXTERNAL_URL_REGEX.test(src);

  // Avoid all optimizations from external URLs; namely IMGIX.
  return <NextImage src={src} unoptimized={unoptimized} {...props} />;
};

export default WrappedImage;
