import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Sports, SportsMatchPhase, SportsMatchState } from 'generated/graphql';
import { i18n } from 'next-i18next';

import {
  checkEventStateBeforeLive,
  isSportEventPreMatch,
  isSportEventSuspended,
} from './sportEventStatusCheck';
import { getLiveMatchPhaseScores } from './sportsMatchStateUtils';
import { transformSportName } from './transformSportName';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const sportNameMapping = new Map<Sports, string>();
sportNameMapping.set(Sports.AMERICAN_FOOTBALL, transformSportName(Sports.AMERICAN_FOOTBALL));
sportNameMapping.set(Sports.BASKETBALL, transformSportName(Sports.BASKETBALL));
sportNameMapping.set(Sports.ICE_HOCKEY, transformSportName(Sports.ICE_HOCKEY));
sportNameMapping.set(Sports.SOCCER, transformSportName(Sports.SOCCER));
sportNameMapping.set(Sports.TENNIS, transformSportName(Sports.TENNIS));
sportNameMapping.set(Sports.BASEBALL, transformSportName(Sports.BASEBALL));
sportNameMapping.set(Sports.MMA, transformSportName(Sports.MMA));
sportNameMapping.set(Sports.BOXING, transformSportName(Sports.BOXING));
sportNameMapping.set(Sports.TABLE_TENNIS, transformSportName(Sports.TABLE_TENNIS));
sportNameMapping.set(Sports.GOLF, transformSportName(Sports.GOLF));
sportNameMapping.set(Sports.RUGBY_UNION, transformSportName(Sports.RUGBY_UNION));
sportNameMapping.set(Sports.ESPORTS, transformSportName(Sports.ESPORTS));

export const generateSportsFromSlug = (sportSlug: string) => {
  return (sportSlug ? sportSlug.toString().replace('-', '_').toUpperCase() : '') as Sports;
};

export const generateSportNameFromSlug = (sportSlug: string) => {
  const sport = generateSportsFromSlug(sportSlug);
  const sportName = sportNameMapping.get(sport as Sports);
  return sportName ? sportName : sport;
};

export const generateSlug = (sports: string) => {
  return sports.replaceAll('_', '-').replaceAll(' ', '-').toLowerCase();
};

// Abbreviation of competitor name should follow:
// if competitor name is 2 or 3 words, abbreviate with first letter of each word (i.e Manchester United == MU, Rhode Island Rams == RIR)
// if competitor name is 1 word || >= 4 words, use the first 3 letters (i.e Australia == AUS, Cambodia == CAM)
// else first 3 letters
export const abbreviateName = (name: string) => {
  if (name) {
    const words = name.trim().split(' ');
    const wordCount = words.length;
    return wordCount === 1 || wordCount >= 4
      ? words[0].slice(0, 3).toUpperCase()
      : words
          .map(word => word.charAt(0).toUpperCase())
          .join('')
          .slice(0, 3);
  }
};

export const abbreviateFixtureName = (name: string) => {
  if (name) {
    const competitors = name.toLowerCase().split(' v ');
    return `${abbreviateName(competitors[0])} vs. ${abbreviateName(competitors[1])}`;
  }
};

// convert sport name to key. Ex: American Football => AmericanFootball
export const getSportKey = (name: string) => {
  return name
    .replace('_', ' ')
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
};

export const convertMatchSetNumber = (number: number): string => {
  let suffix = 'th';
  const lastDigit: number = number % 10;
  const lastTwoDigits: number = number % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    suffix = 'st';
  } else if (lastDigit === 2 && lastTwoDigits !== 12) {
    suffix = 'nd';
  } else if (lastDigit === 3 && lastTwoDigits !== 13) {
    suffix = 'rd';
  }

  return number + suffix;
};

export const getEventState = (params: {
  startTime: string;
  formattedDateTime: string;
  matchPhase: SportsMatchPhase;
}) => {
  const t = (name: string) => i18n?.t?.(name) ?? '';
  const { startTime, formattedDateTime, matchPhase } = params;
  const { shouldShowAboutToStart, shouldShowDelayedStart } = checkEventStateBeforeLive(startTime);
  const isEventSuspended = isSportEventSuspended({ matchPhase });

  if (!startTime || !formattedDateTime) {
    return '';
  }

  if (isEventSuspended) {
    const suffixLabel = t('txtSuspended') || 'Suspended';
    return `${suffixLabel}`;
  }

  if (shouldShowDelayedStart) {
    const suffixLabel = t('delayedStart') || 'Delayed start';
    return `${suffixLabel}`;
  }

  if (shouldShowAboutToStart) {
    const suffixLabel = t('aboutToStart') || 'About to start';
    return `${suffixLabel}`;
  }

  return '';
};

export const generateLiveMatchProgressText = (params: {
  sport: Sports;
  matchStatistics?: SportsMatchState;
  startTime?: string;
  formattedDateTime?: string;
}): {
  content: string;
  parts: [string, string] | [];
} => {
  const { sport, matchStatistics, startTime, formattedDateTime } = params;
  const liveScores = getLiveMatchPhaseScores({ sport, matchStatistics });
  const latestScore = liveScores?.[liveScores.length - 1];
  const matchSet = latestScore?.phase;
  const matchPhaseStatus = latestScore?.status ?? '';
  const matchPhase = matchStatistics?.matchSummary?.matchPhase as SportsMatchPhase;
  const currentPhaseName = matchStatistics?.matchSummary?.currentPhaseName ?? '';
  const timeRemaining = matchStatistics?.matchSummary?.timeRemaining ?? 0;
  const timeRemainingAt = matchStatistics?.matchSummary?.timeRemainingAt ?? null;

  const t = (name: string) => i18n?.t?.(name) ?? '';

  const matchPhaseList = {
    [SportsMatchPhase.PREMATCH]: '',
    [SportsMatchPhase.FIRSTQUARTER]: convertMatchSetNumber(1),
    [SportsMatchPhase.BEFORESECONDQUARTER]: convertMatchSetNumber(2),
    [SportsMatchPhase.SECONDQUARTER]: convertMatchSetNumber(2),
    [SportsMatchPhase.FIRSTHALF]: convertMatchSetNumber(1),
    [SportsMatchPhase.HALFTIME]: t('txtSportLiveLabelsHalftime') || 'Halftime',
    [SportsMatchPhase.THIRDQUARTER]: convertMatchSetNumber(3),
    [SportsMatchPhase.BEFOREFOURTHQUARTER]: convertMatchSetNumber(4),
    [SportsMatchPhase.FOURTHQUARTER]: convertMatchSetNumber(4),
    [SportsMatchPhase.SECONDHALF]: convertMatchSetNumber(2),
    [SportsMatchPhase.BEFOREOVERTIME]: '',
    [SportsMatchPhase.OVERTIME]: '',
    [SportsMatchPhase.POSTGAME]: '',
    [SportsMatchPhase.GAMEABANDONED]: '',
    [SportsMatchPhase.PREGAME]: '',
    [SportsMatchPhase.FULLTIMENORMALTIME]: t('txtSportLiveLabelsFullTime'),
    [SportsMatchPhase.FULLTIMEEXTRATIME]: t('txtSportLiveLabelsFullTime'),
    [SportsMatchPhase.EXTRATIMEFIRSTHALF]: `${t(
      'txtSportLiveLabelsExtraTime',
    )} ${convertMatchSetNumber(1)}`,
    [SportsMatchPhase.EXTRATIMEHALFTIME]: `${t('txtSportLiveLabelsExtraTimeHalftime')}`,
    [SportsMatchPhase.EXTRATIMESECONDHALF]: `${t(
      'txtSportLiveLabelsExtraTime',
    )} ${convertMatchSetNumber(2)}`,
    [SportsMatchPhase.PENALTIES]: `${t('txtSportLiveLabelsPenalties')}`,
    [SportsMatchPhase.POSTMATCH]: '',
    [SportsMatchPhase.MATCHABANDONED]: '',
    [SportsMatchPhase.INPROGRESS]: '',
    [SportsMatchPhase.SUSPENDED]: '',
    [SportsMatchPhase.CANCELLED]: '',
    [SportsMatchPhase.POSTPONED]: '',
    [SportsMatchPhase.ABANDONED]: '',
    [SportsMatchPhase.FIRSTPERIOD]: convertMatchSetNumber(1),
    [SportsMatchPhase.ENDOFFIRSTPERIOD]: convertMatchSetNumber(1),
    [SportsMatchPhase.SECONDPERIOD]: convertMatchSetNumber(2),
    [SportsMatchPhase.ENDOFSECONDPERIOD]: convertMatchSetNumber(2),
    [SportsMatchPhase.THIRDPERIOD]: convertMatchSetNumber(3),
    [SportsMatchPhase.ENDOFTHIRDPERIOD]: convertMatchSetNumber(3),
    [SportsMatchPhase.OVERTIMEPERIOD]: `${t('txtSportLiveLabelsOvertime')}`,
    [SportsMatchPhase.ENDOFOVERTIMEPERIOD]: `${t('txtSportLiveLabelsOvertime')}`,
    [SportsMatchPhase.PENALTYSHOOTOUT]: '',
    [SportsMatchPhase.INPLAY]: '',
    [SportsMatchPhase.BREAKINPLAY]: '',
    [SportsMatchPhase.COMPLETED]: '',
  };

  const labels = {
    [Sports.SOCCER]: t('txtSportLiveLabelsHalf') || 'Half',
    [Sports.BASKETBALL]: t('txtSportLiveLabelsQuarter') || 'Quarter',
    [Sports.TENNIS]: t('txtSportLiveLabelsSet') || 'Set',
    [Sports.BASEBALL]: t('txtSportLiveLabelsInnings') || 'Innings',
    [Sports.ICE_HOCKEY]: t('txtSportLiveLabelsPeriod') || 'Period',
    [Sports.AMERICAN_FOOTBALL]: t('txtSportLiveLabelsQuarter') || 'Quarter',
    [Sports.MMA]: t('txtSportLiveLabelsRound') || 'Round',
    [Sports.BOXING]: t('txtSportLiveLabelsRound') || 'Round',
    [Sports.TABLE_TENNIS]: t('txtSportLiveLabelsGame') || 'Game',
    [Sports.GOLF]: t('txtSportLiveLabelsDay') || 'Day',
    [Sports.RUGBY_UNION]: t('txtSportLiveLabelsHalf') || 'Half',
    [Sports.ESPORTS]: t('txtSportLiveLabelsMap') || 'Map',
  };

  const matchSetDisplay = matchSet
    ? convertMatchSetNumber(matchSet)
    : matchPhaseList[matchPhase] ?? '';
  const timeRemainingAtToMilliseconds = timeRemainingAt ? dayjs(timeRemainingAt).valueOf() : 0;
  const estTimeRemaining = timeRemainingAtToMilliseconds + timeRemaining;

  const estTimeRemainingToMinutesText =
    estTimeRemaining > 0 ? dayjs().to(estTimeRemaining, true)?.split(' ')?.[0] : '';
  // in case of 1 minute remaining, display 1' instead of 'a minute'
  let timeRemainingDisplay = '';
  if (estTimeRemainingToMinutesText) {
    if (estTimeRemainingToMinutesText === 'a' || estTimeRemainingToMinutesText === 'an') {
      timeRemainingDisplay = "1'";
    } else {
      timeRemainingDisplay = `${estTimeRemainingToMinutesText}'`;
    }
  }

  const matchPhaseFromTranslation = [
    SportsMatchPhase.HALFTIME,
    SportsMatchPhase.FULLTIMENORMALTIME,
    SportsMatchPhase.FULLTIMEEXTRATIME,
    SportsMatchPhase.EXTRATIMEHALFTIME,
    SportsMatchPhase.EXTRATIMESECONDHALF,
    SportsMatchPhase.PENALTIES,
    SportsMatchPhase.OVERTIMEPERIOD,
    SportsMatchPhase.ENDOFOVERTIMEPERIOD,
  ];

  const label = !matchPhaseFromTranslation.includes(matchPhase) ? labels[sport] : '';

  const isPreMatch = isSportEventPreMatch({
    startTime: startTime ?? '',
    matchStatistics,
  });

  const getLiveProgressText = () => {
    if (
      timeRemainingDisplay &&
      [Sports.SOCCER, Sports.BASKETBALL, Sports.ICE_HOCKEY, Sports.AMERICAN_FOOTBALL].includes(
        sport,
      )
    ) {
      if (sport === Sports.BASKETBALL && matchPhaseFromTranslation.includes(matchPhase)) {
        return {
          content: `${timeRemainingDisplay} ${matchPhaseList[matchPhase]}`,
          parts: [timeRemainingDisplay, `${matchPhaseList[matchPhase]}`] as [string, string],
        };
      }

      return {
        content: `${timeRemainingDisplay} ${matchSetDisplay} ${label}`,
        parts: [timeRemainingDisplay, `${matchSetDisplay} ${label}`] as [string, string],
      };
    }

    if (currentPhaseName) {
      if ([Sports.ESPORTS, Sports.TABLE_TENNIS].includes(sport)) {
        return {
          content: `${currentPhaseName}`,
        };
      }

      if (sport === Sports.AMERICAN_FOOTBALL) {
        const quarterIndex = currentPhaseName.indexOf('Quarter');
        const quarterString = currentPhaseName.slice(0, quarterIndex + 'Quarter'.length).trim();
        return {
          content: `${quarterString}`,
        };
      }
    }

    if (sport === Sports.BASEBALL) {
      return {
        content: `${matchSetDisplay} ${label} ${matchPhaseStatus}`,
      };
    }

    return {
      content: `${matchSetDisplay} ${label} ${matchPhaseStatus}`,
    };
  };

  if (matchStatistics && !isPreMatch) {
    const output = getLiveProgressText();

    return {
      content: output.content?.trim(),
      parts: output?.parts ?? [],
    };
  }

  return {
    content: getEventState({
      startTime: startTime ?? '',
      formattedDateTime: formattedDateTime ?? '',
      matchPhase,
    }),
    parts: [],
  };
};
