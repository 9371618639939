import { Language } from 'constants/languages';

export interface CustomDateFormats {
  C_MMMMDD: string;
  C_MMMDD: string;
  C_DDDDMMMMDD: string;
}

export const CUSTOM_DATE_FORMATS: Record<Language, CustomDateFormats> = {
  [Language.EN]: {
    C_MMMMDD: 'MMMM DD',
    C_MMMDD: 'MMM DD',
    C_DDDDMMMMDD: 'dddd, MMMM DD',
  },
  [Language.FR]: {
    C_MMMMDD: 'DD MMMM',
    C_MMMDD: 'DD MMM',
    C_DDDDMMMMDD: 'dddd DD MMMM',
  },
  [Language.ZH]: {
    C_MMMMDD: 'MMMDD[日]', // MMM on purpose
    C_MMMDD: 'MMMDD[日]', // MMM on purpose
    C_DDDDMMMMDD: 'MMMDD[日]dddd', // MMM on purpose
  },
  [Language.ES]: {
    C_MMMMDD: 'DD [de] MMMM',
    C_MMMDD: 'DD [de] MMM',
    C_DDDDMMMMDD: 'dddd, DD [de] MMMM',
  },
  [Language.PT]: {
    C_MMMMDD: 'DD [de] MMMM',
    C_MMMDD: 'DD [de] MMM',
    C_DDDDMMMMDD: 'dddd, DD [de] MMMM',
  },
  [Language.JA]: {
    C_MMMMDD: 'MMMDD[日]', // MMM on purpose
    C_MMMDD: 'MMMDD[日]', // MMM on purpose
    C_DDDDMMMMDD: 'MMMDD[日]dddd', // MMM on purpose
  },
  [Language.KO]: {
    C_MMMMDD: 'MMM DD[일]', // MMM on purpose
    C_MMMDD: 'MMM DD[일]', // MMM on purpose
    C_DDDDMMMMDD: 'MMMM DD[일] dddd',
  },
};
