import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import BaseButton from 'components/Buttons/BaseButton';
import usePreference from 'hooks/usePreference';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { setCookiesAccepted } from 'redux/slices/browserPreferenceSlice';

import { BannerBody, BannerImage, BannerWrapper, CookieBannerGroup } from './styles';

const CookieBanner = () => {
  const { t } = useTranslation();
  const { cookiesAccepted } = usePreference();
  const dispatch = useDispatch();

  const handleAccept = useCallback(() => {
    dispatch(setCookiesAccepted(true));
  }, [dispatch]);

  if (cookiesAccepted) return null;

  return (
    <BannerWrapper>
      <BannerBody>
        <BannerImage>🍪</BannerImage>
        <p>{t('txtAcceptCookies')}</p>
      </BannerBody>
      <CookieBannerGroup>
        <Link href="/info/privacy" passHref replace>
          <a>
            <BaseButton color="tertiary" value={t('btnLearnMore')} />
          </a>
        </Link>
        <BaseButton onClick={handleAccept} value={t('btnAccept')} />
      </CookieBannerGroup>
    </BannerWrapper>
  );
};

export default CookieBanner;
