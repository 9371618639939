import React, { ButtonHTMLAttributes } from 'react';

import { Icon, PrimaryButton, SecondaryButton, TertiaryButton } from './styles';

interface ButtonWrapperProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color: 'primary' | 'secondary' | 'tertiary';
  hasMargin?: boolean;
  children: React.ReactNode;
}

export const ButtonWrapper = ({
  color = 'primary',
  hasMargin = false,
  children,
  ...rest
}: ButtonWrapperProps) => {
  return (
    <>
      {color === 'primary' && (
        <PrimaryButton hasMargin={hasMargin} isRound {...rest}>
          {children}
        </PrimaryButton>
      )}
      {color === 'secondary' && (
        <SecondaryButton hasMargin={hasMargin} isRound {...rest}>
          {children}
        </SecondaryButton>
      )}
      {color === 'tertiary' && (
        <TertiaryButton hasMargin={hasMargin} isRound {...rest}>
          {children}
        </TertiaryButton>
      )}
    </>
  );
};

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Button style
   */
  color?: 'primary' | 'secondary' | 'tertiary';
  /**
   * Icon
   */
  icon: React.ReactNode | string;
  /**
   * Add margin top
   */
  hasMargin?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const RoundButton = ({
  color = 'primary',
  icon = '',
  hasMargin = false,
  ...rest
}: ButtonProps) => {
  return (
    <ButtonWrapper hasMargin={hasMargin} {...rest} color={color}>
      <Icon>{icon}</Icon>
    </ButtonWrapper>
  );
};

export default RoundButton;
