import React from 'react';
import { useTranslation } from 'next-i18next';

import { BetSlipStatus } from '../../../../redux/slices/sportsBetSlice';

import BetMessage from './BetMessage';
import { getPlacedBetErrorText } from './betSlipErrors';

type Props = {
  placedBetError?: boolean;
  isInBetAddView: boolean;
  betSlipStatus?: BetSlipStatus;
  exceedMaxAllowedStakeAmount?: boolean;
  showError: boolean;
  errorText: string;
};

export function BetTitleHeaderErrors({
  exceedMaxAllowedStakeAmount,
  placedBetError,
  betSlipStatus,
  isInBetAddView,
  showError,
  errorText,
}: Props) {
  const { t } = useTranslation();
  return (
    <>
      {betSlipStatus === BetSlipStatus.BET_PLACED &&
        placedBetError &&
        !exceedMaxAllowedStakeAmount && (
          <BetMessage msgType="error" message={getPlacedBetErrorText()} />
        )}

      {betSlipStatus === BetSlipStatus.BET_PLACED && exceedMaxAllowedStakeAmount && (
        <BetMessage msgType="error" message={t('betSlipMaxStakeError')} />
      )}

      {isInBetAddView && showError && <BetMessage msgType="error" message={errorText} />}
    </>
  );
}
