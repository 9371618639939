import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';
import FormattedAmount from 'components/FormattedAmount';
import { Currency } from 'generated/graphql';
import usePreference from 'hooks/usePreference';
import { setCryptoPreference } from 'redux/slices/browserPreferenceSlice';

import {
  BetSlipStatus,
  changeBetSlipStatus,
  initialState,
  setBetSlip,
  updateMultiBet,
} from '../../../redux/slices/sportsBetSlice';
import CryptoIcon from '../../CurrencyIcons/CryptoIcon';

import { BALANCE_MAX_DIGITS } from './BalanceButton';
import { Amount, Balance, CryptoIconWrapper, CurrencyText } from './styles';

interface Props {
  currency: Currency;
  value: string;
  closePopup: () => void;
}

const BalanceItem = ({ currency, value, closePopup }: Props) => {
  const { cryptoPreference, hideZeroBalance } = usePreference();
  const { betSlipStatus, betSlips } = useSelector(
    (appState: AppState) => appState.sportsBet,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const onUpdateSportBet = () => {
    if (betSlipStatus === BetSlipStatus.CONFIRMING_BET) {
      dispatch(
        changeBetSlipStatus({
          betSlipStatus: BetSlipStatus.ADDING_BET,
        }),
      );
    }

    if (betSlipStatus === BetSlipStatus.ADDING_BET) {
      dispatch(
        setBetSlip({
          betSlips: betSlips.map(item => ({
            ...item,
            betAmount: '',
            estPayout: '',
          })),
        }),
      );

      dispatch(
        updateMultiBet({
          amount: initialState.multiBet.amount,
        }),
      );
    }
  };

  const clickHandler = () => {
    dispatch(setCryptoPreference(currency));
    closePopup();
    onUpdateSportBet();
  };

  return (
    <Balance
      onClick={clickHandler}
      $selected={cryptoPreference === currency}
      data-testid={`currency-${currency}`}
    >
      <CryptoIconWrapper>
        <CryptoIcon currency={currency} />
      </CryptoIconWrapper>

      {(!hideZeroBalance || BigNumber(value).isGreaterThan(0)) && (
        <>
          <Amount>
            <FormattedAmount value={value} currency={currency} maxDigits={BALANCE_MAX_DIGITS} />
          </Amount>
          <CurrencyText>{currency}</CurrencyText>
        </>
      )}
    </Balance>
  );
};

export default React.memo(BalanceItem);
