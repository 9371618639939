import React from 'react';

import { SvgProps } from '..';

const Boxing = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: SvgProps) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_6582_150257)">
        <path
          d="M2.17628 5.467C1.79144 5.78017 1.54633 6.23305 1.4946 6.72651C1.44288 7.21997 1.58874 7.71383 1.90028 8.1L4.50028 11.5V13.5C4.50028 14.0304 4.71099 14.5391 5.08607 14.9142C5.46114 15.2893 5.96985 15.5 6.50028 15.5H11.5003C12.0307 15.5 12.5394 15.2893 12.9145 14.9142C13.2896 14.5391 13.5003 14.0304 13.5003 13.5V10.5L14.4743 3.816C14.5481 3.41066 14.532 2.99405 14.427 2.59563C14.3221 2.19722 14.1308 1.82674 13.8669 1.51039C13.6029 1.19405 13.2727 0.939579 12.8995 0.76498C12.5263 0.590382 12.1193 0.499922 11.7073 0.5H6.25928C5.84733 0.500097 5.44042 0.590674 5.06732 0.765329C4.69423 0.939983 4.36404 1.19445 4.10011 1.51075C3.83618 1.82705 3.64494 2.19746 3.53991 2.5958C3.43488 2.99414 3.41862 3.41069 3.49228 3.816L4.24328 7.946"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6.5 12.5H11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_6582_150257">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Boxing;
