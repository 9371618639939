import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import Portal from 'HOC/Portal';

import Alert from './Alert';

import styles from './AlertsManager.module.scss';

const AlertsManager = () => {
  const { alerts } = useSelector((state: AppState) => state.alerts);

  return (
    <Portal portal="alerts">
      <div className={styles.root}>
        <AnimatePresence>
          {alerts.map(alert => {
            return (
              <motion.div
                key={alert.id}
                className={styles.alertWrapper}
                initial={{ opacity: 0, x: -25, maxHeight: 0 }}
                animate={{ opacity: 1, x: 0, maxHeight: 500 }}
                exit={{ opacity: 0, x: -25, maxHeight: 0 }}
              >
                <Alert {...alert} />
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
    </Portal>
  );
};

export default AlertsManager;
