import { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';

import { Currency } from '../generated/graphql';
import { convertCryptoToFiatByCurrency } from '../utils/convertCryptoToFiatByCurrency';

export const useCryptoToFiatByCurrency = () => {
  const pricesStore = useSelector((state: AppState) => state.prices, shallowEqual);

  const cryptoToFiatByCurrency = useCallback(
    (amount: string, currency: Currency, roundingMode?: BigNumber.RoundingMode): string =>
      convertCryptoToFiatByCurrency(amount, currency, pricesStore.prices, { roundingMode }),
    [pricesStore.prices],
  );

  return {
    cryptoToFiatByCurrency,
  };
};
