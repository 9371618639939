import React, { useState } from 'react';
import WrappedImage from 'components/WrappedImage';
import { backgrounds, BgEnum, FaceEnum, faces } from 'hooks/useAvatar';
import { imageQuality } from 'utils/imageQuality';

import { AvatarElement } from './styles';

const BASE_AVATAR_SIZE = 40;

const quality = imageQuality();

interface AvatarProps {
  face?: FaceEnum;
  background?: BgEnum;
  size?: number;
}

const Avatar = ({ face, background, size = BASE_AVATAR_SIZE }: AvatarProps) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <AvatarElement
      $background={!loaded || typeof background === 'undefined' ? 'unset' : backgrounds[background]}
      $size={size}
    >
      {typeof face !== 'undefined' && (
        <WrappedImage
          onLoadingComplete={() => setLoaded(true)}
          src={faces[face]}
          alt="user-avatar"
          width={size}
          height={size}
          quality={quality}
        />
      )}
    </AvatarElement>
  );
};

export default React.memo(Avatar);
