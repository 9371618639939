import { FiatCurrency } from 'generated/graphql';

import { SvgProps } from '..';

import { default as BRL } from './BRL.svg';
import { default as EUR } from './EUR.svg';
import { default as IDR } from './IDR.svg';
import { default as JPY } from './JPY.svg';
import { default as MXN } from './MXN.svg';
import { default as USD } from './USD';

export const Fiat: { [key in FiatCurrency]: React.FC<SvgProps> } = {
  [FiatCurrency.USD]: USD,
  [FiatCurrency.EUR]: EUR,
  [FiatCurrency.JPY]: JPY,
  [FiatCurrency.MXN]: MXN,
  [FiatCurrency.BRL]: BRL,
  [FiatCurrency.IDR]: IDR,
};
