import * as React from 'react';

import { SvgProps } from '../index';

export const Casino = ({ color = '#FFFFFF' }: SvgProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 1.5C12.5 1.23478 12.3946 0.98043 12.2071 0.792893C12.0196 0.605357 11.7652 0.5 11.5 0.5H2C1.60218 0.5 1.22064 0.658035 0.93934 0.93934C0.658035 1.22064 0.5 1.60218 0.5 2V11.5C0.5 11.7652 0.605357 12.0196 0.792893 12.2071C0.98043 12.3946 1.23478 12.5 1.5 12.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 3.5H5C4.17157 3.5 3.5 4.17157 3.5 5V14C3.5 14.8284 4.17157 15.5 5 15.5H14C14.8284 15.5 15.5 14.8284 15.5 14V5C15.5 4.17157 14.8284 3.5 14 3.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 7.5C7.05228 7.5 7.5 7.05228 7.5 6.5C7.5 5.94772 7.05228 5.5 6.5 5.5C5.94772 5.5 5.5 5.94772 5.5 6.5C5.5 7.05228 5.94772 7.5 6.5 7.5Z"
        fill={color}
      />
      <path
        d="M12.5 7.5C13.0523 7.5 13.5 7.05228 13.5 6.5C13.5 5.94772 13.0523 5.5 12.5 5.5C11.9477 5.5 11.5 5.94772 11.5 6.5C11.5 7.05228 11.9477 7.5 12.5 7.5Z"
        fill={color}
      />
      <path
        d="M6.5 13.5C7.05228 13.5 7.5 13.0523 7.5 12.5C7.5 11.9477 7.05228 11.5 6.5 11.5C5.94772 11.5 5.5 11.9477 5.5 12.5C5.5 13.0523 5.94772 13.5 6.5 13.5Z"
        fill={color}
      />
      <path
        d="M12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5C13.5 11.9477 13.0523 11.5 12.5 11.5C11.9477 11.5 11.5 11.9477 11.5 12.5C11.5 13.0523 11.9477 13.5 12.5 13.5Z"
        fill={color}
      />
      <path
        d="M9.5 10.5C10.0523 10.5 10.5 10.0523 10.5 9.5C10.5 8.94772 10.0523 8.5 9.5 8.5C8.94772 8.5 8.5 8.94772 8.5 9.5C8.5 10.0523 8.94772 10.5 9.5 10.5Z"
        fill={color}
      />
    </svg>
  );
};
