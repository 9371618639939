export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

type Deferred = {
  promise: Promise<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
};

export function defer() {
  const deferred = {} as Deferred;
  const promise = new Promise(function (resolve, reject) {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  deferred.promise = promise;
  return deferred;
}
