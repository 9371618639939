import React from 'react';

import { SvgProps } from '..';

const GameShow = ({ scale = 1, color = '#FFFFFF' }: SvgProps) => {
  const width = `${scale * 15}`;
  const height = `${scale * 18}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.92747 11.515C4.86415 11.4769 4.79184 11.4562 4.71792 11.455C4.644 11.4539 4.57109 11.4724 4.50664 11.5086C4.44218 11.5448 4.38847 11.5975 4.35098 11.6612C4.31349 11.7249 4.29356 11.7974 4.29321 11.8714V14.2416C4.29356 14.3155 4.31349 14.3881 4.35098 14.4518C4.38847 14.5155 4.44218 14.5681 4.50664 14.6044C4.57109 14.6406 4.644 14.659 4.71792 14.6579C4.79184 14.6568 4.86415 14.6361 4.92747 14.5979L10.0735 11.5108C10.1368 11.4728 10.209 11.4522 10.2828 11.4512C10.3566 11.4501 10.4294 11.4687 10.4938 11.5049C10.5581 11.541 10.6117 11.5936 10.6492 11.6572C10.6866 11.7209 10.7066 11.7933 10.707 11.8671V14.2373C10.7066 14.3112 10.6866 14.3836 10.6492 14.4472C10.6117 14.5108 10.5581 14.5634 10.4938 14.5996C10.4294 14.6357 10.3566 14.6543 10.2828 14.6532C10.209 14.6522 10.1368 14.6316 10.0735 14.5937L4.92747 11.515Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.551765 16.8C0.549211 15.0065 1.24286 13.2819 2.48659 11.9897"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5134 11.9897C13.7572 13.2819 14.4508 15.0065 14.4483 16.8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4483 16.8C14.4508 15.0065 13.7572 13.2819 12.5134 11.9897"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.48659 11.9897C1.24286 13.2819 0.549211 15.0065 0.551765 16.8"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2413 5.754C10.3486 6.17642 9.32524 6.22913 8.3939 5.90066C7.46255 5.57219 6.69865 4.88914 6.26848 4.00018C6.08783 4.59046 5.76421 5.12698 5.32634 5.56211C4.88848 5.99724 4.34994 6.31749 3.75854 6.49444"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1182 9.85171C10.4743 9.50381 10.7572 9.08811 10.9501 8.62912C11.143 8.17014 11.242 7.67717 11.2413 7.1793V5.04137C11.2413 4.04909 10.8471 3.09746 10.1455 2.39581C9.44384 1.69417 8.4922 1.29999 7.49993 1.29999C6.50765 1.29999 5.55602 1.69417 4.85437 2.39581C4.15273 3.09746 3.75855 4.04909 3.75855 5.04137V7.1793C3.75785 7.67717 3.85687 8.17014 4.04977 8.62912C4.24266 9.08811 4.52553 9.50381 4.88168 9.85171"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GameShow;
