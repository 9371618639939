import React from 'react';

interface Props {
  scale?: number;
  color?: string;
  viewBox?: string;
}

const Crown = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 16 16' }: Props) => {
  const width = `${scale * 16}`;
  const height = `${scale * 16}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3269_121925)">
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.39978 12.1804H13.6002C13.7981 12.1806 13.9937 12.1034 14.1739 12.2065L14.9953 5.14065C15.0074 5.03443 14.9961 4.92607 14.9628 4.82732C14.9295 4.72858 14.8753 4.6432 14.8062 4.58046C14.7371 4.51772 14.6557 4.47999 14.5708 4.47138C14.4859 4.46277 14.4007 4.4836 14.3245 4.53161L11.6607 7.08692C11.6106 7.1385 11.5526 7.17642 11.4903 7.19827C11.4281 7.22012 11.3628 7.22543 11.2988 7.21386C11.2348 7.20229 11.1734 7.17409 11.1185 7.13106C11.0636 7.08802 11.0164 7.03108 10.9799 6.96385L8.35095 1.20118C8.30726 1.13801 8.25333 1.08737 8.19279 1.05266C8.13225 1.01796 8.06651 1 8 1C7.93349 1 7.86775 1.01796 7.80721 1.05266C7.74667 1.08737 7.69274 1.13801 7.64905 1.20118L5.02006 6.96385C4.98356 7.03108 4.93636 7.08802 4.88146 7.13106C4.82657 7.17409 4.76517 7.20229 4.70117 7.21386C4.63716 7.22543 4.57195 7.22012 4.50966 7.19827C4.44737 7.17642 4.38937 7.1385 4.33933 7.08692L1.67549 4.5324C1.59931 4.48439 1.51415 4.46356 1.42924 4.47217C1.34433 4.48078 1.26291 4.5185 1.1938 4.58124C1.1247 4.64399 1.07055 4.72936 1.03721 4.82811C1.00388 4.92686 0.992639 5.03521 1.00471 5.14144L1.82607 12.2065C2.00623 12.1032 2.20182 12.1808 2.39978 12.1804Z"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.6 12.2443H2.4C2.0287 12.2443 1.6726 12.3895 1.41005 12.6479C1.1475 12.9063 1 13.2567 1 13.6221C1 13.9876 1.1475 14.338 1.41005 14.5964C1.6726 14.8548 2.0287 15 2.4 15H13.6C13.9713 15 14.3274 14.8548 14.5899 14.5964C14.8525 14.338 15 13.9876 15 13.6221C15 13.2567 14.8525 12.9063 14.5899 12.6479C14.3274 12.3895 13.9713 12.2443 13.6 12.2443Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3269_121925">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Crown;
