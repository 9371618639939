import { pxToRem } from 'assets/styles/convertors';
import UI from 'constants/ui';
import styled from 'styled-components';

import colors from '../../../assets/styles/colors';

export const BalanceCard = styled.div`
  position: absolute;
  top: 3.5rem;
  left: 50%;
  background: ${colors.gray800};
  width: ${pxToRem(187)};
  max-height: 25rem;
  color: ${colors.white};
  transform: translateX(-50%);
  flex-direction: column;
  border-radius: ${UI.radius.sm};
  z-index: ${UI.z.modal};

  > div {
    max-height: 26.5rem;
    overflow: hidden;
    overflow-y: auto;
    background: ${colors.gray800};
  }
`;

export const Balance = styled.div<{ $selected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ $selected }) => ($selected ? colors.gray600 : colors.gray800)};
  padding: 1rem;
  cursor: pointer;
  font-size: ${UI.text.lg};

  &:first-of-type {
    border-radius: ${UI.radius.sm} ${UI.radius.sm} 0 0;
  }

  @media (hover: hover) {
    &:hover {
      background: ${colors.gray600};
    }
  }

  border-bottom: 0.0625rem solid ${colors.gray700};
`;

export const Amount = styled.div`
  text-align: left;
  margin-right: 0.5rem;
  font-variant-numeric: tabular-nums;
`;

export const CurrencyText = styled.div`
  text-align: left;
  width: 2.25rem;
`;

export const Options = styled.div`
  padding: 0.75rem 1rem;
  text-align: start;
  background: ${colors.gray800};
  border-bottom-left-radius: ${UI.radius.sm};
  border-bottom-right-radius: ${UI.radius.sm};
`;

export const CryptoIconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${UI.spacing.xs};
`;
