import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { updateCampaignCode } from 'redux/slices/campaignSlice';

export const CAMPAIGN_QUERY_KEY = 'r';

// This hook extracts the campaign code from the url and updates the redux store
export const useGlobalCampaignCodeSetup = () => {
  const dispatch = useDispatch();

  useMount(() => {
    // Do not use next router because it is not available on mount
    const campaignCode = new URLSearchParams(window.location.search).get(CAMPAIGN_QUERY_KEY);

    if (campaignCode) {
      dispatch(updateCampaignCode({ code: campaignCode }));
    }
  });
};
