import { useEffect } from 'react';
import { setUser } from '@sentry/browser';
import { useMyProfile } from 'hooks/useMyProfile';

import versionFile from '../../../public/version.json';
import { BROWSER_ID } from '../../apollo/utils';

const isProduction = process.env.NEXT_PUBLIC_ENV === 'prod';

function eventualIdentify(userId: string, username: string, email: string) {
  function identify() {
    window.FS!.identify(userId, {
      displayName: username,
      email: email,
    });
  }

  if (window.FS?.identify) {
    identify();
  } else {
    let attempts = 0;
    const interval = setInterval(() => {
      if (window.FS?.identify) {
        identify();
        clearTimeout(interval);
      } else {
        attempts++;

        if (attempts > 100) {
          clearTimeout(interval);
        }
      }
    }, 100);
  }
}

export const useUserTracking = () => {
  const { myProfile } = useMyProfile();

  useEffect(() => {
    if (myProfile?.id && isProduction) {
      eventualIdentify(myProfile?.id, myProfile?.username || '', myProfile?.email);
    }
  }, [myProfile]);

  useEffect(() => {
    try {
      if (isProduction) {
        const browserId = localStorage.getItem(BROWSER_ID);

        setUser({
          id: myProfile?.id || undefined,
          username: myProfile?.username || undefined,
          browserId,
          feVersion: versionFile.version,
        });
      }
    } catch (e) {
      console.error(`Failed to update user ${(e as Error)?.message || ''}`);
    }
  }, [myProfile]);
};
