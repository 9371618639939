import { useMemo } from 'react';

import { useMyProfile } from './useMyProfile';
import { useProfile } from './useProfile';

/**
 * We use Enum here to easily integrate with the backend.
 * Note that in future this enum will come from GraphQL.
 *
 * We will save the enum value to the user's profile.
 * Note on the backend these are zero-indexed.
 */
export enum FaceEnum {
  GIRAFFE_1 = 0,
  GIRAFFE_2 = 1,
  GIRAFFE_3 = 2,
  GIRAFFE_4 = 3,
  GIRAFFE_5 = 4,
  GIRAFFE_6 = 5,
  GIRAFFE_7 = 6,
  GIRAFFE_8 = 7,
  HIPPO_1 = 8,
  HIPPO_2 = 9,
  HIPPO_3 = 10,
  HIPPO_4 = 11,
  HIPPO_5 = 12,
  HIPPO_6 = 13,
  HIPPO_7 = 14,
  HIPPO_8 = 15,
  LION_1 = 16,
  LION_2 = 17,
  LION_3 = 18,
  LION_4 = 19,
  LION_5 = 20,
  LION_6 = 21,
  LION_7 = 22,
  LION_8 = 23,
  PENGUIN_1 = 24,
  PENGUIN_2 = 25,
  PENGUIN_3 = 26,
  PENGUIN_4 = 27,
  PENGUIN_5 = 28,
  PENGUIN_6 = 29,
  PENGUIN_7 = 30,
  PENGUIN_8 = 31,
  SHARK_1 = 32,
  SHARK_2 = 33,
  SHARK_3 = 34,
  SHARK_4 = 35,
  SHARK_5 = 36,
  SHARK_6 = 37,
  SHARK_7 = 38,
  SHARK_8 = 39,
}

/**
 * We use Enum here to easily integrate with the backend.
 * Note that in future this enum will come from GraphQL.
 *
 * We will save the enum value to the user's profile.
 */
export enum BgEnum {
  BG_1 = 0,
  BG_2 = 1,
  BG_3 = 2,
  BG_4 = 3,
  BG_5 = 4,
  BG_6 = 5,
  BG_7 = 6,
  BG_8 = 7,
}

/**
 * URLs for Bitmoji faces.
 * Note that these are not template strings to local files from `1-n` because
 * in the future we will likely be getting these URLs from our CMS which will
 * have UUID-like URL structure.
 */
export const faces: Record<FaceEnum, string> = {
  [FaceEnum.GIRAFFE_1]: '/images/avatars/giraffe/blue.png',
  [FaceEnum.GIRAFFE_2]: '/images/avatars/giraffe/green.png',
  [FaceEnum.GIRAFFE_3]: '/images/avatars/giraffe/light-blue.png',
  [FaceEnum.GIRAFFE_4]: '/images/avatars/giraffe/orange.png',
  [FaceEnum.GIRAFFE_5]: '/images/avatars/giraffe/pink.png',
  [FaceEnum.GIRAFFE_6]: '/images/avatars/giraffe/purple.png',
  [FaceEnum.GIRAFFE_7]: '/images/avatars/giraffe/red.png',
  [FaceEnum.GIRAFFE_8]: '/images/avatars/giraffe/yellow.png',
  [FaceEnum.HIPPO_1]: '/images/avatars/hippo/blue.png',
  [FaceEnum.HIPPO_2]: '/images/avatars/hippo/green.png',
  [FaceEnum.HIPPO_3]: '/images/avatars/hippo/light-blue.png',
  [FaceEnum.HIPPO_4]: '/images/avatars/hippo/orange.png',
  [FaceEnum.HIPPO_5]: '/images/avatars/hippo/pink.png',
  [FaceEnum.HIPPO_6]: '/images/avatars/hippo/purple.png',
  [FaceEnum.HIPPO_7]: '/images/avatars/hippo/red.png',
  [FaceEnum.HIPPO_8]: '/images/avatars/hippo/yellow.png',
  [FaceEnum.LION_1]: '/images/avatars/lion/blue.png',
  [FaceEnum.LION_2]: '/images/avatars/lion/green.png',
  [FaceEnum.LION_3]: '/images/avatars/lion/light-blue.png',
  [FaceEnum.LION_4]: '/images/avatars/lion/orange.png',
  [FaceEnum.LION_5]: '/images/avatars/lion/pink.png',
  [FaceEnum.LION_6]: '/images/avatars/lion/purple.png',
  [FaceEnum.LION_7]: '/images/avatars/lion/red.png',
  [FaceEnum.LION_8]: '/images/avatars/lion/yellow.png',
  [FaceEnum.PENGUIN_1]: '/images/avatars/penguin/blue.png',
  [FaceEnum.PENGUIN_2]: '/images/avatars/penguin/green.png',
  [FaceEnum.PENGUIN_3]: '/images/avatars/penguin/light-blue.png',
  [FaceEnum.PENGUIN_4]: '/images/avatars/penguin/orange.png',
  [FaceEnum.PENGUIN_5]: '/images/avatars/penguin/pink.png',
  [FaceEnum.PENGUIN_6]: '/images/avatars/penguin/purple.png',
  [FaceEnum.PENGUIN_7]: '/images/avatars/penguin/red.png',
  [FaceEnum.PENGUIN_8]: '/images/avatars/penguin/yellow.png',
  [FaceEnum.SHARK_1]: '/images/avatars/shark/blue.png',
  [FaceEnum.SHARK_2]: '/images/avatars/shark/green.png',
  [FaceEnum.SHARK_3]: '/images/avatars/shark/light-blue.png',
  [FaceEnum.SHARK_4]: '/images/avatars/shark/orange.png',
  [FaceEnum.SHARK_5]: '/images/avatars/shark/pink.png',
  [FaceEnum.SHARK_6]: '/images/avatars/shark/purple.png',
  [FaceEnum.SHARK_7]: '/images/avatars/shark/red.png',
  [FaceEnum.SHARK_8]: '/images/avatars/shark/yellow.png',
};

export const backgrounds: Record<BgEnum, string> = {
  [BgEnum.BG_1]: 'linear-gradient(140.11deg, #62A8FC 16.54%, #3A3CF0 86.98%)',
  [BgEnum.BG_2]: 'linear-gradient(140.11deg, #00F07F 16.54%, #009964 86.98%)',
  [BgEnum.BG_3]: 'linear-gradient(140.11deg, #77E0FF 16.54%, #008CCC 86.98%)',
  [BgEnum.BG_4]: 'linear-gradient(140.11deg, #F8B99C 16.54%, #DA6D2A 86.98%)',
  [BgEnum.BG_5]: 'linear-gradient(140.11deg, #FFB1C5 16.54%, #F53FA3 86.98%)',
  [BgEnum.BG_6]: 'linear-gradient(140.11deg, #A997FF 16.54%, #6726EC 86.98%)',
  [BgEnum.BG_7]: 'linear-gradient(140.11deg, #FF8080 16.54%, #FF3838 86.98%)',
  [BgEnum.BG_8]: 'linear-gradient(140.11deg, #FDDA65 16.54%, #DE9D1C 86.98%)',
};

export interface AvatarProperties {
  face: FaceEnum;
  background: BgEnum;
}

/**
 * To fetch another user's avatar, set `username`.
 * Else, it will default to currenct user's avatar.
 */
const useAvatar = (username?: string) => {
  const { myProfile } = useMyProfile();
  const { profile: otherProfile } = useProfile(username);

  // Determine if this is our profile or someone else's.
  const profile = useMemo(
    () => (username ? otherProfile : myProfile),
    [username, otherProfile, myProfile],
  );

  const avatar: AvatarProperties | null = useMemo(() => {
    if (!profile) {
      return null;
    }

    return {
      face: profile.avatar as FaceEnum,
      background: profile.avatarBackground as BgEnum,
    };
  }, [profile]);

  return avatar;
};

export default useAvatar;
