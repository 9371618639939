import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import cache from 'apollo/cache';
import {
  GetMyProfileDocument,
  GetMyStatisticDocument,
  useGetMyProfileQuery,
  useGetMyRaceInfoLazyQuery,
  useGetMyStatisticQuery,
  useVipUpgradeBonusesLazyQuery,
  VipLevel,
} from 'generated/graphql';
import { useEmailVerifiedSubscription, useVipLevelSubscription } from 'generated/subscription';
import { useTranslation } from 'next-i18next';
import { addAlert, AlertsEnum } from 'redux/slices/alertsSlice';

import { useIsLoggedIn } from './auth/useIsLoggedIn';

export const useMyProfile = () => {
  const isLoggedIn = useIsLoggedIn();
  const { data, loading, error, refetch } = useGetMyProfileQuery({
    skip: !isLoggedIn,
  });

  return {
    myProfile: data?.me,
    loading,
    error,
    refetch,
  };
};

export const useMyStatistics = () => {
  const isLoggedIn = useIsLoggedIn();
  const { data, loading, error, refetch } = useGetMyStatisticQuery({
    skip: !isLoggedIn,
  });

  return {
    myStatistics: data?.me,
    loading,
    error,
    refetch,
  };
};

export const useGlobalEmailVerified = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { myProfile } = useMyProfile();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEmailVerifiedSubscription({
    skip: !isLoggedIn || !!myProfile?.emailVerifiedAt,
    onData: ({ data }) => {
      const emailVerifiedAt = data.data?.emailVerified?.emailVerifiedAt;

      if (emailVerifiedAt) {
        cache.writeQuery({
          query: GetMyProfileDocument,
          data: {
            me: {
              ...myProfile,
              emailVerifiedAt,
            },
          },
        });

        dispatch(
          addAlert({
            type: AlertsEnum.Success,
            message: t('msgEmailVerified'),
          }),
        );
      }
    },
  });
};

export const useGlobalUpdateVipLevel = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { myProfile } = useMyProfile();
  const { myStatistics } = useMyStatistics();
  const refVipLevel = useRef<VipLevel>();
  const [, { refetch: refetchUpgradeBonus }] = useVipUpgradeBonusesLazyQuery();

  const [getMyRaceInfo, { data: myRaceInfoData }] = useGetMyRaceInfoLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const previousLevel = refVipLevel.current;

    if (myProfile?.vipLevel) {
      if (previousLevel && previousLevel !== myProfile.vipLevel) {
        refetchUpgradeBonus(); // get the bonus for the upgraded level
        dispatch(
          addAlert({
            type: AlertsEnum.Success,
            message: t('msgLevelUp', {
              level: t(myProfile?.vipLevel),
            }),
          }),
        );
      }
      refVipLevel.current = myProfile.vipLevel;
    }
  }, [dispatch, myProfile?.vipLevel, refetchUpgradeBonus, t]);

  useEffect(() => {
    if (myRaceInfoData?.myRaceInfo) {
      cache.writeQuery({
        query: GetMyProfileDocument,
        data: {
          me: {
            ...myProfile,
            raceInfo: myRaceInfoData?.myRaceInfo,
          },
        },
      });
    }
  }, [myRaceInfoData, myProfile]);

  useVipLevelSubscription({
    skip: !isLoggedIn,
    onData: ({ data }) => {
      const vipLevelUpdated = data.data?.vipLevelUpdated;
      if (vipLevelUpdated) {
        cache.writeQuery({
          query: GetMyProfileDocument,
          data: {
            me: {
              ...myProfile,
              vipLevel: vipLevelUpdated.vipLevel,
              xp: vipLevelUpdated.xp,
            },
          },
        });
      }

      if (myStatistics?.statistic && vipLevelUpdated?.wagered) {
        cache.writeQuery({
          query: GetMyStatisticDocument,
          data: {
            me: {
              ...myProfile,
              id: myStatistics.id,
              statistic: {
                bets: myStatistics.statistic.bets,
                wagered: vipLevelUpdated.wagered,
              },
            },
          },
        });

        // for race entry, fetch the raceInfo from server
        if (!myProfile?.raceInfo) {
          getMyRaceInfo();
        }
      }
    },
  });
};
