import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import colors from 'assets/styles/colors';
import { ArrowDecrease, ArrowIncrease } from 'assets/svgs';
import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { useSportsOdds } from 'hooks/useSportsOdds';
import { ModalTypes } from 'redux/slices/modalSlice';

import { PrimaryButton } from '../../../../components/Buttons/styles';
import CryptoIcon from '../../../../components/CurrencyIcons/CryptoIcon';
import FormattedAmount from '../../../../components/FormattedAmount';
import { Currency, SportsMarketStatus } from '../../../../generated/graphql';
import { BetSlipErrors } from '../../../../hooks/useSportsBetSlipCheck';
import { BetSlipItem, BetSlipStatus } from '../../../../redux/slices/sportsBetSlice';
import { calculateCashOut } from '../../../../utils/calculateCashOut';
import { getSportBetOdds } from '../../../../utils/getSportBetOdds';
import { SportsBetTicketStatusType } from '../BetsGroup.type';

import { BetContent } from './betContent';
import { BetStakeAndPayout } from './BetStakeAndPayout';
import { BetTicketAmount } from './BetTicketAmount';
import { BetTicketText } from './BetTicketText';
import { getBetViewConfig } from './getBetViewConfig';

import styles from './BetOddsStakeAndStatus.module.scss';

interface CashOutButtonProps {
  shouldShowCashOutSuspended: boolean;
  shouldShowCashOutOpen: boolean;
  shouldShowCashedOut: boolean;
  children: React.ReactNode;
}

export const CashOutButtonElement = ({
  shouldShowCashOutSuspended,
  shouldShowCashOutOpen,
  shouldShowCashedOut,
  children,
}: CashOutButtonProps) => {
  return (
    <>
      {shouldShowCashOutOpen && <div className={styles.cashOutButton}>{children}</div>}
      {shouldShowCashOutSuspended && (
        <div className={clsx(styles.cashOutButton, styles.cashOutButtonSuspended)}>{children}</div>
      )}
      {shouldShowCashedOut && (
        <div className={clsx(styles.cashOutButton, styles.cashOutButtonClosed)}>{children}</div>
      )}
    </>
  );
};

type Props = {
  content: BetContent;
  odds?: ReturnType<typeof getSportBetOdds>;
  oddsOutputText: string;
  currency: Currency;
  betSlipStatus?: BetSlipStatus;
  publicView?: boolean;
  estimatePayoutOutput: string;
  amount: string;
  bet: BetSlipItem;
  settlementPayout?: string;
  cashoutOddsDecimal?: string;
  shouldShowCashOut?: boolean;
  onClickCashOut?: () => void;
  hasMultiSelections?: boolean;
  status?: SportsBetTicketStatusType;
  errors: BetSlipErrors;
  isEventClosed?: boolean;
};

export function BetOddsStakeAndStatus({
  bet,
  status,
  bet: { cashOutStatus, betAmount },
  errors,
  content,
  odds,
  oddsOutputText,
  publicView,
  betSlipStatus,
  estimatePayoutOutput,
  currency,
  amount,
  settlementPayout,
  cashoutOddsDecimal,
  shouldShowCashOut = false,
  hasMultiSelections,
  isEventClosed,
  onClickCashOut,
}: Props) {
  const {
    shouldShowStats,
    shouldShowEstimateAmount,
    shouldShowReceipt,
    isInBetInfoView,
    isInBetPlacedView,
    isInBetAddOrConfirmed,
    isCanceled,
    isVoid,
    isPushed,
    shouldShowCashOutSuspended,
    shouldShowCashOutOpen,
    shouldShowCashedOut,
  } = getBetViewConfig({
    status: bet.status,
    betSlipStatus,
    cashOutStatus,
  });

  const modalStore = useSelector((state: AppState) => state.modal);

  const { isOddsChanges, isOddsIncrease, isOddsDecrease } = useSportsOdds({
    odds: cashoutOddsDecimal || '',
    autoHideIndicator: true,
  });

  const openCashOutModal = useCallback(() => {
    if (!shouldShowCashOut || cashOutStatus !== SportsMarketStatus.OPEN) {
      return;
    }

    onClickCashOut && onClickCashOut();
  }, [cashOutStatus, onClickCashOut, shouldShowCashOut]);

  const isInBetResultView = modalStore.modalType === ModalTypes.SPORT_RESULTS;
  const isViewMyBetResult = isInBetResultView && !publicView;
  const hasPlacedBetError = isInBetPlacedView && errors.placedBetError;

  return shouldShowStats ? (
    <div className={hasPlacedBetError || isVoid ? styles.voided : ''}>
      <div
        className={clsx(styles.betTicketFooter, {
          [styles.betTicketFooterNoRounded]: shouldShowReceipt,
          [styles.betTicketFooterDimmed]: isCanceled || isPushed,
        })}
      >
        {!isInBetAddOrConfirmed &&
          (isViewMyBetResult || isInBetPlacedView || isInBetInfoView || status) && (
            <div className={styles.fieldInfo}>
              <BetTicketText capitalize color={colors.gray300}>
                {content.totalOdds()}
              </BetTicketText>
              <BetTicketAmount>{odds?.outputText || oddsOutputText}</BetTicketAmount>
            </div>
          )}
        {hasMultiSelections ? (
          <>
            <div className={styles.fieldInfo}>
              <BetTicketText capitalize color={colors.gray300}>
                {publicView ? content.totalStake() : content.lblYourStake()}
              </BetTicketText>
              <BetTicketAmount>
                <CryptoIcon currency={currency} />
                <FormattedAmount value={amount} currency={currency} />
              </BetTicketAmount>
            </div>
          </>
        ) : (
          <div className={styles.fieldInfo}>
            <BetTicketText capitalize color={colors.gray300}>
              {publicView ? content.totalStake() : content.lblYourStake()}
            </BetTicketText>
            <BetTicketAmount>
              <CryptoIcon currency={currency} />
              <FormattedAmount value={amount} currency={currency} />
            </BetTicketAmount>
          </div>
        )}
        <BetStakeAndPayout
          status={bet.status}
          betSlipStatus={betSlipStatus}
          currency={currency}
          content={content}
          estimatePayoutOutput={estimatePayoutOutput}
          publicView={publicView}
        />
        {shouldShowEstimateAmount && (
          <div className={styles.fieldInfo}>
            <BetTicketText capitalize color={colors.gray300}>
              {content.lblEstPayout()}
            </BetTicketText>
            <BetTicketAmount isPayout={BigNumber(estimatePayoutOutput).isGreaterThan(0)}>
              <CryptoIcon currency={currency} />
              <span className={shouldShowCashedOut || isVoid ? styles.whiteText : ''}>
                <FormattedAmount value={estimatePayoutOutput} currency={currency} />
              </span>
            </BetTicketAmount>
          </div>
        )}
        {((!shouldShowCashedOut && shouldShowCashOut && !isEventClosed) || shouldShowCashedOut) && (
          <CashOutButtonElement
            shouldShowCashOutSuspended={shouldShowCashOutSuspended}
            shouldShowCashOutOpen={shouldShowCashOutOpen}
            shouldShowCashedOut={shouldShowCashedOut}
          >
            <PrimaryButton
              fillIcon={false}
              disabled={cashOutStatus !== SportsMarketStatus.OPEN || shouldShowCashedOut}
              onClick={openCashOutModal}
            >
              {shouldShowCashOutSuspended && content.cashOutSuspended()}
              {(shouldShowCashOutOpen || shouldShowCashedOut) && (
                <div className={styles.cashOutField}>
                  {shouldShowCashedOut ? content.cashedOutFor() : content.cashOutFor()}
                  {isOddsChanges && cashOutStatus === SportsMarketStatus.OPEN && (
                    <span className={styles.indicatorIcon}>
                      {isOddsIncrease && <ArrowIncrease />}
                      {isOddsDecrease && <ArrowDecrease />}
                    </span>
                  )}
                  <CryptoIcon currency={currency} />
                  <FormattedAmount
                    value={
                      shouldShowCashedOut && settlementPayout
                        ? String(settlementPayout)
                        : calculateCashOut({
                            amount: betAmount,
                            cashOutOddsDecimal: cashoutOddsDecimal || '0',
                          })
                    }
                    currency={currency}
                  />
                </div>
              )}
            </PrimaryButton>
          </CashOutButtonElement>
        )}
      </div>
    </div>
  ) : null;
}
