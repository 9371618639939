import React from 'react';

import { SvgProps } from '../..';

const Basketball = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clipPath="url(#clip0_2887_78091)">
        <path
          d="M20.2165 4.50604C18.3396 6.49742 17.1826 9.17218 17.1826 12.1251C17.1826 15.078 18.3411 17.7527 20.2165 19.7441"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.03372 4.50604C5.91067 6.49742 7.06767 9.17218 7.06767 12.1251C7.06767 15.078 5.90908 17.7527 4.03372 19.7441"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.125 1V23.25"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.25 12.125H1"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.125 23.25C18.2692 23.25 23.25 18.2692 23.25 12.125C23.25 5.98083 18.2692 1 12.125 1C5.98083 1 1 5.98083 1 12.125C1 18.2692 5.98083 23.25 12.125 23.25Z"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2887_78091">
          <rect width="24px" height="24px" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Basketball;
