import React from 'react';

import { SvgProps } from '../..';

const Boxing = ({ scale = 1, color = '#FFFFFF', viewBox = '0 0 24 24' }: SvgProps) => {
  const width = `${scale * 24}`;
  const height = `${scale * 24}`;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g id="Property 1=boxing-02">
        <g id="Group">
          <path
            id="Vector"
            d="M3.26442 8.2005C2.68715 8.67026 2.31949 9.34957 2.24191 10.0898C2.16432 10.83 2.38312 11.5707 2.85042 12.15L6.75042 17.25V20.25C6.75042 21.0456 7.06649 21.8087 7.6291 22.3713C8.19171 22.9339 8.95477 23.25 9.75042 23.25H17.2504C18.0461 23.25 18.8091 22.9339 19.3717 22.3713C19.9343 21.8087 20.2504 21.0456 20.2504 20.25V15.75L21.7114 5.724C21.8222 5.11599 21.798 4.49107 21.6406 3.89345C21.4831 3.29583 21.1963 2.7401 20.8003 2.26559C20.4044 1.79108 19.909 1.40937 19.3492 1.14747C18.7894 0.885572 18.1789 0.749882 17.5609 0.75H9.38892C8.77099 0.750146 8.16063 0.886011 7.60099 1.14799C7.04134 1.40997 6.54607 1.79168 6.15017 2.26613C5.75427 2.74058 5.46741 3.29619 5.30986 3.8937C5.15231 4.49121 5.12793 5.11603 5.23842 5.724L6.36492 11.919"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M9.75 18.75H17.25"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default Boxing;
