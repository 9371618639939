import { Language } from 'constants/languages';
import { ChatRoom } from 'generated/graphql';
import { useRouter } from 'next/router';

interface ChatRoomIntercomLang {
  defaultChatRoomLanguage: ChatRoom;
  intercomLanguage: Language;
}

const DEFAULT_LANGUAGE_MAPPING: ChatRoomIntercomLang = {
  defaultChatRoomLanguage: ChatRoom.ENGLISH,
  intercomLanguage: Language.EN,
};

const LANGUAGE_MAPPING: Record<Language, ChatRoomIntercomLang> = {
  [Language.EN]: DEFAULT_LANGUAGE_MAPPING,
  [Language.ZH]: DEFAULT_LANGUAGE_MAPPING,
  [Language.FR]: DEFAULT_LANGUAGE_MAPPING,
  [Language.ES]: DEFAULT_LANGUAGE_MAPPING,
  [Language.PT]: DEFAULT_LANGUAGE_MAPPING,
  [Language.JA]: {
    defaultChatRoomLanguage: ChatRoom.JAPANESE,
    intercomLanguage: Language.JA,
  },
  [Language.KO]: DEFAULT_LANGUAGE_MAPPING,
};

export function useLanguagePreference() {
  const router = useRouter();

  let languagePreference = Language.EN;

  if (router && Object.values(Language).includes(router.locale as Language)) {
    languagePreference = router.locale as Language;
  }

  const chatRoomIntercomLang = LANGUAGE_MAPPING[languagePreference];

  return {
    ...chatRoomIntercomLang,
    languagePreference,
  };
}
