import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';

import usePreference from './usePreference';

/**
 *
 * @returns USD / currency (eg: if 1USD = 1.06 EUR, then it returns 0.94)
 */
export const useFiatRate = () => {
  const { fiatPreference } = usePreference();

  const fiatPrice = useSelector((state: AppState) => state.prices.fiatPrices[fiatPreference]);

  return BigNumber(fiatPrice);
};

export const useUsdToPreferredFiat = (usdAmount: string | number) => {
  const fiatRate = useFiatRate();

  return BigNumber(usdAmount).multipliedBy(fiatRate);
};
