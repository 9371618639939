import { useEffect } from 'react';
import { CORRELATION_USER_ID } from 'apollo/utils';
import { useMyProfile } from 'hooks/useMyProfile';

export const useSetupCorrelationId = () => {
  const { myProfile } = useMyProfile();

  useEffect(() => {
    if (myProfile?.id) {
      localStorage.setItem(CORRELATION_USER_ID, myProfile?.id);
    }
  }, [myProfile?.id]);
};
