import jwtDecode, { JwtPayload } from 'jwt-decode';

export const checkValidJWT = (jwt: string) => {
  try {
    const { exp } = jwtDecode<JwtPayload>(jwt);
    const currentNumericDate = Math.round(Date.now() / 1000);
    // Valid if no expiry, or its greater than now
    return !exp || exp > currentNumericDate;
  } catch (e: unknown) {
    /* Malformed tokens will throw InvalidTokenError exception*/
  }
  return false;
};
